export const toucanIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.3606 6.49835L25.9633 4.83984C25.134 4.2669 24.154 3.95029 23.1438 3.95029H14.2481L12.8158 2.44255C11.3382 0.889577 9.25751 0 7.10145 0C4.22167 0 1.65851 1.92992 0.874487 4.70416L0.482478 6.04603C0.150776 7.19191 0 8.36796 0 9.54399V12.8309C0 12.9062 0.0150792 12.9816 0.0301566 13.057L1.67359 20.1283C1.85452 20.9274 1.83944 21.7567 1.62836 22.5407L0.0452303 28.3907C-0.0301566 28.6923 0.0301493 29.0089 0.211078 29.2501C0.392007 29.4914 0.693556 29.6421 0.995104 29.6421H24.6968C25.2395 29.6421 25.6919 29.2049 25.6919 28.647V26.0688C25.6919 23.0081 24.154 20.2037 21.5758 18.5754L17.2335 15.8162H21.7416C24.1087 15.8162 26.0688 14.1426 26.5664 11.9262L27.9083 13.4943C28.1043 13.7204 28.3757 13.8411 28.6621 13.8411C28.7827 13.8411 28.8883 13.826 29.0089 13.7807C29.4009 13.63 29.6572 13.2681 29.6572 12.861V8.95597C29.6422 7.96087 29.1597 7.05621 28.3606 6.49835ZM3.5281 23.0533C3.82965 21.9527 3.84473 20.7917 3.58842 19.676L3.42257 18.9523C5.51833 18.4246 7.56885 18.2889 9.52891 18.6206C10.7502 18.8166 11.7453 19.4649 12.4841 20.5354C13.645 22.2392 14.0823 24.9078 13.6601 27.667H2.27668L3.5281 23.0533ZM23.7167 26.0688V27.6519H15.6805C16.0876 24.5158 15.5297 21.4401 14.1275 19.4046C13.0872 17.8969 11.6096 16.947 9.86061 16.6605C7.64423 16.2987 5.33739 16.4193 2.98532 17.0073L1.99022 12.7253V9.54399C1.99022 8.54889 2.12591 7.55378 2.41238 6.58883L2.80439 5.24694C3.34718 3.31703 5.14139 1.97513 7.1316 1.97513C8.74488 1.97513 10.2979 2.63855 11.4136 3.81459L12.861 5.3374V14.8211C12.861 15.1528 13.0269 15.4694 13.3133 15.6503L20.5203 20.2339C22.5105 21.5154 23.7167 23.6866 23.7167 26.0688ZM21.7416 13.826H14.8211V11.8508H24.5158C24.1088 12.9967 23.0232 13.826 21.7416 13.826ZM27.667 10.1772C27.4861 9.98123 27.2298 9.87569 26.9584 9.87569H14.8211V5.92542H23.1438C23.7469 5.92542 24.3349 6.10635 24.8325 6.45313L27.2298 8.11164C27.5012 8.29257 27.6519 8.59412 27.6519 8.92583V10.1772H27.667Z"
      fill="#333333"
    />
    <path
      d="M8.45897 6.18164C7.91619 6.18164 7.46387 6.61888 7.46387 7.17674V7.67431C7.46387 8.21709 7.90111 8.66941 8.45897 8.66941C9.01683 8.66941 9.45408 8.23217 9.45408 7.67431V7.17674C9.45408 6.61888 9.00176 6.18164 8.45897 6.18164Z"
      fill="#333333"
    />
  </svg>
);
