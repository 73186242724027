import { useState } from 'react';
import DeleteAccountPop from './DeleteAccountPop';
import styles from './styles.module.css';

export default function PrivacyMobileSettings() {
  const [confirm, setConfirm] = useState(false);
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span className={styles.securityText}>
        <span style={{ color: '#FFC700' }}>*</span>Strong passwords are longer than eight characters, are hard to guess
        and contain a variety of characters, numbers and special symbols. The best ones can be difficult to remember,
        especially if you're using a distinct login for every site (which is recommended).
      </span>
      {/*<div className={styles.changePasswordBtn} onClick={() => console.log('Change password')}>*/}
      {/*  Change password*/}
      {/*</div>*/}
      <div
        className={styles.deleteAccountBtn}
        onClick={() => {
          setConfirm(true);
        }}
      >
        Delete my account
      </div>
      {confirm && <DeleteAccountPop setConfirm={setConfirm} />}
    </div>
  );
}
