import React from 'react';
import { IGroup } from '../../../../types/Group';
import {format} from "date-fns";
import styles from './styles.module.css';

function StatisticsDetails({ group }: { group: IGroup }) {
  return (
    <div className={styles.detailsBlock}>
      <div className={styles.detailsTextSubtitle}>Group description:</div>
        <div className={styles.detailsGroupDescription}>
            {group.description}
        </div>
        <div className={styles.detailsTextSubtitle}>Creation date:</div>
        <div className={styles.detailsGroupDescription}>
            {format(new Date(group.creation_date),'MMM dd, yyyy')}
        </div>
    </div>
  );
}

export default StatisticsDetails;