import React, { useState } from 'react';
import { ReportsApiService } from '../../../../services/report.service';
import { REPORT_TYPES } from '../../../../types/Reports';
import { useToken } from '../../../../cookies';
import { REPORT_OPTIONS } from '../../../../utils/constants';
import { v4 as uuid } from 'uuid';
import mobileChatsStyles from '../../../MobileChats/MobileChatsStyles.module.css';
import styles from './styles.module.css';

function ReportCommentPopUp({ commentId, closePopUp }: { commentId: string; closePopUp: () => void }) {
  const [reportedTitle, setReportedTitle] = useState('');
  const [reportedDescription, setReportedDescription] = useState('');

  const { token } = useToken();

  const submitReportHandler = () => {
    ReportsApiService.createReport(token, {
      report_object_id: commentId,
      report_reason_title: reportedTitle,
      report_reason_description: reportedDescription,
      report_object_type: REPORT_TYPES.COMMENT,
    }).finally(() => closePopUp());
  };

  return (
    <div className={styles.reportPopUpContent}>
      <div className={styles.reportCommentHead}>Report a comment</div>
      {reportedTitle ? (
        <div className={styles.reportMoreInfoBlock}>
          <div>Describe the problem</div>
          <textarea
            className={styles.reportTextArea}
            onChange={(e) => setReportedDescription(e.target.value)}
          ></textarea>
          <div className={styles.reportButton} onClick={submitReportHandler}>
            Send report
          </div>
        </div>
      ) : (
        REPORT_OPTIONS.map((option: any, index: number) => {
          return (
            <div key={uuid()} className={mobileChatsStyles.popupWrapper}>
              <div
                className={mobileChatsStyles.popUpRow}
                onClick={() => setReportedTitle(option.label)}
                style={{ padding: '0 14px' }}
              >
                <div>
                  <option.icon width={20} height={20} />
                </div>
                <span>{option.label}</span>
              </div>
              {index !== REPORT_OPTIONS.length - 1 && <hr className={mobileChatsStyles.reportDivider} />}
            </div>
          );
        })
      )}
    </div>
  );
}

export default ReportCommentPopUp;