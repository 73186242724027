import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useSelector } from 'react-redux';
import { selectAllGroupsWeekMoods } from '../../store/moods/selector';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { IDayMoodForAllGroups } from '../../types/Moods';
import styles from './rechartsStyle.module.css';

export function RechartsUserMood({ adminData }: { adminData?: Array<IDayMoodForAllGroups> }) {
  const defaultData = useSelector(selectAllGroupsWeekMoods);
  const data = adminData ? adminData : defaultData;
  const CustomizedDot = (props: any, type: string, color: string) => {
    const { cx, cy, payload } = props;
    const getRadius = () => {
      if (payload[type].times > 5) return '14';
      if (payload[type].times > 0) return '9';
      return '0';
    };
    return (
      <svg x={cx - 15} y={cy - 17}>
        <circle cx="16" cy="16" r={getRadius()} fill={color} />
        <text x={Number(payload[type].times) > 9 ? '7' : '11'} y="22" fill={'black'} style={{}}>
          {payload[type].times ? payload[type].times : ''}
        </text>
      </svg>
    );
  };
  const moods = [
    { type: 'happy', color: '#70bb28' },
    { type: 'confident', color: '#61A125' },
    { type: 'excited', color: '#61A125' },
    { type: 'confused', color: '#c7cb15' },
    { type: 'curious', color: '#c7cb15' },
    {
      type: 'tired',
      color: '#c7cb15',
    },
    { type: 'angry', color: '#EA0017' },
    { type: 'frustrated', color: '#EA0017' },
    {
      type: 'sad',
      color: '#EA0017',
    },
  ];
  return data.length ? (
    <div
      style={{
        marginTop: '12px',
        display: 'flex',
      }}
    >
      {/*<LeftBarEmoji width={width} height={height}/>*/}
      <ResponsiveContainer width={'99%'} height={430}>
        <LineChart
          data={data}
          margin={{
            top: 18,
            right: 16,
            left: -40,
            bottom: 18,
          }}
        >
          <CartesianGrid opacity={0.2} vertical={true} strokeDasharray="1 3" />
          <XAxis
            tickFormatter={(str) => {
              return str;
            }}
            dataKey={'name'}
            tickSize={15}
          />
          <YAxis
            dataKey={'uv'}
            tickFormatter={(number) => {
              return '';
            }}
            axisLine={false}
            tickCount={9}
            tickSize={1}
            domain={[0, 8]}
          />
          {moods.map((mood) => {
            return (
              <Line
                key={mood.type}
                type="monotone"
                dataKey={`${mood.type}.val`}
                stroke={mood.color}
                isAnimationActive={false}
                dot={(props) => CustomizedDot(props, mood.type, mood.color)}
                strokeDasharray="5 5"
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <div style={{ backgroundColor: !adminData ? '' : 'white' }} className={styles.loaderSpinner}>
      <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
        {!adminData && <CircularProgress color="inherit" />}
      </Stack>
    </div>
  );
}
