import { Paper } from '../../types/Paper';
import { RootState } from '../rootReducer';

export const selectPapers = (state: RootState): Array<Paper> => Object.values(state.articles.papers);

export const selectSelectedPaper = (state: RootState): Paper => state.articles.selectedPaper;

export const selectFeedError = (state: RootState): string | null => state.articles.error;

export const selectSearchedArticles = (state: RootState): Array<Paper> => state.articles.searchResult as Array<Paper>;

export const selectMyArticles = (state: RootState): Array<Paper> | undefined => state.articles.myArticles;

export const selectArticlesLoading = (state: RootState): boolean => state.articles.isLoading;

export const selectArticlesPage = (state: RootState): number => state.articles.page;

export const selectArticlesNext = (state: RootState): string|null => state.articles.next;

export const selectIsArticleLoading = (state: RootState): boolean => state.articles.isArticleLoading;
