import { plusIcon } from 'images/plusIcon';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './accordionStyles.module.css';

import { useToken } from '../../../cookies';
import { createTag } from '../../../store/articles';
import { getTags } from '../../../store/tags';
import { selectTags } from '../../../store/tags/selectors';


function TagsAccordion({
  setSelectedTags,
  selectedTags,
}: {
  setSelectedTags: Dispatch<SetStateAction<{ [p: string]: string }>>;
  selectedTags: { [key: string]: string };
}) {
  const [isCreateTag, setIsCreateTag] = useState(false);
  const [tagName, setTagName] = useState('');
  const { token } = useToken();
  const dispatch = useDispatch();
  const tags = useSelector(selectTags);

  useEffect(() => {
    dispatch(getTags({ token }));
  }, []);

  useEffect(() => {
    if (!Object.keys(selectedTags).length) {
      setSelectedTags({ all: 'All' });
    }
  }, [selectedTags]);

  const handleSelectedTags = (tag: string) => {
    if (tag === 'All') {
      return setSelectedTags({ all: 'All' });
    }
    if (selectedTags[tag.toLowerCase()]) {
      setSelectedTags((current) => {
        const copy = { ...current };
        delete copy[tag.toLowerCase()];
        return copy;
      });

      return;
    }

    setSelectedTags((current) => {
      const { all, ...rest } = current;

      return { ...rest, [tag.toLowerCase()]: tag };
    });
  };

  const addTag = () => {
    if (tagName.length) {
      setTagName('');
      setIsCreateTag(false);
      dispatch(createTag({ token, name: tagName }));
    }
    setTimeout(() => {
      dispatch(getTags({ token }));
    }, 200);
  };

  return (
    <div>
      <div className={styles.tagsWrapper}>
        <div
          onClick={() => handleSelectedTags('All')}
          className={styles.tagElement}
          style={{ backgroundColor: selectedTags.all ? '#E5E5E5FF' : '' }}
        >
          All
        </div>
        {tags.map((tag) => {
          return (
            <div
              key={tag.id}
              onClick={() => handleSelectedTags(tag.name)}
              style={{ backgroundColor: selectedTags[tag.name.toLowerCase()] ? '#E5E5E5FF' : '' }}
              className={styles.tagElement}
            >
              {tag.name.slice(0, 26)}
            </div>
          );
        })}
      </div>

      {isCreateTag ? (
        <div className={styles.createTag}>
          <input value={tagName} type="text" onChange={(e) => setTagName(e.target.value)} />
          <div className={styles.createTagAdd} onClick={addTag}>
            Add
          </div>
          <div className={styles.createTagCancel} onClick={() => setIsCreateTag(false)}>
            Cancel
          </div>
        </div>
      ) : (
        <div onClick={() => setIsCreateTag(true)} className={styles.tagElement}>
          {plusIcon('#757575')} Add tag
        </div>
      )}
    </div>
  );
}

export default TagsAccordion;
