import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const TIMER_REDUCER_NAME = 'timer';

type InitialStateType = {
  resendCodeTime: number;
};

const initialState: InitialStateType = {
  resendCodeTime: 60,
};

const authSlice = createSlice({
  name: TIMER_REDUCER_NAME,
  initialState,
  reducers: {
    setTimer(state, { payload }: PayloadAction<number>) {
      state.resendCodeTime = payload;
    },
  },
});

export const { setTimer } = authSlice.actions;
export default authSlice.reducer;
