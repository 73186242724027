import { Redirect, useLocation } from 'react-router-dom';
import { FC, ReactNode } from 'react';
import { useToken } from '../cookies';

type Props = {
  children: ReactNode;
  redirectTo?: string;
};

export const LoggedRouteMiddleware: FC<Props> = (props: Props) => {
  const { children } = props;
  const { token } = useToken();
  const location = useLocation();

  if (token) {
    return <div>{children}</div>;
  }

  if (location.pathname.startsWith('/admin')) {
    return <Redirect to="/admin/auth" />;
  }

  return <Redirect to={'/login'} />;
};
