import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedPaper } from '../../store/articles/selector';
import { ArticleForm } from '../../types/Paper';
import EditCreateArticleForm from '../EditCreateArticle/EditCreateArticleForm';
import { css } from 'styled-components/macro';

import CreateArticleHeader from '../Header/CreateArticleHeader';
import { useParams } from 'react-router-dom';
import { getPaperById } from '../../store/articles';
import { useToken } from '../../cookies';

export default function EditArticle() {
  const [isPublished, setIsPublished] = useState(false);

  const params = useParams() as { id: string };
  const dispatch = useDispatch();
  const { token } = useToken();

  useEffect(() => {
    dispatch(getPaperById({ token, id: params.id }));
  }, []);

  const paper = useSelector(selectSelectedPaper) as ArticleForm;

  return (
    <div>
      {!isPublished && <CreateArticleHeader setPublish={setIsPublished} />}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 10px 190px;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          background-color: white;
          overflow: hidden;
        `}
      >
        {paper.id === params.id && (
          <EditCreateArticleForm
            initialData={paper}
            isPublished={isPublished}
            setPublish={setIsPublished}
            paperId={params.id}
          />
        )}
      </div>
    </div>
  );
}
