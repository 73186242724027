import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Group_Tabs } from '../../types/Group';

export const NAVIGATION_REDUCER_NAME = 'navigation';

type InitialStateType = {
  groupFeedTab: Group_Tabs;
};

const initialState: InitialStateType = {
  groupFeedTab: Group_Tabs.MOODS,
};

const navigationSlice = createSlice({
  name: NAVIGATION_REDUCER_NAME,
  initialState,
  reducers: {
    setGroupFeedTab(state, { payload: tab }: PayloadAction<Group_Tabs>) {
      state.groupFeedTab = tab;
    },
  },
});

export const { setGroupFeedTab } = navigationSlice.actions;
export default navigationSlice.reducer;
