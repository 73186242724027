import React, { Dispatch } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GraphPeriods } from '../../../../utils/enums';
import styles from '../dashboardStyles.module.css';
import { DownArrow } from 'images/arrows/DownArrow';
import { UpArrow } from 'images/arrows/UpArrow';
import { fromTo, transformDate } from './helper';

function PeriodMenu({
  selectedPeriod,
  setSelectedPeriod,
}: {
  selectedPeriod: GraphPeriods;
  setSelectedPeriod: Dispatch<React.SetStateAction<GraphPeriods>>;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (text?: GraphPeriods) => {
    setAnchorEl(null);
    if (text) {
      setSelectedPeriod(text);
    }
  };
  const { from, to } = fromTo(selectedPeriod);

  return (
    <div className={styles.periodWrapper}>
      <div className={styles.fromToBlock}>
        <div>{transformDate(from)}</div>
        <div>{transformDate(to)}</div>
      </div>
      <div className={styles.periodDropDownBtn} onClick={handleClick}>
        {selectedPeriod}
        <span style={{ display: 'flex', marginLeft: '6px' }}>{!anchorEl ? DownArrow() : UpArrow()}</span>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleClose(GraphPeriods.LAST_24_HOURS)}>{GraphPeriods.LAST_24_HOURS}</MenuItem>
        <MenuItem onClick={() => handleClose(GraphPeriods.LAST_WEEK)}>{GraphPeriods.LAST_WEEK}</MenuItem>
        <MenuItem onClick={() => handleClose(GraphPeriods.LAST_MONTH)}>{GraphPeriods.LAST_MONTH}</MenuItem>
      </Menu>
    </div>
  );
}

export default PeriodMenu;