import { useEffect, useRef, useState } from 'react';
import MessageHockPopUp from 'src/hooks/MessageHockPopUp';
import { landscapeIcon } from 'images/landscapeIcon';
import { closeWhiteCircleIcon } from 'images/closeWhiteCircleIcon';
import './styles.css';
import { uploadIcon } from 'images/uploadIcon';

export default function ImageMobileInput({ setImage, setImagePreview }: { setImage: any; setImagePreview: any }) {
  const [imageUpload, setImageUpload] = useState<any | null>(null);
  const [icon, setIcon] = useState(false);
  const inputRef = useRef(null);

  const notify = (error: string) => {
    error.length && MessageHockPopUp({ message: error, timeOut: 4000, type: 'error', size: 'small' });
  };

  useEffect(() => {
    if (imageUpload && !imageUpload?.name) {
      notify('Not a correct image format');
      // @ts-ignore
      inputRef.current.value = null;
      setImageUpload(null);
      return;
    }
    if (imageUpload?.size > 5100000) {
      notify('The size of image should be less than 5 Mb');
      // @ts-ignore
      inputRef.current.value = null;
      setImageUpload(null);
      return;
    }
    const isImage = imageUpload?.type.split('/')[0];
    if (imageUpload && isImage !== 'image') {
      notify('Only images files');
      // @ts-ignore
      inputRef.current.value = null;
      setImageUpload(null);
      return;
    }
    if (imageUpload) {
      setImage(imageUpload);
      const objectUrl = URL.createObjectURL(imageUpload);
      setImagePreview(objectUrl as any);
    }
  }, [imageUpload]);

  return (
    <div className={'mobileImageInputMain'}>
      <div className={'mobileImageInputBlock'}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {landscapeIcon()}
          <input
            className="imageInputMobile"
            type="file"
            ref={inputRef}
            onChange={(e) => {
              setImageUpload(e.target.files ? e.target.files[0] : null);
              setIcon(true);
            }}
          />
        </div>

        <span
          onClick={() => {
            if (inputRef.current) {
              if (icon) {
                setIcon(false);
                // @ts-ignore
                inputRef.current.value = null;
                setImagePreview('')

              } else {
                // @ts-ignore
                inputRef.current.click();
              }
            }
          }}
        >
          {icon ? closeWhiteCircleIcon() : uploadIcon()}
        </span>
      </div>
    </div>
  );
}
