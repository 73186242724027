import React from 'react';
import { NewlineText } from './textHelper';
import styles from './styles.module.css';

function WhatIsVext() {
  const text =
    'VEXT is mental health and wellness with friends. \n' +
    '\n' +
    'At it\'s most simple, users come to VEXT to create or join small groups. These groups consist of folks we already know and are connected to in real life. Each day you check into a group you can share your mood. This allows you to track your moods as it relates to your various interest groups. As well, when your friends, teammates, peers, colleagues, unit members, classmates, etc. check in with their mood you can see how your group is feeling. (VEXT aggregates the moods to provide an ""average sentiment"" across all group members, so your mood stays anonymous.) Group members only see the group\'s averaged mood. Your mood is private to you for you to track.\n' +
    '\n' +
    '    VEXT is a space for expressing your whole self - especially your moods and emotions - with your IRL friends, colleagues, classmates, teammates, and others. VEXT is a safe space for asking questions, posting ideas, getting help with projects/assignments, sharing our victories, traumas, happynesses, sadnesses, and more. Annonymously share your moods so that you can see how others around you are feeling.\n' +
    '\n' +
    "    There's much more to VEXT than this, including the ability to reach out for help to counselors, discuss projects, assignments, shifts, tours, etc. without worrying about being spied upon by administrators, officers, or bosses. Track your groups moods, ask for help anonymously (by using incognito mode), and much more.";
  return <div className={styles.textBlock}>{NewlineText(text)}</div>;
}

export default WhatIsVext;


 