export const gifIcon = () => (
  <svg width="22" height="22" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.76422 14.3428H10.5699C13.7414 14.3428 15.0099 13.0743 15.0099 9.90283V6.09712C15.0099 2.92569 13.7414 1.65712 10.5699 1.65712H6.76422C3.59279 1.65712 2.32422 2.92569 2.32422 6.09712V9.90283C2.32422 13.0743 3.59279 14.3428 6.76422 14.3428Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.76467 6.7314C7.46528 6.7314 8.03324 6.16344 8.03324 5.46283C8.03324 4.76222 7.46528 4.19426 6.76467 4.19426C6.06405 4.19426 5.49609 4.76222 5.49609 5.46283C5.49609 6.16344 6.06405 6.7314 6.76467 6.7314Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.74902 12.4083L5.87605 10.3088C6.37714 9.97267 7.10022 10.0107 7.55057 10.3976L7.75988 10.5816C8.25462 11.0066 9.05382 11.0066 9.54857 10.5816L12.1872 8.31719C12.6819 7.89222 13.4811 7.89222 13.9759 8.31719L15.0098 9.20519"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
