import { IconCheck } from 'images/checked/IconCheck';
import { dots3Icon } from 'images/dots3Icon';
import React, { useEffect, useState } from 'react';

import styles from './styles.module.css';

import { useToken } from '../../../../cookies';
import { getAnonymousImage } from '../../../../helpers/anonymousGenerator';
import { userNameFromAllParams } from '../../../../helpers/userName';
import MessageHockPopUp from '../../../../hooks/MessageHockPopUp';
import { ReportsApiService } from '../../../../services/report.service';
import { Report, REPORT_STATUS } from '../../../../types/Reports';
import { ChatListPreview } from '../../../../types/User';
import { NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';

function NavConversation({
  user,
  isIncognito,
  chatName,
  openedChat,
}: {
  user: any;
  isIncognito: boolean;
  chatName?: string;
  openedChat?: ChatListPreview;
}) {
  const [chatReport, setChatReport] = useState<Report | null>(null);
  const { token } = useToken();

  useEffect(() => {
    if (openedChat?.report_id) {
      ReportsApiService.getReport(token, openedChat?.report_id).then((report) => {
        if (report.data) {
          setChatReport(report.data);
        }
      });
    } else {
      setChatReport(null);
    }
  }, [openedChat]);

  const makeReportResolved = () => {
    if (chatReport) {
      if (chatReport?.report_status !== 'RESOLVED') {
        ReportsApiService.updateReport(token, {
          ...chatReport,
          report_author: chatReport.report_author.id!,
          report_status: REPORT_STATUS.RESOLVED,
        }).then((r) => {
          if (r.ok && chatReport) {
            setChatReport({ ...chatReport, report_status: REPORT_STATUS.RESOLVED });
            MessageHockPopUp({
              message: 'Report status set as resolved!',
              timeOut: 3000,
              type: 'success',
              size: 'small',
            });
          }
        });
      } else {
        MessageHockPopUp({
          message: 'This conversation already is marked as resolved!',
          timeOut: 3000,
          type: 'warning',
          size: 'small',
        });
      }
    } else {
      MessageHockPopUp({
        message: 'Invalid chat-report id!',
        timeOut: 3000,
        type: 'error',
        size: 'small',
      });
    }
  };

  return (
    <div className={styles.conversationNav}>
      <div className={styles.navLeft}>
        {isIncognito && chatName ? (
          getAnonymousImage(chatName)
        ) : (
          <img className={styles.userImg} src={user?.profile?.image_url || NO_USER_AVATAR} alt="" />
        )}

        <div>
          <div className={styles.userName}>
            {isIncognito
              ? chatName
              : userNameFromAllParams(user?.username, user?.profile?.first_name, user?.profile?.last_name)}
          </div>
          <div
            style={{
              color: '#666666',
              fontSize: '12px',
            }}
          >
            {/*TODO... add correct time*/}
            {/*{calcCreatedTime('1/30/2023')}*/}
          </div>
        </div>
      </div>

      <div className={styles.navRight}>
        {/*<div>{infoIcon('#54B1FD')}</div>*/}
        <div
          onClick={makeReportResolved}
          className={styles.stabilizedBtn}
          style={{ backgroundColor: chatReport?.report_status !== 'RESOLVED' ? '#f5cb2c' : undefined }}
        >
          {chatReport?.report_status === 'RESOLVED' && IconCheck()}{' '}
          {chatReport?.report_status !== 'RESOLVED' ? 'Stabilize' : 'Stabilized'}
        </div>
        <div>{dots3Icon('#757575', '28', '28')}</div>
      </div>
    </div>
  );
}

export default NavConversation;
