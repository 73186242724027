import React, { MutableRefObject } from 'react';
import styles from './layoutStyles.module.css';
import classNames from 'classnames';

interface PopupProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  ref?: MutableRefObject<HTMLDivElement | null>;
}

function CostumePopup(props: PopupProps) {
  const popupClassName = classNames(styles.popUpBody, props.className);

  return (
      <div className={popupClassName} style={props.style}>
        {props.children}
      </div>
  );
}

export default CostumePopup;