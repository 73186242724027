import { vectorDown } from 'images/arrows/vectorDown';
import { vectorUp } from 'images/arrows/vectorUp';
import { IconCheck } from 'images/checked/IconCheck';
import { emergencyIcon } from 'images/emergencyIcon';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';

import { useToken } from '../../../../cookies';
import { generateRandomNicknameId } from '../../../../helpers/anonymousGenerator';
import { calcCreatedTime } from '../../../../helpers/date';
import { userNameFromAllParams } from '../../../../helpers/userName';
import MessageHockPopUp from '../../../../hooks/MessageHockPopUp';
import { EmergencyService } from '../../../../services/emergencies.service';
import { ChatService } from '../../../../services/socket.service';
import { selectUserId } from '../../../../store/auth';
import { EmergencyObj } from '../../../../types/Emergencies';
import { User } from '../../../../types/User';
import { NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';

const UserNameAndText = (user: User | null, isCreatedBySystem: boolean) => {
  if (!user) {
    return <span>This user send an incognito request</span>;
  } else {
    return (
      <div>
        <b>{userNameFromAllParams(user.username, user.profile?.first_name, user.profile?.last_name)}</b>{' '}
        {isCreatedBySystem
          ? ' Has low mood for several days'
          : ' pressed the emergency button. The student chose to display his real data. '}
      </div>
    );
  }
};

function EmergencyHead({
  emergency,
  isEmergencyBlockOpen,
  setEmergencyBlockOpen,
}: {
  emergency: EmergencyObj;
  isEmergencyBlockOpen: boolean;
  setEmergencyBlockOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [isButtonTakeAction, setIsButtonTakeAction] = useState<User | null>(emergency.handler);
  const { token } = useToken();
  const loggedUserId = useSelector(selectUserId);
  const { user, created_at: createdAt, handler: isNew, updated_at: updatedAt } = emergency;

  const takeActionRequest = () => {
    //TODO emergency
    EmergencyService.updateEmergency(token, emergency.id, loggedUserId).then((r) => {
      if (r.ok) {
        if (emergency.user?.id || emergency.id) {
          const data = {
            token,
            name: generateRandomNicknameId(),
            emergencyId: emergency.id,
            ...(emergency.is_created_by_system && { target_must_accept: true, notification: true }),
          };
          ChatService.initiateAEmergencyConversation(data)
            .then((res) => {
              setIsButtonTakeAction(r.data.handler);
              MessageHockPopUp({
                message: 'Operation completed, conversation is opened in the chat!',
                timeOut: 5000,
                type: 'success',
              });
            })
            .catch(() => {
              MessageHockPopUp({
                message: 'Something went wrong!',
                timeOut: 4000,
                type: 'error',
              });
            });
        }
      }
    });
    setIsButtonTakeAction(null);
  };

  return (
    <div
      className={styles.emergencyBlockHeader}
      style={{ borderBottom: isEmergencyBlockOpen ? '1px solid #BEBEBE96' : '' }}
    >
      <img
        className={styles.emergencyImage}
        src={user?.profile?.image_url || NO_USER_AVATAR}
        alt=""
        height={60}
        width={60}
      />
      <div className={styles.userNameInfoBlock}>
        <div>{UserNameAndText(user, emergency.is_created_by_system)}</div>
        <div>{updatedAt ? calcCreatedTime(updatedAt) : '--:--'}</div>
      </div>
      <div>
        <div className={styles.columnInfoHdr}>Group:</div>
        <div>No Data</div>
      </div>
      <div className={styles.columnInfo}>
        <div className={styles.columnInfoHdr}>Type:</div>
        <div className={styles.columnInfoTypeEmoji}>{emergencyIcon('24', '24', 'red')} emergency!</div>
      </div>
      <div className={styles.columnInfo}>
        <div className={styles.columnInfoHdr}>Status:</div>
        {!isNew ? (
          <div className={styles.columnInfoStatusNew}>New</div>
        ) : (
          <div className={styles.columnInfoStatusMonitoring}>Active monitoring</div>
        )}
      </div>
      {!isButtonTakeAction ? (
        <div className={styles.takeActionBtn} onClick={takeActionRequest}>
          {IconCheck()}Take action
        </div>
      ) : (
        <div className={styles.isResponsibleTrue}>
          <div className={styles.columnInfo}>
            <div className={styles.columnInfoHdr}>Responsible person:</div>
            <div className={styles.responsibleUser}>
              <img
                src={isButtonTakeAction.profile?.image_url || NO_USER_AVATAR}
                className={styles.emergencyImage}
                alt=""
                height={30}
                width={30}
              />
              <div className={styles.responsibleUserInfo}>
                <div>
                  {userNameFromAllParams(
                    isButtonTakeAction.username,
                    isButtonTakeAction.profile?.first_name,
                    isButtonTakeAction.profile?.last_name,
                    isButtonTakeAction.profile?.nickname,
                  )}
                </div>
                <div>{isButtonTakeAction.profile?.major || isButtonTakeAction.profile?.institute}</div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div style={{ cursor: 'pointer' }} onClick={() => setEmergencyBlockOpen(!isEmergencyBlockOpen!)}>
        {isEmergencyBlockOpen ? vectorUp('black') : vectorDown()}
      </div>
    </div>
  );
}

export default EmergencyHead;
