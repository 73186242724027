import React from 'react';
import { User } from '../../../../types/User';
import PeopleActions from './PeopleActions';
import { userNameFromAllParams } from '../../../../helpers/userName';
import { NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';
import styles from './peopleStatisticsStyles.module.css';

function Person({ person }: { person: User }) {
  const { profile } = person;
  return (
    <div className={styles.person}>
      <div className={styles.person}>
        <img className={styles.personIcon} src={profile?.image_url || NO_USER_AVATAR} alt="" />
        <div className={styles.personInfoBlock}>
          <span className={styles.personName}>
            {userNameFromAllParams(person.username, profile?.first_name, profile?.last_name, profile?.nickname)}
          </span>
          <span>
            <span className={styles.education}>{profile?.major}</span>
            {profile?.major && profile?.institute && <span> and </span>}
            <span className={styles.education}>{profile?.institute}</span>
            {profile?.faculty && <span> and </span>}
            <span className={styles.education}>{profile?.faculty}</span>
          </span>
          <span className={styles.domain}>{person.domain}</span>
        </div>
        <PeopleActions person={person} />
      </div>
    </div>
  );
}

export default Person;
