import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { SmileIcon } from 'images/SmileIcon';
import { PlaneIcon } from 'images/PlaneIcon';
import { css } from 'styled-components/macro';
import Picker from 'emoji-picker-react';
import { useDispatch, useSelector } from 'react-redux';
import { useToken } from '../../../cookies';
import { IComment, Paper } from '../../../types/Paper';
import { selectSelectedPaper } from '../../../store/articles/selector';
import { createComment } from '../../../store/articles';
import { useOnClickOutside } from 'usehooks-ts';
import { selectUserId } from '../../../store/auth';
import styles from './CommentInput.module.css';

const defaultStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 158,
      width: '100%',
    },
    highlighter: {
      maxHeight: '146px',
      padding: '0px',
    },
    input: {
      padding: '0px 0px',
      border: 'none',
      outline: 'none',
      maxHeight: '146px',
    },
  },

  '&singleLine': {
    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      zIndex: '1000',
      backgroundColor: 'white',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      '&focused': {
        backgroundColor: '#cee5cf',
      },
    },
  },
};

export default function CommentInput(props: {
  comment: IComment;
  setOpenReply: Dispatch<SetStateAction<boolean>>;
  setCommentListOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [isEmojiVisible, setIsEmojiVisible] = useState(false);
  const [repliedMessage, setRepliedMessage] = useState('');
  const paper = useSelector(selectSelectedPaper) as Paper;
  const loggedUserId = useSelector(selectUserId);

  const ref = useRef(null);
  const { token } = useToken();
  const dispatch = useDispatch();
  const handleClickOutside = () => {
    setIsEmojiVisible(false);
  };
  const mySet1 = new Set();
  paper.comments.forEach((comm) => {
    if (comm.author.profile.nickname) {
      mySet1.add(JSON.stringify({ id: comm.author.id, display: comm.author.profile.nickname }));
    }
  });

  const options: Array<{ id: string; display: string }> = [];
  mySet1.forEach((el) => {
    options.push(JSON.parse(el as string));
  });

  const getFirstFivePerson = () => {
    let newList = options;
    const [, regex] = repliedMessage.match(/@([\w\-&]+)/i) ?? [];
    if (regex && regex?.length < 3) {
      newList = options.filter((el) => el.display.includes(regex));
    }

    return newList.slice(0, 5) || [];
  };
  useOnClickOutside(ref, handleClickOutside);

  const replayAComment = () => {
    if (repliedMessage.length) {
      dispatch(
        createComment({
          token,
          articleId: paper.id,
          comment: repliedMessage,
          userId: loggedUserId,
          repliedToCommentId: props.comment.id,
        }),
      );
      setRepliedMessage('');
      props.setOpenReply(false);
      props.setCommentListOpen(true);
    }
  };

  return (
    <div
      css={css`
        height: 226px;
        border: 1px solid #75757594;
        border-radius: 24px;
        margin: 30px 10px 30px 0px;
      `}
    >
      <div
        css={css`
          padding: 8px 20px 0px 20px;
        `}
      >
        <MentionsInput
          value={repliedMessage}
          placeholder="Use @Name to reply"
          onChange={(e: { target: { value: string } }) => setRepliedMessage(e.target.value)}
          style={defaultStyle}
        >
          <Mention trigger="@" data={getFirstFivePerson()} className={styles.defaultMentionStyle} />
        </MentionsInput>
      </div>

      <div
        css={css`
          display: flex;
          height: 26%;
          align-items: flex-end;
          justify-content: end;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: flex-end;
            justify-content: end;
            margin-right: 10px;
            margin-bottom: 4px;
            width: 100%;
            cursor: pointer;
          `}
        >
          <div
            css={css`
              margin-bottom: 12px;
            `}
            ref={ref}
          >
            {isEmojiVisible && (
              <Picker
                onEmojiClick={(e, obj) => {
                  e.stopPropagation();
                  setRepliedMessage(repliedMessage + obj.emoji);
                }}
              />
            )}
          </div>
          <div
            css={css`
              display: flex;
              margin-bottom: 4px;
              font-size: 20px;
              padding: 4px;
            `}
            onClick={(e) => {
              e.stopPropagation();
              setIsEmojiVisible(!isEmojiVisible);
            }}
          >
            {SmileIcon()}
          </div>
          <div
            css={css`
              display: flex;
              align-items: flex-end;
              justify-content: end;
              font-size: 20px;
              margin-bottom: 4px;
              padding: 4px;
              border-radius: 8px;
              width: 30px;
              height: 24px;
            `}
          >
            <div
              onClick={() => replayAComment()}
              css={css`
                display: flex;
                font-size: 20px;
                width: 24px;
                transition: 0.3s;

                &:hover {
                  transition: 0.3s;
                  margin-bottom: 1px;
                  margin-right: 1px;
                }
              `}
            >
              {PlaneIcon()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
