import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DownArrow } from 'images/arrows/DownArrow';
import { UpArrow } from 'images/arrows/UpArrow';
import React, { Dispatch, SetStateAction, useState } from 'react';


import styles from './sortTabStyles.module.css';

import { IOSSwitch } from '../../../utils/componenets';
import { PeopleOrGroupsSwitch, SortByMoods } from '../../../utils/enums';

function SortTab({
  setPeopleOrGroupsSwitch,
  setSortByMoods,
}: {
  setPeopleOrGroupsSwitch: Dispatch<SetStateAction<PeopleOrGroupsSwitch>>;
  setSortByMoods: Dispatch<SetStateAction<SortByMoods | string>>;
}) {
  const [peopleOrGroups, setPeopleOrGroups] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (text?: SortByMoods) => {
    setAnchorEl(null);
    if (text) {
      setSortByMoods(text);
    } else {
      setSortByMoods('');
    }
  };

  return (
    <div className={styles.sortTab}>
      <div className={styles.peopleGroupsBlock}>
        <div style={{ color: peopleOrGroups ? 'gray' : '#61B779' }}>People</div>
        <FormGroup style={{ width: '26%' }}>
          <FormControlLabel
            control={
              <IOSSwitch
                value={'no-icon'}
                clr={'#61B779'}
                sx={{ m: 1, clr: 'red' }}
                onChange={(e: { target: { checked: boolean } }) => {
                  if (e.target.checked) {
                    setPeopleOrGroupsSwitch(PeopleOrGroupsSwitch.GROUPS);
                  } else {
                    setPeopleOrGroupsSwitch(PeopleOrGroupsSwitch.PEOPLE);
                  }
                  setPeopleOrGroups(e.target.checked);
                }}
                checked={peopleOrGroups}
              />
            }
            label=""
          />
        </FormGroup>
        <div style={{ color: peopleOrGroups ? '#61B779' : 'gray' }}>Groups</div>
      </div>

      <div>
        <div className={styles.sortByMoodsBtn} onClick={handleClick}>
          Sort by moods
          <span style={{ display: 'flex', marginLeft: '6px' }}>{!anchorEl ? DownArrow() : UpArrow()}</span>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={() => handleClose()}>All</MenuItem>
          <MenuItem onClick={() => handleClose(SortByMoods.GOOD_MOODS_FIRST)}>Good moods first</MenuItem>
          <MenuItem onClick={() => handleClose(SortByMoods.BAD_MOODS_FIRST)}>Bad moods first</MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default SortTab;
