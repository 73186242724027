export const calendarIcon = (color = '#EDEFF0') => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.41836 16H13.5828C14.7532 16 15.7043 15.0489 15.7043 13.8785V3.30665C15.7043 2.13628 14.7532 1.18516 13.5828 1.18516H12.7413V0.592582C12.7413 0.266652 12.4746 0 12.1487 0C11.8228 0 11.5561 0.266652 11.5561 0.592582V1.18516H4.44505V0.592582C4.44505 0.266652 4.17834 0 3.85241 0C3.52648 0 3.25983 0.266652 3.25983 0.592582V1.18516H2.41836C1.24799 1.18516 0.296875 2.13628 0.296875 3.30665V13.8785C0.296875 15.0489 1.24799 16 2.41836 16V16ZM1.48209 3.30665C1.48209 2.79111 1.90282 2.37038 2.41836 2.37038H3.25983V2.96296C3.25983 3.28889 3.52648 3.55554 3.85241 3.55554C4.17834 3.55554 4.445 3.28889 4.445 2.96296V2.37038H11.5561V2.96296C11.5561 3.28889 11.8228 3.55554 12.1487 3.55554C12.4746 3.55554 12.7413 3.28889 12.7413 2.96296V2.37038H13.5828C14.0983 2.37038 14.519 2.79111 14.519 3.30665V13.8785C14.519 14.3941 14.0983 14.8148 13.5828 14.8148H2.41836C1.90282 14.8148 1.48209 14.3941 1.48209 13.8785V3.30665Z"
      fill={color}
    />
    <path
      d="M4.59229 6.81483C5.00138 6.81483 5.33302 6.48319 5.33302 6.0741C5.33302 5.66501 5.00138 5.33337 4.59229 5.33337C4.1832 5.33337 3.85156 5.66501 3.85156 6.0741C3.85156 6.48319 4.1832 6.81483 4.59229 6.81483Z"
      fill={color}
    />
    <path
      d="M7.85206 6.81483C8.26115 6.81483 8.59278 6.48319 8.59278 6.0741C8.59278 5.66501 8.26115 5.33337 7.85206 5.33337C7.44296 5.33337 7.11133 5.66501 7.11133 6.0741C7.11133 6.48319 7.44296 6.81483 7.85206 6.81483Z"
      fill={color}
    />
    <path
      d="M11.1118 6.81477C11.5209 6.81477 11.8525 6.48313 11.8525 6.07404C11.8525 5.66495 11.5209 5.33331 11.1118 5.33331C10.7027 5.33331 10.3711 5.66495 10.3711 6.07404C10.3711 6.48313 10.7027 6.81477 11.1118 6.81477Z"
      fill={color}
    />
    <path
      d="M4.59229 9.77772C5.00138 9.77772 5.33302 9.44608 5.33302 9.03699C5.33302 8.6279 5.00138 8.29626 4.59229 8.29626C4.1832 8.29626 3.85156 8.6279 3.85156 9.03699C3.85156 9.44608 4.1832 9.77772 4.59229 9.77772Z"
      fill={color}
    />
    <path
      d="M7.85206 9.77772C8.26115 9.77772 8.59278 9.44608 8.59278 9.03699C8.59278 8.6279 8.26115 8.29626 7.85206 8.29626C7.44296 8.29626 7.11133 8.6279 7.11133 9.03699C7.11133 9.44608 7.44296 9.77772 7.85206 9.77772Z"
      fill={color}
    />
    <path
      d="M11.1118 9.77772C11.5209 9.77772 11.8525 9.44608 11.8525 9.03699C11.8525 8.6279 11.5209 8.29626 11.1118 8.29626C10.7027 8.29626 10.3711 8.6279 10.3711 9.03699C10.3711 9.44608 10.7027 9.77772 11.1118 9.77772Z"
      fill={color}
    />
    <path
      d="M4.59229 12.7407C5.00138 12.7407 5.33302 12.4091 5.33302 12C5.33302 11.5909 5.00138 11.2593 4.59229 11.2593C4.1832 11.2593 3.85156 11.5909 3.85156 12C3.85156 12.4091 4.1832 12.7407 4.59229 12.7407Z"
      fill={color}
    />
    <path
      d="M7.85206 12.7407C8.26115 12.7407 8.59278 12.4091 8.59278 12C8.59278 11.5909 8.26115 11.2593 7.85206 11.2593C7.44296 11.2593 7.11133 11.5909 7.11133 12C7.11133 12.4091 7.44296 12.7407 7.85206 12.7407Z"
      fill={color}
    />
    <path
      d="M11.1118 12.7407C11.5209 12.7407 11.8525 12.4091 11.8525 12C11.8525 11.5909 11.5209 11.2593 11.1118 11.2593C10.7027 11.2593 10.3711 11.5909 10.3711 12C10.3711 12.4091 10.7027 12.7407 11.1118 12.7407Z"
      fill={color}
    />
  </svg>
);
