import { useState } from 'react';
import { css } from 'styled-components/macro';
import EditCreateArticleForm from '../../components/EditCreateArticle/EditCreateArticleForm';
import CreateArticleHeader from '../../components/Header/CreateArticleHeader';
import { useIsMobile, useIsTablet } from '../../utils/styles.utils';
import EditCreateArticleMobileForm from '../../components/EditCreateArticle/EditCreateArticleMobileForm';

export default function EditCreateArticlePage() {
  const [isPublished, setIsPublished] = useState(false);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return (
    <div
      css={css`
        background-color: ${isMobile || isTablet ? '#131313' : '#f2f2f2'};
        height: 100vh;
      `}
    >
      {!isPublished && !isMobile && !isTablet && <CreateArticleHeader setPublish={setIsPublished} />}
      {isMobile || isTablet ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            background-color: #131313;
            color: white;
          `}
        >
          <EditCreateArticleMobileForm />
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            padding: 10px 190px;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            background-color: white;
            overflow: hidden;
          `}
        >
          <EditCreateArticleForm initialData={null} isPublished={isPublished} setPublish={setIsPublished} />
        </div>
      )}
    </div>
  );
}
