export const noChats = () => (
  <svg width="518" height="356" viewBox="0 0 318 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="318" height="156" fill="white" />
    <rect width="56" height="56" transform="translate(90.5 40)" fill="white" />
    <path
      d="M137.33 47.6068C137.905 47.6074 138.457 47.8362 138.864 48.243C139.27 48.6499 139.499 49.2014 139.5 49.7768V76.0851C139.499 76.6604 139.27 77.212 138.864 77.6188C138.457 78.0257 137.905 78.2545 137.33 78.2551H104.626L103.926 79.2421L97.4998 88.3444V49.9401C97.4998 49.3213 97.7457 48.7278 98.1833 48.2902C98.6208 47.8526 99.2143 47.6068 99.8332 47.6068H137.33ZM137.33 45.2734H99.8332C98.5955 45.2734 97.4085 45.7651 96.5333 46.6403C95.6582 47.5154 95.1665 48.7024 95.1665 49.9401V88.3841C95.1665 88.6921 95.2275 88.997 95.3459 89.2814C95.4643 89.5657 95.6379 89.8237 95.8565 90.0407C96.0752 90.2576 96.3346 90.4291 96.6198 90.5452C96.9051 90.6614 97.2105 90.7199 97.5185 90.7174C97.8349 90.7174 98.1477 90.6516 98.4373 90.5242C98.7269 90.3969 98.9869 90.2108 99.2008 89.9778L105.835 80.5978H137.335C137.926 80.5972 138.512 80.4799 139.059 80.2527C139.605 80.0256 140.101 79.6929 140.519 79.2738C140.937 78.8547 141.268 78.3574 141.493 77.8102C141.719 77.2631 141.834 76.6769 141.833 76.0851V49.7768C141.833 48.5824 141.359 47.437 140.514 46.5924C139.67 45.7479 138.524 45.2734 137.33 45.2734Z"
      fill="#757575"
    />
    <path
      d="M116.668 71.6762C116.654 71.4276 116.691 71.1788 116.777 70.9451C116.863 70.7114 116.996 70.4977 117.168 70.3173C117.339 70.137 117.546 69.9937 117.775 69.8963C118.004 69.7989 118.251 69.7496 118.5 69.7512C118.999 69.7686 119.471 69.9789 119.818 70.3378C120.164 70.6967 120.358 71.1761 120.358 71.6751C120.358 72.174 120.164 72.6535 119.818 73.0123C119.471 73.3712 118.999 73.5815 118.5 73.5989C118.251 73.6009 118.005 73.5518 117.775 73.4547C117.546 73.3576 117.339 73.2145 117.168 73.0343C116.996 72.8541 116.863 72.6406 116.777 72.407C116.691 72.1734 116.654 71.9247 116.668 71.6762ZM117.445 67.2429L117.009 52.3516H119.989L119.552 67.2429H117.445Z"
      fill="#757575"
    />
    <rect width="56" height="56" transform="translate(171.5 40)" fill="white" />
    <path
      d="M218.33 47.6068C218.905 47.6074 219.457 47.8362 219.864 48.243C220.27 48.6499 220.499 49.2014 220.5 49.7768V76.0851C220.499 76.6604 220.27 77.212 219.864 77.6188C219.457 78.0257 218.905 78.2545 218.33 78.2551H185.626L184.926 79.2421L178.5 88.3444V49.9401C178.5 49.3213 178.746 48.7278 179.183 48.2902C179.621 47.8526 180.214 47.6068 180.833 47.6068H218.33ZM218.33 45.2734H180.833C179.595 45.2734 178.409 45.7651 177.533 46.6403C176.658 47.5154 176.167 48.7024 176.167 49.9401V88.3841C176.166 88.6921 176.227 88.997 176.346 89.2814C176.464 89.5657 176.638 89.8237 176.857 90.0407C177.075 90.2576 177.335 90.4291 177.62 90.5452C177.905 90.6614 178.211 90.7199 178.519 90.7174C178.835 90.7174 179.148 90.6516 179.437 90.5242C179.727 90.3969 179.987 90.2108 180.201 89.9778L186.835 80.5978H218.335C218.926 80.5972 219.512 80.4799 220.059 80.2527C220.605 80.0256 221.101 79.6929 221.519 79.2738C221.937 78.8547 222.268 78.3574 222.493 77.8102C222.719 77.2631 222.834 76.6769 222.833 76.0851V49.7768C222.833 48.5824 222.359 47.437 221.514 46.5924C220.67 45.7479 219.524 45.2734 218.33 45.2734Z"
      fill="#757575"
    />
    <rect x="185.5" y="53" width="28" height="2" fill="#D9D9D9" />
    <rect x="185.5" y="58" width="28" height="2" fill="#D9D9D9" />
    <rect x="185.5" y="63" width="22" height="2" fill="#D9D9D9" />
    <rect x="185.5" y="68" width="22" height="2" fill="#D9D9D9" />
    <rect width="56" height="56" transform="translate(132.5 60)" fill="white" />
    <path
      d="M179.33 67.6068C179.905 67.6074 180.457 67.8362 180.864 68.243C181.27 68.6499 181.499 69.2014 181.5 69.7768V96.0851C181.499 96.6604 181.27 97.212 180.864 97.6188C180.457 98.0257 179.905 98.2545 179.33 98.2551H146.626L145.926 99.2421L139.5 108.344V69.9401C139.5 69.3213 139.746 68.7278 140.183 68.2902C140.621 67.8526 141.214 67.6068 141.833 67.6068H179.33ZM179.33 65.2734H141.833C140.595 65.2734 139.409 65.7651 138.533 66.6403C137.658 67.5154 137.167 68.7024 137.167 69.9401V108.384C137.166 108.692 137.227 108.997 137.346 109.281C137.464 109.566 137.638 109.824 137.857 110.041C138.075 110.258 138.335 110.429 138.62 110.545C138.905 110.661 139.211 110.72 139.519 110.717C139.835 110.717 140.148 110.652 140.437 110.524C140.727 110.397 140.987 110.211 141.201 109.978L147.835 100.598H179.335C179.926 100.597 180.512 100.48 181.059 100.253C181.605 100.026 182.101 99.6929 182.519 99.2738C182.937 98.8547 183.268 98.3574 183.493 97.8102C183.719 97.2631 183.834 96.6769 183.833 96.0851V69.7768C183.833 68.5824 183.359 67.437 182.514 66.5924C181.67 65.7479 180.524 65.2734 179.33 65.2734Z"
      fill="#757575"
    />
  </svg>
);
