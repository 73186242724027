import React, { ReactNode, useRef, useState } from 'react';
import { MenuOption } from '../types/Common';
import { removeArticle } from '../store/articles';
import { useToken } from '../cookies';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { REPORT_OPTIONS } from 'src/utils/constants';
import CostumePopup from 'src/components/layout/CostumePopUp';
import CloseYellowIcon from 'images/closeYellowIcon';
import useOutsideListener from 'src/hooks/useOutsideListener';
import { ReportsApiService } from 'src/services/report.service';
import { REPORT_TYPES } from 'src/types/Reports';
import styles from './MenuDropDownStyles.module.css';

export default function SimpleListMenu({ options, children }: { options: Array<MenuOption>; children: ReactNode }) {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [reportPopup, setReportPopup] = useState<{ firstStep: boolean; secondStep: boolean }>({
    firstStep: false,
    secondStep: false,
  });

  const [report, setReport] = useState<{
    report_reason_title: string;
    report_reason_description: string;
    report_object_id: string;
  }>({
    report_reason_title: '',
    report_reason_description: '',
    report_object_id: '',
  });
  const ref = useRef<HTMLDivElement | null>(null);
  const { token } = useToken();
  const dispatch = useDispatch();
  useOutsideListener(ref, () => setShowPopup(false));

  const handleClose = () => {
    setShowPopup(false);
    setReportPopup({ firstStep: false, secondStep: false });
  };

  const deleteArticle = (id: string) => {
    dispatch(removeArticle({ token, id }));
    setShowPopup(false);
  };

  const reportArticle = (id: string) => {
    setReport((prevState) => ({ ...prevState, report_object_id: id }));
    setReportPopup((prevState) => ({ ...prevState, firstStep: true }));
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, option: MenuOption) => {
    if (option.blockType === 'QUESTION') {
      switch (option.label) {
        case 'Delete':
          deleteArticle(option.blockId);
          break;
        case 'Report':
          reportArticle(option.blockId);
          break;
      }
    }
  };
  const _reportHandler = (selected: string) => {
    if (!reportPopup.secondStep) {
      setReportPopup((prevState) => ({ ...prevState, secondStep: true }));
    }
    if (!report.report_reason_title) {
      setReport((prevState) => ({ ...prevState, report_reason_title: selected }));
    }
    if (report.report_reason_title) {
      setReport((prevState) => ({ ...prevState, report_reason_description: selected }));
    }
  };

  const submitReportHandler = () => {
    ReportsApiService.createReport(token, {
      ...report,
      report_object_type: REPORT_TYPES.QUESTION,
    });
    handleClose();
  };

  console.log('report', report);
  console.log('reportPopup', reportPopup);

  return (
      <div>
        <div onClick={() => setShowPopup(true)}>{children}</div>
        <>
          {showPopup && (
              <CostumePopup
                  ref={ref}
                  className={styles.popup}
                  style={{
                    width: !reportPopup.firstStep && !reportPopup.secondStep ? '110px' : '85vw',
                    backgroundColor: reportPopup.firstStep || reportPopup.secondStep ? '#fff' : '',
                    color: reportPopup.firstStep || reportPopup.secondStep ? '#000' : '',
                    padding: reportPopup.firstStep || reportPopup.secondStep ? '14px 0' : '14px',
                  }}
              >
                {!reportPopup.firstStep ? (
                    options.map((option) => (
                        <div key={uuid()} onClick={(event) => handleMenuItemClick(event, option)}>
                          <div className={styles.menuItemWrapper}>
                            <option.icon pathFill="#fff" color="#fff" />
                            {option.label}
                          </div>
                        </div>
                    ))
                ) : (
                    <>
                      <div className={styles.reportHeaderWrapper} style={{ padding: '0 14px' }}>
                        <div className={styles.reportHeader}>
                          {!reportPopup.secondStep ? 'Flag for:' : 'Describe the problem:'}
                        </div>
                        <div className={styles.reportHeaderClose} onClick={handleClose}>
                          <CloseYellowIcon />
                        </div>
                      </div>
                      <hr className={styles.reportDivider} />
                      {reportPopup.firstStep && !reportPopup.secondStep ? (
                          <>
                            {REPORT_OPTIONS.map((option, index:number) => {
                              return (
                                  <div key={uuid()} className={styles.popupWrapper}>
                                    <div
                                        className={styles.popUpRow}
                                        onClick={() => _reportHandler(option.label)}
                                        style={{ padding: '0 14px' }}
                                    >
                                      <div>
                                        <option.icon width={20} height={20} />
                                      </div>
                                      <span>{option.label}</span>
                                    </div>
                                    {index !== REPORT_OPTIONS.length - 1 && <hr className={styles.reportDivider} />}
                                  </div>
                              );
                            })}
                          </>
                      ) : (
                          <>
                            <div className={styles.textareaWrapper}>
                      <textarea
                          cols={30}
                          rows={10}
                          placeholder="write a description here"
                          onChange={(e) => _reportHandler(e.target.value)}
                      />
                              <div className={styles.sendButton} onClick={submitReportHandler}>
                                Send
                              </div>
                            </div>
                          </>
                      )}
                    </>
                )}
              </CostumePopup>
          )}
        </>
      </div>
  );
}