import React, { useState } from 'react';
import { Dispatch } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { darkTheme, lightTheme, ReportPopover } from '../../../themes/themes';
import { useIsMobile } from '../../../utils/styles.utils';
import { useDispatch, useSelector } from 'react-redux';
import { inviteFriendsIcon } from 'images/inviteFriendsIcon';
import ReportIcon from 'images/reportIcon';
import { pinIcon } from 'images/pinIcon';
import { LogOutIcon } from 'images/LogOut';
import { ThemeProvider } from '@mui/material';
import Popover from '@mui/material/Popover';
import { deleteGroup, leaveGroup, pinAGroup, selectGroupById, unpinAGroup } from '../../../store/groups';
import { useToken } from '../../../cookies';
import { selectUserId } from '../../../store/auth';
import TrashcanIcon from 'images/trashbinIcon';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import CloseYellowIcon from 'images/closeYellowIcon';
import { ReportHandlerType, REPORT_TYPES } from '../../../types/Reports';
import { REPORT_OPTIONS } from 'src/utils/constants';
import { v4 as uuid } from 'uuid';
import {ReportsApiService} from "../../../services/report.service";
import styles from './styles.module.css';

export default function PopOverGroupMenu({
  anchorEl,
  setAnchorEl,
  isPinned,
  groupId,
}: {
  groupId: string;
  isPinned: boolean;
  anchorEl: HTMLDivElement | null;
  setAnchorEl: Dispatch<React.SetStateAction<HTMLDivElement | null>>;
}) {
  const dispatch = useDispatch();
  const loggedUserId = useSelector(selectUserId);
  const groupById = useSelector(selectGroupById(groupId));
  const { token } = useToken();
  const history = useHistory();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const isMobile = useIsMobile();
  const [reportPopup, setReportPopup] = useState<{ firstStep: boolean; secondStep: boolean }>({
    firstStep: false,
    secondStep: false,
  });
  const [report, setReport] = useState<Partial<ReportHandlerType> | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
    setReportPopup({ firstStep: false, secondStep: false });
  };

  const pinGroup = () => {
    dispatch(pinAGroup({ token, groupId }));
  };
  const unpinGroup = () => {
    dispatch(unpinAGroup({ token, groupId }));
  };

  const _leaveGroup = () => {
    dispatch(leaveGroup({ groupId, token, userId: loggedUserId }));
    handleClose();
  };

  const _deleteGroup = () => {
    dispatch(deleteGroup({ groupId, token }));
    handleClose();
  };

  const _reportGroup = () => {
    setReportPopup((prevState) => ({ ...prevState, firstStep: true }));
  };

  const _inviteFriends = () => {
    history.push({ pathname: `/invite-friend`, state: groupId });
    handleClose();
  };
  const reportHandler = (data: Partial<ReportHandlerType>) => {
    if (!reportPopup.secondStep) {
      setReportPopup((prevState) => ({ ...prevState, secondStep: true }));
    }

    if (data) {
      setReport((prevState) => ({ ...prevState, ...data }));
    }
  };

  const submitReportHandler = () => {
    ReportsApiService.createReport(token, {
      ...report,
      report_object_type: REPORT_TYPES.GROUP,
    });
    handleClose();
    setReport(null);
  };

  return (
    <div>
      <ThemeProvider theme={isMobile ? darkTheme : lightTheme}>
        <CSSTransition in={Boolean(anchorEl)} timeout={300} classNames="popover" unmountOnExit>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
          >
            {!reportPopup.firstStep && (
              <List sx={{ pt: 0 }}>
                <ListItem button onClick={_inviteFriends}>
                  <div className={styles.dropDownListIcon}>{inviteFriendsIcon()}</div>
                  Invite friends
                </ListItem>
                <ListItem onClick={_reportGroup} button>
                  <div className={styles.dropDownListIcon}>
                    <ReportIcon />
                  </div>
                  Report
                </ListItem>
                <ListItem
                  button
                  onClick={(e) => {
                    if (!isPinned) {
                      pinGroup();
                    } else {
                      unpinGroup();
                    }
                    handleClose();
                  }}
                >
                  <div className={styles.dropDownListIcon}>{pinIcon()}</div>
                  {isPinned ? 'Unpin' : 'Pin'}
                </ListItem>
                {groupById?.admin?.id !== loggedUserId &&
                  !!groupById?.users.find((user) => {
                    return user.id === loggedUserId;
                  }) && (
                    <ListItem onClick={_leaveGroup}>
                      <div className={styles.dropDownListIcon}>{LogOutIcon()}</div>
                      Leave group
                    </ListItem>
                  )}

                {groupById?.admin?.id === loggedUserId && (
                  <ListItem onClick={_deleteGroup}>
                    <div className={styles.dropDownListIcon}>
                      <TrashcanIcon color="#FFF" />
                    </div>
                    Delete group
                  </ListItem>
                )}
              </List>
            )}
            {reportPopup.firstStep && (
              <ThemeProvider theme={ReportPopover}>
                <List sx={{ pt: 0 }}>
                  <div className={styles.reportHeaderWrapper}>
                    <div className={styles.reportHeader}>
                      {!reportPopup.secondStep ? 'Flag for:' : 'Describe the problem:'}
                    </div>
                    <div className={styles.reportHeaderClose} onClick={handleClose}>
                      <CloseYellowIcon />
                    </div>
                  </div>
                  <hr className={styles.reportDivider} />
                  {!reportPopup.secondStep ? (
                    <>
                      {REPORT_OPTIONS.map((option: any) => {
                        return (
                          <div key={uuid()} >
                            <ListItem
                              onClick={() =>
                                reportHandler({
                                  report_object_id: groupId,
                                  report_reason_title: option.label,
                                })
                              }
                            >
                              <div style={{marginRight:'6px'}}>
                                <option.icon />
                              </div>
                              {option.label}
                            </ListItem>
                            <hr className={styles.reportDivider} />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className={styles.textareaWrapper}>
                      <textarea
                        cols={30}
                        rows={10}
                        placeholder="write a description here"
                        onChange={(e) =>
                          reportHandler({
                            report_reason_description: e.target.value,
                          })
                        }
                      />
                      <div className={styles.sendButton} onClick={submitReportHandler}>
                        Send
                      </div>
                    </div>
                  )}
                </List>
              </ThemeProvider>
            )}
          </Popover>
        </CSSTransition>
      </ThemeProvider>
    </div>
  );
}
