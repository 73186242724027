import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as LogoSvg } from 'images/logo/Logo.svg';
import { ReactComponent as PencilSvg } from 'images/Pencil.svg';
import { ReactComponent as Vector } from 'images/Vector.svg';
import { ReactComponent as BellSvg } from 'images/Bell.svg';
import { Button } from '../layout/Button';
import SearchBar from '../Search/SearchBar';
import { UserDropdown } from '../User/UserDropdown';
import AskModal from '../AskModal/AskModal';
import { Bell2Icon } from 'images/Bell2Icon';
import NotificationsDropDown from './NotificationsDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationsListAsViewed } from 'src/store/notifications';
import { useOnClickOutside } from 'usehooks-ts';
import { selectIsNewNotificationAvailable } from '../../store/notifications/selector';
import style from './HeaderStyle.module.css';

export default function NavigationHeader() {
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [isAskModalOpen, setIsAskModalOpen] = useState(false);
  const [isNotificationDropDownOpen, setIsNewNotificationDropDownOpen] = useState(false);
  const [wasClickOutside, setWasClickOutside] = useState(false);
  const isNewNotifications = useSelector(selectIsNewNotificationAvailable);

  const handleClickOutside = () => {
    setWasClickOutside(true);
    setIsNewNotificationDropDownOpen(false);
  };
  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className={style.navHeaderMain}>
      <div className={style.navMainContainer}>
        <div>
          <LogoSvg width={100} height={52} style={{ cursor: 'pointer' }} onClick={() => history.push('/feed')} />
        </div>
        <div className={style.navRightContainer}>
          <SearchBar />
          <Button
            className={style.writePaperButton}
            icon={<PencilSvg width={24} height={24} />}
            onClick={() => history.push('/create')}
          >
            {'Write a paper'}
          </Button>
          <Button
            className={style.askButton}
            onClick={() => setIsAskModalOpen(true)}
            icon={<Vector width={12} height={24} />}
          >
            {'Ask'}
          </Button>
          <AskModal isOpen={isAskModalOpen} setIsOpen={setIsAskModalOpen} />
          <div className={style.bellButton}>
            {!isNewNotifications ? (
              <BellSvg
                style={{ margin: '0 24px' }}
                onClick={() => {
                  if (wasClickOutside) {
                    return setWasClickOutside(false);
                  }
                  setWasClickOutside(false);
                  setIsNewNotificationDropDownOpen((isNotificationDropDownOpen) => !isNotificationDropDownOpen);
                }}
              />
            ) : (
              <span
                style={{ margin: '0 24px', marginTop: '4px' }}
                onClick={() => {
                  dispatch(setNotificationsListAsViewed());
                  //TODO implement a better logic for this
                  if (wasClickOutside) {
                    return setWasClickOutside(false);
                  }
                  setWasClickOutside(false);
                  setIsNewNotificationDropDownOpen((isNotificationDropDownOpen) => !isNotificationDropDownOpen);
                }}
              >
                {Bell2Icon()}
              </span>
            )}

            <UserDropdown />
            {isNotificationDropDownOpen && (
              <div ref={ref}>
                <NotificationsDropDown />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
