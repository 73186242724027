import { User } from '../types/User';

export const userNameFromAllParams = (
  userName?: string | null,
  firstName?: string,
  lastName?: string,
  nickName?: string,
) => {
  if (userName) {
    return `${userName}`;
  }
  if (firstName || lastName) {
    return `${firstName || ''} ${lastName || ''}`;
  }
  if (nickName) {
    return `${nickName}`;
  }
  return 'No name';
};

export const getUserNameFromList = (usersList: Array<User>, userId: string) => {
  const user = usersList.find((user) => user.id === userId);
  if (user?.profile) {
    return user.profile.first_name;
  }
  return 'No name';
};

export const getUserImageFromList = (usersList: Array<User>, userId: string) => {
  const user = usersList.find((user) => user.id === userId);
  if (user?.profile) {
    return user.profile.image_url || '';
  }
  return '';
};