import React from 'react';
import { NewlineText } from './textHelper';
import styles from './styles.module.css';

function VextPoints() {
  const text =
    'If you create the group, you are responsible for moderating the content within the group. \n' +
    '\n' +
    'There is a system for users to flag content. Flagged content will be reviewed and, when appropriate, removed. If a user is flagged multiple times, it could lead to their removal from the platform. ';
  return <div className={styles.textBlock}>{NewlineText(text)}</div>;
}

export default VextPoints;
