import { css } from 'styled-components/macro';
import { useState } from 'react';
import { PencilIcon } from 'images/PencilIcon';
import Modal from 'react-modal';
import './style.css';

Modal.setAppElement('#root');

export const Security = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [, setNewPassword] = useState('');

  // Change password is not implemented yet

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        margin-bottom: 300px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: start;
          margin-top: 10px;
        `}
      >
        <div
          css={css`
            font-weight: 700;
            font-family: sans-serif;
            margin-bottom: 14px;
          `}
        >
          Password
        </div>
        <span
          css={css`
            width: 760px;
            font-size: 16px;
            line-height: 150%;
            font-family: sans-serif;
            color: #757575;
          `}
        >
          Strong passwords are longer than eight characters, are hard to guess and contain a variety of characters,
          numbers and special symbols. The best ones can be difficult to remember, especially if you are using a
          distinct login for every site (which is recommended).{' '}
        </span>
      </div>
      <div
        css={css`
          margin: 6px;
          display: flex;
          padding: 6px;
          justify-content: end;
        `}
      >
        <div
          // onClick={openModal}
          css={css`
            display: flex;
            align-items: center;
            border: 1px solid #757575;
            border-radius: 14px;
            height: 30px;
            font-family: sans-serif;
            color: #757575;
            font-weight: 700;
            padding: 6px 10px;
            cursor: pointer;
          `}
        >
          <div
            css={css`
              margin-right: 2px;
              margin-top: 2px;
            `}
          >
            {PencilIcon('#757575')}
          </div>
          Change password
        </div>
        <Modal
          isOpen={isOpen}
          onRequestClose={openModal}
          contentLabel="My dialog"
          className="mymodal"
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 360px;
          `}
        >
          <div
            css={css`
              font-family: sans-serif;
              color: #757575;
              margin-bottom: 6px;
              margin-bottom: 6px;
            `}
          >
            Insert new password
          </div>

          <input
            type="password"
            css={css`
              margin-bottom: 10px;
              border: 0.2px solid #757575;

              &:focus {
                outline: none;
              }
            `}
            onChange={(e) => setPasswordInput(e.target.value)}
          />
          <button
            css={css`
              border: 0.2px solid #757575;
              border-radius: 4px;
              height: 30px;
              cursor: pointer;
              background-color: white;
              color: #676767;
            `}
            onClick={() => setNewPassword(passwordInput)}
          >
            {' '}
            Save
          </button>
        </Modal>
      </div>
    </div>
  );
};
