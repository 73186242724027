export const vectorDown = () => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.10742 0.642822L7.00028 6.53568L12.8931 0.642822"
      stroke="#252525"
      strokeWidth="1.17857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
