import React from 'react';
import { User } from '../../types/User';
import ArrowBackButton from '../../components/mobile/Buttons/ArrowBackButton';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../store/auth';
import { NO_USER_AVATAR } from '../../utils/constantImagesUrl';
import { userNameFromAllParams } from '../../helpers/userName';
import { useHistory } from 'react-router-dom';
import styles from './style.module.css';

function UserHeaderInfo({ user }: { user?: User }) {
  const loggedUserId = useSelector(selectUserId);
  const history = useHistory();
  const default_background_image_url =
    'https://w0.peakpx.com/wallpaper/1012/299/HD-wallpaper-dark-blur-abstract-blur-abstract-deviantart.jpg';
  return (
    <div
      className={styles.userInfoHeader}
      style={{ backgroundImage: `url(${user?.profile?.background_image || default_background_image_url})` }}
    >
      <div className={styles.arrowBack}>
        <ArrowBackButton
          setStep={() => {
            window.history.back();
          }}
        />
      </div>
      {user && (
        <div className={styles.userInfoBlock}>
          <img className={styles.userImage} src={user.profile?.image_url || NO_USER_AVATAR} alt="User Avatar" />
          <div className={styles.nameAndSchool}>
            <div className={styles.nicknameLabel}>
              {userNameFromAllParams(user?.username, user?.profile?.first_name, user?.profile?.last_name)}
            </div>
            {!!user?.profile?.major && <div>Major: {user?.profile?.major}</div>}
            {!!user?.profile?.institute && <div>Institute: {user?.profile?.institute}</div>}
            {!!user?.profile?.graduation && <div>Graduation year: {user?.profile?.graduation}</div>}
          </div>
          <div className={styles.editOrMsgButton}>
            {loggedUserId === user.id ? (
              <div
                onClick={() =>
                  history.push({
                    pathname: '/settings',
                    state: `/user/${loggedUserId}`,
                  })
                }
              >
                Edit
              </div>
            ) : (
              <div
                onClick={() => {
                  history.push('/mobile-chat', { user });
                }}
              >
                Message
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserHeaderInfo;
