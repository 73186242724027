import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from 'src/utils/styles.utils';
import { Wrapper } from 'src/components/layout/Wrapper';
import React, { useEffect, useState } from 'react';
import { useToken } from 'src/cookies';
import { getMyArticles } from 'src/store/articles';
import { selectMyArticles, selectPapers } from 'src/store/articles/selector';
import { Paper, PaperType } from '../../../types/Paper';
import { selectUserId } from '../../../store/auth';
import ArticleGrid from '../../../components/Article/ArticleGrid/ArticleGrid';
import styles from './style.module.css';

export enum Group_Tabs {
  FEED = 'MY_FEED',
  QUESTIONS = 'MY_QUESTIONS',
  COMMENTS = 'MY_COMMENTS',
}

export default function ProfileTabs() {
  const [selectedTab, setSelectedTab] = useState(Group_Tabs.FEED);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { token } = useToken();
  const articles = useSelector(selectPapers);

  //TODO -- check if we need this state
  const [filteredArticles, setFilteredArticles] = useState(articles);
  const loggedUser = useSelector(selectUserId);
  const myArticles = useSelector(selectMyArticles);
  const [myFeed, setMyFeed] = useState<Paper[]>([]);
  const [myQuestions, setMyQuestions] = useState<Paper[]>([]);

  useEffect(() => {
    if (loggedUser) {
      dispatch(getMyArticles({ token, userId: loggedUser }));
    }
  }, [loggedUser]);

  useEffect(() => {
    const articles = myArticles?.filter((art) => art.type === 'ARTICLE');
    const questions = myArticles?.filter((art) => art.type === 'QUESTION');
    setMyFeed(articles || []);
    setMyQuestions(questions || []);
  }, [loggedUser, myArticles]);

  useEffect(() => {
    if (selectedTab === Group_Tabs.FEED) {
      setFilteredArticles(articles.filter((article) => article.type === PaperType.ARTICLE));
    }
    if (selectedTab === Group_Tabs.QUESTIONS) {
      setFilteredArticles(articles.filter((article) => article.type === PaperType.QUESTION));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, articles]);

  return (
    <div className={isMobile ? styles.groupMainLayout : ''}>
      <div>
        <Wrapper>
          <div className={styles.groupPageTabs}>
            <div
              onClick={() => setSelectedTab(Group_Tabs.FEED)}
              className={`${styles.userTab} ${selectedTab === Group_Tabs.FEED && styles.selectedTab}`}
            >
              My feed
            </div>
            <div
              onClick={() => setSelectedTab(Group_Tabs.QUESTIONS)}
              className={`${styles.userTab} ${selectedTab === Group_Tabs.QUESTIONS && styles.selectedTab}`}
            >
              My questions
            </div>
            {/*<div*/}
            {/*  className={`${styles.userTab} ${selectedTab === Group_Tabs.COMMENTS && styles.selectedTab}`}*/}
            {/*  onClick={() => setSelectedTab(Group_Tabs.COMMENTS)}*/}
            {/*>*/}
            {/*  My comments*/}
            {/*</div>*/}
          </div>
          <div className={styles.listArea}>
            {!!myFeed.length && selectedTab === Group_Tabs.FEED && <ArticleGrid articles={myFeed} />}
            {!!myQuestions.length && selectedTab === Group_Tabs.QUESTIONS && <ArticleGrid articles={myQuestions} />}
          </div>
        </Wrapper>
      </div>
    </div>
  );
}
