export const exclamationCircle = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
      <path d="M8 5V8.85714" stroke="white" strokeLinecap="round" />
      <ellipse cx="8.00004" cy="10.7858" rx="0.642857" ry="0.642857" fill="white" />
      <path
        d="M14.3004 7.99983C14.3004 4.52044 11.4798 1.69983 8.00044 1.69983C4.52105 1.69983 1.70044 4.52044 1.70044 7.99983C1.70044 11.4792 4.52105 14.2998 8.00044 14.2998C11.4798 14.2998 14.3004 11.4792 14.3004 7.99983Z"
        stroke="white"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
);
