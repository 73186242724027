import React, { PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from '../dashboardStyles.module.css';

function PopUp({
  children,
  activeIndex,
  width,
  height,
  clickOutside,
}: PropsWithChildren<{ activeIndex: number | string; width: number; height: number; clickOutside?: () => void }>) {
  const [popupPosition, setPopupPosition] = useState<number>(0);
  const popupRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (popupRef.current) {
      const { left, right } = popupRef.current.getBoundingClientRect();

      if (left - width < 0) {
        return setPopupPosition(0);
      }

      if (right + width > window.innerWidth) {
        return setPopupPosition(-380);
      }
      return setPopupPosition(-188);
    }
  }, [activeIndex]);

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      // setIsOpen(false);
      clickOutside && clickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      style={{ left: `${popupPosition}px`, width: `${width}px`, height: `${height}px`, top: `-${height + 8}px` }}
      ref={popupRef}
      className={styles.popup}
    >
      {children}
    </div>
  );
}

export default PopUp;