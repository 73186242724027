export const WarningIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6004 53.8113C29.0997 53.5596 29.6528 53.4324 30.2116 53.4132C43.9976 52.9403 55.0284 41.6155 55.0284 27.7143C55.0284 13.5127 43.5157 2 29.3141 2C15.1125 2 3.59985 13.5127 3.59985 27.7143C3.59985 35.5529 7.1072 42.5723 12.6381 47.2888C13.5052 48.0281 14.057 49.0876 14.057 50.2271V55.3129C14.057 57.9866 16.8751 59.7219 19.2626 58.5184L28.6004 53.8113Z"
      fill="#CB8C2F"
    />
    <g clipPath="url(#clip0_915_17910)">
      <path
        d="M29.0001 35.1C29.9188 35.1 30.6836 34.3961 30.761 33.4805L31.9555 19.2141C32.0251 18.3867 31.7445 17.5687 31.1829 16.9578C30.6219 16.3477 29.8298 16 29.0001 16C28.1704 16 27.3782 16.3477 26.8172 16.9578C26.2555 17.5687 25.9751 18.3867 26.0446 19.2141L27.2391 33.4805C27.3165 34.3961 28.0813 35.1 29.0001 35.1Z"
        fill="white"
      />
      <path
        d="M29 36.1504C27.9366 36.1504 27.075 37.012 27.075 38.0754C27.075 39.1387 27.9366 40.0004 29 40.0004C30.0633 40.0004 30.925 39.1387 30.925 38.0754C30.925 37.012 30.0633 36.1504 29 36.1504Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_915_17910">
        <rect width="24" height="24" fill="white" transform="translate(17 16)" />
      </clipPath>
    </defs>
  </svg>
);
