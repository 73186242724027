import React from 'react';
import { NewlineText } from './textHelper';
import styles from './styles.module.css';

function VextUseExamples() {
  const text =
    "Start by creating a group and inviting friends or colleagues. Or you can join a group. Once you join, simply share your mood towards that group each day. Soon your mood analytics within the VEXT app will start to provide insights into how you're feeling towards each area of interest or activity. You can also check to see how the group is feeling. Maybe you're wondering if other members of your unit, team, or class have been feeling positively or negatively? VEXT can help give you this sense. No matter how you're feeling - but especially if it's not great - it can be something of a relief to see that others have similar feelings as you. It helps to feel less isolated. And then, of course, you can always reach out anonymously within your group to pose questions about whatever is relevant to that group.  \n" +
    '\n' +
    "For organizations who participate with VEXT, VEXT provides a means of connecting community members with counseling services. Again, anonymously. Contact us for more information if you're interested in this service: hey@vext.co";
  return <div className={styles.textBlock}>{NewlineText(text)}</div>;
}

export default VextUseExamples;
