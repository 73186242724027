import React from 'react';
import vextStuff from '../../assets/landingPage/vextStuff.png';
import adminStuff from '../../assets/landingPage/adminStuff.png';
import anonymousMentalHealth from '../../assets/landingPage/anonymousMentalHealth.png';
import analyticMentalHealth from '../../assets/landingPage/analysticMentalHealth.png';
import insightsMentalHealth from '../../assets/landingPage/insightsMentalHealth.png';
import user1 from '../../assets/landingPage/user1.png';
import user2 from '../../assets/landingPage/user2.png';
import user3 from '../../assets/landingPage/user3.png';
import ios from '../../assets/landingPage/apleStore.png';
import google from '../../assets/landingPage/googlePlay.png';

import {checkboxChecked} from 'images/checkboxes/checkboxChecked';
import PmhCard from './PMHCard';
import styles from '../landingStyles.module.css';
import {useIsMobile, useIsTablet} from "../../utils/styles.utils";

function Home() {
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const isSmallDevice = isMobile || isTablet;
    const accessAppOnGooglePlay = () => {
        window.open('https://play.google.com/store/apps/details?id=com.vext.cordovareactapp&pli=1', '_blank');
    };
    const accessAppOnAppStore = () => {
        window.open('https://apps.apple.com/md/app/vext/id6443604829', '_blank');
    };

    return (
        <div id="home" className={styles.homeMain} style={{padding: isSmallDevice ? '6px' : undefined}}>
            <div className={isSmallDevice ? styles.homeMobile : styles.homeBlock}>
                <div className={styles.homeBlock1Left}>
                    <div style={{fontSize: isSmallDevice ? '16px' : undefined}}>Eliminating the frictions of mental
                        health support
                    </div>
                    <div style={{
                        fontSize: isSmallDevice ? '12px' : undefined,
                        width: isSmallDevice ? '100%' : undefined
                    }}>
                        Peer-to-peer app for students and health care professionals with a provider dashboard of mental
                        health
                        analytics that is enabling proactive Pushed Mental Health.
                    </div>
                    {/*<div className={styles.landingButton}>Learn more</div>*/}
                    <div className={styles.getAppBlock}>
                        <img onClick={accessAppOnAppStore} className={styles.ios} src={ios} alt=""/>
                        <img onClick={accessAppOnGooglePlay} className={styles.google} src={google} alt=""/>
                    </div>
                </div>
                <img style={{width: isSmallDevice ? '96%' : undefined, marginLeft: isSmallDevice ? '8px' : undefined}}
                     className={styles.homeBlock1Right} src={vextStuff} alt=""/>
            </div>
            <div className={styles.blockSubtitle}>What VEXT does</div>
            <div className={styles.underScoreLine}/>
            <div className={styles.blockAlignedTitle}>We provide:</div>
            <div className={isSmallDevice ? styles.homeMobile : styles.homeBlock}>
                <div className={styles.homeBlock2Left}>
                    <div className={styles.providedElement}>
                        <div>{checkboxChecked()}</div>
                        <span> Students, Health Care Workers, Veterans a platform for anonymous mental health with peers</span>
                    </div>
                    <div className={styles.providedElement}>
                        <div>{checkboxChecked()}</div>
                        <span>Administrators and Counselors with proactive and preemptive Mental Health support</span>
                    </div>
                    <div className={styles.providedElement}>
                        <div>{checkboxChecked()}</div>
                        <span>
              Universities, Health Care Systems, VA, and other institutions with Mental Health analytics for
              institutions and counselor
            </span>
                    </div>
                    <div className={styles.providedElement}>
                        <div>{checkboxChecked()}</div>
                        <span>
              Administrators, Counselors, and Providers with the ability to see how we are closing the loop on Mental
              Health
            </span>
                    </div>
                </div>
                <img className={styles.homeBlock2right} src={adminStuff} alt=""/>
            </div>
            <div className={styles.blockSubtitle}>A Better Paradigm for Mental Health</div>
            <div className={styles.underScoreLine}/>
            <p className={styles.blockParagraph}>
                VEXT brings visibility into the mental health hotspots of your institution. The insights we provide
                empower you
                to proactively identify and take action in parts of your organization where your constituents are
                sending
                digital signals about their mental health state. VEXT allows you to proactively go to your people
                instead of
                passively waiting to uncover situations of extreme duress.
            </p>
            <div className={styles.blockHeading2}>We call this Pushed Mental Health (PMH).</div>
            {/*<div className={styles.pmhMain}>*/}
            {/*  <PmhCard*/}
            {/*    img={anonymousMentalHealth}*/}
            {/*    title={'Anonymous Mental Health Support Network'}*/}
            {/*    textContent={*/}
            {/*      'Our anonymous mental health service provides individuals with the opportunity to connect with peer support in a safe and confidential environment.'*/}
            {/*    }*/}
            {/*  />*/}
            {/*  <PmhCard*/}
            {/*    img={analyticMentalHealth}*/}
            {/*    title={'Mental Health Analytics for Proactivity'}*/}
            {/*    textContent={*/}
            {/*      'Our Mental Health Analytics service provides institutions and counselors with data-driven insights to improve the well-being of their clients.'*/}
            {/*    }*/}
            {/*  />*/}

            {/*  <PmhCard*/}
            {/*    img={insightsMentalHealth}*/}
            {/*    title={'Mental Health Insights and Support'}*/}
            {/*    textContent={*/}
            {/*      'Our proactive, preemptive mental health support service offers personalized strategies and resources to help individuals maintain a healthy state of mind.'*/}
            {/*    }*/}
            {/*  />*/}
            {/*</div>*/}
            <div className={styles.blockSubtitle}>We Work With You By:</div>
            <p className={styles.blockParagraph}>
                1. Helping you <b>understand the mental health landscape of your institution.</b> We work with you to
                understand
                your mental health initiatives and scope and then provide a solution for your organization that brings
                together
                all the powerful elements of the VEXT platform.
                <br/>
                2. Empowering you to <b>be proactive, not reactive.</b> Having the insights and data into where across
                your
                organization mental health hot spots are emerging is crucial. VEXT’s platform also provides a means for
                you to
                communicate with the groups and individuals proactively and without compromising identities. All users
                get to
                remain anonymous until or unless they remove their incognito status.
                <br/>
                3. Giving you the tools to <b>Close the Loop</b> on mental health interventions. VEXT provides
                anonymized yet
                detailed logs of the interactions so you can ensure that your members have had follow ups and follow
                throughs.
            </p>
            <div className={styles.underScoreLine}/>
            <div className={styles.blockSubtitle}>Inspiration</div>
            <div className={styles.underScoreLine}/>
            <div className={isSmallDevice ? styles.inspirationCardsWrapperMobile : styles.inspirationCardsWrapper}>
                <div className={isSmallDevice ? styles.inspirationCardMobile : styles.inspirationCard}>
                    <img className={styles.inspirationCardImage} src={user1} alt="user"/>
                    <p className={styles.blockParagraph}>
                        Sometimes I want my teachers, coaches, and friends to know how I’m feeling. I just don’t
                        necessarily want
                        them to know it’s ME who’s feeling that way. VEXT let’s me stay anonymous with people I know and
                        love. It
                        just makes it easier to say what I want to say sometimes.
                    </p>
                    <div className={styles.underScoreLine}/>
                    <p className={styles.blockParagraph}>High School Senior, Palo Alto, CA</p>
                </div>
                <div className={isSmallDevice ? styles.inspirationCardMobile : styles.inspirationCard}>
                    <img className={styles.inspirationCardImage} src={user2} alt="user"/>
                    <p className={styles.blockParagraph}>
                        I was looking at one of our groups. It was a team. I saw one of the members had expressed
                        negative moods for
                        a couple days in a row. I didn’t know who it was, so I fired off a few supportive texts to a few
                        of them
                        that VEXT recommended.
                    </p>
                    <div className={styles.underScoreLine}/>
                    <p className={styles.blockParagraph}>Women’s Lacrosse Coach, CA </p>
                </div>
                <div className={isSmallDevice ? styles.inspirationCardMobile : styles.inspirationCard}>
                    <img className={styles.inspirationCardImage} src={user3} alt="user"/>
                    <p className={styles.blockParagraph}>
                        After I see a patient I have no idea what happens. I have no idea if any one is closing the loop
                        with them.
                        Whether we put them on a suicide hold or redirect them to other resources, I don’t know what
                        happens once
                        they leave.
                    </p>
                    <div className={styles.underScoreLine}/>
                    <p className={styles.blockParagraph}>Emergency Room MD, Dallas, TX</p>
                </div>
            </div>
        </div>
    );
}

export default Home;