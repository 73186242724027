import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { darkTheme } from '../../../themes/themes';
import { ThemeProvider } from '@mui/material';
import { ReactComponent as ExpandIcon } from 'images/ExpandIcon.svg';
import { ReactComponent as MinusIcon } from 'images/MinusIcon.svg';
import { SyntheticEvent, useEffect, useState } from 'react';
import { AboutYouMobileSettings } from './components/AboutYou';
import { selectLoggedUserData } from '../../../store/user/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { MobileSettingsHeader } from './components/MobileSettingsHeader';
import { ShortBioMobileSettings } from './components/ShortBio';
import { ProfileImageMobileSettings } from './components/ProfileImage';
import PlaceOfResidenceMobileSettings from './components/PlaceOfResidence';
import EducationMobileSettings from './components/Education';
import { useToken } from '../../../cookies';
import { selectUserId } from '../../../store/auth';
import { getUser, updateUser, updateUserImg } from '../../../store/user';
import { UploadImageApiService } from '../../../services/images.service';
import { useLocation } from 'react-router-dom';
import MessageHockPopUp from '../../../hooks/MessageHockPopUp';
import PrivacyMobileSettings from './components/PrivacyMobile';
import { User } from '../../../types/User';
import styles from './MobileSettingStyle.module.css';

export function MobileSettingsPage() {
  const userData = useSelector(selectLoggedUserData);
  const { profile } = userData;
  const [userName, setUserName] = useState(userData.username || '');
  const [userBirthDay, setUserBirthDay] = useState(profile?.birth_date || undefined);
  const [userPhoneNumber, setUserPhoneNumber] = useState(profile?.phone || undefined);
  const [userShortBio, setUserShortBio] = useState(profile?.bio || '');
  const [userImage, setUserImage] = useState();
  const [userBackgroundImage, setUserBackgroundImage] = useState();
  const [userCountry, setUserCountry] = useState(profile?.country || '');
  const [userState, setUserState] = useState(profile?.state || '');
  const [userTown, setUserTown] = useState(profile?.town || '');
  const [userInstitute, setUserInstitute] = useState(profile?.institute || '');
  const [userMajor, setUserMajor] = useState(profile?.major || '');
  const [userGraduation, setUserGraduation] = useState(profile?.graduation || '');
  const [userFacebook, setUserFacebook] = useState(profile?.facebook || '');
  const [userTwitter, setUserTwitter] = useState(profile?.twitter || '');
  const [userInstagram, setUserInstagram] = useState(profile?.instagram || '');
  const [userSnapchat, setUserSnapchat] = useState(profile?.snapchat || '');
  const [isSocialMediaValid, setIsSocialMediaValid] = useState(true);
  const [expanded, setExpanded] = useState<string | false>(false);
  const { state } = useLocation();
  const { token } = useToken();
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);

  useEffect(() => {
    if (!profile?.id && userId) {
      dispatch(getUser({ token: token, userId }));
    }
  }, [userId]);

  useEffect(() => {
    setUserName(userData.username || '');
    if (profile) {
      setUserBirthDay(profile.birth_date);
      setUserPhoneNumber(profile.phone);
      setUserShortBio(profile.bio);
      setUserCountry(profile.country);
      setUserState(profile.state);
      setUserTown(profile.town);
      setUserInstitute(profile.institute);
      setUserMajor(profile.major);
      setUserGraduation(profile.graduation);
      setUserFacebook(profile.facebook);
      setUserTwitter(profile.twitter);
      setUserInstagram(profile.instagram);
      setUserSnapchat(profile.snapchat);
    }
  }, [profile]);

  const saveChanges = async () => {
    if (isSocialMediaValid) {
      const _profile = {
        ...(userBirthDay && { birth_date: userBirthDay }),
        ...(userPhoneNumber && { phone: userPhoneNumber }),
        ...(userShortBio && { bio: userShortBio }),
        ...(userCountry && { country: userCountry }),
        ...(userState && { state: userState }),
        ...(userTown && { town: userTown }),
        ...(userInstitute && { institute: userInstitute }),
        ...(userMajor && { major: userMajor }),
        ...(userGraduation && { graduation: userGraduation }),
        facebook: userFacebook,
        twitter: userTwitter,
        instagram: userInstagram,
        snapchat: userSnapchat,
      };
      const validProfile = Object.fromEntries(Object.entries(_profile).filter(([_, v]) => v != null));

      const newUserInfo = {
        ...userData,
        ...(userName && { username: userName }),
        profile: { ...validProfile },
      } as unknown as User;
      dispatch(updateUser({ token, updatedData: newUserInfo }));

      if (userImage) {
        const formData = new FormData();
        formData.append('image', userImage);
        const res = await UploadImageApiService.uploadUserImg(formData, userId, token);
        if (res.ok) {
          dispatch(updateUserImg(res.data));
        }
      }

      if (userBackgroundImage) {
        const formData = new FormData();
        formData.append('image', userBackgroundImage);
        const res = await UploadImageApiService.uploadUserBackgroundImg(formData, userId, token);
        if (res.ok) {
          dispatch(updateUserImg(res.data));
        }
      }
    } else {
      MessageHockPopUp({ message: 'Error on social media fields', timeOut: 4000, type: 'warning', size: 'small' });
    }
    setExpanded(false);
  };

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={styles.mainMobileSettings}>
      <MobileSettingsHeader saveChanges={saveChanges} url={String(state)} />
      <h1>Profile Settings</h1>
      <ThemeProvider theme={darkTheme}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel1' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel1' ? '#FFC700' : 'white', padding: '0' }}
          >
            About you
          </AccordionSummary>

          <AccordionDetails style={{ padding: '0' }}>
            <AboutYouMobileSettings
              userName={userName}
              birthDate={userBirthDay}
              phone={userPhoneNumber}
              facebook={userFacebook}
              twitter={userTwitter}
              instagram={userInstagram}
              snapchat={userSnapchat}
              setUserName={setUserName}
              setUserBirthDate={setUserBirthDay}
              setUserPhone={setUserPhoneNumber}
              setUserFacebook={setUserFacebook}
              setUserTwitter={setUserTwitter}
              setUserInstagram={setUserInstagram}
              setUserSnapChat={setUserSnapchat}
              setIsSocialMediaValid={setIsSocialMediaValid}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel2' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel2a-header"
            style={{ color: expanded === 'panel2' ? '#FFC700' : 'white', padding: '0' }}
          >
            Short Bio
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <ShortBioMobileSettings shortBio={userShortBio} setUserShortBio={setUserShortBio} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel3' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel3a-header"
            style={{ color: expanded === 'panel3' ? '#FFC700' : 'white', padding: '0' }}
          >
            Profile Image
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <ProfileImageMobileSettings imageUrl={profile?.image_url || ''} setUserImage={setUserImage} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel4' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel4a-header"
            style={{ color: expanded === 'panel4' ? '#FFC700' : 'white', padding: '0' }}
          >
            Place of residence
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <PlaceOfResidenceMobileSettings
              country={userCountry}
              state={userState}
              town={userTown}
              setCountry={setUserCountry}
              setState={setUserState}
              setTown={setUserTown}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel5' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel5a-header"
            style={{ color: expanded === 'panel5' ? '#FFC700' : 'white', padding: '0' }}
          >
            Education
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <EducationMobileSettings
              institute={userInstitute}
              major={userMajor}
              graduation={userGraduation}
              setInstitute={setUserInstitute}
              setMajor={setUserMajor}
              setGraduation={setUserGraduation}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel7' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
            style={{ color: expanded === 'panel7' ? '#FFC700' : 'white', padding: '0' }}
          >
            Background Image
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <ProfileImageMobileSettings
              imageUrl={profile?.background_image || ''}
              setUserImage={setUserBackgroundImage}
              isBackground
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel6' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel6' ? '#FFC700' : 'white', padding: '0' }}
          >
            Privacy
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <PrivacyMobileSettings />
          </AccordionDetails>
        </Accordion>
      </ThemeProvider>
    </div>
  );
}
