import React from 'react';
import { User } from '../../../../types/User';
import { NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';
import { userNameFromAllParams } from '../../../../helpers/userName';
import { calcJoinedTime } from '../../../../helpers/date';
import styles from '../dashboardStyles.module.css';

function UserElement({ user }: { user: User }) {
  return (
    <div className={styles.userRoleElement}>
      <div className={styles.roleAndIcon}>
        <img className={styles.userImage} src={user.profile?.image_url || NO_USER_AVATAR} alt="" />
        <div style={{ minWidth: '120px', overflow: 'hidden' }}>
          <div className={styles.roleLabel}>User:</div>
          <div className={styles.roleName}>
            {userNameFromAllParams(user.username, user.profile?.first_name, user.profile?.last_name)}
          </div>
        </div>
        <div style={{ marginLeft: '10px' }}>
          <div className={styles.roleLabel}>Joined at:</div>
          <div className={styles.roleLabel}>{calcJoinedTime(user.date_joined || '01-01-01')}</div>
        </div>
        <div style={{ marginLeft: '40px' }}>
          <div className={styles.roleLabel}>Role:</div>
          <div className={styles.roleName}>{user.is_staff ? 'Admin' : 'User'}</div>
        </div>
      </div>
    </div>
  );
}

export default UserElement;