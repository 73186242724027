import { ReactComponent as SearchSvg } from 'images/Search.svg';
import React, { Dispatch, SetStateAction } from 'react';

import styles from './searchStyles.module.css';

function Search({ searchVal, setSearchVal }: { setSearchVal: Dispatch<SetStateAction<string>>; searchVal: string }) {
  return (
    <div className={styles.searchBlock}>
      <input
        value={searchVal}
        onChange={(e) => setSearchVal(e.target.value)}
        className={styles.input}
        type="text"
        placeholder={'Search'}
      />
      <SearchSvg style={{ cursor: 'pointer' }} width={22} height={22} />
    </div>
  );
}

export default Search;
