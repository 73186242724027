import { useEffect, useState } from 'react';

type IWindowDimensionsResponse = {
  width: number;
  height: number;
};

function getWindowDimensions(): IWindowDimensionsResponse {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export const useWindowDimensions = (): IWindowDimensionsResponse => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export const useIsTablet = (): boolean => {
  const { width } = useWindowDimensions();

  return width <= 1024 ? isMobile() : false;
};

export const useIsTabletSizes = (): boolean => {
  const { width } = useWindowDimensions();

  return width <= 1024;
};

export const useIsSmallTablet = (): boolean => {
  const { width } = useWindowDimensions();

  return width <= 980 ? isMobile() : false;
};

export const useIsMobile = (): boolean => {
  const { width } = useWindowDimensions();

  return width <= 480 ? isMobile() : false;
};
