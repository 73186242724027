import React from 'react';
import { NewlineText } from './textHelper';
import styles from './styles.module.css';

function WhyUseVext() {
  const text =
    'The most powerful way to learn is by looking at models, seeing examples. When students are given writing assignments they almost never are provided with an example of “what good looks like.” How is a student writer to know where to start? And once started, how is a student writer to know what they should be striving for in their final outputs? These are the types of questions that create endless amounts of anxiety in writers of all levels! The getting started, the process, the expectations….it is all so…vexing. \n' +
    '\n' +
    "The interesting thing about student writing - whether you're in high school, college, or graduate school - is that it exists almost exclusively in a vacuum. When is the last time you read one of your peers papers? When is the last time you connected with a peer because they were thinking about and writing about a topic you were working on? Chances are you haven’t. \n";
  return <div className={styles.textBlock}>{NewlineText(text)}</div>;
}

export default WhyUseVext;
