import styles from '../Footer.module.css';

export default function FooterLinks() {
  return (
    <div className={styles.footerLinks}>
      <a target="_blank" rel="noreferrer" className={styles.footerLink} href="mailto:help@vextedu.net">
        <span className={styles.footerLink}>Help</span>
      </a>

      <a
        href="https://docs.google.com/document/d/1uUR2bg4Xgjku_pdoPOByX5lyUHZQZbZ75hBFhOIu1nU/edit"
        target="_blank"
        rel="noreferrer"
        className={styles.footerLink}
      >
        <span className={styles.footerLink}>Terms</span>
      </a>
      <a
        href="https://docs.google.com/spreadsheets/d/1IB-2i8ZjdlKJvByceAPqXcaLHJoSq_aYxVPWkPLeQjk/edit#gid=0"
        target="_blank"
        rel="noreferrer"
        className={styles.footerLink}
      >
        <span className={styles.footerLink}>About us</span>
      </a>
    </div>
  );
}
