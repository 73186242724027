export const checkImage = (url: string) =>
  new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
export function isValidImageUrl(url: string): string {
    const img = new Image();
    img.src = url;
    if (!img.complete) {
        return "";
    } else if (img.naturalHeight === 0) {
        return "";
    }
    return url;
}