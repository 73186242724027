import { ApiResponse, ApisauceConfig, ApisauceInstance, create } from 'apisauce';
import axios, { AxiosError } from 'axios';
import MessageHockPopUp from '../hooks/MessageHockPopUp';

export function getCookie(cookieName: string) {
  let cookie = {};
  document.cookie.split(';').forEach(function (el) {
    let [key, value] = el.split('=');
    // @ts-ignore
    cookie[key.trim()] = value;
  });
  // @ts-ignore
  return cookie[cookieName];
}

function eraseCookie(name: string) {
  document.cookie = name + '=; Max-Age=0';
  //Here we are removing from localStorage also
  localStorage.removeItem(name);
}

export class _RestApiService {
  api: ApisauceInstance;
  static baseURL = '';

  static defaultHeaders = {
    headers: {
      'content-type': 'application/json',
    },
  };

  constructor(baseURL: string) {
    _RestApiService.baseURL = baseURL;
    this.api = create({
      baseURL,
      ..._RestApiService.defaultHeaders,
    });

    this.api.axiosInstance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (error: AxiosError) => {
        if ((error.response?.status === 401 || error.response?.status === 403) && getCookie('refresh')) {
          try {
            const refreshToken = await axios
              .post(baseURL + '/api/auth/token/refresh/', {
                refresh: getCookie('refresh'),
              })
              .then((r) => r.data)
              .catch((e) => {
                eraseCookie('refresh');
                eraseCookie('token');
                window.location.href = '/login';
              });

            const newConfigs = { ...error.config };
            if (refreshToken.access) {
              newConfigs.headers!.Authorization = `Bearer ${refreshToken.access}`;
              document.cookie = `token=${refreshToken.access}`;
              return axios.request(newConfigs);
            }
          } catch (e) {
            console.log('error', e);
          }
        } else if (error.response?.status === 401 || error.response?.status === 403) {
          eraseCookie('refresh');
          eraseCookie('token');

          MessageHockPopUp({
            message: 'Your token has been expired!',
            timeOut: 5000,
            size: 'small',
            type: 'error',
          });
          window.location.href = '/login';
        } else {
          return error;
        }
      },
    );
  }

  public getAuthenticateHeader(token: string): Partial<ApisauceConfig> {
    return {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  }

  public getAuthenticateHeaderFormData(token: string): Partial<ApisauceConfig> {
    return {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    };
  }

  async get<T, R>(url: string, data?: T, config?: Partial<ApisauceConfig>): Promise<ApiResponse<R>> {
    // @ts-ignore
    return await this.api.get(url, data, config);
  }

  async post<T, R>(url: string, data?: T, config?: Partial<ApisauceConfig>): Promise<ApiResponse<R>> {
    return await this.api.post(url, data, config);
  }

  async put<T, R>(url: string, data?: T, config?: Partial<ApisauceConfig>): Promise<ApiResponse<R>> {
    return await this.api.put(url, data, config);
  }

  async patch<T, R>(url: string, data?: T, config?: Partial<ApisauceConfig>): Promise<ApiResponse<R>> {
    return await this.api.patch(url, data, config);
  }

  async delete<T, R>(url: string, data?: T, config?: Partial<ApisauceConfig>): Promise<ApiResponse<R>> {
    // @ts-ignore
    return await this.api.delete(url, data, config);
  }
}
