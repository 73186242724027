import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';

export default function EmergencyStep4({
  setStepComponent,
  sendEmergencyReq,
}: {
  setStepComponent: Dispatch<SetStateAction<number>>;
  sendEmergencyReq: () => void;
}) {
  const history = useHistory();

  return (
    <div>
      <div className={styles.moreInfoBlock}>
        <h1>We hear you and will get back to you asap! </h1>
        <p>
          If you or someone you know is in crisis, call or text the 988 Suicide & Crisis Lifeline at 988. The Lifeline
          provides 24-hour, confidential support. Call 911 in life-threatening situations.
        </p>
      </div>
      <div className={styles.buttonsBlock}>
        <div
          className={styles.sendButton}
          style={{ marginTop: '28%' }}
          onClick={() => {
            history.push('/feed');
            sendEmergencyReq();
          }}
        >
          I understand
        </div>
        <div className={styles.sendButton} style={{ marginTop: '28%' }} onClick={() => history.push('/feed')}>
          Cancel
        </div>
      </div>
    </div>
  );
}
