import {ChatListPreview, User} from '../types/User';

export const getFirstUserFromUsersList = (users: Array<User>, myUserId: string, hasToAccept?: Array<User>) => {
  if (users.length === 1) {
    if (hasToAccept?.length) {
      return hasToAccept[0];
    }
    return users[0];
  }
  //this method return first user except me from a conversation in chat
  return users.find((e) => e.id !== myUserId);
};

export const getRandomString = () => {
  return Math.random().toString(26).substring(2, 10) + Math.random().toString(26).substring(2, 10);
};

export const getSortedChats = (chats: { [p: string]: ChatListPreview }) => {
  return Object.values(chats).sort((a, b) => {
    if (b?.last_message && a.last_message) {
      return new Date(b?.last_message?.creation_date).getTime() - new Date(a.last_message.creation_date).getTime();
    } else return new Date(b?.creation_date).getTime() - new Date(a.creation_date).getTime();
  });
};