import { PencilIcon } from 'images/PencilIcon';
import { IconCheck } from 'images/checked/IconCheck';
import { IconClose } from 'images/IconClose';
import { Dispatch, SetStateAction } from 'react';
import styles from './Items.module.css';

type IEditContentSwitchProps = {
  isEditable: boolean;
  setIsEditable: Dispatch<SetStateAction<boolean>>;
  submit: () => void;
  isAllCompleted?: boolean;
};

export const EditContentSwitch = ({ isEditable, setIsEditable, submit, isAllCompleted }: IEditContentSwitchProps) => {
  return isEditable ? (
    <div onClick={() => setIsEditable(false)} className={styles.mainSwitch}>
      <div className={styles.pencilIcon}>{PencilIcon('#757575')}</div>
      <span className={styles.editBtnLabel}>Edit</span>
    </div>
  ) : (
    <div style={{ display: 'flex' }}>
      {isAllCompleted ? (
        <div className={styles.saveBtnBlock} onClick={() => submit()}>
          <div className={styles.iconBlock}>{IconCheck()}</div>
          Save
        </div>
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.saveBtnBlockDisable}
        >
          <div className={styles.iconBlock}>{IconCheck()}</div>
          Save
        </div>
      )}

      <div onClick={() => setIsEditable(true)} className={styles.closeBtn}>
        <div className={styles.iconBlock}>{IconClose()}</div>
        <span className={styles.cancelTextBtn}>Cancel</span>
      </div>
    </div>
  );
};
