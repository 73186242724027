import { createTheme } from '@mui/material/styles';

export const switchTheme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: 'white',
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: 'white',
          },
        },
        track: {
          opacity: 0.2,
          backgroundColor: '#fff',
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: '#FFC700',
          },
        },
      },
    },
  },
});