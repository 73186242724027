import React from 'react';
import { NewlineText } from './textHelper';
import styles from './styles.module.css';

function MyVextName() {
  const text = 'VEXT strongly encourages users to use a screen name for posting and commenting on VEXT. This is for safety and security reasons. But it is primarily for helping users feel more comfortable about putting their emtions, questions, and ideas out in public for others to read and comment on. This can be very intimidating for users young and old who may not want to ask for help amongst peers or family or friends because of how they think it may impact how they are perceived. And being able to have the protection of an alter ego in the form of an avatar or screen name is - we hope - a great way to help alleviate the stress of trying to find clarity when making yourself vulnerable by putting a question or feeling out there in the world.';
  return <div className={styles.textBlock}>{NewlineText(text)}</div>;
}

export default MyVextName;

