import { useEffect, useState } from 'react';
import { PencilIcon } from 'images/PencilIcon';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserImg } from '../../../store/user';
import { useToken } from '../../../cookies';
import { User } from '../../../types/User';
import ImageInput from './ImageInput';
import { UploadImageApiService } from 'src/services/images.service';
import { selectUserId } from 'src/store/auth';
import { NO_USER_AVATAR } from '../../../utils/constantImagesUrl';
import styles from './Items.module.css';
import './style.css';

Modal.setAppElement('#root');

export const SettingsProfileImage = ({ userData }: { userData: User; isNotMyUser?: boolean }) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const { token } = useToken();
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(userData.profile?.image_url || '');
  // const baseUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    setImageUrl(userData.profile?.image_url || '');
  }, [userData]);

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  // TODO imageUrl File type
  const verifyImageUrl = async (imageUrl: any) => {
    const formData = new FormData();
    formData.append('image', imageUrl);
    const res = await UploadImageApiService.uploadUserImg(formData, userId, token);
    if (res.ok) {
      dispatch(updateUserImg(res.data));
    }
    setIsOpen(false);
  };
  return (
    <div className={styles.profileImageMain}>
      <div className={styles.profileImageComponents}>
        <img id="img" className={styles.settingsProfileImage} src={imageUrl || NO_USER_AVATAR} alt="user" />
        <span className={styles.aboutUploadImageText}>
          Your photo appears on your Profile page and with your stories across Medium. Recommended size: Square, at
          least 1000 pixels per side. File type: JPG, PNG or GIF.
        </span>
      </div>
      <div className={styles.uploadImageRightItems}>
        <div onClick={openModal} className={styles.uploadImageButton}>
          <div style={{ marginRight: '2px', marginTop: '2px' }}>{PencilIcon('#757575')}</div>
          Upload new image
        </div>
        <Modal
          isOpen={isOpen}
          onRequestClose={openModal}
          contentLabel="My dialog"
          className={`mymodal && ${styles.uploadImgModal}`}
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <ImageInput setImage={verifyImageUrl} />
        </Modal>
      </div>
    </div>
  );
};
