import React, { useEffect, useState } from 'react';
import states from './statesMap';
import USAMap from 'react-usa-map';
import { MoodApiService } from '../../services/mood.service';
import { format } from 'date-fns';
import { MyDate } from '../../helpers/date';
import { BackArrow } from 'images/arrows/BackArrow';
import { ForwardArrow } from 'images/arrows/ForwardArrow';
import { getColorByMood } from '../../components/Recharts/RechartsGroupMood';
import { getMoodText } from '../../helpers/moodList';
import datePickerStyles from '../../components/groups/GroupCardStyles.module.css';
import styles from './usaMapStyles.module.css';

type SelectedState = null | { [key: string]: string };

const Popup = ({
  onClose,
  selectedState,
}: {
  onClose: () => void;
  selectedState: { [key: string]: number | null };
}) => (
  <div className={styles.popup}>
    <button className={styles.closeButton} onClick={onClose}>
      x
    </button>

    <div className={styles.infoBlock}>
      <div className={styles.stateName}>{selectedState && Object.keys(selectedState)[0]}</div>

      <div className={styles.moodAverage}>
        Mood average:
        <div style={{ color: getColorByMood(Math.round(Object.values(selectedState)[0] ?? -1)) }}>
          {getMoodText(Math.round(Object.values(selectedState)[0] ?? -1))}
        </div>
      </div>
    </div>
  </div>
);

function UsaMap({ margin }: { margin?: string }) {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedState, setSelectedState] = useState<SelectedState>(null);
  const [statesAverageMoods, setStatesAverageMoods] = useState<{
    [key: string]: { mood: number | null; color: string | null };
  }>({});
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [statesMoods, setStatesMoods] = useState<{ moods: { [key: string]: { [key: string]: number } } }>({
    moods: {},
  });

  useEffect(() => {
    const from = format(new Date(MyDate.getFromTo().from), 'yyyy-MM-dd');
    const to = format(new Date(MyDate.getFromTo().to), 'yyyy-MM-dd');
    MoodApiService.getUsaStatesMoods(from, to).then((response) => {
      setStatesMoods(response.data);
    });
  }, [date]);

  useEffect(() => {
    if (Object.keys(statesMoods.moods)) {
      const outputData = {} as any;

      for (const [state, data] of Object.entries(statesMoods.moods)) {
        if (data && Object.keys(data).length > 0) {
          const values = Object.values(data);
          const sum = values.reduce((acc, value) => acc + value, 0);
          const averageValue = sum / values.length;
          const moodVal = Math.round(averageValue * 10) / 10;
          outputData[state] = { mood: moodVal, color: getColorByMood(Math.round(moodVal ?? -1)) };
        } else {
          outputData[state] = { mood: null, color: null };
        }
      }
      setStatesAverageMoods(outputData);
    }
  }, [statesMoods]);

  const changeDate = (decrease?: boolean) => {
    const dt = new Date(date);
    if (decrease) {
      dt.setDate(dt.getDate() - 7);
    } else {
      dt.setDate(dt.getDate() + 7);
    }

    setDate(dt.toDateString());
  };

  const statesCustomConfig = () => {
    const config = Object.entries(statesAverageMoods).reduce((acc, [key, val]) => {
      // @ts-ignore
      acc[key] = {
        fill: selectedState && Object.keys(selectedState)[0] === key ? '#868686' : statesAverageMoods[key].color,
      };
      return acc;
    }, {});
    return config;
  };

  const mapHandler = (event: any) => {
    const clickedStateName = event.target.dataset.name;
    setSelectedState({ [clickedStateName]: states[clickedStateName] });
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedState(null);
  };

  return (
    <div className={styles.mapWrapper} style={{ margin }}>
      <div className={styles.datepickerBlock}>
        <span>{MyDate.getCurrentPeriod(date)}</span>
        <div className={datePickerStyles.arrowBlock}>
          <div onClick={() => changeDate(true)} className={datePickerStyles.arrowButton}>
            {BackArrow('black')}
          </div>
          <div className={datePickerStyles.arrowButton} onClick={() => changeDate()}>
            {ForwardArrow('black')}
          </div>
        </div>
      </div>
      <USAMap customize={statesCustomConfig()} onClick={mapHandler} />
      {showPopup && selectedState && (
        <div className={styles.popupContainer}>
          <Popup
            onClose={closePopup}
            selectedState={{
              [Object.values(selectedState)[0]]: statesAverageMoods[Object.keys(selectedState)[0]].mood,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default UsaMap;
