import NavigationNavbar from '../../components/Header/NavigationHeader';
import { Wrapper } from '../../components/layout/Wrapper';
import SettingsNavbar from '../../components/Settings/SettingNavbar';
import { selectLoggedUserData, selectSettingsError } from '../../store/user/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useToken } from '../../cookies';
import { getUser } from '../../store/user';
import { selectUserId } from '../../store/auth';
import MessageHockPopUp from 'src/hooks/MessageHockPopUp';
import { MobileSettingsPage } from '../../components/mobile/SettingsPage/MobileSettingsPage';
import { useIsMobile } from '../../utils/styles.utils';

export default function SettingsPage() {
  const settingsError = useSelector(selectSettingsError);
  const { token } = useToken();
  const dispatch = useDispatch();
  const userData = useSelector(selectLoggedUserData);
  const userId = useSelector(selectUserId);
  const isMobile = useIsMobile();
  
  useEffect(() => {
    if (token?.length && !userData.id && userId) {
      dispatch(getUser({ token: token, userId }));
    }
  }, [userId]);

  const notify = () => {
    settingsError && MessageHockPopUp({ message: settingsError, timeOut: 4000, type: 'error' });
  };

  useEffect(() => {
    notify();
  }, [settingsError]);

  return (
    <div style={{ height: isMobile ? '100vh' : '', backgroundColor: isMobile ? '#0d0d0d' : '' }}>
      {isMobile ? (
        <MobileSettingsPage />
      ) : (
        <>
          <NavigationNavbar />
          <Wrapper>
            <SettingsNavbar />
          </Wrapper>
        </>
      )}
    </div>
  );
}
