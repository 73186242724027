import { Paper } from '../types/Paper';

export const iAmArticleAuthor = (article: Paper, myId: string) => {
  if (article.anonymous_name) {
    //TODO make separate type , cause user id is saved in paper_author when it is incognito
    // @ts-ignore
    return article.paper_author === myId;
  }
  return article.paper_author?.id === myId;
};
