import React, { useRef } from 'react';
import GroupCardMobile from '../../../groups/components/GroupCardMobile';
import styles from '../MobileHeaderStyles.module.css';
import { SearchResponse } from '../../../../types/Common';
import { useHistory } from 'react-router-dom';
import { isValidImageUrl } from '../../../../helpers/checkImageUrl';
import { NO_ARTICLE_COVER, NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';
import UserSearchResult from './UserSearchResult';

function SearchResult(props: SearchResponse) {
  const { groups, papers, users } = props;
  const history = useHistory();
  const resultContainerRef = useRef(null);

  const handleScroll = () => {
    if (document.activeElement !== null) {
      // @ts-ignore
      document.activeElement.blur();
    }
  };

  return (
    <div className={styles.searchResultBlock} ref={resultContainerRef} onScroll={handleScroll}>
      {!!groups.length && (
        <div className={styles.resultParagraph}>
          <div className={styles.resultHeading}>Groups</div>
          {groups.map((group) => {
            return (
              <div className={styles.resultParagraph} onClick={() => history.push(`/group/${group.id}/`)}>
                <GroupCardMobile key={group.id} group={group} isAllFeatures />
              </div>
            );
          })}
        </div>
      )}
      {!!users.length && (
        <div className={styles.resultParagraph}>
          <div className={styles.resultHeading}>People</div>
          {users.map((user) => {
            return <UserSearchResult user={user} />;
          })}
        </div>
      )}
      {!!papers.length && (
        <div className={styles.resultParagraph}>
          <div className={styles.resultHeading}>Papers</div>
          {papers.map((paper) => {
            return (
              <div
                key={paper.id}
                className={styles.searchResulArticle}
                onClick={() => history.push(`/article${paper.id}/`)}
              >
                <img
                  className={styles.searchResImg}
                  src={isValidImageUrl(paper.image || '') || NO_ARTICLE_COVER}
                  alt=""
                />
                <div>
                  <p>{paper.title}</p>
                  <p>{paper.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SearchResult;