import styles from './GroupCardStyles.module.css';

import { User } from '../../types/User';
import { NO_USER_AVATAR } from '../../utils/constantImagesUrl';

export default function GroupUsersImages({ users }: { users: Array<User> }) {
  return (
    <div className={styles.userProfilesSmlIcons} style={{ width: 26 + (users?.length - 1) * 12 + 'px' }}>
      {users?.length
        ? users.slice(0, 4).map((user, index) => {
            return (
              <div key={user.id}>
                <div style={{ left: index ? -index * 7 + 'px' : '0' }} className={styles.userProfileImgBlock}>
                  <img className={styles.userProfileImg} src={user?.profile?.image_url || NO_USER_AVATAR} alt="" />
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}
