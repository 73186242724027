import React, { useEffect, useState } from 'react';
import { BackArrow } from 'images/arrows/BackArrow';
import { ForwardArrow } from 'images/arrows/ForwardArrow';
import { User } from '../../../../types/User';
import Person from './Person';
import AddStudent from './components/AddStudent';
import styles from './peopleStatisticsStyles.module.css';

function PeopleStatistics({ peoples }: { peoples: Array<User> }) {
  const [year, setYear] = useState(new Date());

  return (
    <div className={styles.peopleStatistic}>
      <div className={styles.peopleStatisticHeader}>
        <div className={styles.yarBlock}>
          <div className={styles.arrow} onClick={() => setYear(new Date(year.setFullYear(year.getFullYear() - 1)))}>
            {BackArrow('#757575')}
          </div>
          <div className={styles.yearLabel}>{year.getFullYear()}</div>
          <div className={styles.arrow} onClick={() => setYear(new Date(year.setFullYear(year.getFullYear() + 1)))}>
            {ForwardArrow('#757575')}
          </div>
        </div>
        <AddStudent />
      </div>
      {!!peoples.length && (
        <div className={styles.peopleBlock}>
          {peoples.map((person) => {
            return (
              <div key={person.id}>
                <Person person={person} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default PeopleStatistics;