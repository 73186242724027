import React from 'react';

import styles from './styles.module.css';

import { userNameFromAllParams } from '../../../../helpers/userName';
import { EmergencyObj } from '../../../../types/Emergencies';
import { NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';

function AboutStudent({ emergency }: { emergency: EmergencyObj }) {
  const { user } = emergency;

  return (
    <div className={styles.aboutStudentMain}>
      <img
        style={{ borderRadius: '50%' }}
        src={emergency.user?.profile?.image_url || NO_USER_AVATAR}
        alt=""
        height={50}
        width={50}
      />
      <div className={styles.columnInfo}>
        <div className={styles.columnInfoHdr}>Full Name:</div>
        <div>
          {userNameFromAllParams(
            user?.username,
            user?.profile?.first_name,
            user?.profile?.last_name,
            user?.profile?.nickname,
          )}
        </div>
      </div>

      <div className={styles.columnInfo}>
        <div className={styles.columnInfoHdr}>Personal email:</div>
        <div>{user?.email || '- -'}</div>
      </div>

      <div className={styles.columnInfo}>
        <div className={styles.columnInfoHdr}>Date of birth:</div>
        <div>{user?.profile?.birth_date || '- -'}</div>
      </div>

      <div className={styles.columnInfo}>
        <div className={styles.columnInfoHdr}>Phone number:</div>
        <div>{user?.profile?.phone || '- -'}</div>
      </div>

      <div className={styles.columnInfo}>
        <div className={styles.columnInfoHdr}>Address:</div>
        <div>
          {emergency.state} {emergency.town} {emergency.address}
        </div>
      </div>
    </div>
  );
}

export default AboutStudent;
