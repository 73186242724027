export const pinIcon = (color = '#EDEFF0') => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.72722 11.2727L2.5258 15.4741M3.54524 8.09075L9.9092 14.4547L10.7092 13.6547C10.8783 13.4854 10.9968 13.2724 11.0514 13.0395C11.106 12.8066 11.0945 12.5632 11.0183 12.3364L10.6183 11.1182C10.5421 10.8914 10.5307 10.6479 10.5853 10.4151C10.6399 10.1822 10.7584 9.96915 10.9274 9.79992L13.0912 7.63618L13.5457 8.09075C13.7869 8.33186 14.1139 8.46732 14.4549 8.46732C14.7959 8.46732 15.1229 8.33186 15.364 8.09075C15.6051 7.84963 15.7406 7.5226 15.7406 7.18161C15.7406 6.84062 15.6051 6.51359 15.364 6.27247L11.7275 2.63592C11.4864 2.3948 11.1593 2.25935 10.8183 2.25935C10.4773 2.25935 10.1503 2.3948 9.9092 2.63592C9.66808 2.87704 9.53262 3.20407 9.53262 3.54506C9.53262 3.88605 9.66808 4.21308 9.9092 4.4542L10.3638 4.90877L8.20002 7.07251C8.0308 7.24156 7.81777 7.36005 7.58488 7.41466C7.352 7.46926 7.10851 7.45782 6.88177 7.38162L5.66353 6.9816C5.4368 6.9054 5.1933 6.89396 4.96042 6.94856C4.72754 7.00317 4.51451 7.12166 4.34528 7.29071L3.54524 8.09075Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
