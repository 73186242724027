export const snakeIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.7265 21.7579V19.7799C26.7265 18.1492 25.3977 16.8053 23.7519 16.8053H18.7994V14.4952C18.7994 13.861 19.0108 13.2268 19.4034 12.7135L22.5591 8.51591C22.695 8.34982 22.7554 8.13844 22.7554 7.92705V4.95252C22.7554 2.21957 20.5358 0 17.8029 0H11.8689C9.13595 0 6.91639 2.21957 6.91639 4.95252V7.92705C6.91639 8.13844 6.99188 8.34982 7.11268 8.51591L10.2684 12.7135C10.661 13.2268 10.8724 13.861 10.8724 14.4952V16.8204H5.95004C4.31933 16.8204 2.97551 18.1492 2.97551 19.795V21.773C1.3448 21.773 0.000976562 23.1017 0.000976562 24.7475V26.7255C0.000976562 28.3562 1.3297 29.7 2.97551 29.7H26.7114C28.3421 29.7 29.6859 28.3713 29.6859 26.7255V24.7475C29.701 23.0866 28.3572 21.7579 26.7265 21.7579ZM11.884 1.97799H17.818C19.4487 1.97799 20.7925 3.30671 20.7925 4.95252V5.3602L15.3417 8.47062C15.0397 8.63671 14.6622 8.63671 14.3603 8.47062L8.90947 5.3602V4.95252C8.92457 3.30671 10.2533 1.97799 11.884 1.97799ZM11.884 11.5206L9.00007 7.68546L13.3939 10.1919C13.5449 10.2825 13.711 10.3278 13.8771 10.3882V12.5625C13.8771 13.1061 14.315 13.559 14.8736 13.559C15.4172 13.559 15.8702 13.1212 15.8702 12.5625V10.3882C16.0363 10.3278 16.2024 10.2825 16.3534 10.1919L20.7472 7.68546L17.8633 11.5206C17.2291 12.3662 16.8667 13.4231 16.8667 14.4952V16.8204H12.9107V14.4952C12.8805 13.4231 12.5333 12.3662 11.884 11.5206ZM4.9686 19.7799C4.9686 19.2363 5.40647 18.7833 5.96514 18.7833H23.767C24.3106 18.7833 24.7636 19.2212 24.7636 19.7799V21.7579H4.98369V19.7799H4.9686ZM27.723 26.7104C27.723 27.2539 27.2851 27.7069 26.7265 27.7069H2.99061C2.44704 27.7069 1.99407 27.269 1.99407 26.7104V24.7324C1.99407 24.1888 2.43195 23.7359 2.99061 23.7359H26.7265C27.27 23.7359 27.723 24.1737 27.723 24.7324V26.7104Z"
      fill="#333333"
    />
    <path
      d="M17.3344 5.54143C17.878 5.54143 18.331 5.10355 18.331 4.54488V3.95602C18.331 3.41245 17.8931 2.95947 17.3344 2.95947C16.7909 2.95947 16.3379 3.39735 16.3379 3.95602V4.54488C16.3379 5.10355 16.7909 5.54143 17.3344 5.54143Z"
      fill="#333333"
    />
    <path
      d="M12.3823 5.54143C12.9259 5.54143 13.3788 5.10355 13.3788 4.54488V3.95602C13.3788 3.41245 12.941 2.95947 12.3823 2.95947C11.8387 2.95947 11.3857 3.39735 11.3857 3.95602V4.54488C11.4008 5.10355 11.8387 5.54143 12.3823 5.54143Z"
      fill="#333333"
    />
  </svg>
);
