import { Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useToken } from '../../../cookies';
import { getMyArticles, removeArticle } from '../../../store/articles';
import { selectUserId } from '../../../store/auth';
import styles from './UserComponents.module.css';

export default function DeleteArticleModal({
  isOpen,
  setIsOpen,
  id,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  id: string;
}) {
  const dispatch = useDispatch();
  const { token } = useToken();

  const loggedUser = useSelector(selectUserId);

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={openModal}
        contentLabel="My"
        className={styles.modal}
        overlayClassName="myoverlay"
        closeTimeoutMS={400}
      >
        <div>Are you sure you want to delete this article?</div>
        <div className={styles.deleteBlock}>
          <button
            className={styles.yesButton}
            onClick={() => {
              if (id.length) {
                dispatch(removeArticle({ token, id }));
                setTimeout(() => {
                  dispatch(getMyArticles({ token, userId: loggedUser }));
                  setIsOpen(false);
                }, 700);
              }
            }}
          >
            Yes
          </button>
          <button className={styles.cancelButton} onClick={() => setIsOpen(false)}>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
}
