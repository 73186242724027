import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorGuard } from '../../utils/errorGuard';
import { ERROR } from '../../errorsTemplates';
import { isPendingAction, isRejectedAction } from '../../utils/redux.utils';
import { MoodApiService } from '../../services/mood.service';
import { getDay, getMoodObjByValue, isDateToday } from './helper';
import { IDayMoodForAllGroups, IUserMoodOneGroup } from '../../types/Moods';
import { weekDays } from '../../helpers/date';

export const MOODS_REDUCER_NAME = 'moods';

type InitialStateType = {
  allGroupsMoods: Array<IDayMoodForAllGroups>;
  myGroupMoods: { groupId: string; moods: Array<IUserMoodOneGroup>; average: number };
  checkedGroupsToday: { averageMood: number; groups: number };
  error: string | null;
  myWeekAverage: Array<IUserMoodOneGroup>;
};

const initialState: InitialStateType = {
  allGroupsMoods: [],
  myGroupMoods: { groupId: '', moods: [], average: 0 },
  myWeekAverage: [],
  checkedGroupsToday: { averageMood: -1, groups: 0 },
  error: null,
};

export const getAllGroupsMoods = createAsyncThunk(
  `${MOODS_REDUCER_NAME}/getAllGroupsMoods`,
  async ({ token, from, to }: { token: string; from: string; to: string }) => {
    try {
      const response = await MoodApiService.getAllUsersMoodHistory(token, from, to);
      return response.data;
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

export const getMyMoodsPerGroup = createAsyncThunk(
  `${MOODS_REDUCER_NAME}/getMyMoodsPerGroup`,
  async ({ token, groupId, from, to }: { token: string; groupId: string; from: string; to: string }) => {
    try {
      const response = await MoodApiService.getMyMoodsPerGroupHistory(token, groupId, from, to);
      return { data: response.data, groupId };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

const moodsSlice = createSlice({
  name: MOODS_REDUCER_NAME,
  initialState,
  reducers: {
    resetAllGroupsMoods(state) {
      state.allGroupsMoods = [];
    },
    resetMyGroupMoods(state) {
      state.myGroupMoods = initialState.myGroupMoods;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        getAllGroupsMoods.fulfilled,
        (state, { payload }: PayloadAction<{ mood_graph: { [val: string]: { [val: number]: number } } }>) => {
          const { mood_graph } = payload || { mood_graph: [] };

          const reformedDaysObj: { [val: string]: { [val: number]: number } } = Object.entries(mood_graph).reduce(
            (newObj, [key, val]) => {
              if (isDateToday(key)) {
                const todayData = Object.entries(val).reduce(
                  (partialSum, [k, v]) => {
                    return {
                      averageMood: partialSum.averageMood + Number(k) * Number(v),
                      groups: partialSum.groups + Number(v),
                    };
                  },
                  { averageMood: 0, groups: 0 },
                );
                state.checkedGroupsToday.averageMood = todayData.groups
                  ? Math.round(todayData.averageMood / todayData.groups)
                  : -1;
                state.checkedGroupsToday.groups = todayData.groups;
              }
              return { ...newObj, [getDay(key) as string]: val };
            },
            {},
          );

          const weeklyAveragePerDay: { [val: string]: { [val: number]: number } } = Object.entries(
            reformedDaysObj,
          ).reduce((newObj, [key, val]) => {
            const sum = Object.values(val).reduce((partialSum, a) => partialSum + a, 0);
            const dayAverage = Object.entries(val).reduce((partialSum, [_key, _val]) => {
              return Number(_key) * _val + partialSum;
            }, 0);
            return { ...newObj, [key]: Math.round(dayAverage / sum) };
          }, {});
          // @ts-ignore
          state.myWeekAverage = weekDays.map((day) => {
            return { ...{ name: day }, pv: weeklyAveragePerDay[day] ?? null };
          });

          state.allGroupsMoods = weekDays.map((e) => {
            const changeable = {
              name: e,
              happy: { val: 8, times: 0 },
              confident: { val: 7, times: 0 },
              excited: { val: 6, times: 0 },
              confused: { val: 5, times: 0 },
              curious: { val: 4, times: 0 },
              tired: { val: 3, times: 0 },
              angry: { val: 2, times: 0 },
              frustrated: { val: 1, times: 0 },
              sad: { val: 0, times: 0 },
            };
            if (reformedDaysObj[e]) {
              Object.entries(reformedDaysObj[e]).forEach(([key, val]) => {
                changeable[getMoodObjByValue(Math.round(Number(key)))] = {
                  val: Math.round(Number(key)),
                  times: val,
                };
              });
            }
            return changeable;
          });
          state.error = null;
        },
      )
      .addCase(getMyMoodsPerGroup.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const { data } = payload;
        const reformedDaysObj: { [val: string]: number } = data?.mood_graph
          ? Object.entries(data?.mood_graph).reduce((newObj, [key, val]) => {
              // @ts-ignore
              return { ...newObj, [getDay(key) as string]: Math.round(Number(Object.keys(val)[0])) };
            }, {})
          : {};

        const sum = Object.values(reformedDaysObj).reduce((partialSum, a) => partialSum + a, 0);

        state.myGroupMoods = {
          average: Math.round(sum / Object.values(reformedDaysObj).length) ?? 0,
          groupId: payload.groupId,
          moods: weekDays.map((day) => {
            return { ...{ name: day }, pv: reformedDaysObj[day] ?? null };
          }),
        };
        state.error = null;
      })
      .addMatcher(isPendingAction(`${MOODS_REDUCER_NAME}/`), (state) => {
        state.error = null;
      })
      .addMatcher(isRejectedAction(`${MOODS_REDUCER_NAME}/`), (state, { error }) => {
        state.error = 'Moods error';
      });
  },
});

export const { resetAllGroupsMoods, resetMyGroupMoods } = moodsSlice.actions;
export default moodsSlice.reducer;
