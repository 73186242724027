import React from 'react';
import ResetAccount from './ResetAccount';
import { useIsMobile } from '../../utils/styles.utils';
import ResetAccountMob from './ResetAccountMob';
import { getPasswordError } from '../../utils/errorGuard';
import MessageHockPopUp from '../../hooks/MessageHockPopUp';
import { StatisticsApiService } from '../../services/statistics.service';
import {useHistory} from "react-router";

function Recover() {
  const isMobile = useIsMobile();
  const history = useHistory();

  const submit = (password: string, confirmPassword: string, termsAndCondAccepted: boolean, params:any) => {
    if (getPasswordError(password, confirmPassword, termsAndCondAccepted)) {
      MessageHockPopUp({
        message: getPasswordError(password, confirmPassword, termsAndCondAccepted),
        timeOut: 4000,
        type: 'warning',
        size: `${isMobile ? 'small' : ''}`,
      });
    } else {
      if (params.id) {
        StatisticsApiService.resetPassword({ password, recoverCode: params.id })
          .then((res) => {
            MessageHockPopUp({
              message: res.data,
              timeOut: 4000,
              type: res.ok ? 'success' : 'error',
              size: `${isMobile ? 'small' : ''}`,
            });
          })
          .finally(() => history.push('/login'));
      }
    }
  };

  return isMobile ? <ResetAccountMob submit={submit}  /> : <ResetAccount submit={submit} />;
}

export default Recover;