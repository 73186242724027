import React from 'react';
import { NewlineText } from './textHelper';
import styles from './styles.module.css';

function WhoUseVext() {
  const text =
    'VEXT is for users 13 and above. \n' +
    '\n' +
    'VEXT supports groups of people who are looking for ways to share their moods, emotions, and feelings in group or organizational structures. To build community around their whole self, but in a private way. For organizations who partner with VEXT, we provide anonymous means of connecting users with mental health support services. We NEVER reveal your identity unless you consent (or unless required by law).\n' +
    '\n' +
    'VEXT has a special interest in supporting adolescents and young adults in high school, college, and early in their careers.\n' +
    '\n' +
    '    VEXT is a place for students, professionals, parnets, first responders, and all others to conduct the business of being a young or experienced person working to navigate school, work, social networks, sports, social activities, part time jobs, volunteer work, political polarization, personal/professional traumas, and all the many other extra curriculars that are part of life.\n' +
    '\n' +
    '    People who are using VEXT are those who want to collaborate by giving and getting help with questions about specific intersts. To use school as one example, users leverage VEXT for catching up on notes from a lecture, test reviews, project requirements, work/sports/school relationship challenges, or simply connecting with others to understand different possibilities for how to navigate a challenge.\n' +
    '\n' +
    "    It's also used by those of us who simply don't want to feel like we are the only one experiencing any of a wide range of emotions. ";
  return <div className={styles.textBlock}>{NewlineText(text)}</div>;
}

export default WhoUseVext;
