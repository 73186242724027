import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChartLines from './components/ChartLines';
import PeriodMenu from './components/PeriodMenu';
import styles from './dashboardStyles.module.css';

import { useToken } from '../../../cookies';
import { thisMonthFromToDate, thisWeekFromToDateISO, todayFromToDate } from '../../../helpers/date';
import { getDashboardEmergencies } from '../../../store/dashboard/reducer';
import {
  selectDashboardOutliers,
  selectDashboardOutliersAmount,
  selectDashboardOutliersMaxPerHour,
  selectDashboardOutliersPercent,
} from '../../../store/dashboard/selector';
import { GraphPeriods } from '../../../utils/enums';
import { useIsMobile, useIsTablet } from '../../../utils/styles.utils';


function Outliers() {
  const [selectedPeriod, setSelectedPeriod] = useState<GraphPeriods>(GraphPeriods.LAST_24_HOURS);
  const [periodUnit, setPeriodUnit] = useState<'day' | 'week' | 'month'>('day');
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSmallDevice = isMobile || isTablet;
  const { token } = useToken();

  const outliersAmount = useSelector(selectDashboardOutliersAmount);
  const outliers = useSelector(selectDashboardOutliers);
  const maxOutliersPerHour = useSelector(selectDashboardOutliersMaxPerHour);
  const outliersPercent = useSelector(selectDashboardOutliersPercent);

  useEffect(() => {
    switch (selectedPeriod) {
      case GraphPeriods.LAST_24_HOURS: {
        setPeriodUnit('day');
        dispatch(
          getDashboardEmergencies({
            token,
            periodType: 'day',
            from: todayFromToDate().from,
            to: todayFromToDate().to,
            isOutliers: true,
          }),
        );
        return;
      }
      case GraphPeriods.LAST_WEEK: {
        setPeriodUnit('week');
        dispatch(
          getDashboardEmergencies({
            token,
            periodType: 'week',
            from: thisWeekFromToDateISO().from,
            to: thisWeekFromToDateISO().to,
            isOutliers: true,
          }),
        );
        return;
      }
      case GraphPeriods.LAST_MONTH: {
        setPeriodUnit('month');
        dispatch(
          getDashboardEmergencies({
            token,
            periodType: 'month',
            from: thisMonthFromToDate().from,
            to: thisMonthFromToDate().to,
            isOutliers: true,
          }),
        );
        return;
      }
    }
  }, [selectedPeriod]);

  return (
    <div
      className={styles.topBlock}
      style={{ width: isSmallDevice ? '97%' : undefined, height: isSmallDevice ? '28vh' : undefined }}
    >
      <div className={styles.topBlockContent}>
        <div className={styles.topBlockTitle}>Outliers</div>
        <div style={{ color: '#34B53A' }} className={styles.percent}>
          {outliersPercent >= 0 ? `+${outliersPercent.toFixed(0)}%` : `-${outliersPercent.toFixed(0)}%`}
        </div>
        <div className={styles.infoIcon}>i</div>
        <PeriodMenu selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} />
      </div>

      <div className={styles.topBlockContent}>
        <div className={styles.quantity}>{outliersAmount}</div>
        <div>
          <ChartLines
            color={'#FF3A29'}
            tick={periodUnit}
            data={outliers}
            periodLength={outliers.length}
            yMax={maxOutliersPerHour}
            labelType={'Outliers'}
          />
        </div>
      </div>
    </div>
  );
}

export default Outliers;