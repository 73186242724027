import { Admin, User } from './User';

export interface IGroup {
  id: string;
  name: string;
  description: string;
  is_private: boolean;
  group_photo: string | null;
  admin?: Admin;
  users: Array<User>;
  allow_incognito: 'NO' | 'YES';
  creation_date: string;
  domain: {
    id: string;
    name: string;
    cover_image: string;
    email_prefix: string[];
    groups: number;
    image: string;
    location: string;

  };
  general_average_weekly_mood?: number;
  my_mood_today: {
    mood_value: number | null;
    recording_date: string;
  };
}

export enum Group_Tabs {
  FEED = 'FEED',
  QUESTIONS = 'QUESTIONS',
  MOODS = 'MOODS',
}
