import React from 'react';
import { ReactComponent as Instagram } from '../../assets/socialIcons/Instagram.svg';
import { ReactComponent as TikTok } from '../../assets/socialIcons/TikTok.svg';

import FooterLinks from '../../components/Footer/components/FooterLinks';
import styles from '../landingStyles.module.css';

function LandingFooter() {
  const openInstagram = () => {
    window.open('https://www.instagram.com/vext_vext/', '_blank');
  };
    const openTikTok = () => {
        window.open('https://www.tiktok.com/@vext22?is_from_webapp=1&sender_device=pc', '_blank');
    };
  return (
    <div className={styles.landingFooter}>
      <div>
        <Instagram style={{ margin: '0 11px 0 0', cursor: 'pointer' }} onClick={openInstagram} />
        <TikTok style={{ margin: '0 11px 0 0', cursor: 'pointer' }} onClick={openTikTok}  />
      </div>
      <FooterLinks />
    </div>
  );
}

export default LandingFooter;