import React, { Dispatch, useLayoutEffect, useRef, useState } from 'react';
import { getColorByMood } from '../../../../components/Recharts/RechartsGroupMood';
import GroupDetails from './GroupDetails';
import { IGroup } from '../../../../types/Group';
import styles from '../dashboardStyles.module.css';
import PopUp from './PopUp';

type Props = {
  moodVal: number;
  index: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  activeIndex: number;
  groupId: string;
  setSelectedGroup: Dispatch<React.SetStateAction<IGroup | null>>;
  selectedGroup: IGroup | null;
};

function MoodSquare({ moodVal, index, setActiveIndex, activeIndex, groupId, setSelectedGroup, selectedGroup }: Props) {
  const handleClick = () => {
    if (activeIndex === index) {
      setActiveIndex(-1);
      setSelectedGroup(null);
    } else {
      setActiveIndex(index);
    }
  };
  const clickOutside = () => {
    setActiveIndex(-1); //commit if we want to keep group pop-up open
  };

  return (
    <div
      onClick={handleClick}
      className={`${styles.square} ${activeIndex === index && styles.active}`}
      style={{
        backgroundColor: activeIndex === index ? '' : getColorByMood(moodVal),
        color: activeIndex === index ? getColorByMood(moodVal) : '',
      }}
    >
      {index === activeIndex && 'X'}
      {index === activeIndex && (
        <PopUp width={400} height={290} activeIndex={activeIndex} clickOutside={clickOutside}>
          <GroupDetails setSelectedGroup={setSelectedGroup} selectedGroup={selectedGroup} groupId={groupId} />
        </PopUp>
      )}
    </div>
  );
}

export default MoodSquare;
