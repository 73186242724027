import { css } from 'styled-components/macro';
import { theme } from '../../../themes/variables';
import { Button } from '../../layout/Button';
import { IRegisterFormProps } from '../../../types/Auth';
import { useDispatch } from 'react-redux';
import { checkboxChecked } from 'images/checkboxes/checkboxChecked';
import { useEffect, useState } from 'react';
import { checkboxUnchecked } from 'images/checkboxes/checkboxUnchecked';
import { AuthApiService } from '../../../services/auth.service';
import AuthForm from './AuthForm';
import { setAuthError, setRegisterId } from 'src/store/auth';
import { ERROR } from 'src/errorsTemplates';
import { TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useIsMobile } from '../../../utils/styles.utils';
import { darkTheme, lightTheme } from '../../../themes/themes';
import MessageHockPopUp from '../../../hooks/MessageHockPopUp';
import styles from '../../Footer/Footer.module.css';

export default function RegisterModal({
  setRegisterFormVisible,
  setConfirmCodeFormVisible,
  email,
  password,
  repeatPassword,
  mailPrefix,
  setEmail,
  setMailPrefix,
  setPassword,
  setRepeatPassword,
}: IRegisterFormProps) {
  const [isChecked, setIsChecked] = useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [userName, setUserName] = useState('');
  const [focusedRepeatPass, setFocusedRepeatPass] = useState(false);
  const [userNameFocused, setUserNameFocused] = useState(false);

  const onRepeatPassFocus = () => setFocusedRepeatPass(true);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const isAllCompleted = () => {
    return email.length && password.length > 6 && repeatPassword === password && userName.length;
  };

  useEffect(() => {
    if (focusedRepeatPass) {
      if (repeatPassword.length < 1) {
        setRepeatPasswordError('Not allowed empty field');
      } else if (repeatPassword.length < 8) {
        setRepeatPasswordError('Password should contain minimum 8 characters');
      } else if (repeatPassword !== password) {
        setRepeatPasswordError('Make sure your passwords match');
      } else {
        setRepeatPasswordError('');
      }
    }
    // eslint-disable-next-line
  }, [repeatPassword, password]);

  useEffect(() => {
    if (userNameFocused) {
      if (userName.length < 3) {
        setUserNameError('User name should contain at least 3 characters!');
      } else {
        setUserNameError('');
      }
    }
  }, [userName, userNameFocused]);

  const register = async () => {
    if (!isAllCompleted()) return;
    try {
      const response = await AuthApiService.register(email + mailPrefix, password, userName);
      if (response.ok) {
        dispatch(setRegisterId(response?.data?.id || ''));
        setConfirmCodeFormVisible(true);
        setRegisterFormVisible(false);
      } else {
        try {
          MessageHockPopUp({
            size: 'small',
            message: `Error: ${response.data ? JSON.stringify(response.data) : 'No logs for this error'}`,
            timeOut: 4000,
            type: 'error',
          });
        } catch (e) {
          MessageHockPopUp({
            size: 'small',
            message: 'Error: Response cannot be parsed!',
            timeOut: 4000,
            type: 'error',
          });
        }
      }
    } catch (e) {
      dispatch(setAuthError(ERROR.common.something_went_wrong));
    }
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
      `}
    >
      <ThemeProvider theme={isMobile ? darkTheme : lightTheme}>
        <div
          css={css`
            width: 100%;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            margin-bottom: 14px;
            box-sizing: border-box;
          `}
        >
          <AuthForm
            email={email}
            password={password}
            mailPrefix={mailPrefix}
            setEmail={setEmail}
            setMailPrefix={setMailPrefix}
            setPassword={setPassword}
          />
          <TextField
            size={isMobile ? 'small' : 'medium'}
            onFocus={onRepeatPassFocus}
            id="outlined-repeat-password-input"
            label="Repeat Password"
            type="password"
            onChange={(e) => setRepeatPassword(e.target.value)}
            error={!!repeatPasswordError}
            helperText={repeatPasswordError}
            style={{ marginBottom: '8px' }}
          />
          <TextField
            size={isMobile ? 'small' : 'medium'}
            onFocus={() => setUserNameFocused(true)}
            onBlur={() => setUserNameError('')}
            id="outlined-user-name"
            label="User name"
            type="text"
            onChange={(e) => setUserName(e.target.value)}
            error={!!userNameError}
            helperText={userNameError}
          />
        </div>
        <div
          css={css`
            width: 100%;
            font-size: 14px;
            display: flex;
            margin-bottom: 10px;
          `}
        >
          <div
            css={css`
              margin-right: 8px;
              cursor: pointer;
              display: flex;
            `}
            onClick={() => setIsChecked(!isChecked)}
          >
            {isChecked ? checkboxChecked() : checkboxUnchecked()}
          </div>

          <div
            css={css`
              color: ${isMobile ? 'white' : 'black'};
            `}
          >
            I agree to the{' '}
            <a
              href="https://www.freeprivacypolicy.com/live/29f04f1a-bf44-46eb-9c43-00093fa79702"
              target="_blank"
              rel="noreferrer"
              className={styles.footerLink}
            >
              <span style={{ color: '#f6cf0d' }}>privacy policy </span>
            </a>
            and{' '}
            <a
              href="https://docs.google.com/document/d/1uUR2bg4Xgjku_pdoPOByX5lyUHZQZbZ75hBFhOIu1nU/edit"
              target="_blank"
              rel="noreferrer"
              className={styles.footerLink}
            >
              <span style={{ color: '#f6cf0d' }}>terms and conditions </span>
            </a>
          </div>
        </div>
        <Button
          onClick={register}
          css={css`
            height: 40px;
            background-color: transparent;
            border: ${isMobile ? '1px solid white' : '1px solid green'};
            color: ${isChecked && isAllCompleted() ? (isMobile ? theme.colors.white : 'black') : 'gray'};
            margin-bottom: 10px;

            :hover {
              background-color: ${isChecked && isAllCompleted() ? theme.colors.green_2 : '#DEDADA'};
              color: ${isChecked && isAllCompleted() ? theme.colors.white : 'black'};
              cursor: ${isChecked && isAllCompleted() ? 'pointer' : 'not-allowed'};
            }
          `}
          type={isChecked && isAllCompleted() ? 'submit' : 'button'}
        >
          Register
        </Button>
        <span
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            cursor: pointer;
            font-family: sans-serif;
            text-align: center;
            ${isMobile && `color: #cccccc;`}
          `}
          onClick={() => setRegisterFormVisible(false)}
        >
          Back
        </span>
      </ThemeProvider>
    </div>
  );
}
