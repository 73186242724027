import { User } from './User';

export enum REPORT_CATEGORIES {
  OFFENSIVE_CONTENT = 'Offensive content',
  TRIGGERING_CONTENT = 'Triggered content',
  NEED_HELP = 'A friend needs help',
}

export enum REPORT_TYPES {
  GROUP = 'GROUP',
  ARTICLE = 'ARTICLE',
  QUESTION = 'QUESTION',
  COMMENT = 'COMMENT',
}

export enum REPORT_STATUS {
  NEW = 'NEW',
  ACTION_TAKEN = 'ACTION_TAKEN',
  UNRESOLVED = 'UNRESOLVED',
  IGNORED = 'IGNORED',
  RESOLVED = 'RESOLVED',
}

export interface Report {
  id: string;
  created_at: string;
  report_author: Partial<User>;
  report_object_id: string;
  report_object_type: string;
  report_reason_description: string;
  report_reason_title: string;
  report_status: REPORT_STATUS;
  updated_at: string;
}

export interface UpdateReport {
  id: string;
  created_at: string;
  report_author: string;
  report_object_id: string;
  report_object_type: string;
  report_reason_description: string;
  report_reason_title: string;
  report_status: string;
  updated_at?: any;
}

export type ReportHandlerType = {
  report_reason_title: REPORT_CATEGORIES;
  report_reason_description: string;
  report_object_id: string;
  report_object_type: string;
};

export interface AdminReport {
  count: number;
  next: null;
  previous: null;
  results: Report[];
}

export type ReportedElement = {
  type: string;
  ownerName: string;
  image: string;
  name: string;
  ownerId: string;
};
