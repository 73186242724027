import { ApiResponse } from 'apisauce';
import { _RestApiService } from './api.service';
import { Report, AdminReport, UpdateReport } from '../types/Reports';

const reportsApi = new _RestApiService(window._env_.REACT_APP_BACKEND_URL || '');

export class _ReportsApiService {
  getReport(token: string, reportId: string): Promise<ApiResponse<Report>> {
    return reportsApi.get(`/admin/reports/${reportId}`, {}, reportsApi.getAuthenticateHeader(token));
  }

  getReports(token: string,pageSize:number,page:number): Promise<ApiResponse<AdminReport>> {
    return reportsApi.get('/admin/reports/', {page,page_size:pageSize}, reportsApi.getAuthenticateHeader(token));
  }

  createReport(token: string, data: Partial<Report>) {
    return reportsApi.post('/admin/reports/', data, reportsApi.getAuthenticateHeader(token));
  }

  updateReport(token: string, updatedData: UpdateReport): Promise<ApiResponse<Report>> {
    return reportsApi.patch(
      `/admin/reports/${updatedData.id}/`,
      { ...updatedData },
      reportsApi.getAuthenticateHeader(token),
    );
  }

  deleteReport(token: string, reportId: string): Promise<ApiResponse<Report>> {
    return reportsApi.delete(`/admin/reports/${reportId}/`, {}, reportsApi.getAuthenticateHeader(token));
  }
}

export const ReportsApiService = new _ReportsApiService();
