import { useIsMobile } from 'src/utils/styles.utils';
import { css } from 'styled-components/macro';
import { Paper } from '../../../types/Paper';
import ArticleCard from '../ArticleCard/ArticleCard';
import { useRef } from 'react';
import { getArticles } from '../../../store/articles';
import { useDispatch, useSelector } from 'react-redux';
import { useToken } from '../../../cookies';
import { selectArticlesNext, selectArticlesPage } from '../../../store/articles/selector';

export default function ArticleGrid(props: { articles: Array<Paper>,groupId?:string }) {
  const isMobile = useIsMobile();
  const listInnerRef = useRef(null);
  const dispatch = useDispatch();
  const { token } = useToken();
  const hasNextPage = useSelector(selectArticlesNext);
  const articlesPage = useSelector(selectArticlesPage);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight + 1 > scrollHeight && hasNextPage && props.groupId) {
        dispatch(getArticles({ token: token, groupId: props.groupId  ,page:articlesPage+1}));
      }
    }
  };

  return (
    <div
      ref={listInnerRef}
      onScroll={() => onScroll()}
      css={css`
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        ${isMobile
          ? `justify-content: center;
              background-color:black;
              margin-bottom:22%;
          `
          : `justify-content: ${props.articles.length < 4 ? 'flex-start;' : 'space-between;'}`}
        overflow-y: scroll;
        overflow-x: hidden;
        &&::-webkit-scrollbar {
          display: none;
        }
      `}
    >
      {/*TODO simplify this nested components structure */}
      {props.articles && props.articles.map((article, key) => <ArticleCard key={key} article={article} />)}
    </div>
  );
}
