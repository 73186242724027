import { Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearSelectedPaper } from '../../store/articles';
import { Paper } from '../../types/Paper';
import { ViewQuestionBody, ViewQuestionHeader } from './components';
import styles from './ArticleView.module.css';

export default function ViewQuestion({
  isModalVisible,
  article,
  setModalVisibility,
}: {
  isModalVisible: boolean;
  article: Paper;
  setModalVisibility: Dispatch<SetStateAction<boolean>>;
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(clearSelectedPaper());
    setModalVisibility(false);
    history.push(`/feed`);
  };

  return (
    <div>
      <Modal
        isOpen={isModalVisible}
        onRequestClose={openModal}
        className={styles.questionModalMain}
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className={styles.questionModal}>
          <ViewQuestionHeader article={article} />
          <ViewQuestionBody />
        </div>
      </Modal>
    </div>
  );
}
