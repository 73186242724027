import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { closeMobileIcon } from 'images/closeMobileIcon';
import { createComment, getPaperById } from '../../../../store/articles';
import { useToken } from '../../../../cookies';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../../../../store/auth';
import { selectSelectedPaper } from '../../../../store/articles/selector';
import { Paper } from '../../../../types/Paper';
import ArticleComments from '../ArticleComments';
import { ThemeProvider } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { createTheme } from '@mui/material/styles';
import { findMyAnonymousNameById } from '../../../../helpers/comments';
import { generateRandomNicknameId } from '../../../../helpers/anonymousGenerator';
import { gifIcon } from 'images/gifIcon';
import { SelectedParagraph } from '../../../../pages/Article/ViewArticle';
import styles from './styles.module.css';
import style from '../MobileArticleViewStyles.module.css';

export default function MobileReplyCommentBlock({
  repliedText,
  setIsReplyBlockOpened,
  commentId,
  paragraphId,
  article,
  setSelectedParagraph,
  paragraphComments,
}: {
  repliedText: string;
  setIsReplyBlockOpened: Dispatch<SetStateAction<boolean>>;
  setSelectedParagraph?: Dispatch<SetStateAction<SelectedParagraph | undefined>>;
  commentId?: string;
  paragraphId?: string;
  article?: Paper;
  paragraphComments?: boolean;
}) {
  const [repliedComment, setRepliedComment] = useState('');
  const [isMessageAnonymous, setIsMessageAnonymous] = useState(false);
  const { token } = useToken();
  const dispatch = useDispatch();
  const paper = useSelector(selectSelectedPaper) as Paper;
  const loggedUserId = useSelector(selectUserId);

  useEffect(() => {
    if (!paragraphId && !commentId && article) {
      dispatch(getPaperById({ token, id: article.id }));
    }
    // eslint-disable-next-line
  }, []);

  const replayAComment = () => {
    if (repliedComment.length && !paragraphId && !article) {
      if (isMessageAnonymous) {
        const randomName: string = findMyAnonymousNameById(paper, loggedUserId)
          ? (findMyAnonymousNameById(paper, loggedUserId) as string)
          : generateRandomNicknameId();
        dispatch(
          createComment({
            token,
            articleId: paper.id,
            comment: repliedComment,
            userId: loggedUserId,
            repliedToCommentId: commentId,
            anonymous_name: randomName,
          }),
        );
      } else {
        dispatch(
          createComment({
            token,
            articleId: paper.id,
            comment: repliedComment,
            userId: loggedUserId,
            repliedToCommentId: commentId,
          }),
        );
      }
    }

    if (repliedComment.length && paragraphId && !article) {
      if (isMessageAnonymous) {
        const randomName: string = findMyAnonymousNameById(paper, loggedUserId)
          ? (findMyAnonymousNameById(paper, loggedUserId) as string)
          : generateRandomNicknameId();
        dispatch(
          createComment({
            token,
            articleId: paper.id,
            comment: repliedComment,
            userId: loggedUserId,
            paragraphId,
            anonymous_name: randomName,
          }),
        );
      } else {
        dispatch(
          createComment({
            token,
            articleId: paper.id,
            comment: repliedComment,
            userId: loggedUserId,
            paragraphId,
          }),
        );
      }
    }

      if (repliedComment.length && !paragraphId && !commentId) {
      dispatch(createComment({ token, articleId: paper.id, comment: repliedComment, userId: loggedUserId }));
    }

      setRepliedComment('');
      setSelectedParagraph && setSelectedParagraph(undefined);
      setIsReplyBlockOpened(false);
  };
  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: 'white',
          },
          colorPrimary: {
            '&.Mui-checked': {
              // Controls checked color for the thumb
              color: 'white',
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.2,
            backgroundColor: '#fff',
            '.Mui-checked.Mui-checked + &': {
              // Controls checked color for the track
              opacity: 1,
              backgroundColor: '#FFC700',
            },
          },
        },
      },
    },
  });

  return (
    <div className={styles.replyCommentMain}>
      <div className={styles.replyCommentHeader}>
        <div
          className={styles.closeButton}
          onClick={() => {
            setSelectedParagraph && setSelectedParagraph(undefined);
            setIsReplyBlockOpened(false);
          }}
        >
          {closeMobileIcon()}
        </div>
        <div>Add comment</div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: repliedText || '',
        }}
        className={styles.repliedCommentTextBlock}
      />

      <div className={styles.replyCommentInputBlock}>
        <input
          onChange={(e) => setRepliedComment(e.target.value)}
          placeholder={'Your comment'}
          value={repliedComment}
          className={styles.replyCommentInput}
          type="text"
        />
        <div className={styles.replyCommentIncognitoBlock}>
          <ThemeProvider theme={theme}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch checked={isMessageAnonymous} onChange={(e) => setIsMessageAnonymous(e.target.checked)} />
                }
                label="Incognito"
              />
            </FormGroup>
          </ThemeProvider>
          <div style={{ display: 'flex', width: '35%', justifyContent: 'space-between', alignItems: 'center' }}>
            {/*  TODO ... functionality for gif */}
            <div className={style.gifIcon}>{gifIcon()} GIF</div>
            <div style={{ color: '#FFC700' }} onClick={replayAComment}>
              Post
            </div>
          </div>
        </div>
      </div>
      <div>{article && <ArticleComments comments={paper.comments} />}</div>
      <div>
        {paragraphComments && (
          <ArticleComments comments={paper.comments} paragraphId={paragraphId} isPhCtsBlock={true} />
        )}
      </div>
    </div>
  );
}