import styles from './styles.module.css';
import { Dispatch, SetStateAction } from 'react';

type AboutYouProps = {
  country: string;
  state: string;
  town: string;
  setCountry: Dispatch<SetStateAction<string>>;
  setState: Dispatch<SetStateAction<string>>;
  setTown: Dispatch<SetStateAction<string>>;
};
export default function PlaceOfResidenceMobileSettings(props: AboutYouProps) {
  const { country, state, town, setCountry, setState, setTown } = props;
  return (
    <div>
      <p className={styles.inputLabel}>Country</p>
      <input type="text" className={styles.inputField} value={country} onChange={(e) => setCountry(e.target.value)} />

      <p className={styles.inputLabel}>State</p>
      <input type="text" className={styles.inputField} value={state} onChange={(e) => setState(e.target.value)} />

      <p className={styles.inputLabel}>Town</p>
      <input type="text" className={styles.inputField} value={town} onChange={(e) => setTown(e.target.value)} />
    </div>
  );
}