import { v4 } from 'uuid';
import { PaperType } from '../../../types/Paper';

const calcDuration = (wordCount: number) => {
  return (wordCount / 183).toFixed(2);
};

export const changedPaperContent = (fieldValue: string) => {
  const htmlParser = document.createElement('div');
  htmlParser.innerHTML = fieldValue;
  const res = Array.from(htmlParser.children).map((el) => {
    return el.innerHTML;
  });

  let newContent = '';
  for (let i = 0; i < res.length; i++) {
    newContent += `<p id="${v4()}"> ${res[i]} </p>`;
  }
  return newContent || '';
};

export const getPaperFormData = ({
  articleTitle,
  articleDescription,
  wordCount,
  fieldValue,
  articleTags,
  articleGroups,
  isCommentsOn,
  isSharePaperOn,
  userId,
  imageInputUrl,
}: {
  articleTitle: string;
  articleDescription: string;
  wordCount: number;
  fieldValue: string;
  articleTags?: Array<{ id: string }>;
  articleGroups?: Array<{ id: string }>;
  isCommentsOn: boolean;
  isSharePaperOn: boolean;
  userId: string;
  imageInputUrl: Blob | null;
}) => {
  const formData = new FormData();
  formData.append('title', articleTitle);
  formData.append('description', articleDescription);
  formData.append('duration', String(Math.round(Number(calcDuration(wordCount)))));
  formData.append('content', changedPaperContent(fieldValue));
  articleTags?.forEach((e) => {
    formData.append('paper_tags', e.id);
  });
  articleGroups?.forEach((e) => {
    formData.append('group', e.id);
  });
  formData.append('type', PaperType.ARTICLE);
  formData.append('isCommentsOn', String(isCommentsOn));
  formData.append('isSharePaperOn', String(isSharePaperOn));
  formData.append('paper_author', userId);
  imageInputUrl && formData.append('image', imageInputUrl);

  return formData;
};