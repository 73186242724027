import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AverageStatisticsChart from './AverageStatisticsChart';

import { RechartsUserMood } from '../../../components/Recharts/RechartsUserMood';
import { useToken } from '../../../cookies';
import {
  getAverageMoodsForSpecificGroup,
  getDetailedMoodsForSpecificGroup,
  selectGroupsStatisticsAverageMoodsByGroupId,
  selectGroupsStatisticsDetailedMoodsByGroupId,
} from '../../../store/statistics';
import { ChartType } from '../../../utils/enums';

function RechartsWrapper({
  selectedChartType,
  groupId,
  fromTo,
}: {
  selectedChartType: ChartType;
  fromTo: { from: string; to: string };
  groupId?: string;
}) {
  const dispatch = useDispatch();
  const { token } = useToken();
  const averageGroupStatisticsMoods = useSelector(selectGroupsStatisticsAverageMoodsByGroupId(groupId || 'all_groups'));
  const detailedGroupStatisticsMoods = useSelector(
    selectGroupsStatisticsDetailedMoodsByGroupId(groupId || 'all_groups'),
  );

  useEffect(() => {
    if (selectedChartType === ChartType.AVERAGE) {
      dispatch(
        getAverageMoodsForSpecificGroup({
          token,
          groupId: groupId,
          from: fromTo.from,
          to: fromTo.to,
        }),
      );
    } else {
      dispatch(
        getDetailedMoodsForSpecificGroup({
          token,
          groupId,
          from: fromTo.from,
          to: fromTo.to,
        }),
      );
    }
  }, [fromTo, selectedChartType]);

  return (
    <div>
      {selectedChartType === ChartType.AVERAGE ? (
        <>
          {!!averageGroupStatisticsMoods.length && <AverageStatisticsChart averageData={averageGroupStatisticsMoods} />}
        </>
      ) : (
        <>
          <RechartsUserMood adminData={detailedGroupStatisticsMoods} />
        </>
      )}
    </div>
  );
}

export default RechartsWrapper;
