import Checkbox from '@mui/material/Checkbox';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MessageHockPopUp from 'src/hooks/MessageHockPopUp';
import { UsersApiService } from 'src/services/users.service';
import { clearNotificationsState } from 'src/store/notifications';
import { clearUserState } from 'src/store/user';



import style from './AuthStyle.module.css';
import AuthForm from './forms/AuthForm';
import EmailCodeConfirm from './forms/EmailCodeConfirm';
import RegisterForm from './forms/RegisterForm';

import { SmallVextLogo } from '../../assets/LogoFrames/SmallVextLogo';
import { useRefreshToken, useToken } from '../../cookies';
import { ERROR } from '../../errorsTemplates';
import { Headers } from '../../helpers/layout';
import ForgotPassword from '../../pages/ResetAccount/ForgotPassword';
import { AuthApiService } from '../../services/auth.service';
import { getMailDomains, selectAuthError, setAuthError, setUserLoginData, updateDeviceToken } from '../../store/auth';
import { useIsMobile } from '../../utils/styles.utils';
import styles from '../Footer/Footer.module.css';
import { Button } from '../layout/Button';
import MobileLoaderScreen from '../layout/MobileLoaderScreen';
import HealthKitPermissions from "../HealthKitPermissions/HealthKitPermissions";

export default function AuthBlock() {
  const [register, setRegister] = useState(false);
  const [codeConfirm, setCodeConfirm] = useState(false);
  const authError = useSelector(selectAuthError);
  const [mailPrefix, setMailPrefix] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [areTermsAccepted, setTermsAccepted] = useState(false);
  const dispatch = useDispatch();
  const { setToken, removeToken } = useToken();
  const { setRefreshToken } = useRefreshToken();
  const history = useHistory();
  const isMobile = useIsMobile();

  const notify = () => {
    authError && MessageHockPopUp({ message: authError, timeOut: 4000, type: 'error', size: 'small' });
  };
  useEffect(() => {
    notify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authError]);

  useEffect(() => {
    dispatch(getMailDomains());
  }, [dispatch]);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const authenticate = async () => {
    setIsLoading(true);
    try {
      if (password.length && email.length) {
        const response = await AuthApiService.signIn(email + mailPrefix, password);

        if (response.data.access) {
          const userData = jwt_decode(response.data.access) as {
            token_type: string;
            exp: number;
            iat: number;
            jti: string;
            user_id: string;
          };

          const user = await UsersApiService.getUser(response.data.access, String(userData.user_id));
          dispatch(setUserLoginData({ email: email + mailPrefix, uuid: String(userData.user_id) }));

          if (user.data?.is_email_confirmed) {
            setToken(response.data.access);
            setRefreshToken(response.data.refresh);
            if (window.deviceToken) {
              dispatch(
                updateDeviceToken({
                  deviceToken: window.deviceToken,
                  token: response.data.access,
                  userId: String(userData.user_id),
                }),
              );
            }
            ReactGA.event({
              category: 'User',
              action: 'Login',
              label: 'Success',
            });
            history.push('/feed');
          } else {
            setCodeConfirm(true);
            dispatch(clearUserState());
            dispatch(clearNotificationsState());
            removeToken();
            MessageHockPopUp({
              size: 'small',
              message: 'Email for this account is not confirmed! Please generate a new code and use it!',
              timeOut: 4000,
              type: 'warning',
            });
            ReactGA.event({
              category: 'User',
              action: 'Login',
              label: 'Failed',
            });
          }
        } else {
          MessageHockPopUp({
            message: response.data.detail || 'UNKNOWN ERROR, please check the console',
            timeOut: 4000,
            type: 'error',
            size: 'small',
          });
          ReactGA.event({
            category: 'User',
            action: 'Login',
            label: 'Failed',
          });
        }
      } else {
        MessageHockPopUp({
          size: 'small',
          message: 'Empty fields not allowed',
          timeOut: 4000,
          type: 'warning',
        });
      }
    } catch (e) {
      ReactGA.event({
        category: 'User',
        action: 'Login',
        label: 'Failed',
      });
      dispatch(setAuthError(ERROR.common.something_went_wrong));
    } finally {
      setIsLoading(false);
    }
  };
  const getHeaderText = () => {
    if (register && !codeConfirm) return 'Registration';
    if (!register && codeConfirm) return 'Confirm code';
    return 'Log in';
  };

  return (
    <div className={!isMobile ? style.authContainer : style.authMobileContainer}>
      <HealthKitPermissions email={email}/>
      {isMobile && <div className={style.mobileLogo}>{SmallVextLogo()}</div>}
      {/* @ts-ignore*/}
      <Headers.H2
        style={
          !isMobile
            ? { marginBottom: '44px' }
            : {
                marginBottom: '24px',
                color: 'white',
              }
        }
      >
        {getHeaderText()}
      </Headers.H2>
      {!register && !codeConfirm && (
        <div className={style.loginBlock}>
          <AuthForm
            email={email}
            password={password}
            mailPrefix={mailPrefix}
            setEmail={setEmail}
            setMailPrefix={setMailPrefix}
            setPassword={setPassword}
            authenticate={authenticate}
          />
          <div className={style.termsAndCdxBlock}>
            <Checkbox
              onChange={(e) => {
                setTermsAccepted(e.target.checked);
              }}
              sx={{
                marginLeft: '-12px',
                marginRight: '8px',
                color: isMobile ? '#f1f1f1' : 'black',
                '&.Mui-checked': {
                  color: isMobile ? '#fdfdfd' : 'green',
                },
              }}
            />
            <div style={{ color: !isMobile ? '#2f2f2f' : '' }}>
              I agree to the{' '}
              <a
                href="https://www.freeprivacypolicy.com/live/29f04f1a-bf44-46eb-9c43-00093fa79702"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                <span style={{ color: '#f6cf0d' }}>privacy policy </span>
              </a>
              and{' '}
              <a
                href="https://docs.google.com/document/d/1uUR2bg4Xgjku_pdoPOByX5lyUHZQZbZ75hBFhOIu1nU/edit"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                <span style={{ color: '#f6cf0d' }}>terms and conditions </span>
              </a>
            </div>
          </div>
          {isLoading && <MobileLoaderScreen />}
          <Button
            border={isMobile ? '1px solid white' : '1px solid black'}
            className={areTermsAccepted ? style.socialButton : style.socialButtonDisable}
            radius={32}
            onClick={() => {
              if (areTermsAccepted) {
                authenticate();
              } else {
                MessageHockPopUp({
                  message: 'Please Agree to Terms and Conditions',
                  timeOut: 4000,
                  type: 'warning',
                  size: 'small',
                });
              }
            }}
          >
            <div style={{ color: isMobile ? '' : 'black' }}>Sign in</div>
          </Button>
          <Button
            className={`${style.registerButton} ${isMobile && style.registerButtonMobile}`}
            radius={2}
            onClick={() => setRegister(true)}
          >
            Register
          </Button>
        </div>
      )}
      {register && !codeConfirm && (
        <RegisterForm
          email={email}
          password={password}
          repeatPassword={repeatPassword}
          mailPrefix={mailPrefix}
          setMailPrefix={setMailPrefix}
          setPassword={setPassword}
          setRepeatPassword={setRepeatPassword}
          setEmail={setEmail}
          setConfirmCodeFormVisible={setCodeConfirm}
          setRegisterFormVisible={setRegister}
        />
      )}
      {!register && codeConfirm && <EmailCodeConfirm email={email + mailPrefix} password={password} />}

      {!register && !codeConfirm && !isMobile && (
        <div className={style.authBottomBlock}>
          <ForgotPassword />
          <span className={style.termsAndConditions}>
            By clicking “Continue with Google/Email/SAML” above, you acknowledge that you have read and understood, and
            agree to {"Vext's"} Terms {'&'} Conditions and Privacy Policy
          </span>
        </div>
      )}
      <span className={style.termsAndCdx} onClick={() => history.push('/contact')}>
        Contacts & support
      </span>
    </div>
  );
}
