import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './style.module.css';
import { useIsMobile } from '../../../utils/styles.utils';

export default function CommentToolTip({
  paragraph,
  setSelectedParagraph,
  openedParagraph,
  setOpenedParagraph,
  setIsReplyBlockOpened,
}: {
  paragraph: string;
  setSelectedParagraph: any;
  openedParagraph: string;
  setOpenedParagraph: any;
  setIsReplyBlockOpened?: Dispatch<SetStateAction<boolean>>;
}) {
  const [isCommentToolTipOpen, setIsCommentToolTipOpen] = useState(false);
  const isMobile = useIsMobile();
  const triggerParagraphComment = (data: string) => {
    const spitedParagraph = data.split('id="');
    const paragraphId: string | undefined = spitedParagraph[1]?.split('"')[0];
    const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>[!0-9]*/g;

    if (paragraphId) {
      setSelectedParagraph({
        id: paragraphId,
        text: data.replace(htmlRegexG, ''),
      });
    }
    setIsCommentToolTipOpen(false);
    isMobile && setIsReplyBlockOpened && setIsReplyBlockOpened(true);
  };

  useEffect(() => {
    if (isCommentToolTipOpen && openedParagraph === paragraph) {
      triggerParagraphComment(paragraph);
    }
  }, [isCommentToolTipOpen, openedParagraph, paragraph]);

  return (
    <div className={styles.commentToolTipRoot}>
      <div
        onClick={() => {
          if (paragraph.search('img') === -1 && paragraph.search('id=""') === -1) {
            setOpenedParagraph(paragraph);
            setIsCommentToolTipOpen(!isCommentToolTipOpen);
          }
        }}
        className={styles.articleViewContentLine}
        style={{
          backgroundColor:
            (isCommentToolTipOpen && openedParagraph === paragraph && (isMobile ? '#73737373' : '#75757522')) || '',
        }}
        dangerouslySetInnerHTML={{
          __html: paragraph || '',
        }}
      />
    </div>
  );
}
