import { Button } from '../layout/Button';
import UserInfo from '../User/UserInfo';
import { ReactComponent as Vector } from 'images/Vector.svg';
import { selectSelectedPaper } from '../../store/articles/selector';
import { useDispatch, useSelector } from 'react-redux';
import { IComment, Paper } from '../../types/Paper';
import { selectLoggedUserData } from '../../store/user/selectors';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { createComment } from '../../store/articles';
import CommentsList from './CommentsList';
import { PlaneIcon } from 'images/PlaneIcon';
import Picker from 'emoji-picker-react';
import { SmileIcon } from 'images/SmileIcon';
import { useOnClickOutside } from 'usehooks-ts';
import FooterLinks from '../Footer/components/FooterLinks';
import { useToken } from '../../cookies';
import { SelectedParagraph } from '../../pages/Article/ViewArticle';
import Switch from './Switch/Switch';
import { QuotesIcon } from 'images/QuotesIcon';
import { normalizedCommentsStructure, normalizedParagraphCommentsStructure } from '../../helpers/comments';
import { selectUserId } from '../../store/auth';
import { ImagesTemplate } from '../layout/ImagesTemplate';
import { NO_USER_AVATAR } from '../../utils/constantImagesUrl';
import styles from './CommentsStyle.module.css';
import './style.css';

type ICommentProps = {
  selectedParagraph: SelectedParagraph | undefined;
  setSelectedParagraph: Dispatch<SetStateAction<SelectedParagraph | undefined>>;
};

export const Comments = (props: ICommentProps) => {
  const [comment, setComment] = useState('');
  const [isParagraphComment, setIsParagraphComment] = useState(!!props.selectedParagraph?.id);
  const [isEmojiVisible, setIsEmojiVisible] = useState(false);
  const paper = useSelector(selectSelectedPaper) as Paper;
  const loggedUser = useSelector(selectLoggedUserData);
  const loggedUserId = useSelector(selectUserId);
  const { token } = useToken();
  const dispatch = useDispatch();
  const ref = useRef(null);
  let comments: IComment[] = normalizedCommentsStructure(paper.comments);
  const ParagraphComments: IComment[] = normalizedParagraphCommentsStructure(paper.comments);

  useEffect(() => {
    comments = normalizedCommentsStructure(paper.comments);
  }, [paper.comments]);

  useEffect(() => {
    if (props.selectedParagraph?.id) {
      setIsParagraphComment(true);
    }
  }, [props.selectedParagraph]);

  const postComment = () => {
    if (comment.length && !props.selectedParagraph?.id) {
      dispatch(createComment({ token, articleId: paper.id, comment, userId: loggedUserId }));
      setComment('');
    }
    if (comment.length && props.selectedParagraph?.id) {
      dispatch(
        createComment({
          token,
          articleId: paper.id,
          comment,
          userId: loggedUserId,
          paragraphId: props.selectedParagraph.id,
        }),
      );
      setComment('');
      props.setSelectedParagraph(undefined);
    }
  };

  const saveComment = (e: { code: string }) => {
    if (e.code === 'Enter') {
      postComment();
    }
  };

  const handleClickOutside = () => {
    setIsEmojiVisible(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className={styles.main}>
      <div className={styles.mainContent}>
        <div className={styles.userInfo}>
          <UserInfo
            picture={paper.paper_author?.profile?.image_url || NO_USER_AVATAR}
            firstName={paper.paper_author?.profile?.first_name}
            lastName={paper.paper_author?.profile?.last_name}
            nickname={paper.paper_author?.username}
            isMyPage={paper.paper_author?.profile?.id === loggedUserId}
          />
          <Button className={styles.followButton} icon={<Vector width={12} height={24} />}>
            {'Follow'}
          </Button>
        </div>
        <div className={styles.commentsMain}>
          <div className={styles.switchComment}>
            <p
              style={{
                height: '40px',
                width: '98%',
                marginBottom: isParagraphComment && props.selectedParagraph?.text?.length ? '0px' : '30px',
                marginTop: '0px',
              }}
            >
              <Switch
                selectedParagraph={isParagraphComment}
                seIsParagraphComment={setIsParagraphComment}
                setSelectedParagraph={props.setSelectedParagraph}
              />
            </p>
          </div>
          {isParagraphComment && props.selectedParagraph?.text?.length && props.selectedParagraph?.text !== '\n' && (
            <div>
              <div className={styles.paragraphQuoteIcon}>{QuotesIcon()}</div>
              <div
                className={styles.paragraphQuote}
                dangerouslySetInnerHTML={{
                  __html:
                    props.selectedParagraph?.text.length > 200
                      ? props.selectedParagraph?.text.substring(0, 200) + ' ...'
                      : props.selectedParagraph?.text || '',
                }}
              />
            </div>
          )}

          <div className={styles.messageBlock}>
            <div style={{ margin: '4px 8px' }}>
              <ImagesTemplate url={loggedUser.profile?.image_url} height={38} width={38} isSquare />
            </div>

            <input
              disabled={isParagraphComment && !props.selectedParagraph?.text?.length}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={(e) => saveComment(e)}
              className={styles.messageBlockInput}
              type="text"
              placeholder="Leave a comment..."
            />
            <div ref={ref} className={styles.messageEmojii}>
              {isEmojiVisible && (
                <Picker
                  onEmojiClick={(e, obj) => {
                    e.stopPropagation();
                    setComment(comment + obj.emoji);
                  }}
                />
              )}
            </div>

            <div
              className={styles.messageEmojiiIcon}
              onClick={(e) => {
                e.stopPropagation();
                setIsEmojiVisible(!isEmojiVisible);
              }}
            >
              {SmileIcon()}
            </div>
            <div onClick={() => postComment()} className={styles.messagePostComment}>
              {PlaneIcon()}
            </div>
          </div>

          {!isParagraphComment ? (
            <div className={styles.commentList}>
              {comments.length > 0 &&
                [...comments].reverse().map((comment) => <CommentsList key={comment.id} {...comment} />)}
            </div>
          ) : (
            <div className={styles.paragraphComments}>
              <div className={styles.commentList}>
                {ParagraphComments.length > 0 &&
                  [...ParagraphComments].reverse().map((comment) => <CommentsList key={comment.id} {...comment} />)}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.articleFooterRight}>
        <div className={styles.articleFooterLinks}>
          <FooterLinks />
        </div>
      </div>
    </div>
  );
};
