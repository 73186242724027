import Checkbox from '@mui/material/Checkbox';
import { uncheckedCircle } from 'images/checked/uncheckedCircle';
import { checkedCircle } from 'images/checkedCircle';
import React, { Dispatch, SetStateAction } from 'react';

import styles from './accordionStyles.module.css';

function MoodsAccordion({
  setSelectedMoods,
  selectedMoods,
}: {
  setSelectedMoods: Dispatch<SetStateAction<{ [p: string]: string }>>;
  selectedMoods: { [key: string]: string };
}) {
  const handleSelectedMoods = (event: { target: { checked: boolean } }, mood: string) => {
    if (selectedMoods[mood.toLowerCase()] && !event.target.checked) {
      setSelectedMoods((current) => {
        const copy = { ...current };
        delete copy[mood.toLowerCase()];
        return copy;
      });
    } else {
      setSelectedMoods({ ...selectedMoods, [mood.toLowerCase()]: mood });
    }
  };

  return (
    <div className={styles.moodsAccordion}>
      <div className={styles.moodElement}>
        <Checkbox
          onChange={(e) => handleSelectedMoods(e, 'Happy')}
          icon={uncheckedCircle()}
          checkedIcon={checkedCircle()}
        />
        <div className={styles.moodLabel}>Happy</div>
      </div>

      <div className={styles.moodElement}>
        <Checkbox
          onChange={(e) => handleSelectedMoods(e, 'Confident')}
          icon={uncheckedCircle()}
          checkedIcon={checkedCircle()}
        />
        <div className={styles.moodLabel}>Confident</div>
      </div>

      <div className={styles.moodElement}>
        <Checkbox
          onChange={(e) => handleSelectedMoods(e, 'Excited')}
          icon={uncheckedCircle()}
          checkedIcon={checkedCircle()}
        />
        <div className={styles.moodLabel}>Excited</div>
      </div>

      <div className={styles.moodElement}>
        <Checkbox
          onChange={(e) => handleSelectedMoods(e, 'Confused')}
          icon={uncheckedCircle()}
          checkedIcon={checkedCircle()}
        />
        <div className={styles.moodLabel}>Confused</div>
      </div>

      <div className={styles.moodElement}>
        <Checkbox
          onChange={(e) => handleSelectedMoods(e, 'Curious')}
          icon={uncheckedCircle()}
          checkedIcon={checkedCircle()}
        />
        <div className={styles.moodLabel}>Curious</div>
      </div>

      <div className={styles.moodElement}>
        <Checkbox
          onChange={(e) => handleSelectedMoods(e, 'Tired')}
          icon={uncheckedCircle()}
          checkedIcon={checkedCircle()}
        />
        <div className={styles.moodLabel}>Tired</div>
      </div>

      <div className={styles.moodElement}>
        <Checkbox
          onChange={(e) => handleSelectedMoods(e, 'Angry')}
          icon={uncheckedCircle()}
          checkedIcon={checkedCircle()}
        />
        <div className={styles.moodLabel}>Angry</div>
      </div>

      <div className={styles.moodElement}>
        <Checkbox
          onChange={(e) => handleSelectedMoods(e, 'Frustrated')}
          icon={uncheckedCircle()}
          checkedIcon={checkedCircle()}
        />
        <div className={styles.moodLabel}>Frustrated</div>
      </div>

      <div className={styles.moodElement}>
        <Checkbox
          onChange={(e) => handleSelectedMoods(e, 'Sad')}
          icon={uncheckedCircle()}
          checkedIcon={checkedCircle()}
        />
        <div className={styles.moodLabel}>Sad</div>
      </div>
    </div>
  );
}

export default MoodsAccordion;
