import React, { ReactElement } from 'react';

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  pathFill?: string;
};
const AlertIcon: React.FC<Props> = ({
  width = 16,
  height = 16,
  viewBox = '0 0 14 14',
  pathFill = '#EC6161',
}): ReactElement => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 9.91667C7.16528 9.91667 7.30392 9.86067 7.41592 9.74867C7.52792 9.63667 7.58372 9.49822 7.58333 9.33333C7.58333 9.16806 7.52733 9.02942 7.41533 8.91742C7.30333 8.80542 7.16489 8.74961 7 8.75C6.83472 8.75 6.69608 8.806 6.58408 8.918C6.47208 9.03 6.41628 9.16844 6.41667 9.33333C6.41667 9.49861 6.47267 9.63725 6.58467 9.74925C6.69667 9.86125 6.83511 9.91706 7 9.91667ZM6.41667 7.58333H7.58333V4.08333H6.41667V7.58333ZM4.8125 12.25L1.75 9.1875V4.8125L4.8125 1.75H9.1875L12.25 4.8125V9.1875L9.1875 12.25H4.8125ZM5.30833 11.0833H8.69167L11.0833 8.69167V5.30833L8.69167 2.91667H5.30833L2.91667 5.30833V8.69167L5.30833 11.0833Z"
        fill={pathFill}
      />
    </svg>
  );
};
export default AlertIcon;