import React, { useState } from 'react';
import { IGroup } from '../../../../types/Group';
import styles from './styles.module.css';
import StatisticsMoods from './StatisticsMoods';
import StatisticsDetails from './StatisticsDetails';
import StatisticsOutlier from './StatisticsOutlier';
import {ExpandedGroupTabs} from "../../../../utils/enums";

function ExpandedGroupBlock({ group }: { group: IGroup }) {
  const [selectedTab, setSelectedTab] = useState<ExpandedGroupTabs>(ExpandedGroupTabs.MOODS);

  const displayComponentBySelectedTab = () => {
    switch (selectedTab) {
      case ExpandedGroupTabs.MOODS:
        return <StatisticsMoods group={group} />;
      case ExpandedGroupTabs.DETAILS:
        return <StatisticsDetails group={group} />;
      case ExpandedGroupTabs.OUTLIER_AND_EMERGENCY_MEMBERS:
        return <StatisticsOutlier group={group} />;
    }
  };

  return (
    <div className={styles.mainExpanded}>
      <h4>Group</h4>
      <div className={styles.expandedTabs}>
        {Object.values(ExpandedGroupTabs).map((tab) => {
          return (
            <div
              onClick={() => setSelectedTab(tab)}
              className={`${styles.expandedTab} ${tab === selectedTab && styles.expandedTabSelected}`}
              key={tab}
            >
              {tab}
            </div>
          );
        })}
      </div>
      <div className={styles.expandedWrapper}>{displayComponentBySelectedTab()}</div>
    </div>
  );
}

export default ExpandedGroupBlock;