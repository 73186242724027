import { Dispatch, SetStateAction } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import styles from './styles.module.css';

type EducationProps = {
  institute: string;
  major: string;
  graduation: string;
  setInstitute: Dispatch<SetStateAction<string>>;
  setMajor: Dispatch<SetStateAction<string>>;
  setGraduation: Dispatch<SetStateAction<string>>;
};

export default function EducationMobileSettings(props: EducationProps) {
  const { institute, major, graduation, setInstitute, setMajor, setGraduation } = props;

  return (
    <div>
      <p className={styles.inputLabel}>Institute</p>
      <input
        type="text"
        className={styles.inputField}
        value={institute}
        onChange={(e) => setInstitute(e.target.value)}
      />

      <p className={styles.inputLabel}>Major</p>
      <input type="text" className={styles.inputField} value={major} onChange={(e) => setMajor(e.target.value)} />

      <p className={styles.inputLabel}>Graduation</p>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          showToolbar={false}
          className={styles.datePicker}
          maxDate={String(new Date().getFullYear() + 10)}
          minDate={String(new Date().getFullYear())}
          views={['year']}
          value={graduation?.toString() || String(new Date().getFullYear())}
          onChange={(e: any) => setGraduation(e.getUTCFullYear())}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
      </LocalizationProvider>
    </div>
  );
}