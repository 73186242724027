import { useEffect, useState } from 'react';
import { Box } from '@washingtonpost/wpds-box';
import { getMailDomains, selectMailDomainsPrefixes } from '../../../store/auth';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import { useIsMobile } from '../../../utils/styles.utils';
import { darkTheme, lightTheme } from '../../../themes/themes';
import style from '.././AuthStyle.module.css';

export default function AuthForm({
  email,
  password,
  setEmail,
  setPassword,
  mailPrefix,
  setMailPrefix,
  authenticate,
}: {
  email: string;
  password: string;
  setEmail: any;
  setPassword: any;
  mailPrefix: string;
  setMailPrefix: any;
  authenticate?: () => any;
}) {
  const [passwordError, setPasswordError] = useState('');
  const [mailError, setMailError] = useState('');
  const [focusedPassword, setFocusedPassword] = useState(false);
  const [focusedEmail, setFocusedEmail] = useState(false);
  const onFocusPass = () => setFocusedPassword(true);
  const onFocusEmail = () => setFocusedEmail(true);
  const dispatch = useDispatch();
  const mailDomainsPrefixes = useSelector(selectMailDomainsPrefixes);
  const isMobile = useIsMobile();

  useEffect(() => {
    setMailPrefix('@' + mailDomainsPrefixes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailDomainsPrefixes]);

  useEffect(() => {
    if (focusedPassword) {
      if (password.length < 1) {
        setPasswordError('Not allowed empty field');
      } else if (password.length < 8) {
        setPasswordError('Password should contain minimum 8 characters');
      } else {
        setPasswordError('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  useEffect(() => {
    if (focusedEmail) {
      if (email.length < 1) {
        setMailError('Not allowed empty field');
      } else {
        setMailError('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    dispatch(getMailDomains());
  }, [dispatch]);

  const handleChange = (event: SelectChangeEvent) => {
    setMailPrefix(event.target.value);
  };

  return (
    <ThemeProvider theme={isMobile ? darkTheme : lightTheme}>
      <Box css={{ display: 'flex', flexDirection: 'column', gap: '$075' }} className={style.loginInputs}>
        <div style={{ marginBottom: '8px' }}>
          <TextField
            onFocus={onFocusEmail}
            style={{ width: '60%' }}
            id="outlined-email-input"
            label="Email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            error={!!mailError}
            helperText={mailError}
            size={isMobile ? 'small' : 'medium'}
          />
          <Select
            style={{ width: '40%' }}
            value={mailPrefix}
            defaultValue={'@' + mailDomainsPrefixes[0] || '@example.com'}
            onChange={handleChange}
            size={isMobile ? 'small' : 'medium'}
          >
            {mailDomainsPrefixes.map((domain) => {
              return (
                <MenuItem key={domain} id={domain} value={'@' + domain}>
                  @{domain}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <TextField
          size={isMobile ? 'small' : 'medium'}
          onFocus={onFocusPass}
          id="outlined-password-input"
          label="Password"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              authenticate && authenticate();
            }
          }}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="password"
          error={!!passwordError}
          helperText={passwordError}
        />
      </Box>
    </ThemeProvider>
  );
}
