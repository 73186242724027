import { UsersApiService } from '../../../../services/users.service';
import { useToken } from '../../../../cookies';
import MessageHockPopUp from '../../../../hooks/MessageHockPopUp';
import { clearUserState } from '../../../../store/user';
import { clearNotificationsState } from '../../../../store/notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUserId } from '../../../../store/auth';
import styles from './styles.module.css';

export default function DeleteAccountPop(props: any) {
  const { setConfirm } = props;
  const { token } = useToken();
  const dispatch = useDispatch();
  const { removeToken } = useToken();
  const history = useHistory();
  const loggedUserId = useSelector(selectUserId);

  const logout = () => {
    dispatch(clearUserState());
    dispatch(clearNotificationsState());
    removeToken();
    history.push('/login');
  };

  const deleteAccount = () => {
    UsersApiService.deleteUser(token, loggedUserId).then((r) => {
      if (r.ok) {
        MessageHockPopUp({ message: 'Account deleted', timeOut: 4000, type: 'success', size: 'small' });
        logout();
      } else {
        MessageHockPopUp({ message: 'Something went wrong', timeOut: 4000, type: 'error', size: 'small' });
      }
    });
  };

  return (
    <div className={styles.deleteConfirmationBlock}>
      <div className={styles.deleteConfirmation}>
        <h1 className={styles.warning}>Warning!</h1>
        <span className={styles.warningMessage}>
          By deleting my account we will delete all your personal information and account activity
        </span>
        <div className={styles.confirmationBtnBlock}>
          <div
            onClick={() => {
              deleteAccount();
              setConfirm(false);
            }}
            className={styles.confirmationBtn}
          >
            Yes
          </div>
          <div onClick={() => setConfirm(false)} className={styles.confirmationBtn}>
            No
          </div>
        </div>
      </div>
    </div>
  );
}
