import React, { useState } from 'react';
import { IComment } from '../../../../types/Paper';
import { ImagesTemplate } from '../../../layout/ImagesTemplate';
import { calcCreatedTime } from 'src/helpers/date';
import {
  displayMyUserNameInComments,
  isCommentLikedByMe,
  isMyComment,
  replyCommentFormatted,
} from '../../../../helpers/comments';
import { LikeThumbsUpIcon } from 'images/LikeThumbsUpIcon';
import { LikeThumbsUpOutline } from 'images/LikeThumbsUpOutline';
import { addOrRemoveCommentLike, removeComment } from '../../../../store/articles';
import { useToken } from '../../../../cookies';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../../../../store/auth';
import { ReplyIcon } from 'images/ReplyIcon';
import MobileReplyCommentBlock from './MobileReplyCommentBlock';
import { getAnonymousImage } from '../../../../helpers/anonymousGenerator';
import TrashcanIcon from 'images/trashbinIcon';
import { useHistory } from 'react-router-dom';
import ReportIcon from 'images/reportIcon';
import CostumePopup from '../../../layout/CostumePopUp';
import ReportCommentPopUp from './ReportCommentPopUp';
import commentStyles from '../../../Article/CommentsStyle.module.css';
import style from './styles.module.css';

export default function MobileCommentBlock({ comment, isParagraph }: { comment: IComment; isParagraph?: boolean }) {
  const [isReplyBlockOpened, setIsReplyBlockOpened] = useState(false);
  const [reportPopUp, setReportPopUp] = useState('');
  const [repliedComment, setRepliedComment] = useState('');
  const { token } = useToken();
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedUserId = useSelector(selectUserId);

  const addLikeToComment = () => {
    dispatch(
      addOrRemoveCommentLike({
        authorId: loggedUserId,
        commentId: comment.id,
        token,
        isAddLike: true,
      }),
    );
  };

  const removeLikeFromComment = () => {
    dispatch(
      addOrRemoveCommentLike({
        authorId: loggedUserId,
        commentId: comment.id,
        token,
        isAddLike: false,
      }),
    );
  };

  const closeReportPopUP = () => {
    setReportPopUp('');
  };

  return (
    <div className={style.commentBlock}>
      <div className={style.commentBlockUserInfo}>
        {comment.anonymous_name ? (
          getAnonymousImage(comment.anonymous_name)
        ) : (
          <div
            onClick={() => {
              history.push(`/user/${comment.author.id}`);
            }}
          >
            <ImagesTemplate url={comment.author?.profile?.image_url} height={40} width={40} />
          </div>
        )}
        <span style={{ marginLeft: '8px' }}>{displayMyUserNameInComments(comment)}</span>
        <span style={{ marginLeft: '8px', color: '#FFC700' }}>
          {comment.created && calcCreatedTime(comment.created)}
        </span>
        {isMyComment(comment, loggedUserId) && (
          <span onClick={() => dispatch(removeComment({ token, id: comment.id }))} style={{ marginLeft: 'auto' }}>
            <TrashcanIcon color="#fff" />
          </span>
        )}
      </div>
      <span className={style.articleComment}>{comment.description}</span>
      <div className={style.likesAndReplyBlocks}>
        {/*  Comment report btn */}
        <div
          className={style.likesAndReplyBlock}
          onClick={() => {
            setReportPopUp(comment.id);
          }}
        >
          <ReportIcon />
          <span style={{ marginLeft: '6px' }}>Report</span>
        </div>

        <div
          className={style.likesAndReplyBlock}
          onClick={() => {
            setIsReplyBlockOpened(true);
            setRepliedComment(comment.description);
          }}
        >
          <div>{ReplyIcon('white')}</div>
          <span style={{ marginLeft: '6px' }}>Reply</span>
        </div>
        {/* Report modal */}
        {reportPopUp && (
          <CostumePopup className={style.reportPopUP}>
            <div>
              <div className={style.closeReportPopUp} onClick={closeReportPopUP}>
                x
              </div>
              <ReportCommentPopUp commentId={reportPopUp} closePopUp={closeReportPopUP} />
            </div>
          </CostumePopup>
        )}

        <div className={style.likesAndReplyBlock}>
          {isCommentLikedByMe(comment, loggedUserId) ? (
            <div onClick={() => removeLikeFromComment()} className={commentStyles.paragraphQtLikeBlack}>
              {LikeThumbsUpIcon('white', '20')}
            </div>
          ) : (
            <div onClick={() => addLikeToComment()} className={commentStyles.paragraphQtLikeWhite}>
              {LikeThumbsUpOutline('white', '20')}
            </div>
          )}
          <span style={{ marginLeft: '6px' }}> {comment.likes?.length}</span>
        </div>
      </div>
      <div className={style.mobileRepliedComments}>
        {comment.replays?.map((repCom) => {
          return (
            <div key={repCom.id} style={{ marginTop: '14px', backgroundColor: '#0e0e0e' }}>
              <div className={style.commentBlockUserInfo}>
                {repCom.anonymous_name ? (
                  getAnonymousImage(repCom.anonymous_name)
                ) : (
                  <div
                    onClick={() => {
                      history.push(`/user/${comment.author.id}`);
                    }}
                  >
                    <ImagesTemplate url={repCom.author?.profile?.image_url} height={40} width={40} />
                  </div>
                )}
                <span style={{ marginLeft: '8px' }}>{displayMyUserNameInComments(repCom)}</span>
                <span
                  style={{
                    marginLeft: '8px',
                    color: '#FFC700',
                  }}
                >
                  {' '}
                  {repCom.created && calcCreatedTime(repCom.created)}
                </span>

                {isMyComment(repCom, loggedUserId) && (
                  <span
                    onClick={() => dispatch(removeComment({ token, id: repCom.id }))}
                    style={{ marginLeft: 'auto' }}
                  >
                    <TrashcanIcon color="#fff" />
                  </span>
                )}
                {/*  replay comm report btn */}
                <div
                  className={style.likesAndReplyBlock}
                  onClick={() => {
                    setReportPopUp(repCom.id);
                  }}
                >
                  <ReportIcon />
                  <span style={{ marginLeft: '6px' }}>Report</span>
                </div>
              </div>
              <p
                className={style.repliedCommentText}
                dangerouslySetInnerHTML={{
                  __html: replyCommentFormatted(repCom.description).replace(/\n\r?/g, '<br />'),
                }}
              />
            </div>
          );
        })}
      </div>
      {isReplyBlockOpened && (
        <MobileReplyCommentBlock
          repliedText={repliedComment}
          setIsReplyBlockOpened={setIsReplyBlockOpened}
          commentId={comment.id}
        />
      )}
    </div>
  );
}
