import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearSelectedPaper } from 'src/store/articles';
import { Notification } from 'src/types/Notifications';
import { NotificationsApiService } from 'src/services/notifications.service';
import { useToken } from 'src/cookies';
import { calcCreatedTime } from 'src/helpers/date';
import { CommentedIcon } from 'images/CommentedIcon';
import { LikedIcon } from 'images/LikedIcon';
import { NO_USER_AVATAR } from '../../utils/constantImagesUrl';
import { iconCheckCircle } from 'images/checked/iconCheckCircle';
import { closeWhiteCircleIcon } from 'images/closeWhiteCircleIcon';
import { ChatService } from '../../services/socket.service';
import MessageHockPopUp from '../../hooks/MessageHockPopUp';
import { getNotifications } from '../../store/notifications';
import { userNameFromAllParams } from '../../helpers/userName';
import { sliceText } from '../../helpers/textHelper';
import styles from './styles.module.css';

export const getMyIconsByValue = (value: string, size = 50) => {
  switch (value) {
    case 'PAPER_LIKE':
      return <LikedIcon />;
    case 'QUESTION_LIKE':
      return <LikedIcon />;
    case 'PAPER_COMMENT':
      return <CommentedIcon />;
    case 'QUESTION_COMMENT':
      return <CommentedIcon />;
    case 'COMMENT_LIKE':
      return <LikedIcon />;
    case 'CHAT':
      return <CommentedIcon />;
  }
};

export default function NotificationDropDownBlock({ notification }: { notification: Notification }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useToken();

  const acceptChat = (conversationId: string) => {
    ChatService.acceptConversation(token, conversationId)
      .then(() => {
        MessageHockPopUp({
          message: 'Invitation accepted, check your messages!',
          timeOut: 4000,
          type: 'success',
          size: 'small',
        });
      })
      .finally(() => dispatch(getNotifications({ token })));
  };

  const declineChat = (conversationId: string) => {
    ChatService.declineConversation(token, conversationId)
      .then(() => {
        MessageHockPopUp({
          message: 'Declined, if you have some questions or need more info please let us know!',
          timeOut: 4000,
          type: 'warning',
          size: 'small',
        });
      })
      .finally(() => dispatch(getNotifications({ token })));
  };

  return (
    <div
      className={styles.notifyBlock}
      onClick={() => {
        NotificationsApiService.markNotificationAsRead(token, notification.id).then((r) => {
          if (r.ok) {
            MessageHockPopUp({
              message: 'Marked as read!',
              timeOut: 1300,
              type: 'success',
              size: 'small',
            });
          }
        });
        dispatch(clearSelectedPaper());
        if (
          notification.type === 'QUESTION_LIKE' ||
          notification.type === 'QUESTION_COMMENT' ||
          notification.type === 'PAPER_LIKE'
        ) {
          history.push(`/article${notification.target_id}`);
        }
      }}
    >
      <img
        className={styles.notifyImage}
        src={notification?.sender?.profile?.image_url ? notification?.sender?.profile?.image_url : NO_USER_AVATAR}
        alt=""
      />
      <div className={styles.rightLineNotificationBlock}>
        <div className={styles.notificationMobileHeader}>
          <span className={styles.notificationUserName}>
            {userNameFromAllParams(
              notification?.sender?.username,
              notification?.sender?.profile?.first_name,
              notification?.sender?.profile?.last_name,
            ).slice(0, 16)}
          </span>
          <span className={styles.notificationCreatedAt}>
            {!notification.read && <span className={styles.dotNotification}></span>}
            {notification?.created_at && calcCreatedTime(notification?.created_at)}
          </span>
        </div>
        <div className={styles.notificationDescriptionBlock}>
          <span className={styles.notificationIcon}>{getMyIconsByValue(notification?.type)}</span>
          <span className={styles.notificationMessage}>
            <div>{notification?.description}</div>
            {notification.type !== 'CHAT' && (
              <div style={{ color: '#FFC700', marginLeft: '4px' }}>
                {notification?.name && sliceText(notification.name, 17)}
              </div>
            )}
          </span>
          {notification.type === 'CHAT' && !notification.read && (
            <div className={styles.acceptOrDeclineBlock}>
              <div onClick={() => acceptChat(notification.target_id)}>{iconCheckCircle('30', '30')}</div>
              <div onClick={() => declineChat(notification.target_id)}>{closeWhiteCircleIcon('30', '30')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
