import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ArrowBackButton from '../../Buttons/ArrowBackButton';
import { darkTheme } from '../../../../themes/themes';
import { ThemeProvider } from '@mui/material/styles';
import { vextLogo } from '../../../../assets/LogoFrames/vextLogo';
import GroupCardMobile from '../../../groups/components/GroupCardMobile';
import { selectLoggedUserData } from '../../../../store/user/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../../store/user';
import { useToken } from '../../../../cookies';
import { selectUserId } from '../../../../store/auth';
import { useHistory } from 'react-router-dom';
import CreateQuestionPage from '../../../../pages/CreateQuestionPage/CreateQuestionPage';
import { closeWhiteCircleIcon } from 'images/closeWhiteCircleIcon';
import { getGroups } from '../../../../store/groups';
import styles from './CreateGroupMobileStyle.module.css';

type Sep2Props = {
  setStep: Dispatch<SetStateAction<number>>;
  groupName?: string;
  imagePreview?: string;
};

export default function Step4({ setStep, groupName, imagePreview }: Sep2Props) {
  const myProfile = useSelector(selectLoggedUserData);
  const loggedUserId = useSelector(selectUserId);
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useToken();
  const [createQuestion, setCreateQuestion] = useState(false);

  useEffect(() => {
    if (!myProfile.id) {
      dispatch(getUser({ token: token, userId: loggedUserId }));
    }
    // eslint-disable-next-line
  }, []);

  const group = {
    group_photo: imagePreview || '',
    name: groupName || 'No name',
    users: [
      {
        ...myProfile,
      },
    ],
  };

  const updateGroups = () => {
    dispatch(getGroups({ token, page: 1 }));
  };

  return (
    <div className={styles.createGroupMain}>
      <ThemeProvider theme={darkTheme}>
        <div className={styles.headerCreateGroup}>
          <ArrowBackButton setStep={() => setStep(3)} />
          <div onClick={() => history.push('./feed')}>{closeWhiteCircleIcon('38', '34')}</div>
        </div>
        <div className={styles.createGroupForm}>
          <div>{vextLogo()}</div>
          <h1 style={{ marginBottom: '20px', color: 'white' }}>All set!</h1>
          <div className={styles.groupPreviewBlk}>
            <GroupCardMobile group={group} isAllFeatures />
          </div>
          <div
            className={styles.nextButton}
            onClick={() => {
              updateGroups();
              history.push('/feed');
            }}
          >
            Finish
          </div>

          {/*<div className={styles.skipButton} onClick={() => history.push('/create-question')}>*/}
          <div
            className={styles.skipButton}
            onClick={() => {
              updateGroups();
              setCreateQuestion(true);
            }}
          >
            Ask your first question
          </div>
          {createQuestion && <CreateQuestionPage setCreatingQuestion={setCreateQuestion} />}
        </div>
      </ThemeProvider>
    </div>
  );
}
