import React from 'react';

import styles from './styles.module.css';

import { calcCreatedTime } from '../../../../helpers/date';
import { EmergencyObj } from '../../../../types/Emergencies';

const automaticLogLabel = 'Automatic log added for bad mood';

type Emergency = {
  reason: string;
  title: string;
  info: string;
  time: string | null;
};

const ellipseElement = (emergency: Emergency, isFirst?: boolean, isLast?: boolean) => {
  return (
    <div className={styles.ellipseContainer}>
      <div>
        {isFirst && <div className={styles.circle}></div>}
        <div className={styles.verticalLine}></div>
        <div className={styles.bottomLine}></div>
      </div>
      <div className={styles.ellipseText} style={{ backgroundColor: isLast ? 'rgba(255,221,221,0.79)' : undefined }}>
        <span>Reason: {emergency.reason}</span>
        <span>Info: {emergency.info}</span>
        <span className={styles.ellipseTime}>{emergency.time ? calcCreatedTime(emergency.time) : '--:--'}</span>
      </div>
    </div>
  );
};

function ActionsHistory({
  emergency,
  logs,
}: {
  emergency: EmergencyObj;
  logs: Array<{ created_at: string; id: string; item_description: string }>;
}) {
  const emReasonObj = {
    reason: emergency.emergency_reason || 'Need help',
    title: 'New emergency',
    info: emergency.emergency_info || '--',
    time: emergency.created_at,
  };

  const formattedLogs: Array<Emergency> = logs.map((log) => {
    if (log.item_description === automaticLogLabel) {
      return {
        title: 'Automatic log',
        reason: 'Created by system!',
        info: 'Bad moods for several days !!!',
        time: log.created_at,
      };
    }
    try {
      const logObj: Emergency = JSON.parse(log.item_description);
      return {
        title: logObj.title || 'No title',
        reason: logObj.reason || 'Created by user',
        info: logObj.info || 'The user pressed the emergency button.',
        time: log.created_at,
      };
    } catch (e) {
      try {
        const reasonIndex = log.item_description.indexOf('"reason":');
        const titleIndex = log.item_description.indexOf('"title":');
        const infoIndex = log.item_description.indexOf('"info":');

        const reason = log.item_description.substring(
          reasonIndex + 11,
          log.item_description.indexOf('"', reasonIndex + 11),
        );

        const title = log.item_description.substring(
          titleIndex + 10,
          log.item_description.indexOf('"', titleIndex + 10),
        );
        const info = log.item_description.substring(infoIndex + 9, log.item_description.indexOf('"', infoIndex + 9));

        return {
          title: title || 'No title',
          info: info || 'The user pressed the emergency button.',
          reason: reason || 'Created by user',
          time: log.created_at,
        };
      } catch (e) {
        return {
          title: 'No title',
          info: log.item_description || 'The user pressed the emergency button.',
          reason: 'Created by user',
          time: log.created_at,
        };
      }
    }
  });

  const combinedActionsWithLogs = [...formattedLogs, emReasonObj];
  return (
    <div style={{ marginBottom: '14px' }}>
      {combinedActionsWithLogs.map((action, idx) => {
        return ellipseElement(action, !idx, combinedActionsWithLogs.length === idx + 1);
      })}
    </div>
  );
}

export default ActionsHistory;
