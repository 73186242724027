import styles from './GifTabStyles.module.css';
import List from '@mui/material/List';
import * as React from 'react';
import { Dispatch, useEffect, useState } from 'react';
import { GifApiService } from '../../../services/tenor.service';
import { ApiResponse } from 'apisauce';

type Response = {
  results: Array<{
    itemurl: string;
  }>;
};
type GifType = {
  media: Array<{
    gif: {
      url: string;
    };
  }>;
};

export function GifTab({ setAnchorEl }: { setAnchorEl: Dispatch<React.SetStateAction<boolean>> }) {
  const [gifList, setGifList] = useState<any>([]);
  const [searchedGif, setSearchedGif] = useState('');

  const getGifs = () => {
    GifApiService.searchGif({ searchValue: searchedGif }).then((response: ApiResponse<Response>) => {
      if (response?.data?.results) {
        setGifList(response.data.results);
      }
    });
  };

  useEffect(() => {
    getGifs();
  }, []);



  return (
    <div className={styles.gifBlock}>
      <List sx={{ pt: 0 }}>
        <input
          type="text"
          className={styles.searchInput}
          onChange={(e) => setSearchedGif(e.target.value)}
          value={searchedGif}
        />
        <div className={styles.gifList}>
          {gifList.length &&
            gifList.map((gif: GifType) => {
              return (
                <div className={styles.gifElement}>
                  <img width={'100px'} height={'100px'} src={gif.media[0]?.gif.url || ''} alt="" />
                </div>
              );
            })}
        </div>
      </List>
    </div>
  );
}