import { IComment, Paper } from '../types/Paper';
import { userNameFromAllParams } from './userName';

const commentsArrayToObject = (comments: Array<IComment>) => {
  return comments.reduce((acc: { [key: string]: IComment }, cm: IComment) => {
    const replayed = comments.filter((com) => com.repliedToCommentId === cm.id);

    if (replayed.length) {
      return Object.assign(acc, { [cm.id]: { ...cm, replays: replayed } });
    }
    return Object.assign(acc, { [cm.id]: cm });
  }, {});
};

export const normalizedCommentsStructure = (comments: Array<IComment>) => {
  return Object.values(commentsArrayToObject(comments)).filter((el) => !el.repliedToCommentId && !el.paragraphId);
};

export const normalizedParagraphCommentsStructure = (comments: Array<IComment>) => {
  return Object.values(commentsArrayToObject(comments)).filter((el) => !el.repliedToCommentId && el.paragraphId);
};

export const isCommentLikedByMe = (comment: IComment, loggedUserId: string) => {
  return comment.likes?.find((like) => like.id === loggedUserId);
};

export const replyCommentFormatted = (message: string) => {
  if (message !== '') {
    const regex = /@\[.+?\]\(.+?\)/gm;
    const displayRegex = /@\[.+?\]/g;
    const idRegex = /\(.+?\)/g;
    const matches = message.match(regex);
    const arr: Array<{ id: string; display: string }> = [];
    matches &&
      matches.forEach((m) => {
        const id = m.match(idRegex)![0].replace('(', '').replace(')', '');
        const display = m.match(displayRegex)![0].replace('@[', '').replace(']', '');
        arr.push({ id: id, display: display });
      });
    const newComment = message.split(regex);
    let output = '';
    for (let i = 0; i < newComment.length; i++) {
      const c = newComment[i];
      if (i === newComment.length - 1) output += c;
      else output += c + `<span class='repliedName'>${arr[i].display}</span>`;
    }
    return output;
  }

  return '';
};

export const getQuoteByParagraphId = (paper: Paper, comment: IComment) => {
  const htmlParser = document.createElement('div');
  htmlParser.innerHTML = paper.content;

  const paragraphAndIdsCollection = Array.from(htmlParser.children).map((el: any) => {
    return {
      id: el.id,
      text: el.innerText,
    };
  });
  const pText = paragraphAndIdsCollection.find((paragraph) => paragraph.id === comment.paragraphId);
  if (pText) return pText.text.length > 200 ? pText.text.substring(0, 170) + ' ...' : pText.text;
  return null;
};

export const displayMyUserNameInComments = (comment: IComment) => {
  if (comment.anonymous_name) return comment.anonymous_name;
  const { profile } = comment.author;
  return userNameFromAllParams(comment.author?.username, profile?.first_name, profile?.last_name);
};

export const findMyAnonymousNameById = (article: Paper, loggedUserId: string) => {
  const myName = article.comments.find((comm) => {
    //in this case comm.author will be commented user id and anonymous_name wil be generated anonymous name
    if (comm.anonymous_name && String(comm.author) === loggedUserId) {
      return comm.anonymous_name || 'Anonymous';
    }
  });

  return myName?.anonymous_name;
};

export const isMyComment = (comment: IComment, userId: string) =>
  comment.author.id === userId || String(comment.author) === userId;
