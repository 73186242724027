import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import LandingFooter from './components/LandingFooter';
import UsaMap from '../maps/usaMap/UsaMap';
import MapBox from '../maps/worldMap/MapBox';

function LandingPage() {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      {/*<Portfolio />*/}
      <Contact />
      <UsaMap />
      {/*<MapBox />*/}
      <LandingFooter />
    </div>
  );
}

export default LandingPage;