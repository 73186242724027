export const userPlusIcon = () => (
  <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="8.32129" y="2.93872" width="9.4811" height="9.4811" rx="4.74055" stroke="#EDEFF0" strokeWidth="1.37649" />
    <path
      d="M23.6667 22.359C24.1643 23.7957 22.8922 25.0614 21.3717 25.0614C19.3446 25.0614 16.3729 25.0614 13.0613 25.0614C9.7497 25.0614 6.77801 25.0614 4.75084 25.0614C3.23041 25.0614 1.95828 23.7957 2.45583 22.359C3.81325 18.4393 8.04775 15.5803 13.0613 15.5803C18.0748 15.5803 22.3093 18.4393 23.6667 22.359Z"
      stroke="#EDEFF0"
      strokeWidth="1.37649"
    />
    <path
      d="M19.7217 11.6262H25.9997"
      stroke="#EDEFF0"
      strokeWidth="1.38"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.8613 14.7651L22.8613 8.48711"
      stroke="#EDEFF0"
      strokeWidth="1.38"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
