import { FC, ReactElement } from 'react';

type Props = {
    color?: string;
};

const TrashcanIcon: FC<Props> = ({ color = '#757575' }): ReactElement => (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.20077 8.78046C8.40229 8.78046 8.59556 8.86526 8.73806 9.0162C8.88056 9.16714 8.96062 9.37187 8.96062 9.58533V19.2439C8.96062 19.4573 8.88056 19.6621 8.73806 19.813C8.59556 19.964 8.40229 20.0487 8.20077 20.0487C7.99924 20.0487 7.80597 19.964 7.66347 19.813C7.52097 19.6621 7.44092 19.4573 7.44092 19.2439V9.58533C7.44092 9.37187 7.52097 9.16714 7.66347 9.0162C7.80597 8.86526 7.99924 8.78046 8.20077 8.78046V8.78046ZM12 8.78046C12.2015 8.78046 12.3948 8.86526 12.5373 9.0162C12.6798 9.16714 12.7599 9.37187 12.7599 9.58533V19.2439C12.7599 19.4573 12.6798 19.6621 12.5373 19.813C12.3948 19.964 12.2015 20.0487 12 20.0487C11.7985 20.0487 11.6052 19.964 11.4627 19.813C11.3202 19.6621 11.2402 19.4573 11.2402 19.2439V9.58533C11.2402 9.37187 11.3202 9.16714 11.4627 9.0162C11.6052 8.86526 11.7985 8.78046 12 8.78046V8.78046ZM16.5591 9.58533C16.5591 9.37187 16.4791 9.16714 16.3366 9.0162C16.1941 8.86526 16.0008 8.78046 15.7993 8.78046C15.5977 8.78046 15.4045 8.86526 15.262 9.0162C15.1195 9.16714 15.0394 9.37187 15.0394 9.58533V19.2439C15.0394 19.4573 15.1195 19.6621 15.262 19.813C15.4045 19.964 15.5977 20.0487 15.7993 20.0487C16.0008 20.0487 16.1941 19.964 16.3366 19.813C16.4791 19.6621 16.5591 19.4573 16.5591 19.2439V9.58533Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 5.4C21 5.78898 20.8541 6.16204 20.5945 6.43709C20.3348 6.71214 19.9826 6.86667 19.6154 6.86667H18.9231V20.0667C18.9231 20.8446 18.6313 21.5907 18.112 22.1408C17.5927 22.691 16.8883 23 16.1538 23H7.84615C7.11171 23 6.40734 22.691 5.88801 22.1408C5.36868 21.5907 5.07692 20.8446 5.07692 20.0667V6.86667H4.38462C4.01739 6.86667 3.66521 6.71214 3.40554 6.43709C3.14588 6.16204 3 5.78898 3 5.4V3.93333C3 3.54435 3.14588 3.1713 3.40554 2.89624C3.66521 2.62119 4.01739 2.46667 4.38462 2.46667H9.23077C9.23077 2.07768 9.37665 1.70463 9.63631 1.42958C9.89598 1.15452 10.2482 1 10.6154 1H13.3846C13.7518 1 14.104 1.15452 14.3637 1.42958C14.6234 1.70463 14.7692 2.07768 14.7692 2.46667H19.6154C19.9826 2.46667 20.3348 2.62119 20.5945 2.89624C20.8541 3.1713 21 3.54435 21 3.93333V5.4ZM6.62492 6.86667L6.46154 6.9532V20.0667C6.46154 20.4557 6.60742 20.8287 6.86708 21.1038C7.12675 21.3788 7.47893 21.5333 7.84615 21.5333H16.1538C16.5211 21.5333 16.8733 21.3788 17.1329 21.1038C17.3926 20.8287 17.5385 20.4557 17.5385 20.0667V6.9532L17.3751 6.86667H6.62492ZM4.38462 5.4V3.93333H19.6154V5.4H4.38462Z"
            fill={color}
        />
    </svg>
);

export default TrashcanIcon;
