export const getPageTitleByPath = (path: string) => {
  if (path.startsWith('/group/')) {
    return 'Group page';
  }
  if (path.startsWith('/user/')) {
    return 'User-profile page';
  }
  if (path.startsWith('/article')) {
    return 'Article page';
  }
  switch (path) {
    case '/':
      return 'Landing page';
    case '/login':
      return 'Login page';
    case '/feed':
      return 'Feed page';
    case '/admin':
      return 'Admin page';
    case '/mobile-chat':
      return 'Mobile chat page';
    case '/mobile-emergency':
      return 'Create emergency page';
    case '/create-group-m':
      return 'Create group page';
    case '/mobile-notifications':
      return 'Notification page';
    case '/settings':
      return 'Settings page';
    case '/contact':
      return 'FAQ page';
    case '/invite-friend':
      return 'Invite friend page';
    default:
      return '*';
  }
};