import { format } from 'date-fns';
import { ReactComponent as Angry } from 'emoji/angry.svg';
import { ReactComponent as Confident } from 'emoji/Confident.svg';
import { ReactComponent as Confused } from 'emoji/confused.svg';
import { ReactComponent as Curious } from 'emoji/courious.svg';
import { ReactComponent as Excited } from 'emoji/excited.svg';
import { ReactComponent as Frustrated } from 'emoji/frustrated.svg';
import { ReactComponent as Happy } from 'emoji/Happy.svg';
import { ReactComponent as SadSvg } from 'emoji/sad.svg';
import { ReactComponent as Tired } from 'emoji/tiered.svg';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { useToken } from '../../cookies';
import { getMondayOfCurrentWeek } from '../../helpers/date';
import { getMoodText } from '../../helpers/moodList';
import { getGroupsWeekMoods, selectGroupWeekMoods, selectRecentGroupId } from '../../store/groups';
import { IUserMoodOneGroup } from '../../types/Moods';

const CustomizedDot = (props: { cx: number; cy: number; value: Array<number> } | any) => {
  const { cx, cy, value } = props;
  switch (value[1]) {
    case 8:
      return <Happy x={cx - 10} y={cy - 10} width={28} height={28} />;
    case 7:
      return <Confident x={cx - 10} y={cy - 20} width={28} height={28} />;
    case 6:
      return <Excited x={cx - 10} y={cy - 20} width={28} height={28} />;
    case 5:
      return <Confused x={cx - 10} y={cy - 20} width={28} height={28} />;
    case 4:
      return <Curious x={cx - 10} y={cy - 20} width={28} height={28} />;
    case 3:
      return <Tired x={cx - 10} y={cy - 20} width={28} height={28} />;
    case 2:
      return <Angry x={cx - 10} y={cy - 20} width={28} height={28} />;
    case 1:
      return <Frustrated x={cx - 10} y={cy - 20} width={28} height={28} />;
    case 0:
      return <SadSvg x={cx - 10} y={cy - 20} width={28} height={28} />;
    default:
      return null;
  }
};

export function RechartsGroupMood({
  isExpand,
  groupData,
}: {
  isExpand?: boolean;
  groupData?: Array<IUserMoodOneGroup>;
}) {
  const dispatch = useDispatch();
  const data = useSelector(selectGroupWeekMoods);
  // @ts-ignore
  const { id } = useParams() || { id: '0' };

  const { token } = useToken();
  const recentGroupId = useSelector(selectRecentGroupId);

  useEffect(() => {
    if (!isExpand) {
      dispatch(
        getGroupsWeekMoods({
          token,
          groupId: id || recentGroupId,
          from: format(getMondayOfCurrentWeek(), 'yyyy-MM-dd'),
        }),
      );
    }
    // eslint-disable-next-line
  }, [id]);

  return data.length || (isExpand && groupData?.length) ? (
    <div
      style={{
        marginTop: isExpand ? '0px' : '12px',
        height: isExpand ? '210' : '310px',
        border: !isExpand ? '1px solid rgba(182, 182, 182, 0.37)' : 'none',
        borderRadius: '6px',
      }}
    >
      <ResponsiveContainer width={'100%'} height={isExpand ? 200 : 300}>
        <AreaChart
          width={500}
          height={300}
          data={isExpand ? groupData : data}
          margin={{
            top: 18,
            right: 30,
            left: -30,
            bottom: 18,
          }}
        >
          <defs>
            <linearGradient id={'color'} x1={'0'} y1={'0'} x2={'0'} y2={'1'}>
              <stop offset={'0%'} stopColor={'#282828'} stopOpacity={0.4} />
              <stop offset={'75%'} stopColor={'#494949'} stopOpacity={0.05} />
            </linearGradient>
          </defs>
          <Area dataKey={'pv'} stroke={'#707070'} fill={'url(#color)'} />
          <XAxis
            tickFormatter={(str) => {
              return str;
            }}
            axisLine={false}
            tickLine={false}
            dataKey={'name'}
            tickSize={15}
            stroke={'white'}
            lightingColor={'blue'}
          />
          <YAxis
            dataKey={'pv'}
            tickFormatter={(number) => {
              return ``;
            }}
            axisLine={false}
            tickLine={false}
            tickCount={3}
            tickSize={1}
            domain={[0, 8]}
          />
          <CartesianGrid opacity={0.1} vertical={false} strokeDasharray="3 3" />
          <Area
            dataKey={'pv'}
            stroke={'#3f3f3f'}
            fill={'url(#color)'}
            dot={<CustomizedDot />}
            isAnimationActive={false}
          />
          <Tooltip
            content={({ active, payload, label }: any) => (
              <CustomTooltip active={active} payload={payload} label={label} />
            )}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  ) : null;
}

function CustomTooltip({ active, payload, label }: any) {
  if (active && typeof payload[0]?.payload?.pv === 'number') {
    return <div>{getMoodText(payload[0].payload.pv)}</div>;
  }
  return <div>No data</div>;
}

export const getColorByMood = (mood: number | null) => {
  if (mood === -1) {
    return '#1f9d91';
  }

  if (mood === null) {
    return '#b9b9b9';
  }

  if (mood > 5) {
    return '#61A125';
  }
  if (mood > 2 && mood < 6) {
    return '#FFC700';
  } else return '#EA0017';
};

export const getBackGroundColorByMood = (mood: number | null) => {
  if (mood === -1) {
    return 'rgba(255,255,255,0.12)';
  }

  if (mood === null) {
    return 'rgba(185,185,185,0.32)';
  }

  if (mood > 5) {
    return 'rgba(97,161,37,0.14)';
  }
  if (mood > 2 && mood < 6) {
    return 'rgba(255,199,0,0.14)';
  } else return 'rgba(234,0,23,0.11)';
};