import { css } from 'styled-components/macro';
import Header from '../../components/Header/Header';
import { Wrapper } from '../../components/layout/Wrapper';
import WelcomeBlock from '../../components/Auth/WelcomeBlock';
import AuthBlock from '../../components/Auth/AuthBlock';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { clearArticlesState } from '../../store/articles';
import { clearUserState } from '../../store/user';
import { useIsMobile, useIsTablet } from 'src/utils/styles.utils';
import ForgotPassword from '../ResetAccount/ForgotPassword';

export default function LoginPage() {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const userAgent = navigator.userAgent.toLowerCase();
  const isWebView = /(iPhone|iPod|iPad|Android).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);

  const openStore = () => {
    if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
      window.location.href = 'https://apps.apple.com/md/app/vext/id6443604829';
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.vext.cordovareactapp&pli=1';
    }
  };
  useEffect(() => {
    dispatch(clearUserState());
    dispatch(clearArticlesState());
    // eslint-disable-next-line
  }, []);
  console.log('DT-', window.deviceToken);

  return (
    <div
      css={css`
        ${isMobile
          ? `height: 100vh;
             background-color: #000000;`
          : ``}
      `}
    >
      {!isMobile && <Header />}
      <Wrapper>
        <div
          css={css`
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-around;
            ${isMobile ? `margin: 0;` : `margin: 82px 0;`}
          `}
        >
          {!isMobile && !isTablet && <WelcomeBlock />}
          <AuthBlock />
        </div>
        {!isWebView && (isMobile || isTablet) && (
          <>
            <div
              css={css`
                display: flex;
                width: 50%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 14px;
                margin-bottom: 10px;
                border-radius: 10px;
                padding: 4px;
                flex-direction: row;
                color: white;
                border: 1px solid white;
                justify-content: space-around;
              `}
              onClick={openStore}
            >
              Open in App Store
            </div>
            <ForgotPassword />
          </>
        )}
      </Wrapper>
    </div>
  );
}
