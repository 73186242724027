export const LikeThumbsUpIcon = (color='#757575', size='24') => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 21H18C18.83 21 19.54 20.5 19.84 19.78L22.86 12.73C22.95 12.5 23 12.26 23 12V10C23 8.9 22.1 8 21 8H14.69L15.64 3.43L15.67 3.11C15.67 2.7 15.5 2.32 15.23 2.05L14.17 1L7.58 7.59C7.22 7.95 7 8.45 7 9V19C7 20.1 7.9 21 9 21ZM9 9L13.34 4.66L12 10H21V12L18 19H9V9ZM1 9H5V21H1V9Z"
      fill={color}
    />
    <path
      d="M8.73514 19.1369C8.75898 19.4717 15.003 19.2764 18.1221 19.1369L22.0457 9.71244L12.5318 9.00271C12.9105 7.2788 13.6618 3.79511 13.6379 3.65162C13.614 3.50813 10.3594 6.90014 8.73514 8.61408C8.7252 11.9822 8.71129 18.802 8.73514 19.1369Z"
      fill={color}
    />
  </svg>
);
