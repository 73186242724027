import { RootState } from '../rootReducer';
import { IGroup } from '../../types/Group';
import { IDayMoodForAllGroups } from '../../types/Moods';
import { User } from '../../types/User';

export const selectFilteredStatisticsGroups = (state: RootState): Array<IGroup> =>
  Object.values(state.statistics.allGroupsBaseInfo.results);
export const selectFilteredStatisticsPeople = (state: RootState): Array<User> =>
  Object.values(state.statistics.allPeoplesBaseInfo.results);

export const selectStatisticsLoading = (state: RootState): boolean => state.statistics.loading;

export const selectStatisticsNextGroupPage = (state: RootState): string | null =>
  state.statistics.allGroupsBaseInfo.next;

export const selectStatisticsNextPeoplePage = (state: RootState): string | null =>
  state.statistics.allPeoplesBaseInfo.next;

export const selectGroupsStatisticsAverageMoodsByGroupId =
  (groupId: string) =>
  (state: RootState): Array<{ name: string; val: null | number }> =>
    state.statistics.averageStatisticsMoods[groupId] || [];

export const selectGroupsStatisticsDetailedMoodsByGroupId =
  (groupId: string) =>
  (state: RootState): Array<IDayMoodForAllGroups> =>
    state.statistics.detailedStatisticsMoods[groupId] || [];

export const selectProfilesWithBadMoodsByGroupId =
  (groupId: string) =>
  (state: RootState): Array<User> =>
    state.statistics.badMoodsAccounts[groupId] || [];

export const selectOutlierCount =
  (groupId: string) =>
  (state: RootState): number =>
    state.statistics.outlierCount[groupId] || 1;
