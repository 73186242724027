import { facebookIcon } from 'images/facebookIcon';
import { linkIcon } from 'images/linkIcon';
import { twitterIcon } from 'images/twitterIcon';
import styles from '../Footer.module.css';

export default function FooterIcons() {
  return (
    <div className={styles.footerIcons}>
      {facebookIcon()}
      {twitterIcon()}
      {linkIcon()}
    </div>
  );
}
