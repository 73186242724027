import { ApiResponse } from 'apisauce';
import { IGroup } from 'src/types/Group';

import { _RestApiService } from './api.service';

import { adjustToHour23, currentDateInIsoFormat } from '../helpers/date';

const groupsApi = new _RestApiService(window._env_.REACT_APP_BACKEND_URL || '');

export class _GroupsApiService {
  getGroups(
    token: string,
    page?: number,
  ): Promise<ApiResponse<{ count: number; next: string | null; results: Array<IGroup> }>> {
    //TODO change endpoint to /unpinned only in mobile version
    const now = new Date();
    const timezoneOffsetHours = -now.getTimezoneOffset() / 60;

    return groupsApi.get(
      `/api/groups/unpinned/`,
      {
        page,
        page_size: 12,
        time_zone: timezoneOffsetHours,
      },
      groupsApi.getAuthenticateHeader(token),
    );
  }

  getAllGroupsName(
    token: string,
  ): Promise<ApiResponse<{ count: number; next: string | null; results: Array<IGroup> }>> {
    return groupsApi.get(`/api/groups/names/`, {}, groupsApi.getAuthenticateHeader(token));
  }

  getFullGroupById(token: string, groupId: string): Promise<ApiResponse<IGroup>> {
    return groupsApi.get(`/api/groups/${groupId}/`, {}, groupsApi.getAuthenticateHeader(token));
  }

  getGroupMoodsByDate(
    token: string,
    groupId: string,
    from?: string,
    to?: string,
  ): Promise<ApiResponse<{ moods: any }>> {
    // Instead of current time this will set end of the day
    const adjustedTime = adjustToHour23(currentDateInIsoFormat({ date: to }));

    return groupsApi.get(
      `/api/groups/${groupId}/mood/`,
      {
        from: currentDateInIsoFormat({ date: from }),
        to: adjustedTime,
      },
      groupsApi.getAuthenticateHeader(token),
    );
  }

  getAverageDomainMoodsByDate(token: string, from?: string, to?: string): Promise<ApiResponse<{ moods: any }>> {
    return groupsApi.get(
      `/admin/moods/`,
      {
        from: currentDateInIsoFormat({ date: from, utcZero: false }),
        to: currentDateInIsoFormat({ date: to, end: true }),
      },
      groupsApi.getAuthenticateHeader(token),
    );
  }

  createGroup(token: string, data: any): Promise<ApiResponse<any>> {
    return groupsApi.post(`/api/groups/`, data, groupsApi.getAuthenticateHeaderFormData(token));
  }

  getPinnedGroups(token: string, page?: number): Promise<ApiResponse<any>> {
    return groupsApi.get(`/api/groups/pinned/`, { page, page_size: 8 }, groupsApi.getAuthenticateHeader(token));
  }

  setPinnedGroups(token: string, groupId: string): Promise<ApiResponse<any>> {
    return groupsApi.post(`/api/groups/${groupId}/add_pin/`, {}, groupsApi.getAuthenticateHeader(token));
  }

  removePinnedGroups(token: string, groupId: string): Promise<ApiResponse<any>> {
    return groupsApi.delete(`/api/groups/${groupId}/delete_pin/`, {}, groupsApi.getAuthenticateHeader(token));
  }

  joinGroup(token: string, groupId: string, userId: string): Promise<ApiResponse<any>> {
    return groupsApi.post(`/api/groups/${groupId}/join/`, {}, groupsApi.getAuthenticateHeader(token));
  }

  leaveGroup(token: string, groupId: string, userId: string): Promise<ApiResponse<any>> {
    return groupsApi.post(`/api/groups/${groupId}/leave/`, { id: userId }, groupsApi.getAuthenticateHeader(token));
  }

  deleteGroup(token: string, groupId: string): Promise<ApiResponse<any>> {
    return groupsApi.delete(`/api/groups/${groupId}/`, {}, groupsApi.getAuthenticateHeader(token));
  }

  searchGroup(token: string, param: string): Promise<ApiResponse<any>> {
    return groupsApi.get(`/api/groups/search/`, { param }, groupsApi.getAuthenticateHeader(token));
  }

  inviteFriendInGroup(token: string, email: string[], inviteUrl: string): Promise<ApiResponse<any>> {
    return groupsApi.post(
      `/api/users/invite/?invite_url=${inviteUrl}`,
      { email },
      groupsApi.getAuthenticateHeader(token),
    );
  }
}

export const GroupsApiService = new _GroupsApiService();
