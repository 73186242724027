import React, { SyntheticEvent, useState } from 'react';
import NavigationNavbar from '../../components/Header/NavigationHeader';
import { useIsMobile, useIsTablet } from '../../utils/styles.utils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ReactComponent as MinusIcon } from 'images/MinusIcon.svg';
import { ReactComponent as ExpandIcon } from 'images/ExpandIcon.svg';
import AccordionDetails from '@mui/material/AccordionDetails';
import {
  VextMeaning,
  VextSharingPaper,
  VextUseExamples,
  WhatIsVext,
  WhoUseVext,
  WhyUseVext,
  MyVextName,
  VextPaperHelp,
  VextPoints,
  EarnVextPoints,
  ContactVext,
} from './components';
import ArrowBackButton from '../../components/mobile/Buttons/ArrowBackButton';
import { useToken } from '../../cookies';
import MobileHeader from "../../components/mobile/Header/MobileHeader";
import styles from './ContactStyles.module.css';

function ContactPage() {
  const [expanded, setExpanded] = useState<string | false>(false);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { token } = useToken();

  const getHeader = () => {
    if (isMobile || isTablet) {
      if (!token) {
        return (
          <div style={{ marginTop: '0px', padding: '20px' }}>
            <ArrowBackButton pushUrl={'/login'} style={{ borderRadius: '50%' }} />
          </div>
        );
      }
      return <MobileHeader />;
    }
    return <NavigationNavbar />;
  };
  return (
    <div className={styles.mainContact}>
      {getHeader()}
      <div className={styles.contentBlock}>
        <Accordion
          expanded={expanded === 'panel0'}
          onChange={handleChange('panel0')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel0' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel0a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel1' ? '#FFC700' : 'white', padding: '0' }}
          >
            How do I contact VEXT?
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <ContactVext />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel1' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel1' ? '#FFC700' : 'white', padding: '0' }}
          >
            What does VEXT mean?
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <VextMeaning />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel2' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel2' ? '#FFC700' : 'white', padding: '0' }}
          >
            What is VEXT?
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <WhatIsVext />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel3' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel3' ? '#FFC700' : 'white', padding: '0' }}
          >
            Who uses VEXT?
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <WhoUseVext />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel4' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel4' ? '#FFC700' : 'white', padding: '0' }}
          >
            Why should I use VEXT?
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <WhyUseVext />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel8'}
          onChange={handleChange('panel8')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel8' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel8' ? '#FFC700' : 'white', padding: '0' }}
          >
            Should I use my real name or a screen name to publish on VEXT?
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <MyVextName />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel11'}
          onChange={handleChange('panel11')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel11' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel11' ? '#FFC700' : 'white', padding: '0' }}
          >
            How can I get help on VEXT
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <VextPaperHelp />
          </AccordionDetails>
        </Accordion>
        {/* ---------------  What about moderators   ------------- */}
        <Accordion
          expanded={expanded === 'panel12'}
          onChange={handleChange('panel12')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel12' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel12' ? '#FFC700' : 'white', padding: '0' }}
          >
            What about moderators
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <VextPoints />
          </AccordionDetails>
        </Accordion>

        {/*-------------How do I stay anonymous with my moods? ------------*/}
        <Accordion
          expanded={expanded === 'panel13'}
          onChange={handleChange('panel13')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel13' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel13' ? '#FFC700' : 'white', padding: '0' }}
          >
            How do I stay anonymous with my moods?
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <EarnVextPoints />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel6' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel6' ? '#FFC700' : 'white', padding: '0' }}
          >
            Why should I consider sharing on VEXT?
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <VextSharingPaper />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          style={{ backgroundColor: 'black' }}
        >
          <AccordionSummary
            expandIcon={expanded === 'panel5' ? <MinusIcon /> : <ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: expanded === 'panel5' ? '#FFC700' : 'white', padding: '0' }}
          >
            Are there examples of how to use VEXT?
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <VextUseExamples />
          </AccordionDetails>
        </Accordion>
        <h4>
          If you have questions, feedback, or general inquiries about the VEXT platform or VEXT for Android or iPhone,
          please contact us at: <span style={{ color: 'yellow' }}>hey@vext.co</span>
        </h4>
      </div>
    </div>
  );
}

export default ContactPage;