import { AskedIcon } from 'images/AskedIcon';
import { CommentedIcon } from 'images/CommentedIcon';
import { EllipseIcon } from 'images/EllipseIcon';
import { FollowedIcon } from 'images/FollowedIcon';
import { LikedIcon } from 'images/LikedIcon';
import { PostedIcon } from 'images/PostedIcon';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToken } from 'src/cookies';
import { NotificationsApiService } from 'src/services/notifications.service';
import { clearSelectedPaper } from 'src/store/articles';
import { getNotifications } from 'src/store/notifications';
import { selectNotifications } from 'src/store/notifications/selector';
import { Notification } from 'src/types/Notifications';
import { NO_USER_AVATAR } from '../../utils/constantImagesUrl';
import styles from './styles.module.css';
import { calcCreatedTime } from 'src/helpers/date';

export enum NotificationType {
  QUESTION_LIKE = 'QUESTION_LIKE',
  QUESTION_COMMENT = 'QUESTION_COMMENT',
  COMMENT_LIKE = 'COMMENT_LIKE',
  PAPER_LIKE = 'PAPER_LIKE',
  PAPER_COMMENT = 'PAPER_COMMENT',
  ASKED = 'ASKED',
  POSTED = 'POSTED',
  FOLLOWED = 'FOLLOWED',
  CHAT = 'CHAT',
}

export default function MyNotificationsTab() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useToken();
  const notifications = useSelector(selectNotifications);

  useEffect(() => {
    dispatch(getNotifications({ token }));
    // eslint-disable-next-line
  }, []);

  const getNotifyIcon = (type: NotificationType) => {
    if (type === NotificationType.COMMENT_LIKE || type === NotificationType.QUESTION_LIKE) {
      return LikedIcon();
    }
    if (type === NotificationType.PAPER_COMMENT || type === NotificationType.QUESTION_COMMENT) {
      return CommentedIcon();
    }
    if (type === NotificationType.ASKED) {
      return AskedIcon();
    }
    if (type === NotificationType.FOLLOWED) {
      return FollowedIcon();
    }
    if (type === NotificationType.POSTED) {
      return PostedIcon();
    }
  };

  const getNotificationType = (notification: Notification) => {
    return (
      <div className={styles.notificationIconBlock}>
        <span className={styles.notificationBold}>
          {' '}
          {notification.sender.profile.nickname || notification.sender.profile.first_name}
        </span>
        <span className={styles.notificationIcon}> {getNotifyIcon(notification.type)} </span>{' '}
        <span className={styles.notificationBold}>{notification.description}</span>
      </div>
    );
  };

  return (
    <div>
      {notifications.map((notification) => {
        return (
          <div
            key={notification.id}
            className={styles.notificationBlock}
            onClick={() => {
              NotificationsApiService.markNotificationAsRead(token, notification.id);

              if (notification.type === 'QUESTION_COMMENT' || notification.type === 'QUESTION_LIKE') {
                return window.location.replace(`${window.location.origin}/question/${notification.target_id}`);
              }
              dispatch(clearSelectedPaper());
              history.push(`/article${notification.target_id}`);
            }}
          >
            <img className={styles.userImage} src={notification.sender.profile.image_url || NO_USER_AVATAR} alt="" />
            <div className={styles.notificationInfoBlock}>
              {getNotificationType(notification)}
              <span className={!notification.read ? styles.timePostNotOpened : styles.timePostOpened}>
                {calcCreatedTime(notification.created_at)}
              </span>
            </div>
            {!notification.read && <div className={styles.ellipse}>{EllipseIcon()}</div>}
          </div>
        );
      })}
    </div>
  );
}
