import { useHistory } from 'react-router';
import { css } from 'styled-components/macro';
import { ReactComponent as LogoSvg } from 'images/logo/Logo.svg';

export default function Header() {
  const history = useHistory();
  return (
    <div
      css={css`
        position: fixed;
        height: 64px;
        display: flex;
        align-items: center;
        margin-top: 36px;
        padding: 0 105px;
      `}
    >
      <LogoSvg
        width={100}
        height={72}
        css={css`
          cursor: pointer;
        `}
        onClick={() => history.push('/feed')}
      />
    </div>
  );
}
