import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import style from './styles.module.css';
import { css } from 'styled-components/macro';

function HealthKitPermissions({ email }: { email: string }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    console.log(email)
    if (email == 'vmorari') {
      if (localStorage.getItem('userAcceptedHealthPermissions') !== 'true') {
        setIsModalOpen(true);
      }
    }
  }, [email]);

  function saveHealthPermissions() {
    localStorage.setItem('userAcceptedHealthPermissions', 'true');
    window.parent.postMessage(`{"userAcceptedHealthPermissions":"true", "metrics":["sleep","steps","calories"]}`, '*');
    setIsModalOpen(false);
  }

  const mobileModalStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 54%;
    border-radius: 20px;
    overflow: hidden;
    background: #131313;
    color: white;
    margin: 38% auto;
    padding: 10px;
  `;

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        contentLabel="My dialog"
        className="mymodalPermissions"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        css={mobileModalStyles}
      >
        <h2>Health Kit Data Permissions</h2>
        <h4 className={style.resetPassInfoLabel}>
          <b className={style.vext}>Vext</b> needs permissions to your health data such as: sleep, calories, steps, in
          order to track your health status. <br />
          Only you and individuals like super admins who have the necessary certifications and qualifications will have
          access to this data.
        </h4>
        <div className={style.buttonsBlock}>
          <div onClick={saveHealthPermissions} className={style.button}>
            Next
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default HealthKitPermissions;