export const emergencyIcon = (w = '24', h = '24', color = 'white') => (
  <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0006 14.6518C11.6052 14.6518 11.2773 14.3239 11.2773 13.9286V9.10716C11.2773 8.7118 11.6052 8.38394 12.0006 8.38394C12.3959 8.38394 12.7238 8.7118 12.7238 9.10716V13.9286C12.7238 14.3239 12.3959 14.6518 12.0006 14.6518Z"
      fill={color}
    />
    <path
      d="M11.9994 17.7856C11.9416 17.7856 11.8741 17.776 11.8066 17.7663C11.7487 17.7567 11.6909 17.7374 11.633 17.7085C11.5752 17.6892 11.5173 17.6603 11.4594 17.6217C11.4112 17.5831 11.363 17.5446 11.3148 17.506C11.1412 17.3228 11.0352 17.0721 11.0352 16.8213C11.0352 16.5706 11.1412 16.3199 11.3148 16.1367C11.363 16.0981 11.4112 16.0596 11.4594 16.021C11.5173 15.9824 11.5752 15.9535 11.633 15.9342C11.6909 15.9053 11.7487 15.886 11.8066 15.8763C11.9319 15.8474 12.0669 15.8474 12.1827 15.8763C12.2502 15.886 12.308 15.9053 12.3659 15.9342C12.4237 15.9535 12.4816 15.9824 12.5394 16.021C12.5877 16.0596 12.6359 16.0981 12.6841 16.1367C12.8577 16.3199 12.9637 16.5706 12.9637 16.8213C12.9637 17.0721 12.8577 17.3228 12.6841 17.506C12.6359 17.5446 12.5877 17.5831 12.5394 17.6217C12.4816 17.6603 12.4237 17.6892 12.3659 17.7085C12.308 17.7374 12.2502 17.7567 12.1827 17.7663C12.1248 17.776 12.0573 17.7856 11.9994 17.7856Z"
      fill={color}
    />
    <path
      d="M17.7923 22.2857H6.20483C4.34051 22.2857 2.91597 21.5669 2.18936 20.2711C1.47231 18.9753 1.56792 17.3048 2.47618 15.5737L8.26993 4.53882C9.226 2.71654 10.5454 1.71429 11.9986 1.71429C13.4518 1.71429 14.7712 2.71654 15.7272 4.53882L21.521 15.5838C22.4292 17.315 22.5344 18.9753 21.8078 20.2812C21.0812 21.5669 19.6567 22.2857 17.7923 22.2857ZM11.9986 3.23285C11.0999 3.23285 10.2203 3.96176 9.52238 5.27785L3.73819 16.3228C3.08806 17.5579 2.98289 18.6918 3.43224 19.5118C3.88159 20.3318 4.8759 20.7773 6.21439 20.7773H17.8019C19.1404 20.7773 20.1251 20.3318 20.584 19.5118C21.0429 18.6918 20.9282 17.5681 20.2781 16.3228L14.4748 5.27785C13.7769 3.96176 12.8973 3.23285 11.9986 3.23285Z"
      fill={color}
    />
  </svg>
);
