import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HealthOBj } from '../../types/HealthTypes';
import { HealthApiService } from '../../services/health.service';
import { isPendingAction, isRejectedAction } from '../../utils/redux.utils';

export const HEALTH_REDUCER_NAME = 'health';

type InitialStateType = {
  health: { [key: string]: Array<HealthOBj> };
  isLoading: boolean;
  error: null | string;
};

const initialState: InitialStateType = {
  health: {},
  error: null,
  isLoading: false,
};

export const getHealthInfo = createAsyncThunk(
  `${HEALTH_REDUCER_NAME}/getHealthInfo`,
  async ({
    token,
    unit,
    to_date,
    from_date,
    author_id,
  }: {
    token: string;
    author_id?: string;
    from_date?: string;
    to_date?: string;
    unit?: string;
  }) => {
    const response = await HealthApiService.getHealthInfo(token, author_id, from_date, to_date, unit);
    if (response.ok) {
      return response.data;
    } else {
      throw new Error('Health kit data error!');
    }
  },
);

const healthSlice = createSlice({
  name: HEALTH_REDUCER_NAME,
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getHealthInfo.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.health = payload;
        state.error = null;
        state.isLoading = false;
      })
      .addMatcher(isPendingAction(`${HEALTH_REDUCER_NAME}/`), (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addMatcher(isRejectedAction(`${HEALTH_REDUCER_NAME}/`), (state, { error }) => {
        console.log('ERROR-health-reducer>>>', error);
        state.error = 'Get tags error';
        state.isLoading = false;
      });
  },
});

export const {} = healthSlice.actions;
export default healthSlice.reducer;