import { RootState } from '../rootReducer';

export const selectDashboardEmergencies = (state: RootState): Array<{ x: number; y: number }> =>
  state.dashboard.emergencies;

export const selectDashboardEmergenciesAmount = (state: RootState): number => state.dashboard.emergenciesAmount;
export const selectDashboardEmergenciesMaxPerHour = (state: RootState): number => state.dashboard.maxEmergencyPerHour;
export const selectDashboardEmergenciesPercent = (state: RootState): number => state.dashboard.emergencyPercent;

export const selectDashboardOutliers = (state: RootState): Array<{ x: number; y: number }> => state.dashboard.outliers;

export const selectDashboardOutliersAmount = (state: RootState): number => state.dashboard.outliersAmount;
export const selectDashboardOutliersMaxPerHour = (state: RootState): number => state.dashboard.maxOutliersPerHour;
export const selectDashboardOutliersPercent = (state: RootState): number => state.dashboard.outliersPercent;
export const selectDashboardUsersAndRoles = (state: RootState): { [key: string]: number } =>
  state.dashboard.rolesAndUsers;