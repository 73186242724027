import { noChats } from 'images/noChats';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ChatsStyles.module.css';
import ChatList from './components/ChatList';
import ConversationWindow from './components/ConversationWindow';

import { useToken } from '../../../cookies';
import { getSortedChats } from '../../../helpers/arrays';
import useChatsSocket from '../../../hooks/useChatsSocket';
import { getPeopleConversations } from '../../../store/chats';
import { selectOpenedChat, selectPeopleChats } from '../../../store/chats/selector';


function Chats() {
  const { sendMessage } = useChatsSocket();
  const openedChat = useSelector(selectOpenedChat);
  const chats = useSelector(selectPeopleChats);
  const listInnerRef = useRef(null);
  const dispatch = useDispatch();
  const { token } = useToken();

  useEffect(() => {
    dispatch(getPeopleConversations({ token, page: 1 }));
  }, []);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight + 1 > scrollHeight) {
        //  TODO add pagination for chatList in admin
      }
    }
  };

  return (
    <div className={styles.chatMain}>
      <div className={styles.bottomBlock}>
        <div onScroll={() => onScroll()} ref={listInnerRef} className={styles.chatList}>
          <ChatList chatItems={getSortedChats(chats)} />
        </div>

        <div className={styles.conversationWindow}>
          {openedChat ? (
            <ConversationWindow sendMessage={sendMessage} />
          ) : (
            <div className={styles.noChatIcon}>{noChats()}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Chats;
