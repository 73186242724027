import React, { useEffect, useState } from 'react';
import { StatisticsApiService } from '../../services/statistics.service';
import Modal from 'react-modal';
import MessageHockPopUp from '../../hooks/MessageHockPopUp';
import { css } from 'styled-components/macro';
import { useIsMobile, useIsTablet } from '../../utils/styles.utils';
import CircularProgress from '@mui/material/CircularProgress';
import style from './recoverStyles.module.css';

function ForgotPassword() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isMobOrTabletDevice = isMobile || isTablet;

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(email);
  };

  useEffect(() => {
    if (email.length > 0 && !validateEmail(email)) {
      setEmailError('Not a valid email!');
      return;
    } else {
      setEmailError('');
    }
  }, [email]);

  const mobileModalStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 46%;
    border-radius: 20px;
    overflow: hidden;
    background: #131313;
    color: white;
  `;

  const desktopModalStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 34%;
    height: 40%;
    border-radius: 20px;
    overflow: hidden;
    background: #f8f7f7;
    color: #2c2c2c;
  `;

  const handleSubmit = () => {
    setEmailError('');
    setIsLoading(true);
    const baseUrl = window.location.origin || 'https://vext.co';
    StatisticsApiService.recoverUser({ email: email, recoverUrl: baseUrl + '/recover/' })
      .then((response) => {
        if (response.ok) {
          MessageHockPopUp({
            size: 'small',
            message: 'Email sent',
            timeOut: 5000,
            type: 'success',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalOpen(false);
      });
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setEmailError('');
          setIsModalOpen(false);
        }}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        css={isMobOrTabletDevice ? mobileModalStyles : desktopModalStyles}
      >
        {isLoading && (
          <div style={{ position: 'absolute', zIndex: '200', top: '50%' }}>
            <CircularProgress />
          </div>
        )}
        <h1>Reset password</h1>
        <h4 className={style.resetPassInfoLabel}>
          Please enter the email address associated with the account for which you've forgotten the password. We will
          send the password reset link to this address.
        </h4>
        <input
          style={{ color: isMobOrTabletDevice ? 'white' : 'black' }}
          className={style.forgotPassInput}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <p className={style.resetPassErrorLabel}>{emailError}</p>
        <button
          className={style.resetPassSubmitBtn}
          disabled={!!emailError || email.length === 0}
          onClick={handleSubmit}
        >
          Reset
        </button>
      </Modal>
      <span className={style.forgotPass} onClick={() => setIsModalOpen(true)}>
        Forgot password?
      </span>
    </>
  );
}

export default ForgotPassword;
