import { Tooltip } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { infoIcon } from 'images/infoIcon';
import { GifTab } from '../components/mobile/Gif/GifTab';
import { sendMessageIcon } from 'images/sendMessageIcon';
import React, { Dispatch, SetStateAction } from 'react';
import { IOSSwitch } from '../utils/componenets';
import style from '../components/mobile/MobileArticleView/MobileArticleViewStyles.module.css';

type Props = {
  setCommentInput: Dispatch<SetStateAction<string>>;
  commentInput: string;
  isMessageAnonymous: boolean;
  setIsMessageAnonymous: Dispatch<SetStateAction<boolean>>;
  setTooltipOpen: Dispatch<SetStateAction<boolean>>;
  isTooltipOpen: boolean;
  anchorEl: boolean;
  setAnchorEl: React.Dispatch<React.SetStateAction<boolean>>;
  addComment(): void;
};

export default function SendCommentBlock(props: Props) {
  const {
    setCommentInput,
    commentInput,
    isMessageAnonymous,
    setIsMessageAnonymous,
    setTooltipOpen,
    isTooltipOpen,
    anchorEl,
    setAnchorEl,
    addComment,
  } = props;

  return (
    <div className={style.addCommentMobileInputBlock}>
      <input
        onChange={(e) => setCommentInput(e.target.value)}
        className={style.addCommentMobileInput}
        type="text"
        placeholder="Your comment"
        value={commentInput}
      />
      <div className={style.bottomMessageBlock} style={{ color: 'white' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  onChange={(e:{ target: { checked: boolean } }) => {
                    setIsMessageAnonymous(e.target.checked);
                  }}
                  checked={isMessageAnonymous}
                />
              }
              label="Incognito"
            />
          </FormGroup>

          <Tooltip
            arrow
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setTooltipOpen(false)}
            open={isTooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Leave a comment as incognito"
          >
            <div onClick={() => setTooltipOpen(!isTooltipOpen)}>{infoIcon()} </div>
          </Tooltip>
        </div>

        <div className={style.sendMessageUtilities}>
          {anchorEl && <GifTab setAnchorEl={setAnchorEl} />}
          <div onClick={addComment} className={style.doubleDownArrowBlock}>
            {sendMessageIcon()}
          </div>
        </div>
      </div>
    </div>
  );
}
