import React from 'react';

const InfoIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4508 11.9999C21.4508 6.78077 17.2199 2.54987 12.0008 2.54987C6.78169 2.54987 2.55078 6.78077 2.55078 11.9999C2.55078 17.219 6.78169 21.4499 12.0008 21.4499C17.2199 21.4499 21.4508 17.219 21.4508 11.9999Z"
        stroke="#757575"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12 16.2857V12M12 7.71429H11.9893"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default InfoIcon;