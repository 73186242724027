import React, { useState } from 'react';
import { SmallVextLogo } from '../../assets/LogoFrames/SmallVextLogo';
import PasswordInput from '../../helpers/PasswordInput';
import { darkTheme } from '../../themes/themes';
import { ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';
import styles from './recoverStyles.module.css';

function ResetAccountMob({
  submit,
}: {
  submit: (password: string, confirmPassword: string, termsAndCondAccepted: boolean, params: any) => void;
}) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAndCondAccepted, setTermsAndCondAccepted] = useState(false);
  const params = useParams() as any;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAndCondAccepted(event.target.checked);
  };
  return (
    <div className={styles.recoverMain}>
      <div>{SmallVextLogo()}</div>
      <div className={styles.descriptionMobile}>Please add a password for your account!</div>
      <div className={styles.passwordsBlock}>
        <ThemeProvider theme={darkTheme}>
          <PasswordInput label={'Password'} value={password} setValue={setPassword} />
          <PasswordInput label={'Reenter password'} value={confirmPassword} setValue={setConfirmPassword} />
        </ThemeProvider>
      </div>

      <div className={styles.termsAndCondMobile}>
        <Checkbox
          onChange={handleChange}
          sx={{
            marginLeft: '-12px',
            color: '#f1f1f1',
            '&.Mui-checked': {
              color: '#fdfdfd',
            },
          }}
        />
        I agree to the
        <a
          href="https://www.freeprivacypolicy.com/live/29f04f1a-bf44-46eb-9c43-00093fa79702"
          target="_blank"
          rel="noreferrer"
          className={styles.footerLink}
        >
          <span style={{ color: '#FFC700', marginLeft: '5px' }}>privacy policy</span>
        </a>
      </div>
      <div
        onClick={() => submit(password, confirmPassword, termsAndCondAccepted, params)}
        className={styles.activateMobBtn}
      >
        Activate account
      </div>
    </div>
  );
}

export default ResetAccountMob;