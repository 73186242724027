export const IconClose = (color = '#727272') => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00003 16.9999L12 11.9999M12 11.9999L17 6.99997M12 11.9999L17 16.9999M12 11.9999L7.00003 6.99997"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
