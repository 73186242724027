export const createQuestionIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9994 2.90015H7.39941C4.91413 2.90015 2.89941 4.91486 2.89941 7.40014V20.6001C2.89941 23.0854 4.91413 25.1001 7.39941 25.1001H20.5994C23.0847 25.1001 25.0994 23.0854 25.0994 20.6001V14.0001"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18.4793 4.12132C19.6508 2.94975 21.5503 2.94975 22.7219 4.12132L23.7826 5.18198C24.9541 6.35355 24.9541 8.25305 23.7826 9.42462L14.6206 18.5866C14.3725 18.8347 14.0452 18.988 13.6958 19.0197L10.1956 19.3379C9.2627 19.4227 8.48115 18.6412 8.56596 17.7083L8.88416 14.2081C8.91593 13.8586 9.06921 13.5314 9.31734 13.2832L18.4793 4.12132Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M17 5.75V5.75C17.9579 8.14467 19.8553 10.0421 22.25 11V11"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
