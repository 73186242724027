import { dashboard } from 'images/dashboard';
import { LogOutIcon } from 'images/LogOut';
import { navbarChats } from 'images/navbarChats';
import { navbarEmergencies } from 'images/navbarEmergencies';
import { statisticsIcon } from 'images/statisticsIcon';
import React, { Dispatch, SetStateAction } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import styles from './navbarStyles.module.css';

import { blueLogo } from '../../../assets/LogoFrames/blueLogo';
import { useRefreshToken, useToken } from '../../../cookies';
import { clearNotificationsState } from '../../../store/notifications';
import { clearUserState } from '../../../store/user';
import { TabsVariants } from '../../../utils/enums';
import { useIsMobile, useIsTablet } from '../../../utils/styles.utils';


function Navbar({
  setSelectedTab,
  selectedTab,
}: {
  setSelectedTab: Dispatch<SetStateAction<TabsVariants>>;
  selectedTab: TabsVariants;
}) {
  const { removeRefreshToken } = useRefreshToken();
  const { removeToken } = useToken();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSmallDevice = isMobile || isTablet;

  const logout = () => {
    dispatch(clearUserState());
    dispatch(clearNotificationsState());
    removeToken();
    removeRefreshToken();
    ReactGA.event({
      category: 'User',
      action: 'Logout',
      label: 'Admin logout from app',
    });
    history.push('/admin/auth');
  };

  return (
    <div
      className={styles.navbarWrapper}
      style={{ flexDirection: isSmallDevice ? 'column' : 'row', height: isSmallDevice ? '28vh' : undefined }}
    >
      <div className={styles.logo}>{blueLogo()}</div>
      <div className={styles.navbarItem} onClick={() => setSelectedTab(TabsVariants.DASHBOARD)}>
        <div>{selectedTab === TabsVariants.DASHBOARD ? dashboard('black') : dashboard()}</div>
        <div
          className={styles.navbarLabel}
          style={{ color: selectedTab === TabsVariants.DASHBOARD ? 'black' : '#757575' }}
        >
          Dashboard
        </div>
      </div>

      <div className={styles.navbarItem} onClick={() => setSelectedTab(TabsVariants.STATISTICS)}>
        <div>{selectedTab === TabsVariants.STATISTICS ? statisticsIcon('black') : statisticsIcon()}</div>
        <div
          className={styles.navbarLabel}
          style={{ color: selectedTab === TabsVariants.STATISTICS ? 'black' : '#757575' }}
        >
          Statistics
        </div>
      </div>

      {/*  TODO disable when it will be required*/}
      {/*<div className={styles.navbarItem} onClick={() => setSelectedTab(TabsVariants.LOOPS)}>*/}
      {/*  <div>{selectedTab === TabsVariants.LOOPS ? navbarLoops('black') : navbarLoops()}</div>*/}
      {/*  <div className={styles.navbarLabel} style={{ color: selectedTab === TabsVariants.LOOPS ? 'black' : '#757575' }}>*/}
      {/*    Loops*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className={styles.navbarItem} onClick={() => setSelectedTab(TabsVariants.EMERGENCIES)}>
        <div>{selectedTab === TabsVariants.EMERGENCIES ? navbarEmergencies('black') : navbarEmergencies()}</div>
        <div
          className={styles.navbarLabel}
          style={{ color: selectedTab === TabsVariants.EMERGENCIES ? 'black' : '#757575' }}
        >
          Emergencies
        </div>
      </div>

      <div className={styles.navbarItem} onClick={() => setSelectedTab(TabsVariants.CHATS)}>
        <div>{selectedTab === TabsVariants.CHATS ? navbarChats('black') : navbarChats()}</div>
        <div className={styles.navbarLabel} style={{ color: selectedTab === TabsVariants.CHATS ? 'black' : '#757575' }}>
          Chats
        </div>
      </div>
      {/*<div className={styles.navbarItem} onClick={() => setSelectedTab(TabsVariants.MY_GROUPS)}>*/}
      {/*  <div>{selectedTab === TabsVariants.MY_GROUPS ? navbarMyGroups('black') : navbarMyGroups()}</div>*/}
      {/*  <div*/}
      {/*    className={styles.navbarLabel}*/}
      {/*    style={{ color: selectedTab === TabsVariants.MY_GROUPS ? 'black' : '#757575' }}*/}
      {/*  >*/}
      {/*    My groups*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={styles.logoutAdmin} onClick={logout}>
        <span>Logout</span>
        <div>{LogOutIcon('black')}</div>
      </div>
    </div>
  );
}

export default Navbar;
