import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Wrapper } from '../../components/layout/Wrapper';
import { darkTheme } from '../../themes/themes';
import ArrowBackButton from '../../components/mobile/Buttons/ArrowBackButton';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Tooltip } from '@mui/material';
import { infoIcon } from 'images/infoIcon';
import Select, { MultiValue, StylesConfig } from 'react-select';
import { getAllGroupsNames, selectAllGroupsName, selectFullGroup } from '../../store/groups';
import { useDispatch, useSelector } from 'react-redux';
import { useToken } from '../../cookies';
import { PaperType } from '../../types/Paper';
import { selectUserId } from '../../store/auth';
import { mood } from '../../helpers/moodList';
import { ArticlesApiService } from '../../services/articles.service';
import MessageHockPopUp from '../../hooks/MessageHockPopUp';
import { getArticles } from '../../store/articles';
import { generateRandomNicknameId } from '../../helpers/anonymousGenerator';
import { selectLoggedUserData } from '../../store/user/selectors';
import { getUser } from '../../store/user';
import { IOSSwitch } from '../../utils/componenets';
import MobileLoaderScreen from '../../components/layout/MobileLoaderScreen';
import ReactGA from 'react-ga4';
import styles from './CreateQuestionPageStyle.module.css';

export default function CreateQuestionPage({
  setCreatingQuestion,
}: {
  setCreatingQuestion: Dispatch<SetStateAction<boolean>>;
}) {
  const [isMessageAnonymous, setIsMessageAnonymous] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [questionTitle, setQuestionTitle] = useState('#need_');
  const [questionText, setQuestionText] = useState('');
  const [selectedMood, setSelectedMood] = useState('');
  const [articleGroups, setArticleGroups] = useState<Array<{ id: string }>>();
  const groups = useSelector(selectAllGroupsName);
  const [isLoading, setIsLoading] = useState(false);
  const loggedUserId = useSelector(selectUserId);
  const loggedUserData = useSelector(selectLoggedUserData);
  const currentGroup = useSelector(selectFullGroup);
  const dispatch = useDispatch();
  const { token } = useToken();
  const groupList = groups.map((group) => {
    return { value: Object.keys(group)[0], label: Object.values(group)[0] };
  });

  const selectedGroups = (e: MultiValue<{ value: string; label: string }>) => {
    const selectedGroups = e.map((e) => {
      return { id: e.value };
    });
    setArticleGroups(selectedGroups);
  };

  useEffect(() => {
    if (token?.length && !loggedUserData.id && loggedUserId) {
      dispatch(getUser({ token, userId: loggedUserId }));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getAllGroupsNames(token));
    // eslint-disable-next-line
  }, []);

  const createQuestion = () => {
    // TODO, rename parameter properly when it will be added on the back
    const anonymousName = loggedUserData?.profile?.anonymous_name || generateRandomNicknameId();

    if (questionText.length > 2) {
      const data = {
        title: questionTitle,
        description: questionText,
        paper_tags: [],
        likes: [],
        type: PaperType.QUESTION,
        isCommentsOn: true,
        paper_author: loggedUserId,
        ...(isMessageAnonymous && { anonymous_name: anonymousName }),
        mood: selectedMood,
        group: articleGroups?.length ? articleGroups?.map((e) => e.id) : [currentGroup.id],
      };
      setIsLoading(true);

      ArticlesApiService.postQuestion(data, token)
        .then((res) => {
          if (res.ok) {
            ReactGA.event({
              category: 'User',
              action: 'question_created',
              label: 'User created a question',
            });
            dispatch(getArticles({ token: token, groupId: currentGroup.id }));
            setCreatingQuestion(false);
            MessageHockPopUp({ message: 'Success', timeOut: 4000, type: 'success', size: 'small' });
          } else {
            MessageHockPopUp({
              message: 'Something went wrong',
              timeOut: 4000,
              type: 'error',
              size: 'small',
            });
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  const customStyles: StylesConfig = {
    control: (styles: any) => {
      return {
        ...styles,
        background: '#2A2A2A',
        color: '#fff',
        width: '90vw',
        marginTop: '14px',
        borderRadius: '3px',
        border: '1px solid gray',
        outline: 'none !important',
        boxShadow: 'none !important',
      };
    },
    menu: (styles: any) => ({
      ...styles,
      background: '#2A2A2A',
      borderRadius: 0,
      marginTop: '5px',
    }),
    menuList: (styles: any) => {
      return {
        ...styles,
        background: '#2A2A2A',
        padding: 0,
        color: '#fff',
      };
    },
    option: (styles: any) => ({
      ...styles,
      backgroundColor: '#2a2a2a',
      color: 'white',
      padding: 20,

      ':active': {
        ...styles[':active'],
        backgroundColor: 'none',
      },
    }),
    input: (styles) => ({ ...styles, color: '#fff' }),
    multiValue: (styles: any) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      background: 'white !important',
      border: '1px solid black',
      borderRadius: '34px !important',
      overflow: 'hidden',
      height: '30px',
      padding: '0px 8px',
      fontWeight: '700',
      color: '#C2C2C2',
    }),
    multiValueLabel: (styles) => {
      return {
        ...styles,
        color: '#000000 !important',
      };
    },
    multiValueRemove: (styles) => {
      return {
        ...styles,
        backgroundColor: 'transparent !important',
        cursor: 'pointer',
      };
    },
    singleValue: (styles: any) => {
      return {
        ...styles,
        border: '1px solid red',
      };
    },
    indicatorSeparator: () => {
      return {
        display: 'none',
      };
    },
  };

  return (
    <Wrapper>
      <div className={styles.createQuestionMain}>
        <ThemeProvider theme={darkTheme}>
          <div>
            <ArrowBackButton setStep={() => setCreatingQuestion(false)} />
          </div>
          <div className={styles.createQuestionForm}>
            {isLoading && <MobileLoaderScreen />}
            <h1 className={styles.headTextTitle}>Ask your peers</h1>
            <span className={styles.headDescriptionTitle}>This question will be visible in the group feed.</span>
            <TextField
              style={{ backgroundColor: '#2A2A2A' }}
              fullWidth
              label="#need_"
              value={questionTitle}
              id="outlined-size-small"
              size="small"
              onChange={(e) => setQuestionTitle(e.target.value)}
              InputLabelProps={{
                style: {
                  color: '#8B8C8D',
                  fontWeight: 300,
                },
              }}
            />
            <span className={styles.tagDescription}>
              Complete a field like in this example: #need_help, #need_support
            </span>
            <TextField
              // TODO .. add this param on backend
              style={{ marginTop: '18px', backgroundColor: '#2A2A2A', display: 'flex' }}
              id="filled-start-adornment"
              required
              select
              fullWidth
              label="Feeling"
              size="small"
              InputLabelProps={{
                style: {
                  color: '#8B8C8D',
                  fontWeight: 300,
                },
              }}
              onChange={(e) => {
                setSelectedMood(e.target.value);
              }}
              value={selectedMood}
            >
              {mood.map((option, index) => (
                <MenuItem key={index} value={option.label}>
                  <span className={styles.dropDownImg}>{option.img}</span>
                  <span className={styles.dropDownLabel}>{option.label}</span>
                </MenuItem>
              ))}
            </TextField>
            <span className={styles.feelingDescription}>What emotion does this question evoke in you?</span>

            <Select
              isMulti
              name="groups"
              options={groupList}
              placeholder={'Select groups...'}
              className={styles.select}
              classNamePrefix="select"
              defaultValue={{ value: currentGroup.id, label: currentGroup.name }}
              styles={customStyles}
              onChange={(e: any) => selectedGroups(e)}
            />
            <textarea
              name="question-input"
              className={styles.questionInput}
              cols={1}
              rows={5}
              maxLength={280}
              required
              placeholder="Type your question here"
              onChange={(e) => setQuestionText(e.target.value)}
            />
            <div className={styles.bottomQuestionBlock}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        onChange={(e: { target: { checked: boolean } }) => {
                          setIsMessageAnonymous(e.target.checked);
                        }}
                        checked={isMessageAnonymous}
                      />
                    }
                    label="Incognito"
                  />
                </FormGroup>
                <Tooltip
                  arrow
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => setTooltipOpen(false)}
                  open={isTooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Leave a comment as incognito"
                >
                  <div onClick={() => setTooltipOpen(!isTooltipOpen)}>{infoIcon()} </div>
                </Tooltip>
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <div className={styles.submitFormBtn} onClick={createQuestion}>
                Ask
              </div>
            </div>
          </div>
        </ThemeProvider>
      </div>
    </Wrapper>
  );
}
