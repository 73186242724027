import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import NavigationNavbar from '../../components/Header/NavigationHeader';
import { Wrapper } from '../../components/layout/Wrapper';
import { selectSearchedArticles } from '../../store/articles/selector';
import { css } from 'styled-components/macro';
import UserInfoMini from '../../components/User/UserInfoMini';
import { clearSelectedPaper } from '../../store/articles';
import { NO_ARTICLE_COVER } from '../../utils/constantImagesUrl';

export const SearchPage = () => {
  const searchResult = useSelector(selectSearchedArticles);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  return (
    <div>
      <NavigationNavbar />
      <Wrapper>
        <div
          css={css`
            font-weight: 700;
            font-size: 48px;
            margin: 20px 0 60px 0;
            padding-left: 10px;
          `}
        >
          {searchResult.length} Results for{' '}
          <span
            css={css`
              color: gray;
            `}
          >
            {location.state}{' '}
          </span>
        </div>
        {!!searchResult.length &&
          searchResult.map((element) => {
            return (
              <div
                onClick={() => {
                  dispatch(clearSelectedPaper());
                  history.push(`/article${element.id}`);
                }}
                css={css`
                  display: flex;
                  margin-bottom: 40px;
                  padding-bottom: 40px;
                  border-bottom: 1px solid #00000040;
                  height: 170px;
                  cursor: pointer;
                  transition: 400ms;

                  &:hover {
                    border-bottom: 1px solid #00000021;
                    border-radius: 20px;
                    transition: 400ms;
                  }
                `}
                key={element.id}
              >
                <img
                  css={css`
                    width: 284px;
                    height: 176px;
                    border-radius: 20px;
                  `}
                  src={element.image || NO_ARTICLE_COVER}
                  alt=""
                />

                <div
                  css={css`
                    width: 78%;
                    overflow: hidden;
                    padding: 4px 0px 0px 30px;
                  `}
                >
                  <UserInfoMini {...element} />
                  <div
                    css={css`
                      margin-top: 14px;
                      font-style: normal;
                      font-weight: 700;
                      font-size: 30px;
                      line-height: 120%;
                      color: #252525;
                    `}
                  >
                    {element.title}
                  </div>
                  <div
                    css={css`
                      margin-top: 14px;
                      font-style: normal;
                      font-weight: 300;
                      font-size: 14px;
                      line-height: 170%;
                      color: #757575;
                    `}
                  >
                    {element.description}
                  </div>
                </div>
              </div>
            );
          })}
      </Wrapper>
    </div>
  );
};
