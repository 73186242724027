export const navbarEmergencies = (fill = '#757575') => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.99993 10.9889C8.70341 10.9889 8.45752 10.743 8.45752 10.4464V6.83037C8.45752 6.53386 8.70341 6.28796 8.99993 6.28796C9.29645 6.28796 9.54234 6.53386 9.54234 6.83037V10.4464C9.54234 10.743 9.29645 10.9889 8.99993 10.9889Z"
      fill={fill}
    />
    <path
      d="M9.00007 13.3392C8.95668 13.3392 8.90605 13.332 8.85543 13.3248C8.81203 13.3175 8.76864 13.3031 8.72525 13.2814C8.68186 13.2669 8.63846 13.2452 8.59507 13.2163C8.55891 13.1874 8.52275 13.1584 8.48659 13.1295C8.35641 12.9921 8.27686 12.804 8.27686 12.616C8.27686 12.428 8.35641 12.2399 8.48659 12.1025C8.52275 12.0736 8.55891 12.0447 8.59507 12.0157C8.63846 11.9868 8.68186 11.9651 8.72525 11.9507C8.76864 11.929 8.81203 11.9145 8.85543 11.9073C8.94944 11.8856 9.05069 11.8856 9.13748 11.9073C9.1881 11.9145 9.2315 11.929 9.27489 11.9507C9.31828 11.9651 9.36168 11.9868 9.40507 12.0157C9.44123 12.0447 9.47739 12.0736 9.51355 12.1025C9.64373 12.2399 9.72328 12.428 9.72328 12.616C9.72328 12.804 9.64373 12.9921 9.51355 13.1295C9.47739 13.1584 9.44123 13.1874 9.40507 13.2163C9.36168 13.2452 9.31828 13.2669 9.27489 13.2814C9.2315 13.3031 9.1881 13.3175 9.13748 13.3248C9.09409 13.332 9.04346 13.3392 9.00007 13.3392Z"
      fill={fill}
    />
    <path
      d="M13.3438 16.7143H4.65313C3.25489 16.7143 2.18649 16.1752 1.64153 15.2034C1.10375 14.2315 1.17545 12.9787 1.85665 11.6803L6.20196 3.40416C6.91901 2.03745 7.90853 1.28577 8.99845 1.28577C10.0884 1.28577 11.0779 2.03745 11.7949 3.40416L16.1402 11.6879C16.8214 12.9863 16.9003 14.2315 16.3554 15.211C15.8104 16.1752 14.742 16.7143 13.3438 16.7143ZM8.99845 2.42469C8.32442 2.42469 7.66474 2.97137 7.14129 3.95843L2.80315 12.2422C2.31556 13.1685 2.23668 14.0189 2.5737 14.6339C2.91071 15.2489 3.65644 15.583 4.66031 15.583H13.3509C14.3548 15.583 15.0934 15.2489 15.4375 14.6339C15.7817 14.0189 15.6957 13.1761 15.2081 12.2422L10.8556 3.95843C10.3322 2.97137 9.67247 2.42469 8.99845 2.42469Z"
      fill={fill}
    />
  </svg>
);
