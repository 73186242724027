import React, { useRef, useState } from 'react';
import CostumePopup from '../layout/CostumePopUp';
import { dots3Icon } from 'images/dots3Icon';
import TrashcanIcon from 'images/trashbinIcon';
import { emergencyIcon } from 'images/emergencyIcon';
import { ChatListPreview } from '../../types/User';
import { useToken } from '../../cookies';
import { deleteConversation } from '../../store/chats';
import { useDispatch } from 'react-redux';
import styles from './MobileChatsStyles.module.css';

function RowPopUp({ chat }: { chat: ChatListPreview }) {
  const [showPopup, setShowPopup] = useState(false);
  const ref = useRef(null) as any;
  const { token } = useToken();
  const dispatch = useDispatch();

  const clickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, []);

  const report = () => {
    setShowPopup(false);
  };

  const deleteChat = (e: any) => {
    e.stopPropagation();
    dispatch(deleteConversation({ token, chatId: chat.id, isPeopleChat: true }));
    setShowPopup(false);
  };

  return (
    <div>
      <div
        className={styles.dots3}
        onClick={(event) => {
          event.stopPropagation();
          setShowPopup(true);
        }}
      >
        {dots3Icon('#cbcbcb', '28', '24')}
      </div>
      {showPopup && (
        <CostumePopup style={{ width: '100px', marginRight: '4px', padding: '8px' }}>
          <div ref={ref}>
            <div onClick={report} className={styles.popUpRow}>
              <div>{emergencyIcon('21')}</div>
              <span className={styles.rowText}>Report</span>
            </div>
            <div onClick={(e) => deleteChat(e)} className={styles.popUpRow}>
              <div>
                <TrashcanIcon color="#fff" />
              </div>
              <span className={styles.rowText}>Delete chat</span>
            </div>
          </div>
        </CostumePopup>
      )}
    </div>
  );
}

export default RowPopUp;
