export const ErrorIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6004 53.8113C29.0997 53.5596 29.6528 53.4324 30.2116 53.4132C43.9976 52.9403 55.0284 41.6155 55.0284 27.7143C55.0284 13.5127 43.5157 2 29.3141 2C15.1125 2 3.59985 13.5127 3.59985 27.7143C3.59985 35.5529 7.1072 42.5723 12.6381 47.2888C13.5052 48.0281 14.057 49.0876 14.057 50.2271V55.3129C14.057 57.9866 16.8751 59.7219 19.2626 58.5184L28.6004 53.8113Z"
      fill="#CC4850"
    />
    <path
      d="M23.3431 33.9706L29 28.3137M29 28.3137L34.6569 22.6569M29 28.3137L34.6569 33.9706M29 28.3137L23.3431 22.6569"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
