import { Link } from 'react-scroll';
import { SettingItemContainer } from './SettingItemContainer';
import { AboutYou, Education, PlaceOfResidence, Security, ShortBio } from './Items';
import { SettingsProfileImage } from './Items/SettingsProfileImage';
import './style.css';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectLoggedUserData } from '../../store/user/selectors';
import style from './SettingsStyle.module.css';

export default function SettingsNavbar() {
  const history = useHistory();
  const userData = useSelector(selectLoggedUserData);
  return (
    <div style={{ display: 'flex' }}>
      <ul className={style.leftNavbar}>
        <div className={style.backBtn} onClick={() => history.push('/feed')}>
          {'< Go back'}
        </div>

        <Link activeClass="active" style={{ cursor: 'pointer' }} offset={-240} to="about" spy={true} smooth={true}>
          About you
        </Link>
        <Link activeClass="active" style={{ cursor: 'pointer' }} offset={-140} to="bio" spy={true} smooth={true}>
          Short bio
        </Link>
        <Link activeClass="active" style={{ cursor: 'pointer' }} offset={-130} to="profile" spy={true} smooth={true}>
          Profile Image
        </Link>
        <Link activeClass="active" style={{ cursor: 'pointer' }} offset={-130} to="place" spy={true} smooth={true}>
          Place of residence
        </Link>
        <Link activeClass="active" style={{ cursor: 'pointer' }} offset={-130} to="education" spy={true} smooth={true}>
          Education
        </Link>
        <Link activeClass="active" style={{ cursor: 'pointer' }} offset={-500} to="security" spy={true} smooth={true}>
          Security
        </Link>
      </ul>

      <div style={{ width: '100%' }}>
        <h1 className={style.title}>Profile settings</h1>
        <SettingItemContainer title="About you" id="about">
          <AboutYou userData={userData} />
        </SettingItemContainer>
        <SettingItemContainer title="Short bio" id="bio">
          <ShortBio />
        </SettingItemContainer>
        <SettingItemContainer title="Profile Image" id="profile">
          <SettingsProfileImage userData={userData} />
        </SettingItemContainer>
        <SettingItemContainer title="Place of residence" id="place">
          <PlaceOfResidence />
        </SettingItemContainer>
        <SettingItemContainer title="Education" id="education">
          <Education />
        </SettingItemContainer>
        <SettingItemContainer title="Security" id="security">
          <Security />
        </SettingItemContainer>
      </div>
    </div>
  );
}
