import { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import MessageHockPopUp from 'src/hooks/MessageHockPopUp';
import { useIsMobile } from 'src/utils/styles.utils';
import { NO_IMAGE, NO_USER_AVATAR } from '../../../utils/constantImagesUrl';

export default function ImageInput({ setImage, isPaperImage }: { setImage: any; isPaperImage?: boolean }) {
  const [preview, setPreview] = useState();
  const [imageUpload, setImageUpload] = useState<any | null>(null);
  const [imageURL, setImageURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile();

  const notify = (error: string) => {
    error.length && MessageHockPopUp({ message: error, timeOut: 4000, type: 'error' });
  };

  useEffect(() => {
    if (isPaperImage && imageUpload) {
      setImage(imageUpload);
    }
    // eslint-disable-next-line
  }, [imageUpload]);

  useEffect(() => {
    if (imageUpload?.size > 230000) {
      notify('The size of image should be less than 200 kb');
      setImageUpload(null);
    }
    if (!imageUpload) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(imageUpload);
    setPreview(objectUrl as any);

    return () => URL.revokeObjectURL(objectUrl);
    // eslint-disable-next-line
  }, [imageUpload]);

  const uploadImage = () => {
    setImageURL(preview as any);
    setImage(imageUpload);
  };

  return (
    <div className={isMobile ? 'mobileInputMain' : 'imageInputMain'}>
      {isLoading && (
        <div className="loader">
          <Oval height={30} width={30} strokeWidth={5} strokeWidthSecondary={1} color="black" secondaryColor="gray" />
        </div>
      )}

      <input
        className="imageInputManagement"
        type="file"
        onChange={(e) => {
          setImageUpload(e.target.files ? e.target.files[0] : null);
        }}
      />
      <img className="imageInputImage" src={imageURL || preview || (isPaperImage ? NO_IMAGE : NO_USER_AVATAR)} alt="" />
      {imageUpload && !isPaperImage && (
        <button className="uploadImage" onClick={() => uploadImage()}>
          Upload image to the server
        </button>
      )}
    </div>
  );
}
