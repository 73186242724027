import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { checkImage } from 'src/helpers/checkImageUrl';
import { NO_GROUP_COVER } from '../../../utils/constantImagesUrl';
import styles from '../GroupCardStyles.module.css';

export default function GroupCard({ group }: any) {
  const [isImgAvailable, setIsImgAvailable] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const checkImg = async () => {
      if (group.group_photo) {
        const isAvailable = (await checkImage(group.group_photo)) as boolean;
        setIsImgAvailable(isAvailable);
      } else {
        setIsImgAvailable(true);
      }
    };

    checkImg();
    // eslint-disable-next-line
  }, []);

  const redirectToGroupUrl = () => {
    history.push({ pathname: `group/${group.id}`, state: group.id });
  };

  return (
    <div className={styles.groupCardBlock} onClick={redirectToGroupUrl}>
      <span>{group.name}</span>
      <img
        className={styles.groupCardImage}
        src={group.group_photo && isImgAvailable ? group.group_photo : NO_GROUP_COVER}
        alt="group img"
      />
    </div>
  );
}
