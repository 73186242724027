import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorGuard } from '../../utils/errorGuard';
import { ERROR } from '../../errorsTemplates';
import { isPendingAction, isRejectedAction } from '../../utils/redux.utils';
import { TagApiService } from '../../services/tags.service';
import { Tag } from '../../types/Tag';

export const TAGS_REDUCER_NAME = 'tags';

type InitialStateType = {
  tags: Array<Tag>;
  error: null | string;
};

const initialState: InitialStateType = {
  tags: [],
  error: null,
};

export const getTags = createAsyncThunk(`${TAGS_REDUCER_NAME}/getTags`, async ({ token }: { token: string }) => {
  try {
    const response = await TagApiService.getTags(token);
    return response.data;
  } catch (e) {
    errorGuard(e);
    throw new Error(ERROR.common.something_went_wrong);
  }
});

const tagsSlice = createSlice({
  name: TAGS_REDUCER_NAME,
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getTags.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.tags = payload;
        state.error = null;
      })
      .addMatcher(isPendingAction(`${TAGS_REDUCER_NAME}/`), (state) => {
        state.error = null;
      })
      .addMatcher(isRejectedAction(`${TAGS_REDUCER_NAME}/`), (state, { error }) => {
        state.error = 'Get tags error';
      });
  },
});

export const {} = tagsSlice.actions;
export default tagsSlice.reducer;
