import { iconCheckCircle } from 'images/checked/iconCheckCircle';
import { dots3Icon } from 'images/dots3Icon';
import { exclamationCircle } from 'images/exclamationCircle';
import { MyProfile } from 'images/users/MyProfile';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';

import CostumePopup from '../../../../components/layout/CostumePopUp';
import { getAnonymousImage } from '../../../../helpers/anonymousGenerator';
import { getFirstUserFromUsersList } from '../../../../helpers/arrays';
import { calcCreatedTime } from '../../../../helpers/date';
import { sliceText } from '../../../../helpers/textHelper';
import { userNameFromAllParams } from '../../../../helpers/userName';
import { selectUserId } from '../../../../store/auth';
import { ChatListPreview } from '../../../../types/User';
import { NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';
import { REPORT_CHAT } from '../../../../utils/constants';

function ChatRowItem({ chat }: { chat: ChatListPreview }) {
  const [showPopup, setShowPopup] = useState(false);
  const [chatFromReportReq, setChatFromReportReq] = useState('');
  const ref = useRef(null) as any;
  const myUserId = useSelector(selectUserId);
  const user = getFirstUserFromUsersList(chat.users, myUserId, chat.has_to_accept);
  useEffect(() => {
    if (chat.name === REPORT_CHAT) {
      setChatFromReportReq(
        `Report on ${sliceText(
          userNameFromAllParams(user?.username, user?.profile?.first_name, user?.profile?.last_name),
          21,
        )}`,
      );
    }
  }, []);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const markAsRead = () => {};
  const suspend = () => {
    setShowPopup(false);
  };
  const changeResponsibility = () => {
    setShowPopup(false);
  };
  const isIncognito = chat.emergency?.is_emergency_incognito;

  return (
    <div className={styles.userBlock}>
      {isIncognito && chat.name ? (
        getAnonymousImage(chat.name, 'medium')
      ) : (
        <img className={styles.userImg} src={user?.profile?.image_url || NO_USER_AVATAR} alt="" />
      )}

      <div className={styles.middleBlock}>
        <div className={styles.nameAndTime}>
          <div className={styles.userName}>
            {chatFromReportReq ? (
              <div>{chatFromReportReq}</div>
            ) : isIncognito && chat.name ? (
              chat.name
            ) : (
              sliceText(userNameFromAllParams(user?.username, user?.profile?.first_name, user?.profile?.last_name), 21)
            )}
          </div>
          <div className={styles.creationTime}>
            {chat.has_unseen_message && <span className={styles.unreadMsgDot}></span>}
            {calcCreatedTime(chat.last_message?.creation_date || chat.creation_date)}
          </div>
        </div>
        <div className={styles.previewMessage}>{sliceText(chat.last_message?.message || 'No messages yet', 110)}</div>
      </div>
      <div className={styles.dots3} onClick={() => setShowPopup(true)}>
        {dots3Icon('#757575', '28', '24')}
      </div>
      {showPopup && (
        <CostumePopup>
          <div ref={ref}>
            <div onClick={markAsRead} className={styles.popUpRow}>
              <div>{iconCheckCircle()}</div>
              <span className={styles.rowText}>Mark as read</span>
            </div>
            <div onClick={suspend} className={styles.popUpRow}>
              <div>{exclamationCircle()}</div>
              <span className={styles.rowText}>Suspend for 30 days</span>
            </div>
            <div onClick={changeResponsibility} className={styles.popUpRow}>
              <div>{MyProfile('#dedcdc')}</div>
              <span className={styles.rowText}>Change responsible person</span>
            </div>
          </div>
        </CostumePopup>
      )}
    </div>
  );
}

export default ChatRowItem;
