import { Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';
import { css } from 'styled-components/macro';
import AskModalHeader from './components/AskModalHeader';
import AskModalInfo from './components/AskModalInfo';
import AskModalInput from './components/AskModalInput';

export default function AskModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const openModal = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={openModal}
      contentLabel="My dialog"
      className="mymodal"
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 560px;
        height: 500px;
        border-radius: 20px;
        overflow: hidden;
      `}
    >
      <AskModalHeader />
      <AskModalInput setIsOpen={setIsOpen} />
      <AskModalInfo />
    </Modal>
  );
}
