import React, { Dispatch, SetStateAction } from 'react';
import ArrowBackButton from '../../../components/mobile/Buttons/ArrowBackButton';
import styles from './styles.module.css';

function EmergencyStep3({
  setStepComponent,
  emergencyReason,
  emergencyInfo,
  emergencyStateAndTown,
  sendEmergencyReq,
}: {
  setStepComponent: Dispatch<SetStateAction<number>>;
  emergencyReason: any;
  emergencyInfo: string;
  emergencyStateAndTown: { state: string; town: string; address: string };
  sendEmergencyReq: () => void;
}) {
  return (
    <div>
      <div className={styles.navbar}>
        <ArrowBackButton setStep={() => setStepComponent(2)} />
      </div>
      <div className={styles.moreInfoBlock}>
        <h1>Confirm your data </h1>
        <p className={styles.infoHead}>You have selected</p>
        <p className={styles.infoBody}>{emergencyReason}</p>

        <p className={styles.infoHead}>Your comment</p>
        <p className={styles.infoBody}>{emergencyInfo}</p>

        <p className={styles.infoHead}>Your location</p>
        <p className={styles.infoBody}>
          {emergencyStateAndTown.state} {emergencyStateAndTown.town} {emergencyStateAndTown.address}
        </p>
      </div>
      <div className={styles.buttonsBlock}>
        <div
          onClick={() => {
            sendEmergencyReq();
            setStepComponent(4);
          }}
          className={styles.sendButton}
          style={{ marginTop: '28%' }}
        >
          Accept
        </div>
      </div>
    </div>
  );
}

export default EmergencyStep3;
