import { Dispatch, SetStateAction } from 'react';
import './Switch.css';

type ICommentProps = {
  selectedParagraph: boolean;
  seIsParagraphComment: Dispatch<SetStateAction<boolean>>;
  setSelectedParagraph: Dispatch<SetStateAction<any>>;
};

export default function Switch({ selectedParagraph, seIsParagraphComment, setSelectedParagraph }: ICommentProps) {
  const handleSwitch = () => {
    if (selectedParagraph) {
      setSelectedParagraph(undefined);
    }

    seIsParagraphComment(!selectedParagraph);
  };
  return (
    <div className="pSwitch">
      <input
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
        checked={selectedParagraph}
        onChange={() => handleSwitch()}
      />
      <label className="react-switch-label" htmlFor={`react-switch-new`}>
        <span className={`react-switch-button`}>{!selectedParagraph ? 'General' : 'Paragraph'} </span>
      </label>
    </div>
  );
}
