import Step3 from '../../components/mobile/CreateGroup/components/Step3';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './styles.module.css';

export default function InviteFriendMobile() {
  const location = useLocation();
  const groupId = location.state as string;
  const history = useHistory();
  const goBack = () => {
    history.goBack()
    // history.push(groupId ? `./group/${groupId}` : `/feed`);
  };

  return (
    <div className={styles.inviteFriendsMain}>
      <Step3 newGroupId={groupId} closeWindow={goBack} />
    </div>
  );
}
