import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupsApiService } from 'src/services/groups.service';
import { IGroup } from 'src/types/Group';

import { ERROR } from '../../errorsTemplates';
import { days } from '../../helpers/date';
import MessageHockPopUp from '../../hooks/MessageHockPopUp';
import { User } from '../../types/User';
import { errorGuard } from '../../utils/errorGuard';
import { isPendingAction, isRejectedAction } from '../../utils/redux.utils';

export const GROUPS_REDUCER_NAME = 'groups';

type InitialStateType = {
  selectedFullGroup: any;
  error: string | null;
  weekMoods: Array<{ name: string; pv: unknown }>;
  isLoading: boolean;
  groups: { [key: string]: IGroup };
  recentGroup: string;
  pinnedGroups: { [key: string]: IGroup };
  allGroupsName: Array<{ [key: string]: string }>;
  page: number;
  next: string | null;
  pinnedGroupsPage: number;
  pinnedNextPage: string | null;
};

const initialState: InitialStateType = {
  weekMoods: [],
  pinnedGroups: {},
  selectedFullGroup: null,
  recentGroup: '',
  error: null,
  isLoading: false,
  allGroupsName: [],
  groups: {},
  page: 1,
  pinnedGroupsPage: 1,
  next: null,
  pinnedNextPage: null,
};

export const getGroupsWeekMoods = createAsyncThunk(
  `${GROUPS_REDUCER_NAME}/getGroupsWeekMoods`,
  async ({ token, groupId, from, to }: { token: string; groupId: string; from?: string; to?: string }) => {
    try {
      const response = await GroupsApiService.getGroupMoodsByDate(token, groupId, from, to);
      if (!response.data) return;
      if (!response.data.moods) throw new Error('No Moods');
      return response.data.moods;
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

export const getGroups = createAsyncThunk(
  `${GROUPS_REDUCER_NAME}/getGroups`,
  async ({ token, page }: { token: string; page?: number }) => {
    try {
      const response = await GroupsApiService.getGroups(token, page);
      if (!response.data) return;
      if (!response.data.results) throw new Error('Invalid page');
      return { results: response.data.results, next: response.data.next, page };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

export const getFullGroup = createAsyncThunk(
  `${GROUPS_REDUCER_NAME}/getFullGroup`,
  async ({ token, groupId }: { token: string; groupId: string }) => {
    try {
      const response = await GroupsApiService.getFullGroupById(token, groupId);
      if (!response.data) return;
      return response.data;
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);
export const getAllGroupsNames = createAsyncThunk(`${GROUPS_REDUCER_NAME}/getGroupsNames`, async (token: string) => {
  try {
    const response = await GroupsApiService.getAllGroupsName(token);
    if (!response.data) return;
    return response.data;
  } catch (e) {
    errorGuard(e);
    throw new Error(ERROR.common.something_went_wrong);
  }
});

export const getPinnedGroups = createAsyncThunk(
  `${GROUPS_REDUCER_NAME}/getPinnedGroups`,
  async ({ token, page }: { token: string; page?: number }) => {
    try {
      const response = await GroupsApiService.getPinnedGroups(token, page);
      if (!response.data) return;
      if (!response.data.results) throw new Error('Invalid page');
      return { result: response.data.results, next: response.data.next, page };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

export const pinAGroup = createAsyncThunk(
  `${GROUPS_REDUCER_NAME}/pinAGroup`,
  async ({ token, groupId }: { token: string; groupId: string }) => {
    try {
      const response = await GroupsApiService.setPinnedGroups(token, groupId);
      if (!response.ok) return;
      return { groupId };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

export const unpinAGroup = createAsyncThunk(
  `${GROUPS_REDUCER_NAME}/unpinAGroup`,
  async ({ token, groupId }: { token: string; groupId: string }) => {
    try {
      const response = await GroupsApiService.removePinnedGroups(token, groupId);
      if (!response.ok) return;
      // if (!response.data.results) throw new Error('Invalid page');
      return { groupId };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);
export const joinGroup = createAsyncThunk(
  `${GROUPS_REDUCER_NAME}/joinGroup`,
  async ({
    token,
    groupId,
    userId,
    userLogged,
  }: {
    token: string;
    groupId: string;
    userId: string;
    userLogged: User;
  }) => {
    const response = await GroupsApiService.joinGroup(token, groupId, userId);
    if (response.ok) {
      MessageHockPopUp({
        message: 'Join group success!',
        timeOut: 5000,
        type: 'success',
        size: 'small',
      });
      return { response, groupId, userLogged };
    } else {
      console.log('Response-', response);
      MessageHockPopUp({
        message: 'Failed join group!' + response.data,
        timeOut: 5000,
        type: 'error',
        size: 'small',
      });
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

export const leaveGroup = createAsyncThunk(
  `${GROUPS_REDUCER_NAME}/leaveGroup`,
  async ({ token, groupId, userId }: { token: string; groupId: string; userId: string }) => {
    try {
      const response = await GroupsApiService.leaveGroup(token, groupId, userId);
      return { response, groupId, userId };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

export const deleteGroup = createAsyncThunk(
  `${GROUPS_REDUCER_NAME}/deleteGroup`,
  async ({ token, groupId }: { token: string; groupId: string }) => {
    try {
      const response = await GroupsApiService.deleteGroup(token, groupId);
      return { response, groupId };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

const authSlice = createSlice({
  name: GROUPS_REDUCER_NAME,
  initialState,
  reducers: {
    resetFullGroup(state) {
      state.selectedFullGroup = null;
    },
    setGroupsPage(state, { payload: page }: PayloadAction<number>) {
      state.page = page;
    },
    setRecentGroup(state, { payload: url }: PayloadAction<string>) {
      state.recentGroup = url;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(joinGroup.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const { userLogged } = payload;
        state.selectedFullGroup.users = [...state.selectedFullGroup.users, userLogged];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteGroup.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if (state.groups[payload.groupId]) {
          delete state.groups[payload.groupId];
        } else {
          delete state.pinnedGroups[payload.groupId];
        }

        state.isLoading = false;
        state.error = null;
      })
      .addCase(leaveGroup.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.selectedFullGroup.users = state.selectedFullGroup.users.filter((user: any) => user.id !== payload.userId);
        if (state.pinnedGroups[payload?.groupId]) {
          state.pinnedGroups[payload?.groupId].users = state.pinnedGroups[payload?.groupId].users.filter(
            (user) => user.id !== payload.userId,
          );
        }
        if (state.groups[payload?.groupId]) {
          state.groups[payload?.groupId].users = state.groups[payload?.groupId].users.filter(
            (user) => user.id !== payload.userId,
          );
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getGroups.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const newGroups = payload.results.reduce(
          (acc: { [key: string]: IGroup }, group: IGroup) => Object.assign(acc, { [group.id]: group }),
          {},
        );
        state.next = payload.next;
        if (payload.page) {
          state.groups = { ...state.groups, ...newGroups };
          state.page = payload.page;
        } else {
          state.groups = newGroups;
          state.page = 1;
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getAllGroupsNames.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.allGroupsName = payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getPinnedGroups.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const newPinnedGroups = payload.result.reduce(
          (acc: { [key: string]: IGroup }, group: IGroup) => Object.assign(acc, { [group.id]: group }),
          {},
        );
        if (payload.page) {
          state.pinnedGroups = { ...state.pinnedGroups, ...newPinnedGroups };
          state.pinnedGroupsPage = payload.page;
        } else {
          state.pinnedGroups = newPinnedGroups;
          state.pinnedGroupsPage = 1;
        }
        state.pinnedNextPage = payload?.next;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(pinAGroup.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.pinnedGroups[payload?.groupId] = state.groups[payload?.groupId];
        delete state.groups[payload?.groupId];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(unpinAGroup.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const group = state.pinnedGroups[payload?.groupId];
        if (group && payload.groupId) {
          state.groups[payload.groupId] = group;
          delete state.pinnedGroups[payload?.groupId];
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getGroupsWeekMoods.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const moo = days.map((day, index) => {
          const res = Object.values(payload)[index] ?? null;
          return { ...day, pv: typeof res === 'number' ? Math.round(res) : null };
        });
        state.weekMoods = moo;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getFullGroup.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.selectedFullGroup = payload;
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isPendingAction(`${GROUPS_REDUCER_NAME}/`), (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejectedAction(`${GROUPS_REDUCER_NAME}/`), (state, { error }) => {
        state.error = error.message;
        state.isLoading = false;
      });
  },
});

export const { resetFullGroup, setRecentGroup } = authSlice.actions;
export default authSlice.reducer;
