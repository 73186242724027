import { AsyncThunk, PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isRejectedAction } from '../../utils/redux.utils';
import { Notification } from 'src/types/Notifications';
import { NotificationsApiService } from 'src/services/notifications.service';

export const NOTIFICATIONS_REDUCER_NAME = 'notifications';

interface INotificationsState {
  notifications: Array<Notification>;
  newNotification: boolean;
  error: string | null;
}

const initialState: INotificationsState = { notifications: [], error: null, newNotification: false };

export const getNotifications: AsyncThunk<
  Array<Notification>,
  { token: string },
  { rejectValue: any }
> = createAsyncThunk(`${NOTIFICATIONS_REDUCER_NAME}/getNotifications`, async ({ token }: { token: string }) => {
  const notificationsResponse = await NotificationsApiService.getAllNotifications(token);
  if (!notificationsResponse.ok || !notificationsResponse.data) {
    throw new Error('Notification bad request');
  }

  return notificationsResponse.data;
});

const notificationsSlice = createSlice({
  name: NOTIFICATIONS_REDUCER_NAME,
  initialState,
  reducers: {
    clearNotificationsState(state) {
      state.notifications = [];
      state.error = null;
    },
    setNotificationsListAsViewed(state) {
      state.newNotification = false;
    },
    setHasNewNotification(state) {
      state.newNotification = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, { payload }: PayloadAction<Array<Notification>>) => {
        state.notifications = payload;
        state.error = null;
      })

      .addMatcher(isRejectedAction(`${NOTIFICATIONS_REDUCER_NAME}/`), (state, { error }) => {
        state.error = error.message;
      });
  },
});

export const { clearNotificationsState, setHasNewNotification, setNotificationsListAsViewed } =
  notificationsSlice.actions;
export default notificationsSlice.reducer;
