import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useToken } from 'src/cookies';
import MessageHockPopUp from 'src/hooks/MessageHockPopUp';
import { GroupsApiService } from 'src/services/groups.service';
import { selectUserId } from 'src/store/auth';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import { currentDateInIsoFormat } from '../../../helpers/date';
import { ChatService } from '../../../services/socket.service';

export default function CreateGroupMobile() {
  const [stepper, setStep] = useState(1);
  const [groupName, setGroupName] = useState('');
  const [groupImage, setGroupImage] = useState<string | Blob>('');
  const [groupSubject, setGroupSubject] = useState<Array<string>>([]);
  const [groupPrivacy, setGroupPrivacy] = useState(false);
  const [groupIncognito, setGroupIncognito] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [imagePreview, setImagePreview] = useState();
  const [newGroupId, setNewGroupId] = useState('');
  const [isCreateBtnDisable, setIsCreateGroupBtnDisable] = useState(true);
  const { token } = useToken();
  const userId = useSelector(selectUserId);

  function extractContent(s: string) {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  const createGroup = async () => {
    if (groupName.length > 3) {
      setIsCreateGroupBtnDisable(false);
      const formData = new FormData();
      formData.append('name', groupName);
      formData.append('creation_date', currentDateInIsoFormat({}));
      formData.append('description', groupDescription);
      formData.append('is_private', String(groupPrivacy));
      formData.append('allow_incognito', groupIncognito);
      formData.append('admin', userId);
      formData.append('group_photo', groupImage);
      groupSubject.forEach((tag) => formData.append('tags', tag));

      const newGroup = await GroupsApiService.createGroup(token, formData);

      if (newGroup.ok) {
        setStep(3);
        setNewGroupId(newGroup.data.id);
        const newGroupChat = await ChatService.initiateAConversation({
          token,
          group: newGroup.data.id,
          userIds: [userId],
        });
        MessageHockPopUp({
          message: newGroupChat.ok ? 'Group created with chat' : 'Group created without chat',
          timeOut: 4000,
          type: 'success',
          size: 'small',
        });
      } else {
        //TODO add a normal error messaging
        if (newGroup.status === 413) {
          MessageHockPopUp({
            message: extractContent(newGroup.data) || 'Image size is too large',
            timeOut: 4000,
            type: 'error',
            size: 'small',
          });
        } else {
          MessageHockPopUp({
            message: extractContent(newGroup.data) || 'Create group Error',
            timeOut: 4000,
            type: 'error',
            size: 'small',
          });
        }
      }
    } else {
      MessageHockPopUp({
        message: 'Group name should contain more than 3 characters',
        timeOut: 4000,
        type: 'warning',
        size: 'small',
      });
    }
    setIsCreateGroupBtnDisable(true);
  };

  return (
    <div
      style={{
        backgroundColor: '#131313',
        height: '100vh',
      }}
    >
      {stepper === 1 && (
        <Step1
          setStep={setStep}
          setGroupName={setGroupName}
          setGroupSubject={setGroupSubject}
          setGroupPrivacy={setGroupPrivacy}
          setGroupIncognito={setGroupIncognito}
          setGroupImage={setGroupImage}
          groupName={groupName}
          setImagePreview={setImagePreview}
          groupImage={imagePreview}
        />
      )}
      {stepper === 2 && (
        <Step2
          setStep={setStep}
          setGroupDescription={setGroupDescription}
          createGroup={createGroup}
          isCreateBtnDisable={isCreateBtnDisable}
        />
      )}
      {stepper === 3 && <Step3 newGroupId={newGroupId} setStep={setStep} />}
      {stepper === 4 && <Step4 groupName={groupName} setStep={setStep} imagePreview={imagePreview} />}
    </div>
  );
}
