import { emergencyIcon } from 'images/emergencyIcon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import EmergencyStep1 from './components/EmergencyStep1';
import EmergencyStep2 from './components/EmergencyStep2';
import EmergencyStep3 from './components/EmergencyStep3';
import EmergencyStep4 from './components/EmergencyStep4';
import styles from './EmergencyMobileStyle.module.css';

import ArrowBackButton from '../../components/mobile/Buttons/ArrowBackButton';
import { useToken } from '../../cookies';
import MessageHockPopUp from '../../hooks/MessageHockPopUp';
import { EmergencyService } from '../../services/emergencies.service';
import { selectUserId } from '../../store/auth';
import { EmergencyReasons } from '../../utils/enums';

export default function EmergencyMobile() {
  const [stepComponent, setStepComponent] = useState(4);
  const [emergencyReason, setEmergencyReason] = useState<EmergencyReasons>(EmergencyReasons.OTHER);
  const [emergencyInfo, setEmergencyInfo] = useState('');
  const [emergencyStateAndTown, setEmergencyStateAndTown] = useState({ state: '', town: '', address: '' });
  const [isEmergencyIncognito, setIsEmergencyIncognito] = useState<boolean>(false);
  const loggedUserId = useSelector(selectUserId);

  const { token } = useToken();

  const sendEmergencyRequest = () => {
    EmergencyService.createAnEmergency({
      token,
      emergency_info: '', // emergencyInfo,
      is_emergency_incognito: false, //isEmergencyIncognito,
      is_emergency_button: true,
      state: '', // emergencyStateAndTown.state,
      town: '', // emergencyStateAndTown.town,
      address: '', //emergencyStateAndTown.address,
      emergency_reason: '', // emergencyReason,
      user: loggedUserId,
    }).then(() => {
      MessageHockPopUp({
        message: 'Request sent with success!',
        timeOut: 3000,
        type: 'success',
        size: 'small',
      });
    });
  };

  const getStep = () => {
    switch (stepComponent) {
      case 1:
        return (
          <EmergencyStep1
            emergencyInfo={emergencyInfo}
            setEmergencyReason={setEmergencyInfo}
            setStepComponent={setStepComponent}
          />
        );
      case 2:
        return (
          <EmergencyStep2
            isEmergencyIncognito={isEmergencyIncognito}
            setIsEmergencyIncognito={setIsEmergencyIncognito}
            emergencyStateAndTown={emergencyStateAndTown}
            setEmergencyStateAndTown={setEmergencyStateAndTown}
            setStepComponent={setStepComponent}
          />
        );
      case 3:
        return (
          <EmergencyStep3
            emergencyInfo={emergencyInfo}
            emergencyReason={emergencyReason}
            setStepComponent={setStepComponent}
            emergencyStateAndTown={emergencyStateAndTown}
            sendEmergencyReq={sendEmergencyRequest}
          />
        );
      case 4:
        return <EmergencyStep4 setStepComponent={setStepComponent} sendEmergencyReq={sendEmergencyRequest} />;
    }
  };

  return (
    <div className={styles.mainEmergencyPage}>
      {stepComponent === 0 ? (
        <>
          <div className={styles.navbar}>
            <ArrowBackButton pushUrl={`/feed`} />
            {/*<div onClick={() => history.push('./feed')}>{closeWhiteCircleIcon('40', '38', '#FFC700')}</div>*/}
          </div>
          <div className={styles.emergencyContent}>
            <div>{emergencyIcon('70', '70')}</div>
            <h1>What happened?</h1>
            <p>Choose from the following</p>
          </div>
          <div className={styles.emergencyOptions}>
            <div
              className={styles.emergencyButton}
              onClick={() => {
                setEmergencyReason(EmergencyReasons.FEELING_SAD);
                setStepComponent(1);
              }}
            >
              Feeling sad
            </div>
            <div
              className={styles.emergencyButton}
              onClick={() => {
                setEmergencyReason(EmergencyReasons.SOMETHING_HAPPENED);
                setStepComponent(1);
              }}
            >
              Something happened
            </div>
            <div
              className={styles.emergencyButton}
              onClick={() => {
                setEmergencyReason(EmergencyReasons.CONNECT_A_COUNSELOR);
                setStepComponent(1);
              }}
            >
              Connect to a counselor
            </div>
            <div
              className={styles.emergencyButton}
              onClick={() => {
                setEmergencyReason(EmergencyReasons.OTHER);
                setStepComponent(1);
              }}
            >
              Other
            </div>
          </div>
        </>
      ) : (
        getStep()
      )}
    </div>
  );
}
