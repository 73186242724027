import { IComment, Paper } from '../../types/Paper';
import CommentUserData from './CommentUserData';
import { useState } from 'react';
import { CommentIcon } from 'images/CommentIcon';
import { useDispatch, useSelector } from 'react-redux';
import { addOrRemoveCommentLike, removeComment } from '../../store/articles';
import { ReplyIcon } from 'images/ReplyIcon';
import { useToken } from '../../cookies';
import CommentInput from '../componentsUtil/CommentInput/CommentInput';
import TrashcanIcon from 'images/trashbinIcon';
import { selectSelectedPaper } from '../../store/articles/selector';
import { QuotesIcon } from 'images/QuotesIcon';
import { LikeThumbsUpIcon } from 'images/LikeThumbsUpIcon';
import { LikeThumbsUpOutline } from 'images/LikeThumbsUpOutline';
import { selectUserId } from '../../store/auth';
import { getQuoteByParagraphId, isCommentLikedByMe, replyCommentFormatted } from '../../helpers/comments';
import styles from './CommentsStyle.module.css';
import './style.css';

export default function CommentsList(comment: IComment) {
  const { token } = useToken();
  const dispatch = useDispatch();
  const loggedUserId = useSelector(selectUserId);
  const [isCommentListOpen, setIsCommentListOpen] = useState(false);
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const paper = useSelector(selectSelectedPaper) as Paper;

  const getCommentLikesNumber = () => {
    return Math.max(0, (comment.likes?.length || 0) - (comment.dislikes?.length || 0));
  };

  const addLikeToComment = () => {
    dispatch(
      addOrRemoveCommentLike({
        authorId: loggedUserId,
        commentId: comment.id,
        token,
        isAddLike: true,
      }),
    );
  };

  const removeLikeFromComment = () => {
    dispatch(
      addOrRemoveCommentLike({
        authorId: loggedUserId,
        commentId: comment.id,
        token,
        isAddLike: false,
      }),
    );
  };

  return (
    <div className={styles.mainCommentsList} key={comment.id}>
      <CommentUserData {...comment} />

      {!!comment.paragraphId && getQuoteByParagraphId(paper, comment) && (
        <div>
          <div className={styles.quotesIcon}>{QuotesIcon()}</div>
          <div className={styles.quotesParagraph}>{getQuoteByParagraphId(paper, comment)}</div>
        </div>
      )}
      <div className={styles.paragraphQtCommentBlock}>
        <span className={styles.paragraphQtCommentText}>{comment.description}</span>
        {loggedUserId === comment.author?.id && (
          <div
            className={styles.paragraphQtCommentTrashBin}
            onClick={() => dispatch(removeComment({ token, id: comment.id }))}
          >
            <TrashcanIcon />
          </div>
        )}
      </div>
      <div className={styles.paragraphQtCommentUtilities}>
        <div className={styles.paragraphQtLike}>
          {isCommentLikedByMe(comment, loggedUserId) ? (
            <div onClick={() => removeLikeFromComment()} className={styles.paragraphQtLikeBlack}>
              {LikeThumbsUpIcon()}
            </div>
          ) : (
            <div onClick={() => addLikeToComment()} className={styles.paragraphQtLikeWhite}>
              {LikeThumbsUpOutline()}
            </div>
          )}

          <div>{getCommentLikesNumber()}</div>
        </div>
        <div onClick={() => setIsCommentListOpen(!isCommentListOpen)} style={{ marginLeft: 'auto', cursor: 'pointer' }}>
          {!isCommentListOpen ? (
            <div className={styles.flexCenter}>
              {CommentIcon()}
              <div style={{ marginLeft: '3px' }}>
                {comment.replays?.length || 0}{' '}
                {!!comment.replays && comment.replays?.length === 1 ? 'Comment' : 'Comments'}
              </div>
            </div>
          ) : (
            <div style={{}} className={styles.flexCenter}>
              {CommentIcon()}
              <div style={{ marginLeft: '4px' }}>Hide replies</div>
            </div>
          )}
        </div>
        <div onClick={() => setIsReplyOpen(!isReplyOpen)} className={styles.replyIcon}>
          {ReplyIcon()}
          <div className={styles.replyLabel}>Reply</div>
        </div>
      </div>
      {isReplyOpen && (
        <CommentInput comment={comment} setCommentListOpen={setIsCommentListOpen} setOpenReply={setIsReplyOpen} />
      )}
      {isCommentListOpen && comment.replays && (
        <div className={styles.commentReplyBlock}>
          {[...comment.replays].reverse().map((_comm) => {
            return (
              <div style={{ marginTop: '10px', overflowX: 'hidden' }} key={_comm.id}>
                <CommentUserData {..._comm} />

                {_comm.description.length < 1000 ? (
                  <div className={`${styles.commentDescriptionBlock} ${styles.commentDescriptionBlockCommon}`}>
                    <p
                      className={styles.commentDescriptionText}
                      dangerouslySetInnerHTML={{
                        __html: replyCommentFormatted(_comm.description).replace(/\n\r?/g, '<br />'),
                      }}
                    />
                    {loggedUserId === _comm.author.id && (
                      <div
                        className={styles.commentDescriptionTrashBinIcon}
                        onClick={() => dispatch(removeComment({ token, id: _comm.id }))}
                      >
                        <TrashcanIcon />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={`${styles.commentDescriptionBlockLong} ${styles.commentDescriptionBlockCommon}`}>
                    <p
                      className={styles.commentDescriptionText}
                      dangerouslySetInnerHTML={{
                        __html: replyCommentFormatted(_comm.description).replace(/\n\r?/g, '<br />'),
                      }}
                    />
                    {loggedUserId === _comm.author.id && (
                      <div
                        className={styles.commentDescriptionTrashBinIcon}
                        onClick={() => dispatch(removeComment({ token, id: _comm.id }))}
                      >
                        <TrashcanIcon />
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
