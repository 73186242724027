export const UserMessageCircle = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0017 22.8572C15.5615 22.8572 15.1469 22.6345 14.8535 22.2465L13.8967 20.9743C13.8776 20.9489 13.801 20.9171 13.7691 20.9107H13.4502C10.7902 20.9107 9.14453 20.1919 9.14453 16.617V13.4365C9.14453 10.625 10.6308 9.14288 13.4502 9.14288H18.5532C21.3726 9.14288 22.8588 10.625 22.8588 13.4365V16.617C22.8588 19.4286 21.3726 20.9107 18.5532 20.9107H18.2342C18.1832 20.9107 18.1386 20.9362 18.1067 20.9743L17.1498 22.2465C16.8564 22.6345 16.4418 22.8572 16.0017 22.8572ZM13.4502 10.097C11.1666 10.097 10.1013 11.1593 10.1013 13.4365V16.617C10.1013 19.4922 11.09 19.9566 13.4502 19.9566H13.7691C14.0944 19.9566 14.4644 20.141 14.6621 20.4018L15.6189 21.674C15.8422 21.9666 16.1611 21.9666 16.3844 21.674L17.3412 20.4018C17.5517 20.1219 17.8834 19.9566 18.2342 19.9566H18.5532C20.8368 19.9566 21.902 18.8943 21.902 16.617V13.4365C21.902 11.1593 20.8368 10.097 18.5532 10.097H13.4502Z"
      fill="#FFC700"
    />
    <path
      d="M16.0012 15.9811C15.6439 15.9811 15.3633 15.6948 15.3633 15.345C15.3633 14.9951 15.6503 14.7089 16.0012 14.7089C16.352 14.7089 16.639 14.9951 16.639 15.345C16.639 15.6948 16.3584 15.9811 16.0012 15.9811Z"
      fill="#FFC700"
    />
    <path
      d="M18.5519 15.9811C18.1947 15.9811 17.9141 15.6948 17.9141 15.345C17.9141 14.9951 18.2011 14.7089 18.5519 14.7089C18.9028 14.7089 19.1898 14.9951 19.1898 15.345C19.1898 15.6948 18.9091 15.9811 18.5519 15.9811Z"
      fill="#FFC700"
    />
    <path
      d="M13.4504 15.9811C13.0932 15.9811 12.8125 15.6948 12.8125 15.345C12.8125 14.9951 13.0995 14.7089 13.4504 14.7089C13.8012 14.7089 14.0882 14.9951 14.0882 15.345C14.0882 15.6948 13.8076 15.9811 13.4504 15.9811Z"
      fill="#FFC700"
    />
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#EDEFF0" />
  </svg>
);
