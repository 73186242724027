import style from './AuthStyle.module.css';

import { Headers } from '../../helpers/layout';

export default function WelcomeBlock() {
  return (
    <div className={style.welcomeMain}>
      {/* @ts-ignore*/}
      <Headers.H1>
        <span className={style.joinText}>Join</span>
        <span style={{ fontWeight: '300' }}> VEXT and connect with</span>
        <br /> <b>your peers</b>
      </Headers.H1>
    </div>
  );
}
