import { plusIcon } from 'images/plusIcon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RoleElement from './components/RoleElement';
import UserElement from './components/UserElement';
import styles from './dashboardStyles.module.css';

import { useToken } from '../../../cookies';
import { UsersApiService } from '../../../services/users.service';
import { getRolesAndUsers } from '../../../store/dashboard/reducer';
import { selectDashboardUsersAndRoles } from '../../../store/dashboard/selector';
import { User } from '../../../types/User';
import { UsersRolesTab } from '../../../utils/enums';
import { useIsMobile, useIsTablet } from '../../../utils/styles.utils';
import AddStudent from '../statistics/peopleComponents/components/AddStudent';



function RolesAndUsers() {
  const [selectedTab, setSelectedTab] = useState<UsersRolesTab>(UsersRolesTab.USERS);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSmallDevice = isMobile || isTablet;
  const { token } = useToken();
  const userAndRoles = useSelector(selectDashboardUsersAndRoles);
  const rolesString = Object.keys(userAndRoles).map((key) => key);

  useEffect(() => {
    dispatch(getRolesAndUsers({ token }));
    UsersApiService.getUsers(token, true).then((r) => {
      setUsers(r.data as User[]);
    });
  }, []);

  return (
    <div
      className={styles.usersRolesMain}
      style={{ width: isSmallDevice ? '86%' : undefined, height: isSmallDevice ? '50vh' : undefined }}
    >
      <div className={styles.usersRolesNav}>
        <div className={styles.usersRolesSwitch}>
          {/*<div*/}
          {/*  onClick={() => setSelectedTab(UsersRolesTab.ROLES)}*/}
          {/*  className={selectedTab === UsersRolesTab.ROLES ? styles.usersRolesSelected : undefined}*/}
          {/*>*/}
          {/*  {UsersRolesTab.ROLES}*/}
          {/*</div>*/}
          {/*/*/}
          <div
            onClick={() => setSelectedTab(UsersRolesTab.USERS)}
            className={selectedTab === UsersRolesTab.USERS ? styles.usersRolesSelected : undefined}
          >
            {UsersRolesTab.USERS}
          </div>
        </div>
        {selectedTab === UsersRolesTab.ROLES ? (
          <div className={styles.usersRolesNewRoleBtn}>{plusIcon('#757575', '13')} Add new role</div>
        ) : (
          <div onClick={() => setOpenCreateUser(true)} className={styles.usersRolesNewRoleBtn}>
            {plusIcon('#757575', '13')} Add new user
          </div>
        )}
      </div>
      <div className={styles.userRolesWrap}>
        {selectedTab === UsersRolesTab.ROLES
          ? Object.entries(userAndRoles).map(([key, value]) => {
              return <RoleElement key={key} userRole={key} quantity={value} />;
            })
          : users.map((user) => {
              return <UserElement user={user} key={user.id} />;
            })}
      </div>
      <AddStudent roles={rolesString} isOpen={openCreateUser} setOpenCreateUser={setOpenCreateUser} />
    </div>
  );
}

export default RolesAndUsers;