export const MyProfile = (color = 'white') => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2C5.687 2 3.8 3.93538 3.8 6.30769C3.8 7.79077 4.538 9.10769 5.6564 9.88492C3.5162 10.8265 2 11.4665 2 14H3.2C3.2 11.2738 6.8 10.6154 8 10.6154C9.2 10.6154 12.8 11.2738 12.8 14H14C14 11.4665 12.4838 10.8271 10.3436 9.88431C10.9144 9.48909 11.3821 8.95643 11.7057 8.33292C12.0293 7.7094 12.1991 7.01405 12.2 6.30769C12.2 3.93538 10.313 2 8 2ZM8 3.23077C9.6638 3.23077 11 4.60123 11 6.30769C11 8.01415 9.6638 9.38462 8 9.38462C6.3362 9.38462 5 8.01415 5 6.30769C5 4.60123 6.3362 3.23077 8 3.23077Z"
      fill={color}
      stroke="#F8F8F8"
      strokeWidth="0.133333"
    />
  </svg>
);
