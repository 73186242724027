import { IGroup } from './Group';
import { Paper } from './Paper';
import { User } from './User';

export enum BlockMenuType {
  QUESTION = 'QUESTION',
  PAPER = 'PAPER',
  GROUP = 'GROUP',
}

export type MenuOption = {
  label: string;
  blockId: string;
  blockType: BlockMenuType;
  icon:any
};

export type SearchResponse = {
  groups: Array<IGroup>;
  papers: Array<Paper>;
  users: Array<User>;
};
