import { ApiResponse } from 'apisauce';
import { _RestApiService } from './api.service';

const authApi = new _RestApiService(window._env_.REACT_APP_BACKEND_URL || '');

export class _AuthApiService {
  signIn(email: string, password: string): Promise<ApiResponse<any>> {
    return authApi.post(`/api/auth/token/`, { email, password });
  }

  register(email: string, password: string, username: string): Promise<ApiResponse<any>> {
    return authApi.post(`/api/users/`, { email, password, username });
  }

  getDomains(): Promise<ApiResponse<any>> {
    return authApi.get(`/api/domains/`, {});
  }

  confirmCode(token: string, emailCode: string): Promise<ApiResponse<{ status: string }>> {
    return authApi.get(
      `/api/users/activate/?confirmation_token=${emailCode}`,
      {},
      authApi.getAuthenticateHeader(token),
    );
  }

  resendCode(token: string): Promise<ApiResponse<{ status: string }>> {
    return authApi.get(`/api/users/resend/`, {}, authApi.getAuthenticateHeader(token));
  }

  updateDeviceToken(deviceToken: string, token: string, userId: string): Promise<ApiResponse<any>> {
    return authApi.patch(
      `/api/users/${userId}/`,
      { notification_token: deviceToken },
      authApi.getAuthenticateHeader(token),
    );
  }
}

export const AuthApiService = new _AuthApiService();
