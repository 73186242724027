export const QuotesIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.35999 13.9436C5.964 14.9396 5.34001 15.9236 4.50601 16.8716C4.24201 17.1716 4.20601 17.6036 4.422 17.9396C4.59002 18.2036 4.86599 18.3476 5.16599 18.3476C5.25 18.3476 5.33401 18.3416 5.41802 18.3116C7.18198 17.7956 11.304 15.9656 11.418 10.0976C11.46 7.83558 9.80402 5.89158 7.65 5.66958C6.45601 5.54958 5.26802 5.93955 4.386 6.73156C3.50402 7.52957 3 8.66958 3 9.85757C3 11.8376 4.40402 13.5716 6.35999 13.9436Z"
      fill="#32C5FF"
    />
    <path
      d="M17.2261 5.66958C16.038 5.54958 14.85 5.93955 13.9681 6.73156C13.086 7.52957 12.582 8.66958 12.582 9.85757C12.582 11.8376 13.986 13.5716 15.9421 13.9436C15.546 14.9396 14.922 15.9236 14.088 16.8716C13.824 17.1716 13.788 17.6036 14.0041 17.9396C14.172 18.2036 14.4481 18.3475 14.7481 18.3475C14.832 18.3475 14.916 18.3416 15 18.3115C16.764 17.7956 20.886 15.9656 21 10.0975V10.0136C21 7.78756 19.3621 5.89158 17.2261 5.66958Z"
      fill="#32C5FF"
    />
  </svg>
);
