import React from 'react';
import styles from './styles.module.css';

function VextMeaning() {
  return (
    <div className={styles.textBlock}>
      VEXT is a portmanteau of “nEXT” and “Voice.” It conveniently and cleverly mirrors the word "vexed," which means
      "annoyed, frustrated, or worried." Whether in school, sports, band, club, work, or in whatever vocation or
      avocation you are engaged, we are living in a time of high levels of anxiety and vexation. The feelings and moods
      that so many of us struggle to voice, VEXT means being able to try to find a way to do so.
    </div>
  );
}

export default VextMeaning;
