import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { emptyHeartIcon } from 'images/emptyHeartIcon';
import { heartIcon } from 'images/heartIcon';
import { Comments } from '../../components/Article/Comments';
import FooterIcons from '../../components/Footer/components/FooterIcons';
import NavigationNavbar from '../../components/Header/NavigationHeader';
import { useToken } from '../../cookies';
import { getTimeRead } from '../../helpers/articleInfo';
import { addOrRemovePaperLike, getPaperById } from '../../store/articles';
import { selectIsArticleLoading, selectSelectedPaper } from '../../store/articles/selector';
import { selectLoggedUserData } from '../../store/user/selectors';
import { IComment, Paper } from '../../types/Paper';
import CommentToolTip from './components/CommentToolTip';
import { useIsMobile, useIsTablet } from '../../utils/styles.utils';
import MobileArticleView from '../../components/mobile/MobileArticleView/MobileArticleView';
import { selectUserId } from '../../store/auth';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import ReactGA from 'react-ga4';
import styles from './ArticleView.module.css';

export type SelectedParagraph = {
  id: string;
  text: string;
};

const getCommentsNumber = (comments: Array<IComment>) => {
  return comments.filter((el) => !el.repliedToCommentId);
};

export default function ViewArticle() {
  const params = useParams() as { id: string | undefined };
  const dispatch = useDispatch();
  const { token } = useToken();
  const [selectedParagraph, setSelectedParagraph] = useState<SelectedParagraph | undefined>(undefined);
  const [openedParagraph, setOpenedParagraph] = useState('');
  const loggedUser = useSelector(selectLoggedUserData);
  const loggedUserId = useSelector(selectUserId);
  const isArticleLoading = useSelector(selectIsArticleLoading);
  const paper = useSelector(selectSelectedPaper) as Paper;
  const comments = getCommentsNumber(paper.comments);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false,
  };
  const isPaperLikedByMe = () => {
    return paper.likes.find((like) => like.id === loggedUserId);
  };

  const changedPaperContent = () => {
    const htmlParser = document.createElement('div');
    htmlParser.innerHTML = paper.content;
    const paragraphAndIdsCollection = Array.from(htmlParser.children).map((el) => {
      return {
        id: el.id,
        text: el.innerHTML,
      };
    });

    const newContent = [];
    for (let i = 0; i < paragraphAndIdsCollection.length; i++) {
      const paragraphComments = paper.comments.filter(
        (comment) => comment.paragraphId === paragraphAndIdsCollection[i].id,
      );

      newContent.push(
        `<p ${
          paragraphAndIdsCollection[i].text.search('<img') === -1 && paragraphAndIdsCollection[i].id
            ? "class='paragraph'"
            : ''
        } id="${paragraphAndIdsCollection[i].id}">` +
          paragraphAndIdsCollection[i].text +
          (paragraphComments.length
            ? `<span class='paragraphCommentsCount'>${paragraphComments.length}</span></p>`
            : '</p>'),
      );
    }

    return newContent || paper.content || '';
  };

  const addAddPaperLike = () => {
    dispatch(
      addOrRemovePaperLike({
        author: { ...loggedUser, id: loggedUserId },
        articleId: paper.id,
        token,
        isAddLike: true,
      }),
    );
    ReactGA.event({
      category: 'User',
      action: 'article_like',
      label: 'User likes an article',
    });
  };

  const removePaperLike = () => {
    dispatch(
      addOrRemovePaperLike({
        author: { ...loggedUser, id: loggedUserId },
        articleId: paper.id,
        token,
        isAddLike: false,
      }),
    );
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getPaperById({ token, id: params.id }));
    }
  }, [params]);

  return (
    <>
      {isArticleLoading ? (
        <div className={styles.spinner}>
          <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      ) : (
        <>
          {isMobile || isTablet ? (
            <MobileArticleView
              article={paper}
              isPaperLikedByMe={isPaperLikedByMe}
              addAddPaperLike={addAddPaperLike}
              removePaperLike={removePaperLike}
              changedPaperContent={changedPaperContent}
              setSelectedParagraph={setSelectedParagraph}
              setOpenedParagraph={setOpenedParagraph}
              openedParagraph={openedParagraph}
              selectedParagraph={selectedParagraph}
            />
          ) : (
            <div>
              <NavigationNavbar />
              {paper.title && paper.id ? (
                <div className={styles.articleViewMain}>
                  <div className={styles.articleViewLeftBlock}>
                    <div className={styles.articleViewInfo}>
                      <div className={styles.articleViewInfoLeftBlock}>
                        <div className={styles.articleViewTitle}>{paper.title}</div>
                        <div className={styles.articleViewsNumber}>
                          <div>{paper.views} views</div>
                          <span className={styles.dot}>&#8226;</span>
                          <span>{new Intl.DateTimeFormat('us-US', options).format(new Date(paper.created))}</span>
                          <span className={styles.dot}>&#8226;</span>
                          <span>{getTimeRead(paper.duration)}</span>
                          {!!paper.paper_tags.length && (
                            <>
                              <span className={styles.dot}>&#8226;</span>
                              {paper.paper_tags.map((tag) => {
                                return (
                                  <div key={tag.id} className={styles.articleViewTag}>
                                    {tag.name}
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                      <div className={styles.articleViewInfoRightBlock}>
                        {!isPaperLikedByMe() ? (
                          <div className={styles.articleLike} onClick={() => addAddPaperLike()}>
                            {emptyHeartIcon()}
                          </div>
                        ) : (
                          <div className={styles.articleLike} onClick={() => removePaperLike()}>
                            {heartIcon()}
                          </div>
                        )}
                      </div>
                    </div>
                    {paper.image && (
                      <div className={styles.paperImageBlock}>
                        <img className={styles.paperImage} src={paper.image} alt="" />
                      </div>
                    )}

                    {!!paper?.content?.length && (
                      <div className={styles.articleViewContent}>
                        {changedPaperContent().map((paragraph) => {
                          return (
                            <div key={paragraph}>
                              <CommentToolTip
                                paragraph={paragraph}
                                setSelectedParagraph={setSelectedParagraph}
                                openedParagraph={openedParagraph}
                                setOpenedParagraph={setOpenedParagraph}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <div className={styles.articleFooterLeft}>
                      <div className={styles.articleFooterLeftInfo}>
                        <div>
                          {paper.likes.length} {paper.likes.length === 1 ? 'like' : 'likes'}
                        </div>
                        <div>
                          {comments.length}
                          {comments.length === 1 ? ' comment' : ' comments'}
                        </div>
                      </div>
                      <FooterIcons />
                    </div>
                  </div>
                  <Comments selectedParagraph={selectedParagraph} setSelectedParagraph={setSelectedParagraph} />
                </div>
              ) : (
                <div style={{ width: '100%', textAlign: 'center', marginTop: '10%' }}>
                  Oops, it seems this article has been deleted
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
