import { css } from 'styled-components/macro';
import ReactDOMServer from 'react-dom/server';
import { ErrorIcon } from 'images/ErrorIcon';
import { SuccessIcon } from 'images/SuccessIcon';
import { WarningIcon } from 'images/WarningIcon';
import { HelpIcon } from 'images/HelpIcon';
import { IconClose } from 'images/IconClose';

export default function MessageHockPopUp({
  message,
  timeOut,
  type,
  size,
}: {
  size?: 'small' | string;
  message: string;
  timeOut?: number;
  type?: 'error' | 'warning' | 'success' | 'help';
}) {
  const getMessageBlock = () => {
    if (!type || type === 'error') {
      return (
        <div
          css={css`
            position: fixed;
            ${size === 'small' ? `width: 276px` : `width: 438px`};
            height: 90px;
            border-radius: 32px;
            bottom: 2%;
            right: 1%;
            background-color: #fc5862;
            padding: 14px;
            color: white;
            display: flex;
            align-items: center;
            margin-right: 4px;
            z-index: 1000;
          `}
        >
          <div>{ErrorIcon()}</div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-left: 20px;
              width: 280px;
            `}
          >
            <span
              css={css`
                font-weight: 700;
                font-size: 18px;
                margin-bottom: 8px;
              `}
            >
              Oh snap!
            </span>
            <span
              css={css`
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
              `}
            >
              {message}
            </span>
          </div>
          <div
            id="closeIcon"
            css={css`
              margin-bottom: auto;
              margin-left: auto;
              cursor: pointer;
            `}
          >
            {IconClose('white')}
          </div>
        </div>
      );
    }
    if (type === 'warning') {
      return (
        <div
          css={css`
            position: fixed;
            ${size === 'small' ? `width: 276px` : `width: 438px`};
            height: 90px;
            border-radius: 32px;
            bottom: 2%;
            right: 1%;
            background-color: #ffbb55;
            padding: 14px;
            color: white;
            display: flex;
            align-items: center;
            margin-right: 4px;
            z-index: 1000;
          `}
        >
          <div>{WarningIcon()}</div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-left: 20px;
              width: 280px;
            `}
          >
            <span
              css={css`
                font-weight: 700;
                font-size: 18px;
                margin-bottom: 8px;
              `}
            >
              Warning!
            </span>
            <span
              css={css`
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
              `}
            >
              {message}
            </span>
          </div>
          <div
            id="closeIcon"
            css={css`
              margin-bottom: auto;
              margin-left: auto;
              cursor: pointer;
            `}
          >
            {IconClose('white')}
          </div>
        </div>
      );
    }
    if (type === 'help') {
      return (
        <div
          css={css`
            position: fixed;
            width: 438px;
            height: 90px;
            border-radius: 32px;
            bottom: 2%;
            right: 1%;
            background-color: #32c5ff;
            padding: 14px;
            color: white;
            display: flex;
            align-items: center;
            margin-right: 4px;
          `}
        >
          <div>{HelpIcon()}</div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-left: 20px;
              width: 280px;
            `}
          >
            <span
              css={css`
                font-weight: 700;
                font-size: 18px;
                margin-bottom: 8px;
              `}
            >
              Hi there!
            </span>
            <span
              css={css`
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
              `}
            >
              {message}
            </span>
          </div>
          <div
            id="closeIcon"
            css={css`
              margin-bottom: auto;
              margin-left: auto;
              cursor: pointer;
            `}
          >
            {IconClose('white')}
          </div>
        </div>
      );
    } else
      return (
        <div
          css={css`
            position: fixed;
            ${size === 'small' ? `width: 276px` : `width: 438px`};
            z-index: 1000;
            height: 90px;
            border-radius: 32px;
            bottom: 2%;
            right: 1%;
            background-color: #61b779;
            padding: 14px;
            color: white;
            display: flex;
            align-items: center;
            margin-right: 4px;
          `}
        >
          <div>{SuccessIcon()}</div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-left: 20px;
              width: 280px;
            `}
          >
            <span
              css={css`
                font-weight: 700;
                font-size: 18px;
                margin-bottom: 8px;
              `}
            >
              Well done!
            </span>
            <span
              css={css`
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
              `}
            >
              {message}
            </span>
          </div>
          <div
            id="closeIcon"
            css={css`
              margin-bottom: auto;
              margin-left: auto;
              cursor: pointer;
            `}
          >
            {IconClose('white')}
          </div>
        </div>
      );
  };

  const el = document.createElement('div');
  el.id = 'marker';
  el.innerHTML = ReactDOMServer.renderToStaticMarkup(getMessageBlock());
  const root = document.getElementById('root');
  root?.appendChild(el);
  const closeIconEl = document.getElementById('closeIcon');
  if (closeIconEl)
    closeIconEl.onclick = function () {
      root?.removeChild(el);
    };
  setTimeout(() => {
    root?.removeChild(el);
  }, timeOut || 3000);
}
