import React from 'react';
import styles from './MobileChatsStyles.module.css';
import { calcCreatedTime } from '../../helpers/date';
import { sliceText } from '../../helpers/textHelper';
import { ChatListPreview } from '../../types/User';
import { NO_GROUP_COVER } from '../../utils/constantImagesUrl';

function GroupChatRow({ chatRow }: { chatRow: ChatListPreview }) {
  return (
    <div className={styles.mobChatRow}>
      <img className={styles.userImg} src={chatRow.group?.group_photo || NO_GROUP_COVER} alt="chat img" />
      <div className={styles.middleBlock}>
        <div className={styles.nameAndTime}>
          <div>{chatRow.group?.name}</div>
          <div className={styles.creationTime}>
            {chatRow.has_unseen_message && <span className={styles.unreadMsgDot}></span>}
            {calcCreatedTime(chatRow.last_message?.creation_date || chatRow.creation_date)}
          </div>
        </div>
        <div className={styles.previewMessage}>
          {/*<div className={styles.ownerName}>{chatRow.author}</div>*/}
          {sliceText(chatRow.last_message?.message || 'No messages yet', 110)}
        </div>
      </div>
    </div>
  );
}

export default GroupChatRow;