import { FC, ReactElement } from 'react';

type Props = {
    pathFill?: string;
};

const ReportIcon: FC<Props> = ({ pathFill = '#EDEFF0' }): ReactElement => (
    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 10.0625C7.6925 10.0625 7.4375 9.8075 7.4375 9.5V5.75C7.4375 5.4425 7.6925 5.1875 8 5.1875C8.3075 5.1875 8.5625 5.4425 8.5625 5.75V9.5C8.5625 9.8075 8.3075 10.0625 8 10.0625Z"
      fill={pathFill}
    />
    <path
      d="M8 12.5002C7.955 12.5002 7.9025 12.4927 7.85 12.4852C7.805 12.4777 7.76 12.4627 7.715 12.4402C7.67 12.4252 7.625 12.4027 7.58 12.3727C7.5425 12.3427 7.505 12.3127 7.4675 12.2827C7.3325 12.1402 7.25 11.9452 7.25 11.7502C7.25 11.5552 7.3325 11.3602 7.4675 11.2177C7.505 11.1877 7.5425 11.1577 7.58 11.1277C7.625 11.0977 7.67 11.0752 7.715 11.0602C7.76 11.0377 7.805 11.0227 7.85 11.0152C7.9475 10.9927 8.0525 10.9927 8.1425 11.0152C8.195 11.0227 8.24 11.0377 8.285 11.0602C8.33 11.0752 8.375 11.0977 8.42 11.1277C8.4575 11.1577 8.495 11.1877 8.5325 11.2177C8.6675 11.3602 8.75 11.5552 8.75 11.7502C8.75 11.9452 8.6675 12.1402 8.5325 12.2827C8.495 12.3127 8.4575 12.3427 8.42 12.3727C8.375 12.4027 8.33 12.4252 8.285 12.4402C8.24 12.4627 8.195 12.4777 8.1425 12.4852C8.0975 12.4927 8.045 12.5002 8 12.5002Z"
      fill={pathFill}
    />
    <path
      d="M12.5047 16H3.49221C2.04218 16 0.934209 15.4409 0.369069 14.4331C-0.188635 13.4252 -0.114275 12.126 0.592151 10.7795L5.0984 2.19685C5.84201 0.779528 6.86818 0 7.99846 0C9.12874 0 10.1549 0.779528 10.8985 2.19685L15.4048 10.7874C16.1112 12.1339 16.193 13.4252 15.6279 14.4409C15.0627 15.4409 13.9547 16 12.5047 16ZM7.99846 1.1811C7.29947 1.1811 6.61536 1.74803 6.07252 2.77165L1.57371 11.3622C1.06806 12.3228 0.986262 13.2047 1.33576 13.8425C1.68525 14.4803 2.4586 14.8268 3.49965 14.8268H12.5121C13.5532 14.8268 14.3191 14.4803 14.676 13.8425C15.033 13.2047 14.9437 12.3307 14.4381 11.3622L9.9244 2.77165C9.38157 1.74803 8.69745 1.1811 7.99846 1.1811Z"
      fill={pathFill}
    />
  </svg>
);
export default ReportIcon;
