import { ApiResponse } from 'apisauce';
import { _RestApiService } from './api.service';
import { Message } from '../types/Chat';

const chatApi = new _RestApiService(window?._env_?.REACT_APP_BACKEND_URL || '');

type MessagesResponse = {
  results: Array<Message>;
  count: number;
  next: string | null;
};

export class _ChatService {
  getPeopleConversations(token: string, page_size?: number, page?: number): Promise<ApiResponse<any>> {
    return chatApi.get(`/api/chat/conversations/users/`, { page_size, page }, chatApi.getAuthenticateHeader(token));
  }

  getGroupsConversations(token: string, page_size?: number, page?: number): Promise<ApiResponse<any>> {
    return chatApi.get(`/api/chat/conversations/groups/`, { page_size, page }, chatApi.getAuthenticateHeader(token));
  }

  joinChatGroup(token: string, groupId: string): Promise<ApiResponse<any>> {
    return chatApi.post(`api/groups/${groupId}/chat/`, {}, chatApi.getAuthenticateHeader(token));
  }

  getMyChatMessagesList(
    token: string,
    chatId: string,
    page: number,
    pageSize: number,
  ): Promise<ApiResponse<MessagesResponse>> {
    return chatApi.get(
      `/api/chat/messages/${chatId}/get_messages/`,
      {
        page,
        page_size: pageSize,
      },
      chatApi.getAuthenticateHeader(token),
    );
  }

  initiateAConversation({
    group,
    conversationName,
    should_send_notification,
    should_targets_accept,
    userIds,
    token,
    reportId,
  }: {
    group?: string;
    token: string;
    userIds: Array<string>;
    should_send_notification?: boolean;
    should_targets_accept?: boolean;
    conversationName?: string;
    reportId?: string;
  }): Promise<ApiResponse<any>> {
    //if no notification and target_must_accept there won't be notification about new chat
    return chatApi.post(
      `/api/chat/conversations/`,
      {
        group,
        users: userIds,
        name: conversationName,
        should_send_notification,
        should_targets_accept,
        report_id: reportId,
      },
      chatApi.getAuthenticateHeader(token),
    );
  }

  initiateAEmergencyConversation({
    should_send_notification,
    should_target_accept,
    emergencyId,
    token,
    name,
  }: {
    token: string;
    name?: string;
    emergencyId?: string;
    should_send_notification?: boolean;
    should_target_accept?: boolean;
  }): Promise<ApiResponse<any>> {
    return chatApi.post(
      `api/chat/conversations/emergency/`,
      {
        emergency: emergencyId,
        should_send_notification,
        should_target_accept,
        name,
      },
      chatApi.getAuthenticateHeader(token),
    );
  }

  deleteConversation(token: string, conversationId: string): Promise<ApiResponse<any>> {
    return chatApi.delete(`/api/chat/conversations/${conversationId}/`, {}, chatApi.getAuthenticateHeader(token));
  }

  acceptConversation(token: string, conversationId: string): Promise<ApiResponse<any>> {
    return chatApi.patch(`/api/chat/conversations/${conversationId}/accept/`, {}, chatApi.getAuthenticateHeader(token));
  }

  declineConversation(token: string, conversationId: string): Promise<ApiResponse<any>> {
    return chatApi.patch(
      `/api/chat/conversations/${conversationId}/decline/`,
      {},
      chatApi.getAuthenticateHeader(token),
    );
  }
}

export const ChatService = new _ChatService();