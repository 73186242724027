import { getTimeRead } from 'src/helpers/articleInfo';
import { Paper } from 'src/types/Paper';
import { NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';
import { userNameFromAllParams } from '../../../../helpers/userName';
import styles from './style.module.css';

export default function ArticleUserInfoBlock({ article }: { article: Paper }) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false,
  };
  return (
    <div className={styles.articleBottomBlock}>
      <div className={styles.articleUserInfo}>
        <img
          className={styles.articleUserImg}
          src={article.paper_author?.profile?.image_url || NO_USER_AVATAR}
          alt="User Avatar"
        />
        <div className={styles.articleUserNames}>
          {userNameFromAllParams(
            article.paper_author?.username,
            article.paper_author?.profile?.first_name,
            article.paper_author?.profile?.last_name,
            article.paper_author?.profile?.nickname,
          )}
          <div className={styles.articleInfoBlock}>
            <span>{new Intl.DateTimeFormat('us-US', options).format(new Date(article.created))}</span>
            <span className={styles.dot}>&#8226;</span>
            <span>{getTimeRead(article.duration)}</span>
          </div>
        </div>
      </div>

      {!!article.paper_tags?.length && (
        <>
          <div className={styles.articleTags}>{article.paper_tags[0].name}</div>
        </>
      )}
    </div>
  );
}
