import React, { useEffect, useRef, useState } from 'react';
import { Message } from '../../types/Chat';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../../store/auth';
import { formatDate } from '../../helpers/date';
import MobileLoaderScreen from '../layout/MobileLoaderScreen';
import { ChatListPreview } from '../../types/User';
import ForeignMessage from './components/ForeignMessage';
import { selectChatMessages, selectMessagesAreLoading, selectOpenedMessagesHasNext } from '../../store/chats/selector';
import { getMessagesByChatId, MESSAGES_PER_PAGE } from '../../store/chats';
import { useToken } from '../../cookies';
import scrollSmoothTo from '../../helpers/ScrollingToSmooth';
import styles from './MobileChatsStyles.module.css';

const ACCEPT_CHAT_MESSAGE = 'Has accepted your request!';
const regex = new RegExp(ACCEPT_CHAT_MESSAGE, 'i');

const myMessage = (text: string, date: string) => {
  return (
    <div className={styles.mobChatMyMessage}>
      <div className={styles.messageText}>{text}</div>
      <div className={styles.msgDate}>{format(new Date(date), 'HH:mm')}</div>
    </div>
  );
};

const acceptChatBlock = () => {
  return <div className={styles.acceptedMessage}>Chat request accepted!</div>;
};

function MobileMessagesList({ chat }: { chat: ChatListPreview }) {
  const [mapMessagesPeriod, setMapMessagesPeriod] = useState<{ [key: string]: Message }>({});
  const [scrollDelay, setScrollDelay] = useState(false);
  const [page, setPage] = useState(2);
  const [initialScrollHeight, setInitialScrollHeight] = useState(0);
  const hasNext = useSelector(selectOpenedMessagesHasNext);
  const loggedUserId = useSelector(selectUserId);
  const chatMessages = useSelector(selectChatMessages);
  const isLoading = useSelector(selectMessagesAreLoading);
  const dispatch = useDispatch();
  const { token } = useToken();
  const scrollContainer = useRef(null) as any;
  const chatMessagesRef = useRef([]) as any;

  useEffect(() => {
    if (scrollContainer.current) {
      //TODO scrolling position in chat need a better logic here
      if (chatMessages.length > 0) {
        const previousChatMessages = chatMessagesRef.current;
        const newChatMessages = chatMessages;

        if (previousChatMessages.length === 0) {
          // When open conversation is scrolling to the bottom
          scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight;
          chatMessagesRef.current = newChatMessages;
          return setInitialScrollHeight(scrollContainer.current.scrollHeight);
        }
        if (previousChatMessages.length + 1 === newChatMessages.length) {
          // When new message is coming is scrolling to the bottom
          chatMessagesRef.current = newChatMessages;
          return scrollSmoothTo(scrollContainer, scrollContainer.current.scrollHeight);
        }
        // Maintain in the same ui position each time new items are fetched
        if (newChatMessages.length > MESSAGES_PER_PAGE && previousChatMessages.length < newChatMessages.length) {
          scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight - (initialScrollHeight + 50);
          setInitialScrollHeight(scrollContainer.current.scrollHeight);
        }
        chatMessagesRef.current = newChatMessages;
      }
    }
  }, [chatMessages]);

  useEffect(() => {
    const newMapMessagesPeriod = {} as any;

    chatMessages.forEach((msg) => {
      const date = formatDate(new Date(msg.creation_date));
      if (!newMapMessagesPeriod[date]) {
        newMapMessagesPeriod[date] = msg;
      }
    });

    setMapMessagesPeriod(newMapMessagesPeriod);
  }, [chatMessages]);

  const onScroll = () => {
    if (scrollContainer.current && !scrollDelay && hasNext) {
      const { scrollTop } = scrollContainer.current;
      if (scrollTop === 0) {
        setScrollDelay(true);
        setTimeout(() => {
          dispatch(getMessagesByChatId({ token, chatId: chat.id, page }));
          setPage((page) => page + 1);
          setScrollDelay(false);
        }, 500);
      }
    }
  };

  const itemContent = (message: Message) => {
    const messageTime = formatDate(new Date(message.creation_date));
    if (regex.test(message.message)) {
      return chatMessages.length < 2 ? acceptChatBlock() : <React.Fragment>&#8203;</React.Fragment>;
    }
    return (
      <div className={styles.wrapperMessage}>
        <div className={styles.dateLabel}>
          {mapMessagesPeriod[messageTime] && mapMessagesPeriod[messageTime].creation_date === message.creation_date && (
            <div>{messageTime}</div>
          )}
        </div>
        {message.author === loggedUserId ? (
          myMessage(message.message, message.creation_date)
        ) : (
          <ForeignMessage message={message} date={message.creation_date} chat={chat} />
        )}
      </div>
    );
  };

  return (
    <>
      {isLoading && <MobileLoaderScreen />}
      <div onScroll={() => onScroll()} className={styles.virtusoWrapper} ref={scrollContainer}>
        {chatMessages?.length
          ? chatMessages.map((message) => {
              return <div key={message.creation_date}> {itemContent(message)}</div>;
            })
          : ''}
      </div>
    </>
  );
}

export default MobileMessagesList;