import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { css } from 'styled-components/macro';
import { useToken } from '../../../cookies';
import { updateUser } from '../../../store/user';
import { User } from '../../../types/User';
import { EditContentSwitch } from './EditContentSwitch';

export const AboutYou = ({ userData, isNotMyUser }: { userData: User; isNotMyUser?: boolean }) => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const [isEditable, setIsEditable] = useState(true);
  const [userFirstName, setUserFirstName] = useState(userData.profile?.first_name);
  const [userLastName, setUserLasttName] = useState(userData.profile?.last_name);
  const [displayName, setUserDisplayName] = useState(userData.profile?.nickname);
  const [userEmail, setUserEmail] = useState(userData.email);
  const [userBirthDate, setUserBirthDate] = useState(userData.profile?.birth_date);
  const [userPhoneNumber, setUserPhoneNumber] = useState(userData.profile?.phone);
  const [allCompleted, setAllCompleted] = useState(false);

  useEffect(() => {
    setUserFirstName(userData.profile?.first_name);
    setUserLasttName(userData.profile?.last_name);
    setUserDisplayName(userData.profile?.nickname);
    setUserEmail(userData.email);
    setUserBirthDate(userData.profile?.birth_date);
    setUserPhoneNumber(userData.profile?.phone);
  }, [userData]);

  useEffect(() => {
    if (
      userFirstName?.length &&
      userLastName?.length &&
      userEmail.length &&
      userBirthDate?.length &&
      userPhoneNumber?.length &&
      displayName?.length
    ) {
      setAllCompleted(true);
    } else {
      setAllCompleted(false);
    }
  }, [userFirstName, userEmail, userBirthDate, userPhoneNumber, displayName, userLastName]);

  const saveNewUserInfo = () => {
    if (
      userFirstName?.length &&
      userEmail.length &&
      userBirthDate?.length &&
      userPhoneNumber?.length &&
      displayName?.length
    ) {
      const newUserInfo = {
        ...userData,
        email: userEmail,
        profile:{
          id:userData.profile?.id,
          first_name: userFirstName,
          last_name: userLastName,
          birth_date: userBirthDate,
          phone: userPhoneNumber,
          nickname: displayName,
        }
      }as unknown as User;
      dispatch(updateUser({ token, updatedData: newUserInfo, isNotMyUser }));
      setIsEditable(true);
    }
  };

  const rowData = (title: string, info: string, stateCase: string, isDate?: boolean) => {
    const setUserData = (data: string) => {
      switch (stateCase) {
        case 'firstName':
          return setUserFirstName(data);
        case 'lastName':
          return setUserLasttName(data);
        case 'displayName':
          return setUserDisplayName(data);
        case 'email':
          return setUserEmail(data);
        case 'date':
          return setUserBirthDate(data);
        case 'phone':
          return setUserPhoneNumber(data);
      }
    };

    const getUserData = (): string => {
      switch (stateCase) {
        case 'firstName':
          return userFirstName||'';
        case 'lastName':
          return userLastName||'';
        case 'displayName':
          return displayName||'';
        case 'email':
          return userEmail;
        case 'date':
          return userBirthDate||'';
        case 'phone':
          return userPhoneNumber||'';
        default:
          return '';
      }
    };

    return (
      <div
        css={css`
          margin-top: 6px;
        `}
      >
        <div
          css={css`
            font-family: sans-serif;
            font-weight: 600;
          `}
        >
          {title}
        </div>
        <div
          css={css`
            margin-top: 6px;
            font-family: sans-serif;
            font-weight: 400;
          `}
        >
          {isEditable ? (
            info
          ) : isDate ? (
            <input
              css={css`
                border: none;
                width: 164px;
                ${getUserData().length
                  ? ` border-bottom: 0.5px solid rgba(0, 0, 0, 0.56);`
                  : ` border-bottom: 0.5px solid rgba(253, 61, 2, 0.56);`}

                padding-bottom: 4px;

                &:focus {
                  border: none;
                  outline: none;
                  ${getUserData().length
                    ? ` border-bottom: 0.5px solid rgba(0, 0, 0, 0.56);`
                    : ` border-bottom: 0.5px solid rgba(253, 61, 2, 0.56);`}
                }
              `}
              type="number"
              min={1900}
              max={9999}
              defaultValue={info}
              onChange={(e) => setUserData(e.target.value)}
            />
          ) : (
            <input
              css={css`
                border: none;
                ${getUserData().length
                  ? ` border-bottom: 0.5px solid rgba(0, 0, 0, 0.56);`
                  : ` border-bottom: 0.5px solid rgba(253, 61, 2, 0.56);`}

                padding-bottom: 4px;

                &:focus {
                  border: none;
                  outline: none;
                  ${getUserData().length
                    ? ` border-bottom: 0.5px solid rgba(0, 0, 0, 0.56);`
                    : ` border-bottom: 0.5px solid rgba(253, 61, 2, 0.56);`}
                }
              `}
              defaultValue={info}
              onChange={(e) => setUserData(e.target.value)}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          width: 70%;
        `}
      >
        <div
          css={css`
            margin-top: 14px;
          `}
        >
          {rowData('First Name', userFirstName||'', 'firstName')}
        </div>
        <div
          css={css`
            margin-top: 34px;
          `}
        >
          {rowData('Last Name', userLastName||'', 'lastName')}
        </div>
        <div
          css={css`
            margin-top: 34px;
          `}
        >
          {rowData('Display Name*', displayName||'', 'displayName')}
        </div>
        <div
          css={css`
            margin-top: 34px;
          `}
        >
          {rowData('Personal email*', userEmail, 'email')}
        </div>
        <div
          css={css`
            margin-top: 34px;
          `}
        >
          {rowData('Date of birth', userBirthDate||'', 'date', true)}
        </div>
        <div
          css={css`
            margin-top: 34px;
          `}
        >
          {rowData('Phone Number', userPhoneNumber||'', 'phone')}
        </div>
      </div>
      <div
        css={css`
          margin: 6px;
          display: flex;
          padding: 6px;
          justify-content: end;
        `}
      >
        <EditContentSwitch
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          submit={saveNewUserInfo}
          isAllCompleted={allCompleted}
        />
      </div>
    </div>
  );
};
