export const FollowedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_840_13529)">
      <circle cx="12" cy="12" r="12" fill="#54B1FD" />
    </g>
    <path
      d="M12 6.375C8.89819 6.375 6.375 8.89838 6.375 12C6.375 15.1016 8.89819 17.625 12 17.625C15.1018 17.625 17.625 15.1016 17.625 12C17.625 8.89838 15.1018 6.375 12 6.375ZM10.875 7.5C11.9088 7.5 12.75 8.34119 12.75 9.375C12.75 10.4088 11.9088 11.25 10.875 11.25C9.84119 11.25 9 10.4088 9 9.375C9 8.34119 9.84119 7.5 10.875 7.5ZM13.875 15.8438C13.875 15.9987 13.7798 16.1376 13.6355 16.1935C13.1096 16.3969 12.5596 16.5 12 16.5C10.3982 16.5 8.90442 15.6365 8.10132 14.2463C8.04492 14.1487 8.0354 14.031 8.07568 13.9255C8.51331 12.7738 9.63831 12 10.875 12C12.5292 12 13.875 13.3458 13.875 15V15.8438ZM16.125 12.375H15.375V13.125C15.375 13.3321 15.2073 13.5 15 13.5C14.7927 13.5 14.625 13.3321 14.625 13.125V12.375H13.875C13.6677 12.375 13.5 12.2071 13.5 12C13.5 11.7929 13.6677 11.625 13.875 11.625H14.625V10.875C14.625 10.6679 14.7927 10.5 15 10.5C15.2073 10.5 15.375 10.6679 15.375 10.875V11.625H16.125C16.3323 11.625 16.5 11.7929 16.5 12C16.5 12.2071 16.3323 12.375 16.125 12.375Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_840_13529"
        x="0"
        y="0"
        width="24"
        height="24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_840_13529" />
      </filter>
    </defs>
  </svg>
);
