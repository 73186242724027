import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MessageHockPopUp from 'src/hooks/MessageHockPopUp';
import { AuthApiService } from 'src/services/auth.service';
import { selectResendCodeTime, setTimer } from 'src/store/timer';
import styles from './layoutStyles.module.css';

export default function ResendButton({ token }: { token: string }) {
  const dispatch = useDispatch();
  const time = useSelector(selectResendCodeTime);
  useEffect(() => {
    setTimeout(() => {
      if (time > 0) {
        dispatch(setTimer(time - 1));
      }
    }, 1000);
  }, [time]);

  const resendCode = async () => {
    if (time > 0) return;
    try {
      const resendCodeResp = await AuthApiService.resendCode(token);
      if (resendCodeResp.ok) {
        MessageHockPopUp({
          size: 'small',
          message: 'A new confirmation code was sent!',
          timeOut: 5000,
          type: 'success',
        });
        dispatch(setTimer(60));
      } else {
        MessageHockPopUp({
          size: 'small',
          message: JSON.stringify(resendCodeResp.data),
          timeOut: 5000,
          type: 'error',
        });
      }
    } catch (e) {
      MessageHockPopUp({
        size: 'small',
        message: 'Token is invalid or expired. Please request another confirmation email by signing in',
        timeOut: 5000,
        type: 'error',
      });
    }
  };

  return (
    <div className={styles.resendCodeButton}>
      <span className={time > 0 ? styles.resendCodeTextDisable : styles.resendCodeText} onClick={resendCode}>
        {time > 0 ? `Resend code in ${time} s` : 'Resend code'}
      </span>
    </div>
  );
}
