import React from 'react';
import { HealthOBj } from '../../../types/HealthTypes';
import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './styles.module.css';
import { healthCalories } from 'images/health/healthCalories';
import { healthSteps } from 'images/health/healthSteps';

interface Props {
  dataObjects: Array<HealthOBj>;
  type: 'steps' | 'calories';
}

function HealthMetrics({ dataObjects, type }: Props) {
  let totalValue = 0;
  const targetValue = type === 'calories' ? 5000 : 2300;
  const healthImage = type === 'calories' ? healthCalories : healthSteps;
  const title = type.charAt(0).toUpperCase() + type.slice(1);

  dataObjects.forEach((e) => {
    totalValue += e.value;
  });

  const computePercentage = (value: number) => {
    return Math.min((value / targetValue) * 100, 100);
  };

  const { oldest, latest } = dataObjects.reduce(
    (acc, e) => {
      const date = new Date(e.date);
      if (date < acc.oldest) {
        acc.oldest = date;
      }
      if (date > acc.latest) {
        acc.latest = date;
      }
      return acc;
    },
    { oldest: new Date(dataObjects[0].date), latest: new Date(dataObjects[0].date) },
  );

  const percentage = computePercentage(totalValue);

  return (
    <div className={styles.mainMetrics}>
      <h2>{title}</h2>
      <ProgressBar percentage={Number(percentage.toFixed(2))} />
      <div className={styles.totalMetricsBlock}>
        <div>{healthImage()}</div>
        <div className={styles.totalMetricsValue}>{totalValue.toFixed(0)}</div>
      </div>
      <p className={styles.dateRange}>
        Date Range: {oldest.toISOString().split('T')[0]} to {latest.toISOString().split('T')[0]}
      </p>
    </div>
  );
}

export default HealthMetrics;
