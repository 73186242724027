import { RootState } from '../rootReducer';
import { IDayMoodForAllGroups, IUserMoodOneGroup } from '../../types/Moods';

export const selectAllGroupsWeekMoods = (state: RootState): Array<IDayMoodForAllGroups> => state.moods.allGroupsMoods;
export const selectTodayAverage = (state: RootState): { averageMood: number; groups: number } =>
  state.moods.checkedGroupsToday;
export const selectMyMoodsPerGroup = (
  state: RootState,
): { groupId: string; moods: Array<IUserMoodOneGroup>; average: number } => state.moods.myGroupMoods;
export const selectMyWeeklyAverageMoods = (state: RootState): Array<IUserMoodOneGroup> => state.moods.myWeekAverage;
