import React, { Dispatch, SetStateAction, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import { visibility } from 'images/visibility';
import { visibilityOff } from 'images/visibilityOff';
import {useIsMobile} from "../utils/styles.utils";

export default function PasswordInput({
  value,
  setValue,
  label,
}: {
  value: string;
  label: string;
  setValue: Dispatch<SetStateAction<string>>;
}) {
  const [showPassword, setShowPassword] = useState(false);
    const isMobile = useIsMobile();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      variant="standard"
      margin="normal"
      fullWidth
      label={label}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? visibility(isMobile?'#FFC700':'black') : visibilityOff(isMobile?'#FFC700':'black')}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}