import { useSelector } from 'react-redux';
import { selectNotifications } from 'src/store/notifications/selector';
import NotificationDropDownBlock from '../Notifications/DropDownNotifications';
import { ReactComponent as BellSvg } from 'images/Bell.svg';
import style from './NotifyDropDown.module.css';

export default function NotificationsDropDown() {
  const notifications = useSelector(selectNotifications);
  const filteredNotifications = notifications.filter((notification) => !notification.read);
  return (
    <div className={style.content}>
      {notifications.length ? (
        filteredNotifications.map((notification) => {
          return <NotificationDropDownBlock key={notification.id} notification={notification} />;
        })
      ) : (
        <div className={style.noNewNotification}>
          <BellSvg width={34} height={34} style={{ margin: '0px 0px 16px 0px' }} />
          <span>No new notifications</span>
        </div>
      )}
    </div>
  );
}
