import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Paper } from '../../../types/Paper';

import { dots3Icon } from 'images/dots3Icon';
import { messageTopicIcon } from 'images/messageTopicIcon';
import { shareIcon } from 'images/shareIcon';
import { LikeThumbsUpOutline } from 'images/LikeThumbsUpOutline';
import { User } from '../../../types/User';
import { LikeThumbsUpIcon } from 'images/LikeThumbsUpIcon';
import CommentToolTip from '../../../pages/Article/components/CommentToolTip';
import { SelectedParagraph } from '../../../pages/Article/ViewArticle';
import { createComment, removeArticle } from '../../../store/articles';
import { useDispatch, useSelector } from 'react-redux';
import { useToken } from '../../../cookies';
import { selectUserId } from '../../../store/auth';
import ArticleComments from './ArticleComments';
import { ImagesTemplate } from '../../layout/ImagesTemplate';
import MobileReplyCommentBlock from './components/MobileReplyCommentBlock';
import { checkImage } from '../../../helpers/checkImageUrl';
import { findMyAnonymousNameById } from 'src/helpers/comments';
import { generateRandomNicknameId } from '../../../helpers/anonymousGenerator';
import SendCommentBlock from '../../../helpers/SendCommentBlock';
import MobileFooter from '../Footer/MobileFooter';
import { userNameFromAllParams } from '../../../helpers/userName';
import { useHistory } from 'react-router-dom';
import CostumePopup from '../../layout/CostumePopUp';
import TrashcanIcon from 'images/trashbinIcon';
import MobileHeader from '../Header/MobileHeader';
import ReportIcon from 'images/reportIcon';
import CloseYellowIcon from 'images/closeYellowIcon';
import { REPORT_OPTIONS } from 'src/utils/constants';
import { REPORT_TYPES } from 'src/types/Reports';
import { ReportsApiService } from 'src/services/report.service';
import { v4 as uuid } from 'uuid';
import mobileChatsStyles from '../../MobileChats/MobileChatsStyles.module.css';
import ReactGA from 'react-ga4';
import style from './MobileArticleViewStyles.module.css';

export default function MobileArticleView({
  article,
  isPaperLikedByMe,
  addAddPaperLike,
  removePaperLike,
  changedPaperContent,
  setSelectedParagraph,
  openedParagraph,
  setOpenedParagraph,
  selectedParagraph,
}: {
  article: Paper;
  isPaperLikedByMe: () => User | undefined;
  addAddPaperLike: () => void;
  removePaperLike: () => void;
  changedPaperContent: () => any[];
  openedParagraph: string;
  setOpenedParagraph: Dispatch<SetStateAction<string>>;
  setSelectedParagraph: Dispatch<SetStateAction<SelectedParagraph | undefined>>;
  selectedParagraph: SelectedParagraph | undefined;
}) {
  const myRef = useRef(null);
  const dispatch = useDispatch();
  const { token } = useToken();
  const [isImgAvailable, setIsImgAvailable] = useState(false);
  const [isMessageAnonymous, setIsMessageAnonymous] = useState(false);
  const loggedUserId = useSelector(selectUserId);
  const [isReplyBlockOpen, setIsReplyBlockOpen] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<boolean>(false);
  const ref = useRef(null) as any;
  const [showPopup, setShowPopup] = useState(false);
  const [reportPopup, setReportPopup] = useState<{ firstStep: boolean; secondStep: boolean }>({
    firstStep: false,
    secondStep: false,
  });
  const [report, setReport] = useState<{ report_reason_title: string; report_reason_description: string }>({
    report_reason_title: '',
    report_reason_description: '',
  });
  const history = useHistory();
  const { paper_author } = article;

  const handleClose = () => {
    setReportPopup({ firstStep: false, secondStep: false });
    setShowPopup(false);
    setReport({
      report_reason_title: '',
      report_reason_description: '',
    });
  };

  const clickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, []);

  useEffect(() => {
    const checkImg = async () => {
      if (article.image) {
        const isAvailable = (await checkImage(paper_author?.profile?.image_url || '')) as boolean;
        setIsImgAvailable(isAvailable);
      } else {
        setIsImgAvailable(true);
      }
    };
    checkImg();
    // eslint-disable-next-line
  }, []);

  // @ts-ignore
  const executeScroll = () => myRef?.current?.scrollIntoView();

  const addComment = () => {
    // anonymous_name
    //TODO,,, verify if this name is not already used by another user
    if (commentInput.length && isMessageAnonymous) {
      const randomName: string = findMyAnonymousNameById(article, loggedUserId)
        ? (findMyAnonymousNameById(article, loggedUserId) as string)
        : generateRandomNicknameId();

      dispatch(
        createComment({
          token,
          articleId: article.id,
          comment: commentInput,
          userId: loggedUserId,
          anonymous_name: randomName,
        }),
      );
    }
    if (commentInput.length && !isMessageAnonymous) {
      dispatch(
        createComment({
          token,
          articleId: article.id,
          comment: commentInput,
          userId: loggedUserId,
        }),
      );
    }
    ReactGA.event({
      category: 'User',
      action: 'new_article_comment',
      label: 'Article comment added',
    });
    setCommentInput('');
  };

  //TODO... gif open pop-up logic
  // const handleClick = () => {
  //   setAnchorEl(!anchorEl);
  // };
  const deleteArticle = () => {
    ReactGA.event({
      category: 'User',
      action: 'delete_article',
      label: 'Article was deleted',
    });
    dispatch(removeArticle({ token, id: article.id }));
    window.history.back();
  };

  const reportHandler = (selected: string) => {
    if (!reportPopup.secondStep) {
      setReportPopup((prevState) => ({ ...prevState, secondStep: true }));
    }
    if (!report.report_reason_title) {
      setReport((prevState) => ({ ...prevState, report_reason_title: selected }));
    }
    if (report.report_reason_title) {
      setReport((prevState) => ({ ...prevState, report_reason_description: selected }));
    }
  };

  const submitReportHandler = () => {
    ReactGA.event({
      category: 'User',
      action: 'report_article',
      label: 'User reported article',
    });
    ReportsApiService.createReport(token, {
      ...report,
      report_object_id: article.id,
      report_object_type: REPORT_TYPES.ARTICLE,
    });
    handleClose();
  };

  return (
    <div className={style.mainMobileArticleView}>
      <MobileHeader />
      {article.title && article.id ? (
        <>
          <div className={style.mobArticleViewFunctionality}>
            <div className={style.titleBlock}>
              <span className={style.mobArticleViewTitle}>{article.title}</span>
              <div
                onClick={() => {
                  setShowPopup(true);
                }}
                className={style.mobArticleViewOptions}
              >
                {dots3Icon()}
              </div>

              {showPopup && (
                <CostumePopup
                  style={{
                    width: !reportPopup.firstStep && !reportPopup.secondStep ? '110px' : '85vw',
                    marginRight: '12px',
                    marginTop: '74px',
                    backgroundColor: reportPopup.firstStep || reportPopup.secondStep ? '#fff' : '',
                    color: reportPopup.firstStep || reportPopup.secondStep ? '#000' : '',
                    padding: reportPopup.firstStep || reportPopup.secondStep ? '14px 0' : '14px',
                  }}
                >
                  <div ref={ref}>
                    {/*{loggedUserId === article.paper_author?.id && (*/}
                    <div className={mobileChatsStyles.popupWrapper}>
                      {!reportPopup.firstStep && !reportPopup.secondStep ? (
                        <>
                          <div
                            className={mobileChatsStyles.popUpRow}
                            onClick={() =>
                              setReportPopup((prevState) => ({
                                ...prevState,
                                firstStep: true,
                              }))
                            }
                          >
                            <div>
                              <ReportIcon />
                            </div>
                            <span className={mobileChatsStyles.rowText}>Report</span>
                          </div>
                          {loggedUserId === article.paper_author?.id && (
                            <div onClick={(e) => deleteArticle()} className={mobileChatsStyles.popUpRow}>
                              <div>
                                <TrashcanIcon color="#fff" />
                              </div>
                              <span className={mobileChatsStyles.rowText}>Delete Article</span>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className={mobileChatsStyles.reportHeaderWrapper} style={{ padding: '0 14px' }}>
                            <div className={mobileChatsStyles.reportHeader}>
                              {!reportPopup.secondStep ? 'Flag for:' : 'Describe the problem:'}
                            </div>
                            <div className={mobileChatsStyles.reportHeaderClose} onClick={handleClose}>
                              <CloseYellowIcon />
                            </div>
                          </div>
                          <hr className={mobileChatsStyles.reportDivider} />
                          {reportPopup.firstStep && !reportPopup.secondStep ? (
                            <>
                              {REPORT_OPTIONS.map((option: any, index: number) => {
                                return (
                                  <div key={uuid()} className={mobileChatsStyles.popupWrapper}>
                                    <div
                                      className={mobileChatsStyles.popUpRow}
                                      onClick={() => reportHandler(option.label)}
                                      style={{ padding: '0 14px' }}
                                    >
                                      <div>
                                        <option.icon width={20} height={20} />
                                      </div>
                                      <span>{option.label}</span>
                                    </div>
                                    {index !== REPORT_OPTIONS.length - 1 && (
                                      <hr className={mobileChatsStyles.reportDivider} />
                                    )}
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <div className={mobileChatsStyles.textareaWrapper}>
                                <textarea
                                  cols={30}
                                  rows={10}
                                  placeholder="write a description here"
                                  onChange={(e) => reportHandler(e.target.value)}
                                />
                                <div className={mobileChatsStyles.sendButton} onClick={submitReportHandler}>
                                  Send
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {/*)}*/}
                  </div>
                </CostumePopup>
              )}
            </div>
            <div className={style.mobArticleViewUserBlock}>
              <div
                className={style.userInfo}
                onClick={() => {
                  history.push(`/user/${paper_author?.id}`);
                }}
              >
                <ImagesTemplate
                  url={isImgAvailable ? paper_author?.profile?.image_url : undefined}
                  height={56}
                  width={56}
                />
                <div className={style.nameAndSchool}>
                  {userNameFromAllParams(
                    paper_author?.username,
                    paper_author?.profile?.first_name,
                    paper_author?.profile?.last_name,
                    paper_author?.profile?.nickname,
                  )}

                  <span>{paper_author?.profile?.graduation}</span>
                </div>
              </div>
              <div className={style.likesAndComments}>
                <div className={style.actionBlock} onClick={executeScroll}>
                  {messageTopicIcon()} {article.comments.length}
                </div>
                <div className={style.actionBlock}>
                  {!isPaperLikedByMe() ? (
                    <div onClick={() => addAddPaperLike()}>{LikeThumbsUpOutline('white', '20')}</div>
                  ) : (
                    <div onClick={() => removePaperLike()}>{LikeThumbsUpIcon('white', '20')}</div>
                  )}
                  {article.likes.length}
                </div>
                <div className={style.actionBlock}>{shareIcon()}</div>
              </div>
            </div>
          </div>
          <div className={style.mobArticleViewPictureBlock}>
            <img className={style.mobArticleViewPicture} src={article.image || ''} alt="" />
            {!!article.paper_tags.length && (
              <div className={style.mobArticleViewTags}>
                {article.paper_tags.slice(0, 5).map((tag) => {
                  return (
                    <div key={tag.id} className={style.mobArticleViewTag}>
                      {tag.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className={style.articleContent}>
            {!!article?.content?.length && (
              <div className={style.articleTextContent}>
                {changedPaperContent().map((paragraph) => {
                  return (
                    <div key={paragraph}>
                      <CommentToolTip
                        paragraph={paragraph}
                        setSelectedParagraph={setSelectedParagraph}
                        openedParagraph={openedParagraph}
                        setOpenedParagraph={setOpenedParagraph}
                        setIsReplyBlockOpened={setIsReplyBlockOpen}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            <div style={{ color: 'white' }}>
              {isReplyBlockOpen && selectedParagraph && (
                <MobileReplyCommentBlock
                  repliedText={selectedParagraph.text}
                  setIsReplyBlockOpened={setIsReplyBlockOpen}
                  paragraphId={selectedParagraph.id}
                  setSelectedParagraph={setSelectedParagraph}
                  paragraphComments={true}
                />
              )}
            </div>

            <div ref={myRef}>
              {!selectedParagraph && <ArticleComments comments={[...article.comments].reverse()} />}
            </div>
          </div>
          <div className={style.addCommentMobileInputBlock}>
            <input
              onChange={(e) => setCommentInput(e.target.value)}
              className={style.addCommentMobileInput}
              type="text"
              placeholder="Your comment"
              value={commentInput}
            />
            <SendCommentBlock
              setCommentInput={setCommentInput}
              commentInput={commentInput}
              isMessageAnonymous={isMessageAnonymous}
              setIsMessageAnonymous={setIsMessageAnonymous}
              setTooltipOpen={setTooltipOpen}
              isTooltipOpen={isTooltipOpen}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              addComment={addComment}
            />
          </div>
        </>
      ) : (
        <div style={{ width: '100%', textAlign: 'center', marginTop: '50%', color: 'white' }}>
          Oops, it seems this article has been deleted
          <MobileFooter />
        </div>
      )}
    </div>
  );
}
