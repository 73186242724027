import {useCallback, useEffect} from 'react';
import {useToken} from '../cookies';
import {useDispatch, useSelector} from 'react-redux';
import {addMessageFromEventToChat, getMessagesByChatId, makeConversationAsReadById} from '../store/chats';
import {selectOpenedChat} from '../store/chats/selector';
import {selectUserId} from "../store/auth";
import MessageHockPopUp from "./MessageHockPopUp";

const useChatsSocket = () => {
    const {token} = useToken();
    const dispatch = useDispatch();
    const openedChat = useSelector(selectOpenedChat);
    const myUserId = useSelector(selectUserId);

    const newSocket = useCallback(() => {
        return window.webSocket
    }, [window.webSocket])();

    useEffect(() => {
        if (openedChat) {
            dispatch(getMessagesByChatId({token, chatId: openedChat.id, page: 1}));
        }
    }, [openedChat]);

    useEffect(() => {
        if (newSocket && newSocket.readyState === WebSocket.OPEN && openedChat) {

            if (openedChat.has_unseen_message) {
                newSocket.send(
                    JSON.stringify({type: 'seen', conversation_id: openedChat.id})
                );
                dispatch(makeConversationAsReadById({chatId: openedChat.id, isPeopleChat: !openedChat.group}));
            }
        }
    }, [openedChat])

    const sendMessage = (message: string, chatId: string) => {
        if (message.length && newSocket) {
            if (newSocket.readyState === WebSocket.OPEN) {
                newSocket.send(JSON.stringify({type: 'chat_message', message: message, conversation_id: chatId}));
            } else {
                MessageHockPopUp({
                    message: 'Message not sent. Connection failed! Please try again',
                    timeOut: 3000,
                    type: 'warning',
                    size: 'small'
                });

                //TODO find a better solution
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }
        }
    };

    if (newSocket && newSocket.readyState === WebSocket.OPEN) {
        newSocket.onmessage = function (event) {
            const parsedResult = JSON.parse(event.data);
            dispatch(addMessageFromEventToChat({parsedResult, myUserId}));
        };
    }

    return {sendMessage};
};

export default useChatsSocket;