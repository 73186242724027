import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { copyIcon } from 'images/copyIcon';
import { flegIcon } from 'images/flagIcon';
import UserInfo from '../../../components/User/UserInfo';
import { useToken } from '../../../cookies';
import { addOrRemovePaperLike } from '../../../store/articles';
import { selectSelectedPaper } from '../../../store/articles/selector';
import { selectLoggedUserData } from '../../../store/user/selectors';
import { Paper } from '../../../types/Paper';
import { LikeThumbsUpIcon } from 'images/LikeThumbsUpIcon';
import { LikeThumbsUpOutline } from 'images/LikeThumbsUpOutline';
import { selectUserId } from '../../../store/auth';
import { NO_USER_AVATAR } from '../../../utils/constantImagesUrl';
import styles from './style.module.css';

export default function ViewQuestionHeader({ article }: { article: Paper }) {
  const paper = useSelector(selectSelectedPaper) as Paper;
  const [openPopUpArticleCopied, setOpenPopUpArticleCopied] = useState(false);
  const loggedUser = useSelector(selectLoggedUserData);
  const loggedUserId = useSelector(selectUserId);

  const dispatch = useDispatch();
  const { token } = useToken();

  const showInfoCopiedLink = () => {
    setOpenPopUpArticleCopied(true);
    setTimeout(() => {
      setOpenPopUpArticleCopied(false);
    }, 1500);
  };

  const isQuestionLikedByMe = () => {
    return paper.likes?.find((like) => like.id === loggedUserId);
  };

  const getQuestionLikesNumber = () => {
    return Math.max(0, paper.likes?.length || 0);
  };

  const getPaperAnswers = () => {
    return paper.comments.filter((comment) => !comment.repliedToCommentId).length || 0;
  };

  const removeLikeFromQuestion = () => {
    dispatch(
      addOrRemovePaperLike({
        author: loggedUser,
        articleId: paper.id,
        token,
        isAddLike: false,
      }),
    );
  };
  const addLikeToQuestion = () => {
    dispatch(
      addOrRemovePaperLike({
        author: loggedUser,
        articleId: paper.id,
        token,
        isAddLike: true,
      }),
    );
  };

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  return (
    <div className={styles.questionModalHeader}>
      {openPopUpArticleCopied && <div className={styles.popUpInfo}>Paper link copied</div>}
      <div className={styles.headerTopBlock}>
        <div className={styles.headerTopBlockIcon}>{flegIcon()}</div>
        <span className={styles.headerTopBlockHelpLabel}>#need_help</span>
        <span className={styles.headerTopBlockAnswersNumber}>{`(${getPaperAnswers()} ${
          getPaperAnswers() === 1 ? 'answer' : 'answers'
        })`}</span>
      </div>
      <div className={styles.headerMiddleBlock}>
        <UserInfo
          picture={article.paper_author?.profile?.image_url || NO_USER_AVATAR}
          nickname={article.paper_author?.username}
          firstName={article.paper_author?.profile?.first_name}
          lastName={article.paper_author?.profile?.last_name}
          size={'56px'}
          graduation={article.paper_author?.profile?.graduation || ' '}
          isMyPage={paper.paper_author?.id === loggedUser.id}
        />
        {/* TODO */}
        {/* <div className={styles.headerMiddleBlockFollowBtn}>+ Follow</div> */}
      </div>
      <div className={styles.headerQuestionLabel}>{article.description}</div>
      <div className={styles.headerBottomBlock}>
        <div className={styles.headerBottomBlockLeft}></div>
        <div className={styles.headerBottomBlockRight}>
          <div
            className={styles.headerBottomBlockRightCopy}
            onClick={() => {
              copyTextToClipboard(`${window.location.origin}/question/${article.id}`);
              showInfoCopiedLink();
            }}
          >
            {copyIcon()}
            <span>Copy link</span>
          </div>

          <div className={styles.headerBottomBlockRightLikes}>
            {isQuestionLikedByMe() ? (
              <div className={styles.headerBottomBlockRightLikeUp} onClick={() => removeLikeFromQuestion()}>
                {LikeThumbsUpIcon()}
              </div>
            ) : (
              <div onClick={() => addLikeToQuestion()} className={styles.headerBottomBlockRightLikeUp}>
                {LikeThumbsUpOutline()}
              </div>
            )}

            <div className={styles.headerBottomBlockRightLikeCount}>{getQuestionLikesNumber()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
