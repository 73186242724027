import React from 'react';
import styles from '../landingStyles.module.css';

function Contact() {
  return (
    <div id="contact" className={styles.contactMain}>
      <p>
        If you or someone you know is in crisis, call or text the <b>988</b> Suicide & Crisis Lifeline at <b>988</b>.
        <br/>
        The Lifeline provides 24-hour, confidential support. Call <b>911</b> in life-threatening situations.
      </p>
      {/*<div className={styles.blockSubtitle}>Stay In Touch</div>*/}
      {/*<p className={styles.blockParagraph}>Enter your email address to register to our newsletter subscription!</p>*/}
      {/*<div className={styles.underScoreLine} />*/}
      {/*<input className={styles.emailInput} type="email" placeholder={'Enter email address'} />*/}
      {/*<div style={{ margin: '20px auto' }} className={styles.landingButton}>*/}
      {/*  Subscribe*/}
      {/*</div>*/}
      {/*<div className={styles.contactForm}>*/}
      {/*  <div className={styles.blockAlignedTitle}>Get In Touch</div>*/}
      {/*  <div style={{ marginLeft: 0, marginBottom: '50px' }} className={styles.underScoreLine} />*/}
      {/*  <input className={styles.contactInput} type="text" placeholder={'Enter your name'} />*/}
      {/*  <input className={styles.contactInput} type="email" placeholder={'Enter email address'} />*/}
      {/*  <textarea*/}
      {/*    className={`${styles.contactInput} ${styles.contactArea}`}*/}
      {/*    name="message"*/}
      {/*    id="message"*/}
      {/*    placeholder={'Enter your message'}*/}
      {/*  ></textarea>*/}
      {/*  <div style={{ margin: '20px auto' }} className={styles.landingButton}>*/}
      {/*    Send Message*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}

export default Contact;