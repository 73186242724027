export const visibility = (color='black') => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 10.5C9.10457 10.5 10 9.60457 10 8.5C10 7.39543 9.10457 6.5 8 6.5C6.89543 6.5 6 7.39543 6 8.5C6 9.60457 6.89543 10.5 8 10.5Z"
      fill={color}
    />
    <path
      d="M15.3394 7.95625C14.5125 6.6775 13.4397 5.59125 12.2372 4.81469C10.9069 3.95469 9.43813 3.5 7.99 3.5C6.66125 3.5 5.35469 3.87969 4.10656 4.62844C2.83375 5.39188 1.68062 6.50719 0.679061 7.94312C0.565994 8.10541 0.503723 8.29764 0.500162 8.4954C0.496601 8.69315 0.55191 8.8875 0.659061 9.05375C1.48437 10.3453 2.54656 11.4331 3.73031 12.1991C5.06313 13.0625 6.4975 13.5 7.99 13.5C9.44969 13.5 10.9216 13.0491 12.2463 12.1962C13.4481 11.4222 14.5188 10.3319 15.3425 9.0425C15.446 8.88012 15.5007 8.69146 15.5001 8.49892C15.4996 8.30638 15.4438 8.11804 15.3394 7.95625V7.95625ZM8.00063 11.5C7.40728 11.5 6.82726 11.3241 6.33392 10.9944C5.84057 10.6648 5.45605 10.1962 5.22899 9.64805C5.00192 9.09987 4.94251 8.49667 5.05827 7.91473C5.17403 7.33279 5.45975 6.79824 5.87931 6.37868C6.29886 5.95912 6.83341 5.6734 7.41536 5.55764C7.9973 5.44189 8.6005 5.5013 9.14868 5.72836C9.69686 5.95542 10.1654 6.33994 10.495 6.83329C10.8247 7.32664 11.0006 7.90666 11.0006 8.5C10.9997 9.29537 10.6834 10.0579 10.1209 10.6203C9.55853 11.1827 8.796 11.4991 8.00063 11.5V11.5Z"
      fill={color}
    />
  </svg>
);
