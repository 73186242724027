export const duckIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1656 8.45219C18.7061 8.45219 19.1565 8.01682 19.1565 7.46135V6.87585C19.1565 6.33539 18.7211 5.88501 18.1656 5.88501C17.6252 5.88501 17.1748 6.32038 17.1748 6.87585V7.46135C17.1748 8.01682 17.6252 8.45219 18.1656 8.45219Z"
      fill="#333333"
    />
    <path
      d="M11.275 8.45219C11.8155 8.45219 12.2659 8.01682 12.2659 7.46135V6.87585C12.2659 6.33539 11.8305 5.88501 11.275 5.88501C10.7346 5.88501 10.2842 6.32038 10.2842 6.87585V7.46135C10.2992 8.01682 10.7346 8.45219 11.275 8.45219Z"
      fill="#333333"
    />
    <path
      d="M26.3173 11.0344L24.831 10.0885C24.0053 9.5631 23.4649 8.69237 23.3598 7.71654C22.8644 3.31781 19.1562 0 14.7425 0C10.3288 0 6.60561 3.31781 6.1252 7.71654C6.02011 8.69237 5.47964 9.5631 4.65394 10.0885L3.16768 11.0344C1.186 12.2954 0 14.4422 0 16.7842C0 20.5374 3.0626 23.6 6.81578 23.6H7.32622C7.17609 24.6359 7.32621 25.7018 7.80662 26.6626C8.67735 28.4191 10.4489 29.5 12.4005 29.5H17.0995C19.0662 29.5 20.8226 28.4041 21.6934 26.6626C22.1738 25.7168 22.3239 24.6359 22.1738 23.6H22.6842C26.4374 23.6 29.5 20.5374 29.5 16.7842C29.47 14.4422 28.284 12.2954 26.3173 11.0344ZM5.67482 11.755C7.01095 10.8992 7.88168 9.51807 8.04682 7.94173C8.42213 4.53384 11.2896 1.98168 14.7125 1.98168C18.1354 1.98168 21.0028 4.54885 21.3781 7.94173C21.5583 9.51807 22.414 10.8992 23.7501 11.755L24.5008 12.2354C22.6092 13.0611 20.7776 14.0369 19.0511 15.1779L18.7659 14.4122C18.6608 14.142 18.4356 13.9168 18.1654 13.8267C17.8802 13.7366 17.5799 13.7667 17.3247 13.9318C15.7333 14.9226 13.6916 14.9226 12.1003 13.9318C11.845 13.7817 11.5448 13.7366 11.2595 13.8267C10.9743 13.9168 10.7641 14.127 10.659 14.4122L10.3738 15.1779C8.63231 14.0519 6.81578 13.0611 4.92418 12.2354L5.67482 11.755ZM7.91172 21.6333H6.78576C4.1135 21.6333 1.93664 19.4565 1.93664 16.7842C1.93664 15.5982 2.37202 14.4873 3.12265 13.6165C5.4196 14.5323 7.59645 15.6733 9.65319 17.0545L7.91172 21.6333ZM19.9069 25.7768C19.3664 26.8578 18.2705 27.5333 17.0695 27.5333H12.3705C11.1545 27.5333 10.0735 26.8578 9.53308 25.7768C9.14275 24.9812 9.09772 24.0504 9.41299 23.2247L12.1153 16.1237C13.7817 16.7992 15.6583 16.7992 17.3247 16.1237L20.027 23.2247C20.3573 24.0654 20.2972 24.9812 19.9069 25.7768ZM22.6542 21.6333H21.5282L19.7718 17.0394C21.8285 15.6733 24.0204 14.5173 26.3023 13.6015C27.0529 14.4723 27.4883 15.5832 27.4883 16.7692C27.5033 19.4565 25.3265 21.6333 22.6542 21.6333Z"
      fill="#333333"
    />
    <path
      d="M16.694 19.0813C16.1535 19.0813 15.7031 19.5167 15.7031 20.0721V20.6576C15.7031 21.1981 16.1385 21.6485 16.694 21.6485C17.2344 21.6485 17.6848 21.2131 17.6848 20.6576V20.0721C17.6698 19.5167 17.2344 19.0813 16.694 19.0813Z"
      fill="#333333"
    />
    <path
      d="M12.7613 19.0813C12.2209 19.0813 11.7705 19.5167 11.7705 20.0721V20.6576C11.7705 21.1981 12.2059 21.6485 12.7613 21.6485C13.3018 21.6485 13.7522 21.2131 13.7522 20.6576V20.0721C13.7372 19.5167 13.3018 19.0813 12.7613 19.0813Z"
      fill="#333333"
    />
  </svg>
);
