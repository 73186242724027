import { IGroup } from 'src/types/Group';
import { RootState } from '../rootReducer';

export const selectGroups = (state: RootState): Array<IGroup> =>
  Object.values(state.groups.groups)
    .filter((g) => g.name)
    .sort(function (a: IGroup, b) {
      return Number(new Date(b.creation_date)) - Number(new Date(a.creation_date));
    });
export const selectFullGroup = (state: RootState): IGroup => state.groups.selectedFullGroup;
export const selectPinnedGroups = (state: RootState): IGroup[] =>
  Object.values(state.groups.pinnedGroups).sort(function (a: IGroup, b) {
    return Number(new Date(b.creation_date)) - Number(new Date(a.creation_date));
  });
export const selectGroupWeekMoods = (state: RootState): Array<{ name: string; pv: unknown }> => state.groups.weekMoods;
export const selectIsLoadingGroups = (state: RootState): boolean => state.groups.isLoading;
export const selectGroupsHasNextPage = (state: RootState): boolean => !!state.groups.next;
export const selectPinnedGroupsHasNextPage = (state: RootState): boolean => !!state.groups.pinnedNextPage;
export const selectGroupsPage = (state: RootState): number => state.groups.page;
export const selectPinnedGroupsPage = (state: RootState): number => state.groups.pinnedGroupsPage;
export const selectAllGroupsName = (state: RootState): Array<{ [key: string]: string }> => state.groups.allGroupsName;
export const selectRecentGroupId = (state: RootState): string => state.groups.recentGroup;
export const selectGroupById =
  (id: string) =>
  (state: RootState): IGroup | undefined =>
    state.groups.groups[id] || state.groups.pinnedGroups[id];
