import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { MyProfile as myProfImage } from 'images/users/MyProfile';
import Dialog from '@mui/material/Dialog';
import { useIsMobile } from '../../../../utils/styles.utils';
import { ThemeProvider } from '@mui/material';
import { SettingsIcon } from 'images/Settings';
import { clearUserState } from '../../../../store/user';
import { clearNotificationsState } from '../../../../store/notifications';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useRefreshToken, useToken } from '../../../../cookies';
import { LogOutIcon } from 'images/LogOut';
import { questionIcon } from 'images/questionIcon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { darkTheme, lightTheme } from '../../../../themes/themes';
import { selectUserId } from '../../../../store/auth';
import { partnersIcon } from 'images/partnersIcon';
import { CONTACT, HEALTH, PARTNERS, SETTINGS_ROUTE } from '../../../../utils/constants';
import ReactGA from 'react-ga4';
import style from '../../../User/UserStyles.module.css';
import mobileHeaderStyles from '../MobileHeaderStyles.module.css';
import { healthMenu } from 'images/health/healthMenu';
import { getHealthInfo } from '../../../../store/health/reducer';

export default function MyProfile({
  closeDialog,
  setCloseDialog,
  isGroupPage,
}: {
  closeDialog: boolean;
  setCloseDialog: Dispatch<SetStateAction<boolean>>;
  isGroupPage?: boolean;
}) {
  const loggedUserId = useSelector(selectUserId);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const history = useHistory();
  const { removeToken } = useToken();
  const { removeRefreshToken } = useRefreshToken();
  const { token } = useToken();

  const logout = () => {
    dispatch(clearUserState());
    dispatch(clearNotificationsState());
    removeToken();
    removeRefreshToken();

    ReactGA.event({
      category: 'User',
      action: 'Logout',
      label: 'Users logout from app',
    });
    history.push('/login');
  };

  const handleClose = (e: any) => {
    setCloseDialog(false);
  };

  return (
    <div>
      <ThemeProvider theme={isMobile ? darkTheme : lightTheme}>
        <Dialog
          onClose={handleClose}
          open={closeDialog}
          className={isGroupPage ? mobileHeaderStyles.myProfileGroupDialog : mobileHeaderStyles.myProfileDialog}
        >
          <List sx={{ pt: 0 }}>
            <ListItem button onClick={() => history.push(`/user/${loggedUserId}`)}>
              <div className={style.dropDownListIcon}>{myProfImage()}</div>
              My profile
            </ListItem>
            <ListItem onClick={() => history.push(SETTINGS_ROUTE)} button>
              <div className={style.dropDownListIcon}>{SettingsIcon()}</div>
              Settings
            </ListItem>
            <ListItem button onClick={(e) => history.push(CONTACT)}>
              <div className={style.dropDownListIcon}>{questionIcon()}</div>
              FAQ
            </ListItem>
            <ListItem button onClick={(e) => history.push(PARTNERS)}>
              <div className={style.dropDownListIcon}>{partnersIcon()}</div>
              Resources
            </ListItem>
            {/*<ListItem*/}
            {/*  button*/}
            {/*  onClick={() => {*/}
            {/*    dispatch(getHealthInfo({ token, author_id: loggedUserId }));*/}
            {/*    history.push(HEALTH);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <div className={style.dropDownListIcon}>{healthMenu()}</div>*/}
            {/*  Health*/}
            {/*</ListItem>*/}
            <ListItem button onClick={() => logout()}>
              <div className={style.dropDownListIcon}>{LogOutIcon()}</div>
              Sign out
            </ListItem>
          </List>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
