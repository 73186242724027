import React, { ReactElement } from 'react';

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  strokeFill?: string;
};
const MegafonIcon: React.FC<Props> = ({
  width = 16,
  height = 16,
  viewBox = '0 0 14 14',
  strokeFill = '#EC6161',
}): ReactElement => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.64459 4.92586V9.05781M11.2256 2.21181L8.0297 4.80686H2.44661C2.29206 4.80738 2.14399 4.869 2.0347 4.97828C1.92542 5.08757 1.86379 5.23564 1.86328 5.3902V8.63586C1.86379 8.79041 1.92542 8.93849 2.0347 9.04778C2.14399 9.15706 2.29206 9.21868 2.44661 9.2192H8.0295L11.2258 11.7719L11.2256 2.21181Z"
        stroke={strokeFill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.20009 10.0032V11.7532C5.20009 11.8563 5.15911 11.9553 5.08618 12.0282C5.01325 12.1011 4.91434 12.1421 4.8112 12.1421H4.03342C3.93028 12.1421 3.83137 12.1011 3.75843 12.0282C3.6855 11.9553 3.64453 11.8563 3.64453 11.7532V10.0032M11.9792 6.20414C12.1418 6.28449 12.2787 6.40872 12.3743 6.56278C12.47 6.71684 12.5207 6.8946 12.5207 7.07596C12.5206 7.25731 12.4699 7.43505 12.3741 7.58907C12.2784 7.74309 12.1414 7.86725 11.9788 7.94753"
        stroke={strokeFill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default MegafonIcon;