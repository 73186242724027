export const questionIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.79279 10.1209C7.79094 10.1209 7.7891 10.1209 7.78725 10.1209C7.4475 10.1178 7.17422 9.83972 7.1773 9.49947L7.18007 9.19155C7.18007 9.17399 7.18099 9.15613 7.18284 9.13858C7.26809 8.24283 7.86234 7.69381 8.34027 7.25255C8.50214 7.1029 8.6554 6.96156 8.78588 6.81407C8.94499 6.63455 9.17579 6.26689 8.93298 5.82317C8.65324 5.31079 7.97005 5.16576 7.43919 5.28739C6.88464 5.41425 6.68029 5.88814 6.60767 6.15912C6.51965 6.48767 6.18206 6.68259 5.85369 6.59483C5.52533 6.50676 5.33053 6.16897 5.41823 5.84042C5.66351 4.92373 6.29992 4.28479 7.16407 4.08679C8.32703 3.82106 9.49831 4.29125 10.0126 5.23196C10.4406 6.01532 10.3234 6.93447 9.70635 7.63099C9.53493 7.82437 9.35182 7.99372 9.17456 8.15723C8.73295 8.56523 8.4569 8.83805 8.41013 9.23188L8.40766 9.50994C8.4052 9.84896 8.13008 10.1209 7.79279 10.1209V10.1209Z"
      fill="#EDEFF0"
    />
    <path
      d="M7.79187 11.9674C7.63175 11.9674 7.47163 11.9027 7.3577 11.7888C7.24069 11.6748 7.17603 11.5147 7.17603 11.3515C7.17603 11.1914 7.24069 11.0313 7.3577 10.9174C7.58556 10.6895 7.99818 10.6895 8.22604 10.9174C8.34305 11.0313 8.40772 11.1914 8.40772 11.3515C8.40772 11.5147 8.34306 11.6718 8.22912 11.7888C8.11211 11.9027 7.95507 11.9674 7.79187 11.9674Z"
      fill="#EDEFF0"
    />
    <path
      d="M7.97802 16C7.2779 16 6.58394 15.9089 5.91521 15.7287C3.03349 14.9531 0.77711 12.5645 0.166853 9.64323C-0.455099 6.66715 0.692788 3.53865 3.09166 1.67295C4.47835 0.594292 6.22727 0 8.01649 0C9.25516 0 10.4852 0.288216 11.574 0.832932C14.2628 2.17886 16 4.99082 16 7.99707C16 10.45 14.8432 12.8142 12.9056 14.3212C11.514 15.4039 9.76386 16 7.97802 16V16ZM8.0168 1.23169C6.49993 1.23169 5.01906 1.73361 3.84748 2.64537C1.81729 4.2244 0.845429 6.87223 1.37198 9.39104C1.88838 11.8621 3.79731 13.883 6.23527 14.5392C6.79967 14.691 7.38623 14.7683 7.97802 14.7683H7.97833C9.49182 14.7683 10.9733 14.2642 12.1504 13.3488C13.7904 12.0734 14.7693 10.0728 14.7693 7.99707C14.7693 5.45332 13.2992 3.07369 11.0238 1.93468C10.1048 1.47495 9.06528 1.23169 8.0168 1.23169V1.23169Z"
      fill="#EDEFF0"
    />
  </svg>
);
