import { RootState } from '../rootReducer';
import { ChatListPreview } from '../../types/User';
import { Message } from '../../types/Chat';

export const selectPeopleChats = (state: RootState): { [key: string]: ChatListPreview } => state.chats.peopleChats;
export const selectGroupsChats = (state: RootState): { [key: string]: ChatListPreview } => state.chats.groupChats;
export const selectChatMessages = (state: RootState): Array<Message> => state.chats.messages;
export const selectOpenedChat = (state: RootState): ChatListPreview | null => state.chats.openedChat;
export const selectPeopleChatsHasNext = (state: RootState): boolean => state.chats.peopleChatsHasNext;
export const selectGroupsChatsHasNext = (state: RootState): boolean => state.chats.groupsChatsHasNext;
export const selectOpenedMessagesHasNext = (state: RootState): boolean => state.chats.openedMessagesHasNextPage;
export const selectChatsAreLoading = (state: RootState): boolean => state.chats.isLoading;
export const selectMessagesAreLoading = (state: RootState): boolean => state.chats.isMessagesLoading;
export const selectIsNewMessageInChats = (state: RootState): boolean => state.chats.isNewMessageInChats;
export const selectChatsCount = (state: RootState): number => state.chats.count;

