import NavigationNavbar from '../../components/Header/NavigationHeader';
import { Wrapper } from '../../components/layout/Wrapper';
import UserInfo from '../../components/User/UserInfo';
import { UserArticlesTabList } from '../../components/User/UserArticlesTabs';
import { useIsMobile } from '../../utils/styles.utils';
import MainFooter from '../../components/Footer/MainFooter';
import ProfileTabs from '../MyProfileMobile/Components/ProfileTabs';
import UserHeaderInfo from '../MyProfileMobile/UserHeaderInfo';
import { useEffect, useState } from 'react';
import { User } from '../../types/User';
import { UsersApiService } from '../../services/users.service';
import { useToken } from '../../cookies';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../store/auth';

export default function UserPage() {
  const [userById, setUserById] = useState<User>();
  const isMobile = useIsMobile();
  const { token } = useToken();
  const { id } = useParams() as any;
  const loggedUserId = useSelector(selectUserId);

  useEffect(() => {
    UsersApiService.getUser(token, id).then((response) => {
      setUserById(response.data);
    });
  }, []);

  return (
    <div style={{ backgroundColor: 'black', height: '100vh' }}>
      {!isMobile && <NavigationNavbar />}
      <Wrapper>
        <div>
          {isMobile ? (
            <>
              <UserHeaderInfo user={userById} />
              {userById && loggedUserId === id && <ProfileTabs />}
            </>
          ) : (
            <>
              <UserInfo isMyPage={true} />
              <UserArticlesTabList />
              <MainFooter />
            </>
          )}
        </div>
      </Wrapper>
    </div>
  );
}
