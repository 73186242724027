import { GraphPeriods } from '../../../../utils/enums';
import { thisMonthFromToDate, thisWeekFromToDateISO, todayFromToDate } from '../../../../helpers/date';

export const fromTo = (selectedPeriod: GraphPeriods) => {
  let from, to;
  if (selectedPeriod === GraphPeriods.LAST_24_HOURS) {
    from = todayFromToDate().from;
    to = todayFromToDate().to;
  }
  if (selectedPeriod === GraphPeriods.LAST_WEEK) {
    from = thisWeekFromToDateISO().from;
    to = thisWeekFromToDateISO().to;
  }
  if (selectedPeriod === GraphPeriods.LAST_MONTH) {
    from = thisMonthFromToDate().from;
    to = thisMonthFromToDate().to;
  }
  return { from, to };
};

export function transformDate(dateString = '') {
  const datePart = dateString.split('T')[0];
  const timePart = dateString.split('T')[1].split(':').slice(0, 2).join(':');

  const year = datePart.split('-')[0].slice(-2);
  const month = datePart.split('-')[1];
  const day = datePart.split('-')[2];

  return `${day}-${month}-${year} [ ${timePart} ]`;
}