import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.css';

import MobileLoaderScreen from '../../../../components/layout/MobileLoaderScreen';
import { useToken } from '../../../../cookies';
import scrollSmoothTo from '../../../../helpers/ScrollingToSmooth';
import { selectUserId } from '../../../../store/auth';
import { getMessagesByChatId, MESSAGES_PER_PAGE } from '../../../../store/chats';
import {
  selectChatMessages,
  selectMessagesAreLoading,
  selectOpenedMessagesHasNext,
} from '../../../../store/chats/selector';
import { Message } from '../../../../types/Chat';
import { ChatListPreview } from '../../../../types/User';

const foreignMessage = (text: string, date: string) => {
  return (
    <div className={styles.myMessageBlock}>
      {text}
      <div className={styles.msgDate}>{format(new Date(date), 'HH:mm')}</div>
    </div>
  );
};

const myMessage = (text: string, date: string) => {
  return (
    <div className={styles.foreignMessageBlock}>
      {text}
      <div className={styles.msgDate}>{format(new Date(date), 'HH:mm')}</div>
    </div>
  );
};

const itemContent = (message: Message, loggedUserId: string) => {
  return (
    <div>
      {message.author === loggedUserId
        ? myMessage(message.message, message.creation_date)
        : foreignMessage(message.message, message.creation_date)}
    </div>
  );
};

function ConversationsList({ openedChat }: { openedChat: ChatListPreview }) {
  const [scrollDelay, setScrollDelay] = useState(false);
  const [page, setPage] = useState(2);
  const [initialScrollHeight, setInitialScrollHeight] = useState(0);
  const hasNext = useSelector(selectOpenedMessagesHasNext);
  const loggedUserId = useSelector(selectUserId);
  const isLoading = useSelector(selectMessagesAreLoading);
  const dispatch = useDispatch();
  const { token } = useToken();
  const scrollContainer = useRef(null) as any;
  const chatMsgRef = useRef([]) as any;
  const chatMessages = useSelector(selectChatMessages);

  useEffect(() => {
    if (chatMessages.length > 0) {
      const previousChatMessages = chatMsgRef.current;
      const newChatMessages = chatMessages;
      chatMsgRef.current = newChatMessages;

      //when open page scroll to the bottom
      if (chatMessages.length <= MESSAGES_PER_PAGE) {
        scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight;
        setPage(2);
        return setInitialScrollHeight(scrollContainer.current.scrollHeight);
      }
      if (previousChatMessages.length + 1 === newChatMessages.length) {
        // When new message is coming is scrolling to the bottom
        return scrollSmoothTo(scrollContainer, scrollContainer.current.scrollHeight);
      }
      // Maintain in the same ui position each time new items are fetched
      if (newChatMessages.length > MESSAGES_PER_PAGE && previousChatMessages.length < newChatMessages.length) {
        scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight - initialScrollHeight;
        return setInitialScrollHeight(scrollContainer.current.scrollHeight);
      }
    }
  }, [chatMessages]);

  const onScroll = () => {
    if (scrollContainer.current && !scrollDelay && hasNext) {
      const { scrollTop } = scrollContainer.current;
      if (scrollTop === 0) {
        setScrollDelay(true);
        setTimeout(() => {
          dispatch(getMessagesByChatId({ token, chatId: openedChat.id, page }));
          setPage((page) => page + 1);
          setScrollDelay(false);
        }, 800);
      }
    }
  };

  return (
    <>
      {isLoading && <MobileLoaderScreen />}
      <div onScroll={() => onScroll()} className={styles.mainConvList} ref={scrollContainer}>
        {chatMessages?.length
          ? chatMessages.map((message) => {
              return itemContent(message, loggedUserId);
            })
          : ''}
      </div>
    </>
  );
}

export default ConversationsList;
