import { useState } from 'react';
import { css } from 'styled-components/macro';
import { ReactComponent as SearchSvg } from 'images/Search.svg';
import { useDispatch } from 'react-redux';
import { searchArticles } from '../../store/articles';
import { useHistory } from 'react-router-dom';
import { useToken } from '../../cookies';
import style from './SearchBarStyle.module.css';

export default function SearchBar() {
  const { token } = useToken();
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const getSearchedValue = () => {
    if (searchValue.length) {
      dispatch(searchArticles({ token, param: searchValue }));
      history.push({ pathname: '/search', state: searchValue });
      setSearchValue('');
    }
  };

  return (
    <div className={style.mainSearchBar}>
      <input
        placeholder="Search for papers"
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            getSearchedValue();
          }
        }}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        value={searchValue}
        className={style.searchInput}
      />
      <div
        onClick={() => getSearchedValue()}
        css={css`
          margin: 0 0 0 auto;
        `}
      >
        <SearchSvg style={{ cursor: 'pointer' }} width={22} height={22} />
      </div>
    </div>
  );
}
