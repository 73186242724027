import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMyArticles, selectPapers } from '../../store/articles/selector';
import ArticleGrid from '../Article/ArticleGrid/ArticleGrid';
import { getMyArticles } from '../../store/articles';
import MyArticlesTab from './components/MyArticlesTab';
import { useToken } from '../../cookies';
import { selectUserId } from '../../store/auth';
import MyNotificationsTab from '../Notifications/MyNotificationsTab';
import style from './UserStyles.module.css';

export const UserArticlesTabList = () => {
  const dispatch = useDispatch();
  const { token } = useToken();

  const loggedUser = useSelector(selectUserId);
  const myArticles = useSelector(selectMyArticles);

  useEffect(() => {
    if (loggedUser) {
      dispatch(getMyArticles({ token, userId: loggedUser }));
    }
  }, [loggedUser]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(1);
  const papers = useSelector(selectPapers);

  return (
    <div className={style.mainContainer}>
      <Tabs>
        <TabList className={style.tabList}>
          <Tab
            className={selectedTabIndex === 1 ? style.selectedTable : style.notSelectedTable}
            onClick={() => setSelectedTabIndex(1)}
          >
            My Publications
          </Tab>

          <Tab
            className={selectedTabIndex === 2 ? style.selectedTable : style.notSelectedTable}
            onClick={() => setSelectedTabIndex(2)}
          >
            Latest read
          </Tab>
          <Tab
            className={selectedTabIndex === 3 ? style.selectedTable : style.notSelectedTable}
            onClick={() => setSelectedTabIndex(3)}
          >
            BookMarks
          </Tab>
          <Tab
            className={selectedTabIndex === 4 ? style.selectedTable : style.notSelectedTable}
            onClick={() => setSelectedTabIndex(4)}
          >
            Subscriptions
          </Tab>
          <Tab
            className={selectedTabIndex === 5 ? style.selectedTable : style.notSelectedTable}
            onClick={() => setSelectedTabIndex(5)}
          >
            Notifications
          </Tab>
        </TabList>

        <TabPanel>
          <h2>My Publications</h2>
          <hr />
          {myArticles && <MyArticlesTab articles={[...(myArticles || [])].reverse()} />}
        </TabPanel>
        <TabPanel>
          <h2>Latest read</h2>
          <hr />
          {papers && <ArticleGrid articles={[...papers].reverse()} />}
        </TabPanel>
        <TabPanel>
          <h2>BookMarks</h2>
          <hr />
          {papers && <ArticleGrid articles={papers} />}
        </TabPanel>
        <TabPanel>
          <h2>Subscriptions</h2>
          <hr />
          {papers && <ArticleGrid articles={papers} />}
        </TabPanel>
        <TabPanel>
          <h2>Notifications</h2>
          <hr />
          {papers && <MyNotificationsTab />}
        </TabPanel>
      </Tabs>
    </div>
  );
};
