export const blackWhiteLogo = (w='254',h='190') => (
  <svg width={w} height={h} viewBox="0 0 350 350" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_31_561)">
      <path
        d="M263.819 176.069L337.688 133.858C346.482 127.703 350 121.547 350 108.356V42.401C350 33.6063 340.327 25.6903 331.533 30.0895L206.658 102.2C193.467 109.235 175.879 124.185 175.879 142.653V273.683C175.879 244.288 196.985 208.233 263.819 176.069Z"
        fill="url(#paint0_linear_31_561)"
      />
      <path
        d="M87.9397 176.069V258.733C87.9397 269.286 87.9397 271.045 98.4925 280.718L155.653 314.135C163.782 318.261 169.844 320.419 175.879 320.416V273.683V142.653C175.892 124.257 160.05 110.994 145.1 102.2L19.3467 30.0895C63.3166 55.3031 87.9397 88.13 87.9397 176.069Z"
        fill="url(#paint1_linear_31_561)"
      />
      <path
        d="M19.3467 30.0895C10.5528 26.5722 0.879397 32.7283 0.879397 42.4013L0 110.994C0.879397 120.668 5.27638 125.944 10.5528 130.341L87.9397 176.069C87.9397 88.13 63.3166 55.3031 19.3467 30.0895Z"
        fill="url(#paint2_linear_31_561)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_31_561"
        x1="353.518"
        y1="70.1676"
        x2="174.121"
        y2="188.886"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CFCFCF" />
        <stop offset="1" stopColor="#F8F9FA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_31_561"
        x1="175"
        y1="28.8359"
        x2="175"
        y2="320.416"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.145095" stopColor="#F8F9FA" />
        <stop offset="1" stopColor="#CED4DA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_31_561"
        x1="77.3869"
        y1="89.5143"
        x2="-5.1002e-06"
        y2="122.931"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADB5BD" />
        <stop offset="1" stopColor="#F8F9FA" />
      </linearGradient>
      <clipPath id="clip0_31_561">
        <rect width="350" height="350" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
