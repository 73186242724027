export const GroupIcon = () => (
  <svg width="20" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.524 21.4116H17.8V17.4686C17.7795 16.9096 17.6403 16.3613 17.3915 15.8603C17.1427 15.3592 16.79 14.9169 16.357 14.5626L15.228 13.5566L16.728 13.7256C19.46 14.0356 21.52 15.6446 21.52 17.4686L21.524 21.4116ZM18.761 20.4506H20.561V17.4686C20.561 16.4616 19.486 15.4806 17.984 14.9796C18.4808 15.7154 18.7503 16.5809 18.759 17.4686L18.761 20.4506Z"
      fill="white"
    />
    <path
      d="M15.9459 9.85273C15.3568 9.85403 14.7803 9.68226 14.2879 9.35873L13.9409 9.13073L14.1159 8.75473C14.3631 8.23785 14.4914 7.67218 14.4914 7.09923C14.4914 6.52628 14.3631 5.96061 14.1159 5.44373L13.9409 5.06773L14.2869 4.83973C14.7792 4.51618 15.3558 4.34471 15.9449 4.34673C16.6962 4.32654 17.4248 4.60495 17.9712 5.12097C18.5176 5.63699 18.8372 6.34854 18.8599 7.09973C18.8592 7.46009 18.7853 7.81656 18.6426 8.14748C18.4999 8.47839 18.2915 8.77685 18.0299 9.02473C17.4696 9.56154 16.7219 9.8586 15.9459 9.85273ZM15.1619 8.73873C15.5296 8.88527 15.9312 8.92491 16.3205 8.85307C16.7097 8.78124 17.0707 8.60086 17.3619 8.33273C17.5304 8.17437 17.665 7.98342 17.7575 7.77146C17.85 7.55951 17.8984 7.33098 17.8999 7.09973C17.8771 6.60373 17.6588 6.13694 17.2929 5.80134C16.9269 5.46575 16.443 5.28865 15.9469 5.30873C15.6779 5.3085 15.4114 5.36011 15.1619 5.46073C15.5492 6.51942 15.5492 7.68104 15.1619 8.73973V8.73873Z"
      fill="white"
    />
    <path
      d="M9.53596 13.6326C13.011 13.6326 15.947 15.6256 15.947 17.9846V21.0536H3.12496V17.9846C3.12496 15.6256 6.06096 13.6326 9.53596 13.6326ZM9.53596 12.6866C5.48996 12.6866 2.17896 15.0706 2.17896 17.9866V21.9996H16.893V17.9846C16.893 15.0706 13.582 12.6846 9.53596 12.6846V12.6866Z"
      fill="white"
    />
    <path
      d="M9.53603 4.02935C10.3147 4.0045 11.0715 4.28901 11.641 4.82065C12.2105 5.35229 12.5463 6.0878 12.575 6.86635C12.5731 7.24013 12.4945 7.60953 12.3439 7.95166C12.1934 8.2938 11.9743 8.60138 11.7 8.85535C11.1139 9.40437 10.3391 9.70692 9.53603 9.70035C8.75852 9.72441 8.00304 9.4401 7.43431 8.9094C6.86558 8.3787 6.52974 7.64466 6.50003 6.86735C6.50124 6.4936 6.57928 6.12409 6.72928 5.78176C6.87929 5.43943 7.09806 5.13159 7.37203 4.87735C7.95801 4.3265 8.7338 4.02277 9.53803 4.02935M9.53803 3.08335C8.50754 3.05648 7.50858 3.44004 6.76088 4.14967C6.01319 4.8593 5.57799 5.83687 5.55103 6.86735C5.57852 7.89722 6.01363 8.87407 6.76081 9.58337C7.50798 10.2927 8.50612 10.6764 9.53603 10.6504C10.566 10.6759 11.564 10.2916 12.311 9.582C13.058 8.87235 13.4928 7.8953 13.52 6.86535C13.4923 5.83584 13.0572 4.8594 12.3103 4.15035C11.5633 3.44129 10.5656 3.05755 9.53603 3.08335H9.53803Z"
      fill="white"
    />
  </svg>
);
