import React, { useEffect } from 'react';
import ArrowBackButton from '../../components/mobile/Buttons/ArrowBackButton';
import styles from './healthStyles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectHealthInfo, selectHealthIsLoading } from '../../store/health/selectors';
import { getHealthInfo } from '../../store/health/reducer';
import { useToken } from '../../cookies';
import { selectUserId } from '../../store/auth';
import HealthMetrics from "../../components/HealthKit/HealthMetrics";

function Health() {
  const { token } = useToken();
  const dispatch = useDispatch();
  const loggedUserId = useSelector(selectUserId);
  const isLoading = useSelector(selectHealthIsLoading);
  const healthData = useSelector(selectHealthInfo);

  useEffect(() => {
    if (!Object.keys(healthData).length) {
      dispatch(getHealthInfo({ token, author_id: loggedUserId }));
    }
  }, []);

  return (
    <div className={styles.healthMainPage}>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <div className={styles.arrowBack}>
            <ArrowBackButton
              setStep={() => {
                window.history.back();
              }}
            />
          </div>
          {Object.keys(healthData).length ? (
            <>
              {healthData.steps && <HealthMetrics dataObjects={healthData.steps} type={'steps'} />}
              {healthData.calories && <HealthMetrics dataObjects={healthData.calories} type={'calories'} />}
            </>
          ) : (
            <div className={styles.noDataBlock}>No health values registered!</div>
          )}
        </div>
      )}
    </div>
  );
}

export default Health;