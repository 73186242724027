export const heartIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.25 12C11.2466 12 8 15.2308 8 19.2167C8 22.4344 9.26875 30.071 21.7576 37.7908C21.9813 37.9276 22.2381 38 22.5 38C22.7619 38 23.0187 37.9276 23.2424 37.7908C35.7313 30.071 37 22.4344 37 19.2167C37 15.2308 33.7535 12 29.75 12C25.7466 12 22.5 16.3738 22.5 16.3738C22.5 16.3738 19.2535 12 15.25 12Z"
      fill="#FC5862"
      stroke="#FC5862"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
