import React, { Dispatch, SetStateAction } from 'react';
import ArrowBackButton from '../../Buttons/ArrowBackButton';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { darkTheme } from '../../../../themes/themes';
import { ThemeProvider } from '@mui/material/styles';
import { closeWhiteCircleIcon } from 'images/closeWhiteCircleIcon';
import { useHistory } from 'react-router-dom';
import styles from './CreateGroupMobileStyle.module.css';

type Sep2Props = {
  setStep: Dispatch<SetStateAction<number>>;
  setGroupDescription: Dispatch<SetStateAction<string>>;
  createGroup: () => void;
  isCreateBtnDisable: boolean;
};

export default function Step2({ setStep, createGroup, setGroupDescription, isCreateBtnDisable }: Sep2Props) {
  const history = useHistory();

  return (
    <div className={styles.createGroupMain}>
      <ThemeProvider theme={darkTheme}>
        <div className={styles.headerCreateGroup}>
          <ArrowBackButton setStep={() => setStep(1)} />
          <div onClick={() => history.push('./feed')}>{closeWhiteCircleIcon('38', '34')}</div>
        </div>
        <div className={styles.createGroupForm}>
          <h1 className={styles.headTextTitle} style={{ marginBottom: '20px' }}>
            Write a short description{' '}
          </h1>

          <TextareaAutosize
            onChange={(e) => setGroupDescription(e.target.value)}
            className={styles.createGroupDescription}
            style={{
              marginTop: '10px',
              width: '98%',
              minHeight: '160px',
              borderRadius: '8px',
              padding: '8px',
              boxSizing: 'border-box',
              maxHeight: '200px',
            }}
            aria-label="empty textarea"
            placeholder="Type your description"
          />
          <div
            className={styles.nextButton}
            onClick={() => {
              isCreateBtnDisable && createGroup();
            }}
          >
            Next
          </div>
          {/*<div className={styles.skipButton} onClick={() => setStep(3)}>*/}
          {/*  Skip*/}
          {/*</div>*/}
        </div>
      </ThemeProvider>
    </div>
  );
}
