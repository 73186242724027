// External Libraries
import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
// Utilities & Constants
import ArticleQuestionShared from 'src/components/Article/ArticleCard/components/ArticleQuestionShared';
import GroupPage from 'src/pages/Group/GroupPage';

import {
    ADMIN_AUTH,
    ADMIN_STATISTICS,
    CONTACT,
    CREATE_GROUP_MOBILE,
    EDIT_CREATE_ARTICLE_ROUTE,
    EDIT_PAPER_ROUTE,
    GROUP_ROUTE,
    HEALTH,
    HOME_ROUTE,
    LOGIN_ROUTE,
    MOBILE_CHAT_PAGE,
    MOBILE_EMERGENCY_PAGE,
    MOBILE_EXPLORE,
    MOBILE_INVITE_FRIEND,
    MOBILE_NOTIFICATIONS,
    MOBILE_QUESTION,
    PARTNERS,
    RESET_ACCOUNT,
    SEARCH_PAGE_ROUTE,
    SETTINGS_ROUTE,
    SHARED_QUESTION_ROUTE,
    USER_PROFILE_ROUTE,
    VIEW_ARTICLE_ROUTE,
} from '../utils/constants';

// Components
import { LoggedRouteMiddleware } from './LoggedRouteMiddleware';
import { getPageTitleByPath } from './pageTitle';

import EditArticle from '../components/EditPaper/EditArticle';
import CreateGroupMobile from '../components/mobile/CreateGroup/CreateGroupMobile';

// Pages
import Admin from '../Admin/Pages/Admin';
import AdminAuth from '../Admin/Pages/AdminAuth';
import LandingPage from '../landingPage/LandingPage';
import EditCreateArticlePage from '../pages/Article/EditCreateArticlePage';
import ViewArticle from '../pages/Article/ViewArticle';
import LoginPage from '../pages/Auth/AuthPage';
import ChatMobile from '../pages/ChatMobile/ChatMobile';
import ContactPage from '../pages/ContactPage/ContactPage';
import EmergencyMobile from '../pages/EmergencyMobile/EmergencyMobile';
import { ExploreMobile } from '../pages/ExploreMobile/ExploreMobile';
import HomePage from '../pages/HomePage/HomePage';
import InviteFriendMobile from '../pages/InviteFriend/InviteFriendMobile';
import { MobileNotification } from '../pages/NotificationsMobile/MobileNotification';
import Partners from '../pages/Partners/Partners';
import QuestionMobile from '../pages/QuestionMobile/QuestionMobile';
import Recover from '../pages/ResetAccount';
import { SearchPage } from '../pages/SearchPage/SearchPage';
import SettingsPage from '../pages/SettingsPage/SettingsPage';
import UserPage from '../pages/UserPage/UserPage';
import Health from "../pages/HealthKit/Health";

const HomeRouter: FC = () => {
    return (
        <div>
            <Switch>
                <Route path={HOME_ROUTE} component={HomePage} exact/>
                <Route path={ADMIN_STATISTICS} component={Admin} exact/>
                <Route path={USER_PROFILE_ROUTE} component={UserPage} exact/>
                <Route path={SETTINGS_ROUTE} component={SettingsPage} exact/>
                <Route path={EDIT_CREATE_ARTICLE_ROUTE} component={EditCreateArticlePage} exact/>
                <Route path={VIEW_ARTICLE_ROUTE} component={ViewArticle} exact/>
                <Route path={SEARCH_PAGE_ROUTE} component={SearchPage} exact/>
                <Route path={EDIT_PAPER_ROUTE} component={EditArticle} exact/>
                <Route path={CREATE_GROUP_MOBILE} component={CreateGroupMobile} exact/>
                <Route path={SHARED_QUESTION_ROUTE} component={ArticleQuestionShared} exact/>
                <Route path={GROUP_ROUTE} component={GroupPage} exact/>
                <Route path={MOBILE_NOTIFICATIONS} component={MobileNotification} exact/>
                <Route path={MOBILE_EXPLORE} component={ExploreMobile} exact/>
                <Route path={MOBILE_EMERGENCY_PAGE} component={EmergencyMobile} exact/>
                <Route path={MOBILE_CHAT_PAGE} component={ChatMobile} exact/>
                <Route path={MOBILE_INVITE_FRIEND} component={InviteFriendMobile} exact/>
                <Route path={MOBILE_QUESTION} component={QuestionMobile} exact/>
                <Route path={PARTNERS} component={Partners} exact/>
                <Route path={HEALTH} component={Health} exact/>
                <Redirect to={HOME_ROUTE}/>
            </Switch>
        </div>
    );
};

const AppRouter = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({hitType: 'pageview', page: location.pathname, title: getPageTitleByPath(location.pathname)});
    }, [location]);

    return (
        <Switch>
            <Route path={'/'} component={LandingPage} exact/>
            <Route path={LOGIN_ROUTE} component={LoginPage} exact/>
            <Route path={ADMIN_AUTH} component={AdminAuth} exact/>
            <Route path={CONTACT} component={ContactPage} exact/>
            <Route path={RESET_ACCOUNT} component={Recover} exact/>
            <LoggedRouteMiddleware>
                <Route component={HomeRouter}/>
            </LoggedRouteMiddleware>
        </Switch>
    );
};
export default AppRouter;
