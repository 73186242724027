import { Dispatch, SetStateAction, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { css } from 'styled-components/macro';
import './style.css';

export function MinimalTinyEditor(props: { value: string; onChange: (value: string) => void }) {
  const editorRef = useRef<Editor | null>(null);

  return (
    <>
      {/* @ts-ignore*/}
      <Editor
        ref={editorRef}
        value={props.value}
        apiKey={window?._env_?.REACT_APP_TINYMCE_API_KEY}
        init={{
          menubar: false,
          inline: true,
          toolbar: false,
          plugins: [
            'quickbars',
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime table contextmenu paste imagetools wordcount',
            'wordcount',
          ],
          quickbars_insert_toolbar:
            'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | gallery',
          quickbars_selection_toolbar:
            'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | gallery',
        }}
        onEditorChange={props.onChange}
      />
    </>
  );
}

export function TinyEditor(props: {
  value: string;
  onChange: (value: string) => void;
  setWords: Dispatch<SetStateAction<number>>;
}) {
  const editorRef = useRef<Editor | null>(null);
  props.setWords(editorRef.current?.editor?.plugins?.wordcount?.getCount() || 0);

  return (
    <>
      {/* @ts-ignore*/}
      <Editor
        css={css`
          border-radius: 20px;
        `}
        ref={editorRef}
        value={props.value}
        apiKey={window?._env_?.REACT_APP_TINYMCE_API_KEY}
        init={{
          selector: 'textarea' as any,
          menubar: false,
          inline: true,
          toolbar: false,
          plugins: [
            'quickbars',
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime table contextmenu paste imagetools wordcount',
            'wordcount',
          ],
          quickbars_insert_toolbar:
            'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | gallery',
          quickbars_selection_toolbar:
            'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | gallery',
        }}
        onEditorChange={props.onChange}
      />
    </>
  );
}
