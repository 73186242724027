import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './helpers/AppRouter';
import { useToken } from './cookies';
import { useRef } from 'react';
import { createNotificationsSocket, createWebSocket } from './webSocket';
import MessageHockPopUp from './hooks/MessageHockPopUp';
import { setIsNewMessageInChats } from './store/chats';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from './store/auth';
import { setHasNewNotification } from './store/notifications';

function App() {
  const { token } = useToken();
  const socketRef = useRef<WebSocket | null>(null);
  const notificationSocketRef = useRef<WebSocket | null>(null);
  const dispatch = useDispatch();
  const myUserId = useSelector(selectUserId);

  if (token) {
    let newSocket: WebSocket;
    let newNotificationsSocket: WebSocket;

    if (!notificationSocketRef.current) {
      newNotificationsSocket = createNotificationsSocket(token);
      notificationSocketRef.current = newNotificationsSocket;
    } else {
      newNotificationsSocket = notificationSocketRef.current;
    }

    if (!socketRef.current) {
      newSocket = createWebSocket(token);
      socketRef.current = newSocket;
    } else {
      newSocket = socketRef.current;
    }

    newNotificationsSocket.onmessage = function (event) {
      dispatch(setHasNewNotification());
    };

    newSocket.onmessage = function (event) {
      try {
        const parsedResult = JSON.parse(event.data);
        if (parsedResult.type === 'chat_message' && parsedResult) {
          dispatch(setIsNewMessageInChats({ parsedResult, myUserId }));
        }
      } catch (e) {
        console.log('Socket error:', e);
      }
    };

    newSocket.onclose = () => {
      MessageHockPopUp({
        message: 'Chat connection closed! Please refresh the page or restart the app...',
        timeOut: 3000,
        type: 'warning',
        size: 'small',
      });
      //TODO find a better solution
      // setTimeout(() => {
      //   // window.location.reload();
      // }, 2000);
    };

    newNotificationsSocket.onopen = () => {
      // console.log('Notifications WebSocket connection opened');
    };
    newSocket.onopen = () => {
      // console.log('Chat WebSocket connection opened');
    };
    window.webSocket = newSocket;
  }

  return (
    <Router>
      <AppRouter />
    </Router>
  );
}

export default App;
