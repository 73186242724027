import React, {useEffect, useRef, useState} from 'react';
import ArrowBackButton from '../Buttons/ArrowBackButton';
import { ReactComponent as SearchSvg } from 'images/Search.svg';
import { ImagesTemplate } from '../../layout/ImagesTemplate';
import { NO_USER_AVATAR } from '../../../utils/constantImagesUrl';
import MyProfile from './components/MyProfile';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedUserData } from '../../../store/user/selectors';
import { getUser } from '../../../store/user';
import useDebounce from '../../../hooks/useDebounce';
import { useToken } from '../../../cookies';
import { selectUserId } from '../../../store/auth';
import { useHistory } from 'react-router-dom';
import { MOBILE_EXPLORE } from '../../../utils/constants';
import { frameIcon } from 'images/frameIcon';
import SearchResult from './components/SearchResult';
import { closeSearchTab, search } from '../../../store/globalSearch/reducer';
import { selectGlobalSearchResult, selectIsSearchTabOpen } from '../../../store/globalSearch/selectors';
import styles from './MobileHeaderStyles.module.css';

function MobileHeader({ isFeed }: { isFeed?: boolean }) {
  const [closeProfileDialog, setCloseProfileDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchedResult = useSelector(selectGlobalSearchResult);
  const isSearchTabOpen = useSelector(selectIsSearchTabOpen);
  const loggedUserData = useSelector(selectLoggedUserData);
  const userId = useSelector(selectUserId);
  const { token } = useToken();
  const history = useHistory();
  const dispatch = useDispatch();

  const { groups, papers, users } = searchedResult;



  useEffect(() => {
    if (!loggedUserData.id) {
      dispatch(getUser({ token, userId }));
    }
    // eslint-disable-next-line
  }, []);

  const debouncedSearch = useDebounce(searchValue);

  useEffect(() => {
    if (debouncedSearch.length > 2) {
      dispatch(search({ searchedValue: debouncedSearch, token }));
    } else {
      dispatch(closeSearchTab());
    }
    // eslint-disable-next-line
  }, [debouncedSearch]);

  return (
    <div className={styles.mainMobHeader}>
      {!isFeed && (
        <ArrowBackButton
          setStep={() => {
            window.history.back();
          }}
          style={{ marginTop: '0px', borderRadius: '50%', marginRight: '8px' }}
        />
      )}
      <div className={`${styles.searchInputBlock} ${searchValue && styles.hasValue}`}>
        <SearchSvg width={16} height={16} />
        <input
          placeholder="Search..."
          className={styles.searchInput}
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {!!searchValue.length && (
          <div
            onClick={() => {
              setSearchValue('');
            }}
            className={styles.clearSearchBtn}
          >
            x
          </div>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '12px' }} onClick={() => history.push(MOBILE_EXPLORE)}>
          {frameIcon('33')}
        </div>

        <div>
          <div
            onClick={() => {
              setCloseProfileDialog(true);
            }}
          >
            <ImagesTemplate url={loggedUserData?.profile?.image_url || NO_USER_AVATAR} height={36} width={36} />
          </div>

          <MyProfile closeDialog={closeProfileDialog} setCloseDialog={setCloseProfileDialog} isGroupPage={false} />
        </div>
      </div>
      {isSearchTabOpen ? <SearchResult groups={groups} papers={papers} users={users} /> : null}
    </div>
  );
}

export default MobileHeader;