export const infoIcon = (color="white") => (
  <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9672 7.99992C14.9672 4.52053 12.1466 1.69992 8.66719 1.69992C5.18779 1.69992 2.36719 4.52053 2.36719 7.99992C2.36719 11.4793 5.18779 14.2999 8.66719 14.2999C12.1466 14.2999 14.9672 11.4793 14.9672 7.99992Z"
      stroke={color}
      strokeWidth="0.857143"
      strokeMiterlimit="10"
    />
    <path
      d="M8.66699 10.8571V8M8.66699 5.14285H8.65985"
      stroke={color}
      strokeWidth="0.857143"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
