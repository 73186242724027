import React from 'react';
import { NewlineText } from './textHelper';
import styles from './styles.module.css';

function VextSharingPaper() {
  const text =
    "So you don't have to stay in isolation with your emotions and moods. Sometimes it's helpful just to know that other people close to you are feeling similar feelings as you. Many of our early users shared with us that they want friends and peers and coaches and teachers and bosses to know how they are feeling....without friends, coaches, teachers, etc. knowing it is THEM who is feeling that way. VEXT helps you express how you're feelings without people necessarily knowing it's YOU who feels some kind of way. \n" +
    '\n' +
    "VEXT is a place to signal that you need help in subtle ways and to be able to be acknowledged by group members. And if negative feelings persist, VEXT has a mechanism for prompting other people to subtly reach out. VEXT plays the middle-man so that if others want to offer help or support, you don't have to worry about your identity being revealed - EVER - unless you want it to be. ";
  return <div className={styles.textBlock}>{NewlineText(text)}</div>;
}

export default VextSharingPaper;
