import { useEffect } from 'react';
import NavigationNavbar from '../../components/Header/NavigationHeader';
import { Wrapper } from '../../components/layout/Wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedTypeFilter } from '../../store/articles';
import { selectUserId } from '../../store/auth';
import { getUser } from '../../store/user';
import MainFooter from '../../components/Footer/MainFooter';
import { useToken } from '../../cookies';
import { GroupBlock } from '../../components/groups/GroupBlock';
import { useIsMobile, useIsTablet } from 'src/utils/styles.utils';
import { css } from 'styled-components/macro';
import MobileHeader from '../../components/mobile/Header/MobileHeader';
import { selectIsSearchTabOpen } from '../../store/globalSearch/selectors';

export default function HomePage() {
  const dispatch = useDispatch();
  const { token } = useToken();
  const userId = useSelector(selectUserId);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSearchTabOpen = useSelector(selectIsSearchTabOpen);

  useEffect(() => {
    if (token?.length && userId) {
      dispatch(
        getUser({
          token: token,
          userId,
        }),
      );
    }
  }, [userId]);

  useEffect(() => {
    dispatch(changeSelectedTypeFilter('All'));
  }, []);

  return (
    <div
      css={css`
        height: 100vh;
        display: flex;
        flex-direction: column;
        ${isMobile && `background-color: black;`}
      `}
    >
      {isMobile || isTablet ? <MobileHeader isFeed /> : <NavigationNavbar />}
      <Wrapper>
        {!isSearchTabOpen && <GroupBlock />}
        {isMobile || isTablet ? null : <MainFooter />}
      </Wrapper>
    </div>
  );
}
