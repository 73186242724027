import React, { useEffect, useState } from 'react';
import { XYPlot, LineSeries, Hint, VerticalGridLines } from 'react-vis';
import styles from '../dashboardStyles.module.css';
import { weekdaysLong } from '../../../../helpers/date';

const getLabel = (tick: 'day' | 'week' | 'month', x: number, y: number, labelType?: 'Emergencies' | 'Outliers') => {
  if (tick === 'day') {
    return (
      <>
        <div>{`Hour: ${x < 10 ? '0' + x : x}:00`}</div>
        <div>{`${labelType}: ${y}`}</div>
      </>
    );
  }
  if (tick === 'week') {
    return (
      <>
        <div>{`${weekdaysLong[x]}`}</div>
        <div>{`${labelType}: ${y}`}</div>
      </>
    );
  }
  if (tick === 'month') {
    return (
      <>
        <div>{`Day: ${x}`}</div>
        <div>{`${labelType}: ${y}`}</div>
      </>
    );
  }
};

function ChartLines({
  color,
  tick,
  data,
  periodLength,
  yMax,
  labelType,
}: {
  color: string;
  tick: 'day' | 'week' | 'month';
  data: Array<{ x: number; y: number }>;
  periodLength: number;
  yMax: number;
  labelType?: 'Emergencies' | 'Outliers';
}) {
  const [hintValue, setHintValue] = useState<any>(null);
  const [hoveredX, setHoveredX] = useState(null);

  const handleNearestX = (value: any, { index }: any) => {
    setHintValue(data[index]);
    setHoveredX(value.x);
  };

  const handleMouseLeave = () => {
    setHintValue(null);
    setHoveredX(null);
  };

  return (
    <div className={styles.lineChart}>
      <XYPlot
        width={218}
        height={80}
        xDomain={[0, periodLength]}
        yDomain={[0, yMax || 1]}
        margin={{ top: 2, right: 2, bottom: 2, left: 2 }}
        onMouseLeave={handleMouseLeave}
      >
        <VerticalGridLines tickValues={[hoveredX]} style={{ strokeWidth: 0.5, stroke: '#ccc' }} />
        <LineSeries
          data={data}
          color={color}
          strokeWidth={2}
          curve="curveMonotoneX"
          onNearestX={handleNearestX}
          // onSeriesMouseOut={handleMouseLeave}
        />
        {hintValue && (
          <Hint value={hintValue} className={styles.hint}>
            {getLabel(tick, hintValue.x, hintValue.y, labelType)}
          </Hint>
        )}
        {/*<XAxis style={{ strokeWidth: 0.7, color: '#000', fontSize: '10px' }} />*/}
        {/*<YAxis style={{ strokeWidth: 0.7, color: '#000', fontSize: '10px' }} />*/}
      </XYPlot>
    </div>
  );
}

export default ChartLines;