import { Paper } from '../../../../types/Paper';
import { css } from 'styled-components/macro';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToken } from 'src/cookies';
import { addOrRemovePaperLike, clearSelectedPaper, getPaperById } from 'src/store/articles';
import { dots3Icon } from 'images/dots3Icon';
import { ImagesTemplate } from '../../../layout/ImagesTemplate';
import { checkImage } from '../../../../helpers/checkImageUrl';
import { messageTopicIcon } from 'images/messageTopicIcon';
import { LikeThumbsUpOutline } from 'images/LikeThumbsUpOutline';
import { LikeThumbsUpIcon } from 'images/LikeThumbsUpIcon';
import { selectUserId } from '../../../../store/auth';
import { selectLoggedUserData } from '../../../../store/user/selectors';
import { getUser } from '../../../../store/user';
import { useHistory, useLocation } from 'react-router-dom';
import SimpleListMenu from '../../../../helpers/MenuDropDown';
import { BlockMenuType, MenuOption } from '../../../../types/Common';
import { iAmArticleAuthor } from '../../../../helpers/searchFilters';
import { getAnonymousImage } from '../../../../helpers/anonymousGenerator';
import { userNameFromAllParams } from '../../../../helpers/userName';
import TrashcanIcon from '../../../../assets/icons/trashbinIcon';
import ReportIcon from '../../../../assets/icons/reportIcon';
export default function MobileQuestionCard({ article }: { article: Paper }) {
  const loggedUserId = useSelector(selectUserId);
  const loggedUser = useSelector(selectLoggedUserData);
  const dispatch = useDispatch();
  const { token } = useToken();
  const [isImgAvailable, setIsImgAvailable] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [menuOptions, steMenuOption] = useState<Array<MenuOption>>([
    {
      label: 'Report',
      blockId: article.id,
      blockType: BlockMenuType.QUESTION,
      icon: ReportIcon,
    },
  ]);
  useEffect(() => {
    const checkImg = async () => {
      if (article.image) {
        const isAvailable = (await checkImage(article.paper_author?.profile?.image_url || '')) as boolean;
        setIsImgAvailable(isAvailable);
      } else {
        setIsImgAvailable(true);
      }
    };
    checkImg();
    // @ts-ignore
  }, []);

  useEffect(() => {
    if (menuOptions.length < 2 && iAmArticleAuthor(article, loggedUserId)) {
      steMenuOption([
        ...menuOptions,
        { label: 'Delete', blockId: article.id, blockType: BlockMenuType.QUESTION, icon: TrashcanIcon },
      ]);
    }
  }, []);

  useEffect(() => {
    if (!loggedUser.id) {
      dispatch(getUser({ token: token, userId: loggedUserId }));
    }
  }, []);

  const removeLikeFromQuestion = () => {
    dispatch(
        addOrRemovePaperLike({
          author: { ...loggedUser, id: loggedUserId },
          articleId: article.id,
          token,
          isAddLike: false,
        }),
    );
  };

  const addLikeToQuestion = () => {
    dispatch(
        addOrRemovePaperLike({
          author: { ...loggedUser, id: loggedUserId },
          articleId: article.id,
          token,
          isAddLike: true,
        }),
    );
  };

  const goToArticlePage = () => {
    dispatch(clearSelectedPaper());
    dispatch(getPaperById({ token, id: article.id }));
    history.push('/mobile/question/' + article.id);
  };

  return article.description?.length ? (
    <div
      css={css`
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        color: black;
        width: 89%;
        background-color: white;
        border-radius: 12px;
        margin-bottom: 20px;
        max-height: 50%;
        height: 34%;
        position: relative;
      `}
    >
      <div
        css={css`
          border-bottom: 1px solid black;
          display: flex;
        `}
      >
        <span
          css={css`
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
            padding: 10px;
            border-right: 1px solid black;
            width: 84%;
          `}
          onClick={goToArticlePage}
        >
          {article.title}
        </span>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16%;
          `}
          >
            <SimpleListMenu options={menuOptions}>{dots3Icon('black')}</SimpleListMenu>
          </div>
        </div>
        <div
            css={css`
          border-bottom: 1px solid black;
          display: flex;
          padding: 10px 12px;
          height: 34%;
          overflow-y: scroll;
        `}
      >
        {article.description}
      </div>
      <div
        css={css`
          display: flex;
          padding: 10px 12px;
          background-color: white;
          border-radius: 12px;
        `}
        >
          {article.anonymous_name ? (
              getAnonymousImage(article.anonymous_name)
          ) : (
              <div
                  onClick={(event) => {
                    event.stopPropagation();
                    const regex = /^\/user\//;
                    const isUserPage = regex.test(location.pathname);
                    if (!isUserPage) {
                      history.push(`/user/${article.paper_author?.id}`);
                    }
                  }}
              >
                <ImagesTemplate
                    url={isImgAvailable ? article.paper_author?.profile?.image_url : undefined}
                    width={36}
                    height={36}
                />
              </div>
          )}
          <div
              css={css`
            display: flex;
            flex-direction: column;
            margin-left: 12px;
          `}
        >
          <span>
            {article.anonymous_name ||
                userNameFromAllParams(
                    article.paper_author?.username,
                    article.paper_author?.profile?.first_name,
                    article.paper_author?.profile?.last_name,
                )}
          </span>
            <span
                css={css`
              color: #757575;
            `}
            >
            {article.paper_author?.profile?.major}
          </span>
          </div>
          <div
              css={css`
            display: flex;
            margin-left: auto;
          `}
          >
            <div
                css={css`
              display: flex;
              align-items: center;
              width: 30px;
              justify-content: space-between;
              margin-right: 14px;
            `}
                onClick={goToArticlePage}
            >
              {messageTopicIcon('black')} {article.comments}
            </div>
            <div
                css={css`
              display: flex;
              align-items: center;
              width: 34px;
              justify-content: space-between;
            `}
            >
              {!article.user_liked ? (
                  <div onClick={() => addLikeToQuestion()}>{LikeThumbsUpOutline('black', '20')}</div>
              ) : (
                  <div onClick={() => removeLikeFromQuestion()}>{LikeThumbsUpIcon('black', '20')}</div>
              )}
              {article.likes}
            </div>
          </div>
        </div>
      </div>
  ) : null;
}
