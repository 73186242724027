import React, { useEffect } from 'react';
import { Wrapper } from '../../components/layout/Wrapper';
import { ReactComponent as LocationIcon } from '../../assets/icons/LocationIcon.svg';
import { ReactComponent as GroupIcon } from '../../assets/icons/GroupIcon.svg';
import { getMailDomains, selectMailDomains } from '../../store/auth';
import { useDispatch, useSelector } from 'react-redux';
import MobileHeader from '../../components/mobile/Header/MobileHeader';
import styles from './ExploreMobileStyle.module.css';
import {useToken} from "../../cookies";

export function ExploreMobile() {
  const dispatch = useDispatch();
  const domains = useSelector(selectMailDomains);
  const { token } = useToken();

  useEffect(() => {
    dispatch(getMailDomains());
  }, [dispatch]);

  function sendMessageToCordova() {
    window.parent.postMessage(`{"token":"${token}","startDate":"2023-10-15", "metrics":["sleep","steps","calories"]}`, '*');
  }

  return (
    <Wrapper>
      <div className={styles.mainExploreMobile}>
        <MobileHeader />
        <h1 className={styles.headerTitle}>List of universities</h1>
        <div onClick={sendMessageToCordova} className={styles.cordovaTest}>
          Send message to cordova
        </div>

        {domains.map((domain) => {
          return (
            <div className={styles.list}>
              <div className={styles.blockList}>
                <div className={styles.blockListImage}>
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src =
                        'https://img.freepik.com/free-vector/back-school-background-with-empty-space_23-2148609200.jpg?w=2000';
                    }}
                    className={styles.blockListImage}
                    src={domain.cover_image || ''}
                    alt=""
                  />
                </div>
                <div className={styles.credentialList}>
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src =
                        'https://pharem-project.eu/wp-content/themes/consultix/images/no-image-found-360x250.png';
                    }}
                    className={styles.imageSchool}
                    height={100}
                    src={domain.image || ''}
                    alt=""
                  />
                  <div className={styles.schoolList}>
                    <span className={styles.schoolName}>{domain.name}</span>
                    {!!domain.location.length && (
                      <span className={styles.listDetails}>
                        <LocationIcon />
                        <span style={{ margin: '0 0 0 9px' }}>United States, Cambridge</span>
                      </span>
                    )}

                    <span className={styles.listDetails}>
                      <GroupIcon />
                      <span style={{ margin: '0 0 0 9px' }}>{domain.groups} public groups available</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}
