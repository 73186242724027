export const LikedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_841_13477)">
      <circle cx="12" cy="12" r="12" fill="#54B1FD" />
    </g>
    <path
      d="M9.92737 7.27746C9.00937 7.25786 8.11844 7.66773 7.53644 8.36693C6.46004 9.66146 6.39537 11.8141 7.85377 13.2773C7.86151 13.2852 7.86977 13.2927 7.87817 13.3003L11.7178 16.6071C11.8803 16.7467 12.1206 16.7467 12.283 16.6071L16.1232 13.3003C16.1316 13.2927 16.1392 13.2852 16.147 13.2773C17.6054 11.814 17.5399 9.66146 16.4636 8.36693C15.8824 7.66759 14.9907 7.25773 14.0732 7.27746C13.3122 7.29319 12.5782 7.71613 12.0003 8.40853C11.4226 7.71613 10.6886 7.29319 9.92737 7.27746Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_841_13477"
        x="0"
        y="0"
        width="24"
        height="24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_841_13477" />
      </filter>
    </defs>
  </svg>
);
