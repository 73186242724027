import { vectorDown } from 'images/arrows/vectorDown';
import { vectorUp } from 'images/arrows/vectorUp';
import { dots3Icon } from 'images/dots3Icon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkImage } from 'src/helpers/checkImageUrl';

import PopOverGroupMenu from './PopOverGroupMenu';

import { useToken } from '../../../cookies';
import { thisWeekFromToDate } from '../../../helpers/date';
import { selectUserId } from '../../../store/auth';
import { setRecentGroup } from '../../../store/groups';
import { getMyMoodsPerGroup, resetMyGroupMoods } from '../../../store/moods';
import { selectMyMoodsPerGroup } from '../../../store/moods/selector';
import { NO_GROUP_COVER } from '../../../utils/constantImagesUrl';
import { getMyEmojiByValue } from '../../mood/FellingMood';
import { getBackGroundColorByMood, getColorByMood, RechartsGroupMood } from '../../Recharts/RechartsGroupMood';
import styles from '../GroupCardStyles.module.css';
import GroupUsersImages from '../GroupUsersImages';

export default function GroupCardMobile({ group, isAllFeatures }: { group: any; isAllFeatures?: boolean }) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [isImgUrlAvailable, setIsImgUrlAvailable] = useState(false);
  const [isIRechartsOpen, setIsRechartsOpen] = useState(false);
  const loggedUserId = useSelector(selectUserId);
  const myMoodsForThisGroup = useSelector(selectMyMoodsPerGroup);
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useToken();

  useEffect(() => {
    const checkImg = async () => {
      if (group.group_photo) {
        const isAvailable = (await checkImage(group.group_photo)) as boolean;
        setIsImgUrlAvailable(isAvailable);
      } else {
        setIsImgUrlAvailable(true);
      }
    };
    checkImg();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (myMoodsForThisGroup.groupId !== group.id) {
      setIsRechartsOpen(false);
    }
    // eslint-disable-next-line
  }, [myMoodsForThisGroup]);

  const redirectToGroupUrl = (e: any) => {
    e.stopPropagation();
    dispatch(setRecentGroup(group.id));
    history.push({ pathname: `group/${group.id}`, state: group.id });
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const displayDropDownBtn = () => {
    return (
      !isAllFeatures &&
      (group?.admin?.id === loggedUserId ||
        !!group?.users.find((user: any) => {
          return user.id === loggedUserId;
        }))
    );
  };

  return (
    <div style={{ width: '90%' }}>
      <div
        className={styles.groupCardBlockMobile}
        style={{
          borderBottomRightRadius: isIRechartsOpen ? '0px' : '8px',
          borderBottomLeftRadius: isIRechartsOpen ? '0px' : '8px',
        }}
      >
        <div className={styles.groupCardBlockMobileLeftBlock} onClick={(e) => !isAllFeatures && redirectToGroupUrl(e)}>
          <img
            className={styles.groupCardImageMobile}
            src={group.group_photo && isImgUrlAvailable ? group.group_photo : NO_GROUP_COVER}
            alt="group img"
          />
        </div>
        <div className={styles.groupCardBlockMobileRightBlock}>
          <div className={styles.groupCardBlockMobileRightTop}>
            <div className={styles.mobileGroupTitleBlock} onClick={(e) => !isAllFeatures && redirectToGroupUrl(e)}>
              {group.name.length > 16 ? group.name.substring(0, 16) + '...' : group.name}
            </div>
            <PopOverGroupMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} isPinned={false} groupId={group.id} />
            {!isAllFeatures && (
              <div className={styles.mobileGroupDots3} onClick={(e) => handleClick(e)}>
                {dots3Icon('black', '24', '24')}
              </div>
            )}
          </div>

          <div className={styles.groupUsersListBlock}>
            <div className={styles.groupMembersPics}>
              {group.users && <GroupUsersImages users={group.users} />}
              <span className={styles.groupNumberCounterBlock}>
                {group.users?.length} {group.users?.length === 1 ? 'Member' : 'Members'}
              </span>
            </div>

            {displayDropDownBtn() && (
              <div
                style={{
                  backgroundColor: isIRechartsOpen
                    ? getColorByMood(Math.round(group.my_mood_today?.mood_value ?? -1))
                    : getBackGroundColorByMood(Math.round(group.my_mood_today?.mood_value ?? -1)),
                }}
                className={styles.expandEmoji}
              >
                {(!!group.my_mood_today?.recording_date &&
                  getMyEmojiByValue(Math.round(group.my_mood_today?.mood_value ?? -1), 24)) || (
                  <div
                    style={{
                      border: '1px solid red',
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      color: 'black',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'gray',
                    }}
                  >
                    -
                  </div>
                )}
                {!isIRechartsOpen ? (
                  <div
                    className={styles.vector}
                    onClick={() => {
                      dispatch(
                        getMyMoodsPerGroup({
                          groupId: group.id,
                          token,
                          from: thisWeekFromToDate().from,
                          to: thisWeekFromToDate().to,
                        }),
                      );
                      setIsRechartsOpen(!isIRechartsOpen);
                    }}
                  >
                    {vectorDown()}
                  </div>
                ) : (
                  <div
                    className={styles.vector}
                    onClick={() => {
                      dispatch(resetMyGroupMoods());
                      setIsRechartsOpen(!isIRechartsOpen);
                    }}
                  >
                    {vectorUp()}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={isIRechartsOpen ? styles.rechartsWeekExpand : styles.rechartsWeekExpandClosed}>
        <RechartsGroupMood groupData={myMoodsForThisGroup.moods} isExpand={true} />
      </div>
    </div>
  );
}
