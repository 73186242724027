import { CircularProgress, Stack } from '@mui/material';
import { PencilIcon } from 'images/PencilIcon';
import { userPlusIcon } from 'images/users/userPlusIcon';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ArticleGrid from 'src/components/Article/ArticleGrid/ArticleGrid';
import { Wrapper } from 'src/components/layout/Wrapper';
import { useToken } from 'src/cookies';
import { getArticles } from 'src/store/articles';
import { selectArticlesLoading, selectPapers } from 'src/store/articles/selector';
import { getFullGroup, joinGroup, leaveGroup, resetFullGroup, selectFullGroup } from 'src/store/groups';
import { useIsMobile, useIsTablet } from 'src/utils/styles.utils';

import styles from './GroupPageStyle.module.css';

import GroupFellingFlag from '../../components/groups/components/GroupFellingFlag';
import { GroupDetailsMobile } from '../../components/groups/GroupDetailsMobile';
import GroupUsersImages from '../../components/groups/GroupUsersImages';
import NavigationNavbar from '../../components/Header/NavigationHeader';
import { ElementPreviewImage } from '../../components/layout/ImagesTemplate';
import MobileHeader from '../../components/mobile/Header/MobileHeader';
import FellingMood from '../../components/mood/FellingMood';
import MessageHockPopUp from '../../hooks/MessageHockPopUp';
import { ChatService } from '../../services/socket.service';
import { selectUserId } from '../../store/auth';
import { setGroupFeedTab } from '../../store/navigationStates';
import { selectFeedSelectedTab } from '../../store/navigationStates/selector';
import { getUser } from '../../store/user';
import { selectLoggedUserData } from '../../store/user/selectors';
import { Group_Tabs } from '../../types/Group';
import { PaperType } from '../../types/Paper';

export default function GroupPage() {
  const [refreshCounter, setRefreshCounter] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedUserId = useSelector(selectUserId);
  const userData = useSelector(selectLoggedUserData);
  const { profile } = userData;
  const { token } = useToken();
  const fullGroup = useSelector(selectFullGroup);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const articles = useSelector(selectPapers);
  const selectedTab = useSelector(selectFeedSelectedTab);
  const isLoading = useSelector(selectArticlesLoading);
  const [questions, setQuestions] = useState(articles);
  const { id: groupId }: any = useParams();

  useEffect(() => {
    dispatch(resetFullGroup());
    if (!profile?.id && loggedUserId) {
      dispatch(getUser({ token: token, userId: loggedUserId }));
    }
  }, [loggedUserId]);

  useEffect(() => {
    dispatch(getArticles({ token: token, groupId }));
    if (groupId && loggedUserId) {
      dispatch(getFullGroup({ token: token, groupId }));
    }
  }, [groupId]);

  useEffect(() => {
    if (selectedTab === Group_Tabs.FEED) {
      setQuestions(articles.filter((article) => article.type === PaperType.ARTICLE));
    }
    if (selectedTab === Group_Tabs.QUESTIONS) {
      setQuestions(articles.filter((article) => article.type === PaperType.QUESTION));
    }
  }, [selectedTab, articles, groupId]);

  const _joinGroup = () => {
    dispatch(joinGroup({ groupId: fullGroup.id, token, userId: loggedUserId, userLogged: userData }));

    setTimeout(() => {
      ChatService.joinChatGroup(token, groupId)
        .then((r) => {
          if (r.ok) {
            ReactGA.event({
              category: 'User',
              action: 'join_group',
              label: 'The user has joined the group',
            });
          }
        })
        .catch((e) => {
          MessageHockPopUp({
            message: 'Error on join group chat, try to leave group and join again!',
            timeOut: 5000,
            type: 'error',
            size: 'small',
          });
        });
      setRefreshCounter(refreshCounter + 1);
    }, 1000);
  };

  const _leaveGroup = () => {
    dispatch(leaveGroup({ groupId: fullGroup.id, token, userId: loggedUserId }));
    ReactGA.event({
      category: 'User',
      action: 'leave_group',
      label: 'The user has left the group',
    });
  };

  const iAmInGroup = () => {
    return !!fullGroup?.users?.find((user) => user.id === loggedUserId);
  };
  const iAmGroupAdmin = () => {
    return fullGroup.admin?.id === loggedUserId;
  };
  useEffect(() => {
    if (groupId === fullGroup?.id && !iAmInGroup() && iAmGroupAdmin()) {
      _joinGroup();
    }
  }, [fullGroup]);

  return fullGroup ? (
    <div className={isMobile ? styles.groupMainLayout : styles.groupMainLayoutBrowser}>
      <div>
        <FellingMood key={refreshCounter} />
        {isMobile || isTablet ? <MobileHeader /> : <NavigationNavbar />}
        <Wrapper isGroupLocation>
          {(isMobile || isTablet) && (
            <div className={styles.groupPageInfoBlock}>
              <div className={styles.titleAndOptions}>
                <h2 className={styles.groupName}>{fullGroup.name}</h2>
                {/*<div>{dots3Icon('white')}</div>  TODO uncommit when the design will be available*/}
              </div>
              <div className={styles.groupImg}>
                <ElementPreviewImage url={fullGroup.group_photo} width={100} height={350} />
                {fullGroup && (
                  <div className={styles.bottomImageBlock}>
                    <div className={styles.bottomImageBlockLeft}>
                      <GroupFellingFlag group={fullGroup} />
                      {!iAmGroupAdmin() && (
                        <div
                          className={`${styles.joinGroup} ${iAmInGroup() && styles.joined}`}
                          onClick={iAmInGroup() ? _leaveGroup : _joinGroup}
                        >
                          {!iAmInGroup() && userPlusIcon()}
                          {iAmInGroup() ? 'Leave group' : 'Join group'}
                        </div>
                      )}
                    </div>
                    <div className={styles.bottomImageBlockRight}>
                      <span>
                        {fullGroup?.users?.length} {fullGroup?.users?.length === 1 ? 'Member' : 'Members'}
                      </span>
                      <GroupUsersImages users={fullGroup.users} />
                    </div>
                  </div>
                )}
              </div>
              <span className={styles.groupDescription}>{fullGroup?.description}</span>
            </div>
          )}
          {/* TODO Remove create article button when design with the right placement will be available*/}
          {(isMobile || isTablet) && (
            <div onClick={() => history.push('/create')} className={styles.writeArticleBtn}>
              <span>Write an article</span>
              {PencilIcon()}
            </div>
          )}
          {
            <div className={isMobile || isTablet ? styles.groupPageTabs : styles.groupPageTabsBrowser}>
              <div
                className={selectedTab === Group_Tabs.MOODS ? styles.selectedTab : styles.notSelectedTab}
                onClick={() => {
                  dispatch(setGroupFeedTab(Group_Tabs.MOODS));
                }}
              >
                Moods
              </div>
              <div
                onClick={() => {
                  dispatch(setGroupFeedTab(Group_Tabs.FEED));
                }}
                className={selectedTab === Group_Tabs.FEED ? styles.selectedTab : styles.notSelectedTab}
              >
                Feed
              </div>
              {(isMobile || isTablet) && (
                <div
                  onClick={() => {
                    dispatch(setGroupFeedTab(Group_Tabs.QUESTIONS));
                  }}
                  className={selectedTab === Group_Tabs.QUESTIONS ? styles.selectedTab : styles.notSelectedTab}
                >
                  Questions
                </div>
              )}
            </div>
          }

          {isLoading && (
            <div className={styles.loadingSpinnerWrapper}>
              <CircularProgress color="success" />
            </div>
          )}
          {selectedTab === Group_Tabs.MOODS ? (
            <GroupDetailsMobile group={fullGroup} />
          ) : (
            <div>
              {isMobile || isTablet ? (
                // !! For mobile we separate questions and articles
                <div>
                  {questions.length ? (
                    <ArticleGrid groupId={fullGroup.id} articles={questions} />
                  ) : (
                    <div className={styles.noPapersYet}>
                      {selectedTab === Group_Tabs.FEED ? 'No papers yet' : 'No questions yet'}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {articles.length ? (
                    <ArticleGrid groupId={fullGroup.id} articles={articles} />
                  ) : (
                    <div className={styles.noPapersYet}>
                      {selectedTab === Group_Tabs.FEED ? 'No papers yet' : 'No questions yet'}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Wrapper>
      </div>
    </div>
  ) : (
    <div className={styles.loader}>
      <Stack sx={{ color: 'grey.100' }} spacing={2} direction="row">
        <CircularProgress color="inherit" />
      </Stack>
    </div>
  );
}
