import { User, UserRoles } from './User';
import { Tag } from './Tag';

export enum PaperType {
  ARTICLE = 'ARTICLE',
  QUESTION = 'QUESTION',
}

export interface ADDLikeDislikeResponse {
  data: { status: string };
  isAddLike: boolean;
  authorId: string;
  commentId: string;
}

interface LikeData {
  first_name: string;
  graduation: string;
  id: string;
  image_url: string;
  last_name: string;
  major: string;
  nickname: string;
  role: string;
}

export type IComment = {
  id: string;
  anonymous_name?: string;
  author: {
    id: string;
    username?: string;
    profile: {
      id: string;
      first_name?: string;
      last_name?: string;
      image_url?: string;
      nickname: string;
      major?: string;
      graduation?: string;
      role: UserRoles;
    };
  };
  repliedToCommentId: string;
  description: string;
  created?: string;
  replays?: Array<IComment>;
  likesCount: number | null;
  likes?: Array<LikeData>;
  dislikes?: Array<LikeData>;
  paragraphId?: string;
};

export interface Paper {
  content: string;
  created: string;
  description: string | null;
  mood?: string;
  anonymous_name?: string;
  duration: number;
  image: string | null;
  paper_tags: Array<{ id: string; name: string }>;
  title: string;
  type: PaperType;
  likes: Array<User>;
  updated: string;
  paper_author?: User;
  id: string;
  views: number;
  comments: Array<IComment>; //TODO use only for selected paper
  isSharePaperOn?: boolean;
  isCommentsOn?: boolean;
  user_liked?: boolean;
}

export interface Article {
  papers: Array<Paper>;
  tags: Array<Tag>;
}

export interface ArticleResponse {
  count: number;
  results: Array<Paper>;
  next: string | null;
}

export interface ArticlePayload {
  data: ArticleResponse;
  page?: number;
}

export type ArticleForm = {
  title?: string;
  description: string;
  duration?: number;
  content?: string;
  image?: Blob | null;
  paper_tags?: Array<string | any>;
  group?: Array<string>;
  userId?: string;
  type?: PaperType;
  id?: string;
  isCommentsOn?: boolean;
  isSharePaperOn?: boolean;
  paper_author?: string;
};
