import { Wrapper } from '../../components/layout/Wrapper';
import React, { useEffect, useState } from 'react';
import NotificationDropDownBlock from '../../components/Notifications/DropDownNotifications';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotifications } from '../../store/notifications/selector';
import { getNotifications } from '../../store/notifications';
import { useToken } from '../../cookies';
import MobileHeader from "../../components/mobile/Header/MobileHeader";
import styles from './MobileNotificationStyles.module.css';

export function MobileNotification() {
  //TODO -- add another tabs in notifications when design will be done
  const [isSelectedTab, setIsSelectedTab] = useState('reports');
  const notifications = useSelector(selectNotifications);
  const { token } = useToken();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications({ token }));
  }, []);

  return (
    <Wrapper>
      <div className={styles.mobileNotificationMain}>
        <MobileHeader />
        <div className={styles.mobileNotificationHeader}>
          <h1>Notifications</h1>
          {notifications.filter((value) => !value.read).length !== 0 && (
            <span className={styles.notificationCounter}>{notifications.filter((value) => !value.read).length}</span>
          )}
        </div>

        {isSelectedTab === 'reports' && (
          <div className={styles.notificationsList}>
            {!!notifications.length ? (
              notifications.map((notification) => {
                return <NotificationDropDownBlock key={notification.id} notification={notification} />;
              })
            ) : (
              <div>No notifications</div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
}
