export const frogIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30 10C30 6.68702 27.313 4 24 4C22.0458 4 20.3053 4.94656 19.2061 6.39694C16.4122 5.92366 13.5878 5.92366 10.7939 6.39694C9.69466 4.94656 7.9542 4 6 4C2.68702 4 0 6.68702 0 10C0 11.771 0.778627 13.3588 2 14.458V15.6947C2 16.8397 2.39695 17.9695 3.12977 18.855L5.14504 21.313C7.57252 24.2901 11.1603 25.9847 15.0076 25.9847C18.8397 25.9847 22.4427 24.2748 24.8702 21.313L26.8855 18.855C27.6183 17.9695 28.0153 16.8397 28.0153 15.6947V14.458C29.2214 13.3588 30 11.771 30 10ZM5.98473 6C8.19847 6 9.98473 7.80152 9.98473 10C9.98473 12.1985 8.18321 14 5.98473 14C3.78626 14 1.98473 12.1985 1.98473 10C1.98473 7.78626 3.78626 6 5.98473 6ZM9.00763 22.1679C9.84733 21.8779 10.6412 21.4504 11.3282 20.8397L11.8931 20.3359C13.6031 18.8092 16.3817 18.8092 18.0916 20.3359L18.6565 20.8397C19.3282 21.4504 20.1221 21.8779 20.9771 22.1679C19.2214 23.3435 17.1603 24 14.9924 24C12.8244 24 10.7634 23.3435 9.00763 22.1679ZM25.9847 15.6947C25.9847 16.3817 25.7405 17.0534 25.313 17.5878L23.2977 20.0458C23.1603 20.1985 23.0229 20.3511 22.8855 20.5038C21.8168 20.458 20.7939 20.0611 19.9847 19.3435L19.4198 18.8397C18.1985 17.7557 16.626 17.145 14.9924 17.145C13.3588 17.145 11.7863 17.7405 10.5649 18.8397L10 19.3435C9.19084 20.0611 8.16794 20.458 7.09924 20.5038C6.96183 20.3511 6.80916 20.2137 6.68702 20.0458L4.67176 17.5878C4.22901 17.0534 4 16.3817 4 15.6947V15.6489C4.62595 15.8779 5.29771 16 6 16C9.31298 16 12 13.313 12 10C12 9.40458 11.9084 8.83969 11.7557 8.30534C13.9237 7.99999 16.0916 7.99999 18.2595 8.30534C18.1069 8.83969 18.0153 9.41985 18.0153 10C18.0153 13.313 20.7023 16 24.0153 16C24.7176 16 25.3893 15.8779 26.0153 15.6489V15.6947H25.9847ZM23.9847 14C21.771 14 19.9847 12.1985 19.9847 10C19.9847 7.80152 21.7863 6 23.9847 6C26.1832 6 27.9847 7.80152 27.9847 10C28 12.1985 26.1985 14 23.9847 14Z"
      fill="#333333"
    />
    <path
      d="M24.2439 8.99243H23.7401C23.1904 8.99243 22.7324 9.43519 22.7324 10.0001C22.7324 10.5497 23.1752 11.0077 23.7401 11.0077H24.2439C24.7935 11.0077 25.2515 10.565 25.2515 10.0001C25.2362 9.43519 24.7935 8.99243 24.2439 8.99243Z"
      fill="#333333"
    />
    <path
      d="M5.74006 10.9926H6.24387C6.79349 10.9926 7.25151 10.5498 7.25151 9.98492C7.25151 9.43531 6.80876 8.97729 6.24387 8.97729H5.74006C5.19044 8.97729 4.73242 9.42004 4.73242 9.98492C4.73242 10.5498 5.19044 10.9926 5.74006 10.9926Z"
      fill="#333333"
    />
    <path
      d="M12.993 13.8933C12.4434 13.8933 11.9854 14.3361 11.9854 14.9009V15.0994C11.9854 15.649 12.4281 16.1071 12.993 16.1071C13.5426 16.1071 14.0006 15.6643 14.0006 15.0994V14.9009C13.9854 14.3361 13.5426 13.8933 12.993 13.8933Z"
      fill="#333333"
    />
    <path
      d="M16.992 13.8933C16.4424 13.8933 15.9844 14.3361 15.9844 14.9009V15.0994C15.9844 15.649 16.4271 16.1071 16.992 16.1071C17.5416 16.1071 17.9996 15.6643 17.9996 15.0994V14.9009C17.9844 14.3361 17.5416 13.8933 16.992 13.8933Z"
      fill="#333333"
    />
  </svg>
);
