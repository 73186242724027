import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { UsersApiService } from '../../services/users.service';
import { User } from '../../types/User';
import { isRejectedAction } from '../../utils/redux.utils';
import { ERROR } from '../../errorsTemplates';
import { errorGuard } from '../../utils/errorGuard';

export const USER_REDUCER_NAME = 'user';

type InitialStateType = {
  error: string | null;
  loggedUser: User;
};

const initialState: InitialStateType = {
  error: null,
  loggedUser: {
    email: '',
    id: '',
    is_email_confirmed: false,
    profile: undefined,
    username: null,
    role: '',
  },
};

export const getUser = createAsyncThunk(
  `${USER_REDUCER_NAME}/getUser`,
  async ({
    token,
    userId,
  }: {
    token: string;
    userId: string;
    email?: string;
    first_name?: string;
    last_name?: string;
  }) => {
    const response = await UsersApiService.getUser(token, userId);

    if (!response.ok) {
      errorGuard(response);
    }
    return { data: response.data };
  },
);

export const updateUser = createAsyncThunk(
  `${USER_REDUCER_NAME}/update`,
  async ({ token, updatedData, isNotMyUser }: { token: string; updatedData: Partial<User>; isNotMyUser?: boolean }) => {
    try {
      const response = await UsersApiService.updateUser(token, updatedData);

      const { data } = response;
      return { data: data, isNotMyUser };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.updateUser.update_failed);
    }
  },
);

const authSlice = createSlice({
  name: USER_REDUCER_NAME,
  initialState,
  reducers: {
    clearUserState(state) {
      state.loggedUser = initialState.loggedUser;
      state.error = null;
    },
    updateUserImg(state, { payload }: PayloadAction<string>) {
      if (state.loggedUser.profile?.image_url) {
        state.loggedUser.profile!.image_url = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.loggedUser = payload.data;
        state.error = null;
      })
      .addCase(
        updateUser.fulfilled,
        (state, { payload }: PayloadAction<{ data: User | any; isNotMyUser?: boolean }>) => {
          state.error = null;
        },
      )
      .addMatcher(isRejectedAction(`${USER_REDUCER_NAME}/`), (state, { error }) => {
        state.error = error.message;
      });
  },
});

export const { clearUserState, updateUserImg } = authSlice.actions;
export default authSlice.reducer;
