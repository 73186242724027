import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from 'styled-components/macro';
import { useToken } from '../../../cookies';
import { updateUser } from '../../../store/user';
import { selectLoggedUserData } from '../../../store/user/selectors';
import { EditContentSwitch } from './EditContentSwitch';
import { User } from '../../../types/User';

export const PlaceOfResidence = () => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const userData = useSelector(selectLoggedUserData);
  const [residenceInfo, setResidenceInfo] = useState({
    country: userData.profile?.country,
    state: userData.profile?.state,
    town: userData.profile?.town,
  });
  const [isEditable, setIsEditable] = useState(true);
  const [country, setCountry] = useState(residenceInfo.country);
  const [state, setState] = useState(residenceInfo.state);
  const [town, setTown] = useState(residenceInfo.town);
  const [allCompleted, setAllcompleted] = useState(false);

  useEffect(() => {
    setCountry(userData.profile?.country);
    setState(userData.profile?.state);
    setTown(userData.profile?.town);
  }, [userData]);

  useEffect(() => {
    if (country?.length && state?.length && town?.length) {
      setAllcompleted(true);
    } else {
      setAllcompleted(false);
    }
  }, [country, state, town]);

  const saveNewUserInfo = () => {
    if (country?.length && state?.length && town?.length) {
      setResidenceInfo({
        country,
        state,
        town,
      });
      const newUserInfo = {
        ...userData,
        profile: {
          country,
          state,
          town,
        },
      }as unknown as User;
      dispatch(updateUser({ token, updatedData: newUserInfo }));
      setIsEditable(true);
    }
  };
  const rowData = (title: string, info: string, stateCase: string) => {
    const setPlaceData = (data: string) => {
      switch (stateCase) {
        case 'country':
          return setCountry(data);
        case 'state':
          return setState(data);
        case 'town':
          return setTown(data);
      }
    };

    const getUserData = (): string => {
      switch (stateCase) {
        case 'country':
          return country || '';
        case 'state':
          return state || '';
        case 'town':
          return town || '';
        default:
          return '';
      }
    };
    return (
      <div
        css={css`
          margin-top: 6px;
        `}
      >
        <div
          css={css`
            font-family: sans-serif;
            font-weight: 600;
          `}
        >
          {title}
        </div>
        <div
          css={css`
            margin-top: 6px;
            font-family: sans-serif;
            font-weight: 400;
          `}
        >
          {isEditable ? (
            info
          ) : (
            <input
              css={css`
                border: none;
                ${getUserData().length
                  ? ` border-bottom: 0.5px solid rgba(0, 0, 0, 0.56);`
                  : ` border-bottom: 0.5px solid rgba(253, 61, 2, 0.56);`}
                padding-bottom: 4px;

                &:focus {
                  border: none;
                  outline: none;
                  ${getUserData().length
                    ? ` border-bottom: 0.5px solid rgba(0, 0, 0, 0.56);`
                    : ` border-bottom: 0.5px solid rgba(253, 61, 2, 0.56);`}
                }
              `}
              defaultValue={info}
              onChange={(e) => setPlaceData(e.target.value)}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          width: 70%;
        `}
      >
        <div
          css={css`
            margin-top: 14px;
          `}
        >
          {rowData('Country', country || '', 'country')}
        </div>
        <div
          css={css`
            margin-top: 34px;
          `}
        >
          {rowData('State', state || '', 'state')}
        </div>
        <div
          css={css`
            margin-top: 34px;
          `}
        >
          {rowData('Town', town || '', 'town')}
        </div>
      </div>
      <div
        css={css`
          margin: 6px;
          display: flex;
          padding: 6px;
          justify-content: end;
        `}
      >
        <EditContentSwitch
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          submit={saveNewUserInfo}
          isAllCompleted={allCompleted}
        />
      </div>
    </div>
  );
};
