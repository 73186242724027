export const getTimeRead = (min: number) => {
  if (min < 1) {
    return '1 min read';
  }
  if (min > 60) {
    return `${Math.trunc(min / 60)} h read`;
  }
  return `${min} min read`;
};

