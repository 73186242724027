import { arrowBack } from 'images/arrows/ArrowBack';
import { useHistory } from 'react-router-dom';
import styles from './MobileButtonsStyle.module.css';

export default function ArrowBackButton({
  pushUrl,
  setStep,
  style,
}: {
  pushUrl?: string;
  setStep?: () => void;
  style?: any;
}) {
  const history = useHistory();
  const handleButtonClick = () => {
    if (pushUrl) {
      history.push(pushUrl);
    }
    if (setStep) {
      setStep();
    }
  };

  return (
    <div className={styles.backButton} style={style} onClick={handleButtonClick}>
      {arrowBack()}
    </div>
  );
}
