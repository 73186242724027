import React from 'react';
import styles from "./layoutStyles.module.css";
import {CircularProgress} from "@mui/material";

function MobileLoaderScreen() {
    return (
        <div className={styles.loader}>
            <CircularProgress />
        </div>
    );
}

export default MobileLoaderScreen;