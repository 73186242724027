// import { currentDateInIsoFormat } from '../../helpers/date';

export const getDay = (date: string) => {
    const d = new Date(date);
    return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][d.getUTCDay()];
};

export const isDateToday = (date: string) => {
    if (!date) return false;
    const now = new Date();
    const utcMonth = now.getUTCMonth() + 1;
    const utcYear = now.getUTCFullYear();
    const utcDay = now.getUTCDate();

    const inputDate = new Date(date);
    const inputUtcMonth = inputDate.getUTCMonth() + 1;
    const inputUtcYear = inputDate.getUTCFullYear();
    const inputUtcDay = inputDate.getUTCDate();

    const utcCurrentDate = `${utcYear}-${utcMonth}-${utcDay}`;
    const inputUtcCurrentDate = `${inputUtcYear}-${inputUtcMonth}-${inputUtcDay}`;

    return inputUtcCurrentDate === utcCurrentDate;
};

export const getMoodObjByValue = (moodValue: number) => {
    switch (moodValue) {
        case 0:
            return 'sad';
        case 1:
            return 'frustrated';
        case 2:
            return 'angry';
        case 3:
            return 'tired';
        case 4:
            return 'curious';
        case 5:
            return 'confused';
        case 6:
            return 'excited';
        case 7:
            return 'confident';
        default:
            return 'happy';
    }
};
