export const PostedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_841_13508)">
      <circle cx="12" cy="12" r="12" fill="#54B1FD" />
    </g>
    <path
      d="M16.2495 7.3584H15.8986V15.9787C15.8986 16.5514 15.4324 17.0175 14.8598 17.0175H11.3046H8.47754V17.3371C8.47754 17.703 8.77453 18 9.14045 18H12.6943H16.2482C16.6141 18 16.9111 17.703 16.9111 17.3371V8.02131C16.9111 7.65539 16.6154 7.3584 16.2495 7.3584Z"
      fill="white"
    />
    <path
      d="M11.3049 16.6416H14.8588C15.2247 16.6416 15.5217 16.3446 15.5217 15.9787V7.3584V6.66291C15.5217 6.29699 15.2247 6 14.8588 6H11.3049H10.1232V6.2193C10.1257 6.24185 10.127 6.26566 10.127 6.28947V7.73434V8.10526C10.127 8.6203 9.70841 9.03885 9.19337 9.03885H8.82244H7.37758C7.35878 9.03885 7.33873 9.03759 7.31993 9.03634H7.08936V15.9787C7.08936 16.3446 7.38635 16.6416 7.75226 16.6416H8.47908H11.3049ZM11.439 14.3885H9.12194C8.94901 14.3885 8.80866 14.2481 8.80866 14.0752C8.80866 13.9022 8.94901 13.7619 9.12194 13.7619H11.4402C11.6132 13.7619 11.7535 13.9022 11.7535 14.0752C11.7535 14.2481 11.6119 14.3885 11.439 14.3885ZM13.7573 12.7895H9.12194C8.94901 12.7895 8.80866 12.6491 8.80866 12.4762C8.80866 12.3033 8.94901 12.1629 9.12194 12.1629H13.7573C13.9302 12.1629 14.0706 12.3033 14.0706 12.4762C14.0706 12.6491 13.9302 12.7895 13.7573 12.7895ZM9.12194 10.4649H13.7573C13.9302 10.4649 14.0706 10.6053 14.0706 10.7782C14.0706 10.9511 13.9302 11.0915 13.7573 11.0915H9.12194C8.94901 11.0915 8.80866 10.9511 8.80866 10.7782C8.80866 10.6053 8.94901 10.4649 9.12194 10.4649Z"
      fill="white"
    />
    <path
      d="M7.37778 8.66291H9.19357C9.19483 8.66291 9.19733 8.66291 9.19859 8.66291C9.50184 8.6604 9.74746 8.41479 9.74997 8.11153C9.74997 8.11028 9.74997 8.10777 9.74997 8.10652V6.28947C9.74997 6.12155 9.61212 6.01003 9.46928 6.01003C9.40035 6.01003 9.33143 6.03509 9.27379 6.09273L7.17979 8.18672C7.00435 8.36216 7.12841 8.66291 7.37778 8.66291Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_841_13508"
        x="0"
        y="0"
        width="24"
        height="24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_841_13508" />
      </filter>
    </defs>
  </svg>
);
