import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Picker from 'emoji-picker-react';
import { SmileIcon } from 'images/SmileIcon';
import { useOnClickOutside } from 'usehooks-ts';
import { PaperType } from '../../../types/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserState } from '../../../store/user';
import { ArticlesApiService } from '../../../services/articles.service';
import { selectLoggedUserData } from '../../../store/user/selectors';
import { useToken } from '../../../cookies';
import { getAllGroupsNames, selectAllGroupsName } from '../../../store/groups';
import Select, { MultiValue } from 'react-select';
import style from './style.module.css';

export default function AskModalInput({ setIsOpen }: { setIsOpen: Dispatch<SetStateAction<boolean>> }) {
  const [isEmojiVisible, setIsEmojiVisible] = useState(false);
  const [question, setQuestion] = useState('');
  const [articleGroups, setArticleGroups] = useState<Array<{ id: string }>>();

  const ref = useRef(null);
  const dispatch = useDispatch();
  const { token } = useToken();
  const groups = useSelector(selectAllGroupsName);
  const userData = useSelector(selectLoggedUserData);
  const groupList = groups.map((group) => {
    return { value: Object.keys(group)[0], label: Object.values(group)[0] };
  });

  useEffect(() => {
    dispatch(getAllGroupsNames(token));
    // eslint-disable-next-line
  }, []);

  const handleClickOutside = () => {
    setIsEmojiVisible(false);
  };
  const selectedGroups = (e: MultiValue<{ value: string; label: string }>) => {
    const selectedGroups = e.map((e) => {
      return { id: e.value };
    });
    setArticleGroups(selectedGroups);
  };
  useOnClickOutside(ref, handleClickOutside);

  const createQuestion = () => {
    if (question.length > 2) {
      const data = {
        description: question,
        paper_tags: [],
        likes:[],
        type: PaperType.QUESTION,
        isCommentsOn: true,
        paper_author: userData.id,
        group:articleGroups?.map((e)=>e.id)
      };

      ArticlesApiService.postQuestion(data, token)
        .then((res) => {
          if (!res.ok) {
            if (res.status === 401) {
              dispatch(clearUserState());
              throw new Error('Invalid auth token');
            }
            throw new Error('Error');
          } else {
            window.location.reload();
            setIsOpen(false);
          }
        })
        .catch(() => {
          setIsOpen(false);
        });
    }
  };

  return (
    <div className={style.mainInputBlock}>
      <Select
        isMulti
        name="groups"
        options={groupList}
        placeholder={'Select groups...'}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={(e: any) => selectedGroups(e)}
      />
      <div className={style.mainInput}>
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Start your question here"
          className={style.textarea}
          name=""
          id=""
        />

        <div className={style.inputBottomBlock}>
          <div ref={ref} className={style.emojiBlock}>
            {isEmojiVisible && (
              <Picker
                onEmojiClick={(e, obj) => {
                  e.stopPropagation();
                  setQuestion(question + obj.emoji);
                }}
              />
            )}
          </div>
          <div
            className={style.emojiIcon}
            onClick={(e) => {
              e.stopPropagation();
              setIsEmojiVisible(!isEmojiVisible);
            }}
          >
            {SmileIcon()}
          </div>
          <div onClick={() => setIsOpen(false)} className={style.cancelButton}>
            Cancel
          </div>
          <div onClick={() => createQuestion()} className={style.askButton}>
            Ask
          </div>
        </div>
      </div>
    </div>
  );
}
