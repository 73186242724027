import React, { useEffect, useState } from 'react';
import {
  getBadMoodPeople,
  selectOutlierCount,
  selectProfilesWithBadMoodsByGroupId,
} from '../../../../store/statistics';
import { IGroup } from '../../../../types/Group';
import { useDispatch, useSelector } from 'react-redux';
import { useToken } from '../../../../cookies';
import Person from '../peopleComponents/Person';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function StatisticsOutlier({ group }: { group: IGroup }) {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { token } = useToken();
  const badMoodsProfiles = useSelector(selectProfilesWithBadMoodsByGroupId(group.id));
  const outlierCount = useSelector(selectOutlierCount(group.id));

  useEffect(() => {
    dispatch(getBadMoodPeople({ token, groupId: group.id, page }));
  }, [page]);

  return (
    <div>
      {!!badMoodsProfiles.length &&
        badMoodsProfiles.map((person) => {
          return typeof person.average_mood === 'number' && person.average_mood < 5 ? <Person person={person} /> : null;
        })}
      <Stack spacing={2}>
        <Pagination onChange={(e: any, page) => setPage(page)} count={Math.ceil(outlierCount / 4)} />
      {/* 4 is the page_size from  getPeopleWithBadMood() */}
      </Stack>
    </div>
  );
}

export default StatisticsOutlier;