import React, { useState } from 'react';
import { IGroup } from '../../../../types/Group';
import { dots3Icon } from 'images/dots3Icon';
import GroupUsersImages from '../../../../components/groups/GroupUsersImages';
import GroupCreator from './GroupCreator';
import { getMyEmojiByValue } from '../../../../components/mood/FellingMood';
import { getColorByMood } from '../../../../components/Recharts/RechartsGroupMood';
import { vectorDown } from 'images/arrows/vectorDown';
import { vectorUp } from 'images/arrows/vectorUp';
import ExpandedGroupBlock from './ExpandedGroupBlock';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { checkedCircle } from 'images/checkedCircle';
import { MyProfile } from 'images/users/MyProfile';
import {getMoodText} from "../../../../helpers/moodList";
import styles from './styles.module.css';

function GroupBlock({ group }: { group: IGroup }) {
  const [isGroupExpended, setIsGroupExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.groupBlock}>
      <div className={styles.groupHeader} style={{ borderBottom: isGroupExpended ? '1px solid #BEBEBE96' : '' }}>
        <img className={styles.groupCoverImg} src={group.group_photo || ''} alt="" />
        <div style={{ width: '88%' }}>
          <div className={styles.topHeader}>
            <div className={styles.groupTitle}> {group.name}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/*TODO,.. add admin involved functionality*/}
              {/*<div>Nobody is involved</div>*/}
              <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                {dots3Icon('gray', '24', '24')}
              </div>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => handleClose()}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        marginRight: '6px',
                        marginTop: '4px',
                      }}
                    >
                      {checkedCircle('black', '16')}
                    </div>
                    <div>Get involved</div>
                  </div>
                </MenuItem>
                <MenuItem onClick={() => handleClose()}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ marginRight: '6px', marginTop: '4px' }}>{MyProfile('black')}</div>
                    <div>Report to admin</div>
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </div>

          <div className={styles.bottomHeader}>
            <div className={styles.groupBottomSequence}>
              <span>Group members</span>
              <div className={styles.groupUsersBlock}>
                {group.users && <GroupUsersImages users={group.users} />}
                <div style={{ marginLeft: '6px', fontSize: '12px' }}>
                  {group?.users?.length} {group?.users?.length === 1 ? 'Member' : 'Members'}
                </div>
              </div>
            </div>
            <div className={styles.groupBottomSequence}>
              <span>Group creator</span>
              <GroupCreator creator={group.admin} />
            </div>
            <div className={styles.groupBottomSequence}>
              <span>This group is feeling</span>
              <div
                className={styles.groupFeelingMood}
                style={{
                  border: '1px solid ' + getColorByMood(Math.round(group.general_average_weekly_mood ?? -1)),
                }}
              >
                <div>{getMyEmojiByValue(Math.round(group?.general_average_weekly_mood ?? -1), 24)}</div>
                <div
                  style={{
                    marginLeft: '4px',
                    fontSize: '14px',
                    marginBottom: '1px',
                  }}
                >
                  {getMoodText(Math.round((group?.general_average_weekly_mood ?? -1)))}
                </div>
              </div>
            </div>
            <div className={styles.expandGroupBlock}>
              {/*<div>+99</div>*/}
              <div style={{ cursor: 'pointer' }} onClick={() => setIsGroupExpanded(!isGroupExpended!)}>
                {isGroupExpended ? vectorUp('black') : vectorDown()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isGroupExpended && <ExpandedGroupBlock group={group} />}
    </div>
  );
}

export default GroupBlock;