export const sendMessageIcon = () => (
  <svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.3333 1.33333L11.5 14.1667M24.3333 1.33333L16.1667 24.6667L11.5 14.1667L1 9.5L24.3333 1.33333Z"
      stroke="#FFC700"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
