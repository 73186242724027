export const flegIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_682_3466)">
      <path
        d="M5.75 6.75C5.75 5.09315 7.09315 3.75 8.75 3.75H16.25C17.9069 3.75 19.25 5.09315 19.25 6.75V21.0529C19.25 21.5081 18.6913 21.7265 18.3826 21.3921L13.4185 16.0143C12.9236 15.4781 12.0765 15.4781 11.5815 16.0143L6.6174 21.3921C6.30868 21.7265 5.75 21.5081 5.75 21.0529V6.75Z"
        stroke="black"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_682_3466">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
