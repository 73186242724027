import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as LogoSvg } from 'images/logo/Logo.svg';
import { ReactComponent as BellSvg } from 'images/Bell.svg';
import { Button } from '../layout/Button';
import { UserDropdown } from '../User/UserDropdown';
import style from './HeaderStyle.module.css';

export default function CreateArticleHeader(props: { setPublish: Dispatch<SetStateAction<boolean>> }) {
  const history = useHistory();
  return (
    <div className={style.artHeaderMain}>
      <div className={style.wrapper}>
        <div>
          <LogoSvg width={100} height={52} style={{ cursor: 'pointer' }} onClick={() => history.push('/feed')} />
        </div>
        <div className={style.rightBlocks}>
          <Button className={style.publishButton} onClick={() => props.setPublish(true)}>
            {'Publish'}
          </Button>
          <div className={style.questionButton}>{'?'}</div>

          <div className={style.bellButton}>
            <BellSvg width={24} height={24} style={{ margin: '0 24px' }} />
            <UserDropdown />
          </div>
        </div>
      </div>
    </div>
  );
}
