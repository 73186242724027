import React, { useState } from 'react';
import { TinyEditor } from '../Editor/tinymce';
import { useHistory } from 'react-router-dom';
import MessageHockPopUp from '../../hooks/MessageHockPopUp';
import MobileCreateArticlePage2 from './pages/MobileCreateArticlePage2';
import styles from './EditCreateArticle.module.css';

function EditCreateArticleMobileForm() {
  const [fieldValue, setFieldValue] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [page, setPage] = useState(false);
  const [title, setTitle] = useState('');

  const history = useHistory();
  const goToNextPage = () => {
    if (!title.length) {
      MessageHockPopUp({ message: 'Not allowed empty title', timeOut: 4000, type: 'warning', size: 'small' });
    } else {
      setPage(true);
    }
  };

  return (
    <div className={styles.mobileMainEditor}>
      {!page ? (
        <>
          <div className={styles.mobileTitleBlock}>
            <span>Title :</span>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className={styles.mobileEditArea}>
            <TinyEditor value={fieldValue} onChange={(e) => setFieldValue(e)} setWords={setWordCount} />
          </div>
          <div style={{ marginTop: '14px' }}>
            {wordCount} {wordCount === 1 ? 'word' : 'words'}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <div className={styles.mobileButtonEditPage} onClick={() => history.goBack()}>
              Cancel
            </div>
            <div className={styles.mobileButtonEditPage} onClick={goToNextPage}>
              Next
            </div>
          </div>
        </>
      ) : (
        <MobileCreateArticlePage2 fieldValue={fieldValue} title={title} setPage={setPage} wordCount={wordCount} />
      )}
    </div>
  );
}

export default EditCreateArticleMobileForm;