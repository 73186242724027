export const shareIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4172 19.772C15.4164 19.1796 15.575 18.5979 15.8762 18.0877L8.30551 13.6785C8.01266 14.1814 7.59322 14.5986 7.08861 14.8886C6.58399 15.1786 6.01214 15.3313 5.43013 15.3311C4.54694 15.3308 3.69958 14.9798 3.07507 14.3553C2.45057 13.7308 2.09996 12.8838 2.09961 12.0006C2.09996 11.1175 2.45117 10.2708 3.07572 9.64652C3.70026 9.02223 4.54706 8.67138 5.43013 8.67138C6.01233 8.67112 6.58441 8.8236 7.08925 9.1136C7.59409 9.40361 8.01373 9.82099 8.3068 10.3241L15.871 5.90833C15.5733 5.39936 15.4164 4.82012 15.4172 4.23048C15.4173 3.79294 15.5039 3.35964 15.6715 2.95547C15.8391 2.55131 16.0844 2.18404 16.3939 1.87478C16.7034 1.56552 17.0709 1.32031 17.4752 1.15303C17.8795 0.985751 18.3127 0.89979 18.7503 0.899964C19.1876 0.899964 19.6206 0.985964 20.0246 1.15335C20.4286 1.32074 20.7955 1.56615 21.1047 1.87543C21.4138 2.18471 21.6592 2.55205 21.8264 2.95612C21.9936 3.36018 22.0797 3.79318 22.0795 4.23048C22.0797 4.66772 21.9937 5.10053 21.8264 5.50452C21.6592 5.90851 21.4138 6.27572 21.1047 6.58489C20.7955 6.89406 20.4286 7.13907 20.0246 7.30632C19.6206 7.47356 19.1875 7.55956 18.7503 7.55938C18.1678 7.55971 17.5957 7.40755 17.0905 7.11781C16.5852 6.82807 16.1649 6.41118 15.871 5.90833L8.3068 10.3241C8.60358 10.8329 8.76002 11.4116 8.75935 12.0006C8.76032 12.5899 8.60341 13.1688 8.30551 13.6772L15.8762 18.0864C16.1695 17.5846 16.5894 17.1683 17.0937 16.8792C17.598 16.5902 18.169 16.4383 18.7503 16.4389C19.1877 16.4389 19.6212 16.5252 20.0253 16.6926C20.4293 16.8601 20.7961 17.1056 21.1053 17.415C21.4145 17.7245 21.6599 18.0918 21.8271 18.4961C21.9942 18.9003 22.0798 19.3333 22.0795 19.7707C22.0795 20.6537 21.729 21.5005 21.1047 22.1248C20.4803 22.7492 19.6332 23.1 18.7503 23.1C17.8671 23.1003 17.0202 22.7498 16.3952 22.1258C15.7702 21.5018 15.4182 20.6552 15.4172 19.772V19.772Z"
      stroke="#EDEFF0"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
