import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { groupVextBlueLogo } from '../../assets/LogoFrames/groupVextBlueLogo';
import PasswordInput from '../../helpers/PasswordInput';
import { StatisticsApiService } from '../../services/statistics.service';
import styles from './recoverStyles.module.css';

function ResetAccount({
  submit,
}: {
  submit: (password: string, confirmPassword: string, termsAndCondAccepted: boolean, params: any) => void;
}) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAndCondAccepted, setTermsAndCondAccepted] = useState(false);
  const [email, setEmail] = useState('');
  const params = useParams() as any;

  useEffect(() => {
    if (params.id) {
      StatisticsApiService.getEmailByKey({ key: params.id }).then((res) => {
        if (res.data.email) {
          setEmail(res.data.email);
        }
      });
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAndCondAccepted(event.target.checked);
  };

  return (
    <div className={styles.recoverBlock}>
      <div className={styles.leftBlock}>
        <div>{groupVextBlueLogo()}</div>
        <div className={styles.description}>
          Please add a <b>password</b> for your <span className={styles.email}>{email}</span> account!
        </div>
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.rightWrapper}>
          <PasswordInput label={'Password'} value={password} setValue={setPassword} />
          <PasswordInput label={'Reenter password'} value={confirmPassword} setValue={setConfirmPassword} />

          <div className={styles.termsAndCond}>
            <Checkbox
              onChange={handleChange}
              sx={{
                marginLeft: '-12px',
                color: '#519b65',
                '&.Mui-checked': {
                  color: '#57a66d',
                },
              }}
            />
            By clicking, you agree to the VEXT's{' '}
            <a
              href="https://docs.google.com/document/d/1uUR2bg4Xgjku_pdoPOByX5lyUHZQZbZ75hBFhOIu1nU/edit"
              target="_blank"
              rel="noreferrer"
              className={styles.footerLink}
            >
              <span style={{ color: '#61B779', marginLeft: '4px' }}>Master Service Agreement</span>
            </a>
          </div>
          <div
            onClick={() => submit(password, confirmPassword, termsAndCondAccepted, params)}
            className={styles.activateButton}
          >
            Activate account
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetAccount;