import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { ArticleForm } from '../../types/Paper';
import { TinyEditor } from '../Editor/tinymce';
import Select, { MultiValue } from 'react-select';
import { ArticlesApiService } from '../../services/articles.service';
import { imageLogo } from 'images/logo/imageLogo';
import { selectUserId } from '../../store/auth';
import { clearUserState } from '../../store/user';
import { checkboxUnchecked } from 'images/checkboxes/checkboxUnchecked';
import { checkboxChecked } from 'images/checkboxes/checkboxChecked';
import { useToken } from '../../cookies';
import Switch from 'react-switch';
import MessageHockPopUp from 'src/hooks/MessageHockPopUp';
import { getAllGroupsNames, selectAllGroupsName } from 'src/store/groups';
import ImageInput from '../Settings/Items/ImageInput';
import { selectTags } from '../../store/tags/selectors';
import './style.css';
import { getPaperFormData } from './helper/createArticleHelper';
import styles from './EditCreateArticle.module.css';

export default function EditCreateArticleForm(props: {
  initialData?: ArticleForm | null;
  courseId?: string;
  isPublished?: boolean;
  onSubmitCallback?: (values: ArticleForm) => Promise<void>;
  onPublishCourse?: () => void;
  setPublish: Dispatch<SetStateAction<boolean>>;
  paperId?: string;
}) {
  const ref = useRef(null);
  const history = useHistory();
  const tags = useSelector(selectTags);
  const groups = useSelector(selectAllGroupsName);
  const { token } = useToken();
  const userId = useSelector(selectUserId);
  const [isInputTitle, setIsInputTitle] = useState(false);
  const [fieldValue, setFieldValue] = useState(props.initialData?.content || '');
  const [articleTitle, setArticleTitle] = useState(props.initialData?.title || '');
  const [articleDescription, setArticleDescription] = useState(props.initialData?.description || '');
  const [imageInputUrl, setImageInputUrl] = useState(props.initialData?.image || null);
  const [wordCount, setWordCount] = useState(0);
  const [isImageError, setImageError] = useState('');
  const [isCommentsOn, setIsCommentsOn] = useState<boolean>(props.initialData?.isCommentsOn ?? true);
  const [isSharePaperOn, setIsSharePaperOn] = useState<boolean>(props.initialData?.isSharePaperOn ?? true);
  const [areConditionsAgree, setAreConditionsAgree] = useState(false);
  const [articleTags, setArticleTags] = useState<Array<{ id: string }>>();
  const [articleGroups, setArticleGroups] = useState<Array<{ id: string }>>();
  const [isFirstBlock, setIsFirst] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const tagList = tags.map((tag) => {
    return { value: tag.id, label: tag.name };
  });

  const groupList = groups.map((group) => {
    return { value: Object.keys(group)[0], label: Object.values(group)[0] };
  });

  const notify = () => {
    error.length && MessageHockPopUp({ message: error, timeOut: 4000, type: 'error' });
  };

  useEffect(() => {
    notify();
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    dispatch(getAllGroupsNames(token));
  }, []);

  useEffect(() => {
    if (props.initialData?.id) {
      setArticleTags(
        props.initialData?.paper_tags?.map((tag) => {
          return { id: tag.id };
        }),
      );
    }
    // eslint-disable-next-line
  }, []);

  const handleClickOutside = () => {
    setIsInputTitle(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const submit = async () => {
    // Edit paper
    if (props.initialData?.id && props.paperId && articleTitle.length > 1) {
      ArticlesApiService.editArticle(
        getPaperFormData({
          articleTitle,
          articleDescription,
          wordCount,
          fieldValue,
          articleTags,
          articleGroups,
          isCommentsOn,
          isSharePaperOn,
          userId,
          imageInputUrl,
        }),
        token,
        props.paperId,
      ).then((res) => {
        if (res.ok) {
          MessageHockPopUp({
            message: 'Successfully edited',
            timeOut: 4000,
            type: 'success',
            size: 'small',
          });
          history.push('feed');
        }
      });
    } else {
      // Create paper
      if (articleTitle.length > 1) {
        ArticlesApiService.postArticle(
          getPaperFormData({
            articleTitle,
            articleDescription,
            wordCount,
            fieldValue,
            articleTags,
            articleGroups,
            isCommentsOn,
            isSharePaperOn,
            userId,
            imageInputUrl,
          }),
          token,
        )
          .then((res) => {
            if (!res.ok) {
              if (res.status === 401) {
                setError('Invalid auth token');
                dispatch(clearUserState());
                throw new Error('Invalid auth token');
              }
              throw new Error('Error');
            } else {
              setIsSubmitted(true);
              setTimeout(() => {
                setIsFirst(true);
                setTimeout(() => {
                  history.push('/feed');
                  setIsSubmitted(false);
                }, 1000);
              }, 2000);
            }
          })
          .catch(() => {
            history.push('/feed');
          });
      }
    }
  };

  const selectedArticles = (e: MultiValue<{ value: string; label: string }>) => {
    const selectedTags = e.map((e) => {
      return { id: e.value };
    });
    setArticleTags(selectedTags);
  };

  const selectedGroups = (e: MultiValue<{ value: string; label: string }>) => {
    const selectedGroups = e.map((e) => {
      return { id: e.value };
    });
    setArticleGroups(selectedGroups);
  };

  return (
    <form noValidate className={styles.mainForm}>
      {isSubmitted && (
        <div className={styles.submittedArticleBlock}>
          {!isFirstBlock ? (
            <div className="o block2">
              <div className={styles.submittedArticleBlockBackground}>
                {imageLogo('#1D00D1', '#7681EB', '#A683FF', '#2E57DF', '#551DD3', '#C5B5FF')}
              </div>
            </div>
          ) : (
            <div className="o block2">
              <div className={styles.submittedArticleBlockBackground}>
                {imageLogo('#01B128', '#76EB82', '#94FF83', '#00A911', '#1EC510', '#B5FFBC')}
              </div>
              <div className={styles.submittedArticleBlockInfo}>Successfully published!</div>
            </div>
          )}
        </div>
      )}

      {!props.isPublished ? (
        <>
          {!isInputTitle ? (
            <div
              onClick={() => {
                setIsInputTitle(true);
              }}
              className={styles.articleInputTitle}
            >
              {articleTitle.length ? <span style={{ color: '#222222da' }}>{articleTitle}</span> : 'Title'}
            </div>
          ) : (
            <input
              onKeyPress={(e) => {
                if (e.code === 'Enter') {
                  setIsInputTitle(false);
                }
              }}
              onChange={(e) => setArticleTitle(e.target.value)}
              ref={ref}
              type="text"
              name="title"
              value={articleTitle}
              className={styles.articleInputTitleEl}
            />
          )}
          <div className={styles.articleTinyMcWrapper}>
            <TinyEditor value={fieldValue} onChange={(e) => setFieldValue(e)} setWords={setWordCount} />
          </div>

          {!!wordCount && <div className={styles.articleWordCount}>{wordCount} Words</div>}
        </>
      ) : (
        <>
          <div className={styles.articleCreateStep2}>
            <div className={styles.articleCreateStep2CloseIcon} onClick={() => props.setPublish(false)}>
              &#10005;
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <div className={styles.articleCreateStep2ContentBlock}>
                <span className={styles.articleCreateStep2TextLabelTop}>Title</span>
                <input
                  autoComplete="off"
                  type="text"
                  name="description"
                  value={articleTitle}
                  onChange={(e) => setArticleTitle(e.target.value)}
                  placeholder="Write a preview title"
                  className={styles.articleCreateStep2Input}
                />
                <span className={styles.articleCreateStep2TextLabel}>Description</span>
                <input
                  autoComplete="off"
                  type="text"
                  name="coverTitle"
                  value={articleDescription}
                  onChange={(e) => setArticleDescription(e.target.value)}
                  placeholder="Write a preview description"
                  className={styles.articleCreateStep2Input}
                />
                <span className={styles.articleCreateStep2TextLabel}>
                  {isImageError.length ? (
                    <span style={{ color: 'red' }}>{isImageError}</span>
                  ) : (
                    <span>Cover Image</span>
                  )}
                </span>

                <ImageInput setImage={setImageInputUrl} isPaperImage />
              </div>

              <div className={styles.articleCreateStep2ContentBlock}>
                <span className={styles.articleCreateStep2TextLabelTop}>Tags</span>
                <Select
                  isMulti
                  name="tags"
                  options={tagList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e: any) => selectedArticles(e)}
                  defaultValue={props.initialData?.paper_tags?.map((tag) => {
                    return { value: tag.id, label: tag.name };
                  })}
                />

                <span className={styles.articleCreateStep2TextLabel}>Select groups</span>
                <Select
                  isMulti
                  name="groups"
                  options={groupList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e: any) => selectedGroups(e)}
                />
                <span className={styles.articleCreateStep2TextLabel}>Comments</span>
                <div className={styles.articleCreateStep2SwitchBlock}>
                  <span className={styles.articleCreateStep2SwitchLabel}>Turn on/off comment for your paper</span>
                  <div>
                    {/* @ts-ignore*/}
                    <Switch
                      onChange={() => setIsCommentsOn(!isCommentsOn)}
                      checked={isCommentsOn}
                      onColor="#3db860"
                      offColor="#C2C2C2"
                      checkedIcon={false}
                      uncheckedIcon={false}
                    />
                  </div>
                </div>
                <div className={styles.articleCreateStep2SwitchBlock}>
                  <span className={styles.articleCreateStep2SwitchLabel}>Keep my paper searchable on VEXT</span>
                  <div>
                    {/* @ts-ignore*/}
                    <Switch
                      onChange={() => setIsSharePaperOn(!isSharePaperOn)}
                      checked={isSharePaperOn}
                      onColor="#3db860"
                      offColor="#C2C2C2"
                      checkedIcon={false}
                      uncheckedIcon={false}
                    />
                  </div>
                </div>
                <div className={styles.articleCreateStep2SwitchBlock}>
                  <a
                    href="https://docs.google.com/document/d/1uUR2bg4Xgjku_pdoPOByX5lyUHZQZbZ75hBFhOIu1nU/edit"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.footerLink}
                    style={{ textDecoration: 'none' }}
                  >
                    <span className={styles.articleCreateStep2SwitchLabel}>
                      I agree to{' '}
                      <span style={{ color: '#3bb15c', fontWeight: 700, cursor: 'pointer' }}>VEXT Honor Code</span>
                    </span>
                  </a>

                  <div>
                    <div style={{ cursor: 'pointer' }} onClick={() => setAreConditionsAgree(!areConditionsAgree)}>
                      {areConditionsAgree ? checkboxChecked() : checkboxUnchecked()}
                    </div>
                  </div>
                </div>
                {}
                <div className={styles.articleCreateStep2SubmitBlock}>
                  {areConditionsAgree ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        submit();
                      }}
                      className={styles.articleCreateStep2SubmitActiveButton}
                    >
                      Publish now
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      className={styles.articleCreateStep2SubmitInctiveButton}
                    >
                      Publish now
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  );
}
