import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ADDLikeDislikeResponse, ArticlePayload, IComment, Paper, PaperType } from '../../types/Paper';
import { isPendingAction, isRejectedAction } from '../../utils/redux.utils';
import { ArticlesApiService } from '../../services/articles.service';
import { User } from '../../types/User';
import { Tag } from '../../types/Tag';

export const ARTICLES_REDUCER_NAME = 'articles';

interface IArticleDataInitialState {
  error: null | string;
  papers: { [key: string]: Paper };
  isLoading: boolean;
  isArticleLoading: boolean;
  page: number;
  next: string | null;
  typeFilter: 'All' | 'QUESTION' | 'ARTICLE';
  searchResult: Array<Partial<Paper>>;
  selectedPaper: Paper;
  tags: { [key: string]: Tag };
  myArticles?: Array<Paper>;
}

const initialState: IArticleDataInitialState = {
  error: null,
  isArticleLoading: false,
  isLoading: false,
  papers: {},
  page: 1,
  next: null,
  searchResult: [],
  selectedPaper: {
    content: '',
    created: '',
    description: '',
    duration: 0,
    image: '',
    paper_tags: [],
    likes: [],
    title: '',
    type: PaperType.ARTICLE,
    updated: '',
    id: '',
    views: 0,
    comments: [],
  },
  typeFilter: 'All',
  tags: {},
  myArticles: [],
};

export const getMyArticles = createAsyncThunk(
  `${ARTICLES_REDUCER_NAME}/getMyArticles`,
  async ({ token, userId }: { token: string; userId: string }) => {
    const myArticlesResponse = await ArticlesApiService.getMyArticles(token, userId);

    if (!myArticlesResponse.ok) {
      if (myArticlesResponse.status === 404) {
        throw new Error('URL Address Not Found');
      }
      if (myArticlesResponse.status === 401) {
        throw new Error('Unauthorized');
      }
      throw new Error('Error');
    }

    return myArticlesResponse.data || [];
  },
);

export const searchArticles = createAsyncThunk(
  `${ARTICLES_REDUCER_NAME}/searchArticles`,
  async ({ token, param, tagName }: { token: string; param?: string; tagName?: Array<string> }) => {
    const articlesResponse = await ArticlesApiService.searchArticles(token, param, tagName);

    if (!articlesResponse.ok) {
      if (articlesResponse.status === 404) {
        throw new Error('URL Address Not Found');
      }
      if (articlesResponse.status === 401) {
        throw new Error('Unauthorized');
      }

      throw new Error('Error');
    }

    return { data: articlesResponse.data?.data || [], tagName };
  },
);

export const getPaperById = createAsyncThunk(
  `${ARTICLES_REDUCER_NAME}/getPaperyId`,
  async ({ token, id }: { token: string; id: string }) => {
    const paperResponse = await ArticlesApiService.getPaperById(token, id);

    if (!paperResponse.ok || !paperResponse.data) {
      throw new Error('Error');
    }
    const { data } = paperResponse;

    return data;
  },
);

export const removeArticle = createAsyncThunk(
  `${ARTICLES_REDUCER_NAME}/removeArticle`,
  async ({ token, id }: { token: string; id: string }) => {
    const paperResponse = await ArticlesApiService.removeArticle(token, id);

    if (!paperResponse.ok) {
      throw new Error('Not deleted');
    } else {
      return { articleId: id };
    }
  },
);

export const removeComment = createAsyncThunk(
  `${ARTICLES_REDUCER_NAME}/removeComment`,
  async ({ token, id }: { token: string; id: string }) => {
    const paperResponse = await ArticlesApiService.removeComment(token, id);

    if (!paperResponse.ok) {
      throw new Error('Not deleted');
    }
    return id;
  },
);

export const createTag = createAsyncThunk(
  `${ARTICLES_REDUCER_NAME}/createTag`,
  async ({ token, name }: { token: string; name: string }) => {
    const tagResponse = await ArticlesApiService.createTag(name, token);

    if (!tagResponse.ok) {
      throw new Error('Not created');
    }
    return tagResponse.data;
  },
);

export const removeTag = createAsyncThunk(
  `${ARTICLES_REDUCER_NAME}/removeTag`,
  async ({ token, id }: { token: string; id: string }) => {
    const tagResponse = await ArticlesApiService.removeTag(id, token);

    if (!tagResponse.ok) {
      throw new Error('Not deleted');
    }
    return id;
  },
);

export const createComment = createAsyncThunk(
  `${ARTICLES_REDUCER_NAME}/createComment`,
  async ({
    token,
    userId,
    articleId,
    comment,
    repliedToCommentId,
    paragraphId,
    anonymous_name,
  }: {
    token: string;
    userId: string;
    articleId: string;
    comment: string;
    repliedToCommentId?: string;
    paragraphId?: string;
    anonymous_name?: string;
  }) => {
    const commentResponse = await ArticlesApiService.createComment(
      userId,
      comment,
      articleId,
      token,
      repliedToCommentId,
      paragraphId,
      anonymous_name,
    );
    if (!commentResponse.ok || !commentResponse.data) {
      throw new Error('Error');
    }

    const { data } = commentResponse;

    return data as IComment;
  },
);

export const addOrRemovePaperLike = createAsyncThunk(
  `${ARTICLES_REDUCER_NAME}/addOrRemovePaperLike`,
  async ({
    token,
    author,
    articleId,
    isAddLike,
  }: {
    token: string;
    author: User;
    articleId: string;
    isAddLike: boolean;
  }) => {
    const addLikeDislikeResponse = await ArticlesApiService.addPaperLike({
      token,
      authorId: author.id,
      articleId,
    });

    if (!addLikeDislikeResponse.ok || addLikeDislikeResponse.status !== 200) {
      throw new Error('Failed');
    }

    return { author, isAddLike, articleId };
  },
);

export const addOrRemoveCommentLike = createAsyncThunk(
  `${ARTICLES_REDUCER_NAME}/addCommentLikeOrDislike`,
  async ({
    token,
    commentId,
    isAddLike,
    authorId,
  }: {
    token: string;
    isAddLike: boolean;
    commentId: string;
    authorId: string;
  }) => {
    const addLikeDislikeResponse = await ArticlesApiService.addOrRemoveCommentLike({
      token,
      commentId,
    });
    if (!addLikeDislikeResponse.ok || !addLikeDislikeResponse.data) {
      throw new Error('Failed');
    }
    const { data } = addLikeDislikeResponse;
    return { data, authorId, isAddLike, commentId };
  },
);

export const getArticles = createAsyncThunk(
  `${ARTICLES_REDUCER_NAME}/getArticles`,
  async ({ token, groupId, page }: { token: string; groupId: string; page?: number }) => {
    const articlesResponse = await ArticlesApiService.getArticles(token, groupId, page);

    if (!articlesResponse.ok) {
      if (articlesResponse.status === 404) {
        throw new Error('URL Address Not Found');
      }
      if (articlesResponse.status === 401) {
        throw new Error('Unauthorized');
      }
      throw new Error('Error');
    }

    return { data: articlesResponse.data, page };
  },
);

const articlesSlice = createSlice({
  name: ARTICLES_REDUCER_NAME,
  initialState,
  reducers: {
    clearArticlesState(state) {
      state.papers = {};
      state.tags = {};
      state.error = null;
    },
    clearSelectedPaper(state) {
      state.selectedPaper = initialState.selectedPaper;
    },
    changeSelectedTypeFilter(state, { payload }) {
      state.typeFilter = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArticles.fulfilled, (state, { payload }: PayloadAction<ArticlePayload | any>) => {
        if (!payload.data?.results) return; // TODO add correct type then remove this check
        state.isLoading = false;
        state.next = payload.data.next;
        if (!payload.page) {
          state.papers = payload.data.results.reduce(
            (acc: { [key: string]: Paper }, paper: Paper) => Object.assign(acc, { [paper.id]: paper }),
            {},
          );
          state.page = 1;
        } else {
          state.papers = {
            ...state.papers,
            ...payload.data.results.reduce(
              (acc: { [key: string]: Paper }, paper: Paper) => Object.assign(acc, { [paper.id]: paper }),
              {},
            ),
          };
          state.page = payload.page;
        }
        state.error = null;
      })
      .addCase(getMyArticles.fulfilled, (state, { payload }: PayloadAction<Array<Paper>>) => {
        state.myArticles = payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(removeArticle.fulfilled, (state, { payload }: PayloadAction<any>) => {
          state.isLoading = false;
          state.isArticleLoading = false;
          delete state.papers[payload.articleId];
      })
      .addCase(getPaperById.fulfilled, (state, { payload }: PayloadAction<Paper>) => {
        state.isArticleLoading = false;
        state.selectedPaper = payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(
        searchArticles.fulfilled,
        (state, { payload }: PayloadAction<{ data: Array<Paper>; tagName: Array<string> | undefined }>) => {
          if (payload.tagName) {
            state.papers = payload.data
              .reverse()
              .reduce((acc: { [key: string]: Paper }, paper: Paper) => Object.assign(acc, { [paper.id]: paper }), {});
            return;
          }
          state.searchResult = payload.data;
          state.error = null;
          state.isLoading = false;
        },
      )
      .addCase(removeComment.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.selectedPaper.comments = state.selectedPaper.comments.filter((comment) => comment.id !== payload);
        state.error = null;
        state.isLoading = false;
      })
      .addCase(createTag.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.tags = Object.assign(state.tags, { [payload.id]: payload });
        state.error = null;
        state.isLoading = false;
      })
      .addCase(removeTag.fulfilled, (state, { payload }: PayloadAction<any>) => {
        delete state.tags[payload];
        state.error = null;
        state.isLoading = false;
      })

      .addCase(addOrRemovePaperLike.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const { author, isAddLike, articleId } = payload;
        if (isAddLike) {
          state.selectedPaper.likes.push({
            ...author,
          });
          state.papers[articleId].user_liked = true;
          //in this situation likes is just a number
          state.papers[articleId].likes = ((state.papers[articleId].likes as any) + 1) as any;
        } else {
          state.selectedPaper.likes = state.selectedPaper.likes.filter((likeAuthor) => likeAuthor.id !== author.id);
          state.papers[articleId].user_liked = false;
          state.papers[articleId].likes = ((state.papers[articleId].likes as any) - 1) as any;
        }
        state.isLoading = false;
      })

      .addCase(addOrRemoveCommentLike.fulfilled, (state, { payload }: PayloadAction<ADDLikeDislikeResponse>) => {
        if (!payload.isAddLike) {
          const filteredCommentsLike = state.selectedPaper.comments.map((comment) => {
            if (payload.commentId === comment.id) {
              const filteredLikes = comment.likes?.filter((like) => like.id !== payload.authorId);
              comment.likes = filteredLikes || [];
            }
            return comment;
          });
          state.selectedPaper.comments = filteredCommentsLike;
        } else {
          const newCommentsLikeList = state.selectedPaper.comments.map((comment) => {
            if (payload.commentId === comment.id) {
              comment.likes?.push({
                first_name: '',
                graduation: '',
                id: payload.authorId,
                image_url: '',
                last_name: '',
                major: '',
                nickname: '',
                role: '',
              });
            }

            return comment;
          });
          state.isLoading = false;
          state.selectedPaper.comments = newCommentsLikeList;
        }
      })
      .addCase(createComment.fulfilled, (state, { payload }: PayloadAction<IComment>) => {
        state.selectedPaper.comments = [...state.selectedPaper.comments, payload];
        // @ts-ignore
        if (state.papers[payload.articleId]) {
          // @ts-ignore
          state.papers[payload.articleId].comments = state.papers[payload.articleId].comments + 1;
        }
        state.error = null;
        state.isLoading = false;
      })
      .addMatcher(isPendingAction(`${ARTICLES_REDUCER_NAME}/`), (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isPendingAction(`${ARTICLES_REDUCER_NAME}/getPaperyId`), (state) => {
        state.isLoading = true;
        state.isArticleLoading = true;
        state.error = null;
      })
      .addMatcher(isRejectedAction(`${ARTICLES_REDUCER_NAME}/`), (state, { error }) => {
        state.error = error.message;
        state.isLoading = false;
      });
  },
});

export const {
    clearArticlesState,
    clearSelectedPaper,
    changeSelectedTypeFilter,
} = articlesSlice.actions;
export default articlesSlice.reducer;
