import { userPlusIcon } from 'images/users/userPlusIcon';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import GroupFellingFlag from './components/GroupFellingFlag';
import styles from './GroupCardStyles.module.css';

import { SmallVextLogo } from '../../assets/LogoFrames/SmallVextLogo';
import { userNameFromAllParams } from '../../helpers/userName';
import { IGroup } from '../../types/Group';
import { MOBILE_INVITE_FRIEND } from '../../utils/constants';
import { ImagesTemplate } from '../layout/ImagesTemplate';
import { RechartsGroupMood } from '../Recharts/RechartsGroupMood';

export function GroupDetailsMobile({ group }: { group: IGroup }) {
  const [selectedTop, setSelectedTop] = useState(true);
  const history = useHistory();
  const { admin } = group as any;

  return (
    <div className={styles.groupDetailsInfoBlock}>
      {admin?.profile ? (
        <div className={styles.groupDetailsInfoUser} onClick={() => history.push(`/user/${admin?.id}`)}>
          <ImagesTemplate url={admin?.profile?.image_url} height={70} width={70} />
          <div className={styles.groupDetailsUserBiography}>
            <span>{userNameFromAllParams(admin.username, admin?.profile?.first_name, admin?.profile?.last_name)}</span>
            <span>{admin.profile.graduation}</span>
            <span>{admin.profile.faculty}</span>
            <span>{admin.profile.major}</span>
          </div>
        </div>
      ) : (
        <span className={styles.adminName}>
          Admin: {userNameFromAllParams(admin?.username, admin?.profile?.first_name, admin?.profile?.last_name)}
        </span>
      )}
      <GroupFellingFlag group={group} />

      <RechartsGroupMood />
      <div className={styles.inviteFriend}>
        {group.allow_incognito !== 'NO' && <div>Incognito users are welcome</div>}
        <div
          className={styles.inviteFriendsButton}
          onClick={() =>
            history.push({
              pathname: MOBILE_INVITE_FRIEND,
              state: group.id,
            })
          }
        >
          {userPlusIcon()} Invite friends
        </div>
      </div>
      <div className={styles.groupDetailsFooter}>
        <div className={styles.groupDetailsTops}>
          <span onClick={() => setSelectedTop(true)} className={selectedTop ? styles.selectedTab : styles.notSelected}>
            Top posts
          </span>
          <span
            onClick={() => setSelectedTop(false)}
            className={!selectedTop ? styles.selectedTab : styles.notSelected}
          >
            Top users
          </span>
        </div>
        <div className={styles.noPosts}>
          {SmallVextLogo()}
          <span style={{ marginTop: '18px' }}>-</span>
        </div>
      </div>
    </div>
  );
}
