export function needToUpdateReportStatus({ object }: { object: any }) {
  const createdAt = new Date(object.created_at);

  if (object.created_at === object.updated_at) {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    if (
      createdAt.getUTCFullYear() === today.getUTCFullYear() &&
      createdAt.getUTCMonth() === today.getUTCMonth() &&
      createdAt.getUTCDate() === today.getUTCDate()
    ) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}
