export const reportIcon = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.22796 13.3429C1.7733 13.3429 1.33727 13.1623 1.01577 12.8408C0.694284 12.5193 0.513672 12.0833 0.513672 11.6287V4.0858C0.513672 3.63114 0.694284 3.19511 1.01577 2.87361C1.33727 2.55212 1.7733 2.37151 2.22796 2.37151H3.31396C3.46449 2.11079 3.68102 1.8943 3.94178 1.74384C4.1591 1.61844 4.40075 1.54258 4.6495 1.52086C4.73206 1.51366 4.79805 1.44666 4.81249 1.36506C4.84289 1.19318 4.92533 1.03338 5.05044 0.908278C5.21118 0.747532 5.4292 0.657227 5.65653 0.657227H6.34224C6.4548 0.657227 6.56626 0.679397 6.67026 0.722473C6.77425 0.765548 6.86874 0.828685 6.94833 0.908278C7.02793 0.987871 7.09106 1.08236 7.13414 1.18636C7.15802 1.24402 7.17548 1.30397 7.18629 1.36514C7.20072 1.44671 7.26667 1.51369 7.34919 1.52093C7.59782 1.54275 7.83932 1.61866 8.0565 1.74405C8.3171 1.89451 8.5335 2.11091 8.68396 2.37151H9.77081C10.2255 2.37151 10.6615 2.55212 10.983 2.87361C11.3045 3.19511 11.4851 3.63114 11.4851 4.0858V11.6287C11.4851 12.0833 11.3045 12.5193 10.983 12.8408C10.6615 13.1623 10.2255 13.3429 9.77081 13.3429H2.22796Z"
      stroke="black"
    />
    <path
      d="M8.91353 2.37146C8.91353 3.31823 8.14602 4.08575 7.19925 4.08575H4.79925C3.85247 4.08575 3.08496 3.31823 3.08496 2.37146"
      stroke="black"
    />
    <path
      d="M2.57129 7.23398H4.28557L5.57129 6.14307L6.857 7.85735L7.71415 6.6106L8.14272 7.23398H9.42843"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2.57129 9.91431H7.71415" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.57129 11.6287H5.99986" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
