import ArrowBackButton from '../../../components/mobile/Buttons/ArrowBackButton';
import React from 'react';
import styles from './styles.module.css';

export default function EmergencyStep1({
  setStepComponent,
  emergencyInfo,
  setEmergencyReason,
}: {
  setStepComponent: any;
  emergencyInfo: string;
  setEmergencyReason: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <div>
      <div className={styles.navbar}>
        <ArrowBackButton setStep={() => setStepComponent(0)} />
        {/*<div onClick={() => history.push('./user')}>{closeWhiteCircleIcon('40', '38', '#FFC700')}</div>*/}
      </div>
      <div className={styles.moreInfoBlock}>
        <h1>Give us more information</h1>
        <textarea
          onChange={(e) => setEmergencyReason(e.target.value)}
          value={emergencyInfo}
          name="emergency-comment"
          id="11"
          placeholder="Type your comment"
        />
      </div>
      <div className={styles.buttonsBlock}>
        <div className={styles.sendButton} onClick={() => setStepComponent(2)}>
          Send
        </div>
      </div>
    </div>
  );
}