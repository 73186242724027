export const HelpIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6004 53.8113C29.0997 53.5596 29.6528 53.4324 30.2116 53.4132C43.9976 52.9403 55.0284 41.6155 55.0284 27.7143C55.0284 13.5127 43.5157 2 29.3141 2C15.1125 2 3.59985 13.5127 3.59985 27.7143C3.59985 35.5529 7.1072 42.5723 12.6381 47.2888C13.5052 48.0281 14.057 49.0876 14.057 50.2271V55.3129C14.057 57.9866 16.8751 59.7219 19.2626 58.5184L28.6004 53.8113Z"
      fill="#0F4D79"
    />
    <path
      d="M30.4914 31.8575H26.9537C26.9629 31.0511 27.0277 30.3566 27.1479 29.7742C27.2774 29.1828 27.4947 28.6496 27.7999 28.1747C28.1144 27.6998 28.5306 27.2294 29.0486 26.7634C29.4833 26.3871 29.8625 26.0287 30.1862 25.6882C30.5099 25.3477 30.7642 24.9982 30.9492 24.6398C31.1342 24.2724 31.2267 23.8647 31.2267 23.4167C31.2267 22.897 31.1435 22.4668 30.977 22.1263C30.8197 21.7769 30.5793 21.5125 30.2555 21.3333C29.9411 21.1541 29.5434 21.0645 29.0624 21.0645C28.6647 21.0645 28.2948 21.1496 27.9526 21.3199C27.6103 21.4812 27.3282 21.7321 27.1063 22.0726C26.8935 22.4131 26.7826 22.8611 26.7733 23.4167H22.75C22.7777 22.1891 23.0691 21.1765 23.624 20.379C24.1882 19.5726 24.942 18.9767 25.8854 18.5914C26.8288 18.1971 27.8878 18 29.0624 18C30.3573 18 31.4672 18.2061 32.3921 18.6183C33.317 19.0215 34.0245 19.6174 34.5147 20.4059C35.0049 21.1855 35.25 22.1353 35.25 23.2554C35.25 24.0349 35.0928 24.7294 34.7783 25.3387C34.4638 25.9391 34.0523 26.4991 33.5436 27.0188C33.0349 27.5385 32.4753 28.0762 31.8649 28.6317C31.3377 29.0887 30.977 29.5681 30.7827 30.0699C30.5978 30.5717 30.5006 31.1676 30.4914 31.8575ZM26.5375 36.0108C26.5375 35.4373 26.7409 34.9624 27.1479 34.586C27.5548 34.2007 28.1005 34.0081 28.785 34.0081C29.4601 34.0081 30.0012 34.2007 30.4082 34.586C30.8244 34.9624 31.0325 35.4373 31.0325 36.0108C31.0325 36.5663 30.8244 37.0367 30.4082 37.422C30.0012 37.8073 29.4601 38 28.785 38C28.1005 38 27.5548 37.8073 27.1479 37.422C26.7409 37.0367 26.5375 36.5663 26.5375 36.0108Z"
      fill="white"
    />
  </svg>
);
