import { ApiResponse } from 'apisauce';
import { Notification } from 'src/types/Notifications';
import { _RestApiService } from './api.service';

const notificationsApi = new _RestApiService(window._env_.REACT_APP_BACKEND_URL || '');

export class _NotificationApiService {
  getAllNotifications(token: string): Promise<ApiResponse<Array<Notification>>> {
    return notificationsApi.get('/api/notifications/', {}, notificationsApi.getAuthenticateHeader(token));
  }

  markNotificationAsRead(token: string, notificationId: string): Promise<ApiResponse<Array<Notification>>> {
    return notificationsApi.patch(
      `/api/notifications/${notificationId}/mark_as_read/`,
      {},
      notificationsApi.getAuthenticateHeader(token),
    );
  }
}

export const NotificationsApiService = new _NotificationApiService();
