import { ApiResponse } from 'apisauce';
import { _RestApiService } from './api.service';

const uploadImageApi = new _RestApiService(window._env_.REACT_APP_BACKEND_URL || '');

export class _UploadImageApiService {
  uploadUserImg(formData: FormData, userId: string, token: string): Promise<ApiResponse<any>> {
    return uploadImageApi.put(
      `/api/users/${userId}/upload_photo/`,
      formData,
      uploadImageApi.getAuthenticateHeaderFormData(token),
    );
  }

  uploadUserBackgroundImg(formData: FormData, userId: string, token: string): Promise<ApiResponse<any>> {
    return uploadImageApi.put(
        `/api/users/${userId}/upload_background/`,
        formData,
        uploadImageApi.getAuthenticateHeaderFormData(token),
    );
  }
}

export const UploadImageApiService = new _UploadImageApiService();
