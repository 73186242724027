export const SuccessIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6004 53.8113C29.0997 53.5596 29.6528 53.4324 30.2116 53.4132C43.9976 52.9403 55.0284 41.6155 55.0284 27.7143C55.0284 13.5127 43.5157 2 29.3141 2C15.1125 2 3.59985 13.5127 3.59985 27.7143C3.59985 35.5529 7.1072 42.5723 12.6381 47.2888C13.5052 48.0281 14.057 49.0876 14.057 50.2271V55.3129C14.057 57.9866 16.8751 59.7219 19.2626 58.5184L28.6004 53.8113Z"
      fill="#1F8C3D"
    />
    <path
      d="M36.5294 22.4096C36.819 22.6828 36.9881 23.0597 36.9994 23.4573C37.0107 23.8549 36.8632 24.2407 36.5895 24.5298L28.0693 33.5309C27.9312 33.6765 27.7653 33.793 27.5813 33.8736C27.3973 33.9541 27.199 33.9971 26.9981 33.9999C26.7972 34.0027 26.5978 33.9652 26.4116 33.8898C26.2255 33.8144 26.0563 33.7026 25.9142 33.5609L21.4035 29.0603C21.138 28.776 20.9934 28.3998 21.0002 28.0112C21.0071 27.6225 21.1649 27.2517 21.4404 26.9769C21.7159 26.702 22.0875 26.5446 22.477 26.5377C22.8666 26.5308 23.2435 26.6751 23.5286 26.9401L26.9467 30.3485L34.4043 22.4696C34.6782 22.1805 35.0559 22.0119 35.4544 22.0006C35.8529 21.9893 36.2395 22.1365 36.5294 22.4096Z"
      fill="white"
    />
  </svg>
);
