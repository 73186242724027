import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorGuard } from '../../utils/errorGuard';
import { ERROR } from '../../errorsTemplates';
import { isPendingAction, isRejectedAction } from '../../utils/redux.utils';
import { CommonApiService } from '../../services/common.service';
import { SearchResponse } from '../../types/Common';

export const GLOBAL_SEARCH_REDUCER_NAME = 'gSearch';

type InitialStateType = {
  isSearchTabOpen: boolean;
  error: null | string;
  searchedResult: SearchResponse;
};

const initialState: InitialStateType = {
  searchedResult: { groups: [], papers: [], users: [] },
  isSearchTabOpen: false,
  error: null,
};

export const search = createAsyncThunk(
  `${GLOBAL_SEARCH_REDUCER_NAME}/global`,
  async ({ token, searchedValue }: { token: string; searchedValue: string }) => {
    try {
      const response = await CommonApiService.globalSearch(token, searchedValue);
      return response.data;
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

const searchSlice = createSlice({
  name: GLOBAL_SEARCH_REDUCER_NAME,
  initialState,
  reducers: {
    closeSearchTab(state) {
      state.isSearchTabOpen = false;
      state.searchedResult = initialState.searchedResult;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(search.fulfilled, (state, { payload }: PayloadAction<SearchResponse | undefined>) => {
        if (payload) {
          const { groups, papers, users } = payload;
          state.searchedResult = payload;
          if (users.length || groups.length || papers.length) {
            state.isSearchTabOpen = true;
          } else {
            state.isSearchTabOpen = false;
          }
          state.error = null;
        }
      })
      .addMatcher(isPendingAction(`${GLOBAL_SEARCH_REDUCER_NAME}/`), (state) => {
        state.error = null;
      })
      .addMatcher(isRejectedAction(`${GLOBAL_SEARCH_REDUCER_NAME}/`), (state, { error }) => {
        state.error = 'Search error';
      });
  },
});

export const { closeSearchTab } = searchSlice.actions;
export default searchSlice.reducer;
