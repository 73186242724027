import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export const ReportPopover = createTheme({
  components: {
    MuiList: {
      styleOverrides: {
        padding: { width: '91vw', backgroundColor: '#fff', borderRadius: '8px', padding: '0' },
      },
    },
    MuiListItem: {
      styleOverrides: {
        padding: {
          color: '#000',
        },
      },
    },
  },
});