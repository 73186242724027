export const vectorUp = (color = 'white') => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8926 7.35718L6.99972 1.46432L1.10686 7.35718"
      stroke={color}
      strokeWidth="1.17857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
