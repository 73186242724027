import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGroups,
  getPinnedGroups,
  selectGroups,
  selectGroupsHasNextPage,
  selectGroupsPage,
  selectIsLoadingGroups,
  selectPinnedGroups,
  selectPinnedGroupsHasNextPage,
  selectPinnedGroupsPage,
} from 'src/store/groups';
import { useIsMobile, useIsTablet } from 'src/utils/styles.utils';
import GroupCard from './components/GroupCard';
import CircularProgress from '@mui/material/CircularProgress';
import { selectUserId } from 'src/store/auth';
import { useToken } from 'src/cookies';
import { clearArticlesState } from '../../store/articles';
import GroupCardMobile from './components/GroupCardMobile';
import { Group_Tabs, IGroup } from '../../types/Group';
import PinedGroupCardMobile from './components/PinedGroupCardMobile';
import { CREATE_GROUP_MOBILE } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { RechartsUserMood } from '../Recharts/RechartsUserMood';
import { getAllGroupsMoods, resetAllGroupsMoods } from '../../store/moods';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getColorByMood, RechartsGroupMood } from '../Recharts/RechartsGroupMood';
import { selectMyWeeklyAverageMoods, selectTodayAverage } from '../../store/moods/selector';
import { MyDate } from '../../helpers/date';
import { format } from 'date-fns';
import { switchTheme } from '../../helpers/muiThemes';
import { ThemeProvider } from '@mui/material/styles';
import { getMyEmojiByValue } from '../mood/FellingMood';
import { BackArrow } from 'images/arrows/BackArrow';
import { ForwardArrow } from 'images/arrows/ForwardArrow';
import { setGroupFeedTab } from '../../store/navigationStates';
import { IOSSwitch } from '../../utils/componenets';
import { getMoodText } from '../../helpers/moodList';
import styles from './GroupCardStyles.module.css';

export const GroupBlock = React.memo(() => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const groups = useSelector(selectGroups);
  const isLoading = useSelector(selectIsLoadingGroups);
  const pinnedGroups = useSelector(selectPinnedGroups);
  const groupHasNextPage = useSelector(selectGroupsHasNextPage);
  const nextPinned = useSelector(selectPinnedGroupsHasNextPage);
  const groupPage = useSelector(selectGroupsPage);
  const groupPinnedPage = useSelector(selectPinnedGroupsPage);
  const myWeeklyAverageMoods = useSelector(selectMyWeeklyAverageMoods);
  const todaysMoodValue = useSelector(selectTodayAverage);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const listInnerRef = useRef(null);
  const listInnerRefH = useRef(null);
  const userId = useSelector(selectUserId);
  const history = useHistory();
  const [isAverageMoods, setIsAverageMoods] = useState<boolean>(false); //here we set if the recharts is for all my mods in details count or if it is average
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const UPDATE_INTERVAL_HOURS = 0.1;

  useEffect(() => {
    if (userId.length) {
      dispatch(getGroups({ token }));
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    dispatch(clearArticlesState());
    dispatch(resetAllGroupsMoods());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token && localStorage.getItem('userAcceptedHealthPermissions') === 'true') {
      let cordovaHealthObj: any = {
        token,
        metrics: ['sleep', 'steps', 'calories'],
      };

      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
        2,
        '0',
      )}-${String(currentDate.getDate()).padStart(2, '0')}`;

      const currentTime = currentDate.toISOString();

      const lastUpdateTime = localStorage.getItem('healthLastUpdateTime');

      if (!lastUpdateTime) {
        localStorage.setItem('healthLastUpdateTime', currentTime);
        cordovaHealthObj = { ...cordovaHealthObj, startDate: formattedDate };
      } else {
        const lastUpdateDate = new Date(lastUpdateTime);
        const timeDifference = currentDate.getTime() - lastUpdateDate.getTime();
        const hoursDiff = UPDATE_INTERVAL_HOURS * 60 * 60 * 1000;

        if (timeDifference >= hoursDiff) {
          const lastUpdateFormattedDate = `${lastUpdateDate.getFullYear()}-${String(
            lastUpdateDate.getMonth() + 1,
          ).padStart(2, '0')}-${String(lastUpdateDate.getDate()).padStart(2, '0')}`;

          cordovaHealthObj = { ...cordovaHealthObj, startDate: lastUpdateFormattedDate };

          localStorage.setItem('healthLastUpdateTime', currentTime);
        }
      }
      if (cordovaHealthObj.startDate) {
        const stringCordovaObj = JSON.stringify(cordovaHealthObj);
        //TODO remove this logs after testing
        console.log('stringCordovaObj--', stringCordovaObj);
        window.parent.postMessage(stringCordovaObj, '*');
      }
    }
  }, [UPDATE_INTERVAL_HOURS]);

  useEffect(() => {
    dispatch(getPinnedGroups({ token }));
    dispatch(setGroupFeedTab(Group_Tabs.MOODS));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const from = format(new Date(MyDate.getFromTo().from), 'yyyy-MM-dd');
    const to = format(new Date(MyDate.getFromTo().to), 'yyyy-MM-dd');
    dispatch(getAllGroupsMoods({ token, from, to }));
    // eslint-disable-next-line
  }, [date]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight + 1 > scrollHeight && groupHasNextPage) {
        dispatch(getGroups({ token, page: groupPage + 1 }));
      }
    }
  };

  const onScrollHorizontal = () => {
    if (listInnerRefH.current) {
      const { scrollWidth, scrollLeft, clientWidth } = listInnerRefH.current;
      if (scrollLeft + clientWidth + 1 > scrollWidth && nextPinned) {
        dispatch(getPinnedGroups({ token, page: groupPinnedPage + 1 }));
      }
    }
  };

  const changeDate = (decrease?: boolean) => {
    const dt = new Date(date);
    if (decrease) {
      dt.setDate(dt.getDate() - 7);
    } else {
      dt.setDate(dt.getDate() + 7);
    }

    setDate(dt.toDateString());
  };

  return (
    <div className={`${isMobile || isTablet ? `${styles.groupMainMobile}` : `${styles.groupMain}`}`}>
      {/*{!isLoading && (*/}
      {/*  <div className={styles.loadingSpinnerWrapperS}>*/}
      {/*    <CircularProgress color="success" />*/}
      {/*  </div>*/}
      {/*)}*/}
      {!!pinnedGroups.length && (
        <div
          className={`${(isMobile || isTablet) && `${styles.scrollPinedAreaMobile}`}`}
          onScroll={() => onScrollHorizontal()}
          ref={listInnerRefH}
          style={{ height: isMobile ? '10%' : '34%' }}
        >
          {pinnedGroups.map((group: IGroup) => {
            return (isMobile || isTablet) && <PinedGroupCardMobile key={group.id} group={group} />;
          })}
        </div>
      )}
      {(isMobile || isTablet) && (
        <>
          <div className={styles.chartHeader}>
            <div className={styles.chartHeaderLine}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                <div>Overall you are feeling :</div>
                <div
                  className={styles.fellingEmoji}
                  style={{ backgroundColor: getColorByMood(todaysMoodValue.averageMood ?? -1) }}
                >
                  <div>{getMyEmojiByValue(todaysMoodValue.averageMood ?? -1, 20)}</div>
                  <div style={{ marginLeft: '4px' }}>{getMoodText(todaysMoodValue.averageMood ?? -1)}</div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={styles.groupCount}>{todaysMoodValue.groups}</div>
                <div>{todaysMoodValue.groups === 1 ? 'group' : 'groups'}</div>
              </div>
            </div>
            <div className={styles.chartHeaderLine}>
              <span>View:</span>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <span style={{ marginRight: '10px' }}>Detailed</span>
                <ThemeProvider theme={switchTheme}>
                  <FormGroup style={{ width: '26%' }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          value={'no-icon'}
                          sx={{ m: 1 }}
                          onChange={(e: { target: { checked: boolean } }) => {
                            setIsAverageMoods(e.target.checked);
                          }}
                          checked={isAverageMoods}
                        />
                      }
                      label=""
                    />
                  </FormGroup>
                </ThemeProvider>
                <span>Average</span>
              </div>
            </div>
          </div>
          <div className={styles.chartUserBlock}>
            <div className={styles.datepickerBlock}>
              <span>{MyDate.getCurrentPeriod(date)}</span>
              <div className={styles.arrowBlock}>
                <div onClick={() => changeDate(true)} className={styles.arrowButton}>
                  {BackArrow()}
                </div>
                <div className={styles.arrowButton} onClick={() => changeDate()}>
                  {ForwardArrow()}
                </div>
              </div>
            </div>
            {!isAverageMoods ? (
              <RechartsUserMood />
            ) : (
              <RechartsGroupMood groupData={myWeeklyAverageMoods} isExpand={true} />
            )}
          </div>
        </>
      )}

      {groups.length || pinnedGroups.length ? (
        <div
          style={{
            marginBottom: '20%',
            height:
              !!pinnedGroups.length && isMobile ? window.innerHeight - 62 + 'px' : window.innerHeight - 154 + 'px',
          }}
          className={`${isMobile || isTablet ? `${styles.scrollAreaMobile}` : `${styles.scrollAreaBrowser}`}`}
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          {groups.map((group: IGroup) => {
            return isMobile || isTablet ? (
              <GroupCardMobile key={group.id} group={group} />
            ) : (
              <GroupCard key={group.id} group={group} />
            );
          })}
        </div>
      ) : (
        <div className={styles.noPosts}>
          No groups yet
          <div onClick={() => history.push(CREATE_GROUP_MOBILE)} className={styles.createANewOneBtn}>
            Create a new one
          </div>
        </div>
      )}
    </div>
  );
});


