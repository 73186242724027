import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedPaper } from '../../../store/articles/selector';
import { Paper } from '../../../types/Paper';
import Picker from 'emoji-picker-react';
import { PlaneIcon } from 'images/PlaneIcon';
import { SmileIcon } from 'images/SmileIcon';
import { selectLoggedUserData } from '../../../store/user/selectors';
import { createComment } from '../../../store/articles';
import CommentsList from '../../../components/Article/CommentsList';
import { useToken } from '../../../cookies';
import { normalizedCommentsStructure } from '../../../helpers/comments';
import styles from './style.module.css';


export default function ViewQuestionBody() {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { token } = useToken();
  const loggedUser = useSelector(selectLoggedUserData);
  const paper = useSelector(selectSelectedPaper) as Paper;
  const [comment, setComment] = useState('');
  const [isEmojiVisible, setIsEmojiVisible] = useState(false);

  const comments = normalizedCommentsStructure(paper.comments);

  const postComment = () => {
    if (comment.length) {
      dispatch(createComment({ token, articleId: paper.id, comment, userId: loggedUser.id }));
      setComment('');
    }
  };
  const saveComment = (e: { code: string }) => {
    if (e.code === 'Enter') {
      postComment();
    }
  };

  const anwerInput = () => {
    return (
      <div className={styles.messageBlock}>
        <input
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onKeyDown={(e) => saveComment(e)}
          className={styles.messageBlockInput}
          type="text"
          placeholder="Add answer"
        />
        <div ref={ref} className={styles.messageEmojii}>
          {isEmojiVisible && (
            <Picker
              onEmojiClick={(e, obj) => {
                e.stopPropagation();
                setComment(comment + obj.emoji);
              }}
            />
          )}
        </div>

        <div
          className={styles.messageEmojiiIcon}
          onClick={(e) => {
            e.stopPropagation();
            setIsEmojiVisible(!isEmojiVisible);
          }}
        >
          {SmileIcon()}
        </div>
        <div onClick={() => postComment()} className={styles.messagePostComment}>
          {PlaneIcon()}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.questionModalBody}>
      <div>
        {paper.comments.length ? (
          <div className={styles.questionModalBodyComments}>
            {anwerInput()}
            {comments.length > 0 &&
              [...comments].reverse().map((comment) => <CommentsList key={comment.id} {...comment} />)}
          </div>
        ) : (
          <div className={styles.questionModalBodyComments}>
            <span className={styles.questionModalBodyCommentsLabel}>No answers yet, be the first... </span>
            {anwerInput()}
          </div>
        )}
      </div>
    </div>
  );
}
