import React from 'react';
import styles from '../landingStyles.module.css';
import {SmallVextLogo} from "../../assets/LogoFrames/SmallVextLogo";
import {blackWhiteLogo} from "images/logo/blackWhiteLogo";
import {useHistory} from "react-router-dom";
import {blueLogo} from "../../assets/LogoFrames/blueLogo";
import {useIsMobile, useIsTablet} from "../../utils/styles.utils";

function Navbar() {
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const isSmallDevice = isMobile || isTablet;

    const history = useHistory();
    const handleClick = (e: any, id: any) => {
        e.preventDefault();
        const element = document.getElementById(id);
        element && element.scrollIntoView({behavior: 'smooth'});
    };


    return (
        <div className={styles.navbar} style={{flexDirection: isSmallDevice ? 'column' : 'row'}}>
            <nav className={styles.nav}>
                <div className={styles.logo}>{blueLogo()} VEXT</div>
                <ul className={styles.navList}>
                    <li className={styles.navItem}>
                        <a href="#home" onClick={(e) => handleClick(e, 'home')}>
                            Home
                        </a>
                    </li>
                    <li className={styles.navItem}>
                        <a href="#about" onClick={(e) => handleClick(e, 'about')}>
                            About
                        </a>
                    </li>
                    {/*<li className={styles.navItem}>*/}
                    {/*  <a href="#portfolio" onClick={(e) => handleClick(e, 'portfolio')}>*/}
                    {/*    Portfolio*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                    {/*<li className={styles.navItem}>*/}
                    {/*  <a href="#contact" onClick={(e) => handleClick(e, 'contact')}>*/}
                    {/*    Contact*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                </ul>
            </nav>
            <div className={styles.landingButton} onClick={() => {
                history.push('/admin/auth')
            }}>Login
            </div>
        </div>
    );
}

export default Navbar;