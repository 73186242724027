import React from 'react';
import { IGroup } from '../../../../types/Group';
import { useSelector } from 'react-redux';
import { selectStatisticsLoading } from '../../../../store/statistics';
import CircularProgress from '@mui/material/CircularProgress';
import GroupBlock from './GroupBlock';
import styles from '../statisticsStyles.module.css';

function GroupStatistics({ groups }: { groups: Array<IGroup> }) {
  const isLoading = useSelector(selectStatisticsLoading);

  return (
    <div className={styles.statisticsGroupBlock}>
      <>
        {isLoading && (
          <div className={styles.progress}>
            <CircularProgress />
          </div>
        )}
        {!!groups.length
          ? groups.map((group) => {
              return <GroupBlock key={group.id} group={group} />;
            })
          : !isLoading && <div style={{ textAlign: 'center' }}>No data</div>}
      </>
    </div>
  );
}

export default GroupStatistics;