export const createWebSocket = (token: string) => {
  //TODO on prod the url is coming from js.env
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const url = baseUrl?.split('//')[1];
  return new WebSocket(`${url ? 'ws' : 'wss'}://${url ? url : 'backend.vext.co'}/ws/chat_v2/?token=${token}`);
};

export const createNotificationsSocket = (token: string) => {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const url = baseUrl?.split('//')[1];
  return new WebSocket(`${url ? 'ws' : 'wss'}://${url ? url : 'backend.vext.co'}/ws/notifications/?token=${token}`);
};