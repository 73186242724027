import Picker from 'emoji-picker-react';
import { PlaneIcon } from 'images/PlaneIcon';
import { SmileIcon } from 'images/SmileIcon';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ConversationsList from './ConversationsList';
import NavConversation from './NavConversation';
import styles from './styles.module.css';

import { ImagesTemplate } from '../../../../components/layout/ImagesTemplate';
import { getFirstUserFromUsersList } from '../../../../helpers/arrays';
import { selectUserId } from '../../../../store/auth';
import { selectOpenedChat } from '../../../../store/chats/selector';
import { selectLoggedUserData } from '../../../../store/user/selectors';
import { ChatListPreview } from '../../../../types/User';


function ConversationWindow({ sendMessage }: { sendMessage: (message: string, chatId: string) => void }) {
  const [isEmojiVisible, setIsEmojiVisible] = useState(false);
  const [message, setMessage] = useState('');
  const myUserId = useSelector(selectUserId);
  const openedChat = useSelector(selectOpenedChat) as ChatListPreview;
  const user = getFirstUserFromUsersList(openedChat.users, myUserId, openedChat.has_to_accept);
  const loggedUser = useSelector(selectLoggedUserData);
  const ref = useRef(null);
  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      sendMessage(message, openedChat.id);
      setMessage('');
    }
  };
  return (
    <div className={styles.conversationBlock}>
      <div className={styles.conversationNav}>
        <NavConversation
          user={user}
          isIncognito={!!openedChat.emergency?.is_emergency_incognito}
          chatName={openedChat.name}
          openedChat={openedChat}
        />
      </div>
      <ConversationsList openedChat={openedChat} />
      <div className={styles.msgBlock}>
        <div style={{ margin: '4px 8px', border: '1px solid green', borderRadius: '7px' }}>
          <ImagesTemplate url={loggedUser.profile?.image_url} height={30} width={30} isSquare />
        </div>
        <input
          onKeyDown={handleKeyDown}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          className={styles.msgInput}
          type="text"
        />
        <div ref={ref} className={styles.msgEmoji}>
          {isEmojiVisible && (
            <Picker
              onEmojiClick={(e, obj) => {
                e.stopPropagation();
                setMessage(message + obj.emoji);
              }}
            />
          )}
        </div>
        <div
          onClick={() => {
            setIsEmojiVisible(!isEmojiVisible);
          }}
          className={styles.msgSendIcon}
        >
          {SmileIcon()}
        </div>
        <div
          onClick={() => {
            sendMessage(message, openedChat.id);
            setMessage('');
          }}
          className={styles.msgSendIcon}
        >
          {PlaneIcon()}
        </div>
      </div>
    </div>
  );
}

export default ConversationWindow;
