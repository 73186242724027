export default function scrollSmoothTo(container: any, position: number) {
    const start = container.current.scrollTop;
    const distance = position - start;
    const duration = Math.abs(distance) / 3; // adjust the speed of the animation by changing the divisor
    const easing = (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
    const step = (timestamp: number) => {
        const time = Math.min(1, (timestamp - startTimestamp) / duration);
        const scrollTop = start + distance * easing(time);
        container.current.scrollTo(0, scrollTop);
        if (time < 1) {
            requestAnimationFrame(step);
        }
    };
    const startTimestamp = performance.now();
    requestAnimationFrame(step);
}