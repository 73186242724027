export const dots3Icon = (color='#EDEFF0', width='32', height='32') => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="13" y="4" width="6" height="6" rx="3" fill={color} />
    <rect x="13" y="13" width="6" height="6" rx="3" fill={color} />
    <rect x="13" y="22" width="6" height="6" rx="3" fill={color} />
    <defs>
      <filter
        id="filter0_b_1367_2854"
        x="-14"
        y="-14"
        width="60"
        height="60"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="7" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1367_2854" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1367_2854" result="shape" />
      </filter>
    </defs>
  </svg>
);
