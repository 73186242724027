import React, { useEffect, useState } from 'react';
import { calendarIcon } from 'images/calendarIcon';
import { BackArrow } from 'images/arrows/BackArrow';
import { ForwardArrow } from 'images/arrows/ForwardArrow';
import { format } from 'date-fns';
import { MyDate } from '../../../../helpers/date';
import Radio from '@mui/material/Radio';
import RechartsWrapper from '../../charts/RechartsWrapper';
import { IGroup } from '../../../../types/Group';
import { ChartType } from '../../../../utils/enums';
import { useIsMobile, useIsTablet } from '../../../../utils/styles.utils';
import styles from './styles.module.css';

enum ChartPeriod {
  DAYS = 'Days',
  MONTHS = 'Months',
}

function StatisticsMoods({ group }: { group?: IGroup }) {
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [selectedPeriod, setSelectedPeriod] = useState<ChartPeriod>(ChartPeriod.DAYS);
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(ChartType.AVERAGE);
  const [fromTo, setFromTo] = useState({ from: '', to: '' });

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSmallDevice = isMobile || isTablet;

  useEffect(() => {
    if (selectedPeriod === ChartPeriod.MONTHS) {
      // FROM-TO month
      const from = format(new Date(MyDate.getMonthFromTo(date).firstDay), 'yyyy-MM-dd');
      const to = format(new Date(MyDate.getMonthFromTo(date).lastDay), 'yyyy-MM-dd');
      setFromTo({ from, to });
    } else {
      // FROM-TO week
      const from = format(new Date(MyDate.getFromTo().from), 'yyyy-MM-dd');
      const to = format(new Date(MyDate.getFromTo().to), 'yyyy-MM-dd');
      setFromTo({ from, to });
    }
  }, [date, selectedPeriod]);

  const changeDate = (decrease?: boolean) => {
    if (selectedPeriod === ChartPeriod.MONTHS) {
      const dt = new Date(date);
      if (decrease) {
        dt.setMonth(dt.getMonth() - 1);
      } else {
        dt.setMonth(dt.getMonth() + 1);
      }
      setDate(dt.toDateString());
    } else {
      const dt = new Date(date);
      if (decrease) {
        dt.setDate(dt.getDate() - 7);
      } else {
        dt.setDate(dt.getDate() + 7);
      }
      setDate(dt.toDateString());
    }
  };

  const handleChangeChartCalcTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPeriod(event.target.value as ChartPeriod);
  };
  const handleChangeChartType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedChartType(event.target.value as ChartType);
  };

  const controlPropsTime = (item: string) => ({
    checked: selectedPeriod === item,
    onChange: handleChangeChartCalcTime,
    value: item,
  });

  const controlPropsType = (item: string) => ({
    checked: selectedChartType === item,
    onChange: handleChangeChartType,
    value: item,
  });

  return (
    <div>
      <div className={isSmallDevice ? styles.mobileChartHeaderPanel : styles.chartHeaderPanel}>
        {/*  CALENDAR ICON*/}
        <div className={styles.calendarIcon}>{calendarIcon('#757575')}</div>
        {/*LEFT-RIGHT ARROWS*/}
        <div className={styles.arrowsBtn}>
          <div onClick={() => changeDate(true)}>{BackArrow('#757575')}</div>
          <div onClick={() => changeDate()}> {ForwardArrow('#757575')}</div>
        </div>
        {/*DISPLAY DATE*/}
        {selectedPeriod === ChartPeriod.MONTHS ? (
          <span className={styles.dateBlock}>{MyDate.getSelectedMonth(date)}</span>
        ) : (
          <span className={styles.dateBlock}>{MyDate.getCurrentPeriod(date)}</span>
        )}
        {/*  PERIOD RADIO BUTTONS*/}
        <div>
          <Radio
            {...controlPropsTime(ChartPeriod.DAYS)}
            sx={{
              color: '#61B779',
              '&.Mui-checked': {
                color: '#61B779',
              },
            }}
          />
          <span
            style={{
              color: selectedPeriod === ChartPeriod.DAYS ? '#61B779' : '',
              marginRight: '6px',
            }}
          >
            Days
          </span>
          <Radio
            {...controlPropsTime(ChartPeriod.MONTHS)}
            sx={{
              color: '#61B779',
              '&.Mui-checked': {
                color: '#61B779',
              },
            }}
          />
          <span style={{ color: selectedPeriod === ChartPeriod.MONTHS ? '#61B779' : '' }}>Months</span>
        </div>
        {/* CHART TYPE (Average || Detailed) */}
        <div className={styles.chartTypeRadios}>
          <Radio
            {...controlPropsType(ChartType.AVERAGE)}
            sx={{
              color: '#61B779',
              '&.Mui-checked': {
                color: '#61B779',
              },
            }}
          />
          <span
            style={{
              color: selectedChartType === ChartType.AVERAGE ? '#61B779' : '',
              marginRight: '6px',
            }}
          >
            Average
          </span>
          <Radio
            {...controlPropsType(ChartType.DETAILED)}
            sx={{
              color: '#61B779',
              '&.Mui-checked': {
                color: '#61B779',
              },
            }}
          />
          <span style={{ color: selectedChartType === ChartType.DETAILED ? '#61B779' : '' }}>Detailed</span>
        </div>
      </div>
      <div>
        {!!fromTo.from && <RechartsWrapper selectedChartType={selectedChartType} groupId={group?.id} fromTo={fromTo} />}
      </div>
    </div>
  );
}

export default StatisticsMoods;
