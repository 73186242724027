export const theme = {
  colors: {
    white: '#FFFFFF',
    greenPrimary: '#3aa657',
    gray: '#838E93',
    gray_1: '#f2f2f2',
    gray_2: '#757575',
    gray_3: '#dedada',

    blue_1: '#002F50',
    blue_2: '#32C5FF',
    blue_3: '#BFD4E4',
    blue_4: '#DDEAF3',
    blue_5: '#F2F7F9',
    blue_11: '#0F4D79',

    green_1: '#61B779',
    green_2: '#4D9A62',
  },
};
