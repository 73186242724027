import { useHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import { NotificationIcon } from 'images/notificationIcon';
import { chatIcon } from 'images/chat/chatIcon';
import { homeIcon } from 'images/homeIcon';
import { plusIcon } from 'images/plusIcon';
import CreateQuestionPage from '../../../pages/CreateQuestionPage/CreateQuestionPage';
import { createQuestionIcon } from 'images/createQuestionIcon';
import { MOBILE_CHAT_PAGE, MOBILE_EMERGENCY_PAGE, MOBILE_NOTIFICATIONS } from '../../../utils/constants';
import { emergencyIcon } from 'images/emergencyIcon';
import { getNotifications, setNotificationsListAsViewed } from '../../../store/notifications';
import { useToken } from '../../../cookies';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActive } from 'images/notificationActive';
import { newMessageInChatIcon } from 'images/chat/newMessageInChatIcon';
import { selectIsNewMessageInChats } from '../../../store/chats/selector';
import styles from './MobileFooter.module.css';
import { selectIsNewNotificationAvailable } from '../../../store/notifications/selector';

export default function MobileFooter({ isGroupLocation }: { isGroupLocation?: boolean }) {
  const [createQuestion, setCreateQuestion] = useState(false);
  const [initialHeight, _setInitialHeight] = useState(window.innerHeight);

  const history = useHistory();
  const { token } = useToken();
  const dispatch = useDispatch();
  const isNewMessageInChat = useSelector(selectIsNewMessageInChats);
  const isNewNotifications = useSelector(selectIsNewNotificationAvailable);

  const navigateTo = () => {
    if (isGroupLocation) {
      return setCreateQuestion(true);
    }
    history.push('/create-group-m');
  };

  const getNavigateIcon = () => {
    if (isGroupLocation) {
      return createQuestionIcon();
    }
    return plusIcon();
  };
  useEffect(() => {
    if (isNewNotifications) {
      dispatch(getNotifications({ token }));
    }
  }, [isNewNotifications]);

  return (
    <>
      {/*Here we check if mobile keyboard is open or not*/}
      {window.innerHeight >= initialHeight ? (
        <div className={styles.mainMobileFooter}>
          <div className={styles.mobileFooterBlock} onClick={() => history.push('/feed')}>
            {homeIcon()}
          </div>
          <div className={styles.mobileFooterBlock} onClick={() => history.push(MOBILE_CHAT_PAGE)}>
            {isNewMessageInChat ? newMessageInChatIcon() : chatIcon()}
          </div>
          <div className={styles.mobileFooterBlock} onClick={() => history.push(MOBILE_EMERGENCY_PAGE)}>
            {emergencyIcon()}
          </div>
          <div className={styles.mobileFooterBlock} onClick={navigateTo}>
            {getNavigateIcon()}
          </div>
          <div
            className={styles.mobileFooterBlock}
            onClick={() => {
              dispatch(setNotificationsListAsViewed());
              history.push(MOBILE_NOTIFICATIONS);
            }}
          >
            {isNewNotifications ? notificationActive() : NotificationIcon()}
          </div>
        </div>
      ) : null}
      {createQuestion && <CreateQuestionPage setCreatingQuestion={setCreateQuestion} />}
    </>
  );
}
