import ImageMobileInput from '../../../../helpers/ImageMobileInput';
import { useState } from 'react';
import { NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';
import styles from './styles.module.css';

export function ProfileImageMobileSettings({
  imageUrl,
  setUserImage,
  isBackground,
}: {
  imageUrl: string;
  setUserImage: any;
  isBackground?: boolean;
}) {
  const [userImagePreview, setUserImagePreview] = useState('');
  return (
    <div>
      <div className={styles.profileImageBlock}>
        <div>
          <img
            className={styles.profileImage}
            style={{ borderRadius: isBackground ? '12px' : undefined }}
            src={userImagePreview || imageUrl || NO_USER_AVATAR}
            alt=""
          />
        </div>
        <p className={styles.imageRequirements}>
          {isBackground
            ? 'Your background photo enhances the aesthetics of your Profile page. It acts as a visual representation of your personality or interests'
            : 'Your photo appears on your Profile page and with your stories across Medium. Recommended size: Square, at\n' +
              ' least 1000 pixels per side. File type: JPG, PNG or GIF'}
        </p>
      </div>
      <ImageMobileInput setImage={setUserImage} setImagePreview={setUserImagePreview} />
    </div>
  );
}
