import { RootState } from '../rootReducer';
import {DomainType} from "../../types/Auth";

export const selectUserId = (state: RootState): string => state.auth.uid;

export const selectMailDomains = (
  state: RootState,
): Array<DomainType> =>
  state.auth.domains || [];

export const selectMailDomainsPrefixes = (
  state: RootState,
): Array<string> =>
  state.auth.domainPrefixes || [];

export const selectAuthError = (state: RootState): string | null => state.auth.error;
