import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MyProfile } from 'images/users/MyProfile';
import { SettingsIcon } from 'images/Settings';
import { LogOutIcon } from 'images/LogOut';
import { useHistory } from 'react-router';
import { useOnClickOutside } from 'usehooks-ts';
import { clearUserState } from '../../store/user';
import { selectLoggedUserData } from '../../store/user/selectors';
import {useRefreshToken, useToken} from '../../cookies';
import { clearNotificationsState } from 'src/store/notifications';
import { GroupIcon } from 'images/GroupIccon';
import { ImagesTemplate } from '../layout/ImagesTemplate';
import {selectUserId} from "../../store/auth";
import ReactGA from "react-ga4";
import style from './UserStyles.module.css';

export function UserDropdown() {
  const history = useHistory();
  const ref = useRef(null);
  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(selectLoggedUserData);
  const { removeToken } = useToken();
  const { removeRefreshToken } = useRefreshToken();
  const loggedUserId = useSelector(selectUserId);

  const logout = () => {
    dispatch(clearUserState());
    dispatch(clearNotificationsState());
    removeToken();
    removeRefreshToken()
    ReactGA.event({
      category: 'User',
      action: 'Logout',
      label: 'Users logout from app',
    });
    history.push('/login');
  };

  const handleClickOutside = () => {
    setOpened(false);
  };
  useOnClickOutside(ref, handleClickOutside);

  return (
    <div>
      <button
        className={style.userDropDownBtn}
        ref={ref}
        onClick={() => {
          setOpened((opened) => !opened);
        }}
      >
        <div>
          {userData.profile?.image_url ? (
            <ImagesTemplate url={userData.profile.image_url} height={46} width={46} isSquare/>
          ) : (
            <div className={style.myProfIcon}>{MyProfile()}</div>
          )}
        </div>
      </button>
      {opened && <div className={style.dropDownArrow} />}
      <div className={`${style.dropDownBlock} && ${opened ? style.isVisible : null} `}>
        <div className={style.dropDownContent}>
          <div className={style.dropDownInnerContent}>
            <div onClick={() => history.push(`/user/${loggedUserId}`)} className={style.dropDownListItem}>
              <div className={style.dropDownListIcon}>{MyProfile()}</div>
              <span>My profile</span>
            </div>

            <div onClick={() => history.push('/settings')} className={style.dropDownListItem}>
              <div className={style.dropDownListIcon}>{SettingsIcon()}</div>
              <span>Settings</span>
            </div>
            <div onClick={() => history.push('/create-group-m')} className={style.dropDownListItem}>
              <div style={{ marginRight: '4px' }} className={style.dropDownListIcon}>
                {GroupIcon()}
              </div>
              <span>Create group</span>
            </div>

            <div className={style.dropDownListItem}>
              <button className={style.logOutBtn} onClick={() => logout()}>
                <div className={style.dropDownListIcon}>{LogOutIcon()}</div>
                {'Log out'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
