export const closeWhiteCircleIcon = (width="24", height="24",x='white') => (
  <svg width={width} height={height} viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.4508 12C21.4508 6.78093 17.2199 2.55002 12.0008 2.55002C6.78169 2.55002 2.55078 6.78093 2.55078 12C2.55078 17.2191 6.78169 21.45 12.0008 21.45C17.2199 21.45 21.4508 17.2191 21.4508 12Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path d="M8.78613 8.78571L15.2147 15.2143" stroke={x} strokeWidth="1.8" strokeLinecap="round" />
    <path d="M15.2148 8.78571L8.78627 15.2143" stroke={x} strokeWidth="1.8" strokeLinecap="round" />
  </svg>
);
