import { useHistory } from 'react-router-dom';
import ArrowBackButton from '../../../components/mobile/Buttons/ArrowBackButton';
import React, { Dispatch, SetStateAction } from 'react';
import TextField from '@mui/material/TextField';
import { darkTheme } from '../../../themes/themes';
import { ThemeProvider } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IOSSwitch } from '../../../utils/componenets';
import styles from './styles.module.css';
import MessageHockPopUp from '../../../hooks/MessageHockPopUp';

export default function EmergencyStep2({
  setStepComponent,
  setEmergencyStateAndTown,
  emergencyStateAndTown,
  isEmergencyIncognito,
  setIsEmergencyIncognito,
}: {
  setStepComponent: any;
  setEmergencyStateAndTown: Dispatch<SetStateAction<{ state: string; town: string; address: string }>>;
  emergencyStateAndTown: { state: string; town: string; address: string };
  setIsEmergencyIncognito: Dispatch<SetStateAction<boolean>>;
  isEmergencyIncognito: boolean;
}) {
  const history = useHistory();

  return (
    <div>
      <div className={styles.navbar}>
        <ArrowBackButton setStep={() => setStepComponent(1)} />
        {/*<div onClick={() => history.push('./user')}>{closeWhiteCircleIcon('40', '38', '#FFC700')}</div>*/}
      </div>
      <div className={styles.moreInfoBlock}>
        <h1>Your about to connect with a counselor, in order to do so you must enable your geolocation.</h1>
      </div>
      <div className={styles.stateAndTownBlock}>
        <ThemeProvider theme={darkTheme}>
          <TextField
            required
            style={{ backgroundColor: '#2A2A2A' }}
            onChange={(e) => {
              setEmergencyStateAndTown((prev) => {
                return { ...prev, state: e.target.value };
              });
            }}
            fullWidth
            label="State"
            value={emergencyStateAndTown.state}
            size="small"
            InputLabelProps={{
              style: {
                color: '#8B8C8D',
                fontWeight: 300,
              },
            }}
          />
          <TextField
            required
            style={{ backgroundColor: '#2A2A2A', marginTop: '14px' }}
            onChange={(e) => {
              setEmergencyStateAndTown((prev) => {
                return { ...prev, town: e.target.value };
              });
            }}
            fullWidth
            label="Town"
            value={emergencyStateAndTown.town}
            size="small"
            InputLabelProps={{
              style: {
                color: '#8B8C8D',
                fontWeight: 300,
              },
            }}
          />
          <TextField
            required
            style={{ backgroundColor: '#2A2A2A', marginTop: '14px' }}
            onChange={(e) => {
              setEmergencyStateAndTown((prev) => {
                return { ...prev, address: e.target.value };
              });
            }}
            fullWidth
            label="Address"
            value={emergencyStateAndTown.address}
            size="small"
            InputLabelProps={{
              style: {
                color: '#8B8C8D',
                fontWeight: 300,
              },
            }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: 'white',
              marginTop: '14px',
            }}
          >
            <FormGroup style={{ width: '14%' }}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    value={'no-icon'}
                    sx={{ m: 1 }}
                    onChange={(e: { target: { checked: boolean } }) => {
                      setIsEmergencyIncognito(e.target.checked);
                    }}
                    checked={isEmergencyIncognito}
                  />
                }
                label=""
              />
            </FormGroup>
            <span>Connect as incognito</span>
          </div>
        </ThemeProvider>
      </div>

      <div className={styles.buttonsBlock}>
        <div
          className={styles.sendButton}
          onClick={() => {
            if (emergencyStateAndTown.town && emergencyStateAndTown.state) {
              setStepComponent(3);
            } else {
              MessageHockPopUp({
                message: 'Information about your state and town is required!',
                timeOut: 3500,
                type: 'warning',
                size: 'small',
              });
            }
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}