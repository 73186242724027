import style from './layoutStyles.module.css';
import { NO_ARTICLE_COVER, NO_GROUP_COVER, NO_USER_AVATAR } from '../../utils/constantImagesUrl';

export function ImagesTemplate({
  url,
  width,
  height,
  isSquare,
}: {
  url?: string;
  width: number;
  height: number;
  isSquare?: boolean;
}) {
  return (
    <div className={style.imageCut} style={{ width: `${width}px`, height: `${height}px` }}>
      <img
        className={style.userImg}
        style={{ borderRadius: isSquare ? '12px' : '' }}
        src={url || NO_USER_AVATAR}
        alt=""
      />
    </div>
  );
}

export function ArticleAvatar({ url, width, height }: { url: string | null; width: number; height: number }) {
  return (
    <div className={style.imageCut} style={{ width: `${width}%`, height: `${height}px` }}>
      <img className={style.articleImg} src={url || NO_ARTICLE_COVER} alt="" />
    </div>
  );
}

export function ElementPreviewImage({ url, width, height }: { url: string | null; width: number; height: number }) {
  return (
    <div className={style.imageBlock} style={{ width: `${width}%`, height: `${height}px` }}>
      <img className={style.groupImgCover} src={url || NO_GROUP_COVER} alt="" />
    </div>
  );
}