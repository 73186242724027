import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { MoodApiService } from 'src/services/mood.service';
import { useToken } from 'src/cookies';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { SmallVextLogo } from '../../assets/LogoFrames/SmallVextLogo';
import { getFullGroup, getGroupsWeekMoods, selectRecentGroupId } from '../../store/groups';
import { ReactComponent as SadSvg } from 'emoji/sad.svg';
import { ReactComponent as Frustrated } from 'emoji/frustrated.svg';
import { ReactComponent as Angry } from 'emoji/angry.svg';
import { ReactComponent as Tired } from 'emoji/tiered.svg';
import { ReactComponent as Curious } from 'emoji/courious.svg';
import { ReactComponent as Confused } from 'emoji/confused.svg';
import { ReactComponent as Excited } from 'emoji/excited.svg';
import { ReactComponent as Confident } from 'emoji/Confident.svg';
import { ReactComponent as Happy } from 'emoji/Happy.svg';
import { currentDateInIsoFormat, getMondayOfCurrentWeek } from '../../helpers/date';
import { format } from 'date-fns';
import ReactGA from 'react-ga4';
import CircularProgress from '@mui/material/CircularProgress';
import MessageHockPopUp from '../../hooks/MessageHockPopUp';
import styles from './FellingStyles.module.css';

const MySlider = styled(Slider)(({ theme: string }) => ({
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 20,
    width: 7,
    borderRadius: '4px',
  },
}));

export const getMyEmojiByValue = (value: number, size = 50) => {
  switch (value) {
    case 0:
      return <SadSvg width={size} height={size} />;
    case 1:
      return <Frustrated width={size} height={size} />;
    case 2:
      return <Angry width={size} height={size} />;
    case 3:
      return <Tired width={size} height={size} />;
    case 4:
      return <Curious width={size} height={size} />;
    case 5:
      return <Confused width={size} height={size} />;
    case 6:
      return <Excited width={size} height={size} />;
    case 7:
      return <Confident width={size} height={size} />;
    case 8:
      return <Happy width={size} height={size} />;
    default:
      return <div></div>;
  }
};

export default function FellingMood() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { token } = useToken();
  const dispatch = useDispatch();
  const recentGroupId = useSelector(selectRecentGroupId);

  const openModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (recentGroupId) {
      const canUpdate = async () => await MoodApiService.canUpdateMood(token, recentGroupId);
      canUpdate().then((res) => {
        if (res.data.can_update) {
          setIsModalOpen(true);
        }
      });
    }
    setIsModalOpen(false);
  }, []);

  const [selectedEmoji, setSelectedEmoji] = useState({ mood_label: 'Curious', mood_value: 4, mood_color: '#FFC700' });
  const [isLoading, setIsLoading] = useState(false);

  const setEmojiByValue = (e: number) => {
    switch (e) {
      case 0:
        return setSelectedEmoji({ mood_label: 'Sad', mood_value: e, mood_color: '#EA000E' });
      case 1:
        return setSelectedEmoji({ mood_label: 'Frustrated', mood_value: e, mood_color: '#EB3648' });
      case 2:
        return setSelectedEmoji({ mood_label: 'Angry', mood_value: e, mood_color: '#EB3F08' });
      case 3:
        return setSelectedEmoji({ mood_label: 'Tired', mood_value: e, mood_color: '#EA7E00' });
      case 4:
        return setSelectedEmoji({ mood_label: 'Curious', mood_value: e, mood_color: '#FFC700' });
      case 5:
        return setSelectedEmoji({ mood_label: 'Confused', mood_value: e, mood_color: '#E5EA00' });
      case 6:
        return setSelectedEmoji({ mood_label: 'Excited', mood_value: e, mood_color: '#9FEA00' });
      case 7:
        return setSelectedEmoji({ mood_label: 'Confident', mood_value: e, mood_color: '#89D35B' });
      case 8:
        return setSelectedEmoji({ mood_label: 'Happy', mood_value: e, mood_color: '#13EA00' });
    }
  };

  const submitMood = async () => {
    setIsLoading(true);
    const submitResult = await MoodApiService.postMode(
      token,
      recentGroupId,
      selectedEmoji.mood_value,
      currentDateInIsoFormat({ currentTimezone: true }),
    );
    if (submitResult.ok) {
      ReactGA.event({
        category: 'User',
        action: 'submit_mood',
        label: 'The user submit a new mood in the group',
      });
      setTimeout(() => {
        dispatch(
          getGroupsWeekMoods({
            token,
            groupId: recentGroupId,
            from: format(getMondayOfCurrentWeek(), 'yyyy-MM-dd'),
          }),
        );
        setIsModalOpen(false);
        //  This delay is for backend to have time for updating moods
      }, 800);
      dispatch(getFullGroup({ token: token, groupId: recentGroupId }));
    } else {
      MessageHockPopUp({
        message: 'Something went wrong!',
        timeOut: 3000,
        type: 'error',
        size: 'small',
      });
    }
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={openModal}
      className={styles.fellingModalMain}
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
    >
      {isLoading && (
        <div style={{ position: 'absolute', zIndex: '200' }}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.fellingModalLogo}>{SmallVextLogo()}</div>
      <h2>How's your mood today?</h2>
      <div
        style={{
          fontSize: '38px',
          marginBottom: '38px',
          border: `2px solid ${selectedEmoji.mood_color}`,
          borderRadius: '50%',
          display: 'flex',
          padding: '8px',
        }}
      >
        {getMyEmojiByValue(selectedEmoji.mood_value)}
      </div>
      <div style={{ fontSize: '38px', marginBottom: '38px', color: selectedEmoji.mood_color }}>
        {selectedEmoji.mood_label}
      </div>
      <div>
        <Box width={300}>
          <MySlider
            style={{
              color: 'white',
            }}
            onChange={(e: any) => setEmojiByValue(e.target.value)}
            step={1}
            min={0}
            max={8}
            defaultValue={4}
            aria-label="Default"
            valueLabelDisplay="off"
            sx={{
              '& .MuiSlider-thumb': {
                backgroundColor: selectedEmoji.mood_color,
              },
            }}
          />
        </Box>
      </div>
      <div className={styles.submitMood} onClick={submitMood}>
        Submit
      </div>
      <div
        className={styles.skipMood}
        onClick={() => {
          setIsModalOpen(false);
          ReactGA.event({
            category: 'User',
            action: 'skip_mood',
            label: 'The user skip submit mood in the group',
          });
        }}
      >
        Skip
      </div>
    </Modal>
  );
}
