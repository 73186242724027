import React from 'react';
import { dots3Icon } from 'images/dots3Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { infoIcon } from 'images/infoIcon';
import { messageTopicIcon } from 'images/messageTopicIcon';
import { reportIcon } from 'images/logo/reportIcon';
import styles from './peopleStatisticsStyles.module.css';
import { ChatService } from '../../../../services/socket.service';
import { useToken } from '../../../../cookies';
import { User } from '../../../../types/User';

function PeopleActions({ person }: { person: User }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { token } = useToken();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const requestConnection = () => {
    //  TODO people
    ChatService.initiateAConversation({
      token,
      userIds: [person.id],
      should_targets_accept: true,
      should_send_notification: true,
    }).then((res) => {
      console.log('initConvResp..', res);
      // TODO ... add notification
    });
  };

  return (
    <div className={styles.rightOptions}>
      <div style={{ cursor: 'pointer' }} onClick={handleClick}>
        {dots3Icon('#757575', '24', '24')}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => handleClose()}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <div className={styles.menuItemIcon}>{infoIcon('black')}</div>
            <div> Suspend for 30 days</div>
          </div>
        </MenuItem>
        <MenuItem onClick={() => handleClose()}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <div className={styles.menuItemIcon}>{messageTopicIcon('black')}</div>
            <div onClick={requestConnection}>Request connection</div>
          </div>
        </MenuItem>
        <MenuItem onClick={() => handleClose()}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <div className={styles.menuItemIcon}>{reportIcon()}</div>
            <div>Report to admin</div>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default PeopleActions;
