import React, { Dispatch } from 'react';
import { getColorByMood } from '../../../../components/Recharts/RechartsGroupMood';
import PopUp from './PopUp';
import { User } from '../../../../types/User';
import MoodEmergencyDetails from './MoodEmergencyDetails';
import styles from '../dashboardStyles.module.css';

type Props = {
  moodVal: number|null;
  index: string;
  setActiveIndex: React.Dispatch<React.SetStateAction<string>>;
  activeIndex: string;
  userId: string;
  setSelectedUser: Dispatch<React.SetStateAction<User | null>>;
  selectedUser: User | null;
  emergenciesByGroupId: { [p: string]: boolean };
};

function IndividualSquare({
  moodVal,
  index,
  setActiveIndex,
  activeIndex,
  setSelectedUser,
  userId,
  selectedUser,
  emergenciesByGroupId,
}: Props) {

  const handleClick = () => {
    if (activeIndex === index) {
      setActiveIndex('-1');
      setSelectedUser(null);
    } else {
      setActiveIndex(index);
    }
  };

  const clickOutside=()=>{
    setActiveIndex('-1');
    setSelectedUser(null);
  }
  return (
    <div
      onClick={handleClick}
      className={`${styles.square} ${activeIndex === index && styles.active}`}
      style={{
        backgroundColor: activeIndex === index ? '' : getColorByMood(moodVal),
        color: activeIndex === index ? getColorByMood(moodVal) : '',
      }}
    >
      {index === activeIndex && 'X'}
      {index === activeIndex && (
        <PopUp width={400} height={136} activeIndex={activeIndex} clickOutside={clickOutside}>
          <MoodEmergencyDetails
            emergenciesByGroupId={emergenciesByGroupId}
            setSelectedUser={setSelectedUser}
            userId={userId}
            selectedUser={selectedUser}
            moodVal={moodVal}
          />
        </PopUp>
      )}
    </div>
  );
}

export default IndividualSquare;