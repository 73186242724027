import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ArrowBackButton from '../../Buttons/ArrowBackButton';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import { darkTheme } from '../../../../themes/themes';
import { ThemeProvider } from '@mui/material/styles';
import ImageMobileInput from '../../../../helpers/ImageMobileInput';
import MessageHockPopUp from '../../../../hooks/MessageHockPopUp';
import { closeWhiteCircleIcon } from 'images/closeWhiteCircleIcon';
import { useHistory } from 'react-router-dom';
import { getTags } from '../../../../store/tags';
import { useToken } from '../../../../cookies';
import { selectTags } from '../../../../store/tags/selectors';
import styles from './CreateGroupMobileStyle.module.css';

type Sep1Props = {
  setStep: Dispatch<SetStateAction<number>>;
  setGroupName: Dispatch<SetStateAction<string>>;
  setGroupSubject: Dispatch<SetStateAction<Array<string>>>;
  setGroupPrivacy: Dispatch<SetStateAction<boolean>>;
  setGroupIncognito: Dispatch<SetStateAction<string>>;
  setGroupImage: Dispatch<SetStateAction<any>>;
  groupName: string;
  groupImage?: string;
  setImagePreview: Dispatch<SetStateAction<any>>;
};

export default function Step1({
  setStep,
  setGroupName,
  setGroupSubject,
  setGroupPrivacy,
  setGroupIncognito,
  setGroupImage,
  groupImage,
  groupName,
  setImagePreview,
}: Sep1Props) {
  const tags = useSelector(selectTags);
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useToken();
  const [error, setError] = useState('');
  const [localSelectedTags, setLocalSelectedTags] = useState<Array<string>>([]);

  useEffect(() => {
    dispatch(getTags({ token }));
  }, []);

  const subjectItems = () => {
    return tags.map((subject) => {

      return (
        <MenuItem key={subject.id} value={subject.id}>
          {subject.name}
        </MenuItem>
      );
    });
  };

  return (
    <div className={styles.createGroupMain}>
      <ThemeProvider theme={darkTheme}>
        <div className={styles.headerCreateGroup}>
          <ArrowBackButton pushUrl={'/feed'} />
          <div onClick={() => history.push('./feed')}>{closeWhiteCircleIcon('38', '34')}</div>
        </div>
        <div className={styles.createGroupForm}>
          <h1 className={styles.headTextTitle}>Create a group</h1>
          <TextField
            required
            style={{ backgroundColor: '#2A2A2A' }}
            onChange={(e) => {
              if (e.target.value.length > 0 && e.target.value.length < 4) {
                setError('Minimum 3 characters ');
              } else if (!e.target.value.length) {
                setError('Group name is required');
              } else {
                setError('');
              }
              setGroupName(e.target.value);
            }}
            fullWidth
            label="Name"
            value={groupName}
            id="outlined-size-small"
            size="small"
            InputLabelProps={{
              style: {
                color: '#8B8C8D',
                fontWeight: 300,
              },
            }}
          />
          {!!error && <p className={styles.error}>{error}</p>}
          {!!tags.length && (
            <TextField
              SelectProps={{
                multiple: true,
                value: localSelectedTags,
                onChange: (e) => {
                  const selectedTags = e.target.value as Array<string>;
                  setGroupSubject(selectedTags);
                  setLocalSelectedTags(selectedTags);
                },
                MenuProps: { sx: { maxHeight: '50%' } },
              }}
              style={{ marginTop: '18px', backgroundColor: '#2A2A2A' }}
              select
              fullWidth
              label="Subject/Interest/Tags"
              size="small"
              InputLabelProps={{
                style: {
                  color: '#8B8C8D',
                  fontWeight: 300,
                },
              }}
            >
              {subjectItems()}
            </TextField>
          )}
          <TextField
            onChange={(e) => setGroupPrivacy(e.target.value === 'true')}
            style={{ marginTop: '18px', backgroundColor: '#2A2A2A' }}
            select
            fullWidth
            label="Privacy"
            size="small"
            InputLabelProps={{
              style: {
                color: '#8B8C8D',
                fontWeight: 300,
              },
            }}
          >
            <MenuItem key={1} value="true">
              Private
            </MenuItem>
            <MenuItem key={2} value="false">
              Public
            </MenuItem>
          </TextField>
          <TextField
            onChange={(e) => setGroupIncognito(e.target.value)}
            style={{ marginTop: '18px', backgroundColor: '#2A2A2A' }}
            select
            fullWidth
            label="Allow incognito?"
            size="small"
            InputLabelProps={{
              style: {
                color: '#8B8C8D',
                fontWeight: 300,
              },
            }}
          >
            <MenuItem key={1} value="yes">
              Yes
            </MenuItem>
            <MenuItem key={2} value="no">
              No
            </MenuItem>
          </TextField>
          <ImageMobileInput setImage={setGroupImage} setImagePreview={setImagePreview} />
          <div
            className={styles.nextButton}
            onClick={() => {
              if (groupName.length > 3 && groupImage) {
                setStep(2);
              } else {
                MessageHockPopUp({
                  message: `Group ${groupName ? 'image' : 'name'} missing`,
                  timeOut: 4000,
                  type: 'warning',
                  size: 'small',
                });
              }
            }}
          >
            Next
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
}
