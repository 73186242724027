import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRecentGroupId } from '../../store/groups';
import { addOrRemovePaperLike, createComment } from '../../store/articles';
import { useToken } from '../../cookies';
import { selectSelectedPaper } from '../../store/articles/selector';
import { getMoodIconByName } from '../../helpers/moodList';
import { dots3Icon } from 'images/dots3Icon';
import { generateRandomNicknameId, getAnonymousImage } from '../../helpers/anonymousGenerator';
import { ImagesTemplate } from '../../components/layout/ImagesTemplate';
import { messageTopicIcon } from 'images/messageTopicIcon';
import { LikeThumbsUpOutline } from 'images/LikeThumbsUpOutline';
import { LikeThumbsUpIcon } from 'images/LikeThumbsUpIcon';
import { selectUserId } from '../../store/auth';
import { selectLoggedUserData } from '../../store/user/selectors';
import { getUser } from '../../store/user';
import MobileCommentBlock from '../../components/mobile/MobileArticleView/components/MobileCommentBlock';
import { IComment } from '../../types/Paper';
import { findMyAnonymousNameById, normalizedCommentsStructure } from '../../helpers/comments';
import SendCommentBlock from '../../helpers/SendCommentBlock';
import { userNameFromAllParams } from '../../helpers/userName';
import styles from './QuestionMobileStyle.module.css';
import MobileHeader from '../../components/mobile/Header/MobileHeader';

export default function QuestionMobile() {
  const [commentInput, setCommentInput] = useState('');
  const [isMessageAnonymous, setIsMessageAnonymous] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<boolean>(false);
  const { token } = useToken();
  const dispatch = useDispatch();
  const recentGroupId = useSelector(selectRecentGroupId);
  const selectedPaper = useSelector(selectSelectedPaper);
  const loggedUserId = useSelector(selectUserId);
  const loggedUser = useSelector(selectLoggedUserData);
  let normalizedComments: IComment[] = normalizedCommentsStructure(selectedPaper.comments || []);
  useEffect(() => {
    if (!loggedUser.id) {
      dispatch(getUser({ token: token, userId: loggedUserId }));
    }
  }, []);

  const removeLikeFromQuestion = () => {
    dispatch(
      addOrRemovePaperLike({
        author: { ...loggedUser, id: loggedUserId },
        articleId: selectedPaper.id,
        token,
        isAddLike: false,
      }),
    );
  };
  const addLikeToQuestion = () => {
    dispatch(
      addOrRemovePaperLike({
        author: { ...loggedUser, id: loggedUserId },
        articleId: selectedPaper.id,
        token,
        isAddLike: true,
      }),
    );
  };

  const isQuestionLiked = () => {
    return !selectedPaper.likes.find((like) => like.profile?.user === loggedUserId);
  };

  const addComment = () => {
    // anonymous_name
    //TODO,,, verify if this name is not already used by another user and if this is my post use it's anonymous name
    if (commentInput.length && isMessageAnonymous) {
      const randomName: string = findMyAnonymousNameById(selectedPaper, loggedUserId)
        ? (findMyAnonymousNameById(selectedPaper, loggedUserId) as string)
        : generateRandomNicknameId();

      dispatch(
        createComment({
          token,
          articleId: selectedPaper.id,
          comment: commentInput,
          userId: loggedUserId,
          anonymous_name: randomName,
        }),
      );
    }
    if (commentInput.length && !isMessageAnonymous) {
      dispatch(
        createComment({
          token,
          articleId: selectedPaper.id,
          comment: commentInput,
          userId: loggedUserId,
        }),
      );
    }
    setCommentInput('');
  };

  return (
    <>
      {/*TODO add loader*/}
      {selectedPaper && (
        <div className={styles.questionMobileMain}>
          {/*<MobileHomeHeader isHomePage isGroupPage pushUrl={'/group/' + recentGroupId} />*/}
          <MobileHeader />
          <div className={styles.questionContent}>
            <div className={styles.questionContentMiddleBlock}>
              <div className={styles.left}>
                <div className={styles.questionTitle}>
                  {selectedPaper.mood && <span>{getMoodIconByName(selectedPaper.mood)}</span>}
                  <span>{selectedPaper.title}</span>
                </div>
                <p>{selectedPaper.description}</p>
              </div>
              <div className={styles.right}>{dots3Icon()}</div>
            </div>
            <div className={styles.qtUserInfo}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '14px' }}>
                  {selectedPaper.anonymous_name ? (
                    getAnonymousImage(selectedPaper.anonymous_name, 'medium')
                  ) : (
                    <ImagesTemplate url={selectedPaper.paper_author?.profile?.image_url || ''} height={60} width={60} />
                  )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>
                    {selectedPaper.anonymous_name ||
                      userNameFromAllParams(
                        selectedPaper?.paper_author?.username,
                        selectedPaper.paper_author?.profile?.first_name,
                        selectedPaper.paper_author?.profile?.last_name,
                      )}
                  </span>
                  <span style={{ color: '#757575', fontSize: '12px', lineHeight: '18px' }}>
                    {selectedPaper.paper_author?.profile?.institute &&
                      selectedPaper.paper_author?.profile?.institute + 'and'}
                    {selectedPaper.paper_author?.profile?.graduation}
                  </span>
                </div>
              </div>

              <div className={styles.likesBlock}>
                <div className={styles.commentsBlock}>
                  {messageTopicIcon('white')} {selectedPaper.comments.length}
                </div>
                <div className={styles.likes}>
                  {isQuestionLiked() ? (
                    <div onClick={() => addLikeToQuestion()}>{LikeThumbsUpOutline('white', '20')}</div>
                  ) : (
                    <div onClick={() => removeLikeFromQuestion()}>{LikeThumbsUpIcon('white', '20')}</div>
                  )}
                  {selectedPaper.likes.length}
                </div>
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: '#131313', marginTop: '12px', padding: '0 12px 110px 12px' }}>
            {!!normalizedComments.length &&
              normalizedComments.map((comment) => {
                return <MobileCommentBlock comment={comment} key={comment.id} />;
              })}
          </div>
          <SendCommentBlock
            setCommentInput={setCommentInput}
            commentInput={commentInput}
            isMessageAnonymous={isMessageAnonymous}
            setIsMessageAnonymous={setIsMessageAnonymous}
            setTooltipOpen={setTooltipOpen}
            isTooltipOpen={isTooltipOpen}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            addComment={addComment}
          />
        </div>
      )}
    </>
  );
}
