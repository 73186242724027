import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './adminStyles.module.css';

import { useToken } from '../../cookies';
import { selectUserId } from '../../store/auth';
import { clearUserState, getUser } from '../../store/user';
import { selectLoggedUserData } from '../../store/user/selectors';
import { TabsVariants } from '../../utils/enums';
import Chats from '../Components/chats/Chats';
import DashBoard from '../Components/dashboard/DashBoard';
import Emergencies from '../Components/emergencies/Emergencies';
import Navbar from '../Components/navbar/Navbar';
import Statistics from '../Components/statistics/Statistics';

function Admin() {
  const [selectedTab, setSelectedTab] = useState<TabsVariants>(TabsVariants.DASHBOARD);
  const loggedUserId = useSelector(selectUserId);

  const userData = useSelector(selectLoggedUserData);
  const dispatch = useDispatch();
  const { removeToken } = useToken();
  const history = useHistory();
  const { token } = useToken();

  useEffect(() => {
    if (!token) {
      dispatch(clearUserState());
      removeToken();
      return history.push('/admin/auth');
    }
    if (token && userData.id) {
      if (!userData.is_staff) {
        history.push('/feed');
      }
    }
  }, [userData]);

  useEffect(() => {
    dispatch(
      getUser({
        token,
        userId: loggedUserId,
      }),
    );
  }, []);

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case TabsVariants.STATISTICS:
        return <Statistics />;
      case TabsVariants.CHATS:
        return <Chats />;
      case TabsVariants.EMERGENCIES:
        return <Emergencies />;
      case TabsVariants.DASHBOARD:
        return <DashBoard />;
      default:
        return <Statistics />;
    }
  };

  return (
    <div className={styles.adminMain}>
      <Navbar setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
      {displaySelectedTab()}
    </div>
  );
}

export default Admin;
