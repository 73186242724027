export const pandaIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.001 4.68464C30.001 2.1058 27.8952 0 25.3163 0H23.2411C21.6541 0 20.2044 0.793492 19.3499 2.1058C17.9308 1.40387 16.4506 1.00712 15.001 1.00712C13.5513 1.00712 12.0864 1.41913 10.652 2.1058C9.79751 0.808751 8.33261 0 6.76089 0H4.68562C2.10678 0 0.000976562 2.1058 0.000976562 4.68464C0.000976562 6.68362 1.28277 8.43845 3.12916 9.09461C1.22173 11.9939 0.000976562 15.2289 0.000976562 18.0061C0.000976562 25.178 7.75275 30 15.001 30C22.2492 30 30.001 25.178 30.001 18.0061C30.001 15.2289 28.7802 11.9939 26.8728 9.09461C28.7192 8.43845 30.001 6.68362 30.001 4.68464ZM23.2563 2.01424H25.3316C26.8118 2.01424 28.0172 3.21974 28.0172 4.6999C28.0172 5.98169 27.1017 7.08036 25.8504 7.32451L25.6215 7.3703C24.294 5.70702 22.7528 4.24211 21.1047 3.12817C21.6083 2.4415 22.3865 2.01424 23.2563 2.01424ZM4.16679 7.32451C2.91552 7.08036 1.99996 5.96643 1.99996 4.6999C1.99996 3.21974 3.20545 2.01424 4.68562 2.01424H6.76089C7.63068 2.01424 8.40891 2.4415 8.91248 3.12817C7.26446 4.24211 5.72325 5.70702 4.39568 7.3703L4.16679 7.32451ZM15.001 28.001C8.85143 28.001 1.99996 23.8962 1.99996 18.0061C1.99996 11.9481 8.85143 3.0061 15.001 3.0061C21.1505 3.0061 28.002 11.9481 28.002 18.0061C28.002 23.8962 21.1505 28.001 15.001 28.001Z"
      fill="#333333"
    />
    <path
      d="M14.1774 12.8636L14.0248 10.4831C13.9333 9.09452 13.0787 7.87377 11.7969 7.30917C10.8814 6.89716 9.84373 6.88191 8.9129 7.26339C7.96682 7.62962 7.23437 8.34681 6.85289 9.27764L6.15095 10.9409C5.98309 11.3224 5.76946 11.6734 5.51005 11.9938L4.86916 12.772C3.58737 14.3438 3.77048 16.6174 5.3422 18.006C6.04413 18.6316 6.92918 18.9521 7.81423 18.9521C8.37882 18.9521 8.95868 18.8148 9.49276 18.5554L11.507 17.5482C13.2618 16.6479 14.3147 14.8168 14.1774 12.8636ZM10.6067 15.7476L8.59245 16.7547C7.95156 17.0752 7.18859 16.9684 6.60873 16.4496C5.9068 15.8239 5.81524 14.771 6.41036 14.0386L7.05126 13.2603C7.43274 12.8026 7.75318 12.2837 7.98208 11.7191L8.68401 10.0559C8.86713 9.6286 9.20283 9.29289 9.64535 9.10978C9.85899 9.03348 10.0726 8.9877 10.2863 8.9877C10.5304 8.9877 10.7593 9.03348 10.9882 9.1403C11.5833 9.39971 11.98 9.96431 12.0258 10.6205L12.1784 13.0009C12.27 14.1454 11.6443 15.2288 10.6067 15.7476Z"
      fill="#333333"
    />
    <path
      d="M10.6368 12.8636C10.4842 12.3296 9.91958 12.0244 9.40076 12.177C8.86668 12.3296 8.56149 12.8789 8.71409 13.413L8.85142 13.886C8.9735 14.3285 9.37024 14.6185 9.81276 14.6185C9.90432 14.6185 9.99588 14.6032 10.0874 14.5727C10.6215 14.4201 10.9267 13.8708 10.7741 13.3367L10.6368 12.8636Z"
      fill="#333333"
    />
    <path
      d="M21.1046 7.26344C20.1738 6.89721 19.1361 6.91247 18.2206 7.30922C16.9388 7.87381 16.0843 9.09456 15.9927 10.4832L15.8401 12.8484C15.7028 14.8168 16.7557 16.648 18.5258 17.5178L20.54 18.5249C21.0741 18.7843 21.6387 18.9216 22.2033 18.9216C23.1036 18.9216 24.0039 18.5859 24.7516 17.9298C26.2623 16.5869 26.4454 14.3133 25.1636 12.7416L24.5227 11.9633C24.2633 11.6429 24.0497 11.2919 23.8818 10.9104L23.1799 9.24716C22.7831 8.34686 22.0507 7.62967 21.1046 7.26344ZM23.6071 14.0386C24.2023 14.7711 24.126 15.824 23.363 16.4954C22.8289 16.9684 22.0659 17.0752 21.4251 16.7548L19.3955 15.7477C18.3579 15.2288 17.7475 14.1454 17.8238 13.001L17.9764 10.6358C18.0222 9.97961 18.4189 9.41501 19.0141 9.1556C19.2429 9.06404 19.4718 9.00301 19.716 9.00301C19.9296 9.00301 20.1585 9.04879 20.3569 9.12509C20.7994 9.29294 21.1351 9.62864 21.3182 10.0712L22.0202 11.7344C22.2491 12.299 22.5695 12.8026 22.951 13.2756L23.6071 14.0386Z"
      fill="#333333"
    />
    <path
      d="M20.6167 12.177C20.0826 12.0244 19.5333 12.3296 19.3807 12.8636L19.2434 13.3367C19.0908 13.8708 19.396 14.4201 19.9301 14.5727C20.0216 14.6032 20.1132 14.6185 20.2047 14.6185C20.6473 14.6185 21.044 14.3285 21.1661 13.886L21.3034 13.413C21.456 12.8789 21.1508 12.3296 20.6167 12.177Z"
      fill="#333333"
    />
    <path
      d="M18.0072 24.0031H17.0001C16.4508 24.0031 15.993 23.5606 15.993 22.996V22.6603C16.1914 22.5229 16.3745 22.3703 16.5271 22.172L17.6105 20.6155C18.0683 19.9593 18.1141 19.1201 17.7478 18.4181C17.3816 17.7162 16.6644 17.2737 15.8709 17.2737H14.1161C13.3226 17.2737 12.6054 17.7162 12.2392 18.4181C11.873 19.1201 11.934 19.9593 12.3765 20.6155L13.4599 22.172C13.5973 22.3703 13.7804 22.5382 13.994 22.6603V22.996C13.994 23.5453 13.5515 24.0031 12.9869 24.0031H11.9798C11.4304 24.0031 10.9727 24.4456 10.9727 25.0102C10.9727 25.5748 11.4152 26.0173 11.9798 26.0173H12.9869C13.7651 26.0173 14.4518 25.7121 14.9859 25.2391C15.52 25.7121 16.2219 26.0173 16.9849 26.0173H17.992C18.5413 26.0173 18.9991 25.5748 18.9991 25.0102C18.9991 24.4456 18.5566 24.0031 18.0072 24.0031ZM14.0245 19.349C14.0551 19.2879 14.1161 19.2879 14.1313 19.2879H15.8862C15.9014 19.2879 15.9625 19.2879 15.993 19.349C16.0235 19.41 15.993 19.4558 15.9777 19.471L15.0011 20.8596L14.0245 19.471C14.0245 19.4558 13.994 19.41 14.0245 19.349Z"
      fill="#333333"
    />
  </svg>
);
