import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorGuard } from '../../utils/errorGuard';
import { ERROR } from '../../errorsTemplates';
import { isPendingAction, isRejectedAction } from '../../utils/redux.utils';
import { StatisticsApiService } from '../../services/statistics.service';
import { IGroup } from '../../types/Group';
import { GroupsApiService } from '../../services/groups.service';
import { days, getDaysArray } from '../../helpers/date';
import { IDayMoodForAllGroups } from '../../types/Moods';
import { User } from '../../types/User';
import { PeopleOrGroupsSwitch, SortByMoods } from '../../utils/enums';

export const STATISTICS_REDUCER_NAME = 'statistics';

type InitialStateType = {
  allGroupsBaseInfo: { count: number; next: string | null; results: { [key: string]: IGroup } };
  allPeoplesBaseInfo: { count: number; next: string | null; results: { [key: string]: User } };
  error: string | null;
  averageStatisticsMoods: { [key: string]: Array<{ name: string; val: null | number }> };
  detailedStatisticsMoods: { [key: string]: Array<IDayMoodForAllGroups> };
  badMoodsAccounts: { [key: string]: Array<User> };
  groupsPage: number;
  peoplePage: number;
  outlierCount: { [key: string]: number };
  loading: boolean;
};
const baseResult = {
  count: 0,
  next: null,
  results: {},
};
const initialState: InitialStateType = {
  allGroupsBaseInfo: baseResult,
  allPeoplesBaseInfo: baseResult,
  averageStatisticsMoods: {},
  detailedStatisticsMoods: {},
  badMoodsAccounts: {},
  groupsPage: 1,
  peoplePage: 1,
  outlierCount: {},
  loading: false,
  error: null,
};

export const getFilteredGroups = createAsyncThunk(
  `${STATISTICS_REDUCER_NAME}/getMyMoodsPerGroup`,
  async ({
    token,
    module,
    searchParam,
    sortBy,
    moods,
    tags,
    page,
  }: {
    token: string;
    module: PeopleOrGroupsSwitch;
    page: number;
    searchParam?: string;
    sortBy?: SortByMoods | string;
    moods?: Array<number>;
    tags?: Array<string>;
  }) => {
    try {
      const response = await StatisticsApiService.getAllFilteredGroups(
        token,
        module,
        page,
        searchParam,
        sortBy,
        moods,
        tags,
      );
      const isFilteredReq = !!searchParam || !!sortBy || !!moods?.length || !!tags?.length;

      return { data: response.data, module, isFilteredReq };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

export const getAverageMoodsForSpecificGroup = createAsyncThunk(
  `${STATISTICS_REDUCER_NAME}/getMoodsAverage`,
  async ({ token, groupId, from, to }: { token: string; groupId?: string; from: string; to: string }) => {
    try {
      const response = groupId
        ? await GroupsApiService.getGroupMoodsByDate(token, groupId, from, to)
        : await GroupsApiService.getAverageDomainMoodsByDate(token, from, to);
      return { data: response.data, groupId: groupId || 'all_groups' };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

export const getDetailedMoodsForSpecificGroup = createAsyncThunk(
  `${STATISTICS_REDUCER_NAME}/getMoodsDetailed`,
  async ({ token, groupId, from, to }: { token: string; from: string; to: string; groupId?: string }) => {
    try {
      const response = await StatisticsApiService.getDetailedMoodsStatistics(token, from, to, groupId);
      const daysDiff = getDaysArray(from, to);
      return { data: response.data, groupId: groupId || 'all_groups', daysDiff };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

export const getBadMoodPeople = createAsyncThunk(
  `${STATISTICS_REDUCER_NAME}/getBadMoodPeople`,
  async ({ token, groupId, page }: { token: string; groupId: string; page: number }) => {
    try {
      const response = await StatisticsApiService.getPeopleWithBadMood(token, groupId, page);
      return { data: response.data, groupId };
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.common.something_went_wrong);
    }
  },
);

const statisticsSlice = createSlice({
  name: STATISTICS_REDUCER_NAME,
  initialState,
  reducers: {
    setStatisticsGroupPage(state, { payload }) {
      state.groupsPage = payload;
    },
    setStatisticsPeoplePage(state, { payload }) {
      state.peoplePage = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getBadMoodPeople.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const { data, groupId } = payload;
        state.outlierCount = { ...state.outlierCount, [groupId]: data.count };
        state.badMoodsAccounts = { ...state.badMoodsAccounts, [groupId]: data.results };
        state.loading = false;
        state.error = null;
      })

      .addCase(getAverageMoodsForSpecificGroup.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const { data, groupId } = payload;
        if (Object.keys(data.moods).length > 8) {
          const monthMoods = Object.entries(
            data.moods as {
              [key: string]: number | null;
            },
          ).map(([key, val]) => {
            return {
              name: key.slice(key.length - 2),
              val: Math.floor(val ?? -1) === -1 ? null : Math.floor(val as number),
            };
          });

          state.averageStatisticsMoods = { ...state.averageStatisticsMoods, [groupId]: monthMoods };
        } else {
          const weekMoods = Object.values(
            data.moods as {
              [key: string]: number | null;
            },
          ).map((val, index) => {
            return {
              name: days[index]?.name,
              val: Math.floor(val ?? -1) === -1 ? null : Math.floor(val as number),
            };
          });
          state.averageStatisticsMoods = { ...state.averageStatisticsMoods, [groupId]: weekMoods };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(
        getDetailedMoodsForSpecificGroup.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            data: { [key: string]: {} } | null;
            groupId: string;
            daysDiff: Array<string>;
          }>,
        ) => {
          let { data, groupId, daysDiff } = payload;
          const daysWithMoods = daysDiff.reduce((acc: any, curr: any) => {
            let _data;
            if (data && data[curr]) {
              _data = Object.entries(data[curr]).reduce((_acc: any, [_key, _val]) => {
                return { ..._acc, [Math.round(Number(_key))]: _val };
              }, {});
            } else _data = null;

            return [...acc, { [curr]: _data }];
          }, []);
          const final = daysWithMoods.map((day, index) => {
            const key = Object.keys(day)[0];

            return {
              name: daysDiff.length > 8 ? key.slice(key.length - 2) : days[index].name,
              happy: { val: 8, times: day[key]?.['8'] || 0 },
              confident: { val: 7, times: day[key]?.['7'] || 0 },
              excited: { val: 6, times: day[key]?.['6'] || 0 },
              confused: { val: 5, times: day[key]?.['5'] || 0 },
              curious: { val: 4, times: day[key]?.['4'] || 0 },
              tired: { val: 3, times: day[key]?.['3'] || 0 },
              angry: { val: 2, times: day[key]?.['2'] || 0 },
              frustrated: { val: 1, times: day[key]?.['1'] || 0 },
              sad: { val: 0, times: day[key]?.['0'] || 0 },
            };
          });

          state.detailedStatisticsMoods = { ...state.detailedStatisticsMoods, [groupId]: final };
          state.loading = false;
          state.error = null;
        },
      )
      .addCase(getFilteredGroups.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const { data, isFilteredReq, module } = payload;

        const _results = data?.results?.reduce((acc: any, cur: any) => {
          return { ...acc, [cur.id]: cur };
        }, {});
        let allResults;
        if (module === PeopleOrGroupsSwitch.GROUPS) {
          if (isFilteredReq && state.groupsPage < 2) {
            allResults = _results;
          } else {
            allResults = { ...state.allGroupsBaseInfo.results, ..._results };
          }
          state.allGroupsBaseInfo = { ...data, results: allResults };
        } else {
          if (isFilteredReq && state.peoplePage < 2) {
            allResults = _results;
          } else {
            allResults = { ...state.allPeoplesBaseInfo.results, ..._results };
          }
          state.allPeoplesBaseInfo = { ...data, results: allResults };
        }
        state.loading = false;
        state.error = null;
      })
      .addMatcher(isPendingAction(`${STATISTICS_REDUCER_NAME}/`), (state) => {
        state.error = null;
        state.loading = true;
      })
      .addMatcher(isRejectedAction(`${STATISTICS_REDUCER_NAME}/`), (state, { error }) => {
        state.error = 'Statistics error';
        state.loading = false;
      });
  },
});

export const {setStatisticsGroupPage, setStatisticsPeoplePage} = statisticsSlice.actions;
export default statisticsSlice.reducer;
