import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorGuard } from '../../utils/errorGuard';
import { ERROR } from '../../errorsTemplates';
import { AuthApiService } from '../../services/auth.service';
import { isRejectedAction } from '../../utils/redux.utils';
import { DomainType } from '../../types/Auth';

export const AUTH_REDUCER_NAME = 'auth';

type IUserData = {
  uuid: string;
  email: string;
};

type InitialStateType = {
  error: string | null;
  uid: string;
  email: string;
  domains: Array<DomainType>;
  domainPrefixes: Array<string>;
};

const initialState: InitialStateType = {
  error: null,
  uid: '',
  email: '',
  domains: [],
  domainPrefixes: [],
};

export const getMailDomains = createAsyncThunk(`${AUTH_REDUCER_NAME}/domains`, async () => {
  try {
    const response = await AuthApiService.getDomains();
    if (Array.isArray(response.data)) {
      return response.data;
    } else {
      return [];
    }
  } catch (e) {
    errorGuard(e);
    throw new Error(ERROR.updateUser.update_failed);
  }
});
export const updateDeviceToken = createAsyncThunk(
  `${AUTH_REDUCER_NAME}/updateToken`,
  async ({ deviceToken, token, userId }: { deviceToken: string; token: string; userId: string }) => {
    try {
       await AuthApiService.updateDeviceToken(deviceToken, token, userId);
    } catch (e) {
      errorGuard(e);
      throw new Error(ERROR.updateUser.update_failed);
    }
  },
);

const authSlice = createSlice({
  name: AUTH_REDUCER_NAME,
  initialState,
  reducers: {
    setUserLoginData(state, { payload: userData }: PayloadAction<IUserData>) {
      state.email = userData.email;
      state.uid = userData.uuid;
      state.error = null;
    },
    setAuthError(state, { payload: message }: PayloadAction<string>) {
      state.error = message;
    },
    setRegisterId(state, { payload: id }: PayloadAction<string>) {
      state.uid = id;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMailDomains.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.domains = payload;
        // @ts-ignore
        state.domainPrefixes = payload.flatMap(({ email_prefix }: string[]) => email_prefix);
        state.error = null;
      })
      .addMatcher(isRejectedAction(`${AUTH_REDUCER_NAME}/`), (state, { error }) => {
        state.error = error.message;
        state.domains = [];
      });
  },
});

export const { setUserLoginData, setAuthError, setRegisterId } = authSlice.actions;
export default authSlice.reducer;
