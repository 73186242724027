import React, { Dispatch, useEffect } from 'react';
import { dots3Icon } from 'images/dots3Icon';
import GroupUsersImages from '../../../../components/groups/GroupUsersImages';
import { GroupsApiService } from '../../../../services/groups.service';
import { useToken } from '../../../../cookies';
import { IGroup } from '../../../../types/Group';
import { NO_GROUP_COVER } from '../../../../utils/constantImagesUrl';
import GroupCreator from '../../statistics/groupComponents/GroupCreator';
import { getColorByMood } from '../../../../components/Recharts/RechartsGroupMood';
import { getMyEmojiByValue } from '../../../../components/mood/FellingMood';
import { getMoodText } from '../../../../helpers/moodList';
import styles from '../dashboardStyles.module.css';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  setSelectedGroup: Dispatch<React.SetStateAction<IGroup | null>>;
  groupId: string;
  selectedGroup: IGroup | null;
};

function GroupDetails({ groupId, setSelectedGroup, selectedGroup }: Props) {
  const { token } = useToken();

  const clickGroupBlockArea = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    setSelectedGroup(null);
    GroupsApiService.getFullGroupById(token, groupId).then((res) => {
      setSelectedGroup(res.data || null);
    });
  }, [groupId]);

  return (
    <div
      onClick={(e) => {
        clickGroupBlockArea(e);
      }}
      className={styles.groupDetails}
    >
      {selectedGroup !== null ? (
        <>
          <div className={styles.groupHeader}>
            <img className={styles.groupImg} src={selectedGroup.group_photo || NO_GROUP_COVER} alt="" />
            <div className={styles.groupName}>{selectedGroup.name}</div>
            <div style={{ cursor: 'pointer' }}>{dots3Icon('#757575', '28', '24')}</div>
          </div>
          <div className={styles.groupContent}>
            <div className={styles.groupSequence}>
              <span>Group members:</span>
              <div className={styles.groupUsersBlock}>
                {selectedGroup.users && <GroupUsersImages users={selectedGroup.users} />}
                <div className={styles.groupMembers} style={{ marginLeft: '1px', fontSize: '12px' }}>
                  {selectedGroup.users?.length} {selectedGroup.users?.length === 1 ? 'Member' : 'Members'}
                </div>
              </div>
            </div>
            <div className={styles.groupSequence}>
              <span>Responsible person:</span>
              <GroupCreator creator={selectedGroup.admin} />
            </div>
          </div>
          <div className={styles.groupBottom}>
            <div className={styles.groupSequence}>
              <span>This group is feeling</span>
              <div
                className={styles.groupFeelingMood}
                style={{
                  border: '1px solid ' + getColorByMood(Math.round(selectedGroup.general_average_weekly_mood ?? -1)),
                }}
              >
                <div>{getMyEmojiByValue(Math.round(selectedGroup.general_average_weekly_mood ?? -1), 24)}</div>
                <div
                  style={{
                    marginLeft: '4px',
                    fontSize: '14px',
                    marginBottom: '1px',
                  }}
                >
                  {getMoodText(Math.round(selectedGroup.general_average_weekly_mood ?? -1))}
                </div>
              </div>
            </div>
            <div className={styles.groupSequence}>
              <span>Status:</span>
              <div className={styles.groupStatus}>On the record</div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            position: 'absolute',
            zIndex: '200',
            width: '92%',
            height: '92%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default GroupDetails;