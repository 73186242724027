export const counselor = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.1875 0.1875H35.8125V35.8125H0.1875V0.1875Z" fill="#EB6A9F" />
    <g clipPath="url(#clip0_3750_29665)">
      <path
        d="M21.5212 25.6875V24.0208C21.5212 23.1368 21.17 22.2889 20.5448 21.6638C19.9197 21.0387 19.0719 20.6875 18.1878 20.6875H13.1878C12.3038 20.6875 11.4559 21.0387 10.8308 21.6638C10.2057 22.2889 9.85449 23.1368 9.85449 24.0208V25.6875"
        stroke="#252525"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6878 17.3542C17.5288 17.3542 19.0212 15.8618 19.0212 14.0208C19.0212 12.1799 17.5288 10.6875 15.6878 10.6875C13.8469 10.6875 12.3545 12.1799 12.3545 14.0208C12.3545 15.8618 13.8469 17.3542 15.6878 17.3542Z"
        stroke="#252525"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.7204 19.7589L24.721 19.7584C24.8221 19.6774 24.9235 19.598 25.0242 19.5191C25.4412 19.1923 25.8475 18.8739 26.1852 18.4874C26.5874 18.027 26.8542 17.5079 26.8542 16.8375C26.8542 16.1961 26.498 15.6759 26.0393 15.4628C25.6144 15.2653 25.0069 15.2911 24.3839 15.9491L24.0208 16.3327L23.6577 15.9491C23.0347 15.2909 22.4272 15.2651 22.0023 15.4625C21.5436 15.6756 21.1875 16.1957 21.1875 16.8371C21.1875 17.5077 21.4544 18.0269 21.8566 18.4873C22.1937 18.8731 22.5991 19.1911 23.0152 19.5174C23.1167 19.597 23.2188 19.6771 23.3208 19.7589L24.7204 19.7589ZM24.7204 19.7589C24.543 19.9014 24.4001 20.0134 24.267 20.0925C24.1336 20.1717 24.0585 20.1875 24.0208 20.1875C23.9832 20.1875 23.9082 20.1718 23.7746 20.0924M24.7204 19.7589L23.7746 20.0924M23.7746 20.0924C23.6415 20.0133 23.4985 19.9013 23.3209 19.759L23.7746 20.0924Z"
        fill="#252525"
        stroke="#252525"
      />
    </g>
    <defs>
      <clipPath id="clip0_3750_29665">
        <rect width="20" height="20" fill="white" transform="translate(8.1875 8.1875)" />
      </clipPath>
    </defs>
  </svg>
);
