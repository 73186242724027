export const dashboard = (color = '#757575') => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.7567 16.1358C4.24521 16.1358 3.75467 15.9326 3.39299 15.5709C3.03131 15.2093 2.82812 14.7187 2.82812 14.2072V5.72152C2.82812 5.21003 3.03131 4.71949 3.39299 4.35782C3.75467 3.99614 4.24521 3.79295 4.7567 3.79295H5.97845C6.14779 3.49964 6.39139 3.25609 6.68475 3.08682C6.93467 2.9426 7.2132 2.85663 7.49963 2.83443C7.58225 2.82803 7.64836 2.76106 7.6612 2.67919C7.69264 2.47881 7.78676 2.29204 7.93199 2.14681C8.11283 1.96597 8.35809 1.86438 8.61384 1.86438H9.38527C9.5119 1.86438 9.63729 1.88932 9.75428 1.93778C9.87128 1.98624 9.97758 2.05727 10.0671 2.14681C10.1567 2.23635 10.2277 2.34266 10.2762 2.45965C10.3054 2.53032 10.3261 2.60405 10.3379 2.67927C10.3507 2.76111 10.4168 2.82806 10.4994 2.8345C10.7857 2.85682 11.064 2.94285 11.3138 3.08706C11.607 3.25632 11.8504 3.49978 12.0197 3.79295H13.2424C13.7539 3.79295 14.2444 3.99614 14.6061 4.35782C14.9678 4.71949 15.171 5.21003 15.171 5.72152V14.2072C15.171 14.7187 14.9678 15.2093 14.6061 15.5709C14.2444 15.9326 13.7539 16.1358 13.2424 16.1358H4.7567Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M12.2778 3.79297C12.2778 4.85809 11.4144 5.72154 10.3493 5.72154H7.64927C6.58415 5.72154 5.7207 4.85809 5.7207 3.79297"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M5.14258 9.26316H7.07115L8.51758 8.03589L9.96401 9.96446L10.9283 8.56186L11.4104 9.26316H12.8569"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M5.14258 12.2786H10.9283" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.14258 14.2072H8.99972" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

