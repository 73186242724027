import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';

import localForage from 'localforage';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ARTICLES_REDUCER_NAME } from './articles';
import { rootReducer, RootState } from './rootReducer';
import { AUTH_REDUCER_NAME } from './auth';
import { USER_REDUCER_NAME } from './user';
import { GROUPS_REDUCER_NAME } from './groups';

const persistConfig = {
  key: 'root',
  storage: localForage,
  stateReconciler: autoMergeLevel2,
  whitelist: [ARTICLES_REDUCER_NAME, AUTH_REDUCER_NAME, USER_REDUCER_NAME, GROUPS_REDUCER_NAME], // which reducer want to store
};
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    // serializableCheck: false,
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export default store;
