export const hamsterIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.8473 15.2977L24.8092 11.4809C24.5191 10.3969 24.0458 9.38931 23.4351 8.48855H23.7557C26.1069 8.48855 28 6.58015 28 4.24428C28 1.89313 26.0916 0 23.7557 0H20.7176C18.8855 0 17.2672 1.16031 16.687 2.90076L16.2901 4.0916C15.8626 4.03053 15.4351 4 15.0076 4C14.5802 4 14.1527 4.03053 13.7252 4.0916L13.3283 2.90076C12.7481 1.16031 11.1298 0 9.29771 0H6.24428C3.89313 0 2 1.9084 2 4.24428C2 6.59542 3.9084 8.48855 6.24428 8.48855H6.56489C5.9542 9.38931 5.49618 10.3969 5.19084 11.4809L4.15268 15.2977C1.5878 16.6565 0 19.2824 0 22.1985C0 26.5038 3.49618 30 7.80152 30H22.1985C26.5038 30 30 26.5038 30 22.1985C30 19.2824 28.4122 16.6565 25.8473 15.2977ZM21.7405 9.55725C21.1908 9.83206 20.6107 9.98473 20 9.98473C17.8168 9.98473 16.0458 8.22901 16 6.06107C18.3512 6.35115 20.4275 7.64885 21.7405 9.55725ZM18.5802 3.52672C18.8855 2.61069 19.7405 1.98473 20.7176 1.98473H23.7557C24.9924 1.98473 26 2.99237 26 4.22901C26 5.46565 24.9924 6.47328 23.7557 6.47328H22.3817C22.1985 6.47328 22.0153 6.53435 21.8473 6.62595C20.8092 5.67939 19.5878 4.96183 18.2443 4.50382L18.5802 3.52672ZM3.98473 4.22901C3.98473 2.99237 4.99237 1.98473 6.22901 1.98473H9.26719C10.229 1.98473 11.0992 2.59542 11.4046 3.52672L11.7405 4.53435C10.3969 4.99237 9.17558 5.70992 8.13741 6.65649C7.98474 6.54962 7.80153 6.50382 7.60306 6.50382H6.22901C5.00764 6.48855 3.98473 5.48092 3.98473 4.22901ZM22.1985 27.9847H7.80152C4.61068 27.9847 2 25.3893 2 22.1832C2 19.9695 3.22137 17.9847 5.2061 16.9924L5.45039 16.8702C5.70993 16.7481 5.89313 16.5191 5.96946 16.2443L7.12978 12C8.00001 8.77863 10.7634 6.45801 14.0153 6.0458C14.0458 9.32824 16.7328 11.9847 20.0153 11.9847C20.9466 11.9847 21.8473 11.7557 22.6718 11.3282C22.7481 11.542 22.8244 11.771 22.8855 12L24.0458 16.2443C24.1221 16.5191 24.3053 16.7481 24.5649 16.8702L24.8092 16.9924C26.7786 17.9847 28.0153 19.9695 28.0153 22.1832C28 25.3893 25.3893 27.9847 22.1985 27.9847Z"
      fill="#333333"
    />
    <path
      d="M18.992 15.2826C19.5416 15.2826 19.9996 14.8398 19.9996 14.2749V13.6795C19.9996 13.1299 19.5569 12.6719 18.992 12.6719C18.4271 12.6719 17.9844 13.1146 17.9844 13.6795V14.2749C17.9996 14.8398 18.4424 15.2826 18.992 15.2826Z"
      fill="#333333"
    />
    <path
      d="M10.992 15.2826C11.5416 15.2826 11.9996 14.8398 11.9996 14.2749V13.6795C11.9996 13.1299 11.5569 12.6719 10.992 12.6719C10.4271 12.6719 9.98438 13.1146 9.98438 13.6795V14.2749C9.99964 14.8398 10.4424 15.2826 10.992 15.2826Z"
      fill="#333333"
    />
    <path
      d="M20.4429 21.0076C19.9696 20.7328 19.3589 20.9008 19.0841 21.3741C18.8703 21.7557 18.4581 22 18.0001 22C17.1146 22 16.3665 21.4199 16.107 20.626C16.2597 20.5038 16.4123 20.3817 16.5345 20.2138L17.6184 18.6565C18.0765 18 18.1223 17.1603 17.7558 16.458C17.3894 15.7557 16.6719 15.313 15.878 15.313H14.1222C13.3284 15.313 12.6108 15.7557 12.2444 16.458C11.878 17.1603 11.939 18 12.3818 18.6565L13.4658 20.2138C13.5879 20.3817 13.7253 20.5191 13.8932 20.626C13.6337 21.4199 12.8856 22 11.9848 22C11.5421 22 11.1299 21.7557 10.9161 21.3741C10.6413 20.9008 10.0306 20.7328 9.55736 21.0076C9.08408 21.2825 8.91615 21.8931 9.19096 22.3817C9.72531 23.313 10.7177 23.9084 11.8016 23.9847L12.565 26.3054C12.7024 26.7176 13.0841 26.9924 13.5116 26.9924H16.5192C16.9467 26.9924 17.3284 26.7176 17.4658 26.3054L18.2291 23.9847C19.2978 23.9237 20.2902 23.313 20.8398 22.3817C21.0841 21.8931 20.9314 21.2825 20.4429 21.0076ZM14.0154 17.3588C14.0459 17.2977 14.107 17.2977 14.1222 17.2977H15.878C15.8932 17.2977 15.9543 17.2977 15.9849 17.3588C16.0154 17.4199 15.9848 17.4657 15.9696 17.4809L14.9925 18.8702L14.0154 17.4809C14.0001 17.4809 13.9696 17.4351 14.0154 17.3588ZM14.2138 24.9924L13.7406 23.5725C14.2139 23.3435 14.6413 23.0076 14.9772 22.6107C15.3284 23.0076 15.7406 23.3283 16.2139 23.5725L15.7406 24.9924H14.2138Z"
      fill="#333333"
    />
  </svg>
);
