import React, { Dispatch, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { plusIcon } from 'images/plusIcon';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { StatisticsApiService } from '../../../../../services/statistics.service';
import { useToken } from '../../../../../cookies';
import MessageHockPopUp from '../../../../../hooks/MessageHockPopUp';
import { getCreateUserCostumeError } from '../../../../../utils/errorGuard';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import styles from '../peopleStatisticsStyles.module.css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddStudent({
  isOpen,
  setOpenCreateUser,
  roles,
}: {
  isOpen?: boolean;
  setOpenCreateUser?: Dispatch<React.SetStateAction<boolean>>;
  roles?: string[];
}) {
  const [open, setOpen] = useState(false);
  const [role, setRole] = React.useState<'' | 'Admin' | 'User'>('');
  const [registerData, setRegisterData] = useState({
    fName: '',
    fNameError: ' ',
    email: '',
    emailError: ' ',
    major: '',
    majorError: ' ',
  });
  const [touchedFields, setTouchedFields] = useState({
    fName: false,
    email: false,
    major: false,
  });
  const { token } = useToken();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCreateUser && setOpenCreateUser(false);
  };

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const getLengthError = (length: number, charsLimit: number) => {
    if (length < charsLimit) {
      return `This field should contain at least ${charsLimit} ${charsLimit === 1 ? 'character' : 'characters'}`;
    } else return '';
  };

  const getEmailError = (email: string) => {
    if (getLengthError(email.length, 5)) {
      return getLengthError(email.length, 5);
    } else if (!validateEmail(email)) {
      return 'Invalid email';
    }
    return '';
  };

  const changeFirstName = (_fName: string) => {
    setRegisterData({ ...registerData, fName: _fName, fNameError: getLengthError(_fName.length, 4) });
    setTouchedFields({ ...touchedFields, fName: true });
  };

  const changeEmail = (email: string) => {
    setRegisterData({ ...registerData, email: email, emailError: getEmailError(email) });
    setTouchedFields({ ...touchedFields, email: true });
  };

  const changeMajor = (_major: string) => {
    setRegisterData({ ...registerData, major: _major, majorError: getLengthError(_major.length, 2) });
    setTouchedFields({ ...touchedFields, major: true });
  };

  const registerUser = () => {
    const { fName, email, major } = registerData;
    const baseUrl = window.location.origin || 'https://vext.co';
    StatisticsApiService.createUser({
      token,
      recoverUrl: baseUrl + '/recover/',
      major,
      email,
      username: fName,
      is_staff: role === 'Admin',
    }).then((resp) => {
      if (!resp.ok) {
        MessageHockPopUp({
          message: getCreateUserCostumeError(resp.status || 0),
          timeOut: 5000,
          type: 'error',
        });
      } else {
        StatisticsApiService.recoverUser({ email, recoverUrl: baseUrl + '/recover/' }).then((response) => {
          if (response.ok) {
            MessageHockPopUp({
              message: 'Email sent',
              timeOut: 5000,
              type: 'success',
            });
          }
        });
      }
    });
    setOpen(false);
    setOpenCreateUser && setOpenCreateUser(false);
  };

  return (
    <div>
      {!setOpenCreateUser && (
        <div className={styles.addStudentBtn} onClick={handleClickOpen}>
          {plusIcon('#757575')} Add student
        </div>
      )}
      <Dialog
        open={open || !!isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Create a new user!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            To create a new user, please fill all needed information about it
          </DialogContentText>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 2, width: '56ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="f-name"
              label="Username"
              size="small"
              value={registerData.fName}
              onChange={(e) => {
                changeFirstName(e.target.value);
              }}
              variant="outlined"
              error={touchedFields.fName && !!registerData.fNameError}
              helperText={touchedFields.fName && registerData.fNameError}
            />

            <TextField
              id="email"
              value={registerData.email}
              onChange={(e) => {
                changeEmail(e.target.value);
              }}
              error={touchedFields.email && !!registerData.emailError}
              helperText={touchedFields.email && registerData.emailError}
              label="Email"
              size="small"
              variant="outlined"
            />
            <TextField
              value={registerData.major}
              onChange={(e) => {
                changeMajor(e.target.value);
              }}
              error={touchedFields.major && !!registerData.majorError}
              helperText={touchedFields.major && registerData.majorError}
              id="major"
              label="Major/department/function"
              size="small"
              variant="outlined"
            />
            {setOpenCreateUser && (
              <FormControl fullWidth>
                <InputLabel size={'small'} id="demo-simple-select-label">
                  Role
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  label="Role"
                  onChange={(e) => setRole(e.target.value as any)}
                >
                  {roles?.length ? roles : ['Admin', 'User'].map((role) => <MenuItem value={role}>{role}</MenuItem>)}
                </Select>
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={!!registerData.fNameError || !!registerData.emailError || !!registerData.majorError}
            onClick={registerUser}
          >
            Register user
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddStudent;
