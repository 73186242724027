export const ERROR = {
  common: {
    something_went_wrong: 'Oops something went wrong, please try again',
  },
  updateUser: {
    update_failed: 'User update failed, check console',
  },
  auth: {
    refresh_token_expired: 'Your refresh Token expired',
  },
};