import React from 'react';
import styles from '../landingStyles.module.css';
import teamStory from '../../assets/landingPage/teamStory.png';
import user1 from '../../assets/landingPage/user1.png';
import user2 from '../../assets/landingPage/user2.png';
import user3 from '../../assets/landingPage/user3.png';

function About() {
  return (
    <div id="about" className={styles.aboutMain}>
      {/*<div className={styles.blockSubtitle}>About me</div>*/}
      {/*<div className={styles.underScoreLine} />*/}
      <p>
        The <b>VEXT beta</b> program has been launched on 5 campuses. <br />
        If you would like to join our beta program, please contact us at <b>hey@vext.co</b>. <br />
        In the months ahead we will be announcing several exciting partnerships that will build on VEXT's unique
        approach to supporting your mental health. <br /> We will be bringing crucial additional resources to you and
        your institutions.
      </p>
      <div className={styles.blockAlignedTitle}>Vision and Mission Statement:</div>
      <div style={{ marginLeft: 0 }} className={styles.underScoreLine} />
      <p className={styles.leftAlignParagraph}>
        Our vision is of a world where users do not pull themselves towards expensive, difficult to access mental health
        services. We envision a collection of communities where within VEXT users have free, frictionless, and
        identity-protected mental health support in several forms. This support will be pushed to users before and in
        moments of mental health duress. We envision a world where you can subtly and anonymously send signals that will
        allow folks - both friends and professional behavioral health experts - in your real life circles to take
        actions on. <br />
        This vision drives our mission to raise awareness and proactively work to reduce anxiety, isolation, depression,
        and suicidal ideation.
      </p>
      <p className={styles.leftAlignParagraph}>
        Let’s talk : <b>hey@vext.co</b>
      </p>

      {/*<div style={{margin:'5% 0 10% 0'}} className={styles.homeBlock}>*/}
      {/*  <div className={styles.aboutBlockLeft}>*/}
      {/*    <div className={styles.blockAlignedTitle}>Team Story</div>*/}
      {/*    <div style={{ marginLeft: 0 }} className={styles.underScoreLine} />*/}
      {/*    <p className={styles.leftAlignParagraph}>*/}
      {/*      VEXT was founded by a team of technology experts and mental health professionals who recognized the need for*/}
      {/*      a more proactive approach to mental health support in the workplace. They combined their expertise to create*/}
      {/*      an innovative peer-to-peer application that allows for anonymous interactions around mental health. The team*/}
      {/*      is dedicated to making a positive impact on mental health and wellness in the workplace through the use of*/}
      {/*      technology.*/}
      {/*    </p>*/}
      {/*    <div className={styles.landingButton}>Download CV</div>*/}
      {/*  </div>*/}
      {/*  <img className={styles.aboutBlockRight} src={teamStory} alt="" />*/}
      {/*</div>*/}
      {/*<div className={styles.blockSubtitle}>What My Clients Say</div>*/}
      {/*<div className={styles.underScoreLine} />*/}
      {/*<div className={styles.inspirationCardsWrapper}>*/}
      {/*  <div className={styles.inspirationCard}>*/}
      {/*    <img className={styles.inspirationCardImage} src={user1} alt="user" />*/}
      {/*    <p className={styles.blockParagraph}>*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis,*/}
      {/*      pulvinar dapibus leo.*/}
      {/*    </p>*/}
      {/*    <div style={{ marginTop: '8em' }} className={styles.underScoreLine} />*/}
      {/*    <p className={styles.blockParagraph}>John Doe</p>*/}
      {/*  </div>*/}
      {/*  <div className={styles.inspirationCard}>*/}
      {/*    <img className={styles.inspirationCardImage} src={user2} alt="user" />*/}
      {/*    <p className={styles.blockParagraph}>*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis,*/}
      {/*      pulvinar dapibus leo.*/}
      {/*    </p>*/}
      {/*    <div style={{ marginTop: '8em' }} className={styles.underScoreLine} />*/}

      {/*    <p className={styles.blockParagraph}>Jenna Smith </p>*/}
      {/*  </div>*/}
      {/*  <div className={styles.inspirationCard}>*/}
      {/*    <img className={styles.inspirationCardImage} src={user3} alt="user" />*/}
      {/*    <p className={styles.blockParagraph}>*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis,*/}
      {/*      pulvinar dapibus leo.*/}
      {/*    </p>*/}
      {/*    <div style={{ marginTop: '8em' }} className={styles.underScoreLine} />*/}
      {/*    <p className={styles.blockParagraph}>Samuel Stevens</p>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}

export default About;