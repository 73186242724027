import AlertIcon from 'images/report/alert';
import EmergencyIcon from 'images/report/emergency';
import MegafonIcon from 'images/report/megafon';

export const LOGIN_ROUTE = '/login';
export const HOME_ROUTE = '/feed';
export const USER_PROFILE_ROUTE = '/user/:id';
export const SETTINGS_ROUTE = '/settings';
export const EDIT_CREATE_ARTICLE_ROUTE = '/create';
export const VIEW_ARTICLE_ROUTE = '/article:id';
export const SEARCH_PAGE_ROUTE = '/search';
export const EDIT_PAPER_ROUTE = '/edit-paper:id';
export const SHARED_QUESTION_ROUTE = '/question/:id';
export const GROUP_ROUTE = '/group/:id';
export const CREATE_GROUP_MOBILE = '/create-group-m';
export const MOBILE_NOTIFICATIONS = '/mobile-notifications';
export const MOBILE_EXPLORE = '/mobile-explore';
export const MOBILE_EMERGENCY_PAGE = '/mobile-emergency';
export const MOBILE_CHAT_PAGE = '/mobile-chat';
export const MOBILE_INVITE_FRIEND = '/invite-friend';
export const MOBILE_QUESTION = '/mobile/question/:id';
export const CONTACT = '/contact';
export const ADMIN_STATISTICS = '/admin';
export const ADMIN_AUTH = '/admin/auth';
export const RESET_ACCOUNT = '/recover/:id';
export const PARTNERS = '/partners';
export const HEALTH = '/health';

export const REPORT_OPTIONS = [
    { label: 'Offensive content', icon: AlertIcon },
    { label: 'Triggering content', icon: EmergencyIcon },
    { label: 'A friend needs help', icon: MegafonIcon },
];

//!! Do not remove, this const is used to identify chats created from reports
export const REPORT_CHAT='report_chat'