import { useDispatch, useSelector } from 'react-redux';
import { css } from 'styled-components/macro';
import { MyProfile } from 'images/users/MyProfile';
import { StarIcon } from 'images/Star';
import { clearArticlesState } from '../../store/articles';
import { selectUserId } from '../../store/auth';
import { clearUserState, getUser } from '../../store/user';
import { selectLoggedUserData, selectSettingsError } from '../../store/user/selectors';
import { useEffect } from 'react';
import { useToken } from '../../cookies';
import MessageHockPopUp from 'src/hooks/MessageHockPopUp';
import { NO_USER_AVATAR } from '../../utils/constantImagesUrl';
import { userNameFromAllParams } from '../../helpers/userName';
import style from './UserStyles.module.css';

export default function UserInfo(props: {
  firstName?: string;
  lastName?: string;
  nickname?: string | null;
  picture?: string;
  size?: string;
  graduation?: string;
  isMyPage: boolean;
}) {
  const dispatch = useDispatch();
  const userData = useSelector(selectLoggedUserData);
  const userId = useSelector(selectUserId);
  const userError = useSelector(selectSettingsError);
  const { token, removeToken } = useToken();

  useEffect(() => {
    if (token?.length && !userData.id && userId) {
      dispatch(getUser({ token, userId }));
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (userError) {
      MessageHockPopUp({ message: userError, timeOut: 4000, type: 'warning', size: 'small' });
      dispatch(clearUserState());
      dispatch(clearArticlesState());
      removeToken();
    }
    // eslint-disable-next-line
  }, [userError]);

  return (
    <>
      <div className={style.userInfoMain}>
        <div className={style.userInfoImgBlock}>
          <img
            className={style.userInfoImage}
            src={props.picture || userData.profile?.image_url || NO_USER_AVATAR}
            alt="User Avatar"
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-between;
            margin-left: 20px;
            ${props.size ? `height: ${props.size}` : `height:90px`}
          `}
        >
          <div className={style.userInfoCredentialsBlock}>
            <div className={style.userInfoName}>
              {userNameFromAllParams(
                props?.nickname, //username
                props.firstName,
                props.lastName,
              )}
            </div>
          </div>
          <div className={style.userInfoStudies}>
            {props.graduation || !props.isMyPage
              ? props.graduation || ''
              : `${userData.profile?.graduation} ${userData.profile?.institute}`}{' '}
            {}
          </div>
          <div className={style.userInfoBottomBlock}>
            <div className={style.userInfoPointsBlock}>
              {StarIcon()}
              <span className={style.userInfoPointsText}>0 points</span>
            </div>
            <div className={style.subscribersBlock}>
              {MyProfile('#757575')}
              <span style={{ marginLeft: '5px' }}>0 subscribers</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
