import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { blackWhiteLogo } from 'images/logo/blackWhiteLogo';
import TrashcanIcon from 'images/trashbinIcon';
import { editIcon } from 'images/editIcon';
import { getTimeRead } from '../../../helpers/articleInfo';
import { Paper, PaperType } from '../../../types/Paper';
import { clearSelectedPaper } from '../../../store/articles';
import { useState } from 'react';
import DeleteArticleModal from './DeleteArticleModal';
import { ArticleAvatar } from '../../layout/ImagesTemplate';
import styles from './UserComponents.module.css';

export default function MyArticlesTab({ articles }: { articles: Array<Paper> }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletedId, setDeletedId] = useState('');
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false,
  };
  return (
      <div className={styles.main}>
        {articles.map((article) => {
          return (
              <div key={article.id} className={styles.myArticle}>
                {article.type === 'QUESTION' ? (
                    blackWhiteLogo()
                ) : (
                    <div
                        style={{ width: '250px', cursor: 'pointer' }}
                        onClick={() => {
                          if (article.type === PaperType.ARTICLE) {
                            dispatch(clearSelectedPaper());
                            history.push(`/article${article.id}`);
                          }
                        }}
                    >
                      <ArticleAvatar url={article.image} width={100} height={180} />
                    </div>
                )}

                <div
                    className={styles.myArticleInfo}
                    onClick={() => {
                      if (article.type === PaperType.ARTICLE) {
                        dispatch(clearSelectedPaper());
                        history.push(`/article${article.id}`);
                      }
                    }}
                >
                  <div className={styles.title}>
                    <span>{article.title}</span>
                  </div>
                  <div className={styles.description}>{article.description}</div>
                  <div className={styles.myArticleInfoAbout}>
                    <span>{new Intl.DateTimeFormat('us-US', options).format(new Date(article.created))}</span>
                    <span className={styles.dot}>&#8226;</span>
                    <span>{article.views} views</span>
                    <span className={styles.dot}>&#8226;</span>
                    <span>{getTimeRead(article.duration)}</span>
                  </div>
                  {!!article.paper_tags?.length && (
                      <div className={styles.tags}>
                        {article.paper_tags.map((tag) => (
                            <div key={tag.id} className={styles.tag}>
                              {tag.name}
                            </div>
                        ))}
                      </div>
                  )}
                </div>
                <div className={styles.myArticleOptions}>
              <span
                  className={styles.option}
                  onClick={() => {
                    setDeletedId(article.id);
                    setIsModalOpen(true);
                  }}
              >
                <TrashcanIcon />
                <span className={styles.optionText}> Delete </span>
              </span>
                  {article.type !== 'QUESTION' && (
                      <span
                          className={styles.option}
                          onClick={() =>
                              article.type === PaperType.ARTICLE &&
                              history.push({ pathname: `edit-paper${article.id}`, state: article.id })
                          }
                      >
                  {editIcon()} <span className={styles.optionText}> Edit </span>
                </span>
                  )}
                </div>
              </div>
          );
        })}
        <DeleteArticleModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} id={deletedId} />
      </div>
  );
}