import React from 'react';
import { css } from 'styled-components/macro';
import { theme } from '../../themes/variables';
import { Loader } from './Loader';

export function Button(props: {
  type?: 'button' | 'submit';
  children?: React.ReactNode;
  title?: string;
  name?: string;
  onClick?: () => void;
  className?: string;
  hoverStyles?: string;
  activeStyles?: string;
  disableStyles?: string;
  color?: string;
  background?: string;
  border?: string;
  small?: boolean;
  flat?: boolean;
  icon?: React.ReactNode;
  iconRight?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  radius?: number;
}) {
  return (
    <button
      type={props.type || 'button'}
      name={props.name}
      title={props.title}
      css={css`
        padding: ${props.flat ? '0' : props.small ? '6px 20px' : '10px 20px'};
        border-radius: ${props.radius ? `${props.radius}px` : '16px'};
        border: ${props.border ? props.border : 'none'};
        font-weight: bold;
        cursor: pointer;
        min-width: 7em;
        transition: 0.2s;
        /* :hover {
          ${props.hoverStyles
          ? props.hoverStyles
          : css`
              background: ${theme.colors.blue_5};
            `}
        }
    
        :active {
          ${props.activeStyles}
        }
        :disabled {
          ${props.disableStyles
          ? props.disableStyles
          : css`
              cursor: default;
              background: ${theme.colors.blue_5};
            `}
        } */
      `}
      disabled={props.disabled || props.isSubmitting}
      className={props.className}
      onClick={props.onClick}
    >
      {props.isSubmitting ? (
        <Loader size={15} />
      ) : (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {props.icon && !props.iconRight && (
            <span
              css={css`
                display: flex;
                ${props.children &&
                css`
                  margin-right: 10px;
                `}
              `}
            >
              {props.icon}
            </span>
          )}

          <span
            css={css`
              display: flex;
              vertical-align: middle;
            `}
          >
            {props.children}
          </span>

          {props.icon && props.iconRight && (
            <span
              css={css`
                display: flex;
                ${props.children &&
                css`
                  margin-left: 10px;
                `}
              `}
            >
              {props.icon}
            </span>
          )}
        </div>
      )}
    </button>
  );
}
