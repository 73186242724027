import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChartLines from './components/ChartLines';
import PeriodMenu from './components/PeriodMenu';
import styles from './dashboardStyles.module.css';

import { useToken } from '../../../cookies';
import { thisMonthFromToDate, thisWeekFromToDateISO, todayFromToDate } from '../../../helpers/date';
import { getDashboardEmergencies } from '../../../store/dashboard/reducer';
import {
  selectDashboardEmergencies,
  selectDashboardEmergenciesAmount,
  selectDashboardEmergenciesMaxPerHour,
  selectDashboardEmergenciesPercent,
} from '../../../store/dashboard/selector';
import { GraphPeriods } from '../../../utils/enums';
import { useIsMobile, useIsTablet } from '../../../utils/styles.utils';


function Emergencies() {
  const [selectedPeriod, setSelectedPeriod] = useState<GraphPeriods>(GraphPeriods.LAST_24_HOURS);
  const [periodUnit, setPeriodUnit] = useState<'day' | 'week' | 'month'>('day');
  const dispatch = useDispatch();
  const { token } = useToken();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSmallDevice = isMobile || isTablet;
  const emergenciesAmount = useSelector(selectDashboardEmergenciesAmount);
  const emergencies = useSelector(selectDashboardEmergencies);
  const maxEmergenciesPerHour = useSelector(selectDashboardEmergenciesMaxPerHour);
  const emergenciesPercent = useSelector(selectDashboardEmergenciesPercent);

  useEffect(() => {
    switch (selectedPeriod) {
      case GraphPeriods.LAST_24_HOURS: {
        setPeriodUnit('day');
        dispatch(
          getDashboardEmergencies({
            token,
            periodType: 'day',
            from: todayFromToDate().from,
            to: todayFromToDate().to,
            isOutliers: false,
          }),
        );
        return;
      }
      case GraphPeriods.LAST_WEEK: {
        setPeriodUnit('week');
        dispatch(
          getDashboardEmergencies({
            token,
            periodType: 'week',
            from: thisWeekFromToDateISO().from,
            to: thisWeekFromToDateISO().to,
            isOutliers: false,
          }),
        );
        return;
      }
      case GraphPeriods.LAST_MONTH: {
        setPeriodUnit('month');
        dispatch(
          getDashboardEmergencies({
            token,
            periodType: 'month',
            from: thisMonthFromToDate().from,
            to: thisMonthFromToDate().to,
            isOutliers: false,
          }),
        );
        return;
      }
    }
  }, [selectedPeriod]);

  return (
    <div
      className={styles.topBlock}
      style={{ width: isSmallDevice ? '100%' : '30%', height: isSmallDevice ? '28vh' : undefined }}
    >
      <div className={isSmallDevice ? styles.mobileTopBlockContent : styles.topBlockContent}>
        <div className={styles.topBlockTitle}>Emergencies</div>
        <div style={{ color: '#FF3A29' }} className={styles.percent}>
          {emergenciesPercent > 0 ? `+${emergenciesPercent.toFixed(0)}%` : `${emergenciesPercent.toFixed(0)}%`}
        </div>
        <div className={styles.infoIcon}>i</div>
        <PeriodMenu selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} />
      </div>

      <div className={styles.topBlockContent}>
        <div className={styles.quantity}>{emergenciesAmount}</div>
        <div>
          {!!emergencies.length && (
            <ChartLines
              color={'#FF3A29'}
              tick={periodUnit}
              data={emergencies}
              periodLength={emergencies.length}
              yMax={maxEmergenciesPerHour}
              labelType={'Emergencies'}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Emergencies;