import { ReactComponent as SadSvg } from 'emoji/sad.svg';
import { ReactComponent as Frustrated } from 'emoji/frustrated.svg';
import { ReactComponent as Angry } from 'emoji/angry.svg';
import { ReactComponent as Tired } from 'emoji/tiered.svg';
import { ReactComponent as Curious } from 'emoji/courious.svg';
import { ReactComponent as Confused } from 'emoji/confused.svg';
import { ReactComponent as Excited } from 'emoji/excited.svg';
import { ReactComponent as Confident } from 'emoji/Confident.svg';
import { ReactComponent as Happy } from 'emoji/Happy.svg';
import React from 'react';

export const mood = [
  { img: <SadSvg />, label: 'Sad' },
  { img: <Frustrated />, label: 'Frustrated' },
  { img: <Angry />, label: 'Angry' },
  { img: <Tired />, label: 'Tired' },
  { img: <Curious />, label: 'Curious' },
  { img: <Confused />, label: 'Confused' },
  { img: <Excited />, label: 'Excited' },
  { img: <Confident />, label: 'Confident' },
  { img: <Happy />, label: 'Happy' },
];

export const getMoodIconByName = (name: string) => {
  switch (name) {
    case 'Sad':
      return <SadSvg />;
    case 'Frustrated':
      return <Frustrated />;
    case 'Angry':
      return <Angry />;
    case 'Tired':
      return <Tired />;
    case 'Curious':
      return <Curious />;
    case 'Confused':
      return <Confused />;
    case 'Excited':
      return <Excited />;
    case 'Confident':
      return <Confident />;
    case 'Happy':
      return <Happy />;
  }
};

export const getMoodNumberByName = (name: string) => {
  switch (name.toLowerCase()) {
    case 'happy':
      return 8;
    case 'confident':
      return 7;
    case 'excited':
      return 6;
    case 'confused':
      return 5;
    case 'curious':
      return 4;
    case 'tired':
      return 3;
    case 'angry':
      return 2;
    case 'frustrated':
      return 1;
    case 'sad':
      return 0;
    default:
      return 8;
  }
};

export const getMoodText = (moodNb: number) => {
  switch (moodNb) {
    case 0:
      return 'Sad';
    case 1:
      return 'Frustrated';
    case 2:
      return 'Angry';
    case 3:
      return 'Tired';
    case 4:
      return 'Curious';
    case 5:
      return 'Confused';
    case 6:
      return 'Excited';
    case 7:
      return 'Confident';
    case 8:
      return 'Happy';
    default:
      return 'No moods';
  }
};
