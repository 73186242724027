export enum AccordionVariants {
  MOODS = 'Moods',
  TAGS = 'Tags',
}

export enum TabsVariants {
  STATISTICS = 'Statistics',
  LOOPS = 'Loops',
  EMERGENCIES = 'Emergencies',
  CHATS = 'Chats',
  MY_GROUPS = 'My groups',
  DASHBOARD = 'dashboard',
}

export enum PeopleOrGroupsSwitch {
  PEOPLE = 'people',
  GROUPS = 'groups',
}

export enum SortByMoods {
  GOOD_MOODS_FIRST = 'asc',
  BAD_MOODS_FIRST = 'desc',
}

export enum ExpandedGroupTabs {
  MOODS = 'Moods',
  DETAILS = 'Details',
  OUTLIER_AND_EMERGENCY_MEMBERS = 'Outlier and emergency members ',
}

export enum ChartType {
  AVERAGE = 'Average',
  DETAILED = 'Detailed',
}

export enum EmergencyReasons {
  FEELING_SAD = 'feeling_sad',
  SOMETHING_HAPPENED = 'something_happened',
  CONNECT_A_COUNSELOR = 'connect_a_counselor',
  OTHER = 'other',
}

export enum EmergencyTabs {
  ACTION_HISTORY = 'action_history',
  STUDENT_AND_GROUPS = 'student_and_groups',
}

export enum GraphPeriods {
  LAST_24_HOURS = 'Last 24 hours',
  LAST_WEEK = 'Last week',
  LAST_MONTH = 'Last month',
}

export enum UsersRolesTab {
  USERS = 'Users',
  ROLES = 'Roles',
}