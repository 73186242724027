import { ApiResponse } from 'apisauce';
import { User } from '../types/User';
import { _RestApiService } from './api.service';

const usersApi = new _RestApiService(window._env_.REACT_APP_BACKEND_URL || '');

export class _UsersApiService {
  getUser(token: string, userId: string): Promise<ApiResponse<User>> {
    return usersApi.get('/api/users/' + userId + '/', {}, usersApi.getAuthenticateHeader(token));
  }

  getUsers(token: string,forAdmin?:boolean): Promise<ApiResponse<User[]>> {
    return usersApi.get('/api/users/', {for_admin:forAdmin}, usersApi.getAuthenticateHeader(token));
  }

  updateUser(token: string, updatedData: Partial<User>): Promise<ApiResponse<User>> {
    const { profile } = updatedData;
    return usersApi.patch(
      `/api/users/${updatedData.id}/`,
      {
        username: updatedData.username,
        profile: profile,
      },
      usersApi.getAuthenticateHeader(token),
    );
  }

  deleteUser(token: string, userId: string): Promise<ApiResponse<any>> {
    return usersApi.delete(`/api/users/${userId}/`, {}, usersApi.getAuthenticateHeader(token));
  }
}

export const UsersApiService = new _UsersApiService();
