import React from 'react';
import styles from '../dashboardStyles.module.css';
import { admin } from 'images/userRoles/admin';
import { counselor } from 'images/userRoles/counselor';
import { peer } from 'images/userRoles/peer';
import { dots3Icon } from 'images/dots3Icon';

const getUserRoleIcon = (role: string) => {
  switch (role) {
    case 'Administrator':
      return admin();
    case 'Counselor':
      return counselor();
    default:
      return peer();
  }
};

function RoleElement({ userRole, quantity }: { userRole: string; quantity: number }) {
  return (
    <div className={styles.userRoleElement}>
      <div className={styles.roleAndIcon}>
        <div className={styles.icon}>{getUserRoleIcon(userRole)}</div>
        <div>
          <div className={styles.roleLabel}>Role:</div>
          <div className={styles.roleName}>{userRole}</div>
        </div>
      </div>
      <div className={styles.quantityAndOptions}>
        <div>
          <div className={styles.roleLabel}>Users:</div>
          <div className={styles.roleName}>{quantity}</div>
        </div>
        <div style={{ cursor: 'pointer' }}>{dots3Icon('#757575', '28', '24')}</div>
      </div>
    </div>
  );
}

export default RoleElement;