import React, { useEffect, useState } from 'react';

import EmergencyBlock from './components/EmergencyBlock';
import styles from './EmergenciesStyles.module.css';

import { useToken } from '../../../cookies';
import MessageHockPopUp from '../../../hooks/MessageHockPopUp';
import { EmergencyService } from '../../../services/emergencies.service';
import { EmergencyObj } from '../../../types/Emergencies';


function Emergencies() {
  const [emergencies, setEmergencies] = useState<Array<EmergencyObj>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useToken();

  useEffect(() => {
    setIsLoading(true);
    EmergencyService.getEmergenciesList(token)
      .then((emergencies) => {
        setEmergencies(emergencies.data.results);
      })
      .catch(() => {
        MessageHockPopUp({
          message: 'Get emergencies error, check console',
          timeOut: 3000,
          type: 'error',
          size: 'small',
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className={styles.emergencyMain}>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        emergencies.map((emBlock) => {
          return <EmergencyBlock request={emBlock} />;
        })
      )}
    </div>
  );
}

export default Emergencies;
