import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChatRowItem from './ChatRowItem';

import { selectUserId } from '../../../../store/auth';
import { clearMessages, setOpenedChat } from '../../../../store/chats';
import { selectOpenedChat } from '../../../../store/chats/selector';
import { ChatListPreview } from '../../../../types/User';

function ChatList({ chatItems }: { chatItems: Array<ChatListPreview> }) {
  const dispatch = useDispatch();
  const myUserId = useSelector(selectUserId);
  const openedChat = useSelector(selectOpenedChat) as ChatListPreview;

  return (
    <>
      {!!chatItems.length &&
        chatItems.map((chat) => {
          {
            if (myUserId !== chat.author && !chat.last_message) return null;
          }
          return (
            <div
              key={chat.id}
              onClick={() => {
                if (!openedChat || openedChat.id !== chat.id) {
                  dispatch(setOpenedChat(chat));
                  dispatch(clearMessages());
                }
              }}
            >
              <ChatRowItem key={chat.id} chat={chat} />
            </div>
          );
        })}
    </>
  );
}

export default ChatList;
