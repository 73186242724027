export const copyIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="8.53351"
      y="6.90851"
      width="10.0482"
      height="13.2213"
      rx="1.32213"
      stroke="#757575"
      strokeWidth="1.58655"
    />
    <path
      d="M5.625 16.6924V6.1154C5.625 4.9471 6.5721 4 7.7404 4H15.1443"
      stroke="#757575"
      strokeWidth="1.58655"
      strokeLinecap="round"
    />
  </svg>
);
