import { combineReducers } from '@reduxjs/toolkit';
import articles from './articles/reducer';
import auth from './auth/reducer';
import users from './user/reducer';
import notifications from './notifications/reducer';
import timer from './timer/reducer';
import groups from './groups/reducer';
import tags from './tags/reducer';
import moods from './moods/reducer';
import navigation from './navigationStates/reducer';
import statistics from './statistics/reducer';
import chats from './chats/reducer';
import search from './globalSearch/reducer';
import dashboard from './dashboard/reducer';
import health from './health/reducer'

export const rootReducer = combineReducers({
  articles,
  auth,
  users,
  notifications,
  timer,
  groups,
  tags,
  moods,
  navigation,
  statistics,
  chats,
  search,
  dashboard,
  health
});

export type RootState = ReturnType<typeof rootReducer>;
