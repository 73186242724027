import React, { useEffect, useState } from 'react';
import ArrowBackButton from '../../components/mobile/Buttons/ArrowBackButton';
import { PeopleOrGroupsSwitch } from '../../utils/enums';
import MobilePeopleChat from '../../components/MobileChats/MobilePeopleChat';
import MobileGroupsChat from '../../components/MobileChats/MobileGroupsChat';
import MobileChatPage from '../../components/MobileChats/MobileChatPage';
import useChatsSocket from '../../hooks/useChatsSocket';
import { useLocation } from 'react-router-dom';
import MobileLoaderScreen from '../../components/layout/MobileLoaderScreen';
import { useToken } from '../../cookies';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../../store/auth';
import {
  selectChatsAreLoading,
  selectGroupsChats,
  selectOpenedChat,
  selectPeopleChats,
} from '../../store/chats/selector';
import {
  getGroupsConversations,
  getPeopleConversations,
  initiateNewConversation,
  setOpenedChat,
} from '../../store/chats';
import { getSortedChats } from '../../helpers/arrays';
import styles from './ChatMobileStyle.module.css';

function ChatMobile() {
  const [selectedTab, setSelectedTab] = useState(PeopleOrGroupsSwitch.PEOPLE);
  const location = useLocation() as any;
  const dispatch = useDispatch();
  const { token } = useToken();
  const { sendMessage } = useChatsSocket();

  const openedChat = useSelector(selectOpenedChat);
  const peopleChats = useSelector(selectPeopleChats);
  const groupChats = useSelector(selectGroupsChats);
  const isLoading = useSelector(selectChatsAreLoading);
  const myUserId = useSelector(selectUserId);

  useEffect(() => {
    //initialise a new conversation when were redirected from friend profile page
    if (location.state?.user?.id) {
      dispatch(initiateNewConversation({ token, userIds: [location.state.user.id] }));
    } else {
      dispatch(getPeopleConversations({ token, page: 1 }));
      dispatch(getGroupsConversations({ token, page: 1 }));
    }
  }, [location.state, token]);

  // PEOPLE
  const sortedPeopleChats = getSortedChats(peopleChats);
  const hiddenChats = sortedPeopleChats.filter((chat) => chat.author !== myUserId && !chat.last_message).length;
  const unseenChats = sortedPeopleChats.filter((chat) => chat.has_unseen_message).length - hiddenChats;

  // GROUPS
  const sortedGroupsChats = getSortedChats(groupChats);
  const unseenChatsGroups = sortedGroupsChats.filter((chat) => chat.has_unseen_message).length;
  return (
    <div className={styles.mainChatMobile}>
      {!openedChat && (
        <div className={styles.topMobChats}>
          <div className={styles.mobChatHeader}>
            {openedChat ? (
              <ArrowBackButton setStep={() => dispatch(setOpenedChat(null))} />
            ) : (
              <ArrowBackButton pushUrl={'/feed'} />
            )}
          </div>
          <div className={styles.peopleGroupsSwitch}>
            <div
              onClick={() => setSelectedTab(PeopleOrGroupsSwitch.PEOPLE)}
              className={`${styles.tab} ${selectedTab === PeopleOrGroupsSwitch.PEOPLE && styles.selectedTab}`}
            >
              People <span className={styles.notificationNumber}>{unseenChats}</span>
            </div>
            <div
              onClick={() => setSelectedTab(PeopleOrGroupsSwitch.GROUPS)}
              className={`${styles.tab} ${selectedTab === PeopleOrGroupsSwitch.GROUPS && styles.selectedTab}`}
            >
              Groups <span className={styles.notificationNumber}>{unseenChatsGroups}</span>
            </div>
          </div>
        </div>
      )}
      {
        <>
          {openedChat ? (
            <div className={styles.selectedTabBlock}>
              <MobileChatPage sendMessage={sendMessage} />
            </div>
          ) : isLoading ? (
            <MobileLoaderScreen />
          ) : (
            <div className={styles.selectedTabBlock}>
              {selectedTab === PeopleOrGroupsSwitch.PEOPLE ? (
                <MobilePeopleChat chats={sortedPeopleChats} />
              ) : (
                <MobileGroupsChat chats={sortedGroupsChats} />
              )}
            </div>
          )}
        </>
      }
    </div>
  );
}

export default ChatMobile;
