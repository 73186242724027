import { requiredAsterix } from 'images/requiredAsterix';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dispatch, SetStateAction, useState } from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactComponent as FacebookIcon } from '../../../../assets/socialIcons/FacebookIcon.svg';
import { ReactComponent as TwitterIcon } from '../../../../assets/socialIcons/TwitterIcon.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/socialIcons/InstagramIcon.svg';
import { ReactComponent as SnapchatIcon } from '../../../../assets/socialIcons/SnapchatIcon.svg';
import { isLinkValid } from '../../../../helpers/socialMediaValidator';
import styles from './styles.module.css';

type AboutYouProps = {
  userName: string;
  birthDate?: string;
  phone?: string;
  facebook?: string;
  twitter?: string;
  instagram?: string;
  snapchat?: string;
  setUserName: Dispatch<SetStateAction<string>>;
  setUserBirthDate: Dispatch<SetStateAction<string | undefined>>;
  setUserPhone: Dispatch<SetStateAction<string | undefined>>;
  setUserFacebook: Dispatch<SetStateAction<string>>;
  setUserTwitter: Dispatch<SetStateAction<string>>;
  setUserInstagram: Dispatch<SetStateAction<string>>;
  setUserSnapChat: Dispatch<SetStateAction<string>>;
  setIsSocialMediaValid: Dispatch<SetStateAction<boolean>>;
};

export function AboutYouMobileSettings(props: AboutYouProps) {
  const {
    userName,
    phone,
    birthDate,
    facebook,
    twitter,
    instagram,
    snapchat,
    setUserName,
    setUserBirthDate,
    setUserPhone,
    setUserFacebook,
    setUserTwitter,
    setUserInstagram,
    setUserSnapChat,
    setIsSocialMediaValid,
  } = props;
  const [errors, setErrors] = useState({
    facebook: '',
    twitter: '',
    instagram: '',
    snapchat: '',
  });
  return (
    <div>
      <p className={styles.inputLabel}>User name {requiredAsterix()}</p>
      <input type="text" className={styles.inputField} value={userName} onChange={(e) => setUserName(e.target.value)} />

      <p className={styles.inputLabel}>Birth year</p>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          showToolbar={false}
          className={styles.datePicker}
          maxDate={String(new Date().getFullYear() - 16)}
          views={['year']}
          value={birthDate?.toString() || String(new Date().getFullYear() - 16)}
          onChange={(e: any) => setUserBirthDate(e.getUTCFullYear())}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
      </LocalizationProvider>
      <p className={styles.inputLabel}>Phone Number</p>
      <input type="number" className={styles.inputField} value={phone} onChange={(e) => setUserPhone(e.target.value)} />
      <p className={styles.inputLabel}>Social:</p>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={styles.socialInput}>
          <FacebookIcon style={{ margin: '0 11px 0 0' }} />
          <div className={styles.socialBlock}>
            <input
              type="text"
              className={styles.inputField}
              placeholder="Facebook"
              value={facebook}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  facebook: isLinkValid(String(e.target.value), 'https://www.facebook.com/'),
                });
                setIsSocialMediaValid(!isLinkValid(String(e.target.value), 'https://www.facebook.com/'));
                setUserFacebook(e.target.value);
              }}
            />
            {!!errors.facebook && <p className={styles.errorText}>{errors.facebook}</p>}
          </div>
        </div>
        <div className={styles.socialInput}>
          <TwitterIcon style={{ margin: '0 11px 0 0' }} />
          <div className={styles.socialBlock}>
            <input
              type="text"
              className={styles.inputField}
              placeholder="Twitter"
              value={twitter}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  twitter: isLinkValid(String(e.target.value), 'https://twitter.com/'),
                });
                setIsSocialMediaValid(!isLinkValid(String(e.target.value), 'https://twitter.com/'));
                setUserTwitter(e.target.value);
              }}
            />
            {!!errors.twitter && <p className={styles.errorText}>{errors.twitter}</p>}
          </div>
        </div>
        <div className={styles.socialInput}>
          <InstagramIcon style={{ margin: '0 11px 0 0' }} />
          <div className={styles.socialBlock}>
            <input
              type="text"
              className={styles.inputField}
              placeholder="Instagram"
              value={instagram}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  instagram: isLinkValid(String(e.target.value), 'https://www.instagram.com/'),
                });
                setIsSocialMediaValid(!isLinkValid(String(e.target.value), 'https://www.instagram.com/'));
                setUserInstagram(e.target.value);
              }}
            />
            {!!errors.instagram && <p className={styles.errorText}>{errors.instagram}</p>}
          </div>
        </div>
        <div className={styles.socialInput}>
          <SnapchatIcon style={{ margin: '0 11px 0 0' }} />
          <div className={styles.socialBlock}>
            <input
              type="text"
              className={styles.inputField}
              placeholder="Snapchat"
              value={snapchat}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  snapchat: isLinkValid(String(e.target.value), 'https://www.snapchat.com/'),
                });
                setIsSocialMediaValid(!isLinkValid(String(e.target.value), 'https://www.snapchat.com/'));
                setUserSnapChat(e.target.value);
              }}
            />
            {!!errors.snapchat && <p className={styles.errorText}>{errors.snapchat}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
