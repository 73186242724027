export const admin = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.1875 0.1875H35.8125V35.8125H0.1875V0.1875Z" fill="#8076F0" />
    <g clipPath="url(#clip0_3750_29589)">
      <path
        d="M21.5212 25.6875V24.0208C21.5212 23.1368 21.17 22.2889 20.5448 21.6638C19.9197 21.0387 19.0719 20.6875 18.1878 20.6875H13.1878C12.3038 20.6875 11.4559 21.0387 10.8308 21.6638C10.2057 22.2889 9.85449 23.1368 9.85449 24.0208V25.6875"
        stroke="#252525"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6878 17.3542C17.5288 17.3542 19.0212 15.8618 19.0212 14.0208C19.0212 12.1799 17.5288 10.6875 15.6878 10.6875C13.8469 10.6875 12.3545 12.1799 12.3545 14.0208C12.3545 15.8618 13.8469 17.3542 15.6878 17.3542Z"
        stroke="#252525"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0212 19.0208C24.9416 19.0208 25.6878 18.2746 25.6878 17.3542C25.6878 16.4337 24.9416 15.6875 24.0212 15.6875C23.1007 15.6875 22.3545 16.4337 22.3545 17.3542C22.3545 18.2746 23.1007 19.0208 24.0212 19.0208Z"
        stroke="#252525"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0212 14.8542V15.6875M24.0212 19.0209V19.8542M26.1878 16.1042L25.4628 16.5209M22.5795 18.1875L21.8545 18.6042M26.1878 18.6042L25.4628 18.1875M22.5795 16.5209L21.8545 16.1042"
        stroke="#252525"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3750_29589">
        <rect width="20" height="20" fill="white" transform="translate(8.1875 8.1875)" />
      </clipPath>
    </defs>
  </svg>
);
