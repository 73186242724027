import ArrowBackButton from '../../Buttons/ArrowBackButton';
import styles from './styles.module.css';

export function MobileSettingsHeader({ saveChanges, url }: { saveChanges: () => void; url?: string }) {
  return (
    <div className={styles.header}>
      <ArrowBackButton
        setStep={() => {
          window.history.back();
        }}
      />
      <div style={{ color: '#FFC700' }} onClick={saveChanges}>
        Save
      </div>
    </div>
  );
}
