export const chatIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9421 22.0968L17.9421 22.0967L17.9356 22.0929L13.465 19.4407L13.5694 19.2924L18.023 21.9378C18.2097 22.0532 18.4235 22.1095 18.6435 22.0906C18.848 22.0731 19.006 21.9953 19.1086 21.9287C19.3353 21.7845 19.6411 21.4436 19.5704 20.9208L19.1363 17.4056C19.1324 17.3585 19.1525 17.3229 19.1759 17.3046C21.6383 15.5756 23.0738 12.9203 23.0738 10.0176C23.0738 4.93847 18.6388 0.902836 13.296 0.902836C8.16628 0.902836 3.87201 4.63099 3.53351 9.42958L3.35879 10.5123C3.34823 10.3546 3.34229 10.198 3.34229 10.04C3.34229 4.95967 7.76466 0.75 13.296 0.75C18.8274 0.75 23.2498 4.95967 23.2498 10.04C23.2498 12.8354 21.9577 15.4115 19.654 17.1851C19.4434 17.3472 19.3344 17.6087 19.3674 17.8724L19.7466 20.9048C19.7467 20.9056 19.7468 20.9064 19.7469 20.9073C19.802 21.3831 19.5964 21.8177 19.202 22.0744C19.006 22.2 18.7778 22.2671 18.5585 22.2671C18.3425 22.2671 18.1318 22.2119 17.9421 22.0968ZM3.29299 10.9201L3.20379 11.4729L3.20855 11.4736L3.29299 10.9201Z"
      fill="#D9D9D9"
      stroke="#EDEFF0"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M3.29222 23.1261L3.28879 23.1239C3.02411 22.9534 2.89098 22.6726 2.92342 22.3698L3.14596 20.6569C3.17942 20.3993 3.07723 20.1428 2.87584 19.9788C1.53464 18.8864 0.75 17.2761 0.75 15.6017C0.75 13.6915 1.74912 11.8924 3.45325 10.8066L3.45327 10.8066L3.45907 10.8029C4.48549 10.1355 5.70601 9.77383 6.98314 9.77383C10.4664 9.77383 13.2163 12.4278 13.2163 15.6017C13.2163 16.8946 12.7454 18.1743 11.8766 19.1907L11.8765 19.1907L11.8683 19.2007C10.7516 20.5539 9.07067 21.3583 7.19361 21.4189C7.06706 21.4229 6.94361 21.459 6.83476 21.5237L4.11014 23.1421C4.10994 23.1422 4.10974 23.1423 4.10953 23.1424C3.98552 23.2155 3.85047 23.25 3.70945 23.25C3.56011 23.25 3.42382 23.2119 3.29222 23.1261ZM4.26 22.8357L6.92697 21.2519C6.95516 21.2357 6.97111 21.2326 6.9741 21.2321C8.82131 21.2315 10.5907 20.4419 11.7265 19.0642C12.5694 18.0668 13.0292 16.8713 13.0292 15.5905C13.0292 12.429 10.2554 9.94903 6.97197 9.94903C5.74103 9.94903 4.54724 10.2878 3.53573 10.9522C1.9074 11.9966 0.914786 13.7222 0.914786 15.6017C0.914786 17.4101 1.80365 19.0548 3.33841 20.1049C3.3529 20.1153 3.36258 20.1277 3.36888 20.1417C3.37507 20.1555 3.37925 20.1738 3.37754 20.1966L3.13318 22.0951C3.09656 22.3796 3.22541 22.66 3.46511 22.8176C3.70481 22.9751 4.01337 22.9822 4.26 22.8357Z"
      stroke="#EDEFF0"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path d="M9 7.5H18" stroke="#EDEFF0" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M12 12L18 12" stroke="#EDEFF0" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M4.5 14.25H9" stroke="#EDEFF0" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 17.25H9" stroke="#EDEFF0" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
