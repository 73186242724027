export const NotificationIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3755 16.5763C20.5348 15.6778 18.9612 14.3264 18.9612 9.89996C18.9627 8.31147 18.4072 6.77212 17.3904 5.54676C16.3735 4.3214 14.9589 3.48664 13.3898 3.18612V2.28458C13.3898 2.10275 13.3538 1.9227 13.2838 1.75471C13.2138 1.58672 13.1112 1.43408 12.9818 1.30551C12.8525 1.17693 12.6989 1.07494 12.5299 1.00536C12.361 0.935778 12.1798 0.899963 11.9969 0.899963C11.814 0.899963 11.6329 0.935778 11.4639 1.00536C11.2949 1.07494 11.1414 1.17693 11.012 1.30551C10.8827 1.43408 10.7801 1.58672 10.7101 1.75471C10.6401 1.9227 10.6041 2.10275 10.6041 2.28458V3.18612C9.03499 3.48664 7.62033 4.3214 6.60347 5.54676C5.58662 6.77212 5.03117 8.31147 5.03267 9.89996C5.03267 14.3264 3.45906 15.6778 2.6184 16.5763C2.37861 16.8305 2.24668 17.1669 2.25006 17.5153C2.25023 17.6975 2.28651 17.8778 2.35683 18.046C2.42716 18.2142 2.53015 18.367 2.65991 18.4956C2.78968 18.6242 2.94367 18.7261 3.11309 18.7955C3.2825 18.8649 3.46402 18.9004 3.64725 18.9H20.3528C20.536 18.9004 20.7175 18.8649 20.887 18.7955C21.0564 18.7261 21.2104 18.6242 21.3401 18.4956C21.4699 18.367 21.5729 18.2142 21.6432 18.046C21.7135 17.8778 21.7498 17.6975 21.75 17.5153C21.7517 17.166 21.6175 16.8295 21.3755 16.5763Z"
      stroke="#EDEFF0"
      strokeWidth="1.5"
    />
    <path d="M10.5 1.64996H13.5V3.14996L12.75 3.97496H12H11.25L10.5 3.14996V1.64996Z" fill="#EDEFF0" />
    <path
      d="M15 21C15 21.7956 14.6839 22.5587 14.1213 23.1213C13.5587 23.6839 12.7956 24 12 24C11.2044 24 10.4413 23.6839 9.87868 23.1213C9.31607 22.5587 9 21.7956 9 21L12 21H15Z"
      fill="#EDEFF0"
    />
  </svg>
);
