import React, { useEffect, useState } from 'react';

import ActionsHistory from './ActionsHistory';
import EmergencyHead from './EmergencyHead';
import StudentAndGroups from './StudentAndGroups';
import styles from './styles.module.css';

import { useToken } from '../../../../cookies';
import { EmergencyService } from '../../../../services/emergencies.service';
import { EmergencyObj } from '../../../../types/Emergencies';
import { EmergencyTabs } from '../../../../utils/enums';



function EmergencyBlock({ request }: { request: EmergencyObj }) {
  const [isEmergencyBlockOpen, setEmergencyBlockOpen] = useState(false);
  const [emergencyTab, setEmergencyTab] = useState<EmergencyTabs>(EmergencyTabs.ACTION_HISTORY);
  const [emergencyLogs, setEmergencyLogs] = useState<
    Array<{ created_at: string; id: string; item_description: string }>
  >([]);
  const { token } = useToken();

  useEffect(() => {
    if (isEmergencyBlockOpen) {
      EmergencyService.getEmergenciesLogs(token, request.id).then((r) => {
        if (r.data) {
          setEmergencyLogs(r.data);
        }
      });
    }
  }, [isEmergencyBlockOpen]);

  return (
    <div className={styles.emergencyBlock}>
      <EmergencyHead
        isEmergencyBlockOpen={isEmergencyBlockOpen}
        setEmergencyBlockOpen={setEmergencyBlockOpen}
        emergency={request}
      />
      {isEmergencyBlockOpen && (
        <div className={styles.emergencyExpandedBlock}>
          <div style={{ display: 'flex' }}>
            <div
              onClick={() => setEmergencyTab(EmergencyTabs.ACTION_HISTORY)}
              className={`${styles.emergencyTabButtons} ${
                emergencyTab === EmergencyTabs.ACTION_HISTORY && styles.activeEmergencyButton
              }`}
            >
              Action history
            </div>
            <div
              onClick={() => setEmergencyTab(EmergencyTabs.STUDENT_AND_GROUPS)}
              className={`${styles.emergencyTabButtons} ${
                emergencyTab === EmergencyTabs.STUDENT_AND_GROUPS && styles.activeEmergencyButton
              }`}
            >
              Student and groups
            </div>
          </div>

          <div className={styles.tabsContent}>
            {emergencyTab === EmergencyTabs.ACTION_HISTORY ? (
              <ActionsHistory emergency={request} logs={emergencyLogs} />
            ) : (
              <StudentAndGroups emergency={request} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default EmergencyBlock;
