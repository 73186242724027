import React, { useRef, useState } from 'react';
import { getUserImageFromList, getUserNameFromList } from '../../../helpers/userName';
import { NO_USER_AVATAR } from '../../../utils/constantImagesUrl';
import CostumePopup from '../../layout/CostumePopUp';
import { format } from 'date-fns';
import { Message } from '../../../types/Chat';
import { ChatListPreview } from '../../../types/User';
import { messageTopicIcon } from 'images/messageTopicIcon';
import { useToken } from '../../../cookies';
import {clearMessages, initiateNewConversation} from "../../../store/chats";
import {useDispatch} from "react-redux";
import styles from '../MobileChatsStyles.module.css';

function ForeignMessage({
  message,
  date,
  chat,
}: {
  message: Message;
  date: string;
  chat?: ChatListPreview;
}) {
  const [showPopup, setShowPopup] = useState(false);
  const { token } = useToken();
  const dispatch = useDispatch();

  const ref = useRef(null) as any;
  const clickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-end',
        marginLeft: chat?.group ? '18px' : '4px',
      }}
    >
      {chat?.group && (
        <>
          <img
            onClick={() => {
              setShowPopup(!showPopup);
            }}
            src={getUserImageFromList(chat.users, message.author) || NO_USER_AVATAR}
            className={styles.foreignAuthor}
            alt=""
          />
          {showPopup && (
            <CostumePopup className={styles.chatUserPopup}>
              <div ref={ref}>
                <div
                  onClick={() => {
                    dispatch(initiateNewConversation({ token, userIds: [message.author] }));
                  }}
                  className={styles.popUpRow}
                >
                  <div style={{ marginTop: '2px', marginRight: '4px' }}>{messageTopicIcon()}</div>
                  <span className={styles.rowText}>Direct message</span>
                </div>
              </div>
            </CostumePopup>
          )}
        </>
      )}
      {/*// <div className={styles.foreignAuthor}>{getUserNameFromList(chat.users, message.author)}</div>*/}

      <div className={styles.mobChatForeignMessage}>
        {chat?.group && (
          <div className={styles.foreignAuthorName}>{getUserNameFromList(chat.users, message.author)}</div>
        )}
        <div className={styles.messageText}>{message.message}</div>
        <div className={styles.msgDate}>{format(new Date(date), 'HH:mm')}</div>
      </div>
    </div>
  );
}

export default ForeignMessage;