import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Navbar from '../navbar/Navbar';
import Search from '../search/Search';
import AccordionWrapper from '../accordions/AccordionWrapper';
import MoodsAccordion from '../accordions/MoodsAccordion';
import TagsAccordion from '../accordions/TagsAccordion';
import SortTab from '../sortTab/SortTab';
import {
  getFilteredGroups,
  selectFilteredStatisticsGroups,
  selectFilteredStatisticsPeople,
  selectStatisticsNextGroupPage,
  selectStatisticsNextPeoplePage,
  setStatisticsGroupPage,
  setStatisticsPeoplePage,
} from '../../../store/statistics';
import { useDispatch, useSelector } from 'react-redux';
import { useToken } from '../../../cookies';
import useDebounce from '../../../hooks/useDebounce';
import { getMoodNumberByName } from '../../../helpers/moodList';
import GroupStatistics from './groupComponents/GroupStatistics';
import Divider from '@mui/material/Divider';
import PeopleStatistics from './peopleComponents/PeopleStatistics';
import styles from './statisticsStyles.module.css';
import {AccordionVariants, PeopleOrGroupsSwitch, SortByMoods, TabsVariants} from "../../../utils/enums";

function Statistics() {
  const [selectedTags, setSelectedTags] = useState<{ [key: string]: string }>({ all: 'All' });
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [selectedMoods, setSelectedMoods] = useState<{ [key: string]: string }>({});
  const [peopleOrGroupsSwitch, setPeopleOrGroupsSwitch] = useState<PeopleOrGroupsSwitch>(PeopleOrGroupsSwitch.GROUPS);
  const [sortByMoods, setSortByMoods] = useState<SortByMoods | string>('');
  const dispatch = useDispatch();
  const { token } = useToken();
  const filteredGroups = useSelector(selectFilteredStatisticsGroups);
  const filteredPeople = useSelector(selectFilteredStatisticsPeople);

  const statisticsGroupsNextPage = useSelector(selectStatisticsNextGroupPage);
  const statisticsPeoplesNextPage = useSelector(selectStatisticsNextPeoplePage);

  const debouncedSearch = useDebounce(searchFilter);
  const listInnerRef = useRef(null);

  const getGroupsOrPeople = (page: number) => {
    const moodsNumbersVal = Object.values(selectedMoods).map((mood) => getMoodNumberByName(mood));

    const groupsTagsValues = Object.values(selectedTags).reduce((list: Array<string>, acc: string) => {
      if (acc === 'All') return [];
      else return [...list, acc];
    }, []);

    dispatch(
      getFilteredGroups({
        page: page,
        token,
        module: peopleOrGroupsSwitch,
        ...(sortByMoods && { sortBy: sortByMoods }),
        ...(debouncedSearch && { searchParam: debouncedSearch }),
        ...(moodsNumbersVal.length && { moods: moodsNumbersVal }),
        ...(groupsTagsValues.length && { tags: groupsTagsValues }),
      }),
    );
  };

  useEffect(() => {
    dispatch(setStatisticsGroupPage(1));
    dispatch(setStatisticsPeoplePage(1));
    getGroupsOrPeople(1);
  }, [selectedTags, selectedMoods, peopleOrGroupsSwitch, sortByMoods, debouncedSearch]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight + 1 > scrollHeight && (statisticsGroupsNextPage || statisticsPeoplesNextPage)) {
        if (statisticsGroupsNextPage && peopleOrGroupsSwitch === PeopleOrGroupsSwitch.GROUPS) {
          const newPage = statisticsGroupsNextPage.match(/page=([^&]*)/);
          if (newPage && newPage[1]) {
            getGroupsOrPeople(Number(newPage[1]));
          }
        }
        if (statisticsPeoplesNextPage && peopleOrGroupsSwitch === PeopleOrGroupsSwitch.PEOPLE) {
          const newPage = statisticsPeoplesNextPage.match(/page=([^&]*)/);
          if (newPage && newPage[1]) {
            getGroupsOrPeople(Number(newPage[1]));
          }
        }
      }
    }
  };

  return (
    <div className={styles.statisticsMain}>
      <div className={styles.statisticsContent}>
        <div className={styles.leftBlock}>
          <div>
            <Search searchVal={searchFilter} setSearchVal={setSearchFilter} />
          </div>
          <div>
            <AccordionWrapper accordionSummary={AccordionVariants.MOODS}>
              <MoodsAccordion selectedMoods={selectedMoods} setSelectedMoods={setSelectedMoods} />
            </AccordionWrapper>
            {peopleOrGroupsSwitch === PeopleOrGroupsSwitch.GROUPS && (
              <AccordionWrapper accordionSummary={AccordionVariants.TAGS}>
                <TagsAccordion selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
              </AccordionWrapper>
            )}
          </div>
        </div>
        {/*DIVIDER*/}
        <Divider orientation="vertical" flexItem />
        <div ref={listInnerRef} onScroll={() => onScroll()} className={styles.rightBlock}>
          <SortTab setPeopleOrGroupsSwitch={setPeopleOrGroupsSwitch} setSortByMoods={setSortByMoods} />
          <div className={styles.statisticBlocks}>
            {peopleOrGroupsSwitch === PeopleOrGroupsSwitch.PEOPLE ? (
              <div>
                <PeopleStatistics peoples={filteredPeople} />
              </div>
            ) : (
              <GroupStatistics groups={filteredGroups} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;