import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { DownArrow } from 'images/arrows/DownArrow';
import React, { ReactNode } from 'react';

import styles from './accordionStyles.module.css';

import { AccordionVariants } from '../../../utils/enums';


function AccordionWrapper({
  accordionSummary,
  children,
}: {
  accordionSummary: AccordionVariants;
  children: ReactNode;
}) {
  return (
    <div className={styles.accordion}>
      <Accordion style={{ boxShadow: 'none' }}>
        <AccordionSummary expandIcon={DownArrow()}>
          <Typography>{accordionSummary}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{children}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AccordionWrapper;
