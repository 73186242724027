export const lionIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.2057 5.57536L27.0367 2.26069C26.1049 0.84012 24.5468 0 22.8513 0H7.16395C5.46843 0 3.89511 0.84012 2.97861 2.26069L0.809561 5.57536C0.274938 6.38493 0 7.33197 0 8.30957V20.6823C0 21.0183 0.168028 21.3238 0.442977 21.5071C0.717926 21.6904 1.06925 21.7362 1.37475 21.6141L3.02443 20.9572L4.13951 28.1517C4.18533 28.4419 4.35336 28.7016 4.59776 28.8544C4.84215 29.0071 5.14766 29.0529 5.42261 28.9613L9.85234 27.5713L14.557 29.8931C14.6945 29.9694 14.8472 30 15 30C15.1527 30 15.3055 29.9694 15.443 29.8931L20.1477 27.5713L24.5774 28.9613C24.8523 29.0529 25.1578 29.0071 25.4022 28.8544C25.6466 28.7016 25.8147 28.4419 25.8605 28.1517L26.9756 20.9572L28.6253 21.6141C28.9308 21.7362 29.2821 21.7057 29.557 21.5071C29.832 21.3238 30 21.0183 30 20.6823V8.30957C30.0153 7.33197 29.7403 6.38493 29.2057 5.57536ZM28.0143 19.2159L26.5784 18.6354C26.3035 18.5285 25.9827 18.5438 25.7077 18.6965C25.4481 18.8493 25.2648 19.109 25.2189 19.4145L24.0886 26.7159L20.3768 25.555C20.1324 25.4786 19.8574 25.4939 19.6436 25.6161L15.0153 27.8921L10.387 25.6161C10.2495 25.555 10.0967 25.5092 9.94399 25.5092C9.83706 25.5092 9.74541 25.5244 9.63849 25.555L5.92667 26.7159L4.79634 19.4145C4.75051 19.109 4.5672 18.8493 4.30753 18.6965C4.04785 18.5438 3.72709 18.5285 3.43686 18.6354L2.00101 19.2159V8.30957C2.00101 7.72912 2.16903 7.16394 2.48981 6.67514L4.65886 3.36049C5.20876 2.50509 6.15581 2.00101 7.16395 2.00101H22.8513C23.8747 2.00101 24.8065 2.50509 25.3717 3.36049L27.5407 6.67514C27.8615 7.16394 28.0295 7.72912 28.0295 8.30957V19.2159H28.0143Z"
      fill="#333333"
    />
    <path
      d="M18.0095 19.0021H17.0014C16.4515 19.0021 15.9932 18.5591 15.9932 17.994V17.6579C16.1918 17.5357 16.3751 17.3677 16.5279 17.1691L17.6124 15.6111C18.0706 14.9543 18.1165 14.1141 17.7499 13.4115C17.3833 12.7088 16.6653 12.2659 15.871 12.2659H14.1144C13.3201 12.2659 12.6022 12.7088 12.2356 13.4115C11.869 14.1141 11.9301 14.9543 12.3731 15.6111L13.4576 17.1691C13.5951 17.3677 13.7784 17.5357 13.9922 17.6579V17.994C13.9922 18.5439 13.5493 19.0021 12.9841 19.0021H11.9759C11.426 19.0021 10.9678 19.4451 10.9678 20.0103C10.9678 20.5602 11.4108 21.0184 11.9759 21.0184H12.9841C13.7631 21.0184 14.4505 20.7129 14.9851 20.2394C15.5197 20.7129 16.2224 21.0184 16.9861 21.0184H17.9942C18.5441 21.0184 19.0024 20.5754 19.0024 20.0103C19.0177 19.4451 18.5594 19.0021 18.0095 19.0021ZM14.0228 14.3433C14.0533 14.2822 14.1144 14.2822 14.1297 14.2822H15.8863C15.9016 14.2822 15.9627 14.2822 15.9932 14.3433C16.0238 14.4044 15.9932 14.4502 15.978 14.4655L15.0004 15.8555L14.0228 14.4655C14.0228 14.4502 13.9922 14.4044 14.0228 14.3433Z"
      fill="#333333"
    />
    <path
      d="M12.0065 10.8911V10.3871C12.0065 9.83716 11.5636 9.37891 10.9984 9.37891C10.4485 9.37891 9.99023 9.82189 9.99023 10.3871V10.8911C9.99023 11.441 10.4332 11.8993 10.9984 11.8993C11.5636 11.884 12.0065 11.441 12.0065 10.8911Z"
      fill="#333333"
    />
    <path
      d="M19.0169 9.39404C18.467 9.39404 18.0088 9.83701 18.0088 10.4022V10.9063C18.0088 11.4562 18.4518 11.9144 19.0169 11.9144C19.5668 11.9144 20.0251 11.4714 20.0251 10.9063V10.4022C20.0098 9.83701 19.5668 9.39404 19.0169 9.39404Z"
      fill="#333333"
    />
    <path
      d="M25.6468 6.6599C19.9035 8.89004 15.9167 4.52141 15.7487 4.33811C15.3668 3.91041 14.6183 3.91041 14.2517 4.33811C14.2059 4.38393 10.1428 8.90532 4.35355 6.6599C3.8342 6.46133 3.25375 6.721 3.05518 7.22508C2.8566 7.74442 3.11627 8.32487 3.62034 8.52345C4.33827 8.7984 5.04092 8.99696 5.72829 9.11916L6.95029 18.8799C7.28634 21.5835 9.59285 23.6151 12.3118 23.6151H17.7497C20.4686 23.6151 22.7751 21.5835 23.1112 18.8799L24.3332 9.11916C24.99 8.99696 25.6774 8.81367 26.3647 8.53872C26.8841 8.34014 27.1285 7.7597 26.9299 7.24035C26.7466 6.721 26.1662 6.46133 25.6468 6.6599ZM21.1407 18.6202C20.9269 20.3157 19.4757 21.5988 17.7649 21.5988H12.3271C10.6163 21.5988 9.16515 20.3157 8.9513 18.6202L7.79041 9.27191C11.2273 9.19554 13.8087 7.40837 15.0155 6.36967C16.2222 7.40837 18.8342 9.22609 22.3169 9.27191L21.1407 18.6202Z"
      fill="#333333"
    />
  </svg>
);
