import { ERROR } from '../errorsTemplates';

export const errorGuard = (error: any) => {
  if (error.status && error.status === 401) {
    throw new Error(ERROR.auth.refresh_token_expired);
  }
  return;
};

export const getCreateUserCostumeError = (status: number) => {
  switch (status) {
    case 400:
      return 'User already exist';
    case 500:
      return 'Server error, check the console';
    default:
      return 'Something went wrong';
  }
};

export const getPasswordError = (password: string, repeatPassword: string, termsAndCondAccepted: boolean) => {
  if (!termsAndCondAccepted) {
    return 'Please accept Master Service Agreement';
  } else if (!password.length || !repeatPassword.length) {
    return 'Not allowed empty fields';
  } else if (password !== repeatPassword) {
    return 'Passwords do NOT match';
  }
  else if (password.length<6 || repeatPassword.length<6) {
    return 'Minimum 6 characters';
  }
  return ''
};