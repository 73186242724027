export const peer = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.1875 1.1875H36.8125V36.8125H1.1875V1.1875Z" fill="#8FE799" />
    <g clipPath="url(#clip0_3750_29739)">
      <path
        d="M22.5212 26.6875V25.0208C22.5212 24.1368 22.17 23.2889 21.5448 22.6638C20.9197 22.0387 20.0719 21.6875 19.1878 21.6875H14.1878C13.3038 21.6875 12.4559 22.0387 11.8308 22.6638C11.2057 23.2889 10.8545 24.1368 10.8545 25.0208V26.6875"
        stroke="#252525"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6878 18.3542C18.5288 18.3542 20.0212 16.8618 20.0212 15.0208C20.0212 13.1799 18.5288 11.6875 16.6878 11.6875C14.8469 11.6875 13.3545 13.1799 13.3545 15.0208C13.3545 16.8618 14.8469 18.3542 16.6878 18.3542Z"
        stroke="#252525"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.345 18.0628L27.2064 17.9246L25.8678 19.2632L26.0062 19.4017L26.2213 19.6168L26.3598 19.7552L27.6989 18.4161L27.699 18.4159L27.7527 18.3623L27.8285 18.2865L27.9337 18.1813C27.9472 18.1852 27.9606 18.1899 27.9737 18.1953C28.0197 18.2144 28.0615 18.2424 28.0966 18.2777C28.2451 18.4261 28.2451 18.6655 28.0966 18.8079L25.9547 20.9559C24.9792 21.9314 23.3947 21.9314 22.4192 20.9559C21.4436 19.9803 21.4436 18.3958 22.4192 17.4203L23.8097 16.0267M27.345 18.0628L23.9921 16.2127L23.8097 16.0267M27.345 18.0628L26.3598 19.0481L27.345 18.0628ZM23.8097 16.0267L23.9941 16.2107L23.8097 16.0267ZM22.7654 18.1465L22.8713 18.2523C22.9478 18.329 22.9908 18.4329 22.9908 18.5412C22.9908 18.6496 22.9478 18.7535 22.8713 18.8302L22.5181 19.1837L22.8715 19.5371L23.0866 19.7522L23.4405 20.1061L23.7941 19.7518C24.0884 19.4568 24.2651 19.0647 24.2911 18.6489C24.3075 18.3853 24.2627 18.1236 24.1627 17.8835L24.1841 17.8621L24.3676 18.043L24.5827 18.255L24.9362 18.6036L24.9419 18.598L25.1127 18.7787L25.3217 18.9998L25.506 19.1948L25.2991 19.4017L25.6527 19.7552L25.8678 19.9703L26.0498 20.1524L25.6012 20.6023C25.6011 20.6024 25.6011 20.6024 25.601 20.6025C24.8207 21.3826 23.5529 21.3825 22.7727 20.6023C22.0568 19.8864 21.9977 18.7601 22.5954 17.9765L22.7654 18.1465ZM26.0349 15.6696L26.0336 15.6709C26.034 15.6704 26.0345 15.67 26.035 15.6696L26.0349 15.6696ZM27.634 17.0667L27.6339 17.0669L27.634 17.0667ZM27.2069 17.9241L27.2066 17.9243L27.5453 17.8626L27.3453 18.0626L27.2069 17.9241ZM27.5604 18.2777L26.5749 19.2632L27.5604 18.2777ZM27.9144 18.6308C27.9143 18.6309 27.9141 18.6311 27.914 18.6312L27.9144 18.6308Z"
        fill="#282828"
        stroke="#252525"
      />
    </g>
    <rect
      x="0.594359"
      y="0.594359"
      width="36.8113"
      height="36.8113"
      rx="18.4056"
      stroke="#EDEFF0"
      strokeWidth="1.18872"
    />
    <defs>
      <clipPath id="clip0_3750_29739">
        <rect width="20" height="20" fill="white" transform="translate(9.1875 9.1875)" />
      </clipPath>
    </defs>
  </svg>
);
