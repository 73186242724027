import { getTimeRead } from '../../helpers/articleInfo';
import { Paper } from '../../types/Paper';
import { NO_USER_AVATAR } from '../../utils/constantImagesUrl';
import style from './UserStyles.module.css';
import { userNameFromAllParams } from '../../helpers/userName';

export default function UserInfoMini(props: Paper) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false,
  };
  const { paper_author } = props;

  return (
    <>
      <div className={style.infUserMinMain}>
        <img
          className={style.infUserMinImg}
          src={paper_author?.profile?.image_url || NO_USER_AVATAR}
          alt="User Avatar"
        />

        <div className={style.infUserMinCredentialBlock}>
          {userNameFromAllParams(
            paper_author?.username,
            paper_author?.profile?.first_name,
            paper_author?.profile?.last_name,
          )}

          <span className={style.dot}>&#8226;</span>
          <span>{props.views} Views</span>
          <span className={style.dot}>&#8226;</span>
          <span>{new Intl.DateTimeFormat('us-US', options).format(new Date(props.created))}</span>
          <span className={style.dot}>&#8226;</span>
          <span>{getTimeRead(props.duration)}</span>
          {!!props.paper_tags?.length && (
            <>
              <span className={style.dot}>&#8226;</span>

              {props.paper_tags.map((tag) => {
                return (
                  <div key={tag.id} className={style.infUserMinTagItem}>
                    {tag.name}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
}
