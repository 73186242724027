export const vextLogo = () => (
  <svg width="123" height="92" viewBox="0 0 133 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M99.9894 56.7108L127.978 40.7175C131.31 38.3852 132.642 36.0528 132.642 31.0549V6.06538C132.642 2.73318 128.977 -0.26613 125.645 1.40067L78.3318 28.7227C73.3339 31.3882 66.67 37.0525 66.67 44.0496V93.6954C66.67 82.5582 74.6666 68.8972 99.9894 56.7108Z"
      fill="url(#paint0_linear_1754_11135)"
    />
    <path
      d="M33.3506 56.7108V88.031C33.3506 92.0295 33.3506 92.6959 37.3489 96.3609L59.0065 109.022C62.0866 110.586 64.3833 111.403 66.67 111.402V93.6954V44.0496C66.6747 37.0796 60.6725 32.0546 55.0082 28.7227L7.36151 1.40067C24.0212 10.9538 33.3506 23.3916 33.3506 56.7108Z"
      fill="url(#paint1_linear_1754_11135)"
    />
    <path
      d="M7.36151 1.40067C4.02957 0.0680066 0.364444 2.40048 0.364444 6.06549L0.03125 32.0546C0.364444 35.7197 2.03041 37.7189 4.02958 39.3849L33.3506 56.7108C33.3506 23.3916 24.0212 10.9538 7.36151 1.40067Z"
      fill="url(#paint2_linear_1754_11135)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1754_11135"
        x1="133.975"
        y1="16.5858"
        x2="66.0036"
        y2="61.567"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CFCFCF" />
        <stop offset="1" stopColor="#F8F9FA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1754_11135"
        x1="66.3368"
        y1="0.92572"
        x2="66.3368"
        y2="111.402"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.145095" stopColor="#F8F9FA" />
        <stop offset="1" stopColor="#CED4DA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1754_11135"
        x1="29.3523"
        y1="23.9161"
        x2="0.0312477"
        y2="36.5775"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADB5BD" />
        <stop offset="1" stopColor="#F8F9FA" />
      </linearGradient>
    </defs>
  </svg>
);
