import { ApiResponse } from 'apisauce';
import { format } from 'date-fns';

import { _RestApiService } from './api.service';

import { _currentDateInIsoFormat, currentDateInIsoFormat } from '../helpers/date';

const moodApi = new _RestApiService(window._env_.REACT_APP_BACKEND_URL || '');

export class _MoodApiService {
  canUpdateMood(token: string, groupId: string): Promise<ApiResponse<any>> {
    return moodApi.get(
      `/api/groups/${groupId}/can_update/`,
      {
        local_time: _currentDateInIsoFormat({
          date: format(new Date(), 'yyyy-MM-dd'),
          periodOfDay: 'start',
        }),
      },
      moodApi.getAuthenticateHeader(token),
    );
  }

  getAllUsersMoodHistory(token: string, from: string, to: string): Promise<ApiResponse<any>> {
    return moodApi.get(
      `/api/mood/weekly/`,
      {
        from_date: _currentDateInIsoFormat({ date: from, periodOfDay: 'start' }),
        to_date: _currentDateInIsoFormat({ date: to, periodOfDay: 'end' }),
      },
      moodApi.getAuthenticateHeader(token),
    );
  }

  getMyMoodsPerGroupHistory(token: string, groupId: string, from: string, to: string): Promise<ApiResponse<any>> {
    return moodApi.get(
      `/api/mood/weekly`,
      {
        from_date: currentDateInIsoFormat({ date: from }),
        to_date: currentDateInIsoFormat({ date: to }),
        group_id: groupId,
      },
      moodApi.getAuthenticateHeader(token),
    );
  }

  getDomainMoods(token: string, from?: string, to?: string): Promise<ApiResponse<{ [key: string]: number }>> {
    // return {groupId:averageMood}
    return moodApi.get(
      `/admin/domain/moods`,
      {
        from_date: from,
        to_date: to,
      },
      moodApi.getAuthenticateHeader(token),
    );
  }

  getUsaStatesMoods(from: string, to: string): Promise<ApiResponse<any>> {
    return moodApi.get(`/admin/moods/states/`, {
      from: _currentDateInIsoFormat({ date: from, periodOfDay: 'start' }),
      to: _currentDateInIsoFormat({ date: to, periodOfDay: 'end' }),
    });
  }

  getGroupUsersMoods(
    token: string,
    groupId: string,
    from?: string,
    to?: string,
  ): Promise<ApiResponse<{ [key: string]: number }>> {
    // return {userId:averageMood}
    return moodApi.get(
      `/admin/group/moods`,
      {
        from_date: from,
        to_date: to,
        group_id: groupId,
      },
      moodApi.getAuthenticateHeader(token),
    );
  }

  postMode(token: string, groupId: string, mood_value: number, recording_date: string): Promise<ApiResponse<any>> {
    return moodApi.post(
      `/api/groups/${groupId}/mood/`,
      {
        mood_value,
        recording_date,
      },
      moodApi.getAuthenticateHeader(token),
    );
  }
}

export const MoodApiService = new _MoodApiService();
