import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setRecentGroup } from '../../../store/groups';
import { useHistory } from 'react-router-dom';
import { checkImage } from '../../../helpers/checkImageUrl';
import { dots3Icon } from 'images/dots3Icon';
import PopOverGroupMenu from './PopOverGroupMenu';
import { NO_GROUP_COVER } from '../../../utils/constantImagesUrl';
import styles from './pinedStyles.module.css';

export default function PinedGroupCardMobile({ group }: any) {
  const [isImgUrlAvailable, setIsImgUrlAvailable] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkImg = async () => {
      if (group.group_photo) {
        const isAvailable = (await checkImage(group.group_photo)) as boolean;
        setIsImgUrlAvailable(isAvailable);
      } else {
        setIsImgUrlAvailable(true);
      }
    };

    checkImg();
    // eslint-disable-next-line
  }, []);

  const redirectToGroupUrl = () => {
    dispatch(setRecentGroup(group.id));
    history.push({ pathname: `group/${group.id}`, state: group.id });
  };
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={styles.pinedCardBlockMobile}>
      <div>
        <div className={styles.backgroundThreeDots}>
          <div className={styles.threeDotIcon}>
            <div className={styles.clickThreeDotArea} onClick={(e) => handleClick(e)}>
              {dots3Icon('white', '24', '24')}
            </div>
            <PopOverGroupMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} isPinned={true} groupId={group.id} />
          </div>
        </div>
        <div onClick={redirectToGroupUrl}>
          <div className={styles.pinedGroupImages}>
            <img src={group.group_photo && isImgUrlAvailable ? group.group_photo : NO_GROUP_COVER} alt="group img" />
          </div>
        </div>
      </div>
    </div>
  );
}