import { useSelector } from 'react-redux';
import { DownArrow } from 'images/arrows/DownArrow';
import { imageLogo } from 'images/logo/imageLogo';
import { planetIcon } from 'images/planetIcon';
import { rightArrow } from 'images/rightArrow';
import { selectLoggedUserData } from '../../../store/user/selectors';
import { NO_USER_AVATAR } from '../../../utils/constantImagesUrl';
import style from './style.module.css';

export default function AskModalHeader() {
  const loggedUserData = useSelector(selectLoggedUserData);
  return (
    <div className={style.mainHeader}>
      <div className={style.headerLogoBlock}>
        <div>{imageLogo('#1D00D1', '#7681EB', '#A683FF', '#2E57DF', '#551DD3', '#C5B5FF', '50', '40')}</div>
        <div className={style.headerLogoText}>Add Question</div>
      </div>
      <div className={style.headerPostInfo}>
        <div>
          <img className={style.userImg} src={loggedUserData.profile?.image_url || NO_USER_AVATAR} alt="User Avatar" />
        </div>
        <div>{rightArrow()}</div>

        <div className={style.questionTypeDropDownBlock}>
          {planetIcon()}
          <span className={style.questionTypeDropDownText}>Public</span>
          {DownArrow('16')}
        </div>
      </div>
    </div>
  );
}
