import { Dispatch, SetStateAction } from 'react';
import styles from './styles.module.css';

type BioProps = {
  shortBio: string;
  setUserShortBio: Dispatch<SetStateAction<string>>;
};

export function ShortBioMobileSettings(props: BioProps) {
  const { shortBio, setUserShortBio } = props;
  return (
    <div>
      <p className={styles.inputLabel}>Short bio</p>
      <input
        type="text"
        className={styles.inputField}
        value={shortBio}
        onChange={(e) => setUserShortBio(e.target.value)}
      />
    </div>
  );
}