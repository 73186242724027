import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToken } from 'src/cookies';
import ViewQuestion from 'src/pages/Article/ViewQuestion';
import { getPaperById } from 'src/store/articles';
import { selectFeedError, selectSelectedPaper } from 'src/store/articles/selector';
import { clearUserState } from 'src/store/user';
import { Paper } from 'src/types/Paper';

export default function ArticleQuestionShared() {
  const paper = useSelector(selectSelectedPaper) as Paper;
  const feedError = useSelector(selectFeedError);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const c: { id: string } = useParams();
  const { token } = useToken();
  const { removeToken } = useToken();
  const history = useHistory();

  useEffect(() => {
    dispatch(getPaperById({ token, id: c.id }));
    if (feedError) {
      dispatch(clearUserState());
      removeToken();
      history.push('/login');
    }
    // eslint-disable-next-line
  }, [paper, feedError]);

  return (
    <div>
      {paper.id && <ViewQuestion isModalVisible={isModalOpen} setModalVisibility={setIsModalOpen} article={paper} />}
    </div>
  );
}
