import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from 'styled-components/macro';
import { useToken } from '../../../cookies';
import { updateUser } from '../../../store/user';
import { selectLoggedUserData } from '../../../store/user/selectors';
import { EditContentSwitch } from './EditContentSwitch';
import { User } from '../../../types/User';

export const Education = () => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const userData = useSelector(selectLoggedUserData);
  const [isEditable, setIsEditable] = useState(true);
  const [institute, setInstitute] = useState('');
  const [major, setMajor] = useState('');
  const [graduation, setGraduation] = useState('');
  const [email, setEmail] = useState('');
  const [allCompleted, setAllcompleted] = useState(false);

  useEffect(() => {
    setInstitute(userData.profile?.institute || '');
    setMajor(userData.profile?.major || '');
    setGraduation(userData.profile?.graduation || '');
    setEmail(userData.profile?.student_email || '');
  }, [userData]);

  useEffect(() => {
    if (institute.length && major.length && graduation.length && email.length) {
      setAllcompleted(true);
    } else {
      setAllcompleted(false);
    }
  }, [institute, major, graduation, email]);

  const saveUserEducation = () => {
    if (institute.length && major.length && graduation.length && email.length) {
      const newUserInfo = {
        ...userData,
        profile: {
          institute,
          major,
          graduation,
          student_email: email,
        },
      }as unknown as User;
      dispatch(updateUser({ token, updatedData: newUserInfo }));
      setIsEditable(true);
    }
  };
  const rowData = (title: string, info: string, stateCase: string) => {
    const setEducationData = (data: string) => {
      switch (stateCase) {
        case 'institute':
          return setInstitute(data);
        case 'major':
          return setMajor(data);
        case 'graduation':
          return setGraduation(data);
        case 'email':
          return setEmail(data);
      }
    };
    const getUserData = (): string => {
      switch (stateCase) {
        case 'institute':
          return institute;
        case 'major':
          return major;
        case 'graduation':
          return graduation;
        case 'email':
          return email;
        default:
          return '';
      }
    };

    return (
      <div
        css={css`
          margin-top: 6px;
        `}
      >
        <div
          css={css`
            font-family: sans-serif;
            font-weight: 600;
          `}
        >
          {title}
        </div>
        <div
          css={css`
            margin-top: 6px;
            font-family: sans-serif;
            font-weight: 400;
          `}
        >
          {isEditable ? (
            info
          ) : (
            <input
              css={css`
                border: none;
                ${getUserData().length
                  ? ` border-bottom: 0.5px solid rgba(0, 0, 0, 0.56);`
                  : ` border-bottom: 0.5px solid rgba(253, 61, 2, 0.56);`}
                padding-bottom: 4px;
                width: 28%;

                &:focus {
                  border: none;
                  outline: none;
                  ${getUserData().length
                    ? ` border-bottom: 0.5px solid rgba(0, 0, 0, 0.56);`
                    : ` border-bottom: 0.5px solid rgba(253, 61, 2, 0.56);`}
                }
              `}
              defaultValue={info}
              onChange={(e) => setEducationData(e.target.value)}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          width: 70%;
        `}
      >
        <div
          css={css`
            margin-top: 14px;
          `}
        >
          {rowData('Your institute', institute, 'institute')}
        </div>
        <div
          css={css`
            margin-top: 34px;
          `}
        >
          {rowData('Major', major, 'major')}
        </div>
        <div
          css={css`
            margin-top: 34px;
          `}
        >
          {rowData('Graduation', graduation, 'graduation')}
        </div>
        <div
          css={css`
            margin-top: 34px;
          `}
        >
          {rowData('StudentEmail', email, 'email')}
        </div>
      </div>
      <div
        css={css`
          margin: 6px;
          display: flex;
          padding: 6px;
          justify-content: end;
        `}
      >
        <EditContentSwitch
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          submit={saveUserEducation}
          isAllCompleted={allCompleted}
        />
      </div>
    </div>
  );
};
