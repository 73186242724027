import { ApiResponse } from 'apisauce';

import { _RestApiService } from './api.service';

const emergencyApi = new _RestApiService(window?._env_?.REACT_APP_BACKEND_URL || '');

export class _EmergencyService {
  getEmergenciesList(token: string): Promise<ApiResponse<any>> {
    return emergencyApi.get(`/admin/emergencies/`, {}, emergencyApi.getAuthenticateHeader(token));
  }

  getEmergenciesLogs(
    token: string,
    emergencyId: string,
  ): Promise<ApiResponse<Array<{ created_at: string; id: string; item_description: string }>>> {
    return emergencyApi.get(
      `/admin/emergencies/${emergencyId}/history/`,
      {},
      emergencyApi.getAuthenticateHeader(token),
    );
  }

  getEmergenciesByGroupId(token: string, groupId: string, from?: string, to?: string): Promise<ApiResponse<any>> {
    // return {user_id : is_emergency_incognito}
    return emergencyApi.get(
      `admin/group/emergencies`,
      {
        group_id: groupId,
        from,
        to,
      },
      emergencyApi.getAuthenticateHeader(token),
    );
  }

  getDashBoardEmergencies(
    token: string,
    from: string,
    to: string,
    isOutliers: boolean,
  ): Promise<ApiResponse<{ change: number; data: Array<string> }>> {
    return emergencyApi.get(
      `admin/emergencies/graph/?is_outliers=${isOutliers}&from_date=${from}&to_date=${to}`,
      {},
      emergencyApi.getAuthenticateHeader(token),
    );
  }

  getDashBoardRolesAndUsers(token: string): Promise<ApiResponse<{ [key: string]: number }>> {
    return emergencyApi.get(`admin/roles/`, {}, emergencyApi.getAuthenticateHeader(token));
  }

  updateEmergency(token: string, emerId: string, handlerId: string): Promise<ApiResponse<any>> {
    return emergencyApi.patch(
      `/admin/emergencies/${emerId}/`,
      { handler: handlerId },
      emergencyApi.getAuthenticateHeader(token),
    );
  }

  createAnEmergency({
    token,
    emergency_reason,
    emergency_info,
    state,
    town,
    address,
    user,
    is_emergency_incognito,
    is_emergency_button,
  }: {
    token: string;
    emergency_reason: string;
    emergency_info?: string;
    state: string;
    town: string;
    address: string;
    user: string;
    is_emergency_incognito: boolean;
    is_emergency_button: boolean;
  }): Promise<ApiResponse<any>> {
    return emergencyApi.post(
      `/admin/emergencies/`,
      {
        emergency_reason,
        emergency_info,
        state,
        town,
        address,
        user,
        is_emergency_incognito,
        is_emergency_button,
      },
      emergencyApi.getAuthenticateHeader(token),
    );
  }
}

export const EmergencyService = new _EmergencyService();
