import React, { useEffect, useState } from 'react';
import ImageMobileInput from '../../../helpers/ImageMobileInput';
import MenuItem from '@mui/material/MenuItem';
import { darkTheme } from '../../../themes/themes';
import { ThemeProvider } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { selectTags } from '../../../store/tags/selectors';
import { useHistory } from 'react-router-dom';
import { ArticlesApiService } from '../../../services/articles.service';
import { getPaperFormData } from '../helper/createArticleHelper';
import { selectUserId } from '../../../store/auth';
import { useToken } from '../../../cookies';
import { getAllGroupsNames, selectAllGroupsName, selectRecentGroupId } from '../../../store/groups';
import MessageHockPopUp from '../../../hooks/MessageHockPopUp';
import { switchTheme } from '../../../helpers/muiThemes';
import { getTags } from '../../../store/tags';
import MobileLoaderScreen from '../../layout/MobileLoaderScreen';
import ReactGA from "react-ga4";
import styles from './styles.module.css';

function MobileCreateArticlePage2({
  fieldValue,
  title,
  setPage,
  wordCount,
}: {
  fieldValue: string;
  title: string;
  setPage: React.Dispatch<React.SetStateAction<boolean>>;
  wordCount: number;
}) {
  const [groupImage, setGroupImage] = useState<null | Blob>(null);
  const [imagePreview, setImagePreview] = useState('');
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
  const [isCommentsOn, setIsCommentsOn] = useState(false);
  const [articleDescription, setArticleDescription] = useState('');
  const [isArticleSearchable, setIsArticleSearchable] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userId = useSelector(selectUserId);
  const groups = useSelector(selectAllGroupsName);
  const recentOpenedGroupId = useSelector(selectRecentGroupId);
  const [selectedGroups, setSelectedGroups] = useState<Array<string>>([recentOpenedGroupId]);
  const tags = useSelector(selectTags);
  const { token } = useToken();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllGroupsNames(token));
    dispatch(getTags({ token }));
    // eslint-disable-next-line
  }, []);

  const isPostingAvailable = (): boolean => {
    return isAgreed && !!imagePreview;
  };

  const getMyErrorPopUp = () => {
    if (!isAgreed) {
      MessageHockPopUp({
        message: 'Need to agree Vext honor code',
        timeOut: 5000,
        type: 'error',
        size: 'small',
      });
    }
    if (!imagePreview) {
      MessageHockPopUp({
        message: 'Please chose a image',
        timeOut: 5000,
        type: 'error',
        size: 'small',
      });
    }
  };

  const submit = async () => {
    // Create paper
    if (title.length > 1 && isAgreed) {
      setIsLoading(true);
      ArticlesApiService.postArticle(
        getPaperFormData({
          articleTitle: title,
          articleDescription,
          wordCount,
          fieldValue,
          articleTags: selectedTags.map((tag) => {
            return { id: tag };
          }),
          articleGroups: selectedGroups.map((group) => {
            return { id: group };
          }),
          isCommentsOn,
          isSharePaperOn: isArticleSearchable,
          userId,
          imageInputUrl: groupImage,
        }),
        token,
      )
        .then((res) => {
          if (!res.ok) {
            ReactGA.event({
              category: 'User',
              action: 'article_written',
              label: 'A new article posted by the user',
            });
            MessageHockPopUp({
              message: 'Error',
              timeOut: 5000,
              type: 'error',
              size: 'small',
            });
          } else {
            MessageHockPopUp({
              message: 'Article created',
              timeOut: 5000,
              type: 'success',
              size: 'small',
            });
            history.push(recentOpenedGroupId ? `/group/${recentOpenedGroupId}` : '/feed');
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  const tagsItems = () => {
    return tags.map((subject) => {
      return (
        <MenuItem key={subject.id} value={subject.id}>
          {subject.name}
        </MenuItem>
      );
    });
  };
  const groupsItems = () => {
    return groups.map((group) => {
      return (
        <MenuItem key={Object.keys(group)[0]} value={Object.keys(group)[0]}>
          {Object.values(group)[0]}
        </MenuItem>
      );
    });
  };

  return (
    <div>
      <ThemeProvider theme={darkTheme}>
        <h1>{title}</h1>
        <div>
          <textarea
            onChange={(e) => setArticleDescription(e.target.value)}
            value={articleDescription}
            maxLength={300}
            className={styles.textarea}
            name="description"
            placeholder={'Description'}
          />
        </div>
        <p style={{ marginBottom: '0px' }}>Cover Image</p>
        <div className={styles.imageBlock}>
          <img
            className={styles.imagePreview}
            src={
              imagePreview ||
              'https://t4.ftcdn.net/jpg/04/99/93/31/360_F_499933117_ZAUBfv3P1HEOsZDrnkbNCt4jc3AodArl.jpg'
            }
            alt=""
          />

          <ImageMobileInput setImage={setGroupImage} setImagePreview={setImagePreview} />
        </div>

        <div>
          <TextField
            SelectProps={{
              multiple: true,
              value: selectedTags,
              onChange: (e) => {
                const selectedT = e.target.value as Array<string>;
                setSelectedTags(selectedT);
              },
              MenuProps: { sx: { maxHeight: '50%' } },
            }}
            style={{ marginTop: '18px', backgroundColor: '#2A2A2A' }}
            select
            fullWidth
            label="Tags"
            size="small"
            InputLabelProps={{
              style: {
                color: '#8B8C8D',
                fontWeight: 300,
              },
            }}
          >
            {tagsItems()}
          </TextField>
        </div>
        {isLoading && <MobileLoaderScreen />}
        <div>
          <TextField
            SelectProps={{
              multiple: true,
              value: selectedGroups,
              onChange: (e) => {
                const selectedG = e.target.value as Array<string>;
                setSelectedGroups(selectedG);
              },
              MenuProps: { sx: { maxHeight: '50%' } },
            }}
            style={{ marginTop: '18px', backgroundColor: '#2A2A2A' }}
            select
            fullWidth
            label="Groups"
            size="small"
            InputLabelProps={{
              style: {
                color: '#8B8C8D',
                fontWeight: 300,
              },
            }}
          >
            {groupsItems()}
          </TextField>
        </div>
        <ThemeProvider theme={switchTheme}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <span style={{ marginRight: 'auto' }}>Turn on/off comment for your paper</span>
            <FormGroup style={{ width: '11%' }}>
              <FormControlLabel
                control={<Switch checked={isCommentsOn} onChange={(e) => setIsCommentsOn(e.target.checked)} />}
                label=""
              />
            </FormGroup>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 'auto' }}>Keep my paper searchable on VEXT</span>
            <FormGroup style={{ width: '11%' }}>
              <FormControlLabel
                control={
                  <Switch checked={isArticleSearchable} onChange={(e) => setIsArticleSearchable(e.target.checked)} />
                }
                label=""
              />
            </FormGroup>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 'auto' }}>
              I agree to <span style={{ color: '#3bb15c', fontWeight: 700, cursor: 'pointer' }}>VEXT Honor Code</span>
            </span>
            <FormGroup style={{ width: '11%' }}>
              <FormControlLabel
                control={<Switch checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} />}
                label=""
              />
            </FormGroup>
          </div>
        </ThemeProvider>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '26px',
            justifyContent: 'space-between',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div
            className={styles.button}
            style={{ color: isPostingAvailable() ? 'white' : 'red' }}
            onClick={() => (isPostingAvailable() ? submit() : getMyErrorPopUp())}
          >
            Publish now
          </div>
          <div className={styles.button} onClick={() => setPage(false)}>
            Back
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default MobileCreateArticlePage2;
