import React from 'react';

import AboutStudent from './AboutStudent';
import styles from './styles.module.css';

import { EmergencyObj } from '../../../../types/Emergencies';

function StudentAndGroups({ emergency }: { emergency: EmergencyObj }) {
  return (
    <div>
      <div className={styles.aboutStudentLabel}>About student</div>
      <AboutStudent emergency={emergency} />
      {/*<div className={styles.divider}></div>*/}
    </div>
  );
}

export default StudentAndGroups;
