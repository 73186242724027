import React, { useEffect, useState } from 'react';
import { IConfirmEmailCodeProps } from 'src/types/Auth';
import ResendButton from '../../../components/layout/ResendButton';
import TextField from '@mui/material/TextField';
import { Button } from '../../layout/Button';
import { useRefreshToken, useToken } from 'src/cookies';
import { AuthApiService } from 'src/services/auth.service';
import { useHistory } from 'react-router-dom';
import MessageHockPopUp from 'src/hooks/MessageHockPopUp';
import { useIsMobile } from '../../../utils/styles.utils';
import { darkTheme, lightTheme } from '../../../themes/themes';
import { ThemeProvider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId, updateDeviceToken } from '../../../store/auth';
import style from '../AuthStyle.module.css';

export default function EmailCodeConfirm({ email, password }: IConfirmEmailCodeProps) {
  const [confirmCode, setConfirmCode] = useState('');
  const [confirmCodeError, setConfirmCodeError] = useState('');
  const [tokenResponse, setTokenResponse] = useState<{ access: string; refresh: string }>({ access: '', refresh: '' });
  const { setRefreshToken } = useRefreshToken();
  const { setToken, removeToken } = useToken();
  const history = useHistory();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const loggedUserId = useSelector(selectUserId);

  useEffect(() => {
    AuthApiService.signIn(email, password).then((resp) => {
      setTokenResponse(resp.data);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (confirmCode.length < 1) {
      setConfirmCodeError('Not allowed empty field');
    } else {
      setConfirmCodeError('');
    }
  }, [confirmCode]);

  const triggerConfirmCode = async () => {
    if (tokenResponse.access && confirmCode.length) {
      try {
        const codeConfirmedResponse = await AuthApiService.confirmCode(tokenResponse.access, confirmCode);

        if (codeConfirmedResponse.ok) {
          setToken(tokenResponse.access);
          setRefreshToken(tokenResponse.refresh);
          if (window.deviceToken) {
            dispatch(
              updateDeviceToken({
                deviceToken: window.deviceToken,
                token: tokenResponse.access,
                userId: loggedUserId,
              }),
            );
          }
          history.push('/feed');
        }
      } catch (e) {
        MessageHockPopUp({
          size: 'small',
          message: 'Code is invalid or expired. Please request another confirmation email by signing in',
          timeOut: 5000,
          type: 'error',
        });
        removeToken();
      }
    }
  };

  return (
    <div className={style.mainConfirmBlock}>
      <ThemeProvider theme={isMobile ? darkTheme : lightTheme}>
        <span style={isMobile ? { color: 'white' } : {}}>Enter the code received by email</span>
        <TextField
          id="outlined-email-input"
          label="Confirm code"
          type="text"
          onChange={(e) => setConfirmCode(e.target.value)}
          error={!!confirmCodeError}
          helperText={confirmCodeError}
          size={isMobile ? 'small' : 'medium'}
        />
        <Button className={style.codeConfirmButton} radius={2} onClick={triggerConfirmCode}>
          Confirm
        </Button>
        <ResendButton token={tokenResponse.access} />
      </ThemeProvider>
    </div>
  );
}
