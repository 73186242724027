export const rhinoIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.8845 12.9944L24.8931 12.2928L24.3135 9.36452C29.6364 7.68684 29.9719 3.26385 29.9872 3.06558C30.0024 2.79105 29.9109 2.51652 29.7126 2.31825C29.5296 2.11998 29.2551 1.99796 28.9806 1.99796H22.3003C21.3852 0.77783 19.9363 0 18.3349 0H11.6547C10.0532 0 8.60433 0.77783 7.68924 1.99796H1.00902C0.734493 1.99796 0.475208 2.10473 0.276936 2.31825C0.0939166 2.51652 -0.0128337 2.79105 0.00241791 3.06558C0.0176696 3.26385 0.353198 7.68684 5.67602 9.36452L5.09645 12.2928L4.1051 12.9944C2.7782 13.9248 2.00038 15.4499 2.00038 17.0666V20.3457C2.00038 21.6726 2.51893 22.9385 3.46454 23.8841L5.0202 25.4398C5.23372 27.9868 7.38422 30 9.99225 30H19.9821C22.5901 30 24.7406 27.9868 24.9541 25.4398L26.5098 23.8841C27.4554 22.9385 27.9739 21.6879 27.9739 20.3457V17.0819C27.9892 15.4652 27.2114 13.94 25.8845 12.9944ZM27.7299 4.02644C27.3334 5.06355 26.342 6.58871 23.9322 7.4123L23.2612 4.04169V4.02644H27.7299ZM2.2749 4.02644H6.75889V4.04169L6.08781 7.4123C3.67805 6.57346 2.68669 5.0483 2.2749 4.02644ZM25.9912 20.3457C25.9912 21.1388 25.6709 21.9014 25.1066 22.4657L23.2764 24.2959C23.0934 24.4789 22.9866 24.7382 22.9866 24.9975C22.9866 26.6446 21.6445 28.002 19.9821 28.002H9.99225C8.34507 28.002 6.98766 26.6599 6.98766 24.9975C6.98766 24.7382 6.8809 24.4789 6.69788 24.2959L4.86768 22.4657C4.30337 21.9014 3.9831 21.1388 3.9831 20.3457V17.0819C3.9831 16.1057 4.45588 15.1906 5.24897 14.6416L6.56063 13.7112C6.77415 13.5587 6.91141 13.3452 6.95717 13.0859L8.68059 4.43823C8.95512 3.03507 10.1905 2.02847 11.6242 2.02847H18.3044C19.7228 2.02847 20.9582 3.03507 21.248 4.43823L22.9714 13.0859C23.0172 13.3299 23.1697 13.5587 23.3679 13.7112L24.6796 14.6416C25.4727 15.2059 25.9455 16.121 25.9455 17.0819V20.3457H25.9912Z"
      fill="#333333"
    />
    <path
      d="M15.9403 8.67829C15.803 8.28175 15.4217 8.02246 14.9947 8.02246C14.5676 8.02246 14.2016 8.28175 14.0491 8.67829L10.0531 19.8272C9.87011 20.3153 10.1141 20.8644 10.5869 21.0779C11.9901 21.6879 13.4695 22.0082 14.9794 22.0082C16.5046 22.0082 17.984 21.7032 19.3719 21.0779C19.8447 20.8644 20.0887 20.3153 19.9057 19.8272L15.9403 8.67829ZM12.2799 19.5832L15.0099 11.9879L17.74 19.5832C15.9708 20.1475 14.0338 20.1475 12.2799 19.5832Z"
      fill="#333333"
    />
    <path
      d="M19.4939 6.71069C18.9449 6.71069 18.4873 7.15299 18.4873 7.7173V8.31212C18.4873 8.86118 18.9296 9.31872 19.4939 9.31872C20.043 9.31872 20.5005 8.87643 20.5005 8.31212V7.7173C20.5005 7.16824 20.0582 6.71069 19.4939 6.71069Z"
      fill="#333333"
    />
    <path
      d="M11.5025 8.31212V7.7173C11.5025 7.16824 11.0602 6.71069 10.4959 6.71069C9.9468 6.71069 9.48926 7.15299 9.48926 7.7173V8.31212C9.48926 8.86118 9.93155 9.31872 10.4959 9.31872C11.0602 9.31872 11.5025 8.86118 11.5025 8.31212Z"
      fill="#333333"
    />
    <path
      d="M17.5269 23.3502C17.0236 23.884 16.3525 25.1499 17.0236 26.4615C17.2066 26.8123 17.5574 27.0106 17.9082 27.0106C18.0607 27.0106 18.2132 26.9801 18.3657 26.9038C18.8538 26.6446 19.052 26.0497 18.7928 25.5617C18.5945 25.1804 18.9605 24.7534 18.991 24.7229C19.3571 24.3263 19.3418 23.6857 18.93 23.3197C18.5335 22.9232 17.9082 22.9537 17.5269 23.3502Z"
      fill="#333333"
    />
    <path
      d="M11.0753 23.3046C10.6635 23.6706 10.633 24.2959 10.9991 24.7077C11.0143 24.723 11.3956 25.1653 11.1973 25.5466C10.9381 26.0346 11.1363 26.6447 11.6244 26.8887C11.7769 26.965 11.9294 26.9955 12.0819 26.9955C12.448 26.9955 12.7988 26.7972 12.9665 26.4464C13.6376 25.1348 12.9665 23.8841 12.4632 23.3351C12.1124 22.9538 11.4871 22.9385 11.0753 23.3046Z"
      fill="#333333"
    />
  </svg>
);
