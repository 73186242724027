import FooterIcons from './components/FooterIcons';
import FooterLinks from './components/FooterLinks';
import styles from './Footer.module.css';

export default function MainFooter() {
  return (
    <div className={styles.footerMain}>
      <FooterIcons />
      <FooterLinks />
    </div>
  );
}
