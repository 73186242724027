import { deerIcon } from 'src/assets/anonymousIcons/deerIcon';
import { NO_IMAGE, NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';
import ReportPopUp from './ReportPopUp';
import { REPORT_STATUS, REPORT_TYPES, Report, ReportedElement } from 'src/types/Reports';
import InfoIcon from 'images/report/InfoIcon';
import { useEffect, useState } from 'react';
import { GroupsApiService } from 'src/services/groups.service';
import { useToken } from 'src/cookies';
import { ArticlesApiService } from 'src/services/articles.service';
import { ReportsApiService } from 'src/services/report.service';
import { needToUpdateReportStatus } from '../../../../helpers/reports';
import MessageHockPopUp from '../../../../hooks/MessageHockPopUp';
import { ChatService } from '../../../../services/socket.service';
import { REPORT_CHAT } from '../../../../utils/constants';
import styles from '../dashboardStyles.module.css';

type Props = {
  report: Report;
  getReports: () => void;
};

enum STATUS_STYLE {
  NEW = 'newStatusLabel',
  ACTION_TAKEN = 'actionTakenStatusLabel',
  UNRESOLVED = 'unresolvedStatusLabel',
  IGNORED = 'ignored',
  RESOLVED = 'resolved',
}

function ReportElement({ report, getReports }: Props) {
  const [reportedElement, setReportedElement] = useState<ReportedElement>({
    type: '',
    name: '',
    image: '',
    ownerName: '',
    ownerId: '',
  });
  useEffect(() => {
    //TODO this logic need more testing
    if (needToUpdateReportStatus({ object: report })) {
      ReportsApiService.updateReport(token, {
        ...report,
        // updated_at:report.updated_at, //formatISO(new Date(), { format: 'extended' })
        report_author: report.report_author.id!,
        report_status: REPORT_STATUS.UNRESOLVED,
      });
    }
  }, []);

  const status =
    report.report_status.toLowerCase()[0].toUpperCase() +
    report.report_status.slice(1).toLowerCase().split('_').join(' ');
  const [currentStatusStyle, setCurrentStatusStyle] = useState<string>('');

  const { token } = useToken();
  useEffect(() => {
    switch (report.report_object_type) {
      case REPORT_TYPES.GROUP:
        GroupsApiService.getFullGroupById(token, report.report_object_id).then((res) => {
          setReportedElement({
            type: 'group',
            name: res.data?.name ?? 'No name',
            image: res.data?.group_photo ?? NO_IMAGE,
            ownerName: res.data?.admin?.username ?? 'No name',
            ownerId: res.data?.admin?.id ?? '',
          });
        });
        break;
      case REPORT_TYPES.ARTICLE || REPORT_TYPES.QUESTION:
        ArticlesApiService.getPaperById(token, report.report_object_id).then((res) =>
          setReportedElement({
            type: 'paper',
            name: res.data?.title ?? 'No name',
            image: res.data?.image ?? NO_IMAGE,
            ownerName: res.data?.paper_author?.username ?? 'No name',
            ownerId: res.data?.paper_author?.id ?? '',
          }),
        );
        break;

      case REPORT_TYPES.COMMENT:
        ArticlesApiService.getCommentById(token, report.report_object_id).then(({ data }) => {
          if (data) {
            setReportedElement({
              type: 'comment',
              name: data?.description ?? 'No name',
              image: data?.author?.profile.image_url ?? NO_IMAGE,
              ownerName: data?.author?.username ?? 'No name',
              ownerId: data?.author?.id ?? '',
            });
          }
        });
        break;
    }
    switch (report.report_status) {
      case REPORT_STATUS.NEW:
        setCurrentStatusStyle(STATUS_STYLE.NEW);
        break;
      case REPORT_STATUS.ACTION_TAKEN:
        setCurrentStatusStyle(STATUS_STYLE.ACTION_TAKEN);
        break;
      case REPORT_STATUS.UNRESOLVED:
        setCurrentStatusStyle(STATUS_STYLE.UNRESOLVED);
        break;
      case REPORT_STATUS.IGNORED:
        setCurrentStatusStyle(STATUS_STYLE.IGNORED);
        break;
      case REPORT_STATUS.RESOLVED:
        setCurrentStatusStyle(STATUS_STYLE.RESOLVED);
        break;
    }
  }, [token, report]);

  const updateStatus = (status: REPORT_STATUS) => {
    ReportsApiService.updateReport(token, {
      ...report,
      report_author: report.report_author.id!,
      report_status: status,
    })
      .then((r) => {
        if (r.ok) {
          MessageHockPopUp({
            message: 'Report status updated successfully!',
            timeOut: 3000,
            type: 'success',
            size: 'small',
          });
          getReports();
        }
      })
      .catch(() => {
        MessageHockPopUp({
          message: 'Something went wrong!',
          timeOut: 3000,
          type: 'error',
          size: 'small',
        });
      });
  };

  const onChat = () => {
    if (!reportedElement.ownerId) {
      MessageHockPopUp({
        message: `This ${reportedElement.type} does not have an owner id!`,
        timeOut: 3000,
        type: 'error',
        size: 'small',
      });
    } else {
      ChatService.initiateAConversation({
        token,
        userIds: [reportedElement.ownerId],
        should_targets_accept: true,
        should_send_notification: true,
        conversationName: REPORT_CHAT,
        reportId: report.id,
      }).then((res) => {
        if (res.ok) {
          updateStatus(REPORT_STATUS.ACTION_TAKEN);
          MessageHockPopUp({
            message: 'Request was sent!',
            timeOut: 3000,
            type: 'success',
            size: 'small',
          });
        }
      });
    }
  };

  const onDelete = () => {
    ReportsApiService.deleteReport(token, report.id).then((r) => {
      if (r.ok) {
        MessageHockPopUp({
          message: 'Report deleted!',
          timeOut: 3000,
          type: 'success',
          size: 'small',
        });
      } else {
        MessageHockPopUp({
          message: 'Report was not deleted!',
          timeOut: 3000,
          type: 'error',
          size: 'small',
        });
      }
    });
    getReports();
  };
  const onIgnore = () => {
    updateStatus(REPORT_STATUS.IGNORED);
  };

  return (
    <div className={styles.reportElementWrapper}>
      {report.report_author ? (
        <img className={styles.userImage} src={report.report_author.profile?.image_url || NO_USER_AVATAR} alt="" />
      ) : (
        <div className={styles.reportUserImage}>
          <div className={styles.anonymousIcon}>{deerIcon()}</div>
        </div>
      )}
      <div className={styles.reportUserElement}>
        <div className={styles.reportContent}>
          <div className={styles.reportStaticLabel}>From user:</div>
          <div className={styles.reportUserName}>{report.report_author.username || 'No name'}</div>
        </div>
        {/*<div className={styles.statusUpdated}>Status updated: {calcCreatedTime(report.updated_at)}</div>*/}
        <div className={styles.reportRightWrapper}>
          <div className={styles.reportContent}>
            <div className={styles.reportStaticLabel}>Status:</div>
            <div className={styles[currentStatusStyle]}>{status}</div>
          </div>
          <ReportPopUp
            report={report}
            reportedName={reportedElement.name}
            reportedImage={reportedElement.image}
            reportedOwnerName={reportedElement.ownerName}
            onChat={onChat}
            onDelete={onDelete}
            onIgnore={onIgnore}
          >
            <InfoIcon />
          </ReportPopUp>
        </div>
      </div>
    </div>
  );
}

export default ReportElement;
