import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { checkImage } from 'src/helpers/checkImageUrl';
import { clearSelectedPaper } from '../../../../store/articles';
import { Paper } from '../../../../types/Paper';
import ArticleUserInfoBlock from './ArticleUserInfoBlock';
import { useIsMobile, useIsTablet } from '../../../../utils/styles.utils';
import { ArticleAvatar } from '../../../layout/ImagesTemplate';
import styles from './style.module.css';

export default function ArticlePaperCard({ article }: { article: Paper }) {
  const [isImgAvailable, setIsImgAvailable] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const checkImg = async () => {
      if (article.image) {
        const isAvailable = (await checkImage(article.image)) as boolean;
        setIsImgAvailable(isAvailable);
      } else {
        setIsImgAvailable(true);
      }
    };

    checkImg();
  }, []);

  return (
    <div
      className={isMobile || isTablet ? styles.paperCardMobile : styles.paperCard}
      onClick={() => {
        dispatch(clearSelectedPaper());
        history.push(`/article${article.id}`);
      }}
    >
      <div style={{ border: '1px solid #2e2e2e', borderRadius: '12px' }}>
        <ArticleAvatar url={isImgAvailable ? article.image : null} width={100} height={200} />
      </div>
      <span className={styles.articleTitle}>{article.title}</span>
      <div className={styles.articleDescription}>{article.description}</div>
      <div
        onClick={(event) => {
          event.stopPropagation();
          const regex = /^\/user\//;
          const isUserPage = regex.test(location.pathname);
          if (!isUserPage) {
            history.push(`/user/${article.paper_author?.id}`);
          }
        }}
      >
        <ArticleUserInfoBlock article={article} />
      </div>
    </div>
  );
}
