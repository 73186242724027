import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';

const startApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        {/* @ts-ignore*/}
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
    reportWebVitals();

};
// @ts-ignore
if (window.cordova) {
  document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}
