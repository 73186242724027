import { format } from 'date-fns';

export const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

export const calcCreatedTime = (created: string) => {
  if (created) {
    const startTime = new Date(created);
    const endTime = new Date(Date.now());
    const difference = endTime.getTime() - startTime.getTime();
    const resultInMinutes = Math.round(difference / 60000);
    if (resultInMinutes <= 60) {
      return Math.round(resultInMinutes) === 0 ? 'just now' : `about ${Math.round(resultInMinutes)} min ago`;
    } else if (resultInMinutes <= 1440) {
      const hours = resultInMinutes / 60;
      return `about ${Math.round(hours) === 1 ? Math.round(hours) + ' hour ago' : Math.round(hours) + ' hours ago'}`;
    } else if (resultInMinutes <= 43200) {
      // Less than a month
      const days = resultInMinutes / 1440;
      return Math.round(days) === 1 ? `${Math.round(days)} day ago` : `${Math.round(days)} days ago`;
    } else if (resultInMinutes <= 525600) {
      // Less than a year
      const months = resultInMinutes / 43200;
      return Math.round(months) === 1 ? `${Math.round(months)} month ago` : `${Math.round(months)} months ago`;
    } else {
      const years = resultInMinutes / 525600;
      return Math.round(years) === 1 ? `${Math.round(years)} year ago` : `${Math.round(years)} years ago`;
    }
  }
  return undefined;
};
export const calcJoinedTime = (joined?: string) => {
  if (joined) {
    const startTime = new Date(joined);
    return format(new Date(startTime.toDateString()), 'dd MMM yyyy');
  }
  return undefined;
};
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const days = [
  {
    name: 'Mo',
  },
  {
    name: 'Tu',
  },
  {
    name: 'We',
  },
  {
    name: 'Th',
  },
  {
    name: 'Fr',
  },
  {
    name: 'Sa',
  },
  {
    name: 'Su',
  },
];
export const weekdaysLong = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const currentDate = new Date();

export function getMondayOfCurrentWeek() {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // adjust when day is Sunday
  return new Date(today.setDate(diff));
}

export const getDaysArray = function (start: string, end: string) {
  let arr = [];
  let dt = new Date(
    Date.UTC(
      parseInt(start.substring(0, 4)), // year
      parseInt(start.substring(5, 7)) - 1, // month (zero-based)
      parseInt(start.substring(8, 10)), // day
      0,
      0,
      0,
      0, // hour, minute, second, millisecond (all zero)
    ),
  );

  for (
    arr.length;
    dt <=
    new Date(
      Date.UTC(
        parseInt(end.substring(0, 4)), // year
        parseInt(end.substring(5, 7)) - 1, // month (zero-based)
        parseInt(end.substring(8, 10)), // day
        0,
        0,
        0,
        0, // hour, minute, second, millisecond (all zero)
      ),
    );
    dt.setUTCDate(dt.getUTCDate() + 1)
  ) {
    arr.push(dt.toISOString().substring(0, 10));
  }
  return arr;
};

export const thisWeekFromToDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - ((date.getDay() + 6) % 7));
  const from = format(new Date(date.toDateString()), 'yyyy-MM-dd');
  const mondayDate = date.getDate();
  date.setDate(mondayDate + 6);
  const to = format(new Date(date.toDateString()), 'yyyy-MM-dd');
  return { from, to };
};

export const todayFromToDate = () => {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  const from = date.toISOString();
  date.setUTCHours(23, 59, 59, 999);
  const to = date.toISOString();
  return { from, to };
};

export const thisWeekFromToDateISO = () => {
  const today = new Date();
  const monday = new Date(today);
  monday.setDate(today.getDate() - ((today.getDay() + 6) % 7));
  monday.setHours(0, 0, 0, 0);
  const from = format(monday, "yyyy-MM-dd'T'HH:mm:ss");

  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);
  sunday.setHours(23, 59, 59, 999);
  const to = format(sunday, "yyyy-MM-dd'T'HH:mm:ss");

  return { from, to };
};

export const thisMonthFromToDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();

  const from = new Date(year, month, 1);
  const to = new Date(year, month + 1, 1);
  to.setHours(0, 0, 0);

  const fromDate = format(from, "yyyy-MM-dd'T'HH:mm:ss");
  const toDate = format(to, "yyyy-MM-dd'T'HH:mm:ss");

  return { from: fromDate, to: toDate };
};

export class _MyDate {
  from = thisWeekFromToDate().from;
  to = thisWeekFromToDate().to;

  getMonthFromTo = (d: string) => {
    const now = new Date(d);
    now.setHours(currentDate.getHours());
    now.setMinutes(currentDate.getMinutes());

    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return { firstDay, lastDay };
  };
  getSelectedMonth = (d: string) => {
    const date = new Date(d);
    return `${monthNames[date.getMonth()]}-${date.getFullYear()}`;
  };

  getCurrentPeriod = (d: string) => {
    const date = new Date(d);
    date.setHours(currentDate.getHours());
    date.setMinutes(currentDate.getMinutes());
    const month = monthNames[date.getMonth()];
    date.setDate(date.getDate() - ((date.getDay() + 6) % 7));
    this.from = date.toDateString();
    const mondayDate = date.getDate();
    const prevMonth = monthNames[date.getMonth()];
    date.setDate(mondayDate + 6);
    this.to = date.toDateString();
    const sunday = date.getDate();
    if (mondayDate < sunday) {
      return `${month} ${mondayDate}-${sunday}`;
    } else {
      return `${prevMonth} ${mondayDate} - ${monthNames[date.getMonth()]} ${sunday}`;
    }
  };

  getFromTo = (): { from: string; to: string } => {
    return { from: this.from, to: this.to };
  };
}

export const MyDate = new _MyDate();

export function formatDate(date: Date): string {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return 'Today';
  } else if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    return 'Yesterday';
  } else {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
}

export function adjustToHour23(dateTimeString: string) {
  const parts = dateTimeString.split('T');
  if (parts.length === 2) {
    parts[1] = '23' + parts[1].substring(2);
    return parts.join('T');
  }
  return dateTimeString;
}

//This function should replace currentDateInIsoFormat
export const _currentDateInIsoFormat = ({ date, periodOfDay }: { date: string; periodOfDay: 'start' | 'end' }) => {
  const now = new Date(date);
  const timezoneOffset = now.getTimezoneOffset();
  const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
  const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
  const timezoneSign = timezoneOffset >= 0 ? '-' : '+';
  const timezoneString =
    timezoneSign +
    timezoneOffsetHours.toString().padStart(2, '0') +
    ':' +
    timezoneOffsetMinutes.toString().padStart(2, '0');
  const newDateFormatted = `${date}T${periodOfDay === 'start' ? '00:00:00.000' : '23:59:59.000'}${timezoneString}`;

  return newDateFormatted;
};

export const currentDateInIsoFormat = ({
  date,
  end,
  currentTimezone,
  utcZero,
}: {
  date?: string;
  end?: boolean;
  currentTimezone?: boolean;
  utcZero?: boolean;
}) => {
  const now = date ? new Date(date) : new Date();
  if (end) {
    now.setHours(23);
    now.setMinutes(59);
    now.setSeconds(59);
  }
  // If utcZero flag is set, simply return the ISO string without the timezone offset
  if (utcZero) {
    return now.toISOString();
  }
  const timezoneOffset = now.getTimezoneOffset();
  const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
  const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
  const timezoneSign = timezoneOffset >= 0 ? '-' : '+';
  const timezoneString =
    timezoneSign +
    timezoneOffsetHours.toString().padStart(2, '0') +
    ':' +
    timezoneOffsetMinutes.toString().padStart(2, '0');

  const sign = timezoneString.charAt(0);
  const hours = parseInt(timezoneString.substr(1, 2));
  const minutes = parseInt(timezoneString.substr(4, 2));

  //this is to make possible to keep always our time the same as device time + timezone
  const utcDate = new Date(
    !!currentTimezone
      ? Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours() + (sign === '+' ? hours : -hours),
          now.getUTCMinutes() + (sign === '+' ? minutes : -minutes),
          now.getUTCSeconds(),
        )
      : Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds(),
        ),
  );
  return utcDate.toISOString().slice(0, -1) + timezoneString;
};

export function countDatesByHour(dates: Array<string>): {
  maxPerHour: number;
  hourData: Array<{ x: number; y: number }>;
} {
  const hourCounts = new Array(24).fill(0);
  let maxPerHour = 0;

  dates.forEach((dateString) => {
    const date = new Date(dateString);
    const hour = date.getHours();
    hourCounts[hour]++;
  });

  const hourData = hourCounts.map((count, hour) => {
    if (count > maxPerHour) maxPerHour = count;
    return {
      x: hour,
      y: count,
    };
  });

  return { hourData, maxPerHour };
}

export function countDatesByWeekDays(dates: Array<string>): {
  maxPerDay: number;
  weekdayCounts: Array<{ x: number; y: number }>;
} {
  const weekdayCounts = new Array(7).fill(0);
  let maxPerDay = 0;

  dates.forEach((dateString) => {
    const date = new Date(dateString);
    const weekday = date.getDay() - 1;
    weekdayCounts[weekday]++;
  });

  const weekdayData = weekdayCounts.map((count, weekday) => {
    if (count > maxPerDay) maxPerDay = count;
    return {
      x: weekday,
      y: count,
    };
  });

  return { maxPerDay, weekdayCounts: weekdayData };
}

export function getDaysInCurrentMonth() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;

  return new Date(year, month, 0).getDate();
}

export function countDatesByMonthDays(dates: Array<string>): {
  maxPerDay: number;
  monthData: Array<{ x: number; y: number }>;
} {
  const monthCounts = new Array(getDaysInCurrentMonth()).fill(0);
  let maxPerDay = 0;

  dates.forEach((dateString) => {
    const date = new Date(dateString);
    const monthDay = date.getDate();
    monthCounts[monthDay - 1]++;
  });

  const monthData = monthCounts.map((count, monthDay) => {
    if (count > maxPerDay) maxPerDay = count;
    return {
      x: monthDay + 1,
      y: count,
    };
  });

  return { maxPerDay, monthData };
}
