import { IComment } from '../../../types/Paper';
import { normalizedCommentsStructure, normalizedParagraphCommentsStructure } from '../../../helpers/comments';
import MobileCommentBlock from './components/MobileCommentBlock';
import style from './MobileArticleViewStyles.module.css';

export default function ArticleComments({
  comments,
  isPhCtsBlock,
  paragraphId,
}: {
  comments: Array<IComment>;
  isPhCtsBlock?: boolean;
  paragraphId?: string;
}) {
  let normalizedComments: IComment[] = normalizedCommentsStructure(comments || []);
  const paragraphComments: IComment[] = normalizedParagraphCommentsStructure(comments || []);
  const thisParagraphComments = paragraphComments.filter((pCom) => pCom.paragraphId === paragraphId);

  return (
    <div className={style.commentsMobileMain}>
      {/*Paragraph content*/}
      {!isPhCtsBlock ? (
        <div>
          {!!normalizedComments.length &&
            normalizedComments.map((comment) => {
              return (
                <div key={comment.id}>
                  <MobileCommentBlock comment={comment} key={comment.id} />
                </div>
              );
            })}
        </div>
      ) : (
        <div>
          {thisParagraphComments.map((pComment) => {
            return <MobileCommentBlock comment={pComment} key={pComment.id} isParagraph={true} />;
          })}
        </div>
      )}
    </div>
  );
}