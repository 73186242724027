export const imageLogo = (
  color1: string,
  color2: string,
  color3: string,
  color4: string,
  color5: string,
  color6: string,
  width = '138',
  heigth = '138',
) => (
  <svg width={width} height={heigth} viewBox="0 0 138 138" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_458_3555)">
      <path
        d="M104.184 69.531L133.355 52.8616C136.828 50.4307 138.217 47.9997 138.217 42.7906V16.7446C138.217 13.2716 134.397 10.1455 130.924 11.8827L81.6105 40.3597C76.4014 43.1379 69.4558 49.0417 69.4558 56.3345V108.079C69.4558 96.471 77.7905 82.2326 104.184 69.531Z"
        fill="url(#paint0_linear_458_3555)"
      />
      <path
        d="M34.7279 69.531V102.175C34.7279 106.343 34.7279 107.037 38.8952 110.857L61.4684 124.054C64.6786 125.683 67.0724 126.536 69.4558 126.534V108.079V56.3345C69.4607 49.0699 63.2048 43.8325 57.301 40.3597L7.64014 11.8827C25.0041 21.8397 34.7279 34.8032 34.7279 69.531Z"
        fill="url(#paint1_linear_458_3555)"
      />
      <path
        d="M7.64014 11.8827C4.16735 10.4937 0 12.9248 0 16.7447V43.8325C0.347279 47.6526 2.08367 49.7362 4.16735 51.4726L34.7279 69.531C34.7279 34.8032 25.0041 21.8397 7.64014 11.8827Z"
        fill="url(#paint2_linear_458_3555)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_458_3555"
        x1="139.606"
        y1="27.7098"
        x2="68.7612"
        y2="74.5925"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={color1} />
        <stop offset="1" stopColor={color2} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_458_3555"
        x1="69.1085"
        y1="11.3877"
        x2="69.1085"
        y2="126.534"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.145095" stopColor={color3} />
        <stop offset="1" stopColor={color4} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_458_3555"
        x1="30.5605"
        y1="35.3499"
        x2="-2.65309e-06"
        y2="48.5465"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={color5} />
        <stop offset="1" stopColor={color6} />
      </linearGradient>
      <clipPath id="clip0_458_3555">
        <rect width="138" height="138" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
