import React, { useRef, useState } from 'react';
import { ChatListPreview } from '../../types/User';
import MobileMessagesList from './MobileMessagesList';
import { PlaneIcon } from 'images/PlaneIcon';
import ArrowBackButton from '../mobile/Buttons/ArrowBackButton';
import { dots3Icon } from 'images/dots3Icon';
import CostumePopup from '../layout/CostumePopUp';
import { NO_USER_AVATAR } from '../../utils/constantImagesUrl';
import { getFirstUserFromUsersList } from '../../helpers/arrays';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../../store/auth';
import { userNameFromAllParams } from '../../helpers/userName';
import TrashcanIcon from 'images/trashbinIcon';
import { emergencyIcon } from 'images/emergencyIcon';
import { useToken } from '../../cookies';
import { selectOpenedChat } from '../../store/chats/selector';
import { deleteConversation, setOpenedChat } from '../../store/chats';
import { getAnonymousImage } from '../../helpers/anonymousGenerator';
import styles from './MobileChatsStyles.module.css';

function MobileChatPage({ sendMessage }: { sendMessage: (message: string, chatId: string) => void }) {
  const openedChat = useSelector(selectOpenedChat) as ChatListPreview;
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const ref = useRef(null) as any;
  const { token } = useToken();
  const myUserId = useSelector(selectUserId);
  const user = getFirstUserFromUsersList(openedChat.users, myUserId, openedChat.has_to_accept);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const report = () => {
    console.log('RePoRt');
  };

  const deleteChat = () => {
    dispatch(deleteConversation({ token, chatId: openedChat.id, isPeopleChat: true }));
    setShowPopup(false);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowPopup(false);
    }
  };
  const isIncognito = openedChat.emergency?.is_emergency_incognito;

  return (
      <div className={styles.mobileChatPage}>
        <div className={styles.topChatStuff}>
          <div className={styles.conversationHead}>
            <ArrowBackButton
                setStep={() => {
                  dispatch(setOpenedChat(null));
                }}
            />
            <div className={styles.chatActions}>
              <div className={styles.userData}>
                {isIncognito && openedChat.name ? (
                    getAnonymousImage(openedChat.name)
                ) : (
                    <img
                        className={styles.userImg}
                        src={openedChat.group?.group_photo || user?.profile?.image_url || NO_USER_AVATAR}
                        alt=""
                    />
                )}

                <div>
                  {openedChat.group?.name ||
                      (isIncognito && openedChat.name
                          ? openedChat.name
                          : userNameFromAllParams(user?.username, user?.profile?.first_name, user?.profile?.last_name))}
                </div>
              </div>

              {!openedChat.group && (
                  <div className={styles.dots3} onClick={() => setShowPopup(true)}>
                    {dots3Icon('white', '30', '30')}
                  </div>
              )}
              {showPopup && (
                  <CostumePopup style={{ top: '120px', right: '11px', width: '26%',padding:'8px' }}>
                    <div ref={ref}>
                      <div onClick={report} className={styles.popUpRow}>
                        <div>{emergencyIcon()}</div>
                        <span className={styles.rowText}>Report</span>
                      </div>
                      <div onClick={deleteChat} className={styles.popUpRow}>
                        <div>
                          <TrashcanIcon color="#fff" />
                        </div>
                        <span className={styles.rowText}>Delete chat</span>
                      </div>
                    </div>
                  </CostumePopup>
              )}
            </div>
          </div>
          <MobileMessagesList chat={openedChat} />
        </div>

        <div className={styles.inputBlock}>
          <input
              className={styles.inputMobChatMsg}
              type="text"
              value={message}
              placeholder={'your message...'}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
          />
          <div
              onClick={() => {
                sendMessage(message, openedChat.id);
                setMessage('');
              }}
              className={styles.sendMsgButton}
          >
            {PlaneIcon('#FFC700')}
          </div>
        </div>
      </div>
  );
}

export default MobileChatPage;