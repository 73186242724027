import React from 'react';
import { NewlineText } from './textHelper';
import styles from './styles.module.css';

function VextPaperHelp() {
  const text =
    'There are several ways you can get help on VEXT. \n' +
    '\n' +
    'First, by creating a group and inviting your peers and colleagues you can start building small, hyper local communities wherein you can support one another.\n' +
    '\n' +
    '    Second, by posting and asking a question in the groups you create or are a part of. (And by answering posts shared by group members.)\n' +
    '\n' +
    'Third, by expressing your mood each day in your group. If a user posts a negative mood for several consecutive days in a row, VEXT will send an alert to the group encouraging group members to encourage their peers. To reach out.\n' +
    '\n' +
    "    Fourth, if a user selects a negative mood for even more consecutive days, VEXT will prompt the teacher/coach/counselor that there are members of a specific group who may be struggling with how they are feeling. VEXT will attempt to prompt these folks to reach out to the group. These adults won't know it is YOU who was feeling bad unless you want them to know it was you. \n" +
    '\n' +
    'Fifth, if a user needs to connect with a counselor there is a ""Help"" button on every user profile page. It is the triangle button at the bottom center of your profile page. Press that button to be connected to resources that can help you if you\'re feeling depressed or just want to connect with a counselor for whatever reason. SOME - not all - counselors will be able to speak to you as an ""incognito"" user. Many will not. \n' +
    '\n' +
    'As well, once you press the help button VEXT will ask you to confirm your location. This is a requirement that many counselors and therapists are required to collect before they can speak to someone. VEXT will secure your consent along the way. ';
  return <div className={styles.textBlock}>{NewlineText(text)}</div>;
}

export default VextPaperHelp;

