export const blueLogo = () => (
  <svg width="48" height="42" viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.2378 21.1847L46.3844 15.3867C47.5923 14.5411 48.0755 13.6956 48.0755 11.8837V2.82422C48.0755 1.61619 46.7468 0.528856 45.5388 1.13312L28.3863 11.0382C26.5744 12.0045 24.1585 14.058 24.1585 16.5946V34.5927C24.1585 30.5551 27.0576 25.6026 36.2378 21.1847Z"
      fill="url(#paint0_linear_2907_7444)"
    />
    <path
      d="M12.0793 21.1847V32.5392C12.0793 33.9888 12.0793 34.2304 13.5288 35.559L21.3803 40.1491C22.4969 40.716 23.3295 41.0124 24.1585 41.012V34.5927V16.5946C24.1602 14.0678 21.9843 12.2461 19.9308 11.0382L2.65744 1.13312C8.69707 4.59642 12.0793 9.10548 12.0793 21.1847Z"
      fill="url(#paint1_linear_2907_7444)"
    />
    <path
      d="M2.65744 1.13312C1.44951 0.649991 0 1.49558 0 2.82426V12.2461C0.120793 13.5748 0.724756 14.2996 1.44951 14.9035L12.0793 21.1847C12.0793 9.10548 8.69707 4.59642 2.65744 1.13312Z"
      fill="url(#paint2_linear_2907_7444)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2907_7444"
        x1="48.5587"
        y1="6.63819"
        x2="23.9169"
        y2="22.9452"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1D00D1" />
        <stop offset="1" stopColor="#7681EB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2907_7444"
        x1="24.0377"
        y1="0.960937"
        x2="24.0377"
        y2="41.012"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.145095" stopColor="#A683FF" />
        <stop offset="1" stopColor="#2E57DF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2907_7444"
        x1="10.6298"
        y1="9.29563"
        x2="-8.88213e-07"
        y2="13.8858"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#551DD3" />
        <stop offset="1" stopColor="#C5B5FF" />
      </linearGradient>
    </defs>
  </svg>
);
