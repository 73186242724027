export const inviteFriendsIcon = (color = '#EDEFF0') => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill='none' xmlns="http://www.w3.org/2000/svg">
    <rect
      x="4.34912"
      y="0.88916"
      width="6.09499"
      height="6.09499"
      rx="3.0475"
      stroke={color}
      strokeWidth="1.37649"
    />
    <path
      d="M13.8115 12.478C14.554 13.8048 13.276 15.1109 11.7555 15.1109C10.5516 15.1109 9.0391 15.1109 7.39647 15.1109C5.75384 15.1109 4.24129 15.1109 3.03739 15.1109C1.51697 15.1109 0.238958 13.8048 0.981486 12.478C2.12765 10.43 4.56939 9.01587 7.39647 9.01587C10.2235 9.01587 12.6653 10.43 13.8115 12.478Z"
      stroke={color}
      strokeWidth="1.37649"
    />
    <path
      d="M11.6782 6.47388H15.7141"
      stroke={color}
      strokeWidth="1.38"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6963 8.49194L13.6963 4.45607"
      stroke={color}
      strokeWidth="1.38"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);