import React, { useRef, useState } from 'react';
import { sliceText } from '../../helpers/textHelper';
import RowPopUp from './RowPopUp';
import { ChatListPreview } from '../../types/User';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../../store/auth';
import { getFirstUserFromUsersList } from '../../helpers/arrays';
import { NO_USER_AVATAR } from '../../utils/constantImagesUrl';
import { calcCreatedTime } from '../../helpers/date';
import { userNameFromAllParams } from '../../helpers/userName';
import { clearMessages, getPeopleConversations, setOpenedChat } from '../../store/chats';
import { selectPeopleChatsHasNext } from '../../store/chats/selector';
import { useToken } from '../../cookies';
import styles from './MobileChatsStyles.module.css';
import { getAnonymousImage } from '../../helpers/anonymousGenerator';

function MobilePeopleChat({ chats }: { chats: Array<ChatListPreview> }) {
  const [scrollDelay, setScrollDelay] = useState(false);
  const [page, setPage] = useState(2);
  const listInnerRef = useRef(null);
  const dispatch = useDispatch();
  const myUserId = useSelector(selectUserId);
  const hasNext = useSelector(selectPeopleChatsHasNext);
  const { token } = useToken();

  const onScroll = () => {
    if (listInnerRef.current && !scrollDelay && hasNext) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight + 1 > scrollHeight) {
        setScrollDelay(true);
        setTimeout(() => {
          dispatch(getPeopleConversations({ token, page }));
          setPage((page) => page + 1);
          setScrollDelay(false);
        }, 500);
      }
    }
  };

  return (
    <div onScroll={() => onScroll()} ref={listInnerRef} className={styles.mobileChatList}>
      {chats.map((chat) => {
        const isIncognito = chat.emergency?.is_emergency_incognito;

        {
          if (myUserId !== chat.author && !chat.last_message) return null;
        }
        const user = getFirstUserFromUsersList(chat.users, myUserId, chat.has_to_accept);

        return (
          <div
            key={chat.id}
            className={styles.mobChatRow}
            onClick={() => {
              dispatch(clearMessages());
              dispatch(setOpenedChat(chat));
            }}
          >
            {isIncognito && chat.name ? (
              getAnonymousImage(chat.name)
            ) : (
              <img className={styles.userImg} src={user?.profile?.image_url || NO_USER_AVATAR} alt="" />
            )}

            <div className={styles.middleBlock}>
              <div className={styles.nameAndTime}>
                {isIncognito && chat.name
                  ? chat.name
                  : sliceText(userNameFromAllParams(user?.username,user?.profile?.first_name, user?.profile?.last_name), 21)}
                <div className={styles.creationTime}>
                  {chat.has_unseen_message && <span className={styles.unreadMsgDot}></span>}
                  {calcCreatedTime(chat.last_message?.creation_date || chat.creation_date)}
                </div>
              </div>
              <div className={styles.previewMessage}>
                {sliceText(chat.last_message?.message || 'No messages yet', 110)}
              </div>
            </div>
            <RowPopUp chat={chat} />
          </div>
        );
      })}
    </div>
  );
}

export default MobilePeopleChat;
