import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmergencyService } from '../../services/emergencies.service';
import { countDatesByHour, countDatesByMonthDays, countDatesByWeekDays } from '../../helpers/date';

export const DASHBOARD_REDUCER_NAME = 'dashboard';

type InitialStateType = {
  error: string | null;

  emergencies: Array<{ x: number; y: number }>;
  emergenciesAmount: number;
  maxEmergencyPerHour: number;
  emergencyPercent: number;

  outliers: Array<{ x: number; y: number }>;
  outliersAmount: number;
  maxOutliersPerHour: number;
  outliersPercent: number;

  rolesAndUsers: { [key: string]: number };
};

type PeriodType = 'day' | 'week' | 'month';

const initialState: InitialStateType = {
  error: null,

  emergencies: [],
  emergenciesAmount: 0,
  maxEmergencyPerHour: 0,
  emergencyPercent: 0,

  outliers: [],
  outliersAmount: 0,
  maxOutliersPerHour: 0,
  outliersPercent: 0,

  rolesAndUsers: {},
};

export const getDashboardEmergencies = createAsyncThunk(
  `${DASHBOARD_REDUCER_NAME}/getEmergencies`,
  async ({
    token,
    periodType,
    from,
    to,
    isOutliers,
  }: {
    token: string;
    periodType: PeriodType;
    from: string;
    to: string;
    isOutliers: boolean;
  }) => {
    const response = await EmergencyService.getDashBoardEmergencies(token, from, to, isOutliers);
    if (!response.data) throw new Error('No data');
    return { data: response.data.data, periodType, percent: response.data.change, isOutliers };
  },
);

export const getRolesAndUsers = createAsyncThunk(
  `${DASHBOARD_REDUCER_NAME}/getRolesAndUsers`,
  async ({ token }: { token: string }) => {
    const response = await EmergencyService.getDashBoardRolesAndUsers(token);
    if (!response.data) throw new Error('No data');
    return { data: response.data };
  },
);

const dashboardSlice = createSlice({
  name: DASHBOARD_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getDashboardEmergencies.fulfilled,
      (
        state,
        {
          payload: { data, periodType, percent, isOutliers },
        }: PayloadAction<{ data: Array<string>; periodType: PeriodType; percent: number; isOutliers: boolean }>,
      ) => {
        state.error = null;
        if (!isOutliers) {
          if (periodType === 'day') {
            const _countDatesByHour = countDatesByHour(data);
            state.maxEmergencyPerHour = _countDatesByHour.maxPerHour;
            state.emergencies = _countDatesByHour.hourData;
          }
          if (periodType === 'week') {
            const _countDatesByWeekDays = countDatesByWeekDays(data);
            state.maxEmergencyPerHour = _countDatesByWeekDays.maxPerDay;
            state.emergencies = _countDatesByWeekDays.weekdayCounts;
          }

          if (periodType === 'month') {
            const _countDatesByMonthDays = countDatesByMonthDays(data);
            state.maxEmergencyPerHour = _countDatesByMonthDays.maxPerDay;
            state.emergencies = _countDatesByMonthDays.monthData;
          }
          state.emergencyPercent = percent;
          state.emergenciesAmount = data.length;
        } else {
          if (periodType === 'day') {
            const _countDatesByHour = countDatesByHour(data);
            state.maxOutliersPerHour = _countDatesByHour.maxPerHour;
            state.outliers = _countDatesByHour.hourData;
          }
          if (periodType === 'week') {
            const _countDatesByWeekDays = countDatesByWeekDays(data);
            state.maxOutliersPerHour = _countDatesByWeekDays.maxPerDay;
            state.outliers = _countDatesByWeekDays.weekdayCounts;
          }

          if (periodType === 'month') {
            const _countDatesByMonthDays = countDatesByMonthDays(data);
            state.maxOutliersPerHour = _countDatesByMonthDays.maxPerDay;
            state.outliers = _countDatesByMonthDays.monthData;
          }
          state.outliersPercent = percent;
          state.outliersAmount = data.length;
        }
      },
    );
    builder.addCase(
      getRolesAndUsers.fulfilled,
      (state, { payload: { data } }: PayloadAction<{ data: { [key: string]: number } }>) => {
        state.rolesAndUsers = data;
        state.error = null;
      },
    );
  },
});

export default dashboardSlice.reducer