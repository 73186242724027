import { Paper } from '../../../types/Paper';
import ArticlePaperCard from './components/ArticlePaperCard';
import ArticleQuestionCard from './components/ArticleQuestionCard';
import { useIsMobile } from '../../../utils/styles.utils';
import MobileQuestionCard from './components/MobileQuestionCard';

export default function ArticleCard({ article }: { article: Paper }) {
  const isMobile = useIsMobile();

  return article.type === 'ARTICLE' ? (
    <ArticlePaperCard article={article} />
  ) : isMobile ? (
      <MobileQuestionCard article={article}/>
  ) : (
    <ArticleQuestionCard article={article} />
  );
}
