import { ApiResponse } from 'apisauce';

import { _RestApiService } from './api.service';

import { currentDateInIsoFormat } from '../helpers/date';

const statisticsApi = new _RestApiService(window._env_.REACT_APP_BACKEND_URL || '');

export class _StatisticsApiService {
  getAllFilteredGroups(
    token: string,
    module: string,
    page: number,
    search_param?: string,
    sort_by?: string,
    moods?: Array<number>,
    tags?: Array<string>,
  ): Promise<ApiResponse<any>> {
    return statisticsApi.get(
      `/admin/search/${module}/`,
      { page, page_size: 20, search_param, sort_by, moods, tags },
      statisticsApi.getAuthenticateHeader(token),
    );
  }

  getDetailedMoodsStatistics(token: string, from: string, to: string, groupId?: string): Promise<ApiResponse<any>> {
    return statisticsApi.get(
      `/admin/group/mood/`,
      {
        group_id: groupId,
        from: currentDateInIsoFormat({ date: from, utcZero: false }),
        to: currentDateInIsoFormat({ date: to, end: true }),
      },
      statisticsApi.getAuthenticateHeader(token),
    );
  }

  getPeopleWithBadMood(token: string, groupId: string, page: number): Promise<ApiResponse<any>> {
    return statisticsApi.get(
      `/admin/group/people/`,
      { group_id: groupId, token, page_size: 4, page },
      statisticsApi.getAuthenticateHeader(token),
    );
  }

  createUser({
    token,
    email,
    username,
    major,
    recoverUrl,
    is_staff,
  }: {
    token: string;
    email: string;
    username: string;
    major: string;
    recoverUrl: string;
    is_staff?: boolean;
  }): Promise<ApiResponse<any>> {
    return statisticsApi.post(
      `admin/users/`,
      {
        email,
        username,
        major,
        recover_url: recoverUrl,
        is_staff,
      },
      statisticsApi.getAuthenticateHeader(token),
    );
  }

  recoverUser({ email, recoverUrl }: { email: string; recoverUrl: string }): Promise<ApiResponse<any>> {
    return statisticsApi.post(`admin/users/request_reset`, {
      email,
      recover_url: recoverUrl,
    });
  }

  resetPassword({ password, recoverCode }: { password: string; recoverCode: string }): Promise<ApiResponse<any>> {
    return statisticsApi.post(`admin/users/reset`, { password, recover_code: recoverCode });
  }

  getEmailByKey({ key }: { key: string }): Promise<ApiResponse<any>> {
    return statisticsApi.get(`/admin/users/mail_from_reset_request`, { recover_code: key });
  }
}

export const StatisticsApiService = new _StatisticsApiService();
