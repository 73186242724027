import React, {Dispatch, useEffect, useRef, useState} from 'react';
import { User } from '../../../../types/User';
import { UsersApiService } from '../../../../services/users.service';
import { useToken } from '../../../../cookies';
import { NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';
import { dots3Icon } from 'images/dots3Icon';
import { userNameFromAllParams } from '../../../../helpers/userName';
import { emergencyIcon } from 'images/emergencyIcon';
import { getColorByMood } from '../../../../components/Recharts/RechartsGroupMood';
import { getMyEmojiByValue } from '../../../../components/mood/FellingMood';
import { getMoodText } from '../../../../helpers/moodList';
import LinearLoader from '../../../../helpers/LinearLoader';
import CostumePopup from "../../../../components/layout/CostumePopUp";
import {messageTopicIcon} from "images/messageTopicIcon";
import {ChatService} from "../../../../services/socket.service";
import MessageHockPopUp from "../../../../hooks/MessageHockPopUp";
import {generateRandomNicknameId, getAnonymousImage} from "../../../../helpers/anonymousGenerator";
import styles from '../dashboardStyles.module.css';

function MoodEmergencyDetails({
  userId,
  selectedUser,
  setSelectedUser,
  emergenciesByGroupId,
  moodVal,
}: {
  userId: string;
  selectedUser: User | null;
  setSelectedUser: Dispatch<React.SetStateAction<User | null>>;
  emergenciesByGroupId: { [p: string]: boolean } | null;
  moodVal: number | null;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const ref = useRef(null) as any;
  const { token } = useToken();

  useEffect(() => {
    setIsLoading(true);
    UsersApiService.getUser(token, userId).then((res) => {
      setSelectedUser(res.data || null);
      setIsLoading(false);
    });
  }, [userId]);

  //emergenciesByGroupId => { userId:isEmergencyAnonymous } || null

  const requestConnection = () => {
    if(selectedUser){
      ChatService.initiateAConversation({
        token,
        userIds: [selectedUser.id],
        should_targets_accept: true,
        should_send_notification: true,
      }).then((res) => {
        MessageHockPopUp({
          message: 'Request was sent!',
          timeOut: 3000,
          type: 'success',
          size: 'small',
        });
      }).finally(()=>    setShowPopup(false));
    }

  };
const incognitoName=generateRandomNicknameId()

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {isLoading ? (
          <LinearLoader />
      ) : (
        selectedUser !== null && (
          <div className={styles.groupHeader2}>
            {/*{emergenciesByGroupId && emergenciesByGroupId[userId] ? (*/}
            {/*  <img className={styles.userImg} src={NO_USER_AVATAR} alt="" />*/}
            {/*) : (*/}
            {/*  <img className={styles.userImg} src={selectedUser.profile?.image_url || NO_USER_AVATAR} alt="" />*/}
            {/*)}*/}

            <div className={styles.userImg}>{ getAnonymousImage(incognitoName, 'medium')}</div>
            <div className={styles.groupName}>
              {/*{emergenciesByGroupId && emergenciesByGroupId[userId]*/}
              {/*  ? 'Anonymous'*/}
              {/*  : userNameFromAllParams(*/}
              {/*      selectedUser.username,*/}
              {/*      selectedUser.profile?.first_name,*/}
              {/*      selectedUser.profile?.last_name,*/}
              {/*    )}*/}
              {incognitoName}
              {emergenciesByGroupId && userId in emergenciesByGroupId && (
                <div className={styles.emergencyEmoji}>{emergencyIcon('24', '24', 'red')} emergency!</div>
              )}
              {typeof moodVal === 'number' && (
                <div
                  className={styles.groupFeelingMood}
                  style={{
                    border: '1px solid ' + getColorByMood(Math.round(moodVal)),
                  }}
                >
                  <div>{getMyEmojiByValue(Math.round(moodVal), 24)}</div>
                  <div
                    style={{
                      marginLeft: '4px',
                      fontSize: '14px',
                      marginBottom: '1px',
                    }}
                  >
                    {getMoodText(Math.round(moodVal))}
                  </div>
                </div>
              )}
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => setShowPopup(true)}>{dots3Icon('#757575', '28', '24')}</div>

            {showPopup && (
                <CostumePopup className={styles.popUpUser}>
                  <div ref={ref}>
                    <div onClick={requestConnection} className={styles.popUpRow}>
                      <div style={{marginTop:'3px'}}>{messageTopicIcon('white')}</div>
                      <span className={styles.rowText}>Request connection</span>
                    </div>
                  </div>
                </CostumePopup>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default MoodEmergencyDetails;