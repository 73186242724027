import { ApiResponse } from 'apisauce';
import { ArticleResponse, IComment, Paper } from '../types/Paper';
import { _RestApiService } from './api.service';
import { Tag } from '../types/Tag';

const articlesApi = new _RestApiService(window?._env_?.REACT_APP_BACKEND_URL || '');

export class _ArticlesApiService {
  getArticles(token: string, groupId: string, page?: number): Promise<ApiResponse<ArticleResponse>> {
    return articlesApi.get(
      `/api/groups/${groupId}/papers/`,
      {
        page,
        page_size: 12,
      },
      articlesApi.getAuthenticateHeader(token),
    );
  }

  getMyArticles(token: string, userId: string): Promise<ApiResponse<Array<Paper>>> {
    return articlesApi.get(`/api/papers/`, { userId }, articlesApi.getAuthenticateHeader(token));
  }

  searchArticles(token: string, param?: string, tagName?: Array<string>): Promise<ApiResponse<{ data: Array<Paper> }>> {
    return articlesApi.get(`/api/search/`, { param, tagName }, articlesApi.getAuthenticateHeader(token));
  }

  getPaperById(token: string, id: string): Promise<ApiResponse<Paper>> {
    return articlesApi.get('/api/papers/' + id + '/', {}, articlesApi.getAuthenticateHeader(token));
  }

  removeArticle(token: string, id: string): Promise<ApiResponse<undefined>> {
    return articlesApi.delete(`/api/papers/${id}/`, {}, articlesApi.getAuthenticateHeader(token));
  }

  postArticle(data: any, token: string): Promise<ApiResponse<Paper>> {
    return articlesApi.post('/api/papers/', data, articlesApi.getAuthenticateHeaderFormData(token));
  }

  postQuestion(data: any, token: string): Promise<ApiResponse<Paper>> {
    return articlesApi.post('/api/papers/', data, articlesApi.getAuthenticateHeader(token));
  }

  editArticle(data: any, token: string, id: string): Promise<ApiResponse<Paper>> {
    return articlesApi.patch(`/api/papers/${id}/`, data, articlesApi.getAuthenticateHeaderFormData(token));
  }

  createTag(name: string, token: string): Promise<ApiResponse<Tag>> {
    return articlesApi.post('/api/tags/', { name }, articlesApi.getAuthenticateHeader(token));
  }

  removeTag(id: string, token: string): Promise<ApiResponse<Tag>> {
    return articlesApi.delete(`/api/tags/${id}/`, {}, articlesApi.getAuthenticateHeader(token));
  }

  createComment(
    authorId: string,
    comment: string,
    articleId: string,
    token: string,
    repliedToCommentId?: string,
    paragraphId?: string,
    anonymous_name?: string,
  ): Promise<ApiResponse<IComment>> {
    const data = {
      author: authorId,
      description: comment,
      articleId,
      repliedToCommentId,
      paragraphId,
      anonymous_name,
    };
    return articlesApi.post('/api/comments/', data, articlesApi.getAuthenticateHeader(token));
  }

  removeComment(token: string, id: string): Promise<ApiResponse<undefined>> {
    return articlesApi.delete(`/api/comments/${id}/`, {}, articlesApi.getAuthenticateHeader(token));
  }

  getCommentById(token: string, id: string): Promise<ApiResponse<any>> {
    return articlesApi.get(`/api/comments/${id}/`, {}, articlesApi.getAuthenticateHeader(token));
  }

  addPaperLike(props: {
    token: string;
    authorId: string;
    commentId?: string;
    articleId?: string;
  }): Promise<ApiResponse<{ status: string }>> {
    return articlesApi.patch(
      `/api/papers/like?id=${props.articleId}`,
      { author: props.authorId, paper: props.articleId },
      articlesApi.getAuthenticateHeader(props.token),
    );
  }

  addOrRemoveCommentLike(props: { token: string; commentId?: string }): Promise<ApiResponse<{ status: string }>> {
    return articlesApi.patch(
      `/api/comments/${props.commentId}/like/`,
      {},
      articlesApi.getAuthenticateHeader(props.token),
    );
  }
}

export const ArticlesApiService = new _ArticlesApiService();
