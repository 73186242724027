import React from 'react';
import { NewlineText } from './textHelper';
import styles from './styles.module.css';

function EarnVextPoints() {
  const text =
    'VEXT does not post individual user\'s moods. Instead, we aggregate the moods of all the users in a group. So, for example, if a group has 12 members, and 4 post that they are ""happy"", 4 post that they are ""tired"", and four post that they are ""sad."" The average mood of the group will be somewhere in the middle - i.e. ""tired."" In other words, the VEXT Mood Maps are averages of a group\'s posted moods. \n' +
    '\n' +
    'You can also stay anonymous by not sharing your screen name and keeping personally identifiable traits concealed when you post.\n' +
    '\n' +
    '    Alternatively, users can post in ""incognito"" mode on VEXT.\n' +
    '\n' +
    "    Users sometimes DO want to connect IRL to study, practice, socialize, or whatever. With incognito mode you can reveal your identity to select folks and post in incognito when you don't want friends or peers or teachers to know that YOU are struggling with an assignment or didn't understand a lecture or whatever. ";
  return <div className={styles.textBlock}>{NewlineText(text)}</div>;
}

export default EarnVextPoints;
