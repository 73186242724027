import React from 'react';
import styles from './progressBarStyles.module.css';

const ProgressBar = ({ percentage }: { percentage: number }) => {
  const radius = 90;
  const circumference = Math.PI * radius; // Half circle
  const offset = circumference - (percentage / 100) * circumference; // Offset for half circle from left to right
  let color;
  let label;
  if (percentage < 30) {
    color = '#ee2106';
    label = "Below Average";
  }
  if (percentage > 30 && percentage < 70) {
    color = '#eaa10d';
    label = "Average";
  }
  if (percentage > 70) {
    color = '#1dbd1d';
    label = "Good";
  }

  return (
    <div className={styles.progressContainer}>
      <svg width="200" height="100" viewBox="0 0 200 100">
        <path d="M 10,100 A 90,90 0 0,1 190,100" fill="none" stroke="#eeeeee" strokeWidth="20" />
        <path
          d="M 10,100 A 90,90 0 0,1 190,100"
          fill="none"
          stroke={color}
          strokeWidth="20"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
      </svg>
      <div className={styles.progressPercentage}>
        <div> {percentage}%</div>
        <div style={{ fontWeight: '300',fontSize:'14px',marginTop:'8px' }}>{label}</div>
      </div>
    </div>
  );
};

export default ProgressBar;
