import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthForm from '../../components/Auth/forms/AuthForm';
import { AuthApiService } from '../../services/auth.service';
import jwt_decode from 'jwt-decode';
import { UsersApiService } from '../../services/users.service';
import { setUserLoginData } from '../../store/auth';
import MessageHockPopUp from '../../hooks/MessageHockPopUp';
import { useDispatch } from 'react-redux';
import { useRefreshToken, useToken } from '../../cookies';
import CircularProgress from '@mui/material/CircularProgress';
import { blueLogo } from '../../assets/LogoFrames/blueLogo';
import { getUser } from '../../store/user';
import ForgotPassword from '../../pages/ResetAccount/ForgotPassword';
import styles from './adminStyles.module.css';

function AdminAuth() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mailPrefix, setMailPrefix] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { setToken } = useToken();
  const { setRefreshToken } = useRefreshToken();

  const authenticate = async () => {
    setIsLoading(true);
    try {
      if (password.length && email.length) {
        const response = await AuthApiService.signIn(email + mailPrefix, password);
        if (!response.data.access) {
          MessageHockPopUp({
            message: response.data.detail || 'UNKNOWN ERROR, please check the console',
            timeOut: 4000,
            type: 'error',
          });
        }
        if (response.data.access) {
          const userData = jwt_decode(response.data.access) as {
            token_type: string;
            exp: number;
            iat: number;
            jti: string;
            user_id: string;
          };

          const user = await UsersApiService.getUser(response.data.access, String(userData.user_id));
          dispatch(setUserLoginData({ email: email + mailPrefix, uuid: String(userData.user_id) }));
          dispatch(
            getUser({
              token: response.data.access,
              userId:String(userData.user_id),
            }),
          );
          if (!user.data?.is_staff) {
            MessageHockPopUp({
              message: 'This account is not an Admin account',
              timeOut: 4000,
              type: 'error',
            });
          } else {
            dispatch(setUserLoginData({ email: email + mailPrefix, uuid: String(userData.user_id) }));
            setToken(response.data.access);
            setRefreshToken(response.data.refresh);
            history.push('/admin');
          }
        }
      } else {
        MessageHockPopUp({
          message: 'Empty fields not allowed',
          timeOut: 4000,
          type: 'warning',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.adminAuth}>
      {isLoading && (
        <div style={{ position: 'absolute', zIndex: '200' }}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.logo}>{blueLogo()}VEXT</div>
      <div className={styles.authBlock}>
        <h1>Admin Login</h1>
        <AuthForm
          email={email}
          password={password}
          mailPrefix={mailPrefix}
          setEmail={setEmail}
          setMailPrefix={setMailPrefix}
          setPassword={setPassword}
          authenticate={authenticate}
        />
        <div onClick={authenticate} className={styles.loginBtn}>
          Login
        </div>
        <ForgotPassword />
      </div>
    </div>
  );
}

export default AdminAuth;
