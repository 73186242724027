import React, { useEffect, useState } from 'react';
import MoodSquare from './MoodSquare';
import { GraphPeriods } from '../../../../utils/enums';
import PeriodMenu from './PeriodMenu';
import { MoodApiService } from '../../../../services/mood.service';
import { useToken } from '../../../../cookies';
import { IGroup } from '../../../../types/Group';
import IndividualSquare from './IndividualSquare';
import { User } from '../../../../types/User';
import { EmergencyService } from '../../../../services/emergencies.service';
import LinearLoader from '../../../../helpers/LinearLoader';
import { useIsMobile, useIsTablet } from '../../../../utils/styles.utils';
import styles from '../dashboardStyles.module.css';
import { fromTo } from './helper';

function MoodsSquareGraph() {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeIndividualIndex, setActiveIndividualIndex] = useState('-1');
  const [selectedPeriod, setSelectedPeriod] = useState<GraphPeriods>(GraphPeriods.LAST_24_HOURS);
  const [groupMoods, setGroupMoods] = useState<{ [key: string]: number }>({});
  const [selectedGroup, setSelectedGroup] = useState<null | IGroup>(null);
  const [selectedUser, setSelectedUser] = useState<null | User>(null);
  const [individualsMoods, setIndividualsMoods] = useState<{ [key: string]: number }>({});
  const [emergenciesByGroupId, setEmergenciesByGroupId] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSmallDevice = isMobile || isTablet;

  const { token } = useToken();

  useEffect(() => {
    const { from, to } = fromTo(selectedPeriod);
    MoodApiService.getDomainMoods(token, from, to).then((response) => {
      setGroupMoods(response?.data || {});
    });
  }, [selectedPeriod]);

  useEffect(() => {
    if (Object.keys(groupMoods).length) {
      setActiveIndex(0); //Open first square group by default
    }
  }, []);

  useEffect(() => {
    const { from, to } = fromTo(selectedPeriod);
    setIsLoading(true);
    if (selectedGroup) {
      MoodApiService.getGroupUsersMoods(token, selectedGroup.id, from, to).then((response) => {
        setIndividualsMoods(response?.data || {});
      });
      EmergencyService.getEmergenciesByGroupId(token, selectedGroup.id, from, to).then((res) => {
        setEmergenciesByGroupId(res.data);
        setIsLoading(false);
      });
    }
  }, [selectedPeriod, selectedGroup]);

  const userWithMoods = selectedGroup?.users.map((user) => {
    return { ...user, individualMoodValue: individualsMoods[user.id] ?? null };
  });

  return (
    <div className={styles.hexaWrapper} style={{ width: isSmallDevice ? '87%' : undefined }}>
      <div className={styles.moodsHeader}>
        <div className={styles.topBlockTitle}>Moods</div>
        <PeriodMenu selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} />
      </div>
      <div>Groups</div>
      <div className={styles.squaresWrapper}>
        {!!Object.keys(groupMoods).length &&
          Object.entries(groupMoods).map(([key, val], idx) => {
            return (
              <MoodSquare
                key={key}
                setSelectedGroup={setSelectedGroup}
                selectedGroup={selectedGroup}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                moodVal={val}
                index={idx}
                groupId={key}
              />
            );
          })}
      </div>
      {selectedGroup !== null && (
        <div>
          <div>Individuals</div>
          <div className={styles.squaresWrapper}>
            {isLoading ? (
              <div style={{ width: '100px' }}>
                <LinearLoader />
              </div>
            ) : (
              userWithMoods &&
              userWithMoods.map((user, idx) => {
                return (
                  <IndividualSquare
                    key={user.id}
                    setSelectedUser={setSelectedUser}
                    selectedUser={selectedUser}
                    activeIndex={activeIndividualIndex}
                    setActiveIndex={setActiveIndividualIndex}
                    moodVal={user.individualMoodValue}
                    index={idx + 'i'}
                    userId={user.id}
                    emergenciesByGroupId={emergenciesByGroupId}
                  />
                );
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MoodsSquareGraph;