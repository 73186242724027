import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isPendingAction, isRejectedAction } from '../../utils/redux.utils';

import { ChatService } from '../../services/socket.service';
import { ChatListPreview } from '../../types/User';
import { Message } from '../../types/Chat';

export const CHATS_REDUCER_NAME = 'chats';
export const MESSAGES_PER_PAGE = 18;

interface ChatDataInitialState {
  peopleChats: { [key: string]: ChatListPreview };
  groupChats: { [key: string]: ChatListPreview };
  peopleChatsHasNext: boolean;
  groupsChatsHasNext: boolean;
  openedMessagesHasNextPage: boolean;
  openedChat: ChatListPreview | null;
  messages: Array<Message>;
  error: string | null;
  isLoading: boolean;
  isMessagesLoading: boolean;
  isNewMessageInChats: boolean;
  count: number;
}

const initialState: ChatDataInitialState = {
  error: null,
  isLoading: false,
  messages: [],
  peopleChats: {},
  groupChats: {},
  peopleChatsHasNext: false,
  groupsChatsHasNext: false,
  openedMessagesHasNextPage: false,
  openedChat: null,
  isMessagesLoading: false,
  isNewMessageInChats: false,
  count: 0,
};

export const initiateNewConversation = createAsyncThunk(
  `${CHATS_REDUCER_NAME}/createNewChat`,
  async ({ userIds, token }: { userIds: string[]; token: string }) => {
    const createdChat = await ChatService.initiateAConversation({
      token,
      userIds,
    });
    if (createdChat.ok) {
      return createdChat.data;
    } else {
      throw new Error('New chat not created!');
    }
  },
);

export const deleteConversation = createAsyncThunk(
  `${CHATS_REDUCER_NAME}/deleteConversation`,
  async ({ chatId, token, isPeopleChat }: { chatId: string; token: string; isPeopleChat: boolean }) => {
    const removeChat = await ChatService.deleteConversation(token, chatId);
    if (removeChat.ok) {
      return { removed: 'ok', chatId, isPeopleChat };
    } else {
      return { removed: 'error', chatId, isPeopleChat };
    }
  },
);

export const getPeopleConversations = createAsyncThunk(
  `${CHATS_REDUCER_NAME}/getPeopleConversations`,
  async ({ page, token }: { page: number; token: string }) => {
    const peopleConversations = await ChatService.getPeopleConversations(token, 20, page);

    if (peopleConversations.ok) {
      return peopleConversations.data;
    } else {
      throw new Error('Get people conversation error!');
    }
  },
);

export const getGroupsConversations = createAsyncThunk(
  `${CHATS_REDUCER_NAME}/getGroupsConversations`,
  async ({ page, token }: { page: number; token: string }) => {
    const groupsConversations = await ChatService.getGroupsConversations(token, 20, page);

    if (groupsConversations.ok) {
      return groupsConversations.data;
    } else {
      throw new Error('Get groups conversation error!');
    }
  },
);

export const getMessagesByChatId = createAsyncThunk(
  `${CHATS_REDUCER_NAME}/getMessagesByChatId`,
  async ({ page, token, chatId }: { page: number; token: string; chatId: string }) => {
    const messagesByChatId = await ChatService.getMyChatMessagesList(token, chatId, page, MESSAGES_PER_PAGE);

    if (messagesByChatId.ok) {
      return { data: messagesByChatId.data, page };
    } else {
      throw new Error('Get messagesByChatId error!');
    }
  },
);
const chatSlice = createSlice({
  name: CHATS_REDUCER_NAME,
  initialState,
  reducers: {
    setOpenedChat(state, { payload }) {
      state.messages = [];
      state.openedChat = payload;
    },
    setIsNewMessageInChats(state, { payload }) {
      const { parsedResult, myUserId } = payload;
      state.isNewMessageInChats = myUserId !== parsedResult.author_id;
    },
    makeConversationAsReadById(state, { payload: { chatId, isPeopleChat } }) {
      state.isNewMessageInChats = false;
      if (state.peopleChats[chatId] || state.groupChats[chatId]) {
        if (isPeopleChat && state.peopleChats[chatId].has_unseen_message) {
          state.peopleChats[chatId] = { ...state.peopleChats[chatId], has_unseen_message: false };
        }
        if (!isPeopleChat && state.groupChats[chatId].has_unseen_message) {
          state.groupChats[chatId] = { ...state.groupChats[chatId], has_unseen_message: false };
        }
      }
    },

    addMessageFromEventToChat(state, { payload }) {
      const { parsedResult, myUserId } = payload;
      if (state.openedChat?.id === parsedResult.conversation_id) {
        state.messages = [
          ...state.messages,
          {
            message: parsedResult.message,
            creation_date: parsedResult.creation_date,
            author: parsedResult.author_id,
          },
        ];
      }

      if (state.peopleChats[parsedResult.conversation_id]) {
        // console.log('People message', myUserId, parsedResult.author_id);
        //TODO -- here also we can show some pop-up with new message
        state.peopleChats[parsedResult.conversation_id] = {
          ...state.peopleChats[parsedResult.conversation_id],
          has_unseen_message: myUserId !== parsedResult.author_id,
          last_message: {
            message: parsedResult.message,
            author: parsedResult.author_id,
            creation_date: new Date().toISOString(),
          },
        };
      }
      if (state.groupChats[parsedResult.conversation_id]) {
        // console.log('Group message', myUserId, parsedResult.author_id);
        state.groupChats[parsedResult.conversation_id] = {
          ...state.groupChats[parsedResult.conversation_id],
          has_unseen_message: myUserId !== parsedResult.author_id,
          last_message: {
            message: parsedResult.message,
            author: parsedResult.author_id,
            creation_date: new Date().toISOString(),
          },
        };
      }

      if (parsedResult.type === 'conversation_deleted' && state.peopleChats[parsedResult.conversation]) {
        // Delete socket event
        delete state.peopleChats[parsedResult.conversation];
      }
    },

    clearMessages(state) {
      state.messages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteConversation.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if (payload.isPeopleChat) {
          delete state.peopleChats[payload.chatId];
          state.openedChat = null;
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getMessagesByChatId.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.isMessagesLoading = false;
        state.isLoading = false;
        const { page, data } = payload;
        state.openedMessagesHasNextPage = data.next;
        if (page === 1) {
          state.messages = data.results.reverse();
        } else {
          state.messages = [...data.results.reverse(), ...state.messages];
        }
        state.error = null;
      })
      .addCase(initiateNewConversation.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.messages = [];
        state.openedChat = payload;
        state.peopleChats = { ...state.peopleChats, [payload.id]: payload };
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getPeopleConversations.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.peopleChatsHasNext = !!payload.next;
        state.count = payload.count;
        if (payload.results) {
          const mappedResult = payload.results.reduce(
            (acc: { [key: string]: ChatListPreview }, curr: ChatListPreview) => {
              return { ...acc, [curr.id]: curr };
            },
            {},
          );
          state.peopleChats = { ...state.peopleChats, ...mappedResult };
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getGroupsConversations.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.groupsChatsHasNext = !!payload.next;
        if (payload.results) {
          const mappedResult = payload.results.reduce(
            (acc: { [key: string]: ChatListPreview }, curr: ChatListPreview) => {
              return { ...acc, [curr.id]: curr };
            },
            {},
          );
          if (!payload.previous) {
            state.groupChats = mappedResult;
          } else {
            state.groupChats = { ...state.groupChats, ...mappedResult };
          }
        }
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isPendingAction(`${CHATS_REDUCER_NAME}/`), (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isPendingAction(`${CHATS_REDUCER_NAME}/getMessagesByChatId`), (state) => {
        state.isMessagesLoading = true;
        state.error = null;
      })
      .addMatcher(isRejectedAction(`${CHATS_REDUCER_NAME}/`), (state, { error }) => {
        state.error = error.message;
        state.isLoading = false;
        state.isMessagesLoading = false;
      });
  },
});

export const {
    clearMessages,
    setOpenedChat,
    makeConversationAsReadById,
    addMessageFromEventToChat, setIsNewMessageInChats
} = chatSlice.actions;
export default chatSlice.reducer;
