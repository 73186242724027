import React from 'react';

import { antIcon } from '../assets/anonymousIcons/antIcon';
import { bearIcon } from '../assets/anonymousIcons/bearIcon';
import { beaverIcon } from '../assets/anonymousIcons/beaverIcon';
import { beetleIcon } from '../assets/anonymousIcons/beetleIcon';
import { beeIcon } from '../assets/anonymousIcons/beeIcon';
import { buffaloIcon } from '../assets/anonymousIcons/buffaloIcon';
import { butterflyIcon } from '../assets/anonymousIcons/butterflyIcon';
import { catIcon } from '../assets/anonymousIcons/catIcon';
import { chameleonIcon } from '../assets/anonymousIcons/chameleonIcon';
import { chickenIcon } from '../assets/anonymousIcons/chickenIcon';
import { crabIcon } from '../assets/anonymousIcons/crabIcon';
import { crocodileIcon } from '../assets/anonymousIcons/crocodileIcon';
import { deerIcon } from '../assets/anonymousIcons/deerIcon';
import { dogIcon } from '../assets/anonymousIcons/dogIcon';
import { dragonflyIcon } from '../assets/anonymousIcons/dragonflyIcon';
import { duckIcon } from '../assets/anonymousIcons/duckIcon';
import { eagleIcon } from '../assets/anonymousIcons/eagleIcon';
import { elephantIcon } from '../assets/anonymousIcons/elephantIcon';
import { foxIcon } from '../assets/anonymousIcons/foxIcon';
import { frogIcon } from '../assets/anonymousIcons/frogIcon';
import { giraffeIcon } from '../assets/anonymousIcons/giraffeIcon';
import { goatIcon } from '../assets/anonymousIcons/goatIcon';
import { hamsterIcon } from '../assets/anonymousIcons/hamsterIcon';
import { hippopotamusIcon } from '../assets/anonymousIcons/hippopotamusIcon';
import { horseIcon } from '../assets/anonymousIcons/horseIcon';
import { jellyfishIcon } from '../assets/anonymousIcons/jellyfishIcon';
import { kangarooIcon } from '../assets/anonymousIcons/kangaroIcon';
import { koalaIcon } from '../assets/anonymousIcons/koalaIcon';
import { ladybugIcon } from '../assets/anonymousIcons/ladybugIcon';
import { lionIcon } from '../assets/anonymousIcons/lionIcon';
import { lobsterIcon } from '../assets/anonymousIcons/lobsterIcon';
import { monkeyIcon } from '../assets/anonymousIcons/monkeyIcon';
import { mouseIcon } from '../assets/anonymousIcons/mouseIcon';
import { owlIcon } from '../assets/anonymousIcons/owlIcon';
import { pandaIcon } from '../assets/anonymousIcons/pandaIcon';
import { penguinIcon } from '../assets/anonymousIcons/penguinIcon';
import { pigIcon } from '../assets/anonymousIcons/pigIcon';
import { rabbitIcon } from '../assets/anonymousIcons/rabbitIcon';
import { rhinoIcon } from '../assets/anonymousIcons/rhinoIcon';
import { snakeIcon } from '../assets/anonymousIcons/snakeIcon';
import { spiderIcon } from '../assets/anonymousIcons/spiderIcon';
import { squidIcon } from '../assets/anonymousIcons/squidIcon';
import { toucanIcon } from '../assets/anonymousIcons/toucanIcon';
import { turtleIcon } from '../assets/anonymousIcons/turtleIcon';
import { walrusImage } from '../assets/anonymousIcons/walrusImage';
import { whaleIcon } from '../assets/anonymousIcons/whaleIcon';
import { zebraIcon } from '../assets/anonymousIcons/zebraIcon';

const randomAnimals = [
  'Ant',
  'Bear',
  'Beaver',
  'Beetle',
  'Buffalo',
  'Butterfly',
  'Cat',
  'Chameleon',
  'Chicken',
  'Crab',
  'Crocodile',
  'Deer',
  'Dog',
  'Dragonfly',
  'Duck',
  'Eagle',
  'Elephant',
  'Fox',
  'Frog',
  'Giraffe',
  'Goat',
  'Hamster',
  'Hippopotamus',
  'Horse',
  'Jellyfish',
  'Kangaroo',
  'Koala',
  'Ladybug',
  'Lion',
  'Lobster',
  'Monkey',
  'Mouse',
  'Owl',
  'Panda',
  'Penguin',
  'Pig',
  'Rabbit',
  'Rhino',
  'Snake',
  'Spider',
  'Squid',
  'Toucan',
  'Turtle',
  'Walrus',
  'Whale',
  'Zebra',
];
const randomColors = ['Green', 'Violet', 'Blue', 'Pink', 'Red', 'Orange', 'Cyan', 'Yellow'];

export const generateRandomNicknameId = () => {
  const randomAnimal = randomAnimals[Math.floor(Math.random() * (randomAnimals.length - 1 + 1))];
  const randomColor = randomColors[Math.floor(Math.random() * (randomColors.length - 1 + 1))];

  return `${randomColor} ${randomAnimal}`;
};
export const getColorByAnonymousName = (name: string) => {
  const [color] = name.split(' ');
  return color;
};

export const getIconByAnonymousName = (name: string) => {
  const [_, animal] = name.split(' ');
  switch (animal) {
    case 'Ant':
      return antIcon();
    case 'Bear':
      return bearIcon();
    case 'Beaver':
      return beaverIcon();
    case 'Bee':
      return beeIcon();
    case 'Beetle':
      return beetleIcon();
    case 'Buffalo':
      return buffaloIcon();
    case 'Butterfly':
      return butterflyIcon();
    case 'Cat':
      return catIcon();
    case 'Chameleon':
      return chameleonIcon();
    case 'Chicken':
      return chickenIcon();
    case 'Crab':
      return crabIcon();
    case 'Crocodile':
      return crocodileIcon();
    case 'Deer':
      return deerIcon();
    case 'Dog':
      return dogIcon();
    case 'Dragonfly':
      return dragonflyIcon();
    case 'Duck':
      return duckIcon();
    case 'Eagle':
      return eagleIcon();
    case 'Elephant':
      return elephantIcon();
    case 'Fox':
      return foxIcon();
    case 'Frog':
      return frogIcon();
    case 'Giraffe':
      return giraffeIcon();
    case 'Goat':
      return goatIcon();
    case 'Hamster':
      return hamsterIcon();
    case 'Hippopotamus':
      return hippopotamusIcon();
    case 'Horse':
      return horseIcon();
    case 'Jellyfish':
      return jellyfishIcon();
    case 'Kangaroo':
      return kangarooIcon();
    case 'Koala':
      return koalaIcon();
    case 'Ladybug':
      return ladybugIcon();
    case 'Lion':
      return lionIcon();
    case 'Lobster':
      return lobsterIcon();
    case 'Monkey':
      return monkeyIcon();
    case 'Mouse':
      return mouseIcon();
    case 'Owl':
      return owlIcon();
    case 'Panda':
      return pandaIcon();
    case 'Penguin':
      return penguinIcon();
    case 'Pig':
      return pigIcon();
    case 'Rabbit':
      return rabbitIcon();
    case 'Rhino':
      return rhinoIcon();
    case 'Snake':
      return snakeIcon();
    case 'Spider':
      return spiderIcon();
    case 'Squid':
      return squidIcon();
    case 'Toucan':
      return toucanIcon();
    case 'Turtle':
      return turtleIcon();
    case 'Walrus':
      return walrusImage();
    case 'Whale':
      return whaleIcon();
    case 'Zebra':
      return zebraIcon();
  }
};

export const getAnonymousImage = (name: string, size: 'small' | 'medium' | 'large' = 'small') => {
  let widthAndHeight = 36;
  if (size === 'medium') {
    widthAndHeight = 40;
  }
  if (size === 'large') {
    widthAndHeight = 46;
  }
  return (
    <div
      style={{
        borderRadius: '50px',
        backgroundColor: getColorByAnonymousName(name).toLowerCase(),
        border: '2px solid #daa50d',
        width: widthAndHeight,
        height: widthAndHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {getIconByAnonymousName(name)}
    </div>
  );
};
