import styled from 'styled-components';
import { theme } from '../themes/variables';

const H1 = styled.h1`
  color: ${theme.colors.blue_1};
  font-size: 64px;
  line-height: 83px;
  margin: 0;
  font-weight: 500;
`;

const H2 = styled.h2`
  color: ${theme.colors.blue_11};
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
`;

const H3 = styled.h3`
  color: ${theme.colors.blue_1};
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  margin: 10px 0 0 4px;
`;

const H4 = styled.h4`
  color: ${theme.colors.blue_11};
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin: 0;
`;

const H5 = styled.h5`
  color: ${theme.colors.blue_11};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin: 0;
`;

const H6 = styled.h6`
  color: ${theme.colors.blue_11};
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  margin: 0;
`;

export const Headers: any = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
};
