import React from 'react';
import styles from './styles.module.css';

function ContactVext() {
  return (
    <div className={styles.textBlock}>
      Email us at <a href="mailto: hey@vext.co">hey@vext.co</a>
    </div>
  );
}

export default ContactVext;

