import React from 'react';
import { useSelector } from 'react-redux';

import styles from './dashboardStyles.module.css';

import { selectChatsCount } from '../../../store/chats/selector';
import { useIsMobile, useIsTablet } from '../../../utils/styles.utils';

function OpenChats() {
  const chatsCount = useSelector(selectChatsCount);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSmallDevice = isMobile || isTablet;

  return (
    <div
      className={styles.topBlock}
      style={{ width: isSmallDevice ? '97%' : undefined, height: isSmallDevice ? '28vh' : undefined }}
    >
      <div className={styles.topBlockContent}>
        <div className={styles.topBlockTitle}>Open chats</div>
        <div className={styles.infoIcon}>i</div>
      </div>

      <div className={styles.topBlockContent}>
        <div className={styles.quantity} style={{ marginBottom: '14px' }}>
          {chatsCount}
        </div>
      </div>
    </div>
  );
}

export default OpenChats;