import {calcCreatedTime} from "src/helpers/date";
import { css } from 'styled-components/macro';
import { IComment } from '../../types/Paper';
import { ImagesTemplate } from '../layout/ImagesTemplate';
import { displayMyUserNameInComments } from '../../helpers/comments';

export default function CommentUserData(comment: IComment) {
  return (
    <>
        <div
          css={css`
            display: flex;
            align-items: center;
            margin: 6px 0px;
          `}
        >
          <ImagesTemplate url={comment.author?.profile?.image_url} height={50} width={49} isSquare/>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin: 10px 0 10px 10px;
            `}
          >
            <span style={{ marginLeft: '8px' }}>{displayMyUserNameInComments(comment)}</span>
            <span
              css={css`
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 120%;
                margin-top: 6px;
              `}
            >
              {comment.author.profile?.major || comment.author.profile?.graduation || ' '}
            </span>
          </div>
          <div
            css={css`
              margin-left: auto;
              margin-right: 20px;
              font-weight: 400;
              font-size: 13px;
              margin-bottom: 6px;
              color: #757575;
            `}
          >
            {comment.created && calcCreatedTime(comment.created)}
          </div>
        </div>

    </>
  );
}
