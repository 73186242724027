import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useToken } from 'src/cookies';
import { Report } from 'src/types/Reports';

import ReportElement from './components/ReportElement';
import styles from './dashboardStyles.module.css';

import { ReportsApiService } from '../../../services/report.service';
import { useIsMobile, useIsTablet } from '../../../utils/styles.utils';

function Reports() {
  const { token } = useToken();
  const [reports, setReports] = useState<Report[]>([]);
  const [totalReports, setTotalReports] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSmallDevice = isMobile || isTablet;
  const totalPages = Math.ceil(totalReports / perPage);

  const handleClickPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getReports = async (page?: number) => {
    setIsLoading(true);
    const reportsResponse = await ReportsApiService.getReports(token, perPage, page || currentPage).then((r) => r.data);
    if (reportsResponse) {
      setTotalReports(reportsResponse.count);
      setReports(reportsResponse.results);
    }
  };
  const getReportsAfterUpdate = () => {
    setCurrentPage(1);
    getReports(1).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getReports().finally(() => setIsLoading(false));
  }, [token, currentPage, perPage]);

  const handleItemsPerPageChange = (event: any) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  return (
    <div className={styles.reportWrapper} style={{ width: isSmallDevice ? '86%' : undefined }}>
      {isLoading && (
        <div style={{ position: 'absolute', zIndex: '200', left: '50%', top: '50%' }}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.reportHeader}>
        <div className={styles.topBlockTitle}>Reports</div>
      </div>
      <div className={styles.reportUserContainer}>
        {reports.length ? (
          reports.map((report) => {
            return <ReportElement key={report.id} report={report} getReports={getReportsAfterUpdate} />;
          })
        ) : (
          <h2>No reports</h2>
        )}
      </div>

      <div className={styles.pagesWrapper}>
        <button onClick={handleClickPrevious} disabled={currentPage === 1} className={styles.pageButton}>
          Previous
        </button>
        <span>{currentPage}</span>
        <button onClick={handleClickNext} disabled={currentPage === totalPages} className={styles.pageButton}>
          Next
        </button>
      </div>
      {!isLoading && (
        <div className={styles.perPageWrapper}>
          <label>Page size</label>
          <select value={perPage} onChange={handleItemsPerPageChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
      )}
    </div>
  );
}

export default Reports;