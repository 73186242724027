export const landscapeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.14535 21.5142H14.8539C19.6111 21.5142 21.5139 19.6113 21.5139 14.8542V9.14563C21.5139 4.38848 19.6111 2.48563 14.8539 2.48563H9.14535C4.38821 2.48563 2.48535 4.38848 2.48535 9.14563V14.8542C2.48535 19.6113 4.38821 21.5142 9.14535 21.5142Z"
      stroke="#FFC700"
      strokeWidth="1.28571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.14504 10.097C10.196 10.097 11.0479 9.2451 11.0479 8.19418C11.0479 7.14326 10.196 6.29132 9.14504 6.29132C8.09413 6.29132 7.24219 7.14326 7.24219 8.19418C7.24219 9.2451 8.09413 10.097 9.14504 10.097Z"
      stroke="#FFC700"
      strokeWidth="1.28571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.12305 18.6125L7.81359 15.4633C8.56522 14.9591 9.64985 15.0161 10.3254 15.5965L10.6393 15.8724C11.3814 16.5099 12.5802 16.5099 13.3224 15.8724L17.2803 12.4758C18.0224 11.8384 19.2212 11.8384 19.9633 12.4758L21.5142 13.8078"
      stroke="#FFC700"
      strokeWidth="1.28571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
