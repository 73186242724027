import React from 'react';
import { useIsMobile, useIsTablet } from 'src/utils/styles.utils';
import { css } from 'styled-components/macro';
import MobileFooter from '../mobile/Footer/MobileFooter';
import { useToken } from '../../cookies';

export function Wrapper(props: { children: React.ReactNode | React.ReactNode[]; isGroupLocation?: boolean }) {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { token } = useToken();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        position: static;
        height: 86%;
        ${isMobile || isTablet
          ? `background-color:black;
           `
          : `margin: 20px 105px;
          `}
      `}
    >
      {props.children}
      {(isMobile||isTablet) && token && <MobileFooter isGroupLocation={props.isGroupLocation} />}
    </div>
  );
}
