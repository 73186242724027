import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from 'styled-components/macro';
import { useToken } from '../../../cookies';
import { updateUser } from '../../../store/user';
import { selectLoggedUserData } from '../../../store/user/selectors';
import { EditContentSwitch } from './EditContentSwitch';
import { User } from '../../../types/User';

export const ShortBio = () => {
  const userData = useSelector(selectLoggedUserData);
  const [isEditable, setIsEditable] = useState(true);
  const [shortBio, setShortBio] = useState(userData.profile?.bio || '');
  const dispatch = useDispatch();
  const { token } = useToken();

  const saveUserBiography = () => {
    editUserBiography(shortBio);
    setIsEditable(true);
  };

  useEffect(() => {
    setShortBio(userData.profile?.bio || '');
  }, [userData]);

  const editUserBiography = (bio: string) => {
    const newUserInfo = {
      ...userData,
      profile: {
        bio,
      },
    }as unknown as User;
    dispatch(updateUser({ token, updatedData: newUserInfo }));
  };

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      {isEditable ? (
        shortBio.length ? (
          <span
            css={css`
              display: flex;
              align-items: center;
              width: 70%;
              overflow: hidden;
            `}
          >
            {shortBio}
          </span>
        ) : (
          <div
            css={css`
              display: flex;
              align-items: center;
              width: 70%;
            `}
          >
            Add your short bio
          </div>
        )
      ) : (
        <textarea
          onChange={(e) => setShortBio(e.target.value)}
          defaultValue={shortBio}
          css={css`
            margin-top: 30px;
            min-height: 6px;
            width: 30%;
            border-radius: 6px;
            ${shortBio.length
              ? ` border: 0.5px solid rgba(0, 0, 0, 0.56);`
              : ` border: 0.5px solid rgba(253, 61, 2, 0.56);`}
            &:focus {
              outline: none;
            }
          `}
        />
      )}

      <div
        css={css`
          margin: 6px;
          display: flex;
          padding: 6px;
          justify-content: end;
        `}
      >
        <EditContentSwitch
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          submit={saveUserBiography}
          isAllCompleted={!!shortBio.length}
        />
      </div>
    </div>
  );
};
