import styles from '../dashboardStyles.module.css';
import { ReactNode, useRef, useState } from 'react';
import useOutsideListener from 'src/hooks/useOutsideListener';
import { Report } from 'src/types/Reports';
import { NO_USER_AVATAR } from 'src/utils/constantImagesUrl';
import { calcJoinedTime } from 'src/helpers/date';

type Props = {
  children: ReactNode;
  report: Report;
  reportedName: string;
  reportedImage: string;
  reportedOwnerName: string;
  onChat: () => void;
  onDelete: () => void;
  onIgnore: (report: Report) => void;
};

function ReportPopUp({
  children,
  report,
  reportedName,
  reportedImage,
  reportedOwnerName,
  onChat,
  onDelete,
  onIgnore,
}: Props) {
  const popupRef = useRef<HTMLDivElement>(null);
  const [isReportOpen, setIsReportOpen] = useState<boolean>(false);
  useOutsideListener(popupRef, () => setIsReportOpen(false));
  const user = report.report_author;
  const domain = `${user.profile?.faculty || 'No faculty'}, ${user.profile?.major || 'No major'}, ${
    user.profile?.institute || 'No institute'
  }`;
  return (
    <>
      <div
        className={isReportOpen ? styles.reportInfoButton_active : styles.reportInfoButton}
        onClick={() => setIsReportOpen(true)}
      >
        {children}
      </div>
      {isReportOpen && (
        <div className={styles.reportPopUpWrapper} ref={popupRef}>
          <div className={styles.reportedWrapper}>
            <div className={styles.reportInfoColumn}>
              <div className={styles.reportInfoRow}>
                <img className={styles.reportImage} src={reportedImage} alt="" />
                <div className={styles.reportHeaderContainer}>
                  <div className={styles.popupReportedHeader}>Reported {report.report_object_type.toLowerCase()}:</div>
                  <div className={styles.reportedName}>
                    {reportedName.length > 26 ? reportedName.slice(0, 26) + '...' : reportedName}
                  </div>
                </div>
              </div>
              <div className={styles.reportInfoRow}>
                <div className={styles.reportHeaderContainer}>
                  <div className={styles.popupReportedHeader}>Datetime:</div>
                  <div className={styles.popupReportedHeader}>{calcJoinedTime(report.created_at)}</div>
                </div>
              </div>
            </div>

            <div className={styles.reportInfoColumn}>
              <div className={styles.reportInfoRow}>
                <div className={styles.reportHeaderContainer}>
                  <div className={styles.popupReportedHeader}>Type:</div>
                  <div className={styles.reportedReason}>{report.report_reason_title}</div>
                </div>
              </div>
              <div className={styles.reportInfoRow}>
                <div className={styles.reportHeaderContainer}>
                  <div className={styles.popupReportedHeader}>Reporter's name:</div>
                  <div className={styles.popupReportedHeader}>{reportedOwnerName}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.reporterContainer}>
            <div className={styles.popupReportedHeader}>Who actioned:</div>
            <div className={styles.reporterUser}>
              <img className={styles.reportImage} src={user.profile?.image_url || NO_USER_AVATAR} alt="" />
              <div className={styles.userDataContainer}>
                <div className={styles.reporterUserName}>{user.username}</div>
                <div className={styles.reportDomain}>{domain}</div>
              </div>
            </div>
          </div>
          <div className={styles.reportMessageContainer}>
            <div className={styles.popupReportedHeader}>Message:</div>
            <div className={styles.popupReportedHeader}>{report.report_reason_description}</div>
          </div>
          <div className={styles.reportActionBtnContainer}>
            <div className={styles.popupReportedHeader}>Action:</div>
            <div className={styles.actionBtnWrapper}>
              <button
                className={styles.actionBtn}
                disabled={report?.report_status === 'RESOLVED'|| report?.report_status === 'ACTION_TAKEN'}
                onClick={() => {
                  onChat();
                  setIsReportOpen(false);
                }}
              >
                Chat
              </button>
              <button
                className={styles.actionBtn}
                onClick={() => {
                  onDelete();
                  setIsReportOpen(false);
                }}
              >
                Delete
              </button>
              <button
                disabled={report?.report_status === 'RESOLVED'|| report?.report_status === 'ACTION_TAKEN'}
                className={styles.actionBtn}
                onClick={() => {
                  onIgnore(report);
                  setIsReportOpen(false);
                }}
              >
                Ignore
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ReportPopUp;