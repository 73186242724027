import style from './style.module.css';

export default function AskModalInfo() {
  return (
    <div className={style.footerInfoBlock}>
      <div className={style.footerInfoTitleLabel}>Quick tips on getting good answers quickly</div>
      <ul className={style.footerInfoListBlock}>
        <li>Make sure your question has not been asked already</li>
        <li>Keep your question short and to the point</li>
        <li>Double-check grammar and spelling</li>
      </ul>
    </div>
  );
}
