import { CookieSetOptions } from 'universal-cookie/cjs';
import { useCookies } from 'react-cookie';
import React from 'react';

export const TOKEN = 'token';
export const REFRESH = 'refresh';

// The token cookie is the same for the whole application,
// no matter what the route

const options: CookieSetOptions = { path: '/', sameSite: 'strict' };

/**
 * Hook to manage token. It returns a tuple containing the value of the
 * token and functions to change and remove it.
 * It can be used like:
 *      const {token, setToken} = useToken()
 */

export const useToken = (): {
  token: string;
  setToken: (sessionId: string) => void;
  removeToken: () => void;
} => {
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN]);

  const setToken = React.useCallback(
      (token: string) => {
        setCookie(TOKEN, token, options);
        localStorage.setItem(TOKEN, token);
      },
      [setCookie],
  );

  const removeToken = React.useCallback(() => {
    removeCookie(TOKEN, options);
    localStorage.removeItem(TOKEN);
  }, [removeCookie]);

  // Get the token from local storage if it exists
  const localToken = localStorage.getItem(TOKEN);

  return { token: cookies.token || localToken || '', setToken, removeToken };
};

export const useRefreshToken = (): {
  refreshToken: string;
  setRefreshToken: (refreshToken: string) => void;
  removeRefreshToken: () => void;
} => {
  const [cookies, setCookie, removeCookie] = useCookies([REFRESH]);

  const setRefreshToken = React.useCallback(
      (refreshToken: string) => {
        setCookie(REFRESH, refreshToken, options);
        localStorage.setItem(REFRESH, refreshToken);
      },
      [setCookie],
  );

  const removeRefreshToken = React.useCallback(() => {
    removeCookie(REFRESH, options);
    localStorage.removeItem(REFRESH);
  }, [removeCookie]);

  const localRefreshToken = localStorage.getItem(REFRESH);

  return { refreshToken: cookies.refresh || localRefreshToken || '', setRefreshToken, removeRefreshToken };
};
