import { ApiResponse } from 'apisauce';
import { _RestApiService } from './api.service';
import { SearchResponse } from '../types/Common';

const commonApi = new _RestApiService(window._env_.REACT_APP_BACKEND_URL || '');

export class _CommonApiService {
  globalSearch(token: string, search: string): Promise<ApiResponse<SearchResponse>> {
    return commonApi.get(`/api/search/`, { search }, commonApi.getAuthenticateHeader(token));
  }
}

export const CommonApiService = new _CommonApiService();