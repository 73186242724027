import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import MoodsSquareGraph from './components/MoodsSquareGraph';
import styles from './dashboardStyles.module.css';
import Emergencies from './Emergencies';
import OpenChats from './OpenChats';
import Outliers from './Outliers';
import Reports from './Reports';
import RolesAndUsers from './RolesAndUsers';

import { useToken } from '../../../cookies';
import UsaMap from '../../../maps/usaMap/UsaMap';
import { getPeopleConversations } from '../../../store/chats';
import { useIsMobile, useIsTablet } from '../../../utils/styles.utils';
import StatisticsMoods from '../statistics/groupComponents/StatisticsMoods';


function DashBoard() {
  const dispatch = useDispatch();
  const { token } = useToken();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSmallDevice = isMobile || isTablet;

  useEffect(() => {
    dispatch(getPeopleConversations({ token, page: 1 }));
  }, []);

  return (
    <div className={styles.mainDashboard} style={{ padding: isSmallDevice ? ' 38px 18px 10px 18px' : undefined }}>
      <div className={styles.topBlocksWrapper}>
        <Emergencies />
        <div className={isSmallDevice ? styles.mobileTopRightBlocksWrapper : styles.topRightBlocksWrapper}>
          <Outliers />
          <OpenChats />
        </div>
      </div>
      <div className={styles.rolesAndMoodsWrapper} style={{ flexDirection: isSmallDevice ? 'column' : 'row' }}>
        <RolesAndUsers />
        <div className={styles.dashboardMoods} style={{ width: isSmallDevice ? '101%' : undefined }}>
          <StatisticsMoods />
        </div>
      </div>
      <div className={styles.bottomDashboardWrapper} style={{ flexDirection: isSmallDevice ? 'column' : 'row' }}>
        <Reports />
        <MoodsSquareGraph />
      </div>
      <UsaMap margin={'20px 0'} />
    </div>
  );
}

export default DashBoard;
