import { ApiResponse } from 'apisauce';
import { _RestApiService } from './api.service';
import { HealthOBj } from '../types/HealthTypes';

const healthApi = new _RestApiService(window._env_.REACT_APP_BACKEND_URL || '');

export class _HealthApiService {
  getHealthInfo(
    token: string,
    author_id?: string,
    from_date?: string,
    to_date?: string,
    unit?: string,
  ): Promise<ApiResponse<{ [key: string]: Array<HealthOBj> }>> {
    return healthApi.get(
      `/api/healthkit/`,
      {
        author_id,
        from_date,
        to_date,
        unit,
      },
        healthApi.getAuthenticateHeader(token),
    );
  }
}

export const HealthApiService = new _HealthApiService();
