import React from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import styles from './chartStyles.module.css';

import { getMoodText } from '../../../helpers/moodList';

function AverageStatisticsChart({ averageData }: { averageData: Array<{ name: string; val: number | null }> }) {
  const getDotColor = (val: number) => {
    if (val < 3) return '#EA0017';
    if (val > 2 && val < 6) return '#FFC700';
    return '#498A0C';
  };
  const CustomizedDot = (props: any, type: string, color: string) => {
    const { cx, cy, value } = props;

    if (value[1] !== null) {
      return (
        <svg x={cx - 15} y={cy - 17}>
          <circle cx="16" cy="16" r={'5'} fill={getDotColor(value[1])} />
        </svg>
      );
    }
    return null;
  };
  if (!averageData) return null;
  return (
    <div className={styles.averageChartWrapper}>
      {/*<LeftBarEmoji width={25} height={25} />*/}
      <div className={styles.averageChartArea}>
        <ResponsiveContainer width={'100%'} height={414}>
          <AreaChart
            width={500}
            height={390}
            data={averageData}
            margin={{
              top: 18,
              right: 3,
              left: -46,
              bottom: 1,
            }}
          >
            <CartesianGrid opacity={0.4} strokeDasharray="6 2" />
            <defs>
              <linearGradient id={'color'} x1={'0'} y1={'0'} x2={'0'} y2={'1'}>
                <stop offset={'0%'} stopColor={'#54B1FD'} stopOpacity={0.5} />
                <stop offset={'70%'} stopColor={'#54B1FD'} stopOpacity={0.05} />
              </linearGradient>
            </defs>
            <Area dataKey={'val'} stroke={'#51566e'} fill={'url(#color)'} />
            <XAxis
              tickFormatter={(str) => {
                return str;
              }}
              dataKey={'name'}
              tickSize={5}
              stroke={'black'}
              lightingColor={'blue'}
            />
            <YAxis
              dataKey={'val'}
              tickFormatter={(number) => {
                return ``;
              }}
              axisLine={false}
              tickLine={false}
              tickCount={9}
              tickSize={6}
              domain={[0, 8]}
            />
            <Area
              dataKey={'val'}
              stroke={'#6692d7'}
              fill={'url(#color)'}
              dot={<CustomizedDot />}
              isAnimationActive={false}
            />
            <Tooltip
              content={({ active, payload, label }: any) => (
                <CustomTooltip active={active} payload={payload} label={label} />
              )}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

function CustomTooltip({ active, payload, label }: any) {
  if (active && payload.length && typeof payload[0]?.payload?.val == 'number') {
    return <div>{getMoodText(payload[0].payload.val)}</div>;
  }
  return <div>No data</div>;
}

export default AverageStatisticsChart;
