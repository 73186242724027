import { ApiResponse } from 'apisauce';
import { _RestApiService } from './api.service';

const tenorApi = new _RestApiService('https://g.tenor.com/');

export class _GifApiService {
  searchGif({ searchValue = 'excited' }: { searchValue?: string }): Promise<ApiResponse<any>> {
    return tenorApi.get(`v1/search?`, { Q: 'tea', key: 'LIVDSRZULELA', limit: 9 });
  }
}

export const GifApiService = new _GifApiService();
//AIzaSyCotbwo0N7V48qIWFcYa-SYvurpR-MUgY0