import React from 'react';
import { Admin } from '../../../../types/User';
import { userNameFromAllParams } from '../../../../helpers/userName';
import { NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';
import styles from './styles.module.css';

function GroupCreator({ creator }: { creator?: Admin }) {
  return (
    <div className={styles.creatorBlock}>
      <img src={creator?.profile?.image_url || NO_USER_AVATAR} alt="" />
      <div>
        <div className={styles.creatorName}>
          {userNameFromAllParams(creator?.username, creator?.profile?.first_name, creator?.profile?.last_name)}
        </div>
        <div className={styles.creatorSchool}>
          {creator?.profile?.institute ||
            creator?.profile?.major ||
            creator?.profile?.graduation ||
            'No education info'}
        </div>
      </div>
    </div>
  );
}

export default GroupCreator;
