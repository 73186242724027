export const healthCalories = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4644_1633)">
      <path
        d="M20.0486 12.5178C19.5947 12.5185 19.1524 12.6558 18.7819 12.9111C18.4114 13.1664 18.1311 13.5271 17.9792 13.9437H16.1857L14.8892 10.1535C14.8398 10.0091 14.7442 9.8839 14.6164 9.79623C14.4887 9.70858 14.3355 9.66306 14.1793 9.66634C14.0231 9.66964 13.8721 9.72156 13.7483 9.8145C13.6246 9.90745 13.5346 10.0366 13.4917 10.1829L11.4561 17.1235L9.06303 3.13186C9.03528 2.97032 8.95128 2.82302 8.82518 2.71471C8.69907 2.60642 8.53854 2.54373 8.37058 2.53719C8.2026 2.53065 8.03745 2.58066 7.90289 2.67883C7.76832 2.77699 7.67253 2.91731 7.63166 3.07617L4.84342 13.9437H2.48781C2.29374 13.9437 2.10764 14.0188 1.97041 14.1525C1.83319 14.2862 1.7561 14.4676 1.7561 14.6567C1.7561 14.8458 1.83319 15.0271 1.97041 15.1608C2.10764 15.2945 2.29374 15.3696 2.48781 15.3696H5.4146C5.57776 15.3696 5.73624 15.3164 5.86485 15.2186C5.99343 15.1207 6.08479 14.9838 6.12436 14.8296L8.1978 6.74786L10.5466 20.4775C10.5736 20.6373 10.6558 20.7835 10.7795 20.8917C10.9031 21 11.061 21.064 11.227 21.0732H11.2687C11.4275 21.073 11.582 21.0227 11.7088 20.9294C11.8356 20.8361 11.9279 20.7052 11.9715 20.5563L14.249 12.7905L14.9642 14.8821C15.0128 15.0241 15.1059 15.1476 15.2305 15.2351C15.3551 15.3226 15.5048 15.3696 15.6584 15.3696H17.9792C18.1134 15.7393 18.3493 16.066 18.6611 16.3139C18.9729 16.5618 19.3487 16.7214 19.7473 16.7752C20.146 16.8291 20.552 16.7752 20.9214 16.6193C21.2905 16.4634 21.6088 16.2116 21.8412 15.8915C22.0736 15.5713 22.2109 15.195 22.2386 14.8038C22.2663 14.4126 22.1832 14.0215 21.9981 13.6733C21.8131 13.3251 21.5335 13.0331 21.1895 12.8293C20.8457 12.6256 20.4509 12.5178 20.0486 12.5178Z"
        fill="white"
      />
      <path
        d="M21.2683 0.390137H2.73171C1.43855 0.390137 0.390244 1.43845 0.390244 2.7316V21.2682C0.390244 22.5613 1.43855 23.6096 2.73171 23.6096H21.2683C22.5614 23.6096 23.6098 22.5613 23.6098 21.2682V2.7316C23.6098 1.43845 22.5614 0.390137 21.2683 0.390137Z"
        stroke="white"
      />
      <path
        d="M11.8915 0.390137H21.2683C22.5614 0.390137 23.6098 1.43845 23.6098 2.7316V21.2682C23.6098 22.5613 22.5614 23.6096 21.2683 23.6096H6.19513"
        stroke="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4644_1633">
        <rect width="24" height="24" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
