import React from 'react';
import './styles.css';


function LinearLoader() {
    return (
        <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default LinearLoader;