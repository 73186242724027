import React from 'react';
import { getMyEmojiByValue } from '../../mood/FellingMood';
import { IGroup } from '../../../types/Group';
import { getMoodText } from '../../../helpers/moodList';
import styles from './styles.module.css';

export default function GroupFellingFlag({ group }: { group: IGroup }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {typeof group.general_average_weekly_mood == 'number' && <div>This group is feeling</div>}
      <div className={styles.feelingsEmj}>
        {getMyEmojiByValue(Math.round(group.general_average_weekly_mood ?? -1), 20)}
        <span style={{ padding: '1px 0 0 4px' }}>
          {getMoodText(Math.round(group.general_average_weekly_mood ?? -1))}{' '}
        </span>
      </div>
    </div>
  );
}
