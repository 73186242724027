import React, { Dispatch, SetStateAction, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as SearchSvg } from 'images/Search.svg';
import TextField from '@mui/material/TextField';
import { darkTheme } from '../../../../themes/themes';
import { ThemeProvider } from '@mui/material';
import MessageHockPopUp from '../../../../hooks/MessageHockPopUp';
import { useSelector } from 'react-redux';
import { GroupsApiService } from '../../../../services/groups.service';
import { useToken } from '../../../../cookies';
import { selectMailDomainsPrefixes } from '../../../../store/auth';
import styles from './CreateGroupMobileStyle.module.css';

export default function Step3({
  setStep,
  newGroupId,
  closeWindow,
}: {
  setStep?: Dispatch<SetStateAction<number>>;
  closeWindow?: () => void;
  newGroupId: string;
}) {
  const [searchValue, setSearchValue] = useState('');
  const [mailList, setMailList] = useState<Array<string>>([]);
  const mailDomainsPrefixes = useSelector(selectMailDomainsPrefixes);

  const shareUrl = `${window.location.origin}/group/${newGroupId}`;
  const { token } = useToken();
  const sendEmailInvite = async () => {
    //TODO send to back all email list when back will be done
    const inviteFriend = await GroupsApiService.inviteFriendInGroup(token, mailList, shareUrl);
    if (!inviteFriend.ok) {
      MessageHockPopUp({
        message: inviteFriend.originalError.message,
        timeOut: 3000,
        type: 'error',
        size: 'small',
      });
    } else {
      MessageHockPopUp({
        message: 'Email with invitation was sent to ' + mailList[0],
        timeOut: 3000,
        type: 'success',
        size: 'small',
      });
      setMailList([]);
    }
  };
  const checkEmail = () => {
    const isValidEmail = searchValue.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
    if (!isValidEmail) {
      return MessageHockPopUp({
        message: 'Invalid email format',
        timeOut: 3000,
        type: 'warning',
        size: 'small',
      });
    }
    const searchedMailDomain = searchValue.split('@')[1];
    const isMyDomainAvailable = mailDomainsPrefixes.find((e) => e === searchedMailDomain);
    if (!isMyDomainAvailable) {
      return MessageHockPopUp({
        message: 'This school email is not available',
        timeOut: 3000,
        type: 'warning',
        size: 'small',
      });
    }
    if (mailList.find((mail) => mail === searchValue)) {
      return MessageHockPopUp({
        message: 'This email is already in list',
        timeOut: 3000,
        type: 'warning',
        size: 'small',
      });
    }
    setMailList((prev) => [...prev, searchValue]);
    setSearchValue('');
  };
  const removeMailFromList = (mail: string) => {
    setMailList(mailList.filter((el) => el !== mail));
  };

  return (
    <div className={styles.createGroupMain}>
      <div className={styles.headerCreateGroup}></div>
      <div className={styles.createGroupForm}>
        <h1 className={styles.headTextTitle} style={{ marginBottom: '20px' }}>
          Invite Members{' '}
        </h1>
        <p className={styles.textDescription}>Search a member by name or send email invite</p>

        <div className={styles.inviteFriendsBlock}>
          <ThemeProvider theme={darkTheme}>
            <TextField
              required
              onChange={(e) => setSearchValue(e.target.value)}
              fullWidth
              value={searchValue}
              style={{ marginTop: '10px', width: '96%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchSvg style={{ cursor: 'pointer' }} width={20} height={20} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div onClick={checkEmail} className={styles.sendEmailBtn}>
                      Add
                    </div>
                  </InputAdornment>
                ),
              }}
              id="outlined-size-small"
              size="small"
            />
          </ThemeProvider>
        </div>
        <div className={styles.emailList}>
          {mailList.map((email) => {
            return (
              <div className={styles.emailElement}>
                {email}
                <div className={styles.removeEmailBtn} onClick={() => removeMailFromList(email)}>
                  x
                </div>
              </div>
            );
          })}
        </div>
        {!!mailList.length && (
          <div onClick={sendEmailInvite} className={styles.sendButton}>
            Send
          </div>
        )}
        <p className={styles.textDescription}>
          Invite with a link: <span style={{ color: '#FFC700' }}>{shareUrl}</span>
        </p>
        {setStep ? (
          <>
            <div className={styles.nextButton} onClick={() => setStep(4)}>
              Next
            </div>
            <div className={styles.skipButton} onClick={() => setStep(4)}>
              Skip
            </div>
          </>
        ) : (
          closeWindow && (
            <div className={styles.nextButton} onClick={() => closeWindow()}>
              Close
            </div>
          )
        )}
      </div>
    </div>
  );
}

