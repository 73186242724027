import React from 'react';
import { User } from '../../../../types/User';
import { isValidImageUrl } from '../../../../helpers/checkImageUrl';
import { NO_USER_AVATAR } from '../../../../utils/constantImagesUrl';
import { useHistory } from 'react-router-dom';
import { userNameFromAllParams } from '../../../../helpers/userName';
import { UserMessageCircle } from 'images/chat/userMessageCircle';
import styles from '../MobileHeaderStyles.module.css';

function UserSearchResult({ user }: { user: User }) {
  const history = useHistory();
  const goToUserProfile = () => {
    history.push(`/user/${user.id}/`);
  };
  return (
    <div key={user.id} className={styles.searchResultUserBlock}>
      <img
        onClick={goToUserProfile}
        className={styles.searchUserImgResult}
        src={isValidImageUrl(user.profile?.image_url || '') || NO_USER_AVATAR}
        alt=""
      />
      <div className={styles.userData} onClick={goToUserProfile}>
        <div>{userNameFromAllParams(user.username, user.profile?.first_name, user.profile?.last_name)}</div>
        <div>{user.profile?.major || user.profile?.faculty || user.profile?.institute}</div>
      </div>
      <div
        className={styles.userDirectMessage}
        onClick={() => {
          history.push('/mobile-chat', { user });
        }}
      >
        {UserMessageCircle()}
      </div>
    </div>
  );
}

export default UserSearchResult;