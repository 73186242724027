import React from 'react';
import style from './SettingsStyle.module.css';

export const SettingItemContainer = (props: { title: string; id: string; children: React.ReactNode }) => {
  const { title, id, children } = props;

  return (
    <div id={id} className={style.main}>
      <h2>{title}</h2>
      <div className={style.separator} />
      {children}
    </div>
  );
};
