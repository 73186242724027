export const partnersIcon = () => (
  <svg width="21" height="21" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.84375 18.6124C2.84375 15.3505 5.48807 12.7062 8.75 12.7062V12.7062C12.0119 12.7062 14.6562 15.3505 14.6562 18.6124V22.6624C14.6562 24.0604 13.523 25.1937 12.125 25.1937H5.375C3.97703 25.1937 2.84375 24.0604 2.84375 22.6624V18.6124Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M5.375 17.7687V20.1312C5.375 21.9951 6.88604 23.5062 8.75 23.5062C10.614 23.5062 12.125 21.9951 12.125 20.1312V18.6124C12.125 18.5658 12.0872 18.5281 12.0406 18.5281L11.2813 18.5281C9.88329 18.5281 8.75 17.3948 8.75 15.9968V15.3218C8.75 15.2752 8.71222 15.2374 8.66563 15.2374H7.90625C6.50828 15.2374 5.375 16.3707 5.375 17.7687Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M23.87 8.5376V10.1135C23.87 11.3827 23.3403 12.5943 22.4086 13.4561L21.2708 14.5085C20.9903 14.768 20.6223 14.9121 20.2402 14.9121H18.3934C18.0113 14.9121 17.6433 14.768 17.3628 14.5085L16.225 13.456C15.2934 12.5942 14.7637 11.3827 14.7637 10.1136V8.65145"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M22.7308 4.36391L23.4897 3.60511C23.4897 3.60511 21.6627 2.79301 19.6954 2.84626C17.7282 2.89951 16.6599 3.98455 16.6599 3.98455C16.6599 3.98455 15.099 3.22569 14.7628 5.50228C14.4176 7.12238 14.7628 9.29662 14.7628 9.29662C14.7628 9.29662 15.6195 8.54835 15.977 7.93067C16.3951 7.20841 17.0394 6.26114 17.0394 6.26114C17.0394 6.26114 18.2331 6.91291 19.3159 7.01993C20.2963 7.11682 20.6217 6.39731 21.5925 6.56471C22.9992 6.80728 23.8693 9.29662 23.8693 9.29662L23.8692 6.56471C23.8692 5.47195 23.1355 4.59157 22.7308 4.36391Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M7.63477 10.1001L7.63477 5.07802L10.7165 5.07802"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.63477 10.1001L9.37039 8.36447"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.63477 10.1001L5.89914 8.36447"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9824 18.5781L20.9824 23.6002L17.9007 23.6002"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9824 18.5781L19.2468 20.3138"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9824 18.5781L22.718 20.3138"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
