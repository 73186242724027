import { ApiResponse } from 'apisauce';
import { _RestApiService } from './api.service';
import { Tag } from '../types/Tag';

const tagApi = new _RestApiService(window._env_.REACT_APP_BACKEND_URL || '');

export class _TagApiService {
  getTags(token: string): Promise<ApiResponse<Array<Tag>>> {
    return tagApi.get(`/api/tags/`, {}, tagApi.getAuthenticateHeader(token));
  }
}

export const TagApiService = new _TagApiService();
